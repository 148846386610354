import React, { useState, useEffect } from "react";
import { CSS } from "@stitches/react";
import { Modal, Text, Icon } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { TalentVertical, VerticalConfiguration } from "src/graphql/types";
import { verticalMap } from "src/utils/user";
import { RadioGroup } from "src/ccl/data-entry";
import { Button } from "src/ccl/navigation";
import { useVerticalConfigurations } from "src/hooks";
import { Loading } from "src/components";
import { mergeCss } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";

interface VerticalSelectModalProps {
  vertical: TalentVertical;
  setVertical: (v: TalentVertical) => void;
  boxCss?: CSS;
  backgroundColor?: `$${keyof typeof tokens.colors}`;
  textColor?: `$${keyof typeof tokens.colors}`;
  iconColor?: keyof typeof tokens.colors;
  dataTestId?: string;
  textCss?: CSS;
}

export const VerticalSelectModal = ({
  vertical,
  setVertical,
  boxCss = {},
  textCss = {},
  textColor = "$white",
  iconColor = "white",
  backgroundColor = "$black",
  dataTestId = "VerticalSelectorMobileBanner",
}: VerticalSelectModalProps) => {
  const { loading, configurations: verticalConfigurations } =
    useVerticalConfigurations();

  const radioOptions = (verticalConfigurations || []).map(
    (config: VerticalConfiguration) => ({
      id: config.id as TalentVertical,
      labelText: `${config.label}s`.toUpperCase(),
    }),
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>(vertical);

  useEffect(() => {
    setSelectedOption(vertical);
  }, [vertical]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        css={mergeCss(
          {
            marginTop: "$6",
            mx: "$5",
            backgroundColor: backgroundColor,
            height: "59px",
            display: "grid",
            gridTemplateColumns: "10% [col-start] 80% [col-start] 10% [end]",
            alignItems: "center",
            "@bp4": { display: "none" },
          },
          boxCss,
        )}
        onClick={() => {
          setModalOpen(true);
        }}
        data-test-id={dataTestId}
      >
        <Text
          css={mergeCss(
            {
              color: textColor,
              gridColumnStart: "col-start 1",
              justifySelf: "center",
            },
            textCss,
          )}
          variant="buttonText"
          data-test-id="VerticalSelectorMobileText"
        >
          {`${verticalMap[vertical]}s`}
        </Text>
        <Box css={{ gridColumnStart: "col-start 2", paddingTop: "$1" }}>
          <Icon size={14} variant="arrowDown" color={iconColor} />
        </Box>
      </Box>
      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setSelectedOption(vertical);
          setModalOpen(false);
        }}
        title="select talent type"
        titleCss={{
          fontSize: "18px",
          marginTop: "$7",
          marginBottom: "$7",
          lineHeight: "$24",
          paddingLeft: "$3",
          paddingRight: "$3",
        }}
        showCloseButton
        modalStyles={{
          borderRadius: "20px 20px 0px 0px",
          marginBottom: "0px",
          width: "100%",
        }}
        withBodyPadding={false}
        withTitlePadding={false}
        overlayCss={{ padding: "revert" }}
      >
        <Box css={{ px: "$3" }}>
          <Flex css={{ flexDirection: "column" }}>
            <RadioGroup
              options={radioOptions}
              defaultChecked={vertical}
              name="verticalSelectionMobile"
              size="sm"
              context="mobile"
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              // setHoveredOption={() => {}}
            />
          </Flex>
          <Flex css={{ justifyContent: "space-between", paddingTop: "$6" }}>
            <Button
              css={{ backgroundColor: "$grey6" }}
              variant="primary"
              onClick={() => {
                setSelectedOption(vertical);
                setModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setVertical(selectedOption as TalentVertical);
                setModalOpen(false);
              }}
              data-test-id="VerticalSelectorMobileDoneButton"
            >
              Done
            </Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};
