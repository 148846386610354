import { gql } from "@apollo/client";

export const UPDATE_REVIEW_MUTATION = gql`
  mutation UpdateReview($reviewId: ID!, $rating: Int!, $hidden: Boolean) {
    updateReview(reviewId: $reviewId, rating: $rating, hidden: $hidden) {
      id
      rating
    }
  }
`;
