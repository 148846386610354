import React from "react";
import { CSS, ComponentProps } from "@stitches/react";
import { Box } from "src/ccl/layout";
import { styled } from "src/ccl/stitches";

interface ParagraphRendererProps extends ComponentProps<typeof Box> {
  text: string;
  css?: CSS;
}

const StyledP = styled("p", {
  whiteSpace: "pre-wrap",
});

export const ParagraphRenderer = ({
  text,
  css = {},
}: ParagraphRendererProps) => <StyledP css={css}>{text}</StyledP>;
