import { gql } from "@apollo/client";
import { PAYMENT_BATCH_FRAGMENT } from "src/graphql/fragments";

export const FIRST_PARTY_AGENT_PAYMENT_BATCH_SHOW_QUERY = gql`
  query FirstPartyAgentBatchShowQuery($id: ID!) {
    paymentBatch(id: $id) {
      ...PaymentBatchFragment
    }
  }
  ${PAYMENT_BATCH_FRAGMENT}
`;
