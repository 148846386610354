import { gql } from "@apollo/client";

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateAgencyTalentMutation($talentId: ID!, $groupIds: [ID!]) {
    updateAgencyTalent(talentId: $talentId, groupIds: $groupIds) {
      id
      groups {
        id
        name
        manuallySet
      }
    }
  }
`;
