import { gql } from "@apollo/client";

export const UPDATE_JOB_VERTICALS_MUTATION = gql`
  mutation UpdateJobVerticals(
    $jobId: ID!
    $jobVerticals: [UpdateJobVerticalInput!]!
  ) {
    updateJobVerticals(jobId: $jobId, jobVerticals: $jobVerticals) {
      id
    }
  }
`;
