import { gql } from "@apollo/client";
import { TALENT_PROFILE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const AGENT_TALENT_USER_QUERY = gql`
  query AgentTalentUser($slug: ID!) {
    me {
      agency {
        talent(id: $slug) {
          id
          name
          firstName
          lastName
          slug
          email
          phoneNumber
          addressLine1
          addressLine2
          city
          postcode
          country
          featuredAsset {
            id
            height
            width
            mediaUrl
          }
          notes {
            id
            body
            createdAt
            user {
              id
              name
              featuredAsset {
                id
                height
                width
                mediaUrl
              }
            }
          }
          profile {
            ... on TalentProfile {
              ...TalentProfileDetails
            }
          }
        }
      }
    }
  }

  ${TALENT_PROFILE_DETAILS_FRAGMENT}
`;
