import { gql } from "@apollo/client";
import {
  JOB_PARTS_FRAGMENT,
  NOTE_DETAILS_FRAGMENT,
  ASSIGNEE_PARTS_FRAGMENT,
  INVOICE_DETAILS_FRAGMENT,
} from "src/graphql/fragments";

export const TPA_DASHBOARD_JOB_LIST_QUERY = gql`
  query TPADashboardJobList(
    $filters: JobFilterInput
    $sortOrder: SortOrderInput
    $first: Int!
    $after: String
  ) {
    verticalTalentSearch(inSameAgency: true) {
      totalCount
    }
    me {
      id
      agency {
        id
        name
      }
      jobs(
        filters: $filters
        sortOrder: $sortOrder
        first: $first
        after: $after
      ) @connection(key: "jobs", filter: ["filters", "sortOrder"]) {
        totalCount
        pageInfo {
          startCursor
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...JobParts
            spansMultipleDays
            commissionForUser
            agencyJobState
            latestPaidInvoices {
              invoiceNumber
            }
            state
            talent {
              state
              talent {
                name
                email
                slug
                featuredAsset {
                  mediaUrl
                }
                profile {
                  ... on TalentProfile {
                    primaryLocation {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${JOB_PARTS_FRAGMENT}
  ${ASSIGNEE_PARTS_FRAGMENT}
  ${NOTE_DETAILS_FRAGMENT}
  ${INVOICE_DETAILS_FRAGMENT}
`;
