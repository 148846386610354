import amplitude from "amplitude-js";
import { debugAnalytics, isDev } from "src/config";
import { Scalars, TalentVertical } from "src/graphql/types";
import { Mixpanel } from "src/utils/mixpanel";

export interface AnalyticsValues {
  ctaType: string;
  service: string;
  url: string;
  filterName: string;
  numberOfModels: number;
  jobValue: number;
  jobId?: string;
  jobName?: string;
  vertical: TalentVertical | null;
  rating: number;
  talentId: Scalars["ID"];
}

export const sendToAmplitude = (event: string, properties?: unknown) => {
  if (isDev && debugAnalytics) {
    console.log("[Amplitude event]", event, properties);
  }
  amplitude.getInstance().logEvent(event, properties);
  Mixpanel.trackEvent(event, properties);
};

const sendToGTM = (event: string, values?: Partial<AnalyticsValues>) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({ event, ...values });
};

const sendToCIO = (event: string) => {
  if (!window._cio) {
    return;
  }

  window._cio.track(event);
};

export const trackEvent = (name: string, values?: Partial<AnalyticsValues>) => {
  if (window.gloss?.disableTracking) {
    return;
  }

  if (isDev) {
    console.log("[Tracked event]", name, "values:", values);
  }

  sendToGTM(name, values);
  sendToCIO(name);
};
