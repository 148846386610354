import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import {
  ContentfulAsset,
  ContentfulBlogPostItem,
  ContentfulAuthorEntry,
  ContentfulEntry,
  ContentfulResEntity,
} from "src/entities";

export type CtaVariant = "createJob" | "mailingList" | "talentApplication";

export interface BlogAuthor {
  name: string;
  bio: string;
  avatarUrl: string;
}

export interface BlogPost {
  author: BlogAuthor;
  slug: string;
  title: string;
  leadIn: string;
  createdAt: Date;
  coverAsset?: ContentfulAsset;
  ctaVariant: CtaVariant;
  body: ContentfulDocument;
  assets: ContentfulAsset[];
  metaDescription: string;
}

export const transformEntity = (
  entity: ContentfulResEntity | undefined,
  entry: ContentfulEntry | undefined,
): BlogPost | null => {
  if (!entity) {
    return null;
  }

  if (entry?.sys.contentType?.sys.id !== "blogPost") {
    return null;
  }

  const post = entry as ContentfulBlogPostItem;

  if (!post) {
    return null;
  }

  const authorEntryId = post.fields.author.sys.id;
  const authorAttributes = (entity.includes?.Entry || []).find(
    (e) => e.sys.id === authorEntryId,
  ) as ContentfulAuthorEntry;

  const photoId = authorAttributes.fields.photo.sys.id;
  const authorPhotoAttributes = (entity.includes?.Asset || []).find(
    (asset) => asset.sys.id === photoId,
  ) as ContentfulAsset;

  const author = {
    name: authorAttributes?.fields.name,
    bio: authorAttributes?.fields.bio,
    avatarUrl: authorPhotoAttributes.fields.file.url,
  };

  let coverAssetAttributes;
  if (post.fields.coverAsset !== undefined) {
    const coverAssetEntryId = post.fields.coverAsset.sys.id;
    coverAssetAttributes = (entity.includes?.Asset || []).find(
      (e) => e.sys.id === coverAssetEntryId,
    ) as ContentfulAsset;
  }

  return {
    author: author,
    slug: post.fields.slug,
    title: post.fields.title,
    leadIn: post.fields.leadIn,
    createdAt: new Date(post.sys.createdAt as string),
    ctaVariant: post.fields.ctaVariant as CtaVariant,
    coverAsset: coverAssetAttributes,
    body: post.fields.body,
    assets: entity.includes?.Asset || [],
    metaDescription: post.fields.metaDescription,
  };
};
