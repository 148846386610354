import React from "react";
import { SubscriptionForm } from "./SubscriptionForm";
import { Text, Icon } from "src/ccl/document";
import { Box, ContentContainer, Flex, Grid } from "src/ccl/layout";
import { mergeCss, styled } from "src/ccl/stitches";
import { LinkButton, Link } from "src/ccl/navigation";
import { sendToAmplitude } from "src/utils/analytics";

const PodcastButton = styled(LinkButton, {
  background: "$podcast",
  color: "$white",
});

const BlogPreviewImage = styled("img", {
  maxWidth: "100%",
});

const BlogPost = ({
  imgSrc,
  url,
  title,
}: {
  imgSrc: string;
  url: string;
  title: string;
}) => (
  <Box>
    <Link
      to={url}
      css={{
        textDecoration: "none",
        cursor: "pointer",
      }}
      onClick={() => sendToAmplitude("booker dashboard - clicks blog")}
    >
      <Box css={{ backgroundColor: "$white" }}>
        <BlogPreviewImage src={imgSrc} />
        <Box css={{ p: "$8" }}>
          <Text variant="h3" css={{ mb: "$4" }}>
            {title}
          </Text>
          <Text variant="mini" color="grey6">
            Backstage
          </Text>
        </Box>
      </Box>
    </Link>
  </Box>
);

export const SocialFooter = ({
  showSubscription = true,
  backstageCardAmplitudeTracking = "homepage - backstage card",
  creativePathsAmplitudeTracking = "homepage - creative paths card",
}: {
  showSubscription?: boolean;
  backstageCardAmplitudeTracking?: string;
  creativePathsAmplitudeTracking?: string;
}) => {
  return (
    <Box
      css={{
        backgroundColor: "$black",
        py: "80px",
        color: "$white",
        "@bp2": {
          py: "120px",
        },
      }}
      data-test-id="socialFooter"
    >
      <ContentContainer>
        <Grid
          css={{
            gridGap: "$16",
            gridColumns: "1fr",
            "@bp2": { gridColumns: "1fr 1fr" },
          }}
        >
          <Box>
            <Flex
              css={{
                flexDirection: "row",
                justifyContent: "space-between",
                mb: "$8",
              }}
            >
              <Text variant={{ "@initial": "h2", "@bp2": "h1" }} color="white">
                Read
              </Text>
              <LinkButton
                variant="secondary"
                to="/backstage"
                onClick={() => sendToAmplitude(backstageCardAmplitudeTracking)}
                css={mergeCss(
                  {},
                  { display: "none", "@bp3": { display: "block" } },
                )}
              >
                <Icon
                  variant="backstageVertical"
                  size={16}
                  css={{
                    mr: "$4",
                    verticalAlign: "middle",
                    position: "relative",
                    top: "-1px",
                  }}
                />
                Backstage
              </LinkButton>
            </Flex>

            <Text
              variant="meta"
              color="white"
              css={{ mb: "$7", "@bp3": { mb: "$12" } }}
            >
              Visit our blog for industry advice, interviews and inspiration.
            </Text>
            <LinkButton
              variant="secondary"
              to="/backstage"
              onClick={() => sendToAmplitude(creativePathsAmplitudeTracking)}
              css={mergeCss(
                {},
                {
                  display: "inline-block",
                  mb: "$10",
                  "@bp3": { display: "none" },
                },
              )}
            >
              <Icon
                variant="backstageVertical"
                size={16}
                css={{
                  mr: "$4",
                  verticalAlign: "middle",
                  position: "relative",
                  top: "-1px",
                }}
              />
              Backstage
            </LinkButton>

            <Grid
              css={{
                gridGap: "$6",
                "@bp5": { gridGap: "$8" },
                gridColumns: "1fr",
                "@bp3": { gridColumns: "1fr 1fr" },
              }}
            >
              <BlogPost
                imgSrc="https://images.ctfassets.net/l81brv70t2rv/cvrZ0cKQLFLVkrmxa3vPJ/5ea7a0b73e8801f13fd8f50e56835b8b/_DSC7775.jpg?w=1380"
                url="/backstage/adora-is-the-sound-of-romi-alter"
                title="Adora is the sound of Romi Alter"
              />
              <BlogPost
                imgSrc="https://images.ctfassets.net/l81brv70t2rv/4BMarXDdrI7Pq7oz14kplE/6798e2592920c3fe2de1655ab9217c36/IMG_8076-min_copy.jpg?w=1380"
                url="/backstage/starface"
                title="Celebrating acne in the world of modelling with Starface"
              />
            </Grid>
          </Box>

          <Box>
            <Flex
              css={{
                flexDirection: "row",
                justifyContent: "space-between",
                mb: "$8",
              }}
            >
              <Text variant={{ "@initial": "h2", "@bp2": "h1" }} color="white">
                Listen
              </Text>
              <PodcastButton
                to="/creative-paths"
                onClick={() => sendToAmplitude(creativePathsAmplitudeTracking)}
                css={mergeCss(
                  {},
                  { display: "none", "@bp3": { display: "block" } },
                )}
              >
                <Icon
                  variant="creativePathsVertical"
                  size={16}
                  css={{
                    mr: "$4",
                    verticalAlign: "middle",
                    position: "relative",
                    top: "-1px",
                    path: {
                      "&:nth-of-type(1)": { fill: "$grey6" },
                      "&:nth-of-type(2)": { fill: "$black" },
                    },
                    circle: {
                      fill: "$white",
                    },
                  }}
                />
                Creative Paths
              </PodcastButton>
            </Flex>

            <Text
              variant="meta"
              color="white"
              css={{ mb: "$7", "@bp3": { mb: "$12" } }}
            >
              Listen to our podcast for stories from diverse creatives.
            </Text>
            <PodcastButton
              to="/creative-paths"
              onClick={() => sendToAmplitude(creativePathsAmplitudeTracking)}
              css={mergeCss(
                {},
                {
                  display: "inline-block",
                  mb: "$10",
                  "@bp3": { display: "none" },
                },
              )}
            >
              <Icon
                variant="creativePathsVertical"
                size={16}
                css={{
                  mr: "$4",
                  verticalAlign: "middle",
                  position: "relative",
                  top: "-1px",
                  path: {
                    "&:nth-of-type(1)": { fill: "$grey6" },
                    "&:nth-of-type(2)": { fill: "$black" },
                  },
                  circle: {
                    fill: "$white",
                  },
                }}
              />
              Creative Paths
            </PodcastButton>
            <iframe
              src="https://open.spotify.com/embed/show/5QuUtdpfs9z8oHKNtlWhpC"
              width="100%"
              height="232"
              frameBorder="0"
              allow="encrypted-media"
              title="Creative Paths"
            ></iframe>
          </Box>
        </Grid>
        {showSubscription && (
          <Box css={{ mt: "50px", "@bp2": { mt: "100px" } }}>
            <SubscriptionForm />
          </Box>
        )}
      </ContentContainer>
    </Box>
  );
};
