import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useFormikContext } from "formik";
import {
  Field,
  FileUploadField,
  SelectReact,
  Option,
} from "src/ccl/data-entry";
import { ConfirmModal, InfoBanner, Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { ExternalLink } from "src/ccl/navigation";
import { DELETE_AGENCY_TALENT_CONTRACT } from "src/graphql/mutations/agent/DeleteAgencyTalentContract";
import {
  User,
  TalentProfile,
  MutationDeleteAgencyTalentContractArgs,
  Query,
  ProfileFormContext,
} from "src/graphql/types";
import { AGENCIES_QUERY } from "src/graphql/queries/agency";
import { TalentFormValues } from "src/components/dashboards/agents/talentManagement/forms/formConfigV2";
import { useStoreModel } from "src/hooks";
import { FieldInfoText } from "src/components/dashboards/agents/talentManagement/forms/FieldInfoText";

interface ContractProps {
  context: ProfileFormContext;
  user?: User;
}

export const Contract = ({ user, context }: ContractProps) => {
  const { isFirstPartyAgent } = useStoreModel("currentUser");

  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
  const [contractUrl, setContractUrl] = useState<string | undefined>(
    (user && (user.profile as TalentProfile).contractUrl) || undefined,
  );
  const { setFieldValue, values } = useFormikContext<TalentFormValues>();
  const { data } = useQuery<Query>(AGENCIES_QUERY);

  const [deleteAgencyTalentContract] =
    useMutation<MutationDeleteAgencyTalentContractArgs>(
      DELETE_AGENCY_TALENT_CONTRACT,
    );

  const agencies: Option[] = (data?.agencies || []).map((agency) => ({
    label: agency.name,
    value: agency.id,
  }));

  return (
    <Box css={{ mb: "$16" }}>
      <ConfirmModal
        isOpen={isDeleteModalShowing}
        confirmButtonTitle="Yes, delete it"
        title="Are you sure you want to delete this contract?"
        onCancel={() => setIsDeleteModalShowing(false)}
        onConfirm={async () => {
          setFieldValue("talentProfile.contract", null);
          setIsDeleteModalShowing(false);
          setContractUrl(undefined);
          deleteAgencyTalentContract({
            variables: {
              talentId: user?.id,
            },
          });
        }}
      />
      {context === "edit" && isFirstPartyAgent && agencies.length > 0 && (
        <Field
          variant="b2Bold"
          name="agency"
          label="Agency"
          css={{ mb: "$8" }}
          labelCss={{ mb: "$5" }}
        >
          <SelectReact
            id="agency"
            variant="rebrand"
            initialValues={agencies.find(
              (agency) => agency.value == values.talentProfile.agencyId,
            )}
            onChange={(agency) =>
              setFieldValue("talentProfile.agencyId", agency?.value)
            }
            options={agencies.sort((a, b) => a.label.localeCompare(b.label))}
            isSearchable
          />
        </Field>
      )}

      {contractUrl ? (
        <>
          <ExternalLink
            to={contractUrl}
            target="_blank"
            eventName="agencyDashboard:editTalent:downloadContract"
          >
            Download contract
          </ExternalLink>

          <Box
            css={{
              cursor: "pointer",
              mt: "$3",
            }}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              setIsDeleteModalShowing(true);
            }}
          >
            <InfoBanner icon="cross" iconSize={12} variant="centered">
              <Text>Remove contract</Text>
            </InfoBanner>
          </Box>
        </>
      ) : (
        <Field variant="b2Bold" label="Upload signed contract">
          <FieldInfoText
            text={<>Upload the signed contract between you and your talent</>}
          />
          <FileUploadField
            id="contract"
            name="contract"
            onUpload={(file) => setFieldValue("talentProfile.contract", file)}
          />
        </Field>
      )}
    </Box>
  );
};
