import { gql } from "@apollo/client";
import { SHORTLIST_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const REPLACE_TALENT_MUTATION = gql`
  mutation ReplaceTalent($jobId: ID!, $talentIds: [ID!]!) {
    replaceTalent(jobId: $jobId, talentIds: $talentIds) {
      ...ShortlistDetails
    }
  }
  ${SHORTLIST_DETAILS_FRAGMENT}
`;
