import { useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useHistory } from "react-router-dom";
import { createTypedHooks, Store } from "easy-peasy";
import { merge } from "lodash";
import { StoreModel } from "src/models";

const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;
export const useStore = typedHooks.useStore;

export const useStoreModel = <K extends keyof StoreModel>(key: K) => {
  const state = useStoreState((s) => s[key]);
  const actions = useStoreActions((a) => a[key]);
  return merge({}, state, actions);
};

export const useRequest = <
  Request extends (params: Parameters<Request>[0]) => {
    unsubscribe: () => void;
  },
>(
  request: Request,
  params?: Parameters<Request>[0],
  additionalDeps?: unknown[],
) => {
  let deps = [request, params];
  if (additionalDeps) {
    deps = [...deps, ...additionalDeps];
  }
  useDeepCompareEffect(() => request(params).unsubscribe, deps);
};

export const useVerticalConfigurations = () => {
  const config = useStoreModel("verticalConfigurations");
  useEffect(() => {
    config.request();
  }, []);
  return config;
};

export const useFeatures = () => {
  const features = useStoreModel("features");
  useEffect(() => {
    features.request();
  }, []);
  return features;
};

export const useStoreInitialisers = (store: Store<StoreModel>) => {
  const hasOnAppMount = (actions: {}): actions is { onAppMount: Function } =>
    "onAppMount" in actions;

  const hasOnNavigation = (actions: {}): actions is {
    onNavigation: Function;
  } => "onNavigation" in actions;

  const history = useHistory();

  useEffect(() => {
    const subscribers: Function[] = [];
    const actionsByModel = store.getActions();
    Object.values(actionsByModel).forEach(function executeActions(actions: {}) {
      Object.values(actions).forEach((action) => {
        if (typeof action === "object" && action !== null) {
          executeActions(action);
        }
      });
      if (hasOnAppMount(actions)) actions.onAppMount();
      subscribers.push(
        history.listen(() => {
          if (hasOnNavigation(actions)) actions.onNavigation();
        }),
      );
    });
    return () => subscribers.forEach((unsubscribe) => unsubscribe());
  }, [store]);
};
