import { action, Action, persist, thunk, Thunk } from "easy-peasy";
import { StoreModel } from ".";
import { isDev } from "src/config";
import { graphQLClient } from "src/graphql/client";
import {
  Mutation,
  MutationCreateMarketingAttributionArgs,
} from "src/graphql/types";
import { CREATE_MARKETING_ATTRIBUTION_MUTATION } from "src/graphql/mutations";

interface AttributionQueryParams {
  utmSource: string;
  utmCampaign: string;
  utmMedium: string;
  utmContent?: string;
}

interface TrackEventParams {
  eventName: string;
  jobId?: number;
  attributionParams?: AttributionQueryParams;
}

export interface MarketingAttributionModel {
  utmData: AttributionQueryParams | null;
  setUtmData: Action<MarketingAttributionModel, AttributionQueryParams>;
  onAppMount: Thunk<MarketingAttributionModel, void, void, StoreModel>;
  reset: Action<MarketingAttributionModel>;
  trackAttributionEvent: Thunk<
    MarketingAttributionModel,
    TrackEventParams,
    void,
    StoreModel
  >;
}

const persistInLocalStorage = (model: MarketingAttributionModel) =>
  persist(model, { storage: "localStorage" });

export const marketingAttributionModel: MarketingAttributionModel =
  persistInLocalStorage({
    utmData: null,
    setUtmData: action((state, payload) => {
      state.utmData = payload;
    }),
    onAppMount: thunk(async (actions, _payload, { getState }) => {
      const params = new URLSearchParams(window.location.search);
      const utmSource = params.get("utm_source");
      const utmCampaign = params.get("utm_campaign");
      const utmMedium = params.get("utm_medium");
      const utmContent = params.get("utm_content") || undefined;

      const hasUtmData = utmSource && utmCampaign && utmMedium;
      const hasExistingDataInStore = !!getState().utmData;

      if (hasUtmData) {
        const utmData = {
          utmSource,
          utmCampaign,
          utmMedium,
          utmContent,
        };
        if (!hasExistingDataInStore) {
          await actions.setUtmData(utmData);
        }
        actions.trackAttributionEvent({
          eventName: "site:land",
          attributionParams: utmData,
        });
      }
    }),
    reset: action((state) => {
      state.utmData = null;
    }),
    trackAttributionEvent: thunk(async (_actions, payload, { getState }) => {
      const utmData = payload.attributionParams || getState().utmData;

      if (!utmData) {
        return;
      }

      const { eventName, jobId } = payload;

      if (isDev) {
        console.log(`[Marketing attribution] ${eventName}`, utmData); // eslint-disable-line no-console
        return;
      }

      try {
        await graphQLClient.mutate<
          Mutation,
          MutationCreateMarketingAttributionArgs
        >({
          mutation: CREATE_MARKETING_ATTRIBUTION_MUTATION,
          variables: {
            eventName: eventName,
            utmData: {
              source: utmData.utmSource,
              medium: utmData.utmMedium,
              campaign: utmData.utmCampaign,
              content: utmData.utmContent,
            },
            jobId: jobId,
          },
        });
      } catch {} // eslint-disable-line no-empty
    }),
  });
