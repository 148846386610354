import { gql } from "@apollo/client";
import { JOB_VERTICAL_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const BOOKER_DASHBOARD_HOMEPAGE_LIST_JOBS_FRAGMENT = gql`
  fragment BookerDashboardListJobs on User {
    jobs(
      first: 5
      after: $jobsEndCursor
      filters: { states: ["APPROVED", "FINALIZED"] }
      sortOrder: { direction: ASC, column: "start_date" }
    ) {
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          slug
          brand
          state
          startDate
          endDate
          vertical
          unpaidInvoiceCount
          totalInvoiceCount
          paymentViaExternalPlatform
          ...JobVerticalDetails
          talent {
            id
            state
            email
            phoneNumber
            talent {
              id
              name
              slug
              featuredAsset {
                mediaUrl
              }
              profile {
                ... on TalentProfile {
                  id
                  agency {
                    name
                  }
                  vertical
                  location
                }
              }
            }
          }
        }
      }
    }
  }
  ${JOB_VERTICAL_DETAILS_FRAGMENT}
`;
