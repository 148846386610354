import { tokens } from "src/ccl/stitches/theme";
import { InvoiceState } from "src/graphql/types";

export const tagColors: Record<
  InvoiceState,
  { bg: keyof typeof tokens.colors; color: keyof typeof tokens.colors }
> = {
  [InvoiceState.Processing]: { bg: "rejectedLight", color: "red" },
  [InvoiceState.ReadyToPay]: { bg: "rejectedLight", color: "red" },
  [InvoiceState.Paid]: { bg: "approvedLight", color: "approved" },
  [InvoiceState.Refunded]: { bg: "rejectedLight", color: "red" },
  [InvoiceState.PaymentIntentCreated]: { bg: "grey3", color: "black" },
  [InvoiceState.Pending]: { bg: "grey3", color: "black" },
};
