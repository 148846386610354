import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { InputStyles } from ".";
import { useStoreModel } from "src/hooks";
import { computeChecksum } from "src/utils/checksum";
import { Box, Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { ValidationBlock } from "src/ccl/feedback";
import { styled } from "src/ccl";
import { Mutation, MutationGeneratePresignedUrlArgs } from "src/graphql/types";
import { GENERATE_PRESIGNED_URL_MUTATION } from "src/graphql/mutations";
import { UploadedFile } from "src/entities";

const StyledInput = styled("input", InputStyles);

interface FileUploadFieldProps {
  id: string;
  name: string;
  validExtensions?: string[];
  onUpload?: (details: UploadedFile) => void;
  maxFileSizeBytes?: number;
}

export const FileUploadField = ({
  id,
  name,
  onUpload,
  validExtensions,
  maxFileSizeBytes,
}: FileUploadFieldProps) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [generatePresignedUrl] = useMutation<
    Mutation,
    MutationGeneratePresignedUrlArgs
  >(GENERATE_PRESIGNED_URL_MUTATION);
  const { uploadFile: uploadFileToS3 } = useStoreModel("s3");

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || !files[0]) {
      return;
    }

    setFile(files[0]);
  };

  useEffect(() => {
    if (!file) {
      return;
    }

    if (maxFileSizeBytes && file.size > maxFileSizeBytes) {
      const fileSizeMb = maxFileSizeBytes / 1024 / 1024;
      setError(`File is too big. Maximum file size is ${fileSizeMb}MB`);
      return;
    }

    (async () => {
      setIsUploading(true);
      const checksum = await computeChecksum(file);

      const generateUrlRsp = await generatePresignedUrl({
        variables: {
          attachment: "contracts",
          byteSize: file.size,
          checksum,
          contentType: file.type,
          filename: file.name,
        },
      });

      const data = generateUrlRsp?.data?.generatePresignedUrl;

      if (data) {
        const headers: Headers = new Headers();
        data.headers.forEach((h) => {
          headers.append(h.key, h.value);
        });

        const uploadRsp = await uploadFileToS3.request({
          url: data.url,
          headers: headers,
          body: file,
        });

        if (uploadRsp && onUpload) {
          onUpload({
            key: data.key,
            filename: file.name,
            contentType: file.type,
          });
        }
      }

      setIsUploading(false);
    })();
  }, [file]);

  const isInteractable = !uploadFileToS3.loading;
  const hasUploadError = uploadFileToS3.error;
  const hasError = hasUploadError || error !== undefined;

  return (
    <Box>
      <Box as="label" css={{ cursor: isInteractable ? "pointer" : "default" }}>
        <Flex
          css={{
            height: "146px",
            border: "1.5px dashed $grey3",
            borderRadius: "8px",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <StyledInput
            id={id}
            name={name}
            type="file"
            accept={
              validExtensions !== undefined
                ? validExtensions.join(",")
                : undefined
            }
            onChange={handleUpload}
            disabled={!isInteractable || isUploading}
            hidden
          />
          {!isUploading && !file && (
            <Box>
              <Text
                css={{
                  textDecoration: "underline",
                  color: "$grey5",
                  mb: "10px",
                }}
              >
                Upload file
              </Text>
              <Text css={{ color: "$grey5", textDecoration: "none" }}>
                or drag file here
              </Text>
              <Text css={{ color: "$grey5", textDecoration: "none" }}>
                (max. file photo 5MB)
              </Text>
            </Box>
          )}
          {isUploading && !hasError && <Text>Uploading...</Text>}
          {!isUploading && file && <Text>{file.name}</Text>}
        </Flex>
      </Box>

      {(hasUploadError || error) && (
        <ValidationBlock
          variant="error"
          title={
            error ||
            "There was a problem uploading your file, please try again."
          }
          css={{ mt: "$5" }}
        />
      )}
    </Box>
  );
};
