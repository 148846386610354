import React from "react";
import { Flex, Box } from "src/ccl/layout";
import { Link } from "src/ccl/navigation";

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  rootUrl: string;
  newerText: string;
  olderText: string;
}

export const Pagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  rootUrl,
  newerText,
  olderText,
}: PaginationProps) => {
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasPrevPage = prevPage > 0;
  const hasNextPage = nextPage <= Math.ceil(totalItems / itemsPerPage);

  const pathFor = (page: number) => {
    if (page === 1) {
      return rootUrl;
    }

    return `${rootUrl}/page/${page}`;
  };

  if (!hasPrevPage && !hasNextPage) {
    return null;
  }

  return (
    <Flex css={{ justifyContent: "space-between" }}>
      {hasPrevPage && (
        <Box css={{ marginRight: "auto" }}>
          <Link to={pathFor(prevPage)}>&larr; {newerText}</Link>
        </Box>
      )}

      {hasNextPage && (
        <Box css={{ marginLeft: "auto" }}>
          <Link to={pathFor(nextPage)}>{olderText} &rarr;</Link>
        </Box>
      )}
    </Flex>
  );
};
