import { Event } from "@bugsnag/js";
import { getLocalStorageItem } from "./storage";
import { storeLocalStorageKey } from "src/models";

export const attachLocalStorageItem = (
  event: Event,
  storeKeySuffix: string,
  metaDataKey: string,
) => {
  const item = getLocalStorageItem(storeLocalStorageKey(storeKeySuffix));
  if (item !== null) {
    try {
      event.addMetadata(metaDataKey, JSON.parse(item));
    } catch {} // eslint-disable-line no-empty
  }
};
