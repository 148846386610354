import { gql } from "@apollo/client";
import {
  PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT,
  PORTFOLIO_IMAGE_DETAILS_FRAGMENT,
} from "src/graphql/fragments";

export const TALENT_USER_QUERY = gql`
  query TalentUser($id: ID!) {
    talent(id: $id) {
      id
      name
      slug
      featuredAsset {
        mediaUrl
      }
      groups {
        name
      }
      bookmarked
      averageResponseTimeMinutes
      completedJobCount
      profile {
        ... on TalentProfile {
          scrubbedBiography
          instagramUsername
          website
          agency {
            name
            slug
            logo {
              mediaUrl
            }
          }
          lastPortfolioUpload {
            mediaUrl
            id
            height
            width
          }
          lastPortfolioUploads(count: 3) {
            mediaUrl
            id
            height
            width
          }
          portfolioImages {
            ...PortfolioImageDetails
          }
          portfolioImageCategories {
            ...PortfolioImageCategoryDetails
          }
          socialMediaFields {
            platform
            handle
            followerCount
          }
          primaryLocation {
            name
          }
          vertical
          totalReviews
          averageRating
          hobbies
          pastClients
          numericAttributes {
            name
            value
            conversionType
          }
          verticalData {
            key
            value
          }
        }
      }
    }
  }
  ${PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT}
  ${PORTFOLIO_IMAGE_DETAILS_FRAGMENT}
`;
