import { buildOptionProps } from "./util";
import { FilterOption } from "src/ccl/filtering";
import { DropdownPill } from "src/ccl/blocks";
import { TalentVertical } from "src/graphql/types";
import { uppercaseFirstLetter } from "src/utils/lang";
import { verticalIconMapAltOutline, verticalMap } from "src/utils/user";

const verticalOptions = Object.entries(verticalMap);
export const VerticalFilterOptions = ({
  variant,
  verticals,
  onChange,
}: VerticalFilterProps) => (
  <>
    {verticalOptions.map(([vertical, name], i) => (
      <FilterOption
        variant={variant}
        key={i}
        icon={verticalIconMapAltOutline[vertical as TalentVertical]}
        label={`${uppercaseFirstLetter(name)}s`}
        {...buildOptionProps(verticals, onChange, vertical as TalentVertical)}
      />
    ))}
  </>
);

interface VerticalFilterProps {
  variant?: "dropdown" | "pill" | "roundedButton";
  verticals: TalentVertical[];
  onChange: (states: TalentVertical[]) => void;
  selectedFilter?: string | null;
  setSelectedFilter?: (filter: string | null) => void;
}

export const VerticalFilter = ({
  verticals,
  onChange,
  selectedFilter,
  setSelectedFilter,
}: VerticalFilterProps) => {
  const active = verticals.length > 0;
  return (
    <DropdownPill
      active={active}
      text="Talent type"
      icon={active ? "disciplineOutlineInverted" : "disciplineOutline"}
      withHoverEffect
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
    >
      <VerticalFilterOptions verticals={verticals} onChange={onChange} />
    </DropdownPill>
  );
};
