import { useCallback, useEffect } from "react";

export const useKeyboardShortcut = (
  key: string,
  action: (e: KeyboardEvent) => void,
  deps: unknown[],
) => {
  const handleKeydown = useCallback(
    (e: KeyboardEvent) => e.key === key && action(e),
    deps,
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, [handleKeydown]);
};
