import { gql } from "@apollo/client";

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $bookerProfile: UpdateBookerProfileInput
  ) {
    updateUser(
      userId: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      bookerProfile: $bookerProfile
    ) {
      id
    }
  }
`;
