import React from "react";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Job } from "src/graphql/types";
import { Icon, Text } from "src/ccl/document";
import { Link } from "src/ccl/navigation";
import { JobStateLabel } from "src/ccl/jobs";
import { formatDate } from "src/utils/dates";

interface SimpleJobListItemProps {
  job: Job;
  jobLink: (slug: string) => string;
  context?: "client" | "agent" | "talent";
}

export const SimpleJobListItem = ({
  job,
  jobLink,
  context = "agent",
}: SimpleJobListItemProps) => (
  <Grid
    data-test-id="SimpleJobListItem"
    css={{
      gridColumns: 2,
      "@md": {
        gridColumns: 3,
        gridTemplateColumns: "2fr 2fr 1fr",
      },
    }}
  >
    <Box css={{ width: "100%" }}>
      <Link to={jobLink(job.slug)}>{job.name}</Link>
      <Text variant="meta" css={{ pt: "$4" }}>
        {job.brand}
      </Text>
    </Box>

    <Box
      css={{
        order: 3,
        gridColumn: 2,
        "@md": {
          order: 2,
        },
      }}
    >
      <Flex css={{ alignItems: "center", gap: "6px" }}>
        <Icon variant="calendar" css={{ "@md": { display: "none" } }} />
        <Text variant="b2">
          {formatDate(job.startDate, "dd MMM yyyy")}
          {job.spansMultipleDays && (
            <>
              {" "}
              <Text as="span" variant="b2">
                {" "}
                - {formatDate(job.endDate, "dd MMM yyyy")}
              </Text>
            </>
          )}
        </Text>
      </Flex>
    </Box>

    <Box
      css={{
        order: 2,
        "@md": {
          order: 3,
        },
      }}
    >
      <JobStateLabel job={job} variant="simple" context={context} />
    </Box>
  </Grid>
);
