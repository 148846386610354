import { gql } from "@apollo/client";

export const INVITE_AGENT_MUTATION = gql`
  mutation InviteAgent($email: String!, $agencyId: ID) {
    inviteUserToAgency(email: $email, agencyId: $agencyId) {
      id
      email
      createdAt
    }
  }
`;
