import React from "react";
import type * as Stitches from "@stitches/react";
import { styled } from "src/ccl";
import bookmarksWhite from "src/assets/icons/bookmarksWhite.svg";
import bookmarksBlack from "src/assets/icons/bookmarksBlack.svg";
import { Tooltip } from "src/ccl/feedback";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const PrimitiveCheckbox = (props: InputProps) => (
  <input {...props} type="checkbox" id={props.id || `form-${props.name}`} />
);

const BookmarkCheckbox = styled(PrimitiveCheckbox, {
  background: `url(${bookmarksWhite}) no-repeat center, $grey1`,
  margin: 0,
  width: 36,
  height: 36,
  appearance: "none",
  borderRadius: "100%",

  "&::before": {
    content: `''`,
    display: "block",
    position: "absolute",
    inset: 0,
  },

  "&:focus": {
    boxShadow: "inset 0 0 0 1px $black",
  },

  "&:disabled": {
    backgroundColor: "$grey2",
    borderColor: "$grey6",

    "&::before": {
      display: "none",
    },
  },

  "&:hover": {
    "@bp3": {
      background: `url(${bookmarksWhite}) no-repeat center`,
      cursor: "pointer",
      opacity: "1 !important",
      transition: "0.3s",
    },
  },

  "&:checked": {
    background: `url(${bookmarksBlack}) no-repeat center`,
    opacity: "1 !important",
    border: "none !important",
    transition: "0.3s",
  },
  variants: {
    size: {
      large: {
        width: 48,
        height: 48,
      },
    },
  },
});

export interface BookmarkCircleProps
  extends Stitches.ComponentProps<typeof BookmarkCheckbox> {
  withTooltip?: boolean;
}

export const BookmarkCircle = ({
  withTooltip = true,
  ...props
}: BookmarkCircleProps) =>
  withTooltip ? (
    <Tooltip content="Bookmark" alignment="left">
      <BookmarkCheckbox
        {...props}
        data-test-id="BookmarkCircle"
        className="bookmark-circle"
      />
    </Tooltip>
  ) : (
    <BookmarkCheckbox
      {...props}
      data-test-id="BookmarkCircle"
      className="bookmark-circle"
    />
  );

BookmarkCircle.toString = () => ".bookmark-circle";
