const call = (action: string, ...parameters: unknown[]) => {
  if (!window.zE) {
    return;
  }

  window.zE("messenger", action, ...parameters);
};

export const zendeskAvailable = () => {
  return !!window.zE;
};

export const zendeskOpen = () => {
  call("open");
};

export const zendeskClose = () => {
  call("close");
};
