import { useLocation } from "react-router-dom";
import { TalentVertical } from "src/graphql/types";
import { verticalMap } from "src/utils/user";

export const useVerticalQueryParam = (defaultVertical: TalentVertical) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const verticalParam = params.get("v")?.toUpperCase();
  if (verticalParam && Object.keys(verticalMap).includes(verticalParam)) {
    return verticalParam as TalentVertical;
  }
  return defaultVertical;
};
