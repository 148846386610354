import React, { useState } from "react";
import type * as Stitches from "@stitches/react";
import { Icon, Text } from "src/ccl/document";
import { copyToClipboard } from "src/utils/clipboard";
import { Tooltip } from "src/ccl/feedback";
import { mergeCss } from "src/ccl/stitches";
import { baseUrl } from "src/config";

export const ShareProfileLink = ({ slug }: { slug: string }) => {
  const defaultTooltipContent = "Copy profile URL";
  const [tooltipContent, setTooltipContent] = useState(defaultTooltipContent);

  const TextBody = ({
    body,
    css = {},
  }: {
    body: string;
    css: Stitches.CSS;
  }) => (
    <Text
      as="span"
      variant="meta"
      css={mergeCss(
        {
          pl: "$2",
          textDecoration: "underline",
          cursor: "pointer",
          "&:hover": {
            color: "$grey6",
          },
        },
        css,
      )}
      onClick={() => {
        copyToClipboard(`${baseUrl}/creatives/${slug}`);
        setTooltipContent("Copied");
        setTimeout(() => {
          setTooltipContent(defaultTooltipContent);
        }, 1000);
      }}
    >
      {body}
    </Text>
  );

  return (
    <Tooltip
      content={
        <Text variant="meta" css={{ color: "$white", whiteSpace: "nowrap" }}>
          {tooltipContent}
        </Text>
      }
      alignment="center"
    >
      <Icon
        variant="share"
        size={14}
        css={{
          mr: "$2",
        }}
      />

      <TextBody
        body="Share profile"
        css={{ display: "none", "@bp2": { display: "inline-block" } }}
      />
      <TextBody body="Share" css={{ "@bp2": { display: "none" } }} />
    </Tooltip>
  );
};
