import { gql } from "@apollo/client";

export const ASSET_DETAILS_FRAGMENT = gql`
  fragment AssetDetails on Asset {
    kind
    height
    width
    mediaUrl
  }
`;
