import * as Stitches from "@stitches/react";
import React, { MouseEvent, useState } from "react";
import {
  AddToCollectionButton,
  BookmarkCircle,
  BookmarkCircleProps,
} from "src/ccl/data-entry";
import { breakpoints, mergeCss, styled } from "src/ccl";
import { Box, Flex } from "src/ccl/layout";

const AddToCollectionContainer = styled(Box, {
  zIndex: 2,
  position: "relative",
  "@bp4": { opacity: 0 },
});

const isDesktop = () =>
  window.matchMedia(`(min-width: ${breakpoints.bp4}px)`).matches;

interface BookmarkAndCollectionCircleProps extends BookmarkCircleProps {
  containerCss?: Stitches.CSS;
  analyticsEventPage?: string;
  bookmarkCircleCss?: Stitches.CSS;
  onAddToCollection?: () => void;
  slideOutLeft?: boolean;
  cta?: string;
  analyticsProperty?: string;
}

export const BookmarkAndCollectionCircle = ({
  onAddToCollection,
  analyticsEventPage,
  onClick: handleBookmark,
  containerCss = {},
  bookmarkCircleCss = {},
  slideOutLeft,
  cta,
  analyticsProperty,
  ...bookmarkCircleProps
}: BookmarkAndCollectionCircleProps) => {
  const [addToCollectionVisible, setAddToCollectionVisible] = useState(
    isDesktop(),
  );
  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    /*
       so we want the following behaviour for the Add to Collection CTA:

       On mobile:
       when someone clicks the bookmark button, show the Add to Collection CTA
       when they click the (x) next to the Add to Collection CTA, hide the CTA
       when they click the button while the CTA is open, remove the bookmark
       
       on desktop:
       when someone clicks the bookmark button, remove the bookmark
       when someone hovers the bookmark button, show the Add to Collection CTA
       when someone clicks the (x) next to the Add to Collection CTA, hide the CTA

       the following does all that, with the sad exception that if someone resizes
       their screen to below BP4, all the Add to Collection CTAs will be visible until
       the components are rerendered (refresh, search talent etc)
     */

    if (isDesktop()) {
      return handleBookmark?.(e);
    }
    setAddToCollectionVisible(true);
    setTimeout(() => setAddToCollectionVisible(false), 10000);
    if (!(e.target as HTMLInputElement).checked && !addToCollectionVisible) {
      // on mobile we don't want to unbookmark on the first click of the checked
      // bookmark icon
      return;
    }
    return handleBookmark?.(e);
  };

  const onHide = () => {
    setAddToCollectionVisible(false);
    if (isDesktop()) {
      setTimeout(() => setAddToCollectionVisible(true), 500);
    }
  };

  return (
    <Flex
      css={mergeCss(containerCss, {
        "&:hover": {
          [`& ${AddToCollectionContainer}`]: {
            opacity: 1,
            transition: "0.2s ease-in-out",
          },
        },
      })}
    >
      <BookmarkCircle
        onClick={handleClick}
        css={bookmarkCircleCss}
        {...bookmarkCircleProps}
      />
      {bookmarkCircleProps.checked &&
        onAddToCollection &&
        addToCollectionVisible && (
          <AddToCollectionContainer>
            <AddToCollectionButton
              analyticsEventPage={analyticsEventPage}
              onAddToCollection={onAddToCollection}
              onHide={onHide}
              slideOutLeft={slideOutLeft}
              cta={cta}
              analyticsProperty={analyticsProperty}
            />
          </AddToCollectionContainer>
        )}
    </Flex>
  );
};

BookmarkCircle.toString = () => ".bookmark-circle";
