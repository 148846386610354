import { FetchResult, useMutation } from "@apollo/client";
import {
  MutationForgetTalentArgs,
  MutationSaveTalentArgs,
  SavedTalent,
} from "src/graphql/types";
import {
  SAVE_TALENT_MUTATION,
  FORGET_TALENT_MUTATION,
} from "src/graphql/mutations";
import { sendToAmplitude } from "src/utils/analytics";
import { BOOKER_SAVED_TALENT_COLLECTIONS } from "src/graphql/queries";

export interface BookmarkTalentResult {
  saveTalent?: SavedTalent;
  forgetTalent?: SavedTalent;
}

export const useHandleBookmark = (
  evictUserEntity = false,
  amplitudePage = "creative select",
) => {
  const [saveTalent, { loading: saveLoading, client }] = useMutation<
    BookmarkTalentResult,
    MutationSaveTalentArgs
  >(SAVE_TALENT_MUTATION);

  const [forgetTalent, { loading: forgetLoading }] = useMutation<
    BookmarkTalentResult,
    MutationForgetTalentArgs
  >(FORGET_TALENT_MUTATION);

  const handleBookmark = async (
    checked: boolean,
    talentId: string,
  ): Promise<FetchResult<BookmarkTalentResult>> => {
    const options = { variables: { talentId } };
    if (checked) {
      const promise = saveTalent(options);
      promise.then(({ data }) => {
        if (data?.saveTalent?.id) {
          client.cache.modify({
            id: `User:${talentId}`,
            fields: {
              bookmarked() {
                return true;
              },
            },
          });
        }
        sendToAmplitude(`${amplitudePage}- bookmarks creative`, {
          creativeVertical: data?.saveTalent?.talent.talentProfile?.vertical,
          talentId,
        });
      });
      return promise;
    } else {
      const promise = forgetTalent(options);
      promise.then(({ data }) => {
        const savedTalentId = data?.forgetTalent?.id;
        if (!savedTalentId) {
          return;
        }

        client.refetchQueries({
          include: [BOOKER_SAVED_TALENT_COLLECTIONS],
        });
        client.cache.evict({
          id: `SavedTalent:${savedTalentId}`,
        });

        if (evictUserEntity) {
          client.cache.evict({
            id: `User:${talentId}`,
          });
        } else {
          client.cache.modify({
            id: `User:${talentId}`,
            fields: {
              bookmarked() {
                return false;
              },
            },
          });
        }
        sendToAmplitude(`${amplitudePage} - removes bookmark`, {
          creativeVertical: data?.forgetTalent?.talent.talentProfile?.vertical,
          talentId,
        });
      });

      return promise;
    }
  };

  return { handleBookmark, loading: saveLoading || forgetLoading };
};
