import { gql } from "@apollo/client";
import { SAVED_TALENT_COLLECTION_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const BOOKER_SAVED_TALENT_COLLECTIONS = gql`
  query BookerDashboardSavedTalentCollections(
    $name: String
    $first: Int
    $after: String
  ) {
    me {
      profile {
        ... on BookerProfile {
          savedTalent(last: 4) {
            totalCount
            edges {
              node {
                talent {
                  featuredAsset {
                    mediaUrl
                  }
                }
              }
            }
          }
          savedTalentCollections(
            first: $first
            after: $after
            sortOrder: { column: "created_at", direction: DESC }
            name: $name
          ) {
            totalCount
            pageInfo {
              hasNextPage
              startCursor
              endCursor
            }
            edges {
              node {
                ...SavedTalentCollectionDetails
              }
            }
          }
        }
      }
    }
  }
  ${SAVED_TALENT_COLLECTION_DETAILS_FRAGMENT}
`;
