import { useEffect, useState } from "react";

export enum ScrollDirection {
  up = "up",
  down = "down",
}

const Jitter = 20;

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirection | null>();

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction =
        scrollY > lastScrollY ? ScrollDirection.down : ScrollDirection.up;
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > Jitter || scrollY - lastScrollY < -Jitter)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  return scrollDirection;
};
