import { ApolloError, useMutation } from "@apollo/client";
import {
  MutationCreateTalentReviewArgs,
  Review,
  TalentProfile,
  MutationUpdateReviewArgs,
  Job,
  User,
} from "src/graphql/types";
import {
  CREATE_TALENT_REVIEW_MUTATION,
  UPDATE_REVIEW_MUTATION,
} from "src/graphql/mutations";
import { useStoreModel } from "src/hooks";

type UserHandleReviewResult = [
  (talent: User, rating: number) => void,
  { loading: boolean; error?: ApolloError; called: boolean },
];

export const useHandleReview = (job: Job): UserHandleReviewResult => {
  const currentUser = useStoreModel("currentUser");
  const [
    reviewTalent,
    { loading: createLoading, error: createError, called: createCalled },
  ] = useMutation<
    { createTalentReview: Review },
    MutationCreateTalentReviewArgs
  >(CREATE_TALENT_REVIEW_MUTATION);
  const [
    updateReview,
    { loading: updateLoading, error: updateError, called: updateCalled },
  ] = useMutation<{ updateReview: Review }, MutationUpdateReviewArgs>(
    UPDATE_REVIEW_MUTATION,
  );

  const handleReview = (talent: User, rating: number) => {
    if (!currentUser.me) {
      return;
    }
    const jobReview = (talent.profile as TalentProfile).jobReview;
    if (jobReview) {
      updateReview({
        variables: { reviewId: jobReview.id, rating: rating },
        update: (cache, { data }) => {
          if (data?.updateReview.id) {
            cache.modify({
              id: `TalentProfile:${talent.profile.id}`,
              fields: {
                jobReview() {
                  return { __ref: `Review:${jobReview.id}` };
                },
              },
            });
          }
        },
      });
    } else {
      reviewTalent({
        variables: {
          reviewerId: currentUser.me.id,
          jobId: job.id,
          talentId: talent.id,
          rating,
        },
        update: (cache, { data }) => {
          if (data?.createTalentReview?.id) {
            cache.modify({
              id: `TalentProfile:${talent.profile.id}`,
              fields: {
                jobReview() {
                  return { __ref: `Review:${data.createTalentReview.id}` };
                },
              },
            });
          }
        },
      });
    }
  };

  return [
    handleReview,
    {
      loading: createLoading || updateLoading,
      error: createError || updateError,
      called: createCalled || updateCalled,
    },
  ];
};
