import React from "react";
import emptyJobCards from "src/assets/icons/empty-job-cards.svg";
import { Box, Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { LinkButton } from "src/ccl/navigation";
import { styled } from "src/ccl/stitches";
import { sendToAmplitude } from "src/utils/analytics";

const StyledManagementImage = styled("img", {
  width: 210,
  height: 125,
  "@bp2": {
    width: 350,
    height: 208,
  },
});

export const NoJobsCard = () => {
  return (
    <Flex
      data-test-id="LJNoJobsCard"
      css={{
        backgroundColor: "white",
        border: "1px solid $grey2",
        borderRadius: "8px",
        flexDirection: "column",
        px: "$6",
        py: "$8",
        "@bp2": {
          padding: "$16",
          flexDirection: "row",
          height: "296px",
          alignItems: "center",
          py: "$12",
        },
      }}
    >
      <Box
        css={{
          pb: "$5",
          "@bp2": {
            pb: "0",
            pr: "$11",
          },
        }}
      >
        <StyledManagementImage src={emptyJobCards} aria-hidden="true" />
      </Box>
      <Box
        css={{
          flexDirection: "column",
        }}
      >
        {/* Desktop title */}
        <Text
          variant="h3"
          css={{
            display: "none",
            "@bp2": {
              display: "block",
              color: "black",
            },
          }}
        >
          No jobs created
        </Text>
        {/* Mobile title */}
        <Text
          variant="body"
          css={{
            display: "block",
            color: "$grey6",
            fontWeight: "$bold",
            "@bp2": {
              display: "none",
            },
          }}
        >
          No jobs created
        </Text>
        <Text
          css={{
            py: "$5",
            color: "$grey6",
            mr: "$8",
            "@bp2": {
              py: "$6",
              color: "black",
              mr: "$0",
            },
          }}
        >
          You haven&apos;t created a job yet. Ready to get started?
        </Text>
        <LinkButton
          variant="primary"
          to="/jobs/new/discipline"
          onClick={() =>
            sendToAmplitude(
              "booker dashboard - clicks book creatives empty state card",
            )
          }
        >
          Create job
        </LinkButton>
      </Box>
    </Flex>
  );
};
