import { action, Action, computed, Computed } from "easy-peasy";

type Status = "error" | "pending" | "loading" | "loaded";

export interface RequestModel<T extends {} = {}> {
  error: string | null;
  status: Status;
  loading: Computed<RequestModel, boolean>;
  setError: Action<RequestModel, string>;
  setLoading: Action<T>;
}

export const requestModel: Omit<RequestModel, "request" | "setLoading"> = {
  error: null,
  status: "pending",
  loading: computed((state) => state.status === "loading"),
  setError: action((state, payload) => {
    state.error = payload;
    state.status = "error";
  }),
};
