import React from "react";
import { ComponentProps, CSS } from "@stitches/react";
import { Box } from "src/ccl/layout";
import { ViewportTriggeredVideo } from "src/ccl/document";
import { mergeCss } from "src/ccl/stitches";

interface BlogVideoProps extends ComponentProps<typeof Box> {
  src: string;
  css?: CSS;
}

export const BlogVideo = ({ src, css = {} }: BlogVideoProps) => (
  <ViewportTriggeredVideo src={src} css={mergeCss({}, css)} />
);
