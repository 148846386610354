export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
  VerticalFieldValueScalar: any;
};

export type ActiveStorageBlob = {
  __typename?: "ActiveStorageBlob";
  directUploadHeaders: Array<KeyValue>;
  directUploadUrl: Scalars["String"];
  signedId: Scalars["String"];
};

export type ActivityLog = {
  __typename?: "ActivityLog";
  message: Scalars["String"];
  timestamp: Scalars["ISO8601DateTime"];
  username: Scalars["String"];
};

/** The connection type for ActivityLog. */
export type ActivityLogConnection = {
  __typename?: "ActivityLogConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActivityLogEdge>>>;
  /** A list of nodes. */
  nodes: Array<ActivityLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ActivityLogEdge = {
  __typename?: "ActivityLogEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ActivityLog;
};

export type Address = {
  __typename?: "Address";
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  country: Scalars["String"];
  county?: Maybe<Scalars["String"]>;
  postcode: Scalars["String"];
};

export type AddressInput = {
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
};

export type Agency = {
  __typename?: "Agency";
  agencyReviews?: Maybe<ReviewConnection>;
  agentInvitations?: Maybe<Array<AgentInvitation>>;
  agents?: Maybe<UserConnection>;
  allTalent?: Maybe<Array<User>>;
  bio?: Maybe<Scalars["String"]>;
  commissionPercentage?: Maybe<Scalars["Int"]>;
  commissionRateHistory?: Maybe<AgencyCommissionHistoryConnection>;
  coverPhoto?: Maybe<Asset>;
  firstParty?: Maybe<Scalars["Boolean"]>;
  hideTalent?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  instagramUsername?: Maybe<Scalars["String"]>;
  isPrivate?: Maybe<Scalars["Boolean"]>;
  jobs?: Maybe<JobsConnection>;
  lastCommisionRateHistoryUpdate?: Maybe<Scalars["String"]>;
  location: Scalars["String"];
  logo?: Maybe<Asset>;
  name: Scalars["String"];
  packages?: Maybe<PackageConnection>;
  pastClients?: Maybe<Array<Scalars["String"]>>;
  paymentsEmail?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  specialities?: Maybe<Array<Scalars["String"]>>;
  talent?: Maybe<User>;
  talentInvitations?: Maybe<Array<TalentInvitation>>;
  tiktokUsername?: Maybe<Scalars["String"]>;
  twitterUsername?: Maybe<Scalars["String"]>;
};

export type AgencyAgencyReviewsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  agencyId?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

export type AgencyAgentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  agentName?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  roles?: InputMaybe<Array<AgentProfileRole>>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

export type AgencyCommissionRateHistoryArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

export type AgencyJobsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<JobFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

export type AgencyPackagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  agencyName?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  creatorName?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<PackageState>;
};

export type AgencyTalentArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type AgencyBreakdown = {
  __typename?: "AgencyBreakdown";
  agencyCommissionFee?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Float"]>;
  bookingCommissionFee?: Maybe<Scalars["String"]>;
  recipientName?: Maybe<Scalars["String"]>;
  recipientType?: Maybe<Scalars["String"]>;
};

export type AgencyCommissionHistory = {
  __typename?: "AgencyCommissionHistory";
  percentage?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** The connection type for AgencyCommissionHistory. */
export type AgencyCommissionHistoryConnection = {
  __typename?: "AgencyCommissionHistoryConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyCommissionHistoryEdge>>>;
  /** A list of nodes. */
  nodes: Array<AgencyCommissionHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type AgencyCommissionHistoryEdge = {
  __typename?: "AgencyCommissionHistoryEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: AgencyCommissionHistory;
};

/** The connection type for Agency. */
export type AgencyConnection = {
  __typename?: "AgencyConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyEdge>>>;
  /** A list of nodes. */
  nodes: Array<Agency>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type AgencyEdge = {
  __typename?: "AgencyEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Agency;
};

export type AgencyFilterInput = {
  locations?: InputMaybe<Array<Scalars["String"]>>;
  name?: InputMaybe<Scalars["String"]>;
};

export enum AgencyJobState {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Confirmed = "CONFIRMED",
  Expired = "EXPIRED",
  Paid = "PAID",
  PendingBookerResponse = "PENDING_BOOKER_RESPONSE",
  PendingTalentResponse = "PENDING_TALENT_RESPONSE",
  RejectedByBooker = "REJECTED_BY_BOOKER",
  RejectedByTalent = "REJECTED_BY_TALENT",
}

export type AgentInvitation = {
  __typename?: "AgentInvitation";
  agencyName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>;
  email: Scalars["String"];
  firstParty: Scalars["Boolean"];
  id: Scalars["ID"];
  token: Scalars["String"];
};

export type AgentOnboarding = {
  __typename?: "AgentOnboarding";
  firstParty: Scalars["Boolean"];
};

export type AgentProfile = {
  __typename?: "AgentProfile";
  assets: Array<Asset>;
  id: Scalars["ID"];
  role: Scalars["String"];
};

export enum AgentProfileRole {
  Manager = "MANAGER",
  Owner = "OWNER",
}

export type Asset = {
  __typename?: "Asset";
  height?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  kind: AssetKind;
  mediaUrl: Scalars["String"];
  sortWeight: Scalars["Int"];
  validUntil?: Maybe<Scalars["ISO8601DateTime"]>;
  width?: Maybe<Scalars["Int"]>;
};

export type AssetFileUploadInput = {
  file?: InputMaybe<FileUploadInput>;
  kind: AssetKind;
};

export enum AssetKind {
  Featured = "FEATURED",
  Image = "IMAGE",
  Logo = "LOGO",
  Polaroid = "POLAROID",
  Video = "VIDEO",
}

export type Auth = {
  __typename?: "Auth";
  jwt?: Maybe<Scalars["String"]>;
  unauthorized: Scalars["Boolean"];
};

export type AuthSso = {
  __typename?: "AuthSso";
  jwt?: Maybe<Scalars["String"]>;
  unauthorized: Scalars["Boolean"];
};

export type BankAccount = {
  __typename?: "BankAccount";
  accountNumber?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  kind?: Maybe<BankAccountKind>;
  ownerName: Scalars["String"];
  sortCode?: Maybe<Scalars["String"]>;
};

export enum BankAccountKind {
  Domestic = "DOMESTIC",
  International = "INTERNATIONAL",
}

export type BookerInvitation = {
  __typename?: "BookerInvitation";
  bookingCompanyName: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  email: Scalars["String"];
  id: Scalars["ID"];
  isExistingAccount: Scalars["Boolean"];
  token: Scalars["String"];
};

export type BookerProfile = {
  __typename?: "BookerProfile";
  autoApprovalDisabled?: Maybe<Scalars["Boolean"]>;
  bookingCompany: BookingCompany;
  brands: Array<Scalars["String"]>;
  company: Scalars["String"];
  id: Scalars["ID"];
  invoiceBillingAddress?: Maybe<Scalars["String"]>;
  invoiceBillingName?: Maybe<Scalars["String"]>;
  kind?: Maybe<Scalars["String"]>;
  paymentDetails?: Maybe<PaymentDetails>;
  role?: Maybe<Scalars["String"]>;
  savedTalent?: Maybe<SavedTalentConnection>;
  savedTalentCollection?: Maybe<SavedTalentCollection>;
  savedTalentCollections?: Maybe<SavedTalentCollectionConnection>;
  talentCollectionMemberships?: Maybe<Array<SavedTalentCollection>>;
  website?: Maybe<Scalars["String"]>;
};

export type BookerProfileSavedTalentArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortColumn?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type BookerProfileSavedTalentCollectionArgs = {
  id: Scalars["ID"];
};

export type BookerProfileSavedTalentCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

export type BookerProfileTalentCollectionMembershipsArgs = {
  id: Scalars["ID"];
};

export type BookerShortlist = {
  __typename?: "BookerShortlist";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  talentIds: Array<Scalars["ID"]>;
};

export type BookingCompany = {
  __typename?: "BookingCompany";
  billingContact?: Maybe<Scalars["String"]>;
  billingDetailsPresent?: Maybe<Scalars["Boolean"]>;
  bookerInvitations?: Maybe<Array<BookerInvitation>>;
  companyEmailAddress?: Maybe<Scalars["String"]>;
  companyRegistrationNumber?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>;
  freelancer?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["ID"]>;
  invoices?: Maybe<Array<Invoice>>;
  jobCount?: Maybe<Scalars["Int"]>;
  jobs?: Maybe<JobsConnection>;
  memberCount?: Maybe<Scalars["Int"]>;
  members?: Maybe<Array<User>>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Array<Note>>;
  phoneNumber?: Maybe<Scalars["String"]>;
  registeredAddress?: Maybe<Address>;
  tradingAddress?: Maybe<Address>;
  waiveInvoiceFee?: Maybe<Scalars["Boolean"]>;
};

export type BookingCompanyJobsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<JobFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

/** The connection type for BookingCompany. */
export type BookingCompanyConnection = {
  __typename?: "BookingCompanyConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BookingCompanyEdge>>>;
  /** A list of nodes. */
  nodes: Array<BookingCompany>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BookingCompanyEdge = {
  __typename?: "BookingCompanyEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: BookingCompany;
};

export type CommissionBreakdown = {
  __typename?: "CommissionBreakdown";
  additionalCommissionFee?: Maybe<Scalars["String"]>;
  agencyCommissionFee?: Maybe<Scalars["String"]>;
  contactFee?: Maybe<Scalars["String"]>;
  platformFee?: Maybe<Scalars["String"]>;
  transactionFee?: Maybe<Scalars["String"]>;
};

export enum CompanyHeadcountEstimate {
  AboveFiveHundred = "ABOVE_FIVE_HUNDRED",
  FiftyOneToOneHundred = "FIFTY_ONE_TO_ONE_HUNDRED",
  OneHundredOneToFiveHundred = "ONE_HUNDRED_ONE_TO_FIVE_HUNDRED",
  OneToFive = "ONE_TO_FIVE",
  SixToTwenty = "SIX_TO_TWENTY",
  TwentyOneToFifty = "TWENTY_ONE_TO_FIFTY",
}

export enum Complexion {
  FitzpatrickI = "FITZPATRICK_I",
  FitzpatrickIi = "FITZPATRICK_II",
  FitzpatrickIii = "FITZPATRICK_III",
  FitzpatrickIv = "FITZPATRICK_IV",
  FitzpatrickV = "FITZPATRICK_V",
  FitzpatrickVi = "FITZPATRICK_VI",
}

export type ComplexionOption = {
  __typename?: "ComplexionOption";
  colour: Scalars["String"];
  description: Scalars["String"];
  label: Scalars["String"];
  value: Scalars["String"];
};

export type Component = {
  __typename?: "Component";
  name: Scalars["String"];
  props?: Maybe<ComponentProps>;
};

export type ComponentOption = {
  __typename?: "ComponentOption";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type ComponentProps = {
  __typename?: "ComponentProps";
  checkboxOptions?: Maybe<Array<Scalars["String"]>>;
  label?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  selectOptions?: Maybe<Array<ComponentOption>>;
};

export type ContactBankAccount = {
  __typename?: "ContactBankAccount";
  bankAccountName?: Maybe<Scalars["String"]>;
  bankAccountNumber?: Maybe<Scalars["String"]>;
  bankBic?: Maybe<Scalars["String"]>;
  bankIban?: Maybe<Scalars["String"]>;
  bankSortCode?: Maybe<Scalars["String"]>;
};

export type ContactFeeBreakdown = {
  __typename?: "ContactFeeBreakdown";
  bookingFee?: Maybe<Scalars["String"]>;
  invoiceFee?: Maybe<Scalars["String"]>;
  platformFee?: Maybe<Scalars["String"]>;
  transactionFee?: Maybe<Scalars["String"]>;
  vat?: Maybe<Scalars["String"]>;
};

export enum ConversionType {
  Centimeters = "CENTIMETERS",
  EuShoe = "EU_SHOE",
  FeetAndInches = "FEET_AND_INCHES",
  Inches = "INCHES",
  MetersAndCentimeters = "METERS_AND_CENTIMETERS",
  UkShoe = "UK_SHOE",
}

export type CreateAgencyInput = {
  location: Scalars["String"];
  logo?: InputMaybe<FileUploadInput>;
  name: Scalars["String"];
  paymentsEmail: Scalars["String"];
};

export type CreateJobVerticalInput = {
  budget: Scalars["Float"];
  deliverables?: InputMaybe<Array<Scalars["String"]>>;
  jobLength?: InputMaybe<Scalars["String"]>;
  talentRequired: Scalars["Int"];
  timeslots?: InputMaybe<Array<TimeslotInput>>;
  vertical: TalentVertical;
};

export type CreatePackageVerticalInput = {
  budget?: InputMaybe<Scalars["Float"]>;
  deliverables?: InputMaybe<Array<Scalars["String"]>>;
  jobLength?: InputMaybe<Scalars["String"]>;
  talentRequired?: InputMaybe<Scalars["Int"]>;
  vertical: TalentVertical;
};

export type CreateTalentProfileInput = {
  additionalLocations?: InputMaybe<Array<TalentLocationInput>>;
  agencyId?: InputMaybe<Scalars["ID"]>;
  biography?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<FileUploadInput>;
  dateOfBirth: Scalars["ISO8601Date"];
  dietaryRequirements?: InputMaybe<Array<Scalars["String"]>>;
  gender: Gender;
  hobbies?: InputMaybe<Array<Scalars["String"]>>;
  instagramUsername?: InputMaybe<Scalars["String"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]>;
  maxDayRate?: InputMaybe<Scalars["Int"]>;
  minDayRate?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  pastClients?: InputMaybe<Array<Scalars["String"]>>;
  portfolioImages?: InputMaybe<Array<PortfolioImageInput>>;
  primaryLocation: TalentLocationInput;
  pronouns?: InputMaybe<Pronouns>;
  published?: InputMaybe<Scalars["Boolean"]>;
  shrineAssets?: InputMaybe<Array<AssetFileUploadInput>>;
  socialMediaFields?: InputMaybe<Array<SocialMediaInput>>;
  vertical: TalentVertical;
  verticalData?: InputMaybe<Scalars["JSON"]>;
  website?: InputMaybe<Scalars["String"]>;
  yearsExperience?: InputMaybe<Scalars["Int"]>;
};

export enum DataType {
  Integer = "INTEGER",
  String = "STRING",
}

export type DateRangeInput = {
  endDate: Scalars["ISO8601Date"];
  startDate: Scalars["ISO8601Date"];
};

export type DeckFile = {
  __typename?: "DeckFile";
  filename: Scalars["String"];
  id: Scalars["ID"];
  url: Scalars["String"];
};

export enum EyeColour {
  Blue = "BLUE",
  Brown = "BROWN",
  Green = "GREEN",
  Grey = "GREY",
}

export type FeeItem = {
  __typename?: "FeeItem";
  amount: Scalars["Float"];
  description: Scalars["String"];
  kind: FeeKind;
};

export enum FeeKind {
  BookingFee = "BOOKING_FEE",
  InvoiceFee = "INVOICE_FEE",
  PlatformFee = "PLATFORM_FEE",
  TransactionFee = "TRANSACTION_FEE",
}

export type Field = {
  __typename?: "Field";
  dataType: DataType;
  excludedGenders?: Maybe<Array<Gender>>;
  filterable: Scalars["Boolean"];
  inputType?: Maybe<InputType>;
  label: Scalars["String"];
  maxValue?: Maybe<Scalars["Int"]>;
  minValue?: Maybe<Scalars["Int"]>;
  multiple: Scalars["Boolean"];
  name: Scalars["String"];
  options?: Maybe<Array<FieldOption>>;
  required?: Maybe<Scalars["Boolean"]>;
  requiredGenders?: Maybe<Array<Gender>>;
  unit?: Maybe<Scalars["String"]>;
};

export type FieldOption = {
  __typename?: "FieldOption";
  colour?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  value: Scalars["String"];
};

export type FileUploadInput = {
  contentLength?: InputMaybe<Scalars["Int"]>;
  contentType: Scalars["String"];
  filename: Scalars["String"];
  key: Scalars["ID"];
};

export type Filter = {
  __typename?: "Filter";
  checkedValue?: Maybe<Scalars["String"]>;
  childFilters?: Maybe<Array<Filter>>;
  contexts: Array<FilterContext>;
  dataType?: Maybe<DataType>;
  defaultOptions?: Maybe<Array<Scalars["String"]>>;
  excludedGenders?: Maybe<Array<Gender>>;
  expanded?: Maybe<Scalars["Boolean"]>;
  expandedTitleFilter?: Maybe<Filter>;
  helperText?: Maybe<Scalars["String"]>;
  imperialMeasurementDisplay?: Maybe<MeasurementDisplay>;
  isClearable: Scalars["Boolean"];
  kind: FilterKind;
  label: Scalars["String"];
  labelChecked?: Maybe<Scalars["String"]>;
  labelUnchecked?: Maybe<Scalars["String"]>;
  maxValue?: Maybe<Scalars["Int"]>;
  metricMeasurementDisplay?: Maybe<MeasurementDisplay>;
  minValue?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  options?: Maybe<Array<FilterOption>>;
  preferenceName?: Maybe<Scalars["String"]>;
  rangeLabelPrefix?: Maybe<Scalars["String"]>;
  uncheckedValue?: Maybe<Scalars["String"]>;
  verticalSpecific: Scalars["Boolean"];
};

export enum FilterContext {
  CreateAJob = "CREATE_A_JOB",
  PackageShortlist = "PACKAGE_SHORTLIST",
  TalentManagement = "TALENT_MANAGEMENT",
}

export enum FilterKind {
  FilterGroup = "FILTER_GROUP",
  MultiSelect = "MULTI_SELECT",
  Range = "RANGE",
  Select = "SELECT",
  Text = "TEXT",
  Toggle = "TOGGLE",
}

export type FilterOption = {
  __typename?: "FilterOption";
  colour?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  value: Scalars["String"];
};

export type FilterOptions = {
  __typename?: "FilterOptions";
  bustSize: Measurement;
  chestSize: Measurement;
  complexions: Array<ComplexionOption>;
  dietaryRequirements: Array<LabelValueOption>;
  eyeColours: Array<OptionTypeWithColour>;
  genders: Array<LabelValueOption>;
  hairColours: Array<OptionTypeWithColour>;
  hairStyles: Array<LabelValueOption>;
  height: Measurement;
  hipSize: Measurement;
  hobbies: Array<LabelValueOption>;
  legLength: Measurement;
  shoeSize: Measurement;
  suitSize: Measurement;
  waistSize: Measurement;
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
  NonBinary = "NON_BINARY",
}

export type Group = {
  __typename?: "Group";
  id: Scalars["ID"];
  manuallySet: Scalars["Boolean"];
  name: Scalars["String"];
};

export enum HairColour {
  Black = "BLACK",
  Blond = "BLOND",
  Brown = "BROWN",
  Grey = "GREY",
  Other = "OTHER",
  Red = "RED",
}

export enum HairStyle {
  Bald = "BALD",
  Long = "LONG",
  Medium = "MEDIUM",
  ReligiousHeaddress = "RELIGIOUS_HEADDRESS",
  Short = "SHORT",
}

export enum HeadcountEstimate {
  AboveThirty = "ABOVE_THIRTY",
  ElevenToTwenty = "ELEVEN_TO_TWENTY",
  SixToTen = "SIX_TO_TEN",
  TwentyOneToThirty = "TWENTY_ONE_TO_THIRTY",
  ZeroToFive = "ZERO_TO_FIVE",
}
export enum HeadCountEstimateNumber {
  "30+" = "ABOVE_THIRTY",
  "11-20" = "ELEVEN_TO_TWENTY",
  "6-10" = "SIX_TO_TEN",
  "21-30" = "TWENTY_ONE_TO_THIRTY",
  "0-5" = "ZERO_TO_FIVE",
}

export enum InputType {
  MultiCheckbox = "MULTI_CHECKBOX",
  MultiSelect = "MULTI_SELECT",
  Radio = "RADIO",
  RadioSelect = "RADIO_SELECT",
  Range = "RANGE",
  Select = "SELECT",
  Text = "TEXT",
}

export type Invoice = {
  __typename?: "Invoice";
  currency: Scalars["String"];
  dueDate: Scalars["ISO8601Date"];
  feeItems?: Maybe<Array<FeeItem>>;
  hasSentLatePaymentInvoice: Scalars["Boolean"];
  id: Scalars["ID"];
  invoiceDate: Scalars["ISO8601Date"];
  invoiceNumber?: Maybe<Scalars["String"]>;
  isJobPaymentInvoice: Scalars["Boolean"];
  isLatePaymentInvoice: Scalars["Boolean"];
  job?: Maybe<Job>;
  overdue: Scalars["Boolean"];
  paidAt?: Maybe<Scalars["ISO8601DateTime"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReference?: Maybe<Scalars["String"]>;
  pdfUrl?: Maybe<Scalars["String"]>;
  poNumber?: Maybe<Scalars["String"]>;
  state: InvoiceState;
  subtotal: Scalars["Float"];
  total: Scalars["Float"];
  trustshareProjectId?: Maybe<Scalars["String"]>;
  xeroReference?: Maybe<Scalars["String"]>;
};

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: "InvoiceConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** A list of nodes. */
  nodes: Array<Invoice>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: "InvoiceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Invoice;
};

export type InvoiceFilterInput = {
  dueDateEnd?: InputMaybe<Scalars["ISO8601Date"]>;
  dueDateStart?: InputMaybe<Scalars["ISO8601Date"]>;
  invoiceNumber?: InputMaybe<Scalars["String"]>;
  jobName?: InputMaybe<Scalars["String"]>;
  overdue?: InputMaybe<Scalars["Boolean"]>;
  states?: InputMaybe<Array<InvoiceState>>;
};

export enum InvoiceState {
  Paid = "PAID",
  PaymentIntentCreated = "PAYMENT_INTENT_CREATED",
  Pending = "PENDING",
  Processing = "PROCESSING",
  ReadyToPay = "READY_TO_PAY",
  Refunded = "REFUNDED",
}

export type Job = {
  __typename?: "Job";
  activityStream?: Maybe<ActivityLogConnection>;
  additionalFeesAmount?: Maybe<Scalars["Float"]>;
  additionalNotes?: Maybe<Scalars["String"]>;
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  affiliate?: Maybe<JobAffiliate>;
  agencyJobState?: Maybe<AgencyJobState>;
  allTravelFeesCovered: Maybe<Scalars["Boolean"]>;
  archived: Scalars["Boolean"];
  assignedAgent?: Maybe<JobAssignee>;
  /** @deprecated Use assignedAgent instead */
  assignee?: Maybe<User>;
  awaitingPayment?: Maybe<Scalars["Boolean"]>;
  bloomActivatedAtForCurrentUser?: Maybe<Scalars["ISO8601DateTime"]>;
  bloomFeePercentage: Scalars["Float"];
  booker: User;
  bookingCompany: BookingCompany;
  brand: Scalars["String"];
  budget: Scalars["Float"];
  canReview: Scalars["Boolean"];
  cancellationNote?: Maybe<Scalars["String"]>;
  cancellationReason?: Maybe<JobCancellationReason>;
  cancelledAt?: Maybe<Scalars["ISO8601DateTime"]>;
  cateringProvided?: Maybe<Scalars["Boolean"]>;
  city?: Maybe<Scalars["String"]>;
  /** @deprecated use commissionForUser or totalCommission */
  commission?: Maybe<Scalars["Float"]>;
  commissionForUser?: Maybe<Scalars["Float"]>;
  completedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  conflicts?: Maybe<Array<JobConflict>>;
  country?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  creativeCounts: Array<JobCreativeCount>;
  currency: Scalars["String"];
  deckFiles?: Maybe<Array<DeckFile>>;
  deliverables: Array<Scalars["String"]>;
  description: Scalars["String"];
  endDate: Scalars["ISO8601Date"];
  excludeVat?: Maybe<Scalars["Boolean"]>;
  expensesAmount?: Maybe<Scalars["Float"]>;
  expiresOn?: Maybe<Scalars["ISO8601DateTime"]>;
  extraFeesAmount?: Maybe<Scalars["Float"]>;
  feeItems?: Maybe<Array<FeeItem>>;
  finalizedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  fullAddress?: Maybe<Scalars["String"]>;
  hasUnresolvedDisputes?: Maybe<Scalars["Boolean"]>;
  headcountEstimate?: Maybe<HeadcountEstimate>;
  id: Scalars["ID"];
  imagesReceived?: Maybe<Scalars["Boolean"]>;
  /** @deprecated For backwards compatibility */
  inboundPayment?: Maybe<Scalars["String"]>;
  invoiceBillingAddress?: Maybe<Scalars["String"]>;
  invoiceBillingName?: Maybe<Scalars["String"]>;
  invoiceFeeWithoutVat?: Maybe<Scalars["Float"]>;
  invoices?: Maybe<Array<Invoice>>;
  jobDisputes: Array<JobDispute>;
  jobLength?: Maybe<Scalars["String"]>;
  /** @deprecated replaced by :talent, for backwards compatibility with api types */
  jobTalent: Array<JobTalent>;
  jobVerticals: Array<JobVertical>;
  kind: JobKind;
  latestPaidInvoices?: Maybe<Array<Invoice>>;
  latitude?: Maybe<Scalars["Float"]>;
  location?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  modelsRequired: Scalars["Int"];
  name: Scalars["String"];
  notes?: Maybe<Array<Note>>;
  nudityType?: Maybe<NudityInvolvement>;
  package?: Maybe<Package>;
  payableInvoices?: Maybe<Array<Invoice>>;
  paymentBreakdown?: Maybe<Array<JobPaymentAmount>>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentProcessing?: Maybe<Scalars["Boolean"]>;
  paymentReceived?: Maybe<Scalars["Boolean"]>;
  paymentViaExternalPlatform: Scalars["Boolean"];
  payments?: Maybe<Array<OutboundPayment>>;
  postcode?: Maybe<Scalars["String"]>;
  preApprovalShortlistCount?: Maybe<Scalars["Int"]>;
  rejectionNote?: Maybe<Scalars["String"]>;
  rejectionReason?: Maybe<JobRejectionReason>;
  selfTapeRequired?: Maybe<Scalars["Boolean"]>;
  /** @deprecated Jobs are no longer limited to single verticals, see JobVerticals entity for vertical specific details */
  shortlistCount: Scalars["Int"];
  slug: Scalars["String"];
  spansMultipleDays: Scalars["Boolean"];
  startDate: Scalars["ISO8601Date"];
  state: JobState;
  stateForCurrentUser: JobTalentState;
  talent?: Maybe<Array<JobTalent>>;
  talentBloomEligibility?: Maybe<Scalars["Boolean"]>;
  talentBloomFee?: Maybe<Scalars["Float"]>;
  talentRequired: Scalars["Int"];
  totalCommission?: Maybe<Scalars["Float"]>;
  totalInvoiceCount: Scalars["Int"];
  totalPayable?: Maybe<Scalars["Float"]>;
  tpaAdditionalFeesCommission?: Maybe<Scalars["Float"]>;
  tpaBloomEligibility?: Maybe<Scalars["Boolean"]>;
  tpaBookingFee?: Maybe<Scalars["Float"]>;
  tpaTalentCommission?: Maybe<Scalars["Float"]>;
  transitionableStates: Array<Scalars["String"]>;
  travelExpenseCover?: Maybe<Scalars["Float"]>;
  trustshareProjectId?: Maybe<Scalars["String"]>;
  unpaidInvoiceCount: Scalars["Int"];
  unpaidLineItems?: Maybe<Array<LineItem>>;
  usage?: Maybe<Scalars["String"]>;
  usages?: Maybe<Array<Usage>>;
  vatAmount?: Maybe<Scalars["Float"]>;
  /** @deprecated Jobs are no longer limited to single verticals, see JobVerticals entity for vertical specific details */
  vertical: TalentVertical;
  virtual: Scalars["Boolean"];
};

export type JobActivityStreamArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export enum JobAffiliate {
  Depop = "depop",
}

export type JobAgency = {
  __typename?: "JobAgency";
  agency: Agency;
  id: Scalars["ID"];
  isPaid: Scalars["Boolean"];
  job: Job;
  paidAt?: Maybe<Scalars["ISO8601DateTime"]>;
  state: JobAgencyState;
};

export type JobAgencyInput = {
  id: Scalars["ID"];
  isPaid?: InputMaybe<Scalars["Boolean"]>;
};

export enum JobAgencyState {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type JobAssignee = {
  __typename?: "JobAssignee";
  assignedLiveJobCount?: Maybe<Scalars["Int"]>;
  email: Scalars["String"];
  featuredAsset?: Maybe<Asset>;
  id: Scalars["ID"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export enum JobCancellationReason {
  BookedElsewhere = "BOOKED_ELSEWHERE",
  DateChanged = "DATE_CHANGED",
  JobNotHappening = "JOB_NOT_HAPPENING",
  Other = "OTHER",
  RatesTooHigh = "RATES_TOO_HIGH",
  UnavailableCreatives = "UNAVAILABLE_CREATIVES",
  UnresponsiveBooker = "UNRESPONSIVE_BOOKER",
  UnresponsiveCreatives = "UNRESPONSIVE_CREATIVES",
}

export type JobConflict = {
  __typename?: "JobConflict";
  conflictingJobs: Array<Job>;
  jobTalent: JobTalent;
};

export type JobCreationSchema = {
  __typename?: "JobCreationSchema";
  detailsFormFields: Array<Component>;
};

export type JobCreativeCount = {
  __typename?: "JobCreativeCount";
  confirmedCount: Scalars["Int"];
  pendingCount: Scalars["Int"];
  vertical: TalentVertical;
};

export type JobDispute = {
  __typename?: "JobDispute";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  job: Job;
  reportedBy: User;
  resolvedBy?: Maybe<User>;
};

/** An edge in a connection. */
export type JobEdge = {
  __typename?: "JobEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Job;
};

export type JobFilterInput = {
  assigneeId?: InputMaybe<Scalars["ID"]>;
  brand?: InputMaybe<Scalars["String"]>;
  brands?: InputMaybe<Array<Scalars["String"]>>;
  dateRange?: InputMaybe<DateRangeInput>;
  jobType?: InputMaybe<Array<TalentVertical>>;
  name?: InputMaybe<Scalars["String"]>;
  nameOrBrand?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Array<Scalars["String"]>>;
  talentName?: InputMaybe<Scalars["String"]>;
  verticals?: InputMaybe<Array<TalentVertical>>;
};

export enum JobKind {
  Campaign = "CAMPAIGN",
  Casting = "CASTING",
  Commercial = "COMMERCIAL",
  Ecom = "ECOM",
  Editorial = "EDITORIAL",
  Events = "EVENTS",
  FashionWeek = "FASHION_WEEK",
  Fitting = "FITTING",
  Gifting = "GIFTING",
  InfluencerBrief = "INFLUENCER_BRIEF",
  Lookbook = "LOOKBOOK",
  MusicVideo = "MUSIC_VIDEO",
  Other = "OTHER",
  StudentAndGradProject = "STUDENT_AND_GRAD_PROJECT",
  TestShoot = "TEST_SHOOT",
}

export type JobPaymentAmount = {
  __typename?: "JobPaymentAmount";
  agencyBreakdown?: Maybe<AgencyBreakdown>;
  amount: Scalars["Float"];
  bankAccount?: Maybe<BankAccount>;
  commissionBreakdown?: Maybe<CommissionBreakdown>;
  contactBankAccount?: Maybe<ContactBankAccount>;
  contactFeeBreakdown?: Maybe<ContactFeeBreakdown>;
  isAgencyPaid?: Maybe<Scalars["Boolean"]>;
  isPaid?: Maybe<Scalars["Boolean"]>;
  jobAgencyId?: Maybe<Scalars["ID"]>;
  jobTalentId?: Maybe<Scalars["ID"]>;
  recipientName: Scalars["String"];
  recipientType: Scalars["String"];
};

export enum JobPostCompletionAction {
  ImagesReceived = "IMAGES_RECEIVED",
  PaymentReceived = "PAYMENT_RECEIVED",
}

export enum JobRejectionReason {
  Covid_19 = "COVID_19",
  Generic = "GENERIC",
  InappropriateBudget = "INAPPROPRIATE_BUDGET",
  InsufficientInformation = "INSUFFICIENT_INFORMATION",
  NoTalent = "NO_TALENT",
}

export enum JobState {
  Approved = "APPROVED",
  AwaitingPayment = "AWAITING_PAYMENT",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Expired = "EXPIRED",
  Finalized = "FINALIZED",
  PendingApproval = "PENDING_APPROVAL",
  Rejected = "REJECTED",
}

export type JobTalent = {
  __typename?: "JobTalent";
  assets?: Maybe<Array<Asset>>;
  bloomFee?: Maybe<Scalars["Float"]>;
  eligibleForBloom: Scalars["Boolean"];
  email?: Maybe<Scalars["String"]>;
  firstPaidJob?: Maybe<Scalars["Boolean"]>;
  formattedRejectionReason?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isPaid: Scalars["Boolean"];
  job: Job;
  lineItems?: Maybe<Array<LineItem>>;
  name: Scalars["String"];
  /** @deprecated backwards compatibility with api types */
  notified?: Maybe<Scalars["Boolean"]>;
  notifiedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  nudgedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  outboundPayments?: Maybe<Array<OutboundPayment>>;
  paidAt?: Maybe<Scalars["ISO8601DateTime"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  profile: TalentProfile;
  rejectionNote?: Maybe<Scalars["String"]>;
  state: JobTalentState;
  talent: User;
  transitionableEvents: Array<JobTalentStateEvent>;
  transitionableStates: Array<JobTalentState>;
  userId: Scalars["ID"];
};

export type JobTalentInput = {
  id: Scalars["ID"];
  isPaid?: InputMaybe<Scalars["Boolean"]>;
};

export enum JobTalentRejectionReason {
  BudgetTooLow = "BUDGET_TOO_LOW",
  Busy = "BUSY",
  DoesNotMatchBrief = "DOES_NOT_MATCH_BRIEF",
  JobDetails = "JOB_DETAILS",
  LateNotice = "LATE_NOTICE",
  LocationNotAccessible = "LOCATION_NOT_ACCESSIBLE",
  Other = "OTHER",
  UnwellPersonal = "UNWELL_PERSONAL",
  ValuesDoNotFit = "VALUES_DO_NOT_FIT",
}

export enum JobTalentState {
  Accepted = "ACCEPTED",
  AwaitingConfirmation = "AWAITING_CONFIRMATION",
  Confirmed = "CONFIRMED",
  Disputed = "DISPUTED",
  Paid = "PAID",
  Pending = "PENDING",
  PendingAgencyApproval = "PENDING_AGENCY_APPROVAL",
  Rejected = "REJECTED",
  RejectedByBooker = "REJECTED_BY_BOOKER",
}

export enum JobTalentStateEvent {
  Accept = "ACCEPT",
  Approve = "APPROVE",
  AwaitConfirmation = "AWAIT_CONFIRMATION",
  Confirm = "CONFIRM",
  Dispute = "DISPUTE",
  Pay = "PAY",
  Reject = "REJECT",
  RejectByBooker = "REJECT_BY_BOOKER",
}

export type JobVertical = {
  __typename?: "JobVertical";
  budget: Scalars["Float"];
  deliverables: Array<Scalars["String"]>;
  id: Scalars["ID"];
  jobLength?: Maybe<Scalars["String"]>;
  shortlistCount: Scalars["Int"];
  talentRequired: Scalars["Int"];
  timeslots?: Maybe<Array<Timeslot>>;
  vertical: TalentVertical;
};

/** The connection type for Job. */
export type JobsConnection = {
  __typename?: "JobsConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JobEdge>>>;
  /** A list of nodes. */
  nodes: Array<Job>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type KeyValue = {
  __typename?: "KeyValue";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type LabelValueOption = {
  __typename?: "LabelValueOption";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type LineItem = {
  __typename?: "LineItem";
  alreadyPaid: Scalars["Boolean"];
  amount: Scalars["Float"];
  currency: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  kind: LineItemKind;
  manuallyPaid: Scalars["Boolean"];
  outboundPayment?: Maybe<OutboundPayment>;
};

export enum LineItemKind {
  AdditionalFee = "ADDITIONAL_FEE",
  BookingFee = "BOOKING_FEE",
  Expense = "EXPENSE",
  InvoiceFee = "INVOICE_FEE",
  JobPayment = "JOB_PAYMENT",
  LatePayment = "LATE_PAYMENT",
  PlatformFee = "PLATFORM_FEE",
  TransactionFee = "TRANSACTION_FEE",
}

export type LinkPaymentIntentResponse = {
  __typename?: "LinkPaymentIntentResponse";
  url: Scalars["String"];
};

export type Measurement = {
  __typename?: "Measurement";
  maximum: Scalars["Int"];
  minimum: Scalars["Int"];
};

export enum MeasurementDisplay {
  Centimeters = "CENTIMETERS",
  EuShoe = "EU_SHOE",
  FeetAndInches = "FEET_AND_INCHES",
  Inches = "INCHES",
  MetersAndCentimeters = "METERS_AND_CENTIMETERS",
  UkShoe = "UK_SHOE",
}

export enum MediaLocation {
  Asia = "ASIA",
  Emea = "EMEA",
  Europe = "EUROPE",
  Global = "GLOBAL",
  NorthAmerica = "NORTH_AMERICA",
  OnlineOnly = "ONLINE_ONLY",
  Other = "OTHER",
  SouthAmerica = "SOUTH_AMERICA",
  Uk = "UK",
}

export enum MediaType {
  AllDigitalMedia = "ALL_DIGITAL_MEDIA",
  AllPrintMedia = "ALL_PRINT_MEDIA",
  B2BSales = "B2B_SALES",
  Broadcast = "BROADCAST",
  Brochures = "BROCHURES",
  Casting = "CASTING",
  Cinema = "CINEMA",
  Collateral = "COLLATERAL",
  DigitalPosters = "DIGITAL_POSTERS",
  DirectMail = "DIRECT_MAIL",
  Editorial = "EDITORIAL",
  Exhibition = "EXHIBITION",
  Internal = "INTERNAL",
  InternationalMedia = "INTERNATIONAL_MEDIA",
  Intranet = "INTRANET",
  InStore = "IN_STORE",
  Marketing = "MARKETING",
  Ooh = "OOH",
  Other = "OTHER",
  Packaging = "PACKAGING",
  PaidSocialMedia = "PAID_SOCIAL_MEDIA",
  Portfolio = "PORTFOLIO",
  Pos = "POS",
  PressPr = "PRESS_PR",
  PrintAdvertising = "PRINT_ADVERTISING",
  RightsForThirdPartyChannels = "RIGHTS_FOR_THIRD_PARTY_CHANNELS",
  Show = "SHOW",
  SocialMedia = "SOCIAL_MEDIA",
  Tvc = "TVC",
  Website = "WEBSITE",
  Youtube = "YOUTUBE",
}

export type MissingMediaCount = {
  __typename?: "MissingMediaCount";
  count: Scalars["Int"];
  desired: Scalars["Int"];
  kind: AssetKind;
};

export type Mutation = {
  __typename?: "Mutation";
  activateBloom?: Maybe<SuccessResponse>;
  addLineItem?: Maybe<LineItem>;
  addSavedTalentToCollections?: Maybe<SavedTalent>;
  addTalent?: Maybe<Job>;
  agencyApproveJob?: Maybe<Job>;
  agencyRejectJob?: Maybe<Job>;
  agencyRespondOnBehalf?: Maybe<JobTalent>;
  approveJob?: Maybe<Job>;
  archiveJobs?: Maybe<Array<Job>>;
  assignJob?: Maybe<Job>;
  associateAsset?: Maybe<Asset>;
  awaitJobPayment?: Maybe<Job>;
  cancelJob?: Maybe<Job>;
  categorisePortfolioImages?: Maybe<SuccessResponse>;
  changePassword?: Maybe<User>;
  completeJob?: Maybe<Job>;
  confirmJobInvoicePayment?: Maybe<Job>;
  convertPackageToDraft?: Maybe<Package>;
  convertPackageToJob?: Maybe<Package>;
  createAgency?: Maybe<Agency>;
  createAgencyBookerNote?: Maybe<Note>;
  createAgencyTalent?: Maybe<User>;
  createAgencyTalentInvitation?: Maybe<TalentInvitation>;
  createAgencyTalentNote?: Maybe<Note>;
  createAgentProfile?: Maybe<User>;
  createAsset?: Maybe<Asset>;
  createBooker?: Maybe<User>;
  createBookerProfile?: Maybe<User>;
  createBookingCompanyNote?: Maybe<Note>;
  createDeskRentalPaymentIntent?: Maybe<LinkPaymentIntentResponse>;
  createJob?: Maybe<Job>;
  createJobDispute?: Maybe<JobDispute>;
  createJobNote?: Maybe<Note>;
  createJobVertical?: Maybe<Job>;
  createMarketingAttribution?: Maybe<Scalars["Boolean"]>;
  createPackage?: Maybe<Package>;
  createPaymentIntent?: Maybe<PaymentIntentResponse>;
  createPortfolioImageCategory?: Maybe<PortfolioImageCategory>;
  createPortfolioImages?: Maybe<Array<PortfolioImage>>;
  createSavedTalentCollection?: Maybe<SavedTalentCollection>;
  createTalentApplication?: Maybe<TalentApplication>;
  createTalentProfile?: Maybe<User>;
  createTalentReview?: Maybe<Review>;
  createUser?: Maybe<User>;
  deleteAccount?: Maybe<Scalars["ID"]>;
  deleteAgency?: Maybe<Agency>;
  deleteAgencyBookerNote?: Maybe<Note>;
  deleteAgencyTalent?: Maybe<User>;
  deleteAgencyTalentContract?: Maybe<User>;
  deleteAgencyTalentFeaturedAsset?: Maybe<User>;
  deleteAgencyTalentNote?: Maybe<Note>;
  deleteAgencyUser?: Maybe<User>;
  deleteAgentInvitation?: Maybe<AgentInvitation>;
  deleteBookerInvitation?: Maybe<SuccessResponse>;
  deleteBookingCompanyBooker?: Maybe<SuccessResponse>;
  deleteBookingCompanyNote?: Maybe<Note>;
  deleteJobDeckFile?: Maybe<DeckFile>;
  deleteJobNote?: Maybe<Note>;
  deleteJobVertical?: Maybe<JobVertical>;
  deleteLineItem?: Maybe<LineItem>;
  deleteOutboundPayment?: Maybe<OutboundPayment>;
  deletePackage?: Maybe<Package>;
  deletePackageDeckFile?: Maybe<DeckFile>;
  deletePortfolioImageCategory?: Maybe<SuccessResponse>;
  deletePortfolioImages?: Maybe<SuccessResponse>;
  deleteSavedTalentCollection?: Maybe<SavedTalentCollection>;
  deleteTalentInvitation?: Maybe<SuccessResponse>;
  exchangeToken?: Maybe<Scalars["String"]>;
  expireJob?: Maybe<Job>;
  /** @deprecated Invoice payments are no longer processed through trustshare */
  finalizeInvoice?: Maybe<SuccessResponse>;
  forgetTalent?: Maybe<SavedTalent>;
  generateJobInvoiceForOutstandingLineItems?: Maybe<SuccessResponse>;
  generateLatePaymentInvoice?: Maybe<SuccessResponse>;
  generatePresignedUrl?: Maybe<PresignedUrl>;
  generateUploadUrl?: Maybe<ActiveStorageBlob>;
  inviteAgency?: Maybe<AgentInvitation>;
  inviteUserToAgency?: Maybe<AgentInvitation>;
  inviteUserToBookingCompany?: Maybe<BookerInvitation>;
  jobPostCompletionAction?: Maybe<Job>;
  markInvoicePaid?: Maybe<Invoice>;
  markNotificationRead?: Maybe<Notification>;
  onboardAgent?: Maybe<AgentOnboarding>;
  onboardBooker?: Maybe<User>;
  onboardTalent?: Maybe<TalentOnboarding>;
  publishPackage?: Maybe<Package>;
  rebuildInvoice?: Maybe<Invoice>;
  registerPushToken?: Maybe<Scalars["String"]>;
  rejectJob?: Maybe<Job>;
  removeJobAssignee?: Maybe<Job>;
  removePortfolioImageCategorisation?: Maybe<PortfolioImageCategory>;
  removeSavedTalentFromCollections?: Maybe<SavedTalent>;
  removeTalent?: Maybe<Job>;
  replacePackageTalent?: Maybe<Package>;
  replaceTalent?: Maybe<Job>;
  requestMoreInfo?: Maybe<Job>;
  requestToken?: Maybe<Scalars["String"]>;
  resendOnboardEmail?: Maybe<User>;
  resetGroupToDefault?: Maybe<User>;
  resetInvoicePaymentMethod?: Maybe<Invoice>;
  resolveJobDispute?: Maybe<JobDispute>;
  resubmitJob?: Maybe<Job>;
  retrieveTokenExpiry?: Maybe<Scalars["ISO8601DateTime"]>;
  saveTalent?: Maybe<SavedTalent>;
  sendPaymentVerificationEmail?: Maybe<User>;
  sendTalentNudge?: Maybe<Job>;
  setInvoiceFee?: Maybe<Invoice>;
  sortPortfolioImages?: Maybe<Array<PortfolioImage>>;
  talentAcceptJob?: Maybe<Job>;
  talentJobResponse?: Maybe<Job>;
  talentRejectJob?: Maybe<Job>;
  unarchiveJobs?: Maybe<Array<Job>>;
  updateAgency?: Maybe<Agency>;
  updateAgencyTalent?: Maybe<User>;
  updateBankAccount?: Maybe<BankAccount>;
  updateBookingCompany?: Maybe<BookingCompany>;
  updateInvoice?: Maybe<Invoice>;
  updateJob?: Maybe<Job>;
  updateJobAgencies?: Maybe<Array<JobAgency>>;
  updateJobTalents?: Maybe<Array<JobTalent>>;
  updateJobVerticals?: Maybe<Job>;
  updateLineItem?: Maybe<LineItem>;
  updateOwnUser?: Maybe<User>;
  updatePackage?: Maybe<Package>;
  updatePortfolioImageCategory?: Maybe<PortfolioImageCategory>;
  updateReview?: Maybe<Review>;
  updateSavedTalentCollection?: Maybe<SavedTalentCollection>;
  updateSavedTalentCollections?: Maybe<BookerProfile>;
  updateUser?: Maybe<User>;
  voidInvoice?: Maybe<SuccessResponse>;
  regenerateInvoice?: Maybe<Invoice>;
};

export type MutationActivateBloomArgs = {
  jobId: Scalars["ID"];
};

export type MutationAddLineItemArgs = {
  amount: Scalars["Float"];
  currency: Scalars["String"];
  description: Scalars["String"];
  jobTalentId: Scalars["ID"];
  kind: LineItemKind;
  manuallyPaid: Scalars["Boolean"];
};

export type MutationAddSavedTalentToCollectionsArgs = {
  collectionIds: Array<Scalars["ID"]>;
  talentId: Scalars["ID"];
};

export type MutationAddTalentArgs = {
  jobId: Scalars["ID"];
  talentIds: Array<Scalars["ID"]>;
};

export type MutationAgencyApproveJobArgs = {
  jobId: Scalars["ID"];
};

export type MutationAgencyRejectJobArgs = {
  jobId: Scalars["ID"];
};

export type MutationAgencyRespondOnBehalfArgs = {
  jobId: Scalars["ID"];
  jobTalentId: Scalars["ID"];
  rejectionNote?: InputMaybe<Scalars["String"]>;
  rejectionReason?: InputMaybe<JobTalentRejectionReason>;
  response: JobTalentStateEvent;
};

export type MutationApproveJobArgs = {
  jobId: Scalars["ID"];
};

export type MutationArchiveJobsArgs = {
  jobIds: Array<Scalars["ID"]>;
};

export type MutationAssignJobArgs = {
  assigneeId: Scalars["ID"];
  jobId: Scalars["ID"];
};

export type MutationAssociateAssetArgs = {
  assetId: Scalars["ID"];
};

export type MutationAwaitJobPaymentArgs = {
  jobId: Scalars["ID"];
  talentIdsToConfirm: Array<Scalars["ID"]>;
};

export type MutationCancelJobArgs = {
  cancellationNote?: InputMaybe<Scalars["String"]>;
  cancellationReason: JobCancellationReason;
  jobId: Scalars["ID"];
};

export type MutationCategorisePortfolioImagesArgs = {
  portfolioImageCategoryIds: Array<Scalars["ID"]>;
  portfolioImageIds: Array<Scalars["ID"]>;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type MutationCompleteJobArgs = {
  jobId: Scalars["ID"];
};

export type MutationConfirmJobInvoicePaymentArgs = {
  jobId: Scalars["ID"];
};

export type MutationConvertPackageToDraftArgs = {
  packageId: Scalars["ID"];
};

export type MutationConvertPackageToJobArgs = {
  packageId: Scalars["ID"];
};

export type MutationCreateAgencyArgs = {
  location: Scalars["String"];
  logo?: InputMaybe<FileUploadInput>;
  name: Scalars["String"];
  paymentsEmail?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateAgencyBookerNoteArgs = {
  body: Scalars["String"];
  bookerId: Scalars["ID"];
};

export type MutationCreateAgencyTalentArgs = {
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  country: Scalars["String"];
  county?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  postcode: Scalars["String"];
  talentInvitationToken?: InputMaybe<Scalars["String"]>;
  talentProfile: CreateTalentProfileInput;
};

export type MutationCreateAgencyTalentInvitationArgs = {
  email: Scalars["String"];
  published?: InputMaybe<Scalars["Boolean"]>;
  vertical: TalentVertical;
};

export type MutationCreateAgencyTalentNoteArgs = {
  body: Scalars["String"];
  talentId: Scalars["ID"];
};

export type MutationCreateAgentProfileArgs = {
  agency: CreateAgencyInput;
  role: AgentProfileRole;
};

export type MutationCreateAssetArgs = {
  assetableId?: InputMaybe<Scalars["ID"]>;
  assetableType?: InputMaybe<Scalars["String"]>;
  file: FileUploadInput;
  kind: Scalars["String"];
};

export type MutationCreateBookerArgs = {
  company: Scalars["String"];
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  headcountEstimate?: InputMaybe<CompanyHeadcountEstimate>;
  kind?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  phoneNumber: Scalars["String"];
  role?: InputMaybe<Scalars["String"]>;
  surfacedFrom?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateBookerProfileArgs = {
  company: Scalars["String"];
  headcountEstimate?: InputMaybe<CompanyHeadcountEstimate>;
  role?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateBookingCompanyNoteArgs = {
  body: Scalars["String"];
  bookingCompanyId: Scalars["ID"];
};

export type MutationCreateDeskRentalPaymentIntentArgs = {
  email: Scalars["String"];
  invoiceNumber: Scalars["String"];
};

export type MutationCreateJobArgs = {
  additionalNotes?: InputMaybe<Scalars["String"]>;
  affiliate?: InputMaybe<Scalars["String"]>;
  brand: Scalars["String"];
  cateringProvided?: InputMaybe<Scalars["Boolean"]>;
  deckFiles?: InputMaybe<Array<FileUploadInput>>;
  description: Scalars["String"];
  endDate: Scalars["ISO8601Date"];
  headcountEstimate?: InputMaybe<HeadcountEstimate>;
  jobVerticals?: InputMaybe<Array<CreateJobVerticalInput>>;
  kind: JobKind;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  nudityType?: InputMaybe<NudityInvolvement>;
  selfTapeRequired?: InputMaybe<Scalars["Boolean"]>;
  startDate: Scalars["ISO8601Date"];
  talentIds: Array<Scalars["ID"]>;
  travelExpenseCover?: InputMaybe<Scalars["Float"]>;
  usage: Scalars["String"];
  usages?: InputMaybe<Array<UsageInput>>;
  virtual?: InputMaybe<Scalars["Boolean"]>;
  spansMultipleDays: Scalars["Boolean"];
  allTravelFeesCovered?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateJobDisputeArgs = {
  jobId: Scalars["ID"];
  reportedBy: Scalars["ID"];
};

export type MutationCreateJobNoteArgs = {
  body: Scalars["String"];
  jobId: Scalars["ID"];
};

export type MutationCreateJobVerticalArgs = {
  jobId: Scalars["ID"];
  jobVertical: CreateJobVerticalInput;
};

export type MutationCreateMarketingAttributionArgs = {
  eventName: Scalars["String"];
  jobId?: InputMaybe<Scalars["Int"]>;
  utmData: UtmDataInput;
};

export type MutationCreatePackageArgs = {
  additionalNotes?: InputMaybe<Scalars["String"]>;
  bookerCompany: Scalars["String"];
  bookerEmail: Scalars["String"];
  bookerName: Scalars["String"];
  bookerPhoneNumber?: InputMaybe<Scalars["String"]>;
  brand?: InputMaybe<Scalars["String"]>;
  cateringProvided?: InputMaybe<Scalars["Boolean"]>;
  deckFiles?: InputMaybe<Array<FileUploadInput>>;
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  headcountEstimate?: InputMaybe<HeadcountEstimate>;
  isTemplate?: InputMaybe<Scalars["Boolean"]>;
  kind?: InputMaybe<JobKind>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  nudityType?: InputMaybe<NudityInvolvement>;
  packageVerticals?: InputMaybe<Array<CreatePackageVerticalInput>>;
  selfTapeRequired?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
  talentIds?: InputMaybe<Array<Scalars["ID"]>>;
  travelExpenseCover?: InputMaybe<Scalars["Float"]>;
  usage?: InputMaybe<Scalars["String"]>;
  virtual?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreatePaymentIntentArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationCreatePortfolioImageCategoryArgs = {
  name: Scalars["String"];
  talentProfileId: Scalars["ID"];
};

export type MutationCreatePortfolioImagesArgs = {
  files: Array<FileUploadInput>;
  portfolioImageCategoryId?: InputMaybe<Scalars["ID"]>;
  talentProfileId: Scalars["ID"];
};

export type MutationCreateSavedTalentCollectionArgs = {
  name: Scalars["String"];
};

export type MutationCreateTalentApplicationArgs = {
  assetIds: Array<Scalars["ID"]>;
  biography: Scalars["String"];
  bustSize?: InputMaybe<Scalars["Int"]>;
  chestSize?: InputMaybe<Scalars["Int"]>;
  city: Scalars["String"];
  complexion: Complexion;
  dateOfBirth: Scalars["ISO8601Date"];
  email: Scalars["String"];
  eyeColour: EyeColour;
  gender: Gender;
  hairColour: HairColour;
  hairStyle: HairStyle;
  height: Scalars["Int"];
  hipSize: Scalars["Int"];
  hobbies?: InputMaybe<Array<Scalars["String"]>>;
  instagramUsername?: InputMaybe<Scalars["String"]>;
  legLength: Scalars["Int"];
  location: Scalars["String"];
  name: Scalars["String"];
  pronouns: Pronouns;
  shoeSize: Scalars["Int"];
  suitSize?: InputMaybe<Scalars["Int"]>;
  waistSize: Scalars["Int"];
};

export type MutationCreateTalentProfileArgs = {
  additionalLocations?: InputMaybe<Array<TalentLocationInput>>;
  agencyId: Scalars["ID"];
  biography?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<FileUploadInput>;
  dateOfBirth: Scalars["ISO8601Date"];
  dietaryRequirements?: InputMaybe<Array<Scalars["String"]>>;
  gender: Gender;
  hobbies?: InputMaybe<Array<Scalars["String"]>>;
  instagramUsername?: InputMaybe<Scalars["String"]>;
  maxDayRate?: InputMaybe<Scalars["Int"]>;
  minDayRate?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  pastClients?: InputMaybe<Array<Scalars["String"]>>;
  portfolioImages?: InputMaybe<Array<PortfolioImageInput>>;
  primaryLocation: TalentLocationInput;
  pronouns?: InputMaybe<Pronouns>;
  published?: InputMaybe<Scalars["Boolean"]>;
  shrineAssets?: InputMaybe<Array<AssetFileUploadInput>>;
  socialMediaFields?: InputMaybe<Array<SocialMediaInput>>;
  vertical: TalentVertical;
  verticalData?: InputMaybe<Scalars["JSON"]>;
  website?: InputMaybe<Scalars["String"]>;
  yearsExperience?: InputMaybe<Scalars["Int"]>;
};

export type MutationCreateTalentReviewArgs = {
  createdAt?: InputMaybe<Scalars["ISO8601Date"]>;
  jobId: Scalars["ID"];
  rating: Scalars["Int"];
  reviewerId: Scalars["ID"];
  talentId: Scalars["ID"];
};

export type MutationCreateUserArgs = {
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  registeringAs: UserKind;
  ssoVerified?: InputMaybe<Scalars["Boolean"]>;
  surfacedFrom?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteAgencyArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAgencyBookerNoteArgs = {
  bookerId: Scalars["ID"];
  noteId: Scalars["ID"];
};

export type MutationDeleteAgencyTalentArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAgencyTalentContractArgs = {
  talentId: Scalars["ID"];
};

export type MutationDeleteAgencyTalentFeaturedAssetArgs = {
  talentId: Scalars["ID"];
};

export type MutationDeleteAgencyTalentNoteArgs = {
  noteId: Scalars["ID"];
  talentId: Scalars["ID"];
};

export type MutationDeleteAgencyUserArgs = {
  agencyId?: InputMaybe<Scalars["ID"]>;
  id: Scalars["ID"];
};

export type MutationDeleteAgentInvitationArgs = {
  agencyId?: InputMaybe<Scalars["ID"]>;
  id: Scalars["ID"];
};

export type MutationDeleteBookerInvitationArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteBookingCompanyBookerArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteBookingCompanyNoteArgs = {
  bookingCompanyId: Scalars["ID"];
  noteId: Scalars["ID"];
};

export type MutationDeleteJobDeckFileArgs = {
  deckFileId: Scalars["ID"];
  jobId: Scalars["ID"];
};

export type MutationDeleteJobNoteArgs = {
  jobId: Scalars["ID"];
  noteId: Scalars["ID"];
};

export type MutationDeleteJobVerticalArgs = {
  jobVerticalId: Scalars["ID"];
};

export type MutationDeleteLineItemArgs = {
  jobTalentId: Scalars["ID"];
  lineItemId: Scalars["ID"];
};

export type MutationDeleteOutboundPaymentArgs = {
  outboundPaymentId: Scalars["ID"];
};

export type MutationDeletePackageArgs = {
  packageId: Scalars["ID"];
};

export type MutationDeletePackageDeckFileArgs = {
  deckFileId: Scalars["ID"];
  packageId: Scalars["ID"];
};

export type MutationDeletePortfolioImageCategoryArgs = {
  portfolioImageCategoryId: Scalars["ID"];
};

export type MutationDeletePortfolioImagesArgs = {
  portfolioImageIds: Array<Scalars["ID"]>;
};

export type MutationDeleteSavedTalentCollectionArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTalentInvitationArgs = {
  id: Scalars["ID"];
};

export type MutationExchangeTokenArgs = {
  newPassword: Scalars["String"];
  token: Scalars["String"];
};

export type MutationExpireJobArgs = {
  jobId: Scalars["ID"];
};

export type MutationFinalizeInvoiceArgs = {
  invoiceId: Scalars["ID"];
  trustshareCheckoutId: Scalars["String"];
  trustshareInvoiceId: Scalars["String"];
};

export type MutationForgetTalentArgs = {
  talentId: Scalars["ID"];
};

export type MutationGenerateJobInvoiceForOutstandingLineItemsArgs = {
  jobId: Scalars["ID"];
};

export type MutationGenerateLatePaymentInvoiceArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationGeneratePresignedUrlArgs = {
  attachment: Scalars["String"];
  byteSize: Scalars["Int"];
  checksum: Scalars["String"];
  contentType: Scalars["String"];
  filename?: InputMaybe<Scalars["String"]>;
};

export type MutationGenerateUploadUrlArgs = {
  byteSize: Scalars["Int"];
  checksum: Scalars["String"];
  contentType: Scalars["String"];
  filename?: InputMaybe<Scalars["String"]>;
};

export type MutationInviteAgencyArgs = {
  email: Scalars["String"];
  hideAgencyTalent?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationInviteUserToAgencyArgs = {
  agencyId?: InputMaybe<Scalars["ID"]>;
  email: Scalars["String"];
};

export type MutationInviteUserToBookingCompanyArgs = {
  email: Scalars["String"];
};

export type MutationJobPostCompletionActionArgs = {
  action: JobPostCompletionAction;
  completed: Scalars["Boolean"];
  jobId: Scalars["ID"];
};

export type MutationMarkInvoicePaidArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationMarkNotificationReadArgs = {
  id: Scalars["ID"];
};

export type MutationOnboardAgentArgs = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  phoneNumber: Scalars["String"];
  token: Scalars["String"];
};

export type MutationOnboardBookerArgs = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  token: Scalars["String"];
};

export type MutationOnboardTalentArgs = {
  bankAccountAccountNumber?: InputMaybe<Scalars["String"]>;
  bankAccountIban?: InputMaybe<Scalars["String"]>;
  bankAccountKind?: InputMaybe<BankAccountKind>;
  bankAccountOwnerName?: InputMaybe<Scalars["String"]>;
  bankAccountSortCode?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  token: Scalars["String"];
};

export type MutationPublishPackageArgs = {
  packageId: Scalars["ID"];
};

export type MutationRebuildInvoiceArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationRegisterPushTokenArgs = {
  token: Scalars["String"];
};

export type MutationRejectJobArgs = {
  jobId: Scalars["ID"];
  rejectionNote?: InputMaybe<Scalars["String"]>;
  rejectionReason: JobRejectionReason;
};

export type MutationRemoveJobAssigneeArgs = {
  jobId: Scalars["ID"];
};

export type MutationRemovePortfolioImageCategorisationArgs = {
  portfolioImageCategoryId: Scalars["ID"];
  portfolioImageId: Scalars["ID"];
};

export type MutationRemoveSavedTalentFromCollectionsArgs = {
  collectionIds: Array<Scalars["ID"]>;
  talentId: Scalars["ID"];
};

export type MutationRemoveTalentArgs = {
  jobId: Scalars["ID"];
  jobTalentId: Scalars["ID"];
};

export type MutationReplacePackageTalentArgs = {
  packageId: Scalars["ID"];
  talentIds: Array<Scalars["ID"]>;
};

export type MutationReplaceTalentArgs = {
  jobId: Scalars["ID"];
  talentIds: Array<Scalars["ID"]>;
};

export type MutationRequestMoreInfoArgs = {
  jobId: Scalars["ID"];
  text: Scalars["String"];
};

export type MutationRequestTokenArgs = {
  email: Scalars["String"];
};

export type MutationResendOnboardEmailArgs = {
  userId: Scalars["ID"];
};

export type MutationResetGroupToDefaultArgs = {
  talentId: Scalars["ID"];
};

export type MutationResetInvoicePaymentMethodArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationResolveJobDisputeArgs = {
  id: Scalars["ID"];
};

export type MutationResubmitJobArgs = {
  jobDetails?: InputMaybe<UpdateJobDetailsInput>;
  jobId: Scalars["ID"];
  jobVerticals?: InputMaybe<Array<UpdateJobVerticalInput>>;
  talentIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MutationRetrieveTokenExpiryArgs = {
  token: Scalars["String"];
};

export type MutationSaveTalentArgs = {
  talentId: Scalars["ID"];
};

export type MutationSendPaymentVerificationEmailArgs = {
  userId: Scalars["ID"];
};

export type MutationSendTalentNudgeArgs = {
  jobId: Scalars["ID"];
  jobTalentId: Scalars["ID"];
};

export type MutationSetInvoiceFeeArgs = {
  amount: Scalars["Float"];
  feeKind: FeeKind;
  invoiceId: Scalars["ID"];
};

export type MutationSortPortfolioImagesArgs = {
  portfolioImageCategoryId?: InputMaybe<Scalars["ID"]>;
  sortOrder: Array<UpdateSortOrderInput>;
  talentProfileId?: InputMaybe<Scalars["ID"]>;
};

export type MutationTalentAcceptJobArgs = {
  jobId: Scalars["ID"];
};

export type MutationTalentJobResponseArgs = {
  jobId: Scalars["ID"];
  jobTalentId: Scalars["ID"];
  rejectionNote?: InputMaybe<Scalars["String"]>;
  rejectionReason?: InputMaybe<JobTalentRejectionReason>;
  response: JobTalentStateEvent;
};

export type MutationTalentRejectJobArgs = {
  jobId: Scalars["ID"];
  rejectionNote?: InputMaybe<Scalars["String"]>;
  rejectionReason?: InputMaybe<JobTalentRejectionReason>;
};

export type MutationUnarchiveJobsArgs = {
  jobIds: Array<Scalars["ID"]>;
};

export type MutationUpdateAgencyArgs = {
  agencyId?: InputMaybe<Scalars["ID"]>;
  assetIds?: InputMaybe<Array<Scalars["Int"]>>;
  bio?: InputMaybe<Scalars["String"]>;
  commissionPercentage?: InputMaybe<Scalars["Int"]>;
  coverPhoto?: InputMaybe<FileUploadInput>;
  hideTalent?: InputMaybe<Scalars["Boolean"]>;
  instagramUsername?: InputMaybe<Scalars["String"]>;
  isPrivate?: InputMaybe<Scalars["Boolean"]>;
  location?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<FileUploadInput>;
  name?: InputMaybe<Scalars["String"]>;
  pastClients?: InputMaybe<Array<Scalars["String"]>>;
  paymentsEmail?: InputMaybe<Scalars["String"]>;
  specialities?: InputMaybe<Array<Scalars["String"]>>;
  tiktokUsername?: InputMaybe<Scalars["String"]>;
  twitterUsername?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateAgencyTalentArgs = {
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  groupIds?: InputMaybe<Array<Scalars["ID"]>>;
  lastName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  talentId: Scalars["ID"];
  talentProfile?: InputMaybe<UpdateTalentProfileInput>;
};

export type MutationUpdateBankAccountArgs = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<BankAccountKind>;
  ownerName?: InputMaybe<Scalars["String"]>;
  sortCode?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type MutationUpdateBookingCompanyArgs = {
  billingContact?: InputMaybe<Scalars["String"]>;
  billingDetailsPresent?: InputMaybe<Scalars["Boolean"]>;
  companyEmailAddress?: InputMaybe<Scalars["String"]>;
  companyRegistrationNumber?: InputMaybe<Scalars["String"]>;
  freelancer?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  registeredAddress?: InputMaybe<AddressInput>;
  tradingAddress?: InputMaybe<AddressInput>;
};

export type MutationUpdateInvoiceArgs = {
  invoiceId: Scalars["ID"];
  paymentMethod?: InputMaybe<PaymentMethod>;
  poNumber?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateJobArgs = {
  additionalNotes?: InputMaybe<Scalars["String"]>;
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  affiliate?: InputMaybe<Scalars["String"]>;
  brand?: InputMaybe<Scalars["String"]>;
  cateringProvided?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  deckFiles?: InputMaybe<Array<FileUploadInput>>;
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  excludeVat?: InputMaybe<Scalars["Boolean"]>;
  headcountEstimate?: InputMaybe<HeadcountEstimate>;
  invoiceBillingAddress?: InputMaybe<Scalars["String"]>;
  invoiceBillingName?: InputMaybe<Scalars["String"]>;
  jobId: Scalars["ID"];
  kind?: InputMaybe<JobKind>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nudityType?: InputMaybe<NudityInvolvement>;
  postcode?: InputMaybe<Scalars["String"]>;
  selfTapeRequired?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
  travelExpenseCover?: InputMaybe<Scalars["Float"]>;
  usage?: InputMaybe<Scalars["String"]>;
  usages?: InputMaybe<Array<UsageInput>>;
  virtual?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateJobAgenciesArgs = {
  collections: Array<JobAgencyInput>;
};

export type MutationUpdateJobTalentsArgs = {
  collections: Array<JobTalentInput>;
};

export type MutationUpdateJobVerticalsArgs = {
  jobId: Scalars["ID"];
  jobVerticals: Array<UpdateJobVerticalInput>;
};

export type MutationUpdateLineItemArgs = {
  amount?: InputMaybe<Scalars["Float"]>;
  currency?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<LineItemKind>;
  lineItemId: Scalars["ID"];
  manuallyPaid?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateOwnUserArgs = {
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  bookerProfile?: InputMaybe<UpdateOwnBookerProfileInput>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  county?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  featuredAsset?: InputMaybe<FileUploadInput>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  talentProfile?: InputMaybe<UpdateOwnTalentProfileInput>;
};

export type MutationUpdatePackageArgs = {
  additionalNotes?: InputMaybe<Scalars["String"]>;
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  bookerCompany?: InputMaybe<Scalars["String"]>;
  bookerEmail?: InputMaybe<Scalars["String"]>;
  bookerName?: InputMaybe<Scalars["String"]>;
  brand?: InputMaybe<Scalars["String"]>;
  budget?: InputMaybe<Scalars["Float"]>;
  cateringProvided?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  deckFiles?: InputMaybe<Array<FileUploadInput>>;
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  headcountEstimate?: InputMaybe<HeadcountEstimate>;
  isTemplate?: InputMaybe<Scalars["Boolean"]>;
  jobLength?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<JobKind>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nudityType?: InputMaybe<NudityInvolvement>;
  packageId: Scalars["ID"];
  packageTalent?: InputMaybe<Array<PackageTalentInput>>;
  packageVerticals?: InputMaybe<Array<CreatePackageVerticalInput>>;
  postcode?: InputMaybe<Scalars["String"]>;
  selfTapeRequired?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
  talentRequired?: InputMaybe<Scalars["Int"]>;
  travelExpenseCover?: InputMaybe<Scalars["Float"]>;
  usage?: InputMaybe<Scalars["String"]>;
  virtual?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdatePortfolioImageCategoryArgs = {
  name: Scalars["String"];
  portfolioImageCategoryId: Scalars["ID"];
};

export type MutationUpdateReviewArgs = {
  hidden?: InputMaybe<Scalars["Boolean"]>;
  rating: Scalars["Int"];
  reviewId: Scalars["ID"];
};

export type MutationUpdateSavedTalentCollectionArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationUpdateSavedTalentCollectionsArgs = {
  collectionIds: Array<Scalars["ID"]>;
  talentId: Scalars["ID"];
};

export type MutationUpdateUserArgs = {
  bookerProfile?: InputMaybe<UpdateBookerProfileInput>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

export type MutationVoidInvoiceArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationRegenerateInvoiceArgs = {
  invoiceId: Scalars["ID"];
};

export type Note = {
  __typename?: "Note";
  body: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  user: User;
};

export type Notification = {
  __typename?: "Notification";
  category?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  job?: Maybe<Job>;
  subtitle: Scalars["String"];
  title: Scalars["String"];
  unread: Scalars["Boolean"];
};

/** The connection type for Notification. */
export type NotificationConnection = {
  __typename?: "NotificationConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NotificationEdge>>>;
  /** A list of nodes. */
  nodes: Array<Notification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: "NotificationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Notification;
};

export enum NudityInvolvement {
  BikiniLingerie = "BIKINI_LINGERIE",
  FullFrontal = "FULL_FRONTAL",
  FullFrontAndBack = "FULL_FRONT_AND_BACK",
  FullNudity = "FULL_NUDITY",
  PartialNudity = "PARTIAL_NUDITY",
}

export type NumericAttribute = {
  __typename?: "NumericAttribute";
  conversionType: ConversionType;
  name: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
};

export type OptionTypeWithColour = {
  __typename?: "OptionTypeWithColour";
  colour?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  value: Scalars["String"];
};

export type OrderingOption = {
  __typename?: "OrderingOption";
  direction: Scalars["String"];
  field: Scalars["String"];
  label: Scalars["String"];
};

export type OutboundPayment = {
  __typename?: "OutboundPayment";
  additionalPayments?: Maybe<Array<LineItem>>;
  amount: Scalars["Float"];
  bankedId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  currency: Scalars["String"];
  id: Scalars["ID"];
  jobTalent?: Maybe<JobTalent>;
  lineItems?: Maybe<Array<LineItem>>;
  paymentBatch?: Maybe<PaymentBatch>;
  remittanceLetterUrl?: Maybe<Scalars["String"]>;
  sentAt?: Maybe<Scalars["ISO8601DateTime"]>;
  state: OutboundPaymentState;
};

export enum OutboundPaymentState {
  Failed = "FAILED",
  Pending = "PENDING",
  Sent = "SENT",
}

export type Package = {
  __typename?: "Package";
  additionalNotes?: Maybe<Scalars["String"]>;
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  agency?: Maybe<Agency>;
  booker?: Maybe<PackageBooker>;
  bookerCompany?: Maybe<Scalars["String"]>;
  bookerEmail?: Maybe<Scalars["String"]>;
  bookerName?: Maybe<Scalars["String"]>;
  bookerPhoneNumber?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  /** @deprecated Vertical specific fields have been moved to the package vertical relation */
  budget?: Maybe<Scalars["Float"]>;
  cateringProvided?: Maybe<Scalars["Boolean"]>;
  city?: Maybe<Scalars["String"]>;
  clonedFrom?: Maybe<Package>;
  country?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  creator?: Maybe<User>;
  deckFiles?: Maybe<Array<DeckFile>>;
  /** @deprecated Vertical specific fields have been moved to the package vertical relation */
  deliverables?: Maybe<Array<Scalars["String"]>>;
  description?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  hasClones?: Maybe<Scalars["Boolean"]>;
  headcountEstimate?: Maybe<HeadcountEstimate>;
  id: Scalars["ID"];
  isTemplate?: Maybe<Scalars["Boolean"]>;
  /** @deprecated Vertical specific fields have been moved to the package vertical relation */
  jobLength?: Maybe<Scalars["String"]>;
  jobSlug?: Maybe<Scalars["String"]>;
  jobState?: Maybe<JobState>;
  kind?: Maybe<JobKind>;
  location?: Maybe<Scalars["String"]>;
  mayConvertToJob: Scalars["Boolean"];
  name: Scalars["String"];
  nudityType?: Maybe<NudityInvolvement>;
  packageTalent: Array<PackageTalent>;
  packageVerticals: Array<PackageVertical>;
  postcode?: Maybe<Scalars["String"]>;
  selfTapeRequired?: Maybe<Scalars["Boolean"]>;
  shortlistCount: Scalars["Int"];
  slug: Scalars["String"];
  spansMultipleDays: Scalars["Boolean"];
  startDate?: Maybe<Scalars["ISO8601Date"]>;
  state: PackageState;
  /** @deprecated Vertical specific fields have been moved to the package vertical relation */
  talentRequired?: Maybe<Scalars["Int"]>;
  travelExpenseCover?: Maybe<Scalars["Float"]>;
  usage?: Maybe<Scalars["String"]>;
  /** @deprecated Vertical specific fields have been moved to the package vertical relation */
  vertical?: Maybe<TalentVertical>;
  virtual: Scalars["Boolean"];
};

export type PackageBooker = {
  __typename?: "PackageBooker";
  email: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

/** The connection type for Package. */
export type PackageConnection = {
  __typename?: "PackageConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PackageEdge>>>;
  /** A list of nodes. */
  nodes: Array<Package>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type PackageEdge = {
  __typename?: "PackageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Package;
};

export enum PackageState {
  ConvertedToJob = "CONVERTED_TO_JOB",
  Draft = "DRAFT",
  Published = "PUBLISHED",
}

export type PackageTalent = {
  __typename?: "PackageTalent";
  id: Scalars["ID"];
  state: PackageTalentState;
  talent: User;
};

export type PackageTalentInput = {
  id: Scalars["ID"];
  state: PackageTalentState;
};

export enum PackageTalentState {
  Optioned = "OPTIONED",
  Pending = "PENDING",
}

export type PackageVertical = {
  __typename?: "PackageVertical";
  budget?: Maybe<Scalars["Float"]>;
  deliverables?: Maybe<Array<Scalars["String"]>>;
  id: Scalars["ID"];
  jobLength?: Maybe<Scalars["String"]>;
  talentRequired?: Maybe<Scalars["Int"]>;
  vertical: TalentVertical;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type PasswordValidation = {
  __typename?: "PasswordValidation";
  errors: Array<Scalars["String"]>;
  valid: Scalars["Boolean"];
};

export type PaymentBatch = {
  __typename?: "PaymentBatch";
  authorizationUrl?: Maybe<Scalars["String"]>;
  bankedId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  outboundPayments: Array<OutboundPayment>;
  sealedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  sentAt?: Maybe<Scalars["ISO8601DateTime"]>;
  state: PaymentBatchState;
};

export enum PaymentBatchState {
  Pending = "PENDING",
  Processing = "PROCESSING",
  Sealed = "SEALED",
  Sent = "SENT",
}

export type PaymentDetails = {
  __typename?: "PaymentDetails";
  accountNumber: Scalars["String"];
  iban: Scalars["String"];
  sortCode: Scalars["String"];
  swift: Scalars["String"];
};

export type PaymentIntentResponse = {
  __typename?: "PaymentIntentResponse";
  clientSecret: Scalars["String"];
};

export enum PaymentMethod {
  BankTransfer = "BANK_TRANSFER",
  Card = "CARD",
  Invoice = "INVOICE",
  OpenBanking = "OPEN_BANKING",
  TradeAccount = "TRADE_ACCOUNT",
}

export type PortfolioImage = {
  __typename?: "PortfolioImage";
  height?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  mediaUrl: Scalars["String"];
  sortWeight: Scalars["Int"];
  width?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

export type PortfolioImageCategorisationInput = {
  name: Scalars["String"];
  sortWeight: Scalars["Int"];
};

export type PortfolioImageCategory = {
  __typename?: "PortfolioImageCategory";
  id: Scalars["ID"];
  name: Scalars["String"];
  portfolioImages: Array<PortfolioImage>;
};

export type PortfolioImageInput = {
  categories: Array<PortfolioImageCategorisationInput>;
  fileData: FileUploadInput;
  sortWeight: Scalars["Int"];
};

export type PresignedUrl = {
  __typename?: "PresignedUrl";
  headers: Array<KeyValue>;
  key: Scalars["String"];
  url: Scalars["String"];
};

export type Profile = AgentProfile | BookerProfile | TalentProfile;

export type ProfileAttribute = {
  __typename?: "ProfileAttribute";
  field: Scalars["String"];
  icon: Scalars["String"];
  label: Scalars["String"];
  verticalSpecific?: Maybe<Scalars["Boolean"]>;
};

export enum Pronouns {
  HeHim = "HE_HIM",
  HeThey = "HE_THEY",
  SheHer = "SHE_HER",
  SheThey = "SHE_THEY",
  TheyThem = "THEY_THEM",
  ZeHir = "ZE_HIR",
}

/** The query root of this schema */
export type Query = {
  __typename?: "Query";
  agencies?: Maybe<Array<Agency>>;
  agency?: Maybe<Agency>;
  agentInvitation?: Maybe<AgentInvitation>;
  auth?: Maybe<Auth>;
  authSso?: Maybe<AuthSso>;
  booker?: Maybe<User>;
  bookerCompany?: Maybe<BookingCompany>;
  bookerInvitation?: Maybe<BookerInvitation>;
  bookers?: Maybe<UserConnection>;
  bookingCompanies?: Maybe<BookingCompanyConnection>;
  dayRateDistribution: Array<Scalars["Int"]>;
  enabledFeatures: Array<Scalars["String"]>;
  filterOptions: FilterOptions;
  invoices?: Maybe<InvoiceConnection>;
  /** Find a Job Record */
  job?: Maybe<Job>;
  jobTalentRejectionReasons: Array<KeyValue>;
  maximumMinDayRate: Scalars["Int"];
  me?: Maybe<User>;
  package?: Maybe<Package>;
  paymentBatch?: Maybe<PaymentBatch>;
  paymentBatches?: Maybe<Array<PaymentBatch>>;
  sendEmailVerification?: Maybe<SuccessResponse>;
  talent?: Maybe<User>;
  talentInvitation?: Maybe<TalentInvitation>;
  talentOnboarding?: Maybe<TalentOnboarding>;
  thirdPartyAgencies?: Maybe<AgencyConnection>;
  totalJobCount: Scalars["Int"];
  validatePassword: PasswordValidation;
  verifyEmail?: Maybe<SuccessResponse>;
  verticalConfigurations?: Maybe<Array<VerticalConfiguration>>;
  verticalTalentSearch?: Maybe<UserConnection>;
};

/** The query root of this schema */
export type QueryAgencyArgs = {
  id: Scalars["ID"];
};

/** The query root of this schema */
export type QueryAgentInvitationArgs = {
  token: Scalars["String"];
};

/** The query root of this schema */
export type QueryAuthArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

/** The query root of this schema */
export type QueryAuthSsoArgs = {
  idToken: Scalars["String"];
  nonce?: InputMaybe<Scalars["String"]>;
  providerId: Scalars["String"];
};

/** The query root of this schema */
export type QueryBookerArgs = {
  id: Scalars["ID"];
};

/** The query root of this schema */
export type QueryBookerCompanyArgs = {
  id: Scalars["ID"];
};

/** The query root of this schema */
export type QueryBookerInvitationArgs = {
  token: Scalars["String"];
};

/** The query root of this schema */
export type QueryBookersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["ID"]>;
  excludedIds?: InputMaybe<Array<Scalars["ID"]>>;
  first?: InputMaybe<Scalars["Int"]>;
  kind?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  nameOrEmail?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars["String"]>;
};

/** The query root of this schema */
export type QueryBookingCompaniesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  hasMultipleMembers?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars["String"]>;
};

/** The query root of this schema */
export type QueryDayRateDistributionArgs = {
  verticals?: InputMaybe<Array<TalentVertical>>;
};

/** The query root of this schema */
export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<InvoiceFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

/** The query root of this schema */
export type QueryJobArgs = {
  id: Scalars["ID"];
};

/** The query root of this schema */
export type QueryMaximumMinDayRateArgs = {
  verticals?: InputMaybe<Array<TalentVertical>>;
};

/** The query root of this schema */
export type QueryPackageArgs = {
  id: Scalars["ID"];
};

/** The query root of this schema */
export type QueryPaymentBatchArgs = {
  id: Scalars["ID"];
};

/** The query root of this schema */
export type QueryPaymentBatchesArgs = {
  sortColumn?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerySendEmailVerificationArgs = {
  email: Scalars["String"];
};

/** The query root of this schema */
export type QueryTalentArgs = {
  id: Scalars["ID"];
};

/** The query root of this schema */
export type QueryTalentInvitationArgs = {
  token: Scalars["String"];
};

/** The query root of this schema */
export type QueryTalentOnboardingArgs = {
  token: Scalars["String"];
};

/** The query root of this schema */
export type QueryThirdPartyAgenciesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<AgencyFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

/** The query root of this schema */
export type QueryValidatePasswordArgs = {
  password: Scalars["String"];
};

/** The query root of this schema */
export type QueryVerifyEmailArgs = {
  token: Scalars["String"];
};

/** The query root of this schema */
export type QueryVerticalTalentSearchArgs = {
  after?: InputMaybe<Scalars["String"]>;
  agencyId?: InputMaybe<Scalars["ID"]>;
  availableFrom?: InputMaybe<Scalars["ISO8601Date"]>;
  availableTo?: InputMaybe<Scalars["ISO8601Date"]>;
  before?: InputMaybe<Scalars["String"]>;
  bookmarked?: InputMaybe<Scalars["Boolean"]>;
  excludeAgencyIds?: InputMaybe<Array<Scalars["ID"]>>;
  excludedIds?: InputMaybe<Array<Scalars["Int"]>>;
  fields?: InputMaybe<Array<VerticalFieldInput>>;
  first?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Gender>;
  genders?: InputMaybe<Array<Gender>>;
  group?: InputMaybe<TalentSearchGroup>;
  groupId?: InputMaybe<Scalars["Int"]>;
  groupIds?: InputMaybe<Array<Scalars["Int"]>>;
  hobbies?: InputMaybe<Array<Scalars["String"]>>;
  inSameAgency?: InputMaybe<Scalars["Boolean"]>;
  isContactOpen?: InputMaybe<Scalars["Boolean"]>;
  isContactSelect?: InputMaybe<Scalars["Boolean"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
  locationContext?: InputMaybe<TalentSearchLocationContext>;
  locations?: InputMaybe<Array<Scalars["String"]>>;
  maxAge?: InputMaybe<Scalars["Int"]>;
  maxDayRate?: InputMaybe<Scalars["Int"]>;
  minAge?: InputMaybe<Scalars["Int"]>;
  minDayRate?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  random?: InputMaybe<Scalars["Boolean"]>;
  recentlyBooked?: InputMaybe<Scalars["Boolean"]>;
  seed?: InputMaybe<Scalars["Float"]>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars["String"]>;
  vertical?: InputMaybe<TalentVertical>;
  verticals?: InputMaybe<Array<TalentVertical>>;
};

export type Review = {
  __typename?: "Review";
  createdAt: Scalars["ISO8601DateTime"];
  hidden?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  job: Job;
  rating: Scalars["Int"];
  reviewable: Profile;
  reviewableName: Scalars["String"];
  reviewer: User;
};

/** The connection type for Review. */
export type ReviewConnection = {
  __typename?: "ReviewConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReviewEdge>>>;
  /** A list of nodes. */
  nodes: Array<Review>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ReviewEdge = {
  __typename?: "ReviewEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Review;
};

export type SavedTalent = {
  __typename?: "SavedTalent";
  bookerProfile: BookerProfile;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  labels: Array<Scalars["String"]>;
  talent: User;
  timeSinceBookmarked: Scalars["String"];
};

export type SavedTalentCollection = {
  __typename?: "SavedTalentCollection";
  id: Scalars["ID"];
  memberCount: Scalars["Int"];
  members: UserConnection;
  name: Scalars["String"];
  slug: Scalars["String"];
  talentVerticals: Array<TalentVertical>;
};

export type SavedTalentCollectionMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  vertical?: InputMaybe<TalentVertical>;
};

/** The connection type for SavedTalentCollection. */
export type SavedTalentCollectionConnection = {
  __typename?: "SavedTalentCollectionConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SavedTalentCollectionEdge>>>;
  /** A list of nodes. */
  nodes: Array<SavedTalentCollection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type SavedTalentCollectionEdge = {
  __typename?: "SavedTalentCollectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: SavedTalentCollection;
};

/** The connection type for SavedTalent. */
export type SavedTalentConnection = {
  __typename?: "SavedTalentConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SavedTalentEdge>>>;
  /** A list of nodes. */
  nodes: Array<SavedTalent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type SavedTalentEdge = {
  __typename?: "SavedTalentEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: SavedTalent;
};

export type SocialMedia = {
  __typename?: "SocialMedia";
  followerCount: Scalars["Int"];
  handle: Scalars["String"];
  platform: SocialMediaPlatform;
};

export type SocialMediaInput = {
  followerCount?: InputMaybe<Scalars["Int"]>;
  handle: Scalars["String"];
  platform: SocialMediaPlatform;
};

export enum SocialMediaPlatform {
  Instagram = "INSTAGRAM",
  Tiktok = "TIKTOK",
  Youtube = "YOUTUBE",
}

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type SortOrderInput = {
  column: Scalars["String"];
  direction: SortDirection;
};

export type SuccessResponse = {
  __typename?: "SuccessResponse";
  success: Scalars["Boolean"];
};

export type TalentApplication = {
  __typename?: "TalentApplication";
  assets: Array<Asset>;
  biography?: Maybe<Scalars["String"]>;
  bustSize: Scalars["Int"];
  chestSize: Scalars["Int"];
  city?: Maybe<Scalars["String"]>;
  complexion?: Maybe<Complexion>;
  dateOfBirth?: Maybe<Scalars["ISO8601Date"]>;
  email: Scalars["String"];
  eyeColour?: Maybe<EyeColour>;
  gender?: Maybe<Gender>;
  hairColour?: Maybe<HairColour>;
  hairStyle?: Maybe<HairStyle>;
  height?: Maybe<Scalars["Int"]>;
  hipSize?: Maybe<Scalars["Int"]>;
  hobbies?: Maybe<Array<Scalars["String"]>>;
  id: Scalars["ID"];
  instagramUsername?: Maybe<Scalars["String"]>;
  legLength?: Maybe<Scalars["Int"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  pronouns?: Maybe<Pronouns>;
  shoeSize?: Maybe<Scalars["Int"]>;
  suitSize: Scalars["Int"];
  waistSize?: Maybe<Scalars["Int"]>;
};

export type TalentInvitation = {
  __typename?: "TalentInvitation";
  createdAt: Scalars["ISO8601DateTime"];
  email: Scalars["String"];
  id: Scalars["ID"];
  published: Scalars["Boolean"];
  vertical: TalentVertical;
};

export type TalentLocation = {
  __typename?: "TalentLocation";
  id: Scalars["ID"];
  latitude?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  name: Scalars["String"];
};

export type TalentLocationInput = {
  name: Scalars["String"];
};

export type TalentOnboarding = {
  __typename?: "TalentOnboarding";
  email: Scalars["String"];
  name: Scalars["String"];
};

export type TalentProfile = {
  __typename?: "TalentProfile";
  additionalLocations: Array<TalentLocation>;
  age?: Maybe<Scalars["Int"]>;
  agency: Agency;
  assets: Array<Asset>;
  averageRating?: Maybe<Scalars["Float"]>;
  biography?: Maybe<Scalars["String"]>;
  bookers?: Maybe<Array<User>>;
  cardUrl?: Maybe<Scalars["String"]>;
  contractUrl?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>;
  dateOfBirth?: Maybe<Scalars["ISO8601DateTime"]>;
  dietaryRequirements?: Maybe<Array<Scalars["String"]>>;
  gender: Gender;
  hobbies?: Maybe<Array<Scalars["String"]>>;
  id: Scalars["ID"];
  instagramUsername?: Maybe<Scalars["String"]>;
  isAgencyFeatured?: Maybe<Scalars["Boolean"]>;
  isContactOpen?: Maybe<Scalars["Boolean"]>;
  isContactSelect?: Maybe<Scalars["Boolean"]>;
  isFeatured?: Maybe<Scalars["Boolean"]>;
  jobReview?: Maybe<Review>;
  lastPortfolioUpload?: Maybe<PortfolioImage>;
  lastPortfolioUploads?: Maybe<Array<PortfolioImage>>;
  /** @deprecated Please use primary_location instead */
  location: Scalars["String"];
  maxDayRate?: Maybe<Scalars["Int"]>;
  mediaCounts?: Maybe<Array<MissingMediaCount>>;
  minDayRate?: Maybe<Scalars["Int"]>;
  missingInformation?: Maybe<Array<Scalars["String"]>>;
  missingMeasurements?: Maybe<Array<Scalars["String"]>>;
  numericAttributes?: Maybe<Array<NumericAttribute>>;
  pastClients?: Maybe<Array<Scalars["String"]>>;
  portfolioImageCategories: Array<PortfolioImageCategory>;
  portfolioImages: Array<PortfolioImage>;
  primaryLocation: TalentLocation;
  pronouns?: Maybe<Pronouns>;
  published?: Maybe<Scalars["Boolean"]>;
  reviewableJobsForBooker?: Maybe<Array<Job>>;
  reviews?: Maybe<Array<Review>>;
  scrubbedBiography?: Maybe<Scalars["String"]>;
  selfManaged?: Maybe<Scalars["Boolean"]>;
  socialMediaFields?: Maybe<Array<SocialMedia>>;
  totalReviews: Scalars["Int"];
  twitterUsername?: Maybe<Scalars["String"]>;
  unhideContactSelectRequest?: Maybe<TalentProfileRequest>;
  vertical: TalentVertical;
  verticalData?: Maybe<Array<KeyValue>>;
  website?: Maybe<Scalars["String"]>;
  yearsExperience?: Maybe<Scalars["Int"]>;
};

export type TalentProfileJobReviewArgs = {
  jobId: Scalars["ID"];
};

export type TalentProfileLastPortfolioUploadsArgs = {
  count?: InputMaybe<Scalars["Int"]>;
};

export type TalentProfileReviewableJobsForBookerArgs = {
  bookerId: Scalars["ID"];
};

export type TalentProfileReviewsArgs = {
  sortOrder?: InputMaybe<SortOrderInput>;
};

export type TalentProfileRequest = {
  __typename?: "TalentProfileRequest";
  declineReason?: Maybe<Scalars["String"]>;
  requestReason?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type TalentProfileRequestInput = {
  declineReason?: InputMaybe<Scalars["String"]>;
  requestReason?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export enum TalentSearchGroup {
  HiddenProfiles = "HIDDEN_PROFILES",
  IncompleteMeasurements = "INCOMPLETE_MEASUREMENTS",
  IncompleteProfiles = "INCOMPLETE_PROFILES",
  LowMediaCount = "LOW_MEDIA_COUNT",
  PublishedProfiles = "PUBLISHED_PROFILES",
  WithoutSignedContract = "WITHOUT_SIGNED_CONTRACT",
  WithSignedContract = "WITH_SIGNED_CONTRACT",
}

export enum TalentSearchLocationContext {
  AdditionalOnly = "ADDITIONAL_ONLY",
  PrimaryAndAdditional = "PRIMARY_AND_ADDITIONAL",
  PrimaryOnly = "PRIMARY_ONLY",
}

export enum TalentVertical {
  FashionModel = "FASHION_MODEL",
  Hmua = "HMUA",
  Influencer = "INFLUENCER",
  Photographer = "PHOTOGRAPHER",
}

export type Timeslot = {
  __typename?: "Timeslot";
  from: Scalars["ISO8601DateTime"];
  to: Scalars["ISO8601DateTime"];
};

export type TimeslotInput = {
  from: Scalars["ISO8601DateTime"];
  to: Scalars["ISO8601DateTime"];
};

export type UpdateBookerProfileInput = {
  autoApprovalDisabled?: InputMaybe<Scalars["Boolean"]>;
  bookingCompany?: InputMaybe<UpdateBookingCompanyInput>;
  company?: InputMaybe<Scalars["String"]>;
  invoiceBillingAddress?: InputMaybe<Scalars["String"]>;
  invoiceBillingName?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type UpdateBookingCompanyInput = {
  waiveInvoiceFee?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateJobDetailsInput = {
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  affiliate?: InputMaybe<Scalars["String"]>;
  brand?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  deckFiles?: InputMaybe<Array<FileUploadInput>>;
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  kind?: InputMaybe<JobKind>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
  usage?: InputMaybe<Scalars["String"]>;
  virtual?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateJobVerticalInput = {
  budget?: InputMaybe<Scalars["Float"]>;
  deliverables?: InputMaybe<Array<Scalars["String"]>>;
  jobLength?: InputMaybe<Scalars["String"]>;
  talentRequired?: InputMaybe<Scalars["Int"]>;
  timeslots?: InputMaybe<Array<TimeslotInput>>;
  vertical: TalentVertical;
};

export type UpdateOwnBookerProfileInput = {
  company?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type UpdateOwnTalentProfileInput = {
  additionalLocations?: InputMaybe<Array<TalentLocationInput>>;
  biography?: InputMaybe<Scalars["String"]>;
  dietaryRequirements?: InputMaybe<Array<Scalars["String"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  instagramUsername?: InputMaybe<Scalars["String"]>;
  primaryLocation?: InputMaybe<TalentLocationInput>;
  pronouns?: InputMaybe<Pronouns>;
};

export type UpdateSortOrderInput = {
  id: Scalars["ID"];
  weight: Scalars["Int"];
};

export type UpdateTalentProfileInput = {
  additionalLocations?: InputMaybe<Array<TalentLocationInput>>;
  agency?: InputMaybe<Scalars["ID"]>;
  agencyId?: InputMaybe<Scalars["ID"]>;
  assets?: InputMaybe<Array<AssetFileUploadInput>>;
  biography?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<FileUploadInput>;
  dateOfBirth?: InputMaybe<Scalars["ISO8601Date"]>;
  dietaryRequirements?: InputMaybe<Array<Scalars["String"]>>;
  gender?: InputMaybe<Gender>;
  hobbies?: InputMaybe<Array<Scalars["String"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  instagramUsername?: InputMaybe<Scalars["String"]>;
  isAgencyFeatured?: InputMaybe<Scalars["Boolean"]>;
  isContactOpen?: InputMaybe<Scalars["Boolean"]>;
  isContactSelect?: InputMaybe<Scalars["Boolean"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]>;
  maxDayRate?: InputMaybe<Scalars["Int"]>;
  minDayRate?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  pastClients?: InputMaybe<Array<Scalars["String"]>>;
  primaryLocation?: InputMaybe<TalentLocationInput>;
  pronouns?: InputMaybe<Pronouns>;
  published?: InputMaybe<Scalars["Boolean"]>;
  socialMediaFields?: InputMaybe<Array<SocialMediaInput>>;
  unhideContactSelectRequest?: InputMaybe<TalentProfileRequestInput>;
  verticalData?: InputMaybe<Scalars["JSON"]>;
  website?: InputMaybe<Scalars["String"]>;
  yearsExperience?: InputMaybe<Scalars["Int"]>;
};

export type Usage = {
  __typename?: "Usage";
  form: Array<Scalars["String"]>;
  length: Scalars["String"];
  location: MediaLocation;
  mediaType: MediaType;
};

export type UsageInput = {
  form: Array<Scalars["String"]>;
  length: Scalars["String"];
  location: MediaLocation;
  mediaType: MediaType;
};

export type User = {
  __typename?: "User";
  activityStream?: Maybe<ActivityLogConnection>;
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  agency?: Maybe<Agency>;
  agencyUsers?: Maybe<Array<User>>;
  agentProfile?: Maybe<AgentProfile>;
  archivedJobs?: Maybe<JobsConnection>;
  assignedLiveJobCount?: Maybe<Scalars["Int"]>;
  availableFeatures?: Maybe<Array<Scalars["String"]>>;
  averageJobPay?: Maybe<Scalars["Int"]>;
  averageResponseTimeMinutes?: Maybe<Scalars["Int"]>;
  bankAccount?: Maybe<BankAccount>;
  bookerProfile?: Maybe<BookerProfile>;
  bookmarked?: Maybe<Scalars["Boolean"]>;
  city?: Maybe<Scalars["String"]>;
  completedJobCount?: Maybe<Scalars["Int"]>;
  convertedPackageCount?: Maybe<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>;
  dashboardUpcomingJobCount?: Maybe<Scalars["Int"]>;
  defaultGroup?: Maybe<Group>;
  email?: Maybe<Scalars["String"]>;
  featuredAsset?: Maybe<Asset>;
  firstName?: Maybe<Scalars["String"]>;
  groups?: Maybe<Array<Group>>;
  id: Scalars["ID"];
  invoices?: Maybe<Array<Invoice>>;
  jobs?: Maybe<JobsConnection>;
  jobsCalendarUrl?: Maybe<Scalars["String"]>;
  kinds?: Maybe<Array<Scalars["String"]>>;
  lastActionAt?: Maybe<Scalars["ISO8601DateTime"]>;
  lastName?: Maybe<Scalars["String"]>;
  liveJobs?: Maybe<JobsConnection>;
  name: Scalars["String"];
  nextJob?: Maybe<Job>;
  nextJobStartDate?: Maybe<Scalars["ISO8601Date"]>;
  notes?: Maybe<Array<Note>>;
  notifications: NotificationConnection;
  packageCount?: Maybe<Scalars["Int"]>;
  payments?: Maybe<Array<OutboundPayment>>;
  pendingJobCount?: Maybe<Scalars["Int"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  previousBrands?: Maybe<Array<Scalars["String"]>>;
  previousLocationByBrand?: Maybe<Scalars["String"]>;
  previousLocations?: Maybe<Array<Scalars["String"]>>;
  profile: Profile;
  registeringAs?: Maybe<Scalars["String"]>;
  shortlistCount?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  staffUsers: Array<Maybe<User>>;
  successfulJobCount?: Maybe<Scalars["Int"]>;
  talentProfile?: Maybe<TalentProfile>;
  talentProfiles?: Maybe<Array<TalentProfile>>;
  totalPay?: Maybe<Scalars["Int"]>;
  trustshareVerified?: Maybe<Scalars["Boolean"]>;
  upcomingJobCount?: Maybe<Scalars["Int"]>;
};

export type UserActivityStreamArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserArchivedJobsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserJobsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<JobFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

export type UserLiveJobsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<JobFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortOrder?: InputMaybe<SortOrderInput>;
};

export type UserNotificationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserPreviousLocationByBrandArgs = {
  brand: Scalars["String"];
};

export type UserTalentProfileArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: "UserConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: User;
};

export enum UserKind {
  Agent = "AGENT",
  Booker = "BOOKER",
  Talent = "TALENT",
}

export type UtmDataInput = {
  campaign: Scalars["String"];
  content?: InputMaybe<Scalars["String"]>;
  medium: Scalars["String"];
  source: Scalars["String"];
};

export type VerticalConfiguration = {
  __typename?: "VerticalConfiguration";
  advancedFilters: Array<Filter>;
  cardType: VerticalConfigurationCard;
  fields: Array<Field>;
  filters: Array<Filter>;
  id: TalentVertical;
  image: Scalars["String"];
  jobCreationSchema: JobCreationSchema;
  jobVerticalDetailsFields: Array<Component>;
  label: Scalars["String"];
  maxConfirmedTalent?: Maybe<Scalars["Int"]>;
  maxShortlistCount?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  newVertical: Scalars["Boolean"];
  orderingOptions: Array<OrderingOption>;
  profileAttributes: Array<ProfileAttribute>;
  selectionImage: Scalars["String"];
};

export enum VerticalConfigurationCard {
  Multiple = "MULTIPLE",
  Single = "SINGLE",
}

export type VerticalFieldInput = {
  name: Scalars["String"];
  value: Scalars["VerticalFieldValueScalar"];
};
