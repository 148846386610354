import { Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";

export const SectionHeader = ({
  step,
  label,
  isOptional,
}: {
  step?: string;
  label: string;
  isOptional?: boolean;
}) => (
  <Flex
    css={{
      mb: "$8",
      alignItems: "center",
      gap: "$4",
    }}
  >
    {step && (
      <Flex
        css={{
          backgroundColor: "$black",
          width: "30px",
          height: "30px",
          borderRadius: "$round",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text color="white" weight="bold">
          {step}
        </Text>
      </Flex>
    )}
    <Text variant="h4">{label}</Text>
    {isOptional && (
      <Text variant="meta" color="grey6">
        (Optional)
      </Text>
    )}
  </Flex>
);
