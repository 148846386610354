import { gql } from "@apollo/client";

export const AGENCIES_QUERY = gql`
  query Agencies {
    agencies {
      name
      id
      location
      logo {
        id
        mediaUrl
      }
    }
  }
`;
