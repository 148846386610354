import React from "react";
import { Filter } from "src/graphql/types";
import { MultiSelectFilter } from "src/ccl/filtering";
import { useURLStringArraySearchParam } from "src/hooks";

export const UrlStateMultiselectFilter = ({
  expanded,
  filter,
}: {
  expanded: boolean;
  filter: Filter;
}) => {
  const [values, setValues] = useURLStringArraySearchParam(filter.name);

  if (!filter.options) {
    return <></>;
  }
  return (
    <MultiSelectFilter
      wrapperCss={{ border: "unset" }}
      labelCss={{ px: "unset" }}
      filterCss={{ px: "unset" }}
      expanded={expanded || values.length > 0}
      searchable={filter.options.length > 8}
      options={filter.options}
      label={filter.label}
      labelSuffix={values.length > 0 ? values.length : undefined}
      onChange={setValues}
      onReset={() => setValues([])}
      values={values}
    />
  );
};
