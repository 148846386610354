import { useState } from "react";
import { escapeRegExp } from "lodash";
import { BaseFilter, BaseFilterProps } from "src/ccl/filtering/baseFilter";
import { Grid, Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches";
import checkIcon from "src/assets/icons/invertedCheck.svg";
import { TextInputPill } from "src/ccl/blocks";
import { useToggle } from "src/hooks";
import { ProfileRadioSelect } from "src/ccl/data-entry/input/ProfileRadioSelect";

export const OptionWrapper = styled("label", {
  display: "flex",
  position: "relative",
  paddingTop: "$2",
  paddingBottom: "$3",
  paddingRight: "$2",
  cursor: "pointer",
});

export const OptionIcon = styled("div", {
  width: "25px",
  height: "25px",
  flexShrink: 0,
  boxSizing: "border-box",
  "input:checked ~ &": {
    borderWidth: "4px",
    borderColor: "$black",
    borderStyle: "solid",
    backgroundImage: `url(${checkIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
});

export interface MultiSelectFilterOption {
  label: string;
  value: string;
  colour?: string;
  description?: string;
  icon?: string;
}

interface MultiSelectFilterProps extends BaseFilterProps {
  options: MultiSelectFilterOption[];
  values: string[];
  onChange: (nextValues: string[]) => void;
  searchable?: boolean;
}

export const MultiSelectFilter = ({
  options,
  values,
  onChange,
  onReset,
  searchable,
  ...filterProps
}: MultiSelectFilterProps) => {
  const addOption = (value: string) => {
    onChange([...values, value]);
  };
  const removeOption = (value: string) => {
    onChange(values.filter((o) => o.toString() !== value));
  };
  const stringValues = values.map((v) => v.toString());
  const [search, setSearch] = useState("");
  const [showMore, toggleShowMore] = useToggle();

  let visibleOptions = search
    ? options.filter(({ label }) =>
        label.match(new RegExp(escapeRegExp(search), "i")),
      )
    : options;
  if (!showMore) {
    visibleOptions = visibleOptions.slice(0, 8);
  }

  return (
    <BaseFilter
      {...filterProps}
      onCollapse={() => showMore && toggleShowMore()}
      onReset={values.length > 0 && onReset ? onReset : undefined}
    >
      {searchable && (
        <Box css={{ pb: "$5", ml: "-8px" }}>
          <TextInputPill
            withinModal={true}
            icon="search"
            name={filterProps.name?.toLowerCase() || ""}
            active={false}
            placeholder="Search"
            onChange={setSearch}
            value={search}
          />
        </Box>
      )}
      <Grid
        css={{ gridColumns: 1, gridGap: "16px", "@bp1": { gridColumns: 2 } }}
      >
        {visibleOptions.map((option) => (
          <ProfileRadioSelect
            key={option.value}
            option={option}
            selectedOption={
              stringValues?.includes(option.value) ? option.value : ""
            }
            setSelectedOption={(value) => {
              if (!stringValues?.includes(value)) {
                addOption(value);
              } else {
                removeOption(value);
              }
            }}
            name={option.label}
          />
        ))}
      </Grid>
      {options.length > 8 && !showMore && (
        <Text
          onClick={toggleShowMore}
          css={{
            textDecoration: "underline",
            color: "$grey6",
            "&:hover": { cursor: "pointer" },
          }}
        >
          See more
        </Text>
      )}
    </BaseFilter>
  );
};
