import React from "react";
import { AdvancedFilters } from "./AdvancedFilters";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { FilterContext, TalentVertical } from "src/graphql/types";
import { Button } from "src/ccl/navigation";

interface AdvancedFiltersModalProps extends ModalProps {
  loading: boolean;
  totalCount?: number;
  vertical?: TalentVertical;
  filterContext: FilterContext;
}

export const AdvancedFiltersModal = ({
  loading,
  vertical,
  totalCount,
  onClose,
  isOpen,
  filterContext,
}: AdvancedFiltersModalProps) => (
  <Modal
    withBodyPadding={false}
    isOpen={isOpen}
    showCloseButton={true}
    onClose={onClose}
  >
    <Box css={{ p: "$3" }}>
      <Text
        variant="h2"
        css={{
          pb: "$7",
        }}
      >
        Advanced filters
      </Text>
      {vertical && (
        <AdvancedFilters
          heightConstrained
          vertical={vertical}
          context={filterContext}
        />
      )}
      <Flex css={{ borderTop: "1px solid $grey1", flexDirection: "column" }}>
        <Box css={{ py: "$5" }}>
          <Flex css={{ justifyContent: "center", gap: "$5" }}>
            <Text color="grey4">
              {loading
                ? "..."
                : `${totalCount || "No"} ${
                    totalCount === 1 ? "creative" : "creatives"
                  } found`}
            </Text>
          </Flex>
        </Box>
        <Button
          onClick={onClose}
          variant="secondary"
          css={{
            "@bp2": { width: 149, alignSelf: "end" },
            py: "$5",
          }}
        >
          Close
        </Button>
      </Flex>
    </Box>
  </Modal>
);
