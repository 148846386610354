import { gql } from "@apollo/client";

export const UPDATE_OWN_USER_MUTATION = gql`
  mutation UpdateOwnUser(
    $name: String
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $county: String
    $country: String
    $postcode: String
    $featuredAsset: FileUploadInput
    $talentProfile: UpdateOwnTalentProfileInput
    $bookerProfile: UpdateOwnBookerProfileInput
  ) {
    updateOwnUser(
      name: $name
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      county: $county
      country: $country
      postcode: $postcode
      featuredAsset: $featuredAsset
      talentProfile: $talentProfile
      bookerProfile: $bookerProfile
    ) {
      id
      name
      firstName
      lastName
      email
      phoneNumber
      profile {
        ... on TalentProfile {
          id
          dietaryRequirements
          additionalLocations {
            name
          }
          primaryLocation {
            name
          }
          pronouns
        }
        ... on BookerProfile {
          id
          company
          website
          kind
          role
        }
      }
    }
  }
`;
