export const categorisationExplainers = {
  "New Faces": "Talent who are recently signed and new to modelling.",
  Development:
    "Still becoming established, but able to take on a variety of different briefs.",
  Mainboard:
    "Experienced in modelling and delivering high standards. Ready for all kinds of briefs.",
};

export const categorisationRequirements = {
  "New Faces": ["Income: Less than £500"],
  Development: ["Income: £500 and above", "Successful jobs: 2 or more"],
  Mainboard: ["Income: £1000 and above", "Successful jobs: 10 or more"],
};
