import React, { useRef } from "react";
import { NoJobsCard } from "./NoJobsCard";
import { LatestJobsCAJCard } from "./LatestJobsCAJCard";
import { BookerDashboardJobCard } from "src/ccl/jobs";
import { Box, Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { LinkButton } from "src/ccl/navigation";
import { Job } from "src/graphql/types";
import { sendToAmplitude } from "src/utils/analytics";
import { styled } from "src/ccl/stitches";
import { Carousel } from "src/ccl/blocks";

const Container = styled("div", {
  px: "$5",
  "@bp2": {
    mx: "auto",
    maxWidth: "1100px",
    px: "$7",
  },
  "@bp5": {
    maxWidth: "1440px",
  },
  variants: {
    section: {
      latestJobsHeader: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        pb: "$9",
        "@bp2": {
          flexDirection: "row",
          alignItems: "center",
        },
      },
    },
  },
});

interface LatestJobsProps {
  loading: boolean;
  jobs: Job[];
  fetchMore: () => void;
}

export const LatestJobs = ({ loading, fetchMore, jobs }: LatestJobsProps) => {
  const cardRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      css={{
        py: "$9",
        borderTop: "1px $grey3 solid",
        "@bp2": { py: "$15" },
      }}
    >
      <Container section="latestJobsHeader">
        <Text variant={{ "@initial": "h3", "@bp2": "h2" }}>Latest Jobs</Text>
        {jobs.length > 0 && (
          <Flex
            css={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              pt: "$5",
              "@bp2": { pt: "0" },
            }}
          >
            <LinkButton
              to="/dashboard/client/jobs"
              data-test-id="LJViewAllButton"
              onClick={() =>
                sendToAmplitude(
                  "client dashboard - clicks view all latest jobs cta",
                )
              }
            >
              View All
            </LinkButton>
            <Text
              variant="meta"
              css={{
                color: "$grey6",
                display: "block",
                px: "$6",
                "@bp2": { display: "none" },
              }}
            >
              or
            </Text>
            <LinkButton
              variant="primary"
              to="/jobs/new/discipline"
              css={{ display: "block", "@bp2": { display: "none" } }}
              data-test-id="LJCreateJobButton"
              onClick={() =>
                sendToAmplitude(
                  "booker dashboard - clicks create a job latest jobs cta",
                )
              }
            >
              Create Job
            </LinkButton>
          </Flex>
        )}
      </Container>
      {!loading && jobs.length === 0 ? (
        <Container>
          <NoJobsCard />
        </Container>
      ) : (
        <Box css={{ height: 350, "@bp2": { height: 296 } }}>
          <Carousel padSides={true} fetchMore={fetchMore}>
            <Box ref={cardRef}>
              <LatestJobsCAJCard />
            </Box>
            {jobs.map((job, i) => (
              <BookerDashboardJobCard job={job} key={i} />
            ))}
          </Carousel>
        </Box>
      )}
    </Box>
  );
};
