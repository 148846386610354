import React from "react";
import { RemittanceDownload } from "./RemittanceDownload";
import { Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { ExternalLink } from "src/ccl/navigation";
import { tokens } from "src/ccl/stitches/theme";
import { OutboundPayment, OutboundPaymentState } from "src/graphql/types";
import { formatCurrency } from "src/utils/currencyFormatter";
import { formatDate } from "src/utils/dates";
import { additionalFeeAmount, expenseAmount } from "src/utils/outboundPayment";

const PaymentFailed = ({
  payment,
  showLink,
}: {
  payment: OutboundPayment;
  showLink: boolean;
}) => (
  <>
    <Text variant="meta" color="red" css={{ ml: "$2" }}>
      Payment failed on {formatDate(payment.sentAt)}
    </Text>

    {showLink && (
      <ExternalLink
        to="mailto:info@contact.xyz"
        eventName="account:payments:paymentFailed:contact"
        css={{ fontSize: "$14" }}
      >
        Contact us
      </ExternalLink>
    )}
  </>
);

interface PaymentBreakdownProps {
  payment: OutboundPayment;
  color: keyof typeof tokens.colors;
  showPaymentFailedContactLink: boolean;
}

export const PaymentBreakdown = ({
  payment,
  color,
  showPaymentFailedContactLink,
}: PaymentBreakdownProps) => {
  const isFailed = payment.state === OutboundPaymentState.Failed;
  const isPaid = payment.state === OutboundPaymentState.Sent;
  const isPending = payment.state === OutboundPaymentState.Pending;

  const expenses = expenseAmount(payment);
  const additionalFees = additionalFeeAmount(payment);
  const totalAdditions = expenses + additionalFees;

  return (
    <>
      <Text
        css={{
          fontWeight: "$bold",
          color: color,
          mb: "$2",
        }}
      >
        {formatCurrency(payment.amount, payment.currency)}
      </Text>

      {totalAdditions > 0 && (
        <Text variant="meta" weight="bold" color={color} css={{ mb: "$2" }}>
          + {formatCurrency(totalAdditions, payment.currency)} expenses
        </Text>
      )}

      {isPending && (
        <Flex css={{ alignItems: "center", justifyContent: "flex-end" }}>
          <Icon variant="calendar" size={14} />
          <Text variant="meta" css={{ ml: "$2" }}>
            Upcoming
          </Text>
        </Flex>
      )}

      {isPaid && payment.sentAt && (
        <Text variant="meta" css={{ ml: "$2" }}>
          Paid on {formatDate(payment.sentAt)}
        </Text>
      )}

      {isFailed && payment.sentAt && (
        <PaymentFailed
          payment={payment}
          showLink={showPaymentFailedContactLink}
        />
      )}

      {payment.remittanceLetterUrl && (
        <Box css={{ display: "block", mt: "$2", "@bp2": { display: "none" } }}>
          <RemittanceDownload url={payment.remittanceLetterUrl} />
        </Box>
      )}
    </>
  );
};
