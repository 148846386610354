import React from "react";
import { capitalize } from "lodash";
import { Grid } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { Usage, MediaType, MediaLocation } from "src/graphql/types";
import { getEnumKeyByEnumValue } from "src/utils/getEnumKeyByEnumValue";

export const JobUsages = ({ usages }: { usages: Usage[] }) => (
  <Grid css={{ gridColumns: 3, columnGap: "82px", rowGap: "14px" }}>
    <Text variant="b2Bold">Media type</Text>
    <Text variant="b2Bold">Location</Text>
    <Text variant="b2Bold">Time</Text>
    {usages.map((usage, index) => (
      <React.Fragment key={index}>
        <Text variant="b2">
          {capitalize(usage.form.join(", "))} -{" "}
          {getEnumKeyByEnumValue(MediaType, usage.mediaType)}
        </Text>
        <Text variant="b2">
          {getEnumKeyByEnumValue(MediaLocation, usage.location)}
        </Text>
        <Text variant="b2">
          {usage.length.includes("1 ")
            ? usage.length.slice(0, -1)
            : usage.length}
        </Text>
      </React.Fragment>
    ))}
  </Grid>
);
