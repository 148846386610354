import React from "react";
import { Text } from "src/ccl/document/text";
import { tokens } from "src/ccl/stitches/theme";
import { JobTalentStateColors } from "src/graphql/colors";
import {
  Job,
  JobState,
  JobTalent,
  JobTalentState,
} from "src/graphql/generated/types";
import {
  jobCancelled,
  jobExpired,
  jobIsLive,
  jobRejected,
  jobApproved,
  jobConfirmed,
  jobAwaitingPayment,
} from "src/utils/job";

type Context = "fpa" | "tpa";

interface JobTalentStateLabelProps {
  jobTalent: JobTalent;
  job?: Job;
  fontSize?: keyof typeof tokens.fontSizes;
  context?: Context;
}

const stateLabels: Record<JobTalentState, string> = {
  [JobTalentState.Accepted]: "Available",
  [JobTalentState.AwaitingConfirmation]: "Available",
  [JobTalentState.Rejected]: "Unavailable",
  [JobTalentState.Pending]: "Response pending",
  [JobTalentState.RejectedByBooker]: "Released",
  [JobTalentState.Confirmed]: "Confirmed",
  [JobTalentState.Paid]: "Paid",
  [JobTalentState.Disputed]: "In dispute",
  [JobTalentState.PendingAgencyApproval]: "Pending agency approval",
};

const nonLiveJobLabels = (state: JobState) => {
  if (jobCancelled(state)) return "Job has been cancelled";
  if (jobExpired(state)) return "Job has expired";
  if (jobRejected(state)) return "Job was rejected";
  return "Job is complete";
};

export const JobTalentStateLabel = ({
  jobTalent,
  job,
  fontSize = 14,
  context = "fpa",
}: JobTalentStateLabelProps) => {
  if (context === "tpa") {
    return (
      <Text
        css={{
          fontSize: fontSize,
          color: `$${JobTalentStateColors[jobTalent.state]}`,
        }}
      >
        {stateLabels[jobTalent.state]}
      </Text>
    );
  }

  return (
    <>
      {job && !jobIsLive(job) ? (
        <Text
          css={{
            fontSize: fontSize,
            color: "$grey6",
          }}
        >
          {nonLiveJobLabels(job.state)}
        </Text>
      ) : job &&
        (jobApproved(job.state) ||
          jobConfirmed(job.state) ||
          jobAwaitingPayment(job.state)) ? (
        <Text
          css={{
            fontSize: fontSize,
            color: `$${JobTalentStateColors[jobTalent.state]}`,
          }}
        >
          {stateLabels[jobTalent.state]}
        </Text>
      ) : job && !jobConfirmed(job.state) ? (
        <Text
          css={{
            fontSize: fontSize,
            color: `$${JobTalentStateColors[jobTalent.state]}`,
          }}
        >
          Will be notified when job is approved
        </Text>
      ) : null}
    </>
  );
};
