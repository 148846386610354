import { persist, Action, action, Computed, computed } from "easy-peasy";
import { TalentFormValues } from "src/components/dashboards/agents/talentManagement/forms/formConfigV2";
import {
  AssetKind,
  DraftPortfolioImage,
  PortfolioImageCategory,
} from "src/graphql/types";

type Status = "profile" | "portfolio";
export type FeaturedAsset = { kind: AssetKind; mediaUrl: string };
type ShrineAssets = { kind: AssetKind; file: {} };

export interface DraftTalentModel {
  details: TalentFormValues | null;
  featuredAsset: FeaturedAsset | null;
  shrineAssets: ShrineAssets | null;
  portfolioImages: DraftPortfolioImage[] | null;
  portfolioCategories: PortfolioImageCategory[] | null;
  setShrineAssets: Action<DraftTalentModel, ShrineAssets>;
  setFeaturedAsset: Action<DraftTalentModel, FeaturedAsset | null>;
  setDetails: Action<DraftTalentModel, TalentFormValues | null>;
  setPortfolioImages: Action<DraftTalentModel, DraftPortfolioImage[] | null>;
  addPortfolioImages: Action<DraftTalentModel, DraftPortfolioImage[]>;
  setPortfolioCategories: Action<DraftTalentModel, PortfolioImageCategory[]>;
  addPortfolioCategory: Action<DraftTalentModel, PortfolioImageCategory>;
  updatePortfolioCategory: Action<DraftTalentModel, PortfolioImageCategory>;
  deletePortfolioCategory: Action<DraftTalentModel, PortfolioImageCategory>;
  reset: Action<DraftTalentModel>;
  status: Computed<DraftTalentModel, Status>;
}

const persistInLocalStorage = (model: DraftTalentModel) =>
  persist(model, { storage: "localStorage" });

export const draftTalentModel: DraftTalentModel = persistInLocalStorage({
  details: null,
  featuredAsset: null,
  portfolioImages: null,
  portfolioCategories: null,
  setFeaturedAsset: action((state, payload) => {
    state.featuredAsset = payload;
  }),
  setDetails: action((state, payload) => {
    state.details = payload;
  }),
  setPortfolioImages: action((state, payload) => {
    state.portfolioImages = payload;
  }),
  setPortfolioCategories: action((state, payload) => {
    state.portfolioCategories = payload;
  }),
  addPortfolioImages: action((state, payload) => {
    state.portfolioImages = (state.portfolioImages || []).concat(payload);
  }),
  addPortfolioCategory: action((state, payload) => {
    state.portfolioCategories
      ? state.portfolioCategories.push(payload)
      : (state.portfolioCategories = [payload]);
  }),
  updatePortfolioCategory: action((state, payload) => {
    state.portfolioCategories = state.portfolioCategories?.map((category) => {
      if (category.name !== payload.name) {
        return category;
      }
      return payload;
    }) || [payload];
  }),
  deletePortfolioCategory: action((state, payload) => {
    state.portfolioCategories =
      state.portfolioCategories?.filter(({ name }) => name !== payload.name) ||
      [];
  }),
  reset: action((state) => {
    state.details = null;
    state.featuredAsset = null;
    state.shrineAssets = null;
    state.portfolioImages = null;
    state.portfolioCategories = null;
  }),
  status: computed((state) => {
    if (!state.details || state.details.email === "") {
      return "profile";
    }
    return "portfolio";
  }),
  shrineAssets: null,
  setShrineAssets: action((state, payload) => {
    state.shrineAssets = payload;
  }),
});
