import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Job,
  JobTalent,
  JobTalentState,
  MutationUpdateJobArgs,
  JobAddressDetails,
  TalentProfile,
  MutationAwaitJobPaymentArgs,
  Mutation,
  MutationConfirmJobInvoicePaymentArgs,
} from "src/graphql/types";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Icon, Modal, ModalProps, Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { Field, Input } from "src/ccl/data-entry";
import { ValidationBlock } from "src/ccl/feedback";
import { jobTalentWithState } from "src/utils/jobTalent";
import {
  AWAIT_JOB_PAYMENT_MUTATION,
  UPDATE_JOB_CONFIRM_MUTATION,
  CONFIRM_JOB_INVOICE_PAYMENT_MUTATION,
} from "src/graphql/mutations";
import { ShortlistVerticals } from "src/components/ShortlistVerticals";
import { getTalentVerticals } from "src/utils/user";
import { AGENT_DASHBOARD_SHOW_JOB_QUERY } from "src/graphql/queries";
import { useFeatures } from "src/hooks";

interface JobConfirmModalProps extends ModalProps {
  job: Job;
  onConfirmJob?: () => void;
  onCancel?: () => void;
}

export const JobConfirmModal = ({
  job,
  onConfirmJob,
  onCancel,
  ...modalProps
}: JobConfirmModalProps) => {
  const { featureEnabled } = useFeatures();
  const [jobDetails, setJobDetails] = useState<JobAddressDetails>({
    addressLine1: job.addressLine1 || "",
    addressLine2: job.addressLine2 || "",
    city: job.city || "",
    country: job.country || "",
    postcode: job.postcode || "",
  });
  const jobTalent = jobTalentWithState(job.talent, JobTalentState.Accepted);
  const [selectedJobTalent, setSelectedJobTalent] = useState<JobTalent[]>([]);

  const valid =
    selectedJobTalent.length > 0 &&
    (job.virtual ||
      [
        jobDetails.addressLine1,
        jobDetails.city,
        jobDetails.country,
        jobDetails.postcode,
      ].every((el) => el !== undefined && el !== ""));

  const [updateJob, { loading: updateJobLoading }] =
    useMutation<MutationUpdateJobArgs>(UPDATE_JOB_CONFIRM_MUTATION);

  const [
    confirmJobInvoicePayment,
    {
      loading: confirmJobInvoicePaymentLoading,
      error: confirmJobInvocePaymentError,
    },
  ] = useMutation<Mutation, MutationConfirmJobInvoicePaymentArgs>(
    CONFIRM_JOB_INVOICE_PAYMENT_MUTATION,
    {
      variables: {
        jobId: job.id || "",
      },
      refetchQueries: [
        {
          query: AGENT_DASHBOARD_SHOW_JOB_QUERY,
          variables: {
            jobId: job.id,
          },
        },
      ],
    },
  );

  const [
    awaitJobPayment,
    { error: awaitJobPaymentError, loading: awaitJobPaymentLoading },
  ] = useMutation<Mutation, MutationAwaitJobPaymentArgs>(
    AWAIT_JOB_PAYMENT_MUTATION,
    {
      variables: {
        jobId: job.id,
        talentIdsToConfirm: selectedJobTalent.map((jt) => jt.id),
      },
    },
  );

  const isLoading =
    awaitJobPaymentLoading ||
    updateJobLoading ||
    confirmJobInvoicePaymentLoading;
  const buttonDisabled = !valid || isLoading;

  const showError = awaitJobPaymentError || confirmJobInvocePaymentError;

  const talentToConfirm = jobTalent.map((jt) => jt.talent);
  const talentVerticals = getTalentVerticals(talentToConfirm);

  const handleConfirm = async () => {
    await awaitJobPayment();
    if (!showError) {
      await confirmJobInvoicePayment();
    }
  };

  return (
    <Modal
      {...modalProps}
      width={job.virtual ? undefined : "super-wide"}
      title="Confirm job"
      showCloseButton={true}
      onClose={onCancel}
    >
      {showError && (
        <ValidationBlock
          variant="error"
          title="Oops, there's been a problem. Please try again later."
          css={{ mt: "$5" }}
        />
      )}

      <Grid
        css={{
          gridColumns: 1,
          "@bp2": {
            gap: "$6",
            gridColumns: job.virtual ? 1 : 2,
          },
        }}
      >
        <Flex
          css={{
            border: "1px solid $grey3",
            flexDirection: "column",
            px: "$8",
            pt: "$6",
            mb: "$6",
            ...(featureEnabled("unified_dashboards") && {
              borderRadius: "$lg",
              px: "$7",
              py: "$6",
            }),
            "@bp2": { mb: "$0" },
          }}
        >
          <Text
            variant={!featureEnabled("unified_dashboards") ? "mini" : "title"}
            css={{
              mb: "$6",
            }}
          >
            Select which talents to confirm
          </Text>
          <Box
            css={{
              maxHeight: "477px",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "$grey3",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "$grey2",
                borderRadius: "5px",
                height: "81px",
              },
            }}
          >
            <Box css={{ pr: "$6" }}>
              {jobTalent && (
                <ShortlistVerticals
                  checkboxSize={{ width: 24, height: 24 }}
                  talent={talentToConfirm}
                  talentVerticals={talentVerticals}
                  jobSlug={job.slug}
                  isCheckable={() => true}
                  isChecked={(talent) =>
                    selectedJobTalent
                      .map((jt) => jt.talent.id)
                      .includes(talent.id)
                  }
                  onCheck={(talent, checked) => {
                    if (checked) {
                      const jt = jobTalent.find(
                        (jt) => jt.talent.id === talent.id,
                      );
                      if (jt) {
                        setSelectedJobTalent([...selectedJobTalent, jt]);
                      }
                    } else {
                      setSelectedJobTalent(
                        selectedJobTalent.filter(
                          (t) => t.talent.id !== talent.id,
                        ),
                      );
                    }
                  }}
                  getSubHeading={(t) => {
                    const profile = t.profile as TalentProfile;
                    let subtext = profile.location;
                    if (t.country) {
                      subtext += t.country === "GB" ? ", UK" : `, ${t.country}`;
                    }
                    return <Text css={{ color: "$grey6" }}>{subtext}</Text>;
                  }}
                />
              )}
            </Box>
          </Box>
        </Flex>
        {!job.virtual && !featureEnabled("unified_dashboards") && (
          <Box css={{ backgroundColor: "$grey1", p: "$6" }}>
            <Text variant="mini" css={{ mb: "$3" }}>
              Confirm the full address
            </Text>

            <Text>Enter the address the creatives should travel to.</Text>

            <Box css={{ mt: "$9" }}>
              <Field label="Address line 1">
                <Input
                  type="text"
                  id="addressLine1"
                  value={jobDetails.addressLine1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setJobDetails({
                      ...jobDetails,
                      addressLine1: e.target.value,
                    })
                  }
                />
              </Field>
              <Field label="Address line 2">
                <Input
                  type="text"
                  id="addressLine2"
                  value={jobDetails.addressLine2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setJobDetails({
                      ...jobDetails,
                      addressLine2: e.target.value,
                    })
                  }
                />
              </Field>
              <Flex
                css={{
                  gap: "$6",
                  flexDirection: "column",
                  "@bp2": { flexDirection: "row" },
                }}
              >
                <Box css={{ flex: "1" }}>
                  <Field label="City">
                    <Input
                      type="text"
                      id="city"
                      value={jobDetails.city}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setJobDetails({ ...jobDetails, city: e.target.value })
                      }
                    />
                  </Field>
                </Box>
                <Box css={{ flex: "1" }}>
                  <Field label="Postcode">
                    <Input
                      type="text"
                      id="postcode"
                      value={jobDetails.postcode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setJobDetails({
                          ...jobDetails,
                          postcode: e.target.value,
                        })
                      }
                    />
                  </Field>
                </Box>
              </Flex>
              <Field label="Country">
                <Input
                  type="text"
                  id="country"
                  value={jobDetails.country}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setJobDetails({ ...jobDetails, country: e.target.value })
                  }
                />
              </Field>
            </Box>
          </Box>
        )}

        {/* For Unified Dashboard */}
        {!job.virtual && featureEnabled("unified_dashboards") && (
          <Box
            css={{
              px: "$7",
              py: "$6",
              border: "1px solid $grey3",
              borderRadius: "$lg",
            }}
          >
            <Text variant="title" css={{ mb: "$3" }}>
              Confirm the full address
            </Text>

            <Text variant="b2">
              Enter the address the creatives should travel to.
            </Text>

            <Box css={{ mt: "$9" }}>
              {/* Address Line 1 */}
              <Field label="Address Line 1" labelCss={{ mb: "$5" }}>
                <Input
                  type="text"
                  id="addressLine1"
                  variant="rebrand"
                  placeholder="Address Line 1"
                  css={{ borderColor: "$grey2" }}
                  value={jobDetails.addressLine1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setJobDetails({
                      ...jobDetails,
                      addressLine1: e.target.value,
                    })
                  }
                />
              </Field>

              {/* Address Line 2 */}
              <Field label="Address Line 2" labelCss={{ mb: "$5" }}>
                <Input
                  type="text"
                  id="addressLine2"
                  variant="rebrand"
                  placeholder="Address Line 2"
                  css={{ borderColor: "$grey2" }}
                  value={jobDetails.addressLine2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setJobDetails({
                      ...jobDetails,
                      addressLine2: e.target.value,
                    })
                  }
                />
              </Field>

              {/* Country */}
              <Field label="Country" labelCss={{ mb: "$5" }}>
                <Input
                  type="text"
                  id="country"
                  variant="rebrand"
                  placeholder="Country"
                  css={{ borderColor: "$grey2" }}
                  value={jobDetails.country}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setJobDetails({ ...jobDetails, country: e.target.value })
                  }
                />
              </Field>

              {/* City and Postal Code */}
              <Flex
                css={{
                  gap: "$6",
                  flexDirection: "column",
                  "@bp2": { flexDirection: "row" },
                }}
              >
                <Box css={{ flex: "1" }}>
                  <Field label="Town/City" labelCss={{ mb: "$5" }}>
                    <Input
                      type="text"
                      id="city"
                      variant="rebrand"
                      placeholder="Town/City"
                      css={{ borderColor: "$grey2" }}
                      value={jobDetails.city}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setJobDetails({ ...jobDetails, city: e.target.value })
                      }
                    />
                  </Field>
                </Box>
                <Box css={{ flex: "1" }}>
                  <Field label="Postcode" labelCss={{ mb: "$5" }}>
                    <Input
                      type="text"
                      id="postcode"
                      variant="rebrand"
                      placeholder="Postcode"
                      css={{ borderColor: "$grey2" }}
                      value={jobDetails.postcode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setJobDetails({
                          ...jobDetails,
                          postcode: e.target.value,
                        })
                      }
                    />
                  </Field>
                </Box>
              </Flex>
            </Box>
          </Box>
        )}
      </Grid>

      {/* Buttons */}
      <Flex
        css={{
          flexDirection: "column-reverse",
          mt: "$9",
          justifyContent: "space-between",
          "@bp2": { flexDirection: "row" },
        }}
      >
        <Button
          variant="primaryPillBordered"
          onClick={() => {
            modalProps?.onClose?.();
          }}
          css={{ padding: "21px 20px" }}
        >
          Cancel
        </Button>

        <Button
          variant="primaryPillV2"
          onClick={async () => {
            if (!job.virtual) {
              await updateJob({
                variables: {
                  jobId: job.id,
                  ...jobDetails,
                },
              });
            }

            await handleConfirm();

            if (showError) {
              return;
            }

            onConfirmJob?.();
          }}
          disabled={buttonDisabled}
          css={{ padding: "21px 32px" }}
        >
          <Flex css={{ justifyContent: "center" }}>
            <Icon
              variant="check"
              size={16}
              color={buttonDisabled ? "grey6" : "white"}
            />
            <Text
              color={buttonDisabled ? "grey6" : "white"}
              css={{
                textTransform: "uppercase",
                fontFamily: "$sansWide",
                ml: "$2",
                fontSize: "$12",
                lineHeight: "$16",
              }}
            >
              {isLoading ? "Confirming job..." : "Confirm job"}
            </Text>
          </Flex>
        </Button>
      </Flex>
    </Modal>
  );
};
