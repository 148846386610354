import React, { useState } from "react";
import { AvailableIcon, Icon } from "src/ccl/document/icon";
import { Box, Flex } from "src/ccl/layout";
import { styled } from "src/ccl/stitches";
import { Text } from "src/ccl/document";

interface ExpandingBoxProps {
  icon: AvailableIcon;
  title: React.ReactNode;
  children: React.ReactNode;
}

const Container = styled(Box, {
  backgroundColor: "$grey1",
  padding: "$3",

  variants: {
    height: {
      expanded: {
        "svg.toggle": {
          transform: "rotate(180deg)",
        },
      },
      collapsed: {},
    },
  },
});

const Content = styled(Box, {
  ml: "$10",
  pr: "$3",
  transition: "max-height 0.3s ease, opacity 0.6s ease",
  variants: {
    visibility: {
      visible: {
        opacity: 1,
        maxHeight: "500px",
      },
      hidden: {
        maxHeight: 0,
        overflow: "hidden",
        opacity: 0,
      },
    },
  },
});

export const ExpandingBox = ({ icon, children, title }: ExpandingBoxProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Container height={expanded ? "expanded" : "collapsed"}>
      <Flex
        css={{
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setExpanded((expanded) => !expanded);
        }}
      >
        <Icon variant={icon} size={20} color="grey6" css={{ mr: "$5" }} />
        <Text variant="meta" color="grey6">
          {title}
        </Text>
        <Icon
          className="toggle"
          variant="chevronDown"
          size={14}
          color="grey6"
          css={{
            ml: "auto",
            mr: "$3",
            transition: "0.4s ease all",
          }}
        />
      </Flex>

      <Content visibility={expanded ? "visible" : "hidden"}>
        <Box css={{ pt: "$3" }}>{children}</Box>
      </Content>
    </Container>
  );
};
