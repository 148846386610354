import { gql } from "@apollo/client";

export const LINE_ITEM_FRAGMENT = gql`
  fragment LineItemDetails on LineItem {
    id
    amount
    currency
    description
    kind
    manuallyPaid
    outboundPayment {
      id
      state
      paymentBatch {
        id
        state
      }
    }
  }
`;
