import React from "react";
import Select, { OnChangeValue } from "react-select";
import AsyncSelect from "react-select/async";
import { DropdownIndicator } from "./SelectIcons";
import { controlStyles, selectStyles } from "./SelectStyles";
import { PillContainer, PillRemoveButton } from "src/ccl/blocks";
import { Box, Flex } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";
import { Text } from "src/ccl/document";

interface Option {
  value: string;
  label: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Style = Record<string, any>;

interface MultiSelectProps {
  id?: string;
  name: string;
  options?: Option[];
  loadOptions?: (inputValue: string) => Promise<Option[]>;
  initialValues?: Option[];
  label?: string;
  onChange: (values: string[]) => void;
  onInputChange?: (value: string) => void;
  maxOptions?: number;
  noOptionsText?: string;
  maxOptionsText?: string;
  hideIndicator?: boolean;
  placeholder?: string;
  optional?: boolean;
  variant?: "rebrand";
  value?: Option[];
}

const styles = (variant?: string) => {
  return {
    option: (
      provided: {},
      state: { isFocused: boolean; isSelected: boolean },
    ) => ({
      ...provided,
      color: tokens.colors.black,
      backgroundColor: state.isFocused
        ? tokens.colors.grey2
        : tokens.colors.white,
      ":active": {
        backgroundColor: tokens.colors.white,
      },
    }),
    ...selectStyles,
    control: (
      styles: Style,
      state: { isFocused: boolean; hasValue: boolean },
    ) => ({
      ...styles,
      ...controlStyles,
      border:
        variant === "rebrand"
          ? `1.5px solid ${tokens.colors.grey2}`
          : `2px solid ${tokens.colors.black}`,
      borderRadius: variant === "rebrand" ? "8px" : undefined,
      ":hover": {
        border:
          variant === "rebrand" && !state.hasValue
            ? `2px solid ${tokens.colors.grey4}`
            : `2px solid ${tokens.colors.black}`,
      },
      outline: state.isFocused
        ? `2px solid ${tokens.colors.black}`
        : variant === "rebrand" && state.hasValue
        ? `2px solid ${tokens.colors.black}`
        : "none",
    }),
    multiValueLabel: (styles: Style) => ({
      ...styles,
      color: tokens.colors.grey6,
      fontSize: tokens.fontSizes[14],
      padding: "4px 8px",
    }),
    valueContainer: (styles: Style) => ({
      ...styles,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 8,
    }),
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiValueRemove = (props: any) => (
  <PillRemoveButton icon="cross" {...props.innerProps} />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiValueContainer = (props: any) => (
  <PillContainer variant="regular" {...props}>
    {props.children}
  </PillContainer>
);

export const MultiSelect = ({
  name,
  id = name,
  options,
  loadOptions,
  label,
  onChange,
  onInputChange,
  initialValues = [],
  maxOptions,
  maxOptionsText,
  noOptionsText,
  hideIndicator,
  placeholder = "Select...",
  optional,
  variant,
  value,
}: MultiSelectProps) => {
  const handleOnChange = (value: OnChangeValue<Option, true>) => {
    if (!value) {
      onChange([]);
    } else if (value instanceof Array) {
      onChange((value as Option[]).map((v) => v.value));
    } else {
      onChange([(value as Option).value]);
    }
  };

  return (
    <Box css={{ width: "100%" }}>
      <Flex
        css={{
          alignItems: "center",
          justifyContent: "space-between",
          pb: "$5",
        }}
      >
        {label && <Text variant="b2Bold">{label}</Text>}
        {optional && (
          <Text variant="meta" color="grey6">
            (optional)
          </Text>
        )}
      </Flex>

      {loadOptions ? (
        <AsyncSelect
          id={id}
          placeholder={placeholder}
          defaultValue={initialValues}
          isMulti
          name={name}
          noOptionsMessage={() =>
            (initialValues.length === maxOptions ||
            loadOptions.length === maxOptions
              ? maxOptionsText
              : noOptionsText) || null
          }
          onInputChange={onInputChange}
          defaultOptions
          loadOptions={loadOptions}
          styles={{
            ...styles(variant),
            noOptionsMessage: (base) => ({
              ...base,
              color:
                initialValues.length === maxOptions ||
                loadOptions.length === maxOptions
                  ? `${tokens.colors.red}`
                  : `${tokens.colors.grey6}`,
            }),
          }}
          components={{
            ClearIndicator: undefined,
            IndicatorSeparator: null,
            MultiValueRemove,
            MultiValueContainer,
            ...(hideIndicator
              ? { DropdownIndicator: null }
              : { DropdownIndicator }),
          }}
          onChange={handleOnChange}
        />
      ) : (
        <Select
          id={id}
          placeholder={placeholder}
          defaultValue={initialValues}
          value={value}
          isMulti
          name={name}
          options={options}
          styles={styles(variant)}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator,
            ClearIndicator: undefined,
            MultiValueRemove,
            MultiValueContainer,
          }}
          onChange={handleOnChange}
        />
      )}
    </Box>
  );
};
