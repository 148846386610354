import { keyframes } from "@stitches/react";
import React from "react";
import { Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { sendToAmplitude } from "src/utils/analytics";

const textZoom = keyframes({
  "0%": { opacity: 0, fontSize: 0, border: "unset" },
  "60%": { opacity: 0, fontSize: 1, border: "unset" },
  "100%": { opacity: 1, fontSize: "$12" },
});

const slideOutR = keyframes({
  "0%": {
    left: 0,
    width: "36px",
    height: "36px",
  },
  "30%": { width: "36px", height: "36px", left: 5 },
  "60%": { width: "36px" },
  "90%": { width: "185px" },
  "100%": { width: "fit-content" },
});

const slideOutL = keyframes({
  "0%": {
    right: 0,
    width: "36px",
    height: "36px",
  },
  "30%": { width: "36px", height: "36px", right: 35 },
  "60%": { width: "36px" },
  "90%": { width: "185px" },
  "100%": { width: "fit-content" },
});

export const AddToCollectionButton = ({
  onAddToCollection,
  analyticsEventPage,
  onHide,
  slideOutLeft,
  cta = "Add to collection",
  analyticsProperty,
}: {
  onAddToCollection: () => void;
  analyticsEventPage?: string;
  onHide: () => void;
  cta?: string;
  slideOutLeft?: boolean;
  analyticsProperty?: string;
}) => {
  return (
    <Flex
      data-test-id="AddToCollectionButton"
      css={{
        position: "absolute",
        top: 0,
        right: slideOutLeft ? 35 : undefined,
        left: slideOutLeft ? undefined : 5,
        width: "fit-content",
        height: "36px",
        background:
          "linear-gradient(91.69deg, rgba(244, 244, 244, 0.675) 3.29%, rgba(233, 233, 233, 0.792) 52.5%, rgba(202, 202, 202, 0.504) 99.69%)",
        border: "1px solid $grey3",
        backdropFilter: "blur(4px)",
        "&::-webkit-backdrop-filter": "blur(4px)",
        borderRadius: "$xl",
        animation: slideOutLeft
          ? `${slideOutL} 0.8s forwards`
          : `${slideOutR} 0.8s forwards`,
        zIndex: 2,
        "@bp2": {
          right: 0,
          left: 5,
          animation: `${slideOutR} 0.8s forwards`,
          cursor: "pointer",
        },
      }}
    >
      <Flex
        css={{
          pl: "$5",
          height: "100%",
          alignItems: "center",
          borderRadius: "50px 0px 0px 50px",
          "&:hover": {
            background: "rgba(255, 255, 255, 0.5)",
          },
        }}
        onClick={() => {
          analyticsEventPage &&
            sendToAmplitude(
              `${analyticsEventPage} - clicks add to collection`,
              { agencyID: analyticsProperty },
            );
          onAddToCollection();
        }}
      >
        <Text
          variant="mini"
          css={{
            pr: "$3",
            borderRight: "1px solid $grey3",
            whiteSpace: "nowrap",
            animation: `${textZoom} 0.8s forwards`,
          }}
        >
          {cta}
        </Text>
      </Flex>
      <Flex
        css={{
          height: "100%",
          alignItems: "center",
          borderRadius: "0px 50px 50px 0px",
          "&:hover": {
            background: "rgba(255, 255, 255, 0.5)",
          },
          pl: "$3",
          pr: "$5",
          animation: `${textZoom} 0.8s forwards`,
        }}
        onClick={onHide}
      >
        <Icon
          variant="cross"
          css={{
            width: "8px",
            height: "8px",
          }}
        />
      </Flex>
    </Flex>
  );
};
