import React from "react";
import { AutoplayVideo, Text } from "src/ccl/document";
import { styled } from "src/ccl";
import { Box, Flex, ContentContainer } from "src/ccl/layout";
import { Link } from "src/ccl/navigation";

const Video = styled(AutoplayVideo, {
  maxWidth: "120px",
  margin: "auto",
  display: "block",
  marginBottom: "-20px",
  "@bp3": {
    maxWidth: "150px",
  },
});

interface NotFoundErrorProps {
  message?: React.ReactNode | undefined;
}

const defaultMessage = () => (
  <Text align="center">
    It may have been deleted, or you may have mistyped a URL. Head back to our{" "}
    <Link to="/">homepage</Link>?
  </Text>
);

export const NotFoundError = ({
  message = defaultMessage(),
}: NotFoundErrorProps) => (
  <Flex
    css={{
      linearGradient: "#cfcfcf, #141414",
      pt: "$16",
      pb: "$16",
      alignItems: "center",
      justifyContent: "center",
      "@bp3": {
        pt: "160px",
        pb: "160px",
      },
    }}
  >
    <ContentContainer>
      <Box
        css={{
          background: "$white",
          maxWidth: "562px",
          boxShadow: "$focus",
          border: "2px black solid",
          padding: "$12",
          spaceY: "$6",
          mx: "auto",
        }}
      >
        <Video
          src="https://contact-media.s3-eu-west-1.amazonaws.com/gloss/404.mp4"
          poster="https://contact-media.s3-eu-west-1.amazonaws.com/gloss/404-poster.jpg"
        />
        <Text variant="h4" align="center">
          Oops, this page doesn&apos;t exist
        </Text>
        {message}

        <Box css={{ pt: "$4", textAlign: "center" }}>
          <Text
            variant="meta"
            color="grey6"
            css={{ overflowWrap: "break-word" }}
          >
            {window.location.href}
          </Text>
        </Box>
      </Box>
    </ContentContainer>
  </Flex>
);
