import { gql } from "@apollo/client";
import { PACKAGE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const CONVERT_PACKAGE_TO_DRAFT_MUTATION = gql`
  mutation ConvertPackageToDraft($packageId: ID!) {
    convertPackageToDraft(packageId: $packageId) {
      ...PackageDetails
    }
  }
  ${PACKAGE_DETAILS_FRAGMENT}
`;
