import { ApolloError, useQuery } from "@apollo/client";
import { Modal, ModalProps } from "src/ccl/document";
import { ValidationBlock } from "src/ccl/feedback";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { Loading } from "src/components";
import { InvoiceItemContent } from "src/components/payments/InvoiceItem";
import { BOOKER_DASHBOARD_JOB_INVOICES_QUERY } from "src/graphql/queries";
import { Invoice, InvoiceState, Job, Query } from "src/graphql/types";

export const PayInvoiceModal = ({
  job,
  onSelectInvoice,
  error,
  ...modalProps
}: {
  job: Job;
  error?: ApolloError;
  onSelectInvoice: (inv: Invoice) => void;
} & ModalProps) => {
  const { data: invoicesData } = useQuery<Query>(
    BOOKER_DASHBOARD_JOB_INVOICES_QUERY,
    {
      variables: { jobId: job.id },
    },
  );

  return (
    <Modal title="Pay Invoice" {...modalProps} showCloseButton={true}>
      {error && (
        <ValidationBlock
          title="Something went wrong"
          variant="error"
          body={error.message}
        />
      )}
      {invoicesData?.job?.invoices ? (
        <>
          {invoicesData.job?.invoices.map((invoice, i) => (
            <Box
              data-test-id={`Invoice-${invoice.id}-${invoice.state}`}
              key={`invoice-${i}`}
              onClick={
                invoice.state !== InvoiceState.Paid &&
                invoice.state !== InvoiceState.Refunded
                  ? () => onSelectInvoice(invoice)
                  : undefined
              }
              css={{
                my: "$3",
                cursor:
                  invoice.state !== InvoiceState.Paid
                    ? "pointer"
                    : "not-allowed",
                "&:hover": {
                  border: "1px solid $grey6",
                  borderRadius: "$md",
                },
              }}
            >
              <InvoiceItemContent invoice={invoice} />
            </Box>
          ))}
        </>
      ) : (
        <Loading />
      )}

      <Flex
        css={{
          justifyContent: "right",
          borderTop: "1px solid $grey2",
          pt: "$5",
          mt: "$5",
        }}
      >
        <Button
          variant="primary"
          css={{ px: "$10" }}
          onClick={modalProps.onClose}
        >
          Done
        </Button>
      </Flex>
    </Modal>
  );
};
