import { Gender, Pronouns, TalentProfile } from "src/graphql/types";
import { pluralize, toSentence } from "src/utils/lang";

export const gendersMap = {
  All: "All",
  [Gender.Male]: "Male",
  [Gender.Female]: "Female",
  [Gender.NonBinary]: "Non-binary",
};

export const groupMap = {
  All: "All",
  "Hidden profiles": "hidden_profiles",
  "Incomplete profiles": "incomplete_profiles",
  "Incomplete measurements": "incomplete_measurements",
  "Low media count": "low_media_count",
  "Contract signed": "with_signed_contract",
  "Contract not signed": "without_signed_contract",
};

export const talentHelpers = {
  profileNeedsAttention: (profile: TalentProfile) => {
    if (talentHelpers.hasLowMediaCount(profile)) {
      return true;
    }

    return (
      talentHelpers.hasMissingInformation(profile) ||
      talentHelpers.hasMissingMeasurements(profile)
    );
  },
  hasLowMediaCount: (profile: TalentProfile) => {
    if (!profile.mediaCounts) {
      return false;
    }

    return Object.values(profile.mediaCounts).some(
      (counts) => counts.count < counts.desired,
    );
  },
  hasMissingInformation: (profile: TalentProfile) =>
    (profile.missingInformation ?? []).length > 0,
  hasMissingMeasurements: (profile: TalentProfile) =>
    (profile.missingMeasurements ?? []).length > 0,
  buildMissingItemsString: (profile: TalentProfile) => {
    const parts = [];

    if (talentHelpers.hasLowMediaCount(profile)) {
      parts.push("Needs more images");
    }

    if (profile.missingInformation && profile.missingInformation.length > 0) {
      parts.push(`Has no ${toSentence(profile.missingInformation, "or")}`);
    }

    const missingMeasuremntCount = (profile.missingMeasurements || []).length;
    if (missingMeasuremntCount > 0) {
      parts.push(`Missing ${pluralize(missingMeasuremntCount, "measurement")}`);
    }

    if (parts.length === 0) {
      return "";
    }

    return `${parts.join(". ")}.`;
  },
  buildMissingInformationString: (profile: TalentProfile) => {
    if (
      !profile.missingInformation ||
      profile.missingInformation.length === 0
    ) {
      return "";
    }

    return `has no ${toSentence(profile.missingInformation, "or")} set.`;
  },
  buildMissingMeasurementsString: (profile: TalentProfile) => {
    if (
      !profile.missingMeasurements ||
      profile.missingMeasurements.length === 0
    ) {
      return "";
    }

    return `has no ${toSentence(
      profile.missingMeasurements.map((field) => field.replace("_", " ")),
      "or",
    )} set.`;
  },
};

export const pronounLabelMap = {
  [Pronouns.HeHim]: "He/Him",
  [Pronouns.SheHer]: "She/Her",
  [Pronouns.TheyThem]: "They/Them",
  [Pronouns.HeThey]: "He/They",
  [Pronouns.SheThey]: "She/They",
  [Pronouns.ZeHir]: "Ze/Hir",
};
