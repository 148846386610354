import { useEffect, useRef, useState } from "react";
import { Box, Flex } from "src/ccl/layout";
import { SavedTalentCollection } from "src/graphql/types";
import { Loading } from "src/components/Loading";
import emptyCollection from "src/assets/collections/emptyCollection.svg";
import { sendToAmplitude } from "src/utils/analytics";
import { CreateNewListItem, DroppableListItem } from "src/ccl/document";

export const CollectionList = ({
  collections,
  onClickItem,
  isItemSelected,
  onCreateNewCollection,
  loading,
  withCheckbox,
  collectionListItemAmplitudeData,
  analyticsProperty,
}: {
  collections: SavedTalentCollection[];
  onClickItem: (collection: SavedTalentCollection) => void;
  isItemSelected: (selectedItem: SavedTalentCollection) => boolean;
  onCreateNewCollection: () => void;
  loading?: boolean;
  withCheckbox?: boolean;
  collectionListItemAmplitudeData?: (
    collection: SavedTalentCollection,
  ) => string;
  analyticsProperty?: string;
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    function handleScroll() {
      if (scrollableDiv && scrollableDiv.scrollTop > 0 && !isScrolled) {
        setIsScrolled(true);
      } else if (scrollableDiv?.scrollTop === 0 && isScrolled) {
        setIsScrolled(false);
      }
    }
    scrollableDiv?.addEventListener("scroll", handleScroll);
    return () => {
      scrollableDiv?.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  return (
    <Box
      css={{
        position: "relative",
      }}
    >
      <Box
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "20px",
          background:
            "linear-gradient(to bottom, rgba(200, 200, 200, 1), rgba(200, 200, 200, 0))",
          opacity: isScrolled ? 1 : 0,
          transition: "opacity 0.1s ease-in-out",
        }}
      ></Box>
      <Flex
        ref={scrollableDivRef}
        css={{
          maxHeight: "60vh",
          flexDirection: "column",
          gap: "$3",
          overflowY: "scroll",
          mr: "-8px",
          pr: "$7",
          mb: "$8",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "$grey3",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "$grey2",
            borderRadius: "5px",
            height: "81px",
          },
        }}
      >
        <CreateNewListItem
          onCreateNewItem={onCreateNewCollection}
          cta="Create a new collection"
        />

        {loading ? (
          <Loading />
        ) : (
          collections?.map((c: SavedTalentCollection, i: number) => {
            const memberEdges = c.members?.edges || [];
            const collectionImage =
              memberEdges[0]?.node?.featuredAsset?.mediaUrl;
            return (
              <DroppableListItem
                withCheckbox={withCheckbox}
                key={`collection-list-item-${i}`}
                name={c.name}
                droppableId={c.id}
                count={c.members?.totalCount}
                entity="saved talent"
                image={collectionImage}
                emptyIcon={emptyCollection}
                isActive={isItemSelected(c)}
                onClick={() => {
                  onClickItem(c);
                  collectionListItemAmplitudeData &&
                    sendToAmplitude(collectionListItemAmplitudeData(c), {
                      agencyID: analyticsProperty,
                    });
                }}
              />
            );
          })
        )}
      </Flex>
    </Box>
  );
};
