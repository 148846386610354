import React from "react";
import { TableHeader, TableHeaderItem } from "src/ccl/document/table";
import { Text } from "src/ccl/document";
import { SortOrderInput, SortDirection } from "src/graphql/types";
import {
  currentFieldName,
  currentDirectionFromSortOrder,
} from "src/utils/sorting";

interface JobTableHeaderProps {
  sortOrder: SortOrderInput;
  setSortOrder: (column: string, sortDirection: SortDirection) => void;
}

export const JobTableHeader = ({
  sortOrder,
  setSortOrder,
}: JobTableHeaderProps) => (
  <TableHeader>
    <TableHeaderItem
      widthPercentage={30}
      fieldName="name"
      active={currentFieldName("name", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder("name", sortOrder)}
      onSortChange={setSortOrder}
    >
      <Text variant="mini" color="grey6">
        Job
      </Text>
    </TableHeaderItem>

    <TableHeaderItem widthPercentage={10} fieldName="assigneeId" active={false}>
      <Text variant="mini" color="grey6">
        Owner
      </Text>
    </TableHeaderItem>

    <TableHeaderItem
      widthPercentage={10}
      fieldName="state"
      active={currentFieldName("state", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder("state", sortOrder)}
      onSortChange={setSortOrder}
    >
      <Text variant="mini" color="grey6">
        Status
      </Text>
    </TableHeaderItem>

    <TableHeaderItem
      widthPercentage={20}
      fieldName="booker"
      active={currentFieldName("booker", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder("booker", sortOrder)}
      onSortChange={setSortOrder}
    >
      <Text variant="mini" color="grey6">
        Booker
      </Text>
    </TableHeaderItem>

    <TableHeaderItem
      widthPercentage={15}
      fieldName="start_date"
      active={currentFieldName("start_date", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder(
        "start_date",
        sortOrder,
      )}
      onSortChange={setSortOrder}
    >
      <Text variant="mini" color="grey6">
        Job date
      </Text>
    </TableHeaderItem>

    <TableHeaderItem
      widthPercentage={15}
      fieldName="created_at"
      active={currentFieldName("created_at", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder(
        "created_at",
        sortOrder,
      )}
      onSortChange={setSortOrder}
    >
      <Text variant="mini" color="grey6">
        posted date
      </Text>
    </TableHeaderItem>
  </TableHeader>
);
