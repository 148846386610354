import { Modal, ModalProps, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { styled } from "src/ccl";
import { FilterOption } from "src/ccl/filtering";
import { Button } from "src/ccl/navigation";
import { OrderingOption } from "src/graphql/types";

interface Option {
  value: string;
  label: string;
}

const Divider = styled("hr", {
  width: "100%",
  height: "1px",
  border: "none",
  backgroundColor: "$grey1",
});

const FilterContainer = ({
  label,
  children,
}: {
  label: string;
  children: JSX.Element;
}) => {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "18px",
      }}
    >
      <Text variant="nh4">{label}</Text>
      {children}
    </Flex>
  );
};

interface ManagedTalentSortModalProps extends ModalProps {
  defaultOrderingOption?: OrderingOption;
  options?: Option[];
  onChange: (value: Option | undefined) => void;
}

export const ManagedTalentSortModal = ({
  defaultOrderingOption,
  options,
  onChange,
  onClose,
  ...modalProps
}: ManagedTalentSortModalProps) => {
  return (
    <Modal
      title="Sort"
      titleVariant="nh3"
      titleCss={{
        marginBottom: 0,
        fontSize: "$22 !important",
      }}
      showCloseButton
      withBodyPadding={false}
      onClose={onClose}
      {...modalProps}
    >
      <Flex
        css={{
          pt: "$5",
          px: "$5",
        }}
      >
        <Divider />
      </Flex>
      <Flex
        data-test-id="JobFilterModal"
        css={{
          flexDirection: "column",
          gap: "32px",
          padding: "$9 $5",
          maxHeight: "595px",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            backgroundColor: "$white",
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "$grey3",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "$grey2",
            borderRadius: "5px",
            height: "81px",
          },
        }}
      >
        <FilterContainer label="Sort by">
          <Flex
            css={{
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            {options?.map((option) => (
              <FilterOption
                key={option.label}
                variant="pill"
                label={option.label}
                checked={defaultOrderingOption?.label === option.label}
                onClick={() => onChange(option)}
              />
            ))}
          </Flex>
        </FilterContainer>
      </Flex>
      <Flex
        css={{
          flexDirection: "column",
          gap: "25px",
          padding: "$0 $5 $5 $5",
        }}
      >
        <Flex>
          <Divider />
        </Flex>
        <Flex
          css={{
            justifyContent: "space-between",
            flexDirection: "column-reverse",
            gap: "18px",
            "@sm": {
              flexDirection: "row",
            },
          }}
        >
          <Button
            data-test-id="ClearAllButton"
            variant="secondaryCta"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            data-test-id="ShowJobButton"
            variant="primaryCta"
            onClick={onClose}
          >
            Show creatives
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
