import React from "react";
import * as Stitches from "@stitches/react";
import { TextInputPill } from "src/ccl/blocks";

export const SearchFilter = ({
  placeholder,
  search,
  onChange,
  withinModal,
  showClearButton,
  css,
}: {
  placeholder: string;
  search?: string;
  onChange: (search: string) => void;
  withinModal?: boolean;
  showClearButton?: boolean;
  css?: Stitches.CSS;
}) => (
  <TextInputPill
    name="nameOrBrand"
    active={!!search}
    placeholder={placeholder}
    onChange={onChange}
    value={search || ""}
    withinModal={withinModal}
    showClearButton={showClearButton}
    css={css}
  />
);
