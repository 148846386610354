import { useEffect, useState } from "react";
import type * as Stitches from "@stitches/react";
import { FilterOption } from "src/ccl/filtering/multiSelectFilter";
import { DropdownPill } from "src/ccl/blocks/pill/DropdownPill";
import { Box, Flex } from "src/ccl/layout";
import { Input } from "src/ccl/data-entry";
import { searchPlace } from "src/utils/google";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";
import { Text, Icon } from "src/ccl/document";
import { mergeCss } from "src/ccl";

function onlyUnique(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index;
}

const DEFAULT_LOCATIONS = [
  "London",
  "Manchester",
  "Paris",
  "Leeds",
  "Liverpool",
];

export const LocationFilter = ({
  locations,
  onChange,
  selectedFilter,
  setSelectedFilter,
}: {
  locations: string[];
  onChange: (locations: string[]) => void;
  selectedFilter?: string | null;
  setSelectedFilter?: (filter: string | null) => void;
}) => {
  return (
    <DropdownPill
      active={locations.length > 0}
      icon="locationOutline"
      text="Location"
      withHoverEffect
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
    >
      <LocationFilterOptions
        locations={locations}
        onChange={onChange}
        css={{ py: "$5", px: "$4" }}
      />
    </DropdownPill>
  );
};

export const LocationFilterOptions = ({
  locations,
  onChange,
  css = {},
}: {
  locations: string[];
  onChange: (locations: string[]) => void;
  css?: Stitches.CSS;
}) => {
  const rows = 7;
  const [locationOptions, setLocationsToDisplay] = useState(DEFAULT_LOCATIONS);
  const [, setLocationSearch] = useDebouncedInputValue("", async (value) => {
    const locationResults = await searchPlace(value, {
      types: ["(regions)"],
    });
    setLocationsToDisplay(locationResults.map(({ label }) => label));
  });

  useEffect(() => {
    if (locations.length === 0 && locationOptions.length === 0) {
      setLocationsToDisplay(DEFAULT_LOCATIONS);
    }
  }, [locations, locationOptions]);

  const locationsToDisplay = [...locations, ...locationOptions].filter(
    onlyUnique,
  );
  return (
    <Box css={mergeCss({}, css)}>
      <Flex
        css={{
          border: "1.5px solid $grey3",
          borderRadius: "8px",
          pl: "$6",
          mb: "$3",
          gap: "$2",
        }}
      >
        <Icon variant="search" color="grey6" size={20} css={{ my: "auto" }} />
        <Input
          css={{
            border: "unset",
            width: "80%",
            minWidth: "unset",
          }}
          placeholder="Search location"
          onChange={(e) => setLocationSearch(e.target.value)}
        />
      </Flex>
      <Box
        css={{
          height:
            locationsToDisplay.length < rows
              ? 42 * (locationsToDisplay.length || 1)
              : rows * 42,
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            backgroundColor: "$white",
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "$grey3",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "$grey2",
            borderRadius: "5px",
            height: "81px",
          },
        }}
      >
        {locationsToDisplay.map((location, i) => {
          const checked = locations.includes(location);
          return (
            <FilterOption
              key={i}
              label={location}
              onClick={() => {
                if (checked) {
                  onChange(locations.filter((b) => b !== location));
                } else {
                  onChange([...locations, location]);
                }
              }}
              checked={checked}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export const LocationPillFilterOptions = ({
  locations,
  onChange,
  css = {},
}: {
  locations: string[];
  onChange: (locations: string[]) => void;
  css?: Stitches.CSS;
}) => {
  const [locationOptions, setLocationsToDisplay] = useState(DEFAULT_LOCATIONS);
  const [, setLocationSearch] = useDebouncedInputValue("", async (value) => {
    const locationResults = await searchPlace(value, {
      types: ["(regions)"],
    });
    setLocationsToDisplay(locationResults.map(({ label }) => label));
  });

  useEffect(() => {
    if (locations.length === 0 && locationOptions.length === 0) {
      setLocationsToDisplay(DEFAULT_LOCATIONS);
    }
  }, [locations, locationOptions]);

  const locationsToDisplay = [...locations, ...locationOptions].filter(
    onlyUnique,
  );
  return (
    <Box css={mergeCss({}, css)}>
      <Flex
        css={{
          border: "1.5px solid $grey3",
          borderRadius: "8px",
          pl: "$6",
          gap: "$2",
        }}
      >
        <Input
          css={{
            border: "unset",
            width: "80%",
            minWidth: "unset",
          }}
          placeholder="Search for location"
          onChange={(e) => setLocationSearch(e.target.value)}
        />
      </Flex>
      <Flex
        css={{
          gap: "8px",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Text variant="rebrand" css={{ fontSize: "$14" }}>
          Popular:{" "}
        </Text>
        {locationsToDisplay.map((location, i) => {
          const checked = locations.includes(location);
          return (
            <FilterOption
              variant="pill"
              key={i}
              label={location}
              onClick={() => {
                if (checked) {
                  onChange(locations.filter((b) => b !== location));
                } else {
                  onChange([...locations, location]);
                }
              }}
              checked={checked}
            />
          );
        })}
      </Flex>
    </Box>
  );
};
