import { gql } from "@apollo/client";

export const AGENT_BOOKER_LIST_QUERY = gql`
  query BookerSearchQuery($name: String) {
    bookers(nameOrEmail: $name) {
      edges {
        node {
          id
          name
          email
          profile {
            ... on BookerProfile {
              company
            }
          }
        }
      }
    }
  }
`;
