import React from "react";
import { Box } from "src/ccl/layout";
import { Job, JobTalent, JobTalentState } from "src/graphql/types";
import { Icon } from "src/ccl/document";
import {
  jobCompleted,
  jobConfirmed,
  jobIsLive,
  JobTalentAccepted,
  jobTalentRejected,
} from "src/utils/job";
import {
  AvatarCollection,
  AvatarCollectionProps,
} from "src/ccl/blocks/avatar/AvatarCollection";

interface BookerDashboardJobsTalentProps
  extends Omit<AvatarCollectionProps, "users"> {
  talent: JobTalent[];
  job: Job;
}

export const BookerDashboardJobsTalent = ({
  talent,
  job,
  ...avatarCollectionProps
}: BookerDashboardJobsTalentProps) => {
  const jobTalent = talent.map((jt) => jt);

  const sortOrder: JobTalentState[] = [
    JobTalentState.Confirmed,
    JobTalentState.Accepted,
    JobTalentState.Rejected,
    JobTalentState.RejectedByBooker,
    JobTalentState.Pending,
  ];

  const confirmedCreatives = jobTalent.filter(
    (talent) => talent.state === JobTalentState.Confirmed,
  );

  const sortedCreatives = jobTalent.sort((a, b) => {
    return sortOrder.indexOf(a.state) - sortOrder.indexOf(b.state);
  });

  const size = avatarCollectionProps.size;

  const creatives =
    jobConfirmed(job.state) || jobCompleted(job.state)
      ? confirmedCreatives
      : sortedCreatives;

  const creativeAvatarUsers = creatives.map((jt) => jt.talent);
  const creativeAvatarIcons = creatives.map(({ state }) => {
    return state !== JobTalentState.Pending && jobIsLive(job) ? (
      <Box css={{ width: 0 }}>
        <Box
          css={{
            position: "relative",
            zIndex: "$300",
            top: size ? size - 12 : "52px",
            left: size ? size * 0.4 * -1 : `-20px`,
            "@bp2": {
              top: size ? size * 0.75 : "70px",
              left: size ? size * -0.5 : `-26px`,
              height: "$5",
              width: "$5",
            },
            borderRadius: "$round",
            display: "flex",
            height: "$4",
            width: "$4",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: JobTalentAccepted(state)
              ? "$green"
              : jobTalentRejected(state)
              ? "$red"
              : undefined,
          }}
        >
          <Icon
            variant={JobTalentAccepted(state) ? "check" : "cross"}
            size={8}
            color="white"
          />
        </Box>
      </Box>
    ) : (
      <></>
    );
  });

  return (
    <AvatarCollection
      icons={creativeAvatarIcons}
      users={creativeAvatarUsers}
      {...avatarCollectionProps}
    />
  );
};
