import { gql } from "@apollo/client";
import { SHORTLIST_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const AGENT_DASHBOARD_JOB_SHORTLIST_QUERY = gql`
  query AgentDashboardJobShortlist($jobId: ID!) {
    job(id: $jobId) {
      ...ShortlistDetails
    }
  }
  ${SHORTLIST_DETAILS_FRAGMENT}
`;
