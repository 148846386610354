import React, { useCallback, useState } from "react";
import { isEqual } from "lodash";
import { SortOrderOption } from "./SortOrderOption";
import { JobState, SortDirection, SortOrderInput } from "src/graphql/types";
import { Text, Icon } from "src/ccl/document";
import { Flex, Box } from "src/ccl/layout";
import { Dropdown } from "src/ccl/navigation";
import { styled } from "src/ccl";

const Caret = styled(Box, {
  width: "0",
  height: "0",
  position: "absolute",
  top: -6,
  right: "50%",
  borderLeftWidth: "6px",
  borderLeftColor: "transparent",
  borderLeftStyle: "solid",
  borderRightWidth: "6px",
  borderRightColor: "transparent",
  borderRightStyle: "solid",
  borderBottomColor: "$white",
  borderBottomStyle: "solid",
  borderBottomWidth: "6px",
});

export const LATEST_JOBS: SortOrderInput = {
  column: "start_date",
  direction: SortDirection.Desc,
};
export const EARLIEST_JOBS: SortOrderInput = {
  column: "start_date",
  direction: SortDirection.Asc,
};
export const ACTIVE_STATES: JobState[] = [
  JobState.PendingApproval,
  JobState.Approved,
  JobState.Finalized,
];
export const PAST_STATES: JobState[] = [JobState.Completed, JobState.Cancelled];

export const SortOrderFilter = ({
  sortOrder,
  onSortOrderChange,
  onStatesChange,
}: {
  sortOrder: SortOrderInput;
  onSortOrderChange: (sortOrder: SortOrderInput) => void;
  onStatesChange: (sortOrder: JobState[]) => void;
}) => {
  /**
     because we can alter the job states filter outside this component,
     we need to keep track of whether we're sorting by active or past here
  **/
  const [sortByActive, setSortByActive] = useState(false);
  const [sortByPast, setSortByPast] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const onClose = useCallback(() => setDropdownOpen(false), []);
  const onOpen = useCallback(() => setDropdownOpen(true), []);
  const sortByStateFlags = sortByPast || sortByActive;
  const resetStateFlags = () => {
    setSortByActive(false);
    setSortByPast(false);
  };

  return (
    <Box data-test-id="SortOrderFilter" css={{ width: "fit-content" }}>
      <Dropdown
        position="center"
        bodyCss={{
          border: "none",
          filter: "drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.2))",
          width: "max-content",
          borderRadius: "8px",
          zIndex: 351,
        }}
        wrapperCss={{ zIndex: 300 }}
        onClose={onClose}
        onOpen={onOpen}
        launcher={
          <Box
            css={{
              borderRadius: "2px",
              backgroundColor: "$grey1",
              width: "fit-content",
              py: "$2",
              px: "$4",
            }}
          >
            <Flex css={{ alignItems: "center", cursor: "pointer", gap: "$3" }}>
              <Icon
                variant="sortRows"
                size={18}
                css={{ my: "auto" }}
                color={dropdownOpen ? "black" : "grey4"}
              />
              <Text variant="body" color={dropdownOpen ? "black" : "grey4"}>
                Sort
              </Text>
            </Flex>
          </Box>
        }
      >
        <Caret data-test-id="caret" />
        <SortOrderOption
          label="Latest job date"
          icon="calendar"
          selected={!sortByStateFlags && isEqual(LATEST_JOBS, sortOrder)}
          onClick={() => {
            onSortOrderChange(LATEST_JOBS);
            resetStateFlags();
          }}
        />
        <SortOrderOption
          label="Earliest job date"
          icon="calendar"
          selected={isEqual(EARLIEST_JOBS, sortOrder)}
          onClick={() => {
            onSortOrderChange(EARLIEST_JOBS);
            resetStateFlags();
          }}
        />
        <SortOrderOption
          label="Active jobs"
          icon="virtual"
          selected={isEqual(LATEST_JOBS, sortOrder) && sortByActive}
          onClick={() => {
            onSortOrderChange(LATEST_JOBS);
            onStatesChange(ACTIVE_STATES);
            setSortByPast(false);
            setSortByActive(true);
          }}
        />
        <SortOrderOption
          label="Past jobs"
          icon="check"
          selected={isEqual(LATEST_JOBS, sortOrder) && sortByPast}
          onClick={() => {
            onSortOrderChange(LATEST_JOBS);
            onStatesChange(PAST_STATES);
            setSortByActive(false);
            setSortByPast(true);
          }}
        />
      </Dropdown>
    </Box>
  );
};
