import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { Field, FormikInput } from "src/ccl/data-entry";
import { Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { UPDATE_JOB_CONFIRM_MUTATION } from "src/graphql/mutations";
import { Job, Mutation, MutationUpdateJobArgs } from "src/graphql/types";
import { ValidationBlock } from "src/ccl/feedback";

export const schema = Yup.object().shape({
  addressLine1: Yup.string().required("Required").trim(),
  addressLine2: Yup.string().trim(),
  city: Yup.string().required("Required").trim(),
  country: Yup.string().required("Required").trim(),
  postcode: Yup.string().required("Required").trim(),
});

interface JobAddressProps {
  job: Job;
  onBack: () => void;
  onContinue: () => void;
}

export const JobAddress = ({ job, onBack, onContinue }: JobAddressProps) => {
  const [updateJob, { error, loading }] = useMutation<
    Mutation,
    MutationUpdateJobArgs
  >(UPDATE_JOB_CONFIRM_MUTATION);

  return (
    <Formik
      initialValues={{
        addressLine1: job.addressLine1 || "",
        addressLine2: job.addressLine2 || "",
        city: job.city || "",
        country: job.country || "",
        postcode: job.postcode || "",
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        await updateJob({ variables: { jobId: job.id, ...values } });
        if (!error) {
          onContinue();
        }
      }}
    >
      <Form>
        <Text variant="h2" css={{ pb: "$9" }}>
          Confirm Job
        </Text>
        <Text css={{ pb: "$7" }}>
          Please confirm the address that your creative will travel to.
        </Text>
        {error && (
          <ValidationBlock
            variant="error"
            title={"Could no update address"}
            body={error.message}
          />
        )}

        <Box>
          <Field label="Address line 1" name="addressLine1">
            <FormikInput type="text" name="addressLine1" autoFocus />
          </Field>
          <Field label="Address line 2" name="addressLine2">
            <FormikInput type="text" name="addressLine2" />
          </Field>
          <Flex
            css={{
              gap: "$6",
              flexDirection: "column",
              "@bp2": { flexDirection: "row" },
            }}
          >
            <Box css={{ flex: "1" }}>
              <Field label="City" name="city">
                <FormikInput type="text" name="city" />
              </Field>
            </Box>
            <Box css={{ flex: "1" }}>
              <Field label="Postcode" name="postcode">
                <FormikInput type="text" name="postcode" />
              </Field>
            </Box>
          </Flex>
          <Field label="Country" name="country">
            <FormikInput type="text" name="country" />
          </Field>
        </Box>
        <Flex css={{ justifyContent: "space-between" }}>
          <Button variant="secondary" onClick={onBack} type="reset">
            Back
          </Button>

          <Button variant="primary" type="submit">
            {loading ? "Saving..." : "Continue"}
          </Button>
        </Flex>
      </Form>
    </Formik>
  );
};
