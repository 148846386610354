import { gql } from "@apollo/client";
import {
  JOB_DETAILS_FRAGMENT,
  JOB_DETAILS_SPLIT_ADDRESS_FRAGMENT,
  JOB_VERTICAL_DETAILS_FRAGMENT,
} from "src/graphql/fragments";

export const BOOKER_DASHBOARD_EDIT_JOB_QUERY = gql`
  query BookerDashboardEditJob($jobId: ID!) {
    job(id: $jobId) {
      ...JobDetails
      ...JobDetailsSplitAddress
      ...JobVerticalDetails
      talent {
        id
        talent {
          id
          name
          slug
          featuredAsset {
            mediaUrl
          }
          profile {
            ... on TalentProfile {
              vertical
            }
          }
        }
      }
    }
  }
  ${JOB_DETAILS_FRAGMENT}
  ${JOB_DETAILS_SPLIT_ADDRESS_FRAGMENT}
  ${JOB_VERTICAL_DETAILS_FRAGMENT}
`;
