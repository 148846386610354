const getGoogleAutocomplete = () =>
  window.google && new window.google.maps.places.AutocompleteService();

export const searchPlace = (
  input: string,
  options?: google.maps.places.AutocompleteOptions,
) =>
  new Promise<{ label: string; value: string }[]>((resolve) => {
    const googleAutocomplete = getGoogleAutocomplete();
    if (input) {
      if (googleAutocomplete) {
        googleAutocomplete.getPlacePredictions(
          { input, ...options },
          (result) => {
            if (!result) {
              resolve([]);
            } else {
              resolve(
                result?.map((p) => ({
                  label: p.description,
                  value: p.description,
                })),
              );
            }
          },
        );
      } else {
        resolve([]);
      }
    } else {
      resolve([]);
    }
  });
