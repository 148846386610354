import React from "react";
import { Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Job, JobState } from "src/graphql/types";
import {
  jobAllTalentResponded,
  jobApproved,
  jobAwaitingPayment,
  jobFinalized,
  jobIsLive,
  jobPendingApproval,
  jobTitleForFPA,
  jobTitleForTPA,
} from "src/utils/job";
import { styled } from "src/ccl/stitches/config";
import { jobStates } from "src/ccl/stitches/theme";

const agentDescriptions: Record<JobState, string | React.ReactNode> = {
  [JobState.PendingApproval]:
    "We’re still approving your job. This normally takes an hour or so but can be a lot quicker. Not heard from us? Please check your spam folder.",
  [JobState.Approved]:
    "Models should now respond before the client makes their final selection and confirms.",
  [JobState.AwaitingPayment]:
    "Models should now respond before the client makes their final selection and confirms.",
  [JobState.Finalized]:
    "This job has been confirmed. Now just make sure your models are all prepared for the job date!",
  [JobState.Completed]:
    "This job has completed. We will reach out to yourself and your models with payment as soon as possible.",
  [JobState.Expired]:
    "Unfortunately the client did not confirm the job in time",
  [JobState.Cancelled]: "This job has been cancelled.",
  [JobState.Rejected]: "This job has been rejected",
};

const hasPassed = (passingState: JobState, state: JobState): boolean => {
  if ([JobState.Finalized, JobState.Completed].includes(state)) {
    return [
      JobState.PendingApproval,
      JobState.Approved,
      JobState.AwaitingPayment,
      JobState.Finalized,
    ].includes(passingState);
  }

  if ([JobState.Approved, JobState.AwaitingPayment].includes(state)) {
    return [
      JobState.PendingApproval,
      JobState.Approved,
      JobState.AwaitingPayment,
    ].includes(passingState);
  }

  if (state === JobState.PendingApproval) {
    return [JobState.PendingApproval].includes(passingState);
  }

  return false;
};

const colorForStep = (circleStates: JobState[], state: JobState) => {
  if (circleStates.includes(state)) {
    return jobStates[state];
  }

  if (circleStates.some((circleState) => hasPassed(circleState, state))) {
    return "black";
  }

  return "#BEBEBE";
};

const colorForLine = (lineState: JobState, state: JobState) => {
  if (hasPassed(lineState, state)) {
    return "black";
  }

  return "#BEBEBE";
};

const iconPositionForStep = (job: Job) => {
  if (jobPendingApproval(job.state)) {
    return "translate(calc(33% - 8px), 3.5px)";
  } else if (jobApproved(job.state) && !jobAllTalentResponded(job)) {
    return "translate(calc(66% - 8px), 3.5px)";
  } else if (
    (jobApproved(job.state) && jobAllTalentResponded(job)) ||
    jobAwaitingPayment(job.state)
  ) {
    return "translate(calc(66% - 8px), 5px)";
  } else if (jobFinalized(job.state)) {
    return "translate(calc(100% - 20px), 5px)";
  }
};

const TimerIcon = styled("g", {});
const TickIcon = styled("g", {});

export const HorizontalProgressIcon = ({
  job,
}: {
  job: Job;
  children?: React.SVGProps<SVGSVGElement> | React.ReactNode;
}) => (
  <svg width="100%" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="11" width="33%" height="2" fill="black" />
    <rect
      x="33%"
      y="11"
      width="33%"
      height="2"
      fill={colorForLine(JobState.Approved, job.state)}
    />
    <rect
      x="66%"
      y="11"
      width="33%"
      height="2"
      fill={colorForLine(JobState.Finalized, job.state)}
    />
    <circle
      cx="0"
      cy="12"
      r="12"
      style={{ transform: "translate(calc(0% + 12px))" }}
      fill="black"
    />
    <circle
      cx="0"
      cy="12"
      r="12"
      style={{ transform: "translate(calc(33%))" }}
      fill={colorForStep([JobState.PendingApproval], job.state)}
    />
    <circle
      cx="0"
      cy="12"
      r="12"
      style={{ transform: "translate(calc(66%)" }}
      fill={colorForStep(
        [JobState.Approved, JobState.AwaitingPayment],
        job.state,
      )}
    />
    <circle
      cx="0"
      cy="12"
      r="12"
      style={{ transform: "translate(calc(100% - 12px))" }}
      fill={colorForStep([JobState.Finalized], job.state)}
    />
    {jobIsLive(job) &&
      (jobFinalized(job.state) ||
      (jobApproved(job.state) && jobAllTalentResponded(job)) ? (
        <TickIcon
          css={{
            display: "none",
            "@bp1": { display: "block" },
            transform: iconPositionForStep(job),
          }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 2.62456L5.59134 13.369L0 7.94709L2.02557 5.85821L5.52702 9.25355L13.9101 0.600006L16 2.62456Z"
            fill="white"
          />
        </TickIcon>
      ) : (
        <TimerIcon
          css={{
            display: "none",
            "@bp1": { display: "block" },
            transform: iconPositionForStep(job),
          }}
        >
          <path
            d="M9.528 7.472L11.6368 4.2512L11.2736 3.9264L8.3352 6.4056C7.7952 6.2952 7.2136 6.46 6.82 6.9C6.2312 7.5584 6.288 8.5704 6.9472 9.1592C7.6064 9.748 8.6176 9.6912 9.2064 9.032C9.6 8.592 9.6984 7.996 9.528 7.472Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
            fill="white"
          />
        </TimerIcon>
      ))}
  </svg>
);

interface JobProgressProps {
  job: Job;
  aboveProgressBarContent?: React.ReactNode;
  belowProgressBarContent?: React.ReactNode;
  tpa?: boolean;
}

export const JobProgress = ({
  job,
  aboveProgressBarContent = null,
  belowProgressBarContent = null,
  tpa,
}: JobProgressProps) => (
  <Flex
    css={{
      flexDirection: "column",
      borderRadius: "10px",
      boxShadow: "$focus",
      py: "$7",
      px: "$9",
    }}
  >
    <Text
      variant="h2"
      css={{
        color: jobStates[job.state],
        textTransform: "uppercase",
      }}
    >
      {tpa ? jobTitleForTPA(job) : jobTitleForFPA(job)}
    </Text>

    {tpa && (
      <Text variant="meta" css={{ mt: "$2", mb: "$5" }}>
        {agentDescriptions[job.state]}
      </Text>
    )}

    {aboveProgressBarContent}

    <HorizontalProgressIcon job={job} />

    {belowProgressBarContent}
  </Flex>
);
