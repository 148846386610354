import React from "react";
import { DeckFileList } from "./DeckFileList";
import { Field, FileDragDropUpload } from "src/ccl/data-entry";
import { MaxDeckFileSizeMb, MaxNumberOfDeckFilesPerJob } from "src/config";
import { Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { UploadedFile } from "src/entities";
import { DeckFile } from "src/graphql/types";

export interface DeckFileUploadProps {
  onUpload: (files: UploadedFile[]) => void;
  onRemove: (file: UploadedFile) => void;
  deckFiles: UploadedFile[];
  existingDeckFiles: DeckFile[];
  onDeleteExistingDeckFile: (deckFile: DeckFile) => void;
  viewDeckFileAnalyticsEvent?: string;
  variant?: "rebrand";
}

export const DeckFileUpload = ({
  onUpload,
  onRemove,
  deckFiles,
  existingDeckFiles,
  onDeleteExistingDeckFile,
  viewDeckFileAnalyticsEvent,
  variant,
}: DeckFileUploadProps) => (
  <>
    <Field
      label="Attach deck or moodboard"
      secondaryLabel="(Optional)"
      css={{ mb: "$2", pt: "$5", "@bp2": { pt: "$0" } }}
    />
    <FileDragDropUpload
      attachmentStoreName="deck_files"
      contentNoFiles={
        <Box>
          <Text css={{ textDecoration: "underline" }}>Upload PDFs</Text>
          <Text variant="meta" color="grey6">
            Drag or select up to {MaxNumberOfDeckFilesPerJob}
          </Text>
        </Box>
      }
      onUpload={onUpload}
      onRemove={onRemove}
      maxFileSizeMb={MaxDeckFileSizeMb}
      maxFileCount={MaxNumberOfDeckFilesPerJob}
      existingFileCount={(deckFiles || []).length}
      type="all"
      withBorder={true}
      multiple
      showPreviewList
      containerCss={
        variant === "rebrand"
          ? { borderRadius: "8px", borderColor: "$grey4" }
          : undefined
      }
    />

    <DeckFileList
      deckFiles={existingDeckFiles}
      analyticsEvent={viewDeckFileAnalyticsEvent}
      onRemove={onDeleteExistingDeckFile}
    />
  </>
);
