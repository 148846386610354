import React, { useState } from "react";
import { ShortlistTalentSubheading } from "./ShortlistTalentSubheading";
import { ShortlistTitle } from "./ShortlistTitle";
import { RemoveJobTalentModal } from "./RemoveJobTalentModal";
import { ConfirmJobModal } from "./ConfirmJobModal";
import { ReviewAdvice } from "./ReviewAdvice";
import {
  Job,
  JobState,
  JobTalent,
  JobTalentState,
  TalentVertical,
  User,
  Scalars,
} from "src/graphql/types";
import { Box, Flex } from "src/ccl/layout";
import { Text, Icon, InfoBanner, TalentListItemProps } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import {
  jobApproved,
  jobAwaitingPayment,
  jobCompleted,
  jobConfirmed,
  jobHasRespondedTalent,
  jobIsLive,
  jobPendingApproval,
} from "src/utils/job";
import { Tooltip } from "src/ccl/feedback";
import { partitionArray } from "src/utils/array";
import { JobTalentStateLabel } from "src/ccl/jobs";
import { Loading } from "src/components";
import { ShortlistVerticals } from "src/components/ShortlistVerticals";
import { pluralize } from "src/utils/lang";
import { useHandleReview } from "src/hooks";
import { sendToAmplitude, trackEvent } from "src/utils/analytics";

function findAndApply<T>(
  jobTalent: JobTalent[],
  talentId: Scalars["ID"],
  callback: (jt: JobTalent) => T,
) {
  const jt = jobTalent.find((jt) => jt.talent.id === talentId);
  if (!jt) {
    return;
  }
  return callback(jt);
}

const ConfirmButtonContainer = ({
  children,
  showTooltip,
}: {
  showTooltip: boolean;
  children?: React.ReactNode;
}) =>
  showTooltip ? (
    <>{children}</>
  ) : (
    <Tooltip
      css={{ display: "none", "@bp3": { display: "block" } }}
      content="You must select at least 1 creative to confirm"
      alignment="left"
    >
      {children}
    </Tooltip>
  );

const ConfirmButton = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) => (
  <ConfirmButtonContainer showTooltip={!disabled}>
    <Button variant="primary" onClick={onClick} disabled={disabled}>
      Confirm job
    </Button>
  </ConfirmButtonContainer>
);

const TalentListWithArchivedShortlist = ({
  job,
  jobTalent,
  talentVerticals,
  withEmailAll,
  onReview,
}: {
  job: Job;
  jobTalent: JobTalent[];
  talentVerticals: TalentVertical[];
  withEmailAll: boolean;
  onReview?: (talent: User, rating: number) => void;
}) => {
  const [showArchived, setShowArchived] = useState(false);
  const [primaryTalent, archivedTalent]: [JobTalent[], JobTalent[]] =
    partitionArray(jobTalent, (jt) =>
      [
        JobTalentState.AwaitingConfirmation,
        JobTalentState.Confirmed,
        JobTalentState.Paid,
      ].includes(jt.state),
    );

  return (
    <>
      <ShortlistVerticals
        talent={primaryTalent.map((jt) => {
          return {
            ...jt.talent,
            phoneNumber: jt.phoneNumber,
            email: jt.email,
          };
        })}
        talentVerticals={talentVerticals}
        avatarSizes={{ width: 70, aspectRatio: 1.2 }}
        getSubHeading={(t) =>
          findAndApply<React.ReactNode>(primaryTalent, t.id, (jt) => (
            <JobTalentStateLabel jobTalent={jt} job={job} />
          ))
        }
        showEmailSubheader={jobAwaitingPayment(job.state)}
        ccEmail={job.assignedAgent?.email}
        showContactData={job.state === JobState.Finalized}
        openInNewWindow
        withEmailAll={withEmailAll}
        titleCss={
          jobCompleted(job.state)
            ? { color: "$grey6", fontWeight: "$normal" }
            : undefined
        }
        onReview={onReview}
      />
      {!!archivedTalent.length && (
        <>
          <Flex
            css={{
              justifyContent: "flex-end",
              alignItems: "center",
              py: "$5",
            }}
            onClick={() => setShowArchived(!showArchived)}
          >
            <Icon
              size={14}
              variant={showArchived ? "chevronUp" : "chevronDown"}
              css={{ mr: "$2" }}
            />
            <Text
              variant="meta"
              css={{
                textDecoration: "underline",
                cursor: "pointer",
                pl: "$2",
                whiteSpace: "nowrap",
              }}
            >
              {showArchived
                ? `Close archived shortlist (${archivedTalent.length})`
                : `View archived shortlist (${archivedTalent.length})`}
            </Text>
          </Flex>
          {showArchived && (
            <Box css={{ border: "1px solid $grey3", padding: "$6" }}>
              <Text
                variant="mini"
                css={{
                  pb: "$6",
                  fontSize: 14,
                  borderBottom: "1px solid $grey3",
                }}
              >
                {pluralize(archivedTalent.length, "creative")} previously
                shortlisted
              </Text>

              <ShortlistVerticals
                talent={archivedTalent.map((jt) => jt.talent)}
                talentVerticals={talentVerticals}
                avatarSizes={{ width: 32, aspectRatio: 1.2 }}
                getSubHeading={(t) =>
                  findAndApply(archivedTalent, t.id, (jt) => (
                    <JobTalentStateLabel jobTalent={jt} job={job} />
                  ))
                }
                disabled={true}
                titleCss={{ color: "$grey6", fontWeight: "$normal" }}
                ccEmail={job.assignedAgent?.email}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

interface ShortlistProps {
  job: Job;
  loading: boolean;
  refetchJob: () => void;
  onShareShortlist: () => void;
}

export const Shortlist = ({
  job,
  loading,
  refetchJob,
  onShareShortlist,
}: ShortlistProps) => {
  const talent = job?.talent || [];
  const users = talent?.map((t) => ({ ...t.talent, email: t.email }));

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [isConfirmJobModalShowing, setIsConfirmJobModalShowing] =
    useState(false);
  const [jobTalentToRemove, setJobTalentToRemove] = useState<JobTalent>();

  const talentVerticals = job.jobVerticals.map(({ vertical }) => vertical);
  const [handleReview, { error: reviewError, called, loading: reviewLoading }] =
    useHandleReview(job);
  const showReviewAdvice = called && !reviewLoading;

  const isReviewable = job.canReview;

  const selectedUserIds = selectedUsers.map((u) => u.id);
  const selectedJobTalent = (talent || []).filter((t) =>
    selectedUserIds.includes(t.talent.id),
  );

  const sharedTalentListProps: Partial<Omit<TalentListItemProps, "talent">> = {
    avatarSizes: { width: 70, aspectRatio: 1.2 },
    alignItems: "start",
    // eslint-disable-next-line react/display-name
    getSubHeading: (t: User) => {
      const jobTalent = talent?.find((jt) => jt.talent.id === t.id);

      if (!jobTalent) {
        return null;
      }

      return <ShortlistTalentSubheading jobTalent={jobTalent} job={job} />;
    },
  };

  const unconfirmedTalentListProps = {
    ...sharedTalentListProps,
    showEmailSubheader: jobApproved(job.state),
    isCheckable: (u: User) =>
      job.state === JobState.Approved &&
      !!findAndApply(
        talent,
        u.id,
        (jt) => jt.state === JobTalentState.Accepted,
      ),
    isChecked: (u: User) => selectedUserIds.includes(u.id),
    onCheck: (u: User, checked: boolean) => {
      if (checked) {
        setSelectedUsers([...selectedUsers, u]);
      } else {
        setSelectedUsers(selectedUsers.filter((su) => su.id !== u.id));
      }
    },
    isRemovable: () => job.state === JobState.PendingApproval,
    onRemove: (u: User) => findAndApply(talent, u.id, setJobTalentToRemove),
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ShortlistTitle job={job} talentVerticals={talentVerticals} />
      {jobApproved(job.state) && !jobHasRespondedTalent(job) && (
        <InfoBanner
          icon="alert"
          iconSize={14}
          variant="centered"
          css={{ pb: "$10", pt: "$5" }}
        >
          <Text variant="meta">
            Need to contact your shortlist? Send an email below.
          </Text>
        </InfoBanner>
      )}
      {(jobApproved(job.state) || jobPendingApproval(job.state)) && (
        <Flex
          css={{
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            mb: "$7",
          }}
        >
          <Text
            onClick={onShareShortlist}
            variant={{ "@initial": "meta", "@bp2": "body" }}
            css={{
              textDecoration: "underline",
              "&:hover": { cursor: "pointer" },
            }}
          >
            <Icon variant="document" size={14} css={{ mr: "$3" }} />
            Share shortlist
          </Text>

          {jobApproved(job.state) && (
            <ConfirmButton
              disabled={selectedUsers.length === 0}
              onClick={() => setIsConfirmJobModalShowing(true)}
            />
          )}
        </Flex>
      )}

      {showReviewAdvice && <ReviewAdvice error={reviewError} />}
      <Box data-test-id="BookerShortlist">
        {jobIsLive(job) ? (
          jobConfirmed(job.state) || jobAwaitingPayment(job.state) ? (
            <TalentListWithArchivedShortlist
              job={job}
              jobTalent={talent}
              talentVerticals={talentVerticals}
              withEmailAll={jobConfirmed(job.state)}
            />
          ) : (
            <ShortlistVerticals
              talent={users}
              talentVerticals={talentVerticals}
              withAddMore={true}
              jobSlug={job.slug}
              ccEmail={job.assignedAgent?.email}
              {...unconfirmedTalentListProps}
            />
          )
        ) : jobCompleted(job.state) ? (
          <TalentListWithArchivedShortlist
            job={job}
            jobTalent={talent}
            talentVerticals={talentVerticals}
            withEmailAll={false}
            onReview={
              isReviewable
                ? (talent, rating) => {
                    handleReview(talent, rating);
                    sendToAmplitude(
                      "booker job details page - rates a creative",
                      {
                        rating,
                        jobId: job.id,
                        talentId: talent.id,
                        jobVertical: job.jobVerticals,
                      },
                    );
                    trackEvent("bookerDashoard:jobDetails:rateCreative", {
                      rating,
                      jobId: job.id,
                      talentId: talent.id,
                    });
                  }
                : undefined
            }
          />
        ) : (
          <ShortlistVerticals
            talent={users}
            talentVerticals={talentVerticals}
            titleCss={{ color: "$grey6" }}
            {...sharedTalentListProps}
            ccEmail={job.assignedAgent?.email}
          />
        )}
      </Box>
      <RemoveJobTalentModal
        job={job}
        jobTalent={jobTalentToRemove}
        onRemove={() => {
          setJobTalentToRemove(undefined);
          refetchJob();
        }}
        onCancel={() => {
          setJobTalentToRemove(undefined);
        }}
      />

      {jobApproved(job.state) && (
        <ConfirmJobModal
          job={job}
          selectedJobTalent={selectedJobTalent}
          isOpen={isConfirmJobModalShowing}
          onCancel={() => {
            setIsConfirmJobModalShowing(false);
          }}
          refetchJob={refetchJob}
        />
      )}
    </>
  );
};
