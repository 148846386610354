import React from "react";
import { JobTalent } from "src/graphql/types";
import { Tooltip } from "src/ccl/feedback";
import { Icon } from "src/ccl/document";

export const RejectionReasonTooltip = ({
  jobTalent,
}: {
  jobTalent: JobTalent;
}) => {
  const hasReason =
    jobTalent?.formattedRejectionReason || jobTalent.rejectionNote;

  if (!hasReason) {
    return null;
  }

  const rejectionReasonDisplay =
    jobTalent?.formattedRejectionReason === "Other"
      ? `Another reason: ${jobTalent.rejectionNote}`
      : jobTalent?.formattedRejectionReason;

  return (
    <Tooltip
      css={{ alignItems: "center", display: "flex" }}
      content={rejectionReasonDisplay}
    >
      <Icon size={18} variant="information" color="red" css={{ mr: "6px" }} />
    </Tooltip>
  );
};
