import { Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { LinkButton } from "src/ccl/navigation";
import { TalentVertical } from "src/graphql/types";
import { verticalMap } from "src/utils/user";

interface NoBookmarkedCreativesProps {
  vertical?: TalentVertical;
}

export const NoBookmarkedCreatives = ({
  vertical,
}: NoBookmarkedCreativesProps) => {
  return (
    <Flex
      css={{
        padding: "100px 0px",
        flexDirection: "column",
        alignItems: "center",
        gap: "18px",
      }}
    >
      <Icon variant="bookmarkOutline" size={60} color="grey3" />
      {vertical ? (
        <Flex
          css={{ flexDirection: "column", alignItems: "center", gap: "$4" }}
        >
          <Text variant="nh4" color="grey5">
            {`You have no ${verticalMap[vertical]}s bookmarked, yet`}
          </Text>
          <LinkButton
            variant="secondaryCta"
            to={`/jobs/new/shortlist?verticals=${vertical.toLowerCase()}`}
          >
            {`Browse ${verticalMap[vertical]}s`}
          </LinkButton>
        </Flex>
      ) : (
        <Flex
          css={{ flexDirection: "column", alignItems: "center", gap: "$4" }}
        >
          <Text variant="nh4" color="grey5">
            No bookmarked creatives
          </Text>
          <LinkButton variant="secondaryCta" to={`/jobs/new/shortlist`}>
            Browse Creatives
          </LinkButton>
        </Flex>
      )}
    </Flex>
  );
};
