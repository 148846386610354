import React from "react";
import emptyCollections from "src/assets/icons/emptyCollections.svg";
import { Icon, Img, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";

export const CollectionsSideBarEmpty = ({
  onCreateCollection,
}: {
  onCreateCollection: () => void;
}) => (
  <Box
    data-test-id="BookmarkCollectionsSidebar"
    css={{
      display: "none",
      "@bp3": {
        display: "block",
      },
      top: 200,
      position: "sticky",
      border: "1px solid $grey2",
      borderRadius: "$lg",
      px: "$7",
      pt: "$8",
      pb: "$11",
      mb: "$17",
      boxShadow: "$focus",
      height: "fit-content",
    }}
  >
    <Flex css={{ flexDirection: "column", alignItems: "center" }}>
      <Icon variant="bookmark" css={{ height: 23 }} />
      <Text variant="h3" css={{ textAlign: "center", pt: "$4" }}>
        You have no collections
      </Text>

      <Box css={{ pt: "$15", pb: "$11" }}>
        <Img src={emptyCollections} aria-hidden="true" />
      </Box>
      <Text css={{ textAlign: "center", pb: "$7" }}>
        Organise your bookmarked creatives with Collections
      </Text>
      <Button variant="primary" onClick={onCreateCollection}>
        Create collection
      </Button>
    </Flex>
  </Box>
);
