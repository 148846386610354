import { useCallback, useState } from "react";

export const useToggle = (
  dependencies = [] as unknown[],
): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = useCallback(
    () => setIsOpen((current) => !current),
    [isOpen, ...dependencies],
  );

  return [isOpen, toggleModal];
};
