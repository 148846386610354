import React, { FC } from "react";
import { Flex, BoxProps } from "src/ccl/layout";
import { useUserKindContext, useStoreModel } from "src/hooks";
import { styled } from "src/ccl/stitches";
import { PatternedFooter } from "src/components/footer";

const Main = styled("main", {});

export interface PageProps extends BoxProps {
  header?: React.ReactNode;
  title?: string;
  displayFooter?: boolean;
  rebrandFooter?: boolean;
  children?: React.ReactNode;
  FooterComponent?: FC | undefined;
}

export const Page = ({
  header = null,
  children,
  displayFooter = true,
  FooterComponent,
  ...boxProps
}: PageProps) => {
  const currentUser = useStoreModel("currentUser");
  const userKindContext = useUserKindContext(currentUser);

  const onSignOut = () => {
    currentUser.logOut();
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "stretch",
        minHeight: "100vh",
      }}
      {...boxProps}
    >
      {header}

      <Main>{children}</Main>
      {displayFooter && FooterComponent ? (
        <FooterComponent />
      ) : (
        <PatternedFooter context={userKindContext} onSignOut={onSignOut} />
      )}
    </Flex>
  );
};
