import React from "react";
import { Filter } from "src/graphql/generated/types";
import { useURLNumberArraySearchParam } from "src/hooks";
import { RangeFilter } from "src/ccl/filtering";

export const UrlStateRangeFilter = ({
  filter,
  expanded,
}: {
  filter: Filter;
  expanded: boolean;
}) => {
  const [[rangeMin, rangeMax], setValues] = useURLNumberArraySearchParam(
    filter.name,
  );

  const minValue = rangeMin || filter.minValue || 0;

  const maxValue = rangeMax || filter.maxValue || 1;

  const handleSet = (nextMin: number, nextMax: number) => {
    setValues([nextMin, nextMax]);
  };

  const handleReset = () => {
    setValues([]);
  };
  let activeValues = 0;
  if (minValue !== filter.minValue) {
    activeValues += 1;
  }
  if (maxValue !== filter.maxValue) {
    activeValues += 1;
  }

  return (
    <RangeFilter
      wrapperCss={{ border: "unset" }}
      name={filter.name}
      label={filter.label}
      labelSuffix={activeValues > 0 ? activeValues : undefined}
      minimum={filter.minValue || 0}
      maximum={filter.maxValue || 1}
      values={[minValue, maxValue]}
      expanded={!!(expanded || activeValues > 0)}
      onChange={handleSet}
      onReset={activeValues > 0 ? handleReset : undefined}
      labelCss={{
        px: 0,
        py: 0,
      }}
      filterCss={{
        pb: 0,
        pt: "$3",
        px: 0,
      }}
      sliderPrefix={filter.rangeLabelPrefix}
      imperialMeasurementDisplay={filter.imperialMeasurementDisplay}
      metricMeasurementDisplay={filter.metricMeasurementDisplay}
      helperText={filter.helperText}
    />
  );
};
