import React from "react";
import type * as Stitches from "@stitches/react";
import { Avatar } from "./Avatar";
import { Flex } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";
import { getInitials } from "src/utils/lang";

interface AvatarUser {
  name: string;
  featuredAsset?: {
    mediaUrl: string;
  };
}

export interface AvatarCollectionProps {
  avatarCss?: Stitches.CSS;
  icons?: JSX.Element[];
  overlap?: number;
  reverseOverlap?: boolean;
  size?: number;
  users: AvatarUser[];
  showLeftover?: boolean;
}

export const AvatarCollection = ({
  avatarCss,
  reverseOverlap,
  icons,
  users,
  size,
  overlap,
  showLeftover,
}: AvatarCollectionProps) => {
  const usersToDisplay = users.slice(0, 5);
  const overlapMobile = overlap || 15;
  const overlapDesktop = overlap || 25;
  const sizeMobile = size || 64;
  const sizeDesktop = size || 84;

  const avatarCount =
    users.length > usersToDisplay.length && showLeftover
      ? usersToDisplay.length + 1
      : usersToDisplay.length;

  return (
    <Flex
      css={{
        mt: "auto",
        flexWrap: "wrap",
        "@bp2": { flexWrap: "nowrap" },
      }}
    >
      <Flex
        css={{
          width: `${
            avatarCount * sizeMobile - (avatarCount - 1) * overlapMobile
          }px`,
          "@bp2": {
            mr: "$3",
            width: `${
              avatarCount * sizeDesktop - (avatarCount - 1) * overlapDesktop
            }px`,
          },
        }}
      >
        {usersToDisplay.map(({ name, featuredAsset }, index) => (
          <Flex
            key={index}
            css={{
              position: "relative",
              zIndex: tokens.zIndices[300] - index * (reverseOverlap ? -1 : 1),
              left: `${-overlapMobile * index}px`,
              "@bp2": {
                left: `${-overlapDesktop * index}px`,
              },
            }}
          >
            <Avatar
              css={{
                width: `${sizeMobile}px`,
                height: `${sizeMobile}px`,
                "@bp2": {
                  width: `${sizeDesktop}px`,
                  height: `${sizeDesktop}px`,
                  border: `${sizeDesktop < 60 ? "2" : "4"}px solid $white`,
                },
                border: `${sizeMobile < 60 ? "2" : "4"}px solid $white`,
                ...avatarCss,
              }}
              initials={getInitials(name)}
              imageUrl={featuredAsset?.mediaUrl}
            />
            {icons && icons.length === usersToDisplay.length ? (
              icons[index]
            ) : (
              <></>
            )}
          </Flex>
        ))}

        {showLeftover && users.length > usersToDisplay.length && (
          <Flex
            css={{
              position: "relative",
              zIndex: tokens.zIndices[300] - 5 * (reverseOverlap ? -1 : 1),
              left: `${-overlapMobile * 5}px`,
              "@bp2": {
                left: `${-overlapDesktop * 5}px`,
              },
            }}
          >
            <Avatar
              css={{
                background: "white",
                width: `${sizeMobile}px`,
                height: `${sizeMobile}px`,
                border: `${sizeMobile < 60 ? "2" : "4"}px solid $grey2`,
                "@bp2": {
                  width: `${sizeDesktop}px`,
                  height: `${sizeDesktop}px`,
                  border: `${sizeDesktop < 60 ? "2" : "4"}px solid $grey2`,
                },

                ...avatarCss,
              }}
              fontVariant="mini"
              fontColor="grey6"
              initials={`+${users.length - usersToDisplay.length}`}
              imageUrl={undefined}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
