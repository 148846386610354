import {
  Route,
  useLocation,
  useParams,
  Switch,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LegacyPortfolio } from "./edit/LegacyPortfolio";
import { LegacyProfile } from "./edit/LegacyProfile";
import { ADEditProfileNav } from "src/components/dashboards/agents";
import { useTiara, useVerticalConfigurations } from "src/hooks";
import { Loading, StickyActionBar } from "src/components";
import { LegacyContainer, Box, Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { Query, TalentProfile } from "src/graphql/types";
import { AGENT_TALENT_USER_QUERY } from "src/graphql/queries";
import { getConfigForVertical } from "src/utils/user";
import { AGENCY_TALENT_USER_QUERY } from "src/graphql/queries/agency/talent/TalentUser";

interface LocationState {
  name: string;
}

interface TalentEditParams {
  id?: string;
  slug?: string;
}

export const LegacyEditTalent = () => {
  const { path } = useRouteMatch();
  const { id, slug } = useParams<TalentEditParams>();

  const {
    data,
    error: userError,
    loading: userLoading,
  } = useQuery<Query>(id ? AGENCY_TALENT_USER_QUERY : AGENT_TALENT_USER_QUERY, {
    variables: {
      id,
      slug,
    },
  });

  const {
    loading: verticalConfigLoading,
    error: verticalConfigError,
    configurations: verticalConfigs,
  } = useVerticalConfigurations();

  const responseData = id ? data : data?.me;
  const agency = id ? responseData?.agency : undefined;
  const user = responseData?.agency?.talent;
  const talentProfile = user?.profile as TalentProfile;
  const vertical = talentProfile?.vertical;
  const verticalConfig = getConfigForVertical(verticalConfigs || [], vertical);
  const { state } = useLocation<LocationState>();
  const name = user?.name || state?.name || slug?.replace("-", " ");

  const title = user ? `Edit ${name}` : "";
  useTiara({ title: title });

  const error = userError || verticalConfigError;
  const loading = userLoading || verticalConfigLoading;

  const LoadingEditPage = () => {
    return (
      <>
        <LegacyContainer variant="gutter">
          <Box
            css={{
              display: "none",
              "@bp2": { display: "none" },
              "@bp3": { display: "block" },
            }}
          >
            <Box
              css={{
                mb: "$5",
                "@bp5": { mb: "$9" },
              }}
            >
              <Text variant="h1">{name}</Text>
            </Box>
          </Box>

          <Flex css={{ flexWrap: "wrap", alignItems: "stretch" }}>
            <ADEditProfileNav user={user} />

            <Box css={{ width: "100%", "@bp2": { width: "67%" } }}>
              <Loading />
            </Box>
          </Flex>
        </LegacyContainer>

        <Box css={{ display: "initial", "@bp2": { display: "none" } }}>
          <StickyActionBar css={{ zIndex: 0 }}>
            <Text variant="h3">{name}</Text>
          </StickyActionBar>
        </Box>
      </>
    );
  };

  if (loading || !user) {
    return <>{LoadingEditPage()}</>;
  }

  if (!verticalConfig || error) {
    return <Text variant="h4">Oops, something went wrong</Text>;
  }

  return (
    <Switch>
      <Route path={`${path}/profile`}>
        <LegacyProfile
          agency={agency}
          user={user}
          verticalConfig={verticalConfig}
        />
      </Route>
      <Route exact path={`${path}/portfolio`}>
        <LegacyPortfolio agency={agency} user={user} vertical={vertical} />
      </Route>

      <Redirect
        exact
        from="/dashboard/agent/talent/:slug/edit"
        to="/dashboard/agent/talent/:slug/edit/profile"
      />
    </Switch>
  );
};

export default LegacyEditTalent; // eslint-disable-line import/no-default-export
