import { computed, action, thunk, Computed, Action, Thunk } from "easy-peasy";
import { isDev } from "src/config";
import { Status } from "src/entities";
import { StoreModel } from "src/models/index";

interface S3UploadReq {
  url: string;
  headers: Headers;
  body: string | Blob;
}

type S3UploadRes = string;

export type ValueWrapper<T> = [T]; // https://github.com/ctrlplusb/easy-peasy/issues/361

interface S3DataModel<Req, Res> {
  status: Status;
  loading: Computed<S3DataModel<Req, Res>, boolean>;
  error: Error | null;
  _data: ValueWrapper<Res | null>;
  data: Computed<S3DataModel<Req, Res>, Res | null>;
  setStatus: Action<S3DataModel<Req, Res>, Status>;
  setError: Action<S3DataModel<Req, Res>, Error | null>;
  setData: Action<S3DataModel<Req, Res>, Res | null>;
  reset: Thunk<S3DataModel<Req, Res>>;
  onRequestStart: Thunk<S3DataModel<Req, Res>, { req: Req }>;
  onRequestSuccess: Thunk<S3DataModel<Req, Res>>;
  onRequestError: Thunk<S3DataModel<Req, Res>, { req: Req; error: Error }>;
  onNavigation: Thunk<S3DataModel<Req, Res>>;
}

interface S3UploadModel<Req, Res> extends S3DataModel<Req, Res> {
  request: Thunk<
    S3UploadModel<Req, Res>,
    Req,
    void,
    StoreModel,
    Promise<boolean | void> & { unsubscribe: () => void }
  >;
}

const createS3DataModel = <Req, Res>(): S3DataModel<Req, Res> => ({
  status: "pending",
  loading: computed((state) => state.status === "loading"),
  error: null,
  _data: [null],
  data: computed((state) => state._data[0]),
  setStatus: action((state, payload) => {
    state.status = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setData: action((state, payload) => {
    state.data = payload;
  }),
  reset: thunk((actions) => {
    actions.setStatus("pending");
    actions.setError(null);
    actions.setData(null);
  }),
  onRequestStart: thunk((actions) => {
    actions.setStatus("loading");
    actions.setError(null);
  }),
  onRequestSuccess: thunk((actions) => {
    actions.setStatus("loaded");
    actions.setError(null);
  }),
  onRequestError: thunk((actions, { error }) => {
    if (isDev) console.error(error);
    actions.setStatus("error");
    actions.setError(error);
  }),
  onNavigation: thunk((actions, _, { getState }) => {
    if (getState().status !== "pending") {
      actions.reset();
    }
  }),
});

export interface S3Model {
  uploadFile: S3UploadModel<S3UploadReq, S3UploadRes>;
}

export const s3Model: S3Model = {
  uploadFile: {
    ...createS3DataModel(),
    request: thunk((actions, payload) => {
      const req = payload;

      const promise = (async () => {
        actions.onRequestStart({ req });
        try {
          const requestInit: RequestInit = {
            method: "PUT",
            mode: "cors",
            headers: req.headers,
            body: req.body,
          };

          const response = await fetch(req.url, requestInit);

          if (!response.ok) {
            throw new Error(`S3 error: ${response.status}`);
          }

          if (response.status >= 200 && response.status < 300) {
            actions.onRequestSuccess();
            return true;
          } else {
            return false;
          }
        } catch (error) {
          if (error instanceof Error) {
            actions.onRequestError({ req, error });
          }
        }
      })();
      return Object.assign(promise, { unsubscribe: () => {} });
    }),
  },
};
