import { useEffect, useState } from "react";
import { escapeRegExp } from "lodash";
import addImage from "src/assets/icons/addImage.svg";
import {
  ModalProps,
  Modal,
  Text,
  DroppableListItem,
  CreateNewListItem,
} from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { Input } from "src/ccl/data-entry";
import { UploadedFile } from "src/entities";

export const AddToCategoriesModal = <
  Image extends {
    id: string;
    mediaUrl: string;
    sortWeight: number;
    fileData?: { key: string } & UploadedFile;
  },
  Category extends { name: string; portfolioImages: Image[] },
>({
  categories,
  onCreateNewCategory,
  onClose,
  isOpen,
  onAddToCategories,
  selectedImages,
}: {
  categories: Category[];
  selectedImages: Image[];
  onCreateNewCategory: () => void;
  onAddToCategories: (selectedCategories: Category[]) => void;
} & ModalProps) => {
  const [categoryName, setCategoryName] = useState("");
  const categoryNameRegexp = new RegExp(escapeRegExp(categoryName), "i");

  const [selectedCategories, setSelectedCategories] = useState<Category[]>(
    categories.filter(({ portfolioImages }) =>
      selectedImages.every(({ id: selectedId }) =>
        portfolioImages.some(({ id }) => id === selectedId),
      ),
    ),
  );

  useEffect(() => {
    setSelectedCategories(
      categories.filter(({ portfolioImages }) =>
        selectedImages.every(({ id: selectedId }) =>
          portfolioImages.some(({ id }) => id === selectedId),
        ),
      ),
    );
  }, [selectedImages]);

  useEffect(() => {
    if (!isOpen && selectedCategories.length) {
      setSelectedCategories([]);
    }
  }, [isOpen]);

  return (
    <Modal
      showCloseButton={true}
      onClose={onClose}
      isOpen={isOpen}
      width="responsive"
      parentCss={{ "@bp3": { px: "$11", py: "$6" } }}
      withTitlePadding={false}
    >
      <Flex
        data-test-id="AddToCategoriesModal"
        css={{
          flexDirection: "column",
          gap: "$9",
        }}
      >
        <Text variant="nh3">Add to category</Text>
        <Flex
          css={{
            alignItems: "center",
            pr: "$7",
          }}
        >
          <Input
            variant="rebrand"
            placeholder="Search categories"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            css={{ border: "unset" }}
          />
        </Flex>
        <Flex
          css={{
            maxHeight: "60vh",
            flexDirection: "column",
            gap: "$4",
            overflowY: "scroll",
            mr: "-8px",
            pr: "$7",
            mb: "$8",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "$grey3",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "$grey2",
              borderRadius: "5px",
              height: "81px",
            },
          }}
        >
          <CreateNewListItem
            onCreateNewItem={onCreateNewCategory}
            cta="Add new category"
            boxHeight={60}
          />
          {(categories || [])
            .filter(({ name }) => name.match(categoryNameRegexp))
            .map((category, i) => (
              <DroppableListItem
                key={i}
                isActive={
                  !!selectedCategories.find(
                    ({ name: selectedName }) => category.name === selectedName,
                  )
                }
                imageSize={60}
                name={category.name}
                droppableId={`${category.name}-modal`}
                image={category.portfolioImages?.[0]?.mediaUrl}
                count={category.portfolioImages.length}
                entity="photo"
                onClick={() =>
                  setSelectedCategories(selectedCategories.concat(category))
                }
                emptyIcon={addImage}
              />
            ))}
        </Flex>
        <Flex
          css={{
            flexDirection: "column-reverse",
            gap: "18px",
            "@sm": {
              flexDirection: "row",
              justifyContent: "space-between",
            },
          }}
        >
          <Button
            variant="secondaryCta"
            onClick={onClose}
            css={{
              width: "100%",
              "@sm": {
                width: "fit-content",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primaryCta"
            css={{
              width: "100%",
              "@sm": {
                width: "fit-content",
              },
            }}
            onClick={() => {
              onAddToCategories(selectedCategories);
            }}
          >
            Add to category
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
