import { gql } from "@apollo/client";

export const CREATE_TALENT_REVIEW_MUTATION = gql`
  mutation CreateTalentReview(
    $jobId: ID!
    $rating: Int!
    $reviewerId: ID!
    $talentId: ID!
    $createdAt: ISO8601Date
  ) {
    createTalentReview(
      jobId: $jobId
      rating: $rating
      reviewerId: $reviewerId
      talentId: $talentId
      createdAt: $createdAt
    ) {
      id
      rating
    }
  }
`;
