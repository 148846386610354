import { gql } from "@apollo/client";

export const ADDRESS_DETAILS_FRAGMENT = gql`
  fragment AddressDetails on Address {
    addressLine1
    addressLine2
    city
    country
    postcode
  }
`;
