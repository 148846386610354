import React from "react";
import { capitalize } from "lodash";
import { Icon, Img, Text } from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import bookmarksGold from "src/assets/icons/bookmarksGold.svg";
import { TalentVertical, User } from "src/graphql/types";
import { Link } from "src/ccl/navigation";
import { verticalMap } from "src/utils/user";
import { styled } from "src/ccl/stitches";
import { sendToAmplitude } from "src/utils/analytics";
import { urlAddQuery } from "src/utils/url";

const GoldLargeBookmark = styled(Box, {
  flexShrink: "0",
  background: `url(${bookmarksGold}) no-repeat center`,
  width: "42px",
  height: "72px",
  position: "relative",
  bottom: "$3",
  textAlign: "center",
});

const GridImage = ({
  src,
  gridArea = "auto",
}: {
  src: string;
  gridArea?: string;
}) => (
  <Img
    src={`${src}?auto=format&fit=crop&dpr=1&crop=faces&h=108&w=162`}
    css={{
      width: "100%",
      height: "54px",
      objectFit: "cover",
      gridArea: gridArea,
      "@bp2": {
        height: "74px",
      },
      "@bp5": {
        height: "108px",
      },
      "&:nth-child(n+1)": {
        borderBottom: "2px solid $white",
        borderLeft: "2px solid $white",
      },
      "&:first-child": {
        borderRadius: "8px 0px 0px 0px",
      },
      "&:last-child": {
        borderRadius: "0px 8px 0px 0px",
      },
      "&:only-child": {
        borderRadius: "0px 8px 0px 0px",
      },
    }}
  />
);

const ImageGrid = ({ members }: { members: User[] }) => {
  return (
    <Grid
      css={{
        gridTemplateAreas: members.length > 1 ? undefined : '". talent"',
        gridColumns: members.length > 2 ? 3 : 2,
      }}
    >
      {members.map(
        ({ featuredAsset }, i) =>
          featuredAsset && (
            <GridImage
              key={i}
              src={featuredAsset?.mediaUrl}
              gridArea={members.length > 1 ? undefined : "talent"}
            />
          ),
      )}
    </Grid>
  );
};

export const CollectionCard = ({
  name,
  members,
  linkTo,
  talentVerticals,
  memberCount,
  collectionCardAmplitudeData,
}: {
  name: string;
  members: User[];
  linkTo: string;
  talentVerticals: TalentVertical[];
  memberCount: number;
  collectionCardAmplitudeData?: string;
}) => {
  const creativeType =
    talentVerticals.length > 1
      ? "Multiple talent types"
      : `${verticalMap[talentVerticals[0]]}s`;

  const url = urlAddQuery(
    members[memberCount - 1]?.featuredAsset?.mediaUrl,
    "auto=format&fit=crop&dpr=1&crop=faces",
  );
  const mainTalentImage = `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1)), url("${url}")`;

  return (
    <Link
      to={linkTo}
      css={{ textDecoration: "none" }}
      data-test-id={`CollectionCard-${name}`}
      onClick={() =>
        collectionCardAmplitudeData &&
        sendToAmplitude(collectionCardAmplitudeData)
      }
    >
      <Flex
        css={{
          backgroundColor: "$grey2",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            memberCount > 0 ? `${mainTalentImage}h=217&ar=1.5:1")` : undefined,
          height: "217px",
          borderRadius: "8px",
          flexDirection: "column",
          justifyContent: "space-between",
          minWidth: "250px",
          "@bp2": {
            width: "222px",
            height: "296px",
            backgroundImage:
              memberCount > 0
                ? memberCount === 3
                  ? `${mainTalentImage}h=340&ar=1:1.3&pad-top=20")`
                  : `${mainTalentImage}h=340&ar=1:1.3")`
                : undefined,
          },
          "@bp4": {
            width: "250px",
            height: "338px",
          },
        }}
      >
        {memberCount === 0 ? (
          <Flex
            css={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Icon
              variant="community"
              css={{
                width: 110,
                height: 110,
                "@bp2": { mt: "10%" },
                "@bp5": {
                  mt: "20%",
                  width: "156px",
                  height: "156px",
                },
              }}
            />
          </Flex>
        ) : (
          <ImageGrid members={members.slice(-4, -1)} />
        )}
        <Flex
          css={{
            alignItems: "flex-end",
            gap: "9px",
            ml: "12px",
            mr: "14px",
          }}
        >
          <GoldLargeBookmark>
            <Text css={{ pt: "$5", fontWeight: "$bold" }}>{memberCount}</Text>
          </GoldLargeBookmark>
          <Box css={{ mb: "18px", width: "75%" }}>
            <Text
              css={{
                overflow: "hidden",
                display: "-webkit-box",
                "-webkit-line-clamp": 2,
                "-webkit-box-orient": "vertical",
                color: memberCount && memberCount > 0 ? "$white" : "$black",
                fontWeight: "bold",
              }}
            >
              {name}
            </Text>

            <Text
              variant="meta"
              css={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                minWidth: "140px",
                textOverflow: "ellipsis",
                color: "$white",
                display: memberCount && memberCount > 0 ? "block" : "none",
                "@bp2": {
                  width: "140px",
                },
                "@bp4": {
                  width: "240px",
                },
              }}
            >
              {capitalize(creativeType)}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Link>
  );
};
