import { gql } from "@apollo/client";

export const RESOLVE_DISPUTE_MUTATION = gql`
  mutation ResolveJobDispute($id: ID!) {
    resolveJobDispute(id: $id) {
      resolvedBy {
        id
      }
    }
  }
`;
