import { gql } from "@apollo/client";

export const FINALIZE_INVOICE_MUTATION = gql`
  mutation FinalizeInvoice(
    $invoiceId: ID!
    $trustshareInvoiceId: String!
    $trustshareCheckoutId: String!
  ) {
    finalizeInvoice(
      invoiceId: $invoiceId
      trustshareInvoiceId: $trustshareInvoiceId
      trustshareCheckoutId: $trustshareCheckoutId
    ) {
      success
    }
  }
`;
