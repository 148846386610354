import React, { useState } from "react";
import * as Yup from "yup";
import { ApolloError } from "@apollo/client";
import { Form, Formik } from "formik";
import { Box, Flex } from "src/ccl/layout";
import { LineItem, LineItemKind } from "src/graphql/types";
import { Text } from "src/ccl/document";
import { FormTab, Tab, TabbedContainer } from "src/ccl/blocks";
import { Field, FormikCheckbox, FormikInput } from "src/ccl/data-entry";
import { ValidationBlock } from "src/ccl/feedback";
import { Button } from "src/ccl/navigation";

const FormFieldContainer = ({ children }: { children?: React.ReactNode }) => (
  <Flex
    css={{
      spaceX: 0,
      mt: "$4",
      flexDirection: "column",
    }}
  >
    {children}
  </Flex>
);

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError("Must be a number")
    .required("Required")
    .min(0.01, "Must be more than £0")
    .max(10000, "Must be less than £10,000")
    .test("is-decimal", "Must be a valid monetary amount", (value) =>
      /^\d{1,5}\.?\d{0,2}$/.test(value + ""),
    ),
  description: Yup.string().required("Required"),
  manuallyPaid: Yup.boolean(),
});

interface AdditionalPaymentFormProps {
  onSubmit: (
    lineItem: Omit<LineItem, "id" | "jobTalentId" | "alreadyPaid">,
  ) => void;
  createPaymentError?: ApolloError;
  onClose?: () => void;
  loading: boolean;
}
export const AdditionalPaymentFormV2: React.FC<AdditionalPaymentFormProps> = ({
  onSubmit,
  createPaymentError,
  onClose,
  loading,
}) => {
  const [paymentKind, setPaymentKind] = useState<LineItemKind>(
    LineItemKind.Expense,
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        amount: 0,
        description: "",
        manuallyPaid: false,
      }}
      onSubmit={() => {}}
    >
      {({ resetForm, values, setValues, validateForm }) => (
        <Form>
          <Text variant="title" weight="bold" css={{ mb: "$3" }}>
            Add additional payment
          </Text>

          <TabbedContainer
            backgroundColor="$white"
            initialActiveId="expense"
            css={{ height: "420px" }}
            onTabChange={(newPaymentKind) => {
              if (newPaymentKind === "expense") {
                setPaymentKind(LineItemKind.Expense);
              } else if (newPaymentKind === "additionalFee") {
                setValues({
                  ...values,
                  manuallyPaid: false,
                });
                setPaymentKind(LineItemKind.AdditionalFee);
              }
            }}
          >
            <Tab
              id="expense"
              title={(active) => (
                <FormTab
                  active={active}
                  title="Expense"
                  icon="expense"
                  rebrand
                  boxCss={{
                    backgroundColor: "$white",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "$3",
                    px: "$5",
                    py: "7",
                    ...(active && {
                      borderBottom: "2px solid $black",
                    }),
                    marginBottom: "25px",
                  }}
                />
              )}
            >
              <FormFieldContainer>
                <Box>
                  <Field label="Amount" name="amount" labelCss={{ mb: "$5" }}>
                    <FormikInput
                      name="amount"
                      inputPrefix="£"
                      type="number"
                      variant="rebrand"
                      placeholder="0"
                      css={{ borderColor: "$grey2" }}
                    />
                  </Field>

                  <Field
                    label="Description"
                    name="description"
                    labelCss={{ mb: "$5" }}
                  >
                    <FormikInput
                      name="description"
                      type="text"
                      placeholder="e.g. train tickets to London"
                      variant="rebrand"
                      css={{ borderColor: "$grey2" }}
                    />
                  </Field>
                </Box>

                <Box>
                  <Flex css={{ spaceX: "$4" }}>
                    <Field name="manuallyPaid">
                      <FormikCheckbox
                        name="manuallyPaid"
                        css={{ width: 24, height: 24 }}
                        variant="rebrand"
                      />
                    </Field>
                    <Box>
                      <Text weight="bold">Already reimbursed?</Text>
                      <Text variant="meta" color="grey6">
                        If the talent has already been paid for this expense
                        (e.g. if we paid it for them using the company card)
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </FormFieldContainer>
            </Tab>

            <Tab
              id="additionalFee"
              title={(active) => (
                <FormTab
                  active={active}
                  title="Additional fee"
                  titleCondensed="Fee"
                  icon="additionalFee"
                  rebrand
                  boxCss={{
                    backgroundColor: "$white",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "$3",
                    px: "$5",
                    py: "7",
                    ...(active && {
                      borderBottom: "2px solid $black",
                    }),
                    color: "$grey4",
                    marginBottom: "25px",
                  }}
                />
              )}
            >
              <FormFieldContainer>
                <Box>
                  <Field label="Amount" name="amount" labelCss={{ mb: "$5" }}>
                    <FormikInput
                      name="amount"
                      inputPrefix="£"
                      type="number"
                      variant="rebrand"
                      placeholder="0"
                      css={{ borderColor: "$grey2" }}
                    />
                  </Field>
                </Box>

                <Box>
                  <Field
                    label="Description"
                    name="description"
                    labelCss={{ mb: "$5" }}
                  >
                    <FormikInput
                      name="description"
                      placeholder="e.g. train tickets to London"
                      type="text"
                      variant="rebrand"
                      css={{ borderColor: "$grey2" }}
                    />
                  </Field>
                </Box>
              </FormFieldContainer>
            </Tab>
          </TabbedContainer>
          {createPaymentError && (
            <ValidationBlock
              variant="error"
              size="sm"
              css={{ my: "$3" }}
              title="Couldn't create additional payment"
              body={createPaymentError.message}
            />
          )}
          <Flex
            css={{
              flexDirection: "column-reverse",
              mt: "$9",
              justifyContent: "space-between",
              "@bp2": { flexDirection: "row" },
            }}
          >
            <Button
              variant="primaryPillBordered"
              onClick={onClose}
              css={{ width: "100%", "@bp2": { width: "initial" } }}
            >
              Cancel
            </Button>

            <Button
              variant="primaryPillV2"
              type="submit"
              disabled={loading}
              onClick={() => {
                validateForm(values).then(
                  ({ amount, description, manuallyPaid }) => {
                    if (amount || description || manuallyPaid) {
                      return;
                    }
                    onSubmit({
                      ...values,
                      currency: "GBP",
                      kind: paymentKind,
                      outboundPayment: undefined,
                    });
                    resetForm();
                  },
                );
              }}
              css={{
                borderRadius: 10,
                width: "100%",
                mb: "$5",
                "@bp2": { width: "initial", mb: 0 },
              }}
            >
              {loading ? "Adding payment..." : "Add payment"}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
