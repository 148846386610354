import React, { useState } from "react";
import { format, formatDistanceToNow } from "date-fns";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { Query, ActivityLog } from "src/graphql/types";
import { Flex, Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { Loading } from "src/components/Loading";
import { parseLinks } from "src/utils/linkParser";
import { Button } from "src/ccl/navigation";
import { extractNodes } from "src/utils/lang";
import { AGENT_DASHBOARD_JOBS_ACTIVITY_STREAM_QUERY } from "src/graphql/mutations";

interface ActivityBoxProps {
  activity: ActivityLog;
}

const ActivityBox = ({ activity }: ActivityBoxProps) => {
  const location = useLocation();

  return (
    <>
      <Box
        css={{
          background: "$white",
          p: "$6",
          border: "0.5px $grey6 solid",
        }}
      >
        <Text
          weight="bold"
          css={{ fontSize: "$14" }}
          title={format(new Date(activity.timestamp), "dd/MM/yyyy HH:mm:ss")}
        >
          {formatDistanceToNow(new Date(activity.timestamp))} ago
        </Text>

        <Text css={{ fontSize: "$14" }}>
          {activity.username}{" "}
          {parseLinks(
            activity.message,
            location.key,
            "agencyDashboard:activityStream",
          )}
        </Text>
      </Box>
    </>
  );
};

export const ActivityStream = () => {
  const { data, loading, fetchMore } = useQuery<Query>(
    AGENT_DASHBOARD_JOBS_ACTIVITY_STREAM_QUERY,
    {
      variables: {
        first: 20,
      },
    },
  );
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const activityStream = extractNodes<ActivityLog>(data?.me?.activityStream);
  const pageInfo = data?.me?.activityStream?.pageInfo;
  const hasMore = pageInfo?.hasNextPage || false;
  const endCursor = pageInfo?.endCursor || "";

  return (
    <Box css={{ width: "100%", backgroundColor: "$grey1" }}>
      {loading && <Loading />}
      <Box css={{ overflowY: "scroll" }}>
        <Flex
          css={{
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "$6",
            p: "$6",
            width: activityStream.length * 375,
          }}
        >
          {activityStream.map((activity, i) => (
            <ActivityBox key={i} activity={activity} />
          ))}

          {hasMore && (
            <Button
              css={{ mt: "auto", mb: "auto" }}
              variant="primary"
              onClick={async () => {
                setIsLoadingMore(true);
                await fetchMore({
                  variables: {
                    after: endCursor,
                  },
                });
                setIsLoadingMore(false);
              }}
            >
              {isLoadingMore ? "Loading..." : "Load more"}
            </Button>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
