import * as Yup from "yup";
import { FormikValues } from "formik";
import { getValidationErrors } from "./validationHelpers";

export const validateAccount = async (
  values: FormikValues,
  currentPage: string,
) => {
  const schema: Record<string, Yup.SchemaOf<unknown>> = {
    firstName: Yup.string().trim().required("Required"),
    lastName: Yup.string().trim().required("Required"),
    email: Yup.string()
      .trim()
      .required("Required")
      .email("Must be a valid email address"),
    phoneNumber: Yup.string().trim().required("Required"),
    company: Yup.string().trim().required("Required"),
    kind: Yup.string().trim().required("Required"),
    role: Yup.string().trim().required("Required"),
    headcountEstimate: Yup.string().trim().required("Required"),
    website: Yup.string().trim().required("Required"),
    surfacedFrom: Yup.string().trim().required("Required"),
    password:
      currentPage === "details"
        ? Yup.string().optional()
        : Yup.string()
            .trim()
            .required("Required")
            .min(8, "Password is too short - should be 8 chars minimum.")
            .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  };

  return getValidationErrors(values, Yup.object().shape(schema));
};
