import React from "react";
import type * as Stitches from "@stitches/react";
import { Input } from "src/ccl/data-entry/input";
import {
  SelectReact,
  SelectReactProps,
} from "src/ccl/data-entry/select/SelectReact";
import { mergeCss } from "src/ccl/stitches";
import { Flex } from "src/ccl/layout";

interface SelectReactWithInputProps
  extends Omit<SelectReactProps, "value" | "onChange"> {
  value?: string;
  onChange: (value: string) => void;
  inputCSS?: Stitches.CSS;
  id?: string;
}

export const SelectReactWithInput = ({
  value,
  options,
  placeholder,
  onChange,
  isDisabled,
  inputCSS = {},
  id,
}: SelectReactWithInputProps) => {
  const splitValue = (value: string) => {
    if (isDisabled) {
      return ["", value];
    }

    let [inputValue, selectValue] = value.split(" ", 2);

    if (!inputValue || isNaN(parseInt(inputValue))) {
      selectValue = inputValue;
      inputValue = "";
    }

    if (!selectValue) {
      selectValue = options?.[0].value || "";
    }
    return [inputValue, selectValue];
  };
  const [inputValue, selectValue] = splitValue(value || "");

  const handleChange = (inputValue: string, optionValue: string) => {
    if (/\D/.test(inputValue)) {
      return;
    }

    onChange([inputValue, optionValue].join(" ").trim());
  };

  return (
    <Flex
      css={{
        position: "relative",
      }}
    >
      {!isDisabled && (
        <Input
          name={id}
          type="text"
          value={inputValue}
          placeholder={placeholder}
          onChange={(e) => {
            handleChange(e.target.value, selectValue);
          }}
          css={mergeCss(
            {
              border: "none",
              position: "absolute",
              zIndex: "$100",
              p: "$0",
              maxWidth: "170px",
              top: "6px",
              left: "18px",

              "@bp2": {
                maxWidth: "46px",
              },
            },
            inputCSS,
          )}
        />
      )}
      <SelectReact
        id={id}
        variant="rebrand"
        value={{
          label: selectValue.replaceAll("_", " ").toUpperCase(),
          value: selectValue,
        }}
        options={options}
        onChange={(option) => {
          handleChange(inputValue, option?.value || "");
        }}
        fontSize={14}
        fontWeight="bold"
        withInput={!isDisabled}
      />
    </Flex>
  );
};
