import React from "react";
import type * as Stitches from "@stitches/react";
import { mergeCss } from "src/ccl/stitches";
import { LegacyContainer } from "src/ccl/layout";
import { Button, ButtonVariants } from "src/ccl/navigation";
import { Panel, Text } from "src/ccl/document";
interface CJMobileStickyCTAProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  buttonType?: "button" | "submit";
  css?: Stitches.CSS;
  infoBox?: string;
  buttonVariant?: keyof typeof ButtonVariants;
}

export const CJMobileStickyCTA = ({
  text,
  onClick = () => {},
  disabled = false,
  buttonType = "submit",
  css = {},
  infoBox,
  buttonVariant = "primary",
}: CJMobileStickyCTAProps) => (
  <LegacyContainer
    css={mergeCss(
      {
        position: "sticky",
        bottom: 18,
        zIndex: "$200",
        px: "$5",
        "@bp4": { display: "none" },
      },
      css,
    )}
  >
    {infoBox && (
      <Panel
        css={{ alignItems: "end", mb: "$4", justifyContent: "center" }}
        contentCss={{ width: "fit-content" }}
        iconSize={16}
        icon="information"
      >
        <Text variant="meta">{infoBox}</Text>
      </Panel>
    )}
    <Button
      data-test-id="MobileStickyCTA"
      variant={buttonVariant}
      css={{ width: "100%", height: "$16", boxShadow: "$focus" }}
      onClick={onClick}
      disabled={disabled}
      type={buttonType}
    >
      {text}
    </Button>
  </LegacyContainer>
);
