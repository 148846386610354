import { gql } from "@apollo/client";
import { NOTE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const CREATE_JOB_NOTE_MUTATION = gql`
  mutation CreateJobNote($jobId: ID!, $body: String!) {
    createJobNote(jobId: $jobId, body: $body) {
      ...NoteDetails
    }
  }
  ${NOTE_DETAILS_FRAGMENT}
`;
