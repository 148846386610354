import React, { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { ModalProps, Modal, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import {
  Mutation,
  MutationCreateSavedTalentCollectionArgs,
} from "src/graphql/types";
import { CREATE_COLLECTION_MUTATION } from "src/graphql/mutations";
import { Field, FormikInput } from "src/ccl/data-entry";
import { sendToAmplitude } from "src/utils/analytics";
import { ValidationBlock } from "src/ccl/feedback";

interface CreateCollectionModalProps extends ModalProps {
  analyticsEventPage?: string;
  setNewCollection?: Dispatch<SetStateAction<string>>;
  analyticsProperty?: string;
  redirectTo?: string;
}

export const CreateCollectionModal = ({
  setNewCollection,
  onClose,
  isOpen,
  analyticsEventPage,
  analyticsProperty,
  redirectTo,
}: CreateCollectionModalProps) => {
  const history = useHistory();

  const [createCollection, { error: createCollectionError, reset }] =
    useMutation<Mutation, MutationCreateSavedTalentCollectionArgs>(
      CREATE_COLLECTION_MUTATION,
    );

  return (
    <Modal
      showCloseButton={true}
      onClose={() => {
        reset();
        onClose?.();
      }}
      isOpen={isOpen}
    >
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={async ({ name }) => {
          const resp = await createCollection({
            variables: {
              name,
            },
          });

          if (resp.data?.createSavedTalentCollection) {
            onClose?.();
            analyticsEventPage &&
              sendToAmplitude(
                `${analyticsEventPage} - creates new collection`,
                { agencyID: analyticsProperty },
              );
            const id = resp.data.createSavedTalentCollection.id;
            if (setNewCollection) {
              setNewCollection(id);
            }
            if (redirectTo) {
              history.push(
                `${redirectTo}/${resp.data?.createSavedTalentCollection.slug}`,
              );
            }
          }
        }}
      >
        {() => (
          <Form data-test-id="CreateCollectionModal">
            <Flex
              css={{
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <Text variant="nh3">Create new collection</Text>
              {createCollectionError && (
                <ValidationBlock
                  variant="error"
                  title="Oops, there's a problem"
                  body={createCollectionError.message}
                  css={{ mt: "$9" }}
                />
              )}
              <Field
                variant="b2Bold"
                name="name"
                label="Name your collection"
                css={{ marginBottom: "0" }}
              >
                <FormikInput
                  variant="rebrand"
                  placeholder="Collection name"
                  name="name"
                  type="string"
                />
              </Field>

              <Flex
                css={{
                  justifyContent: "flex-start",
                  flexDirection: "column-reverse",
                  gap: "18px",
                  "@sm": {
                    justifyContent: "space-between",
                    flexDirection: "row",
                  },
                }}
              >
                <Button
                  variant="secondaryCta"
                  type="reset"
                  onClick={() => {
                    reset();
                    onClose?.();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primaryCta"
                  type="submit"
                  data-test-id="create-button"
                >
                  Create new collection
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
