import React from "react";
import * as Stitches from "@stitches/react";
import { isSingleSizeValue } from "./helpers";
import { AssetImageSize } from "./types";
import { AssetImageType } from ".";
import { Box } from "src/ccl/layout";
import { mergeCss, ResponsiveValue } from "src/ccl/stitches";

const placeholderAspectRatio = (
  asset: AssetImageType,
  size: AssetImageSize,
) => {
  if (size.aspectRatio) {
    return size.aspectRatio;
  }

  if (size.width && size.height) {
    return size.height / size.width;
  }

  if (asset.width && asset.height) {
    return asset.width / asset.height;
  }

  return 1;
};

export interface AssetImagePlaceholderProps {
  asset: AssetImageType;
  size: ResponsiveValue<AssetImageSize>;
  children: React.ReactNode;
  css?: Stitches.CSS;
}

export const AssetImagePlaceholder = ({
  asset,
  size,
  children,
  css = {},
}: AssetImagePlaceholderProps) => {
  if (isSingleSizeValue(size)) {
    throw new Error(
      "Multiple size values must be supplied to the size prop of AssetImagePlaceholder",
    );
  }

  const { "@initial": initialPadding, ...breakpointPadding } =
    Object.fromEntries(
      Object.entries(size).map(([breakpoint, size]) => [
        breakpoint,
        size
          ? {
              paddingTop: `${placeholderAspectRatio(asset, size) * 100}%`,
            }
          : undefined,
      ]),
    );

  const { "@initial": initialMaxWidth, ...breakpointMaxWidth } =
    Object.fromEntries(
      Object.entries(size).map(([breakpoint, size]) => [
        breakpoint,
        size
          ? {
              maxWidth: size.width,
            }
          : undefined,
      ]),
    );

  return (
    <Box
      css={{
        ...initialMaxWidth,
        ...breakpointMaxWidth,
      }}
    >
      <Box
        css={mergeCss(
          {
            position: "relative",
            backgroundColor: "$grey1",
            overflow: "hidden",
            ...initialPadding,
            ...breakpointPadding,

            img: {
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            },
          },
          css,
        )}
      >
        {children}
      </Box>
    </Box>
  );
};
