import React from "react";
import type * as Stitches from "@stitches/react";
import { useLocation } from "react-router-dom";
import { Text } from "src/ccl/document";
import { AvailableIcon, Icon } from "src/ccl/document/icon";
import { Link } from "src/ccl/navigation";
import { mergeCss } from "src/ccl/stitches";
import { Flex } from "src/ccl/layout";
import { sendToAmplitude } from "src/utils/analytics";

interface SubNavItemProps {
  to: string;
  match: RegExp;
  title?: React.ReactNode;
  icon?: AvailableIcon;
  css?: Stitches.CSS;
  tracking?: string;
}

export const SubNavItem = ({
  to,
  match,
  title,
  icon,
  css = {},
  tracking,
}: SubNavItemProps) => {
  const active = match.test(useLocation().pathname);
  return (
    <Link
      to={to}
      css={mergeCss(
        {
          backgroundColor: active ? "$black" : "inherit",
          textDecoration: "none",
          fontWeight: "$bold",
          px: "$5",
          py: "$5",
          "@bp2": {
            fontSize: "$16",
          },
        },
        css,
      )}
      data-test-id={`SubNav${title}Button`}
      onClick={() => (tracking ? sendToAmplitude(tracking) : undefined)}
    >
      <Flex
        css={{
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          gap: "$4",
        }}
      >
        {icon && (
          <Icon
            color={active ? "white" : "black"}
            css={{ width: 14, height: 14, "@bp2": { width: 20, height: 20 } }}
            variant={icon as AvailableIcon}
          />
        )}
        {title && (
          <Text
            as="span"
            variant={{ "@initial": "meta", "@bp2": "body" }}
            css={{
              display: "inline-block",
              color: active ? "$white" : "$black",
            }}
          >
            {title}
          </Text>
        )}
      </Flex>
    </Link>
  );
};
