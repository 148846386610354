import { gql } from "@apollo/client";
import { LINE_ITEM_FRAGMENT } from "./LineItem";

export const OUTBOUND_PAYMENTS_DETAILS_FRAGMENT = gql`
  fragment OutboundPaymentsDetailsFragment on OutboundPayment {
    id
    amount
    currency
    createdAt
    state
    remittanceLetterUrl
  }
`;

export const PAYMENT_BATCH_DETAILS_FRAGMENT = gql`
  fragment PaymentBatchDetailsFragment on PaymentBatch {
    id
    state
    createdAt
    sealedAt
    sentAt
    authorizationUrl
  }
`;

export const PAYMENT_BATCH_FRAGMENT = gql`
  fragment PaymentBatchFragment on PaymentBatch {
    ...PaymentBatchDetailsFragment
    outboundPayments {
      ...OutboundPaymentsDetailsFragment
      lineItems {
        ...LineItemDetails
      }
      jobTalent {
        id
        talent {
          name
          slug
        }
        job {
          name
          slug
          budget
        }
      }
    }
  }
  ${PAYMENT_BATCH_DETAILS_FRAGMENT}
  ${OUTBOUND_PAYMENTS_DETAILS_FRAGMENT}
  ${LINE_ITEM_FRAGMENT}
`;
