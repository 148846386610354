import React from "react";
import { Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { formatDate } from "src/utils/dates";
import { Job } from "src/graphql/types";
import { tokens } from "src/ccl/stitches/theme";

const displayDate = (job: Job) => {
  if (job.startDate === job.endDate) {
    return formatDate(job.startDate, "dd LLL yyyy").toString();
  } else {
    return `${formatDate(job.startDate, "dd LLL yyyy")} - \n${formatDate(
      job.endDate,
      "dd LLL yyyy",
    )}`;
  }
};

export const JobDetails = ({
  job,
  color,
}: {
  job: Job;
  color?: keyof typeof tokens.colors;
}) => (
  <>
    <Flex css={{ flexWrap: "wrap", pb: "$3", "@bp2": { pb: "$4" } }}>
      <Icon variant="calendar" size={16} color={color} css={{ my: "auto" }} />
      <Text
        variant="meta"
        css={{ ml: "10px", ...(color && { color: `$${color}` }) }}
      >
        {displayDate(job)}
      </Text>
    </Flex>
    <Flex css={{ flexWrap: "wrap", pb: "$3", "@bp2": { pb: "$4" } }}>
      <Icon variant="brand" size={16} color={color} css={{ my: "auto" }} />
      <Text
        variant="meta"
        css={{ ml: "10px", ...(color && { color: `$${color}` }) }}
      >
        {job.brand}
      </Text>
    </Flex>
  </>
);
