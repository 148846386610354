import { JobFilterModal } from "src/components/dashboards/agents/jobsIndex/JobFilterModal";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { useToggle } from "src/hooks";
import { DateRangeInput, JobState, SortOrderInput } from "src/graphql/types";
import {
  DateRangeFilter,
  JobStateFilter,
  SearchFilter,
  SortOrderFilter,
} from "src/components/filtering";
import { SortOrderModal } from "src/components/filtering/jobFilters/SortOrderModal";
import { MobileFilterButton } from "src/components/filtering/MobileFilterButton";

const filterableStates = [
  JobState.Approved,
  JobState.Finalized,
  JobState.Completed,
  JobState.Cancelled,
];

interface JobFiltersProps {
  nameOrBrand: string;
  setNameOrBrand: (value: string) => void;
  states: JobState[];
  setStates: (states: JobState[]) => void;
  dateRange?: DateRangeInput;
  setDateRange: (dateRange?: DateRangeInput) => void;
  sortOrder: SortOrderInput;
  setSortOrder: (sortOrder: SortOrderInput) => void;
  onReset: () => void;
  jobCount?: number;
  loading?: boolean;
}

export const JobFilters = ({
  nameOrBrand,
  setNameOrBrand,
  states,
  setStates,
  dateRange,
  setDateRange,
  sortOrder,
  setSortOrder,
  onReset,
  jobCount,
  loading,
}: JobFiltersProps) => {
  const [filterModalOpen, toggleFilterModal] = useToggle();
  const [sortModalOpen, toggleSortModal] = useToggle();

  let activeFilterCount = [nameOrBrand, states].reduce(
    (total, a) => (a.length > 0 ? total + 1 : total),
    0,
  );

  if (dateRange) {
    activeFilterCount += 1;
  }
  const filterModalProps = {
    nameOrBrand,
    setNameOrBrand,
    states,
    setStates,
    setDateRange,
    availableJobStates: filterableStates,
  };
  return (
    <Box css={{ textAlign: "center" }}>
      <JobFilterModal
        {...filterModalProps}
        isOpen={filterModalOpen}
        onClose={toggleFilterModal}
        filtersActive={activeFilterCount > 0}
        jobCount={jobCount}
        onReset={onReset}
      />
      <SortOrderModal
        isOpen={sortModalOpen}
        onClose={toggleSortModal}
        jobCount={jobCount}
        onSortOrderChange={setSortOrder}
        onStatesChange={setStates}
        sortOrder={sortOrder}
      />
      <Grid
        css={{
          "@bp4": { display: "none" },
          gridColumns: "1fr 1fr",
          borderBottom: "1px solid $grey2",
          pb: "$4",
        }}
      >
        <MobileFilterButton
          text="Sort"
          icon="sortRows"
          borderRight={true}
          onClick={toggleSortModal}
        />
        <MobileFilterButton
          text="Filter"
          icon="filter"
          onClick={toggleFilterModal}
          suffix={activeFilterCount > 0 ? activeFilterCount : undefined}
        />
      </Grid>
      <Box
        css={{
          display: "none",
          "@bp4": { display: "block" },
        }}
      >
        <Grid
          css={{
            gridColumns: "1fr 3fr 1fr",
            width: "100%",
          }}
        >
          <Box />
          <Flex css={{ gap: "$2", width: "fit-content", mx: "auto" }}>
            <SearchFilter
              placeholder="Search jobs"
              search={nameOrBrand}
              onChange={setNameOrBrand}
            />
            <JobStateFilter
              availableStates={filterableStates}
              onChange={setStates}
              states={states}
            />

            <DateRangeFilter dateRange={dateRange} onChange={setDateRange} />
          </Flex>
        </Grid>
        <Grid css={{ gridColumns: "1fr 1fr 1fr", pt: "$9" }}>
          <Box />
          <Flex
            css={{
              textAlign: "center",
              gap: "$3",
              m: "auto",
            }}
          >
            {!loading && (
              <>
                <Text color="grey6">{`${jobCount || "No"} jobs found`}</Text>
                {activeFilterCount > 0 && (
                  <Text
                    css={{
                      textDecoration: "underline",
                      "&:hover": { cursor: "pointer" },
                    }}
                    onClick={onReset}
                  >
                    Clear Filters
                  </Text>
                )}
              </>
            )}
          </Flex>
          <Box css={{ ml: "auto" }}>
            <SortOrderFilter
              onSortOrderChange={setSortOrder}
              onStatesChange={setStates}
              sortOrder={sortOrder}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
