import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Box, Flex } from "src/ccl/layout";
import {
  Job,
  JobPostCompletionAction,
  JobTalent,
  MutationJobPostCompletionActionArgs,
} from "src/graphql/types";
import { ConfirmModal, Icon, Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { styled } from "src/ccl";
import { allConfirmedTalentArePaid, isZeroBudget } from "src/utils/job";
import { POST_COMPLETION_ACTION_MUTATION } from "src/graphql/mutations";

const Item = ({
  completed,
  completedDescription,
  incompletedDescription,
  completedAction,
  incompletedAction,
  v2,
}: {
  v2?: boolean;
  completed: boolean;
  completedDescription: string;
  incompletedDescription: string;
  completedAction?: React.ReactNode;
  incompletedAction?: React.ReactNode;
}) => (
  <Flex
    css={{
      alignItems: "center",
      py: "$3",
      ...(v2
        ? { border: "1px solid $grey2", px: "$5", borderRadius: "$md" }
        : {
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: "$grey3",
          }),
    }}
  >
    <Box css={{ mr: "$3", minWidth: "$14" }}>
      <Icon
        variant={completed ? "check" : "cross"}
        color={completed ? "green" : "grey6"}
        size={14}
      />
    </Box>

    <Text variant="meta">
      {completed ? completedDescription : incompletedDescription}
    </Text>

    {!completed && incompletedAction && (
      <Flex
        css={{
          alignItems: "center",
          flex: 1,
          justifyContent: "flex-end",
          minWidth: "30%",
        }}
      >
        {incompletedAction}
      </Flex>
    )}

    {completed && completedAction && (
      <Flex
        css={{
          alignItems: "center",
          flex: 1,
          justifyContent: "flex-end",
          minWidth: "30%",
        }}
      >
        {completedAction}
      </Flex>
    )}
  </Flex>
);

const ActionLinkText = styled(Text, {
  textTransform: "none",
  textDecoration: "underline",
  fontWeight: "$regular",
  textAlign: "right",
});

interface PostCompletionActionsProps {
  job: Job;
  v2?: boolean;
}

export const PostCompletionActions = ({
  job,
  v2,
}: PostCompletionActionsProps) => {
  const allTalentPaid = allConfirmedTalentArePaid(job.talent as JobTalent[]);

  const [updatePostCompletionState, { loading }] =
    useMutation<MutationJobPostCompletionActionArgs>(
      POST_COMPLETION_ACTION_MUTATION,
    );

  const [isModalShowing, setIsModalShowing] = useState(false);
  const [mutationVariables, setMutationVariables] = useState<
    MutationJobPostCompletionActionArgs | undefined
  >(undefined);
  const [modalBodyText, setModalBodyText] = useState("");

  return (
    <>
      <Box>
        <Text variant={v2 ? "nh3" : "mini"} css={{ mb: v2 ? "$10" : "$3" }}>
          <Flex css={{ gap: "$3", alignItems: "center" }}>
            <Icon variant="check" size={24} /> Manage completed job
          </Flex>
        </Text>

        <Flex css={{ flexDirection: "column", gap: "$3" }}>
          <Item
            v2={v2}
            completed={!!job.paymentReceived}
            completedDescription="Booker has paid Contact"
            incompletedDescription="Booker has not yet paid Contact"
            completedAction={
              <Button
                variant="unstyled"
                onClick={() => {
                  setMutationVariables({
                    jobId: job.id,
                    action: JobPostCompletionAction.PaymentReceived,
                    completed: false,
                  });
                  setModalBodyText(
                    "Are you sure you want to mark payment as not having been received?",
                  );
                  setIsModalShowing(true);
                }}
              >
                <ActionLinkText color="grey6" variant="meta">
                  Change
                </ActionLinkText>
              </Button>
            }
            incompletedAction={
              <Button
                variant="unstyled"
                onClick={() => {
                  setMutationVariables({
                    jobId: job.id,
                    action: JobPostCompletionAction.PaymentReceived,
                    completed: true,
                  });
                  setModalBodyText(
                    "Are you sure you want to mark payment as received?",
                  );
                  setIsModalShowing(true);
                }}
              >
                <ActionLinkText color="green" variant="meta">
                  Mark as received
                </ActionLinkText>
              </Button>
            }
          />

          <Item
            v2={v2}
            completed={!!job.imagesReceived}
            completedDescription="Images received (we recommend adding a link in notes)"
            incompletedDescription="Images have not been received"
            completedAction={
              <Button
                variant="unstyled"
                onClick={() => {
                  setMutationVariables({
                    jobId: job.id,
                    action: JobPostCompletionAction.ImagesReceived,
                    completed: false,
                  });
                  setModalBodyText(
                    "Are you sure you want to mark images as not having been received?",
                  );
                  setIsModalShowing(true);
                }}
              >
                <ActionLinkText color="grey6" variant="meta">
                  Change
                </ActionLinkText>
              </Button>
            }
            incompletedAction={
              <Button
                variant="unstyled"
                onClick={() => {
                  setMutationVariables({
                    jobId: job.id,
                    action: JobPostCompletionAction.ImagesReceived,
                    completed: true,
                  });
                  setModalBodyText(
                    "Are you sure you want to mark images as received?",
                  );
                  setIsModalShowing(true);
                }}
              >
                <ActionLinkText color="green" variant="meta">
                  Mark as received
                </ActionLinkText>
              </Button>
            }
          />

          <Item
            v2={v2}
            completed={isZeroBudget(job) ? true : allTalentPaid}
            completedDescription={
              isZeroBudget(job)
                ? "No budget: no payment due"
                : "We've paid all creatives"
            }
            incompletedDescription="We've not paid all creatives"
            incompletedAction={
              <>
                <Icon
                  variant="information"
                  size={14}
                  color="grey6"
                  css={{ mr: "$2" }}
                />
                <Text variant="meta" color="grey6">
                  Manage in shortlist
                </Text>
              </>
            }
          />
        </Flex>
      </Box>

      <ConfirmModal
        title="Please confirm"
        isOpen={isModalShowing}
        confirmButtonTitle="Confirm"
        cancelButtonTitle="Cancel"
        onCancel={() => {
          setIsModalShowing(false);
        }}
        onConfirm={async () => {
          if (mutationVariables === undefined) {
            return;
          }

          await updatePostCompletionState({
            variables: mutationVariables,
          });

          setMutationVariables(undefined);
          setIsModalShowing(false);
          setModalBodyText("");
        }}
        isDisabled={loading}
      >
        <Text>{modalBodyText}</Text>
      </ConfirmModal>
    </>
  );
};
