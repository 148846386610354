import React, { LazyExoticComponent } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { PackagesProps } from "src/components/packages";

interface RouterProps {
  Homepage: React.FC<RouteComponentProps>;
  SignIn: React.FC<RouteComponentProps>;
  StartPasswordReset: React.FC<RouteComponentProps>;
  ConfirmPasswordReset: React.FC<RouteComponentProps>;
  Blog: React.FC<RouteComponentProps>;
  About: React.FC<RouteComponentProps>;
  ForTalent: React.FC<RouteComponentProps>;
  ForClients: React.FC<RouteComponentProps>;
  ForAgencies: React.FC<RouteComponentProps>;
  Careers: React.FC<RouteComponentProps>;
  Podcast: React.FC<RouteComponentProps>;
  Account: React.FC<RouteComponentProps>;
  CreateAccount: React.FC<RouteComponentProps>;
  CreateJob: React.FC<RouteComponentProps>;
  Dashboard: React.FC<RouteComponentProps>;
  DeskRental: React.FC<RouteComponentProps>;
  SelfManagedTalentOnboarding: React.FC<RouteComponentProps>;
  TalentOnboarding: React.FC<RouteComponentProps>;
  AgentOnboarding: React.FC<RouteComponentProps>;
  JoinBookingCompany: React.FC<RouteComponentProps>;
  PaymentVerification: React.FC<RouteComponentProps>;
  Packages: LazyExoticComponent<React.FC<PackagesProps>>;
  BuilderLandingPage: React.FC<RouteComponentProps>;
  Static: React.FC<RouteComponentProps>;
  AgencyDirectory: React.FC<RouteComponentProps>;
  AgencyHomepage: React.FC<RouteComponentProps>;
  NotFound: React.FC<{ message?: React.ReactNode }>;
  EmailVerification: React.FC<RouteComponentProps>;
  CreateAccountOnboarding: React.FC<RouteComponentProps>;
  AccountNotFound: React.FC<RouteComponentProps>;
  VerifyEmail: React.FC<RouteComponentProps>;
}

export const Router: React.FC<RouterProps> = ({
  Homepage,
  SignIn,
  StartPasswordReset,
  ConfirmPasswordReset,
  Blog,
  About,
  ForTalent,
  ForClients,
  ForAgencies,
  Careers,
  Podcast,
  Account,
  CreateAccount,
  CreateJob,
  Dashboard,
  DeskRental,
  PaymentVerification,
  SelfManagedTalentOnboarding,
  TalentOnboarding,
  AgentOnboarding,
  JoinBookingCompany,
  Packages,
  BuilderLandingPage,
  Static,
  AgencyDirectory,
  AgencyHomepage,
  NotFound,
  EmailVerification,
  CreateAccountOnboarding,
  AccountNotFound,
  VerifyEmail,
}) => {
  return (
    <Switch>
      <Route component={Homepage} path="/" exact />
      <Route component={SignIn} path="/sign-in" />
      <Route component={StartPasswordReset} exact path="/reset-password" />
      <Route component={ConfirmPasswordReset} path="/reset-password/:token" />
      <Route component={Blog} path="/backstage" />
      <Route component={About} path="/about" />
      <Route component={ForTalent} path="/for/talent" />
      <Route component={ForTalent} path="/for/creatives" />
      <Route component={ForClients} path="/for/clients" />
      <Route component={ForAgencies} path="/for/agencies" />
      <Redirect from="/new" to="/" />
      <Route component={Careers} path="/careers" />
      <Route component={Podcast} path="/podcast" />
      <Route component={Podcast} path="/creative-paths" />
      <Route component={AccountNotFound} path="/account/not-found" />
      <Route component={EmailVerification} path="/account/confirm-email" />
      <Route component={CreateAccountOnboarding} path="/account/onboarding" />
      <Route component={CreateAccount} path="/account/new" />

      <Route component={Account} path="/account" />
      <Route component={CreateJob} path="/jobs/new" />
      <Route component={Dashboard} path="/dashboard" />
      <Route component={DeskRental} path="/desk-rental" exact />
      <Route component={TalentOnboarding} path="/talent-onboarding/:token" />
      <Route
        component={SelfManagedTalentOnboarding}
        path="/create-profile/:token"
      />
      <Route component={AgentOnboarding} path="/agent-onboarding" />
      <Route component={JoinBookingCompany} path="/join-booking-team/:token" />
      <Redirect from="/models/:slug" to="/creatives/:slug" />
      <Route component={Packages} path="/packages/:slug" />
      <Route component={AgencyDirectory} path="/agencies" exact />
      <Route component={AgencyHomepage} path="/agency/:slug" exact />
      <Route
        component={PaymentVerification}
        path="/payment-verification/:token"
      />
      <Route component={VerifyEmail} path="/verify-email/:token" />
      <Route component={BuilderLandingPage} path="/l/:slug" />
      <Route component={Static} path="/:slug" />
      <Route component={NotFound} path="*" />
    </Switch>
  );
};
