import { gql } from "@apollo/client";
import { PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const CREATE_PORTFOLIO_IMAGE_CATEGORY_MUTATION = gql`
  mutation CreatePortfolioImageCategory($name: String!, $talentProfileId: ID!) {
    createPortfolioImageCategory(
      name: $name
      talentProfileId: $talentProfileId
    ) {
      ...PortfolioImageCategoryDetails
    }
  }
  ${PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT}
`;
