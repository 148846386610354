import { useEffect } from "react";
import { useHistory } from "react-router";

export const useScrollToTop = (dependencies: unknown | unknown[] = true) => {
  const history = useHistory();
  useEffect(
    () => {
      // the browser remembers scroll positions when you go back,
      // we don't want to break that
      if (dependencies && history.action !== "POP") {
        window.scrollTo(0, 0);
      }
    },
    // ESLint by default tries to remove the next line as it can't work out that
    // we're passing `dependencies` through, so we have to manually disable
    // this rule.
    //
    Array.isArray(dependencies) ? dependencies : [dependencies],
  );
};
