import { camelCase } from "lodash";
import { useFormikContext } from "formik";
import options from "src/data/filterOptions.json";
import {
  Contract,
  FeaturedAssetForm,
  FieldInfoText,
  RadioSelect,
} from "src/components/dashboards/agents";
import {
  Field,
  FormikInput,
  MultiSelect,
  FormikTextarea,
} from "src/ccl/data-entry";
import { AvailableIcon, Text } from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import {
  Asset,
  ProfileFormContext,
  TalentVertical,
  UpdateTalentProfileInput,
  User,
} from "src/graphql/types";
import { searchPlace } from "src/utils/google";
import { pronounsMap } from "src/utils/user";
import { UploadedFile } from "src/entities";
import { useStoreModel } from "src/hooks";

interface TalentDetailsProps {
  user?: User;
  onProfilePictureUpload?: (asset: UploadedFile) => void;
  profilePicture?: Asset;
  context: ProfileFormContext;
  talentProfile: UpdateTalentProfileInput;
  vertical: TalentVertical;
}

export const TalentDetails = ({
  user,
  talentProfile,
  profilePicture,
  onProfilePictureUpload,
  context,
  vertical,
}: TalentDetailsProps) => {
  const { featureEnabled } = useStoreModel("features");
  const maxOptions = 8;
  const maxClients = 10;
  const { setFieldValue } = useFormikContext();

  const dietaryRequirementOptions = [
    { value: "No requirements", label: "No requirements" },
    ...options.dietaryRequirements.filter((r) => r.label !== "No requirements"),
  ];

  const variant = featureEnabled("tpa_updated_creative_upload")
    ? "rebrand"
    : undefined;

  return (
    <>
      <Box>
        {context === "edit" && (
          <FeaturedAssetForm
            onUpload={(assets) => {
              if (onProfilePictureUpload) {
                onProfilePictureUpload(assets[0]);
              }
            }}
            featuredAsset={
              profilePicture
                ? {
                    kind: profilePicture.kind,
                    mediaUrl: profilePicture.mediaUrl,
                  }
                : undefined
            }
          />
        )}
        <Grid
          css={{ gridColumns: 1, "@bp2": { gridColumns: 2, columnGap: "$7" } }}
        >
          <Field
            variant="b2Bold"
            name="firstName"
            label="First name"
            css={{ mb: "$5" }}
            labelCss={{ mb: "$5" }}
          >
            <FormikInput name="firstName" type="text" variant={variant} />
          </Field>
          <Field
            variant="b2Bold"
            name="lastName"
            label="Last name"
            css={{ mb: "$5" }}
            labelCss={{ mb: "$5" }}
          >
            <FormikInput name="lastName" type="text" variant={variant} />
          </Field>
          <Field
            variant="b2Bold"
            name="email"
            label="Email"
            css={{ mb: "$5" }}
            labelCss={{ mb: "$5" }}
          >
            <FormikInput
              name="email"
              type="email"
              variant={variant}
              disabled={context === "talentOnboard"}
            />
          </Field>
          <Field
            variant="b2Bold"
            name="talentProfile.dateOfBirth"
            label="Date of birth"
            css={{ mb: "$5" }}
            labelCss={{ mb: "$5" }}
          >
            <FormikInput
              name="talentProfile.dateOfBirth"
              placeholder="DD/MM/YYYY"
              type="text"
              variant={variant}
            />
          </Field>
          <Box>
            <Field
              variant="b2Bold"
              name="phoneNumber"
              label="Phone"
              css={{ mb: "$5" }}
              labelCss={{ mb: "$5" }}
            >
              <FormikInput name="phoneNumber" type="tel" variant={variant} />
            </Field>
            <FieldInfoText
              text="Please make sure phone number matches the country stated in the
              billing address"
            />
          </Box>
          <Box>
            <Field
              variant="b2Bold"
              name="talentProfile.primaryLocation.name"
              label="Current location"
              labelCss={{ mb: "$5" }}
            >
              <FormikInput
                name="talentProfile.primaryLocation.name"
                type="text"
                variant={variant}
              />
            </Field>
            <FieldInfoText
              text="To help bookers find creatives, we recommend you provide the
              nearest city rather than the exact town or village they live in."
            />
          </Box>
          <Box css={{ pt: "$8", "@bp2": { gridColumn: "span 2" } }}>
            <MultiSelect
              label=" Other locations willing to travel to"
              placeholder={`Choose up to ${maxOptions} locations`}
              name="talentProfile.additionalLocations"
              maxOptions={maxOptions}
              noOptionsText={`Choose up to ${maxOptions} locations`}
              maxOptionsText={`You can't choose more that ${maxOptions} other locations.`}
              initialValues={talentProfile.additionalLocations?.map((l) => ({
                label: l.name,
                value: l.name,
              }))}
              loadOptions={(value: string) => {
                if (
                  talentProfile.additionalLocations &&
                  talentProfile.additionalLocations.length === maxOptions
                ) {
                  return new Promise((resolve) => resolve([]));
                }
                return searchPlace(value, {
                  types: ["(regions)"],
                });
              }}
              variant={variant}
              onChange={(values) =>
                setFieldValue(
                  "talentProfile.additionalLocations",
                  values.map((l) => ({ name: l })),
                )
              }
            />
            <FieldInfoText text="Select other cities the creative can easily travel to. It's best to stick with well-connected cities in the creative's country. We'll contact creatives specifically for overseas jobs." />
          </Box>
          <Flex
            css={{
              pt: "$8",
              flexDirection: "column",
              gap: "$4",
              "@bp2": { gridColumn: "span 2" },
            }}
          >
            <Text variant="b2Bold">Gender</Text>
            <RadioSelect
              name="gender"
              options={options.genders.map((g) => ({
                label: g.label,
                value: g.value.toUpperCase(),
                icon: camelCase(g.label) as AvailableIcon,
              }))}
              selectedOption={talentProfile.gender || ""}
              setSelectedOption={(val: string) => {
                setFieldValue("talentProfile.gender", val);
              }}
              gridCss={{
                gap: "$5",
                gridColumns: 1,
                "@sm": {
                  gridColumns: 3,
                },
              }}
            />
          </Flex>
          <Flex
            css={{
              pt: "$8",
              flexDirection: "column",
              gap: "$4",
              "@bp2": { gridColumn: "span 2" },
            }}
          >
            <Text variant="b2Bold">Pronouns</Text>
            <RadioSelect
              name="pronouns"
              options={Object.entries(pronounsMap).map(([label, value]) => ({
                label: value,
                value: label,
              }))}
              selectedOption={talentProfile.pronouns || ""}
              setSelectedOption={(val: string) => {
                setFieldValue("talentProfile.pronouns", val);
              }}
              optional
              gridCss={{
                gap: "$5",
                gridColumns: 1,
                "@sm": {
                  gridColumns: 3,
                },
              }}
            />
            <FieldInfoText text="To inform people how the talent want to be referenced (e.g. He/Him)." />
          </Flex>
          <Box css={{ mt: "$6", "@bp2": { gridColumn: "span 2" } }}>
            <MultiSelect
              id="dietaryRequirements"
              name="dietaryRequirements"
              initialValues={(talentProfile.dietaryRequirements || []).map(
                (d) => ({
                  label: d,
                  value: d,
                }),
              )}
              label="Dietary or allergy requirements"
              options={dietaryRequirementOptions}
              onChange={(values: string[]) =>
                setFieldValue("talentProfile.dietaryRequirements", values)
              }
              variant={variant}
              optional
            />
            <FieldInfoText
              text={
                <>
                  To inform the booker if they are providing meals. Note,
                  Contact is not liable if the booker makes any mistakes with
                  requirements.
                </>
              }
            />
          </Box>
          <Box css={{ "@bp2": { gridColumn: "span 2" }, mt: "$5" }}>
            <Field
              variant="b2Bold"
              name="talentProfile.biography"
              label="A bio"
              css={{ mb: "$5" }}
              labelCss={{ mb: "$5" }}
            >
              <FormikTextarea
                rows={8}
                name="talentProfile.biography"
                placeholder="3-5 sentences from your creative talking about: their background, where they're from, occupation (studying/other jobs), other interests, and anything they stand for."
                variant={variant}
              />
            </Field>
          </Box>
          <Box css={{ "@bp2": { gridColumn: "span 2" }, mb: "$6" }}>
            <MultiSelect
              id="pastClients"
              label="Past Clients"
              name="talentProfile.pastClients"
              placeholder="e.g. Nike, Adidas, GUAP"
              maxOptions={maxClients}
              noOptionsText={`Enter up to ${maxClients} clients`}
              maxOptionsText={`You can't list more that ${maxClients} clients.`}
              initialValues={(talentProfile?.pastClients || []).map(
                (client) => ({
                  label: client,
                  value: client,
                }),
              )}
              loadOptions={(value: string) => {
                if (
                  (talentProfile &&
                    talentProfile.pastClients?.length === maxClients) ||
                  value.length === 0
                ) {
                  return new Promise((resolve) => resolve([]));
                }
                return new Promise<{ label: string; value: string }[]>(
                  (resolve) => resolve([{ label: value, value: value }]),
                );
              }}
              hideIndicator
              variant={variant}
              onChange={(values) => {
                setFieldValue("talentProfile.pastClients", values);
              }}
              optional
            />
          </Box>
          <Box css={{ mb: "$5", "@bp2": { gridColumn: "span 2" } }}>
            <MultiSelect
              id="interests"
              label="Interests"
              name="talentProfile.hobbies"
              initialValues={talentProfile.hobbies?.map((h) => ({
                label: h,
                value: h,
              }))}
              options={options.hobbies}
              onChange={(values) => {
                setFieldValue("talentProfile.hobbies", values);
              }}
              variant={variant}
              optional
            />
          </Box>
        </Grid>
      </Box>
      {context !== "talentOnboard" &&
        vertical === TalentVertical.FashionModel && (
          <Contract user={user} context={context} />
        )}
    </>
  );
};
