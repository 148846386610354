import { gql } from "@apollo/client";

export const CREATE_DESK_RENTAL_PAYMENT_INTENT_MUTATION = gql`
  mutation CreateDeskRentalPaymentIntent(
    $invoiceNumber: String!
    $email: String!
  ) {
    createDeskRentalPaymentIntent(
      invoiceNumber: $invoiceNumber
      email: $email
    ) {
      url
    }
  }
`;
