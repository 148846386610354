import React from "react";
import { useQuery } from "@apollo/client";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Panel, Text } from "src/ccl/document";
import { Job, Query, User } from "src/graphql/types";
import { Avatar } from "src/ccl/blocks";
import { getInitials } from "src/utils/lang";
import { ExternalLink } from "src/ccl/navigation";
import { BOOKER_DASHBOARD_TEAM_HELP_PANEL_QUERY } from "src/graphql/queries/booker/TeamHelpPanel";
import { zendeskAvailable, zendeskOpen } from "src/utils/zendesk";
import { useStoreModel } from "src/hooks";

export interface TeamHelpPanelProps {
  job: Job;
}

type TeamMember = Pick<User, "name" | "email" | "featuredAsset">;

export const TeamHelpPanel = ({ job }: TeamHelpPanelProps) => {
  const { data } = useQuery<Query>(BOOKER_DASHBOARD_TEAM_HELP_PANEL_QUERY);
  const { featureEnabled } = useStoreModel("features");
  const ss = (data?.me?.staffUsers || []).filter(
    (su) => typeof su !== "undefined",
  ) as User[];

  const staffUsers: TeamMember[] = job.assignedAgent ? [job.assignedAgent] : ss;

  return (
    <Panel css={{ padding: "$9", mb: "$11" }}>
      <Flex
        css={{
          mb: "$5",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Icon variant="support" size={40} color="grey2" />
        <Flex css={{ gap: "$3" }}>
          {staffUsers.map((su, index) => (
            <Avatar
              initials={getInitials(su?.name)}
              imageUrl={
                su?.featuredAsset?.mediaUrl &&
                `${su?.featuredAsset.mediaUrl}?w=120&h=120&fit=crop`
              }
              key={index}
              css={{
                width: "$avatar",
                height: "$avatar",
                "@bp3": { width: "$largeAvatar", height: "$largeAvatar" },
              }}
            />
          ))}
        </Flex>
      </Flex>
      <Box>
        <>
          {job.assignedAgent ? (
            <>
              <Text variant="h4" css={{ mb: "$6" }}>
                {job.assignedAgent?.name}&apos;s here to help
              </Text>
              <Text>
                Got a question? {job.assignedAgent.name}&apos;s managing your
                job and ready to help. Email{" "}
                <ExternalLink
                  to={`mailto:${job.assignedAgent.email}`}
                  eventName="bookerDashboard:teamHelpPanel:email"
                >
                  {job.assignedAgent.email}
                </ExternalLink>{" "}
                or{" "}
                <ExternalLink
                  to="https://support.contact.xyz/hc/en-gb"
                  target="_blank"
                  onClick={(event) => {
                    if (
                      !featureEnabled("zendesk_enabled") ||
                      !zendeskAvailable()
                    ) {
                      return;
                    }
                    event.preventDefault();
                    zendeskOpen();
                  }}
                  eventName="bookerDashboard:teamHelpPanel:email"
                >
                  send us a message
                </ExternalLink>
                .
                <br />
                <br />
                We’re here 9am-5pm, Monday to Friday.
              </Text>
            </>
          ) : (
            <>
              <Text variant="h4" css={{ mb: "$6" }}>
                {"We're"} here to help
              </Text>
              <Text>
                Got a question? Email{" "}
                <ExternalLink
                  to="mailto:help@contact.xyz"
                  eventName="bookerDashboard:teamHelpPanel:email"
                >
                  help@contact.xyz
                </ExternalLink>{" "}
                or{" "}
                <ExternalLink
                  to="https://support.contact.xyz/hc/en-gb"
                  target="_blank"
                  onClick={(event) => {
                    if (
                      !featureEnabled("zendesk_enabled") ||
                      !zendeskAvailable()
                    ) {
                      return;
                    }
                    event.preventDefault();
                    zendeskOpen();
                  }}
                  eventName="bookerDashboard:teamHelpPanel:email"
                >
                  send us a message
                </ExternalLink>
                .
                <br />
                <br />
                We’re here 9am-5pm, Monday to Friday.
              </Text>
            </>
          )}
        </>
      </Box>
    </Panel>
  );
};
