import React from "react";
import { styled } from "src/ccl/stitches";
import { Icon, AssetImage, Text } from "src/ccl/document";
import { AssetKind, Asset, VerticalConfiguration } from "src/graphql/types";
import { Flex, Box } from "src/ccl/layout";
import { Pill } from "src/ccl/blocks";

const Gradient = styled(Box, {
  position: "absolute",
  width: "100%",
  height: "30%",
  bottom: "0",
  left: "0",
  linearGradient: "rgba(48, 48, 48, 0), rgba(48, 48, 48, 1)",
  opacity: 0.9,
  transition: "all 0.15s ease-in-out",
  zIndex: "$100",
  cursor: "pointer",
});

const Container = styled(Box, {
  position: "relative",
  boxShadow: "0px 10px 60px rgba(0, 0, 0, 0.2)",
  cursor: "pointer",
  transition: "all 0.15s ease-in-out",
  "&:hover": {
    boxShadow: "0px 20px 60px rgba(0, 0, 0, 0.6)",
    [`& ${Gradient}`]: {
      height: "100%",
    },
  },
});

const Overlay = styled(Flex, {
  position: "absolute",
  zIndex: "$100",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  p: "$6",
  cursor: "pointer",
  "@bp3": {
    px: "$6",
    py: "$7",
  },
});

export interface VerticalSelectionCardProps {
  onClick: () => void;
  config: VerticalConfiguration;
  index: number;
  isNew?: boolean;
}

export const VerticalSelectionCard = ({
  onClick,
  config,
  index,
  isNew,
}: VerticalSelectionCardProps) => (
  <Container onClick={onClick} data-test-id={config.label}>
    <Gradient></Gradient>
    <Overlay
      css={{
        justifyContent: isNew ? "space-between" : "flex-end",
      }}
    >
      {isNew && (
        <Flex css={{ justifyContent: "flex-end" }}>
          <Pill variant="announcement">
            <Text variant="meta" color="white">
              New
            </Text>
          </Pill>
        </Flex>
      )}
      <Flex
        css={{
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Text
          color="white"
          variant={{ "@initial": "body", "@bp3": "h4" }}
          weight="bold"
        >
          {config.label}s
        </Text>
        <Icon size={20} variant="chevronRight" color="white" />
      </Flex>
    </Overlay>
    <AssetImage
      eager
      alt={`Choose ${config.label}`}
      asset={
        {
          id: index.toString(),
          kind: AssetKind.Image,
          mediaUrl: config.image,
          sortWeight: index,
        } as Asset
      }
      size={{
        "@initial": {
          width: 600,
          height: 400,
        },
        "@bp1": {
          width: 700,
          height: 400,
        },
      }}
    />
  </Container>
);
