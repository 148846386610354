import { filter, find, includes, isNil, split } from "lodash";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SubLevel } from "./SubLevel";
import { TopLevel } from "./TopLevel";
import { useStoreModel } from "src/hooks";
import { IconProps } from "src/ccl/document";

enum ECONDITIONAL_SUB_PAGES {
  agencies = "/agencies",
  clients = "/clients",
  clientTeams = "/clients/teams",
}

const PAGE_WITH_SUBPAGES = [
  { path: ECONDITIONAL_SUB_PAGES.agencies, idOrSlugIdx: 3 },
  { path: ECONDITIONAL_SUB_PAGES.clientTeams, idOrSlugIdx: 4 },
  { path: ECONDITIONAL_SUB_PAGES.clients, idOrSlugIdx: 3 },
];

interface SideNavBarProps {
  path: string;
  onClick?: () => void;
}

type Page = {
  name: string;
  icon: IconProps["variant"];
  match: RegExp;
  visible: boolean;
  subPages: SubPage[];
};

type SubPage = {
  visible: boolean;
  linkTo: string;
  title: string;
  isSelected: boolean;
  onClick?: () => void;
};

export const SideNavBar = ({ path, onClick }: SideNavBarProps) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { isBooker, isAgent, isFirstPartyAgent } = useStoreModel("currentUser");

  // check if the page have conditional subpages
  const { page, slugOrId } = useMemo(() => {
    const page =
      find(PAGE_WITH_SUBPAGES, (page) => includes(currentPath, page.path)) ||
      null;

    if (!isNil(page)) {
      const slugOrId =
        filter(split(currentPath, "/"))[page?.idOrSlugIdx] || null;

      return { page: page?.path, slugOrId };
    }

    return { page: null, slugOrId: null };
  }, [currentPath]);

  const pages: Page[] = [
    {
      name: "Jobs",
      icon: "casting",
      match: isAgent
        ? /\/dashboard\/agent\/jobs(?!\/new)/
        : /\/dashboard\/client\/jobs(?!\/new)/,
      visible: isBooker || isAgent,
      subPages: [
        {
          visible: isBooker || isAgent,
          linkTo: `${path}/jobs`,
          title: "All jobs",
          isSelected: currentPath === `${path}/jobs`,
          onClick,
        },
      ],
    },
    {
      name: "Talent",
      icon: "userOutline",
      match: /\/dashboard\/client\/talent(?!\/new)/,
      visible: isBooker,
      subPages: [
        {
          visible: isBooker,
          linkTo: `${path}/talent/select`,
          title: "Select",
          isSelected: includes(currentPath, `${path}/talent/select`),
          onClick,
        },
        {
          visible: isBooker,
          linkTo: `${path}/talent/open`,
          title: "Open",
          isSelected: includes(currentPath, `${path}/talent/open`),
          onClick,
        },
      ],
    },
    {
      name: "Bookmarks",
      icon: "bookmarkOutline",
      match: /\/dashboard\/client\/bookmark-collections(?!\/new)/,
      visible: isBooker,
      subPages: [
        {
          visible: isBooker,
          linkTo: `${path}/bookmark-collections`,
          title: "Collections",
          isSelected: currentPath.includes(`${path}/bookmark-collections`),
          onClick,
        },
      ],
    },
    {
      name: "Settings",
      icon: "settingsWheel",
      match: /\/dashboard\/client\/account/,
      visible: isBooker,
      subPages: [
        {
          visible: isBooker,
          linkTo: `${path}/account/settings`,
          title: "Profile",
          isSelected: currentPath === `${path}/account/settings`,
          onClick,
        },
        {
          visible: isBooker,
          linkTo: `${path}/account/company-settings`,
          title: "Company Settings",
          isSelected: currentPath === `${path}/account/company-settings`,
          onClick,
        },
        {
          visible: isBooker,
          linkTo: `${path}/account/password`,
          title: "Change Password",
          isSelected: currentPath === `${path}/account/password`,
          onClick,
        },
        {
          visible: isBooker,
          linkTo: `${path}/account/company-members`,
          title: "Manage Team",
          isSelected: currentPath === `${path}/account/company-members`,
          onClick,
        },
        {
          visible: isBooker,
          linkTo: `${path}/account/delete`,
          title: "Delete Account",
          isSelected: currentPath === `${path}/account/delete`,
          onClick,
        },
      ],
    },
    {
      name: isFirstPartyAgent ? "Talents" : "My Talents",
      icon: "userRebrand",
      match: /\/dashboard\/agent\/(talent|talent-invitations)/,
      visible: isAgent,
      subPages: [
        {
          visible: isAgent && !isFirstPartyAgent,
          linkTo: `${path}/talent`,
          title: "Manage talent",
          isSelected: includes(currentPath, `${path}/talent`),
          onClick,
        },
        {
          visible: isAgent && isFirstPartyAgent,
          linkTo: `${path}/talent/select`,
          title: "Select",
          isSelected: includes(currentPath, `${path}/talent/select`),
          onClick,
        },
        {
          visible: isAgent && isFirstPartyAgent,
          linkTo: `${path}/talent/open`,
          title: "Open",
          isSelected: includes(currentPath, `${path}/talent/open`),
          onClick,
        },
      ],
    },
    {
      name: "Payments",
      icon: "budget",
      match: /\/dashboard\/agent\/payments/,
      visible: isAgent && isFirstPartyAgent,
      subPages: [
        {
          visible: isAgent && isFirstPartyAgent,
          linkTo: `${path}/payments/invoices`,
          title: "Invoices",
          isSelected: currentPath === `${path}/payments/invoices`,
          onClick,
        },
        {
          visible: isAgent && isFirstPartyAgent,
          linkTo: `${path}/payments/batches`,
          title: "Previous batches",
          isSelected: currentPath === `${path}/payments/batches`,
          onClick,
        },
      ],
    },
    {
      name: "Agencies",
      icon: "userOutline",
      match: /\/dashboard\/agent\/agencies/,
      visible: isAgent && isFirstPartyAgent,
      subPages: [
        {
          visible: isAgent && isFirstPartyAgent,
          linkTo: `${path}/agencies`,
          title: "All agencies",
          isSelected: currentPath === `${path}/agencies`,
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.agencies,
          linkTo: `${path}/agencies/${slugOrId}/talent`,
          title: "Talent",
          isSelected: currentPath === `${path}/agencies/${slugOrId}/talent`,
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.agencies,
          linkTo: `${path}/agencies/${slugOrId}/manage-agents`,
          title: "Manage agents",
          isSelected:
            currentPath === `${path}/agencies/${slugOrId}/manage-agents`,
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.agencies,
          linkTo: `${path}/agencies/${slugOrId}/settings`,
          title: "Settings",
          isSelected: currentPath === `${path}/agencies/${slugOrId}/settings`,
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.agencies,
          linkTo: `${path}/agencies/${slugOrId}/jobs`,
          title: "Jobs",
          isSelected: currentPath === `${path}/agencies/${slugOrId}/jobs`,
          onClick,
        },
      ],
    },
    {
      name: "Packages",
      icon: "gifting",
      match: /\/dashboard\/agent\/packages/,
      visible: isAgent,
      subPages: [
        {
          visible: isAgent,
          linkTo: `${path}/packages`,
          title: "Manage packages",
          isSelected: currentPath === `${path}/packages`,
          onClick,
        },
      ],
    },
    {
      name: "Clients",
      icon: "bookersVertical",
      match: /\/dashboard\/agent\/clients/,
      visible: isAgent && isFirstPartyAgent,
      subPages: [
        {
          visible: isAgent,
          linkTo: `${path}/clients`,
          title: "Client",
          isSelected: currentPath === `${path}/clients`,
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.clients,
          linkTo: `${path}/clients/${slugOrId}`,
          title: "Profile details",
          isSelected: currentPath === `${path}/clients/${slugOrId}`,
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.clients,
          linkTo: `${path}/clients/${slugOrId}/jobs`,
          title: "Booking history",
          isSelected: currentPath === `${path}/clients/${slugOrId}/jobs`,
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.clients,
          linkTo: `${path}/clients/${slugOrId}/invoices`,
          title: "Invoices",
          isSelected: currentPath === `${path}/clients/${slugOrId}/invoices`,
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.clients,
          linkTo: `${path}/clients/${slugOrId}/notes`,
          title: "Notes",
          isSelected: currentPath === `${path}/clients/${slugOrId}/notes`,
          onClick,
        },
        {
          visible: isAgent && isFirstPartyAgent,
          linkTo: `${path}/clients/teams`,
          title: "Teams",
          isSelected: includes(currentPath, `${path}/clients/teams`),
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.clientTeams,
          linkTo: `${path}/clients/teams/${slugOrId}/members`,
          title: "Members",
          isSelected: includes(
            currentPath,
            `${path}/clients/teams/${slugOrId}/members`,
          ),
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.clientTeams,
          linkTo: `${path}/clients/teams/${slugOrId}/jobs`,
          title: "Booking history",
          isSelected: includes(
            currentPath,
            `${path}/clients/teams/${slugOrId}/jobs`,
          ),
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.clientTeams,
          linkTo: `${path}/clients/teams/${slugOrId}/invoices`,
          title: "Invoices",
          isSelected:
            currentPath === `${path}/clients/teams/${slugOrId}/invoices`,
          onClick,
        },
        {
          visible:
            isAgent &&
            isFirstPartyAgent &&
            !isNil(slugOrId) &&
            page === ECONDITIONAL_SUB_PAGES.clientTeams,
          linkTo: `${path}/clients/teams/${slugOrId}/notes`,
          title: "Notes",
          isSelected: currentPath === `${path}/clients/teams/${slugOrId}/notes`,
          onClick,
        },
      ],
    },
    {
      name: "Reviews",
      icon: "star",
      match: /\/dashboard\/agent\/reviews/,
      visible: isAgent && isFirstPartyAgent,
      subPages: [
        {
          visible: isAgent && isFirstPartyAgent,
          linkTo: `${path}/reviews`,
          title: "Manage reviews",
          isSelected: currentPath === `${path}/reviews`,
          onClick,
        },
      ],
    },
    {
      name: "Settings",
      icon: "settingsWheel",
      match:
        /\/dashboard\/agent\/account(\/settings|\/password|\/jobs-calendar|\/talent-invitation|\/agency-settings|\/manage-teams)/,
      visible: isAgent,
      subPages: [
        {
          visible: isAgent,
          linkTo: `${path}/account/settings`,
          title: "Your account",
          isSelected: currentPath === `${path}/account/settings`,
          onClick,
        },
        {
          visible: isAgent,
          linkTo: `${path}/account/manage-teams`,
          title: "Manage Teams",
          isSelected: currentPath === `${path}/account/manage-teams`,
          onClick,
        },
        {
          visible: isAgent,
          linkTo: `${path}/account/password`,
          title: "Change password",
          isSelected: currentPath === `${path}/account/password`,
          onClick,
        },
        {
          visible: isAgent,
          linkTo: `${path}/account/jobs-calendar`,
          title: "Jobs calendar",
          isSelected: currentPath === `${path}/account/jobs-calendar`,
          onClick,
        },
        {
          visible: isAgent,
          linkTo: `${path}/account/agency-settings`,
          title: "Agency settings",
          isSelected: currentPath === `${path}/account/agency-settings`,
          onClick,
        },
        {
          visible: isAgent && isFirstPartyAgent,
          linkTo: `${path}/account/talent-invitations`,
          title: "Talent invitations",
          isSelected: currentPath === `${path}/account/talent-invitations`,
          onClick,
        },
      ],
    },
  ];

  return (
    <>
      {pages.map((page, idx) => {
        return (
          page.visible && (
            <TopLevel
              key={`${page.name}-${idx}`}
              name={page.name}
              icon={page.icon}
              match={page.match}
            >
              {page.subPages.map((subPage, indx) => {
                return (
                  subPage.visible && (
                    <SubLevel
                      key={`${subPage.title}-${indx}`}
                      linkTo={subPage.linkTo}
                      title={subPage.title}
                      isSelected={subPage.isSelected}
                      onClick={subPage.onClick}
                    />
                  )
                );
              })}
            </TopLevel>
          )
        );
      })}
    </>
  );
};
