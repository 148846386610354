import React from "react";
import { PopupNotification } from "./PopupNotification";
import { InfoBanner, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { useStoreModel } from "src/hooks";

export const RefreshNotification = () => {
  const { shouldPromptToRefresh } = useStoreModel("contactConfig");

  if (!shouldPromptToRefresh) {
    return null;
  }

  return (
    <PopupNotification>
      <InfoBanner
        iconSize={24}
        css={{
          "> svg": {
            display: "none",
          },

          "@bp2": {
            "> svg": {
              display: "block",
            },
          },
        }}
      >
        <Flex
          css={{
            flexDirection: "column",
            "@bp2": {
              flexDirection: "row",
              spaceX: "$5",
            },
          }}
        >
          <Text
            css={{
              mb: "$5",
              "@bp2": {
                mb: 0,
              },
            }}
          >
            A new version of Contact is available.
          </Text>
          <Button
            variant={{ "@initial": "primary", "@bp2": "unstyled" }}
            onClick={() => window.location.reload()}
          >
            <Text
              css={{
                color: "$white",
                fontFamily: "$sansWide",
                fontSize: "$14",
                "@bp2": {
                  color: "$turquoiseDark",
                },
              }}
            >
              Refresh
            </Text>
          </Button>
        </Flex>
      </InfoBanner>
    </PopupNotification>
  );
};
