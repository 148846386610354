import { gql } from "@apollo/client";

export const FORGET_TALENT_MUTATION = gql`
  mutation ForgetTalent($talentId: ID!) {
    forgetTalent(talentId: $talentId) {
      id
      talent {
        talentProfile {
          vertical
        }
      }
    }
  }
`;
