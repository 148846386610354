import React from "react";
import { styled } from "@stitches/react";
import { useHistory, useLocation } from "react-router-dom";
import { Icon, Text } from "src/ccl/document";
import { Flex, Box } from "src/ccl/layout";
import { ShortlistVerticals } from "src/components/ShortlistVerticals";
import { useStoreModel } from "src/hooks";
import { getTalentVerticals } from "src/utils/user";
import { Button } from "src/ccl/navigation";

const Backdrop = styled("div", {
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: "$501",
  transition: "400ms ease all",
  opacity: 0,
  visibility: "hidden",

  variants: {
    state: {
      open: {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
});

const Wrapper = styled("div", {
  position: "fixed",
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "$white",
  zIndex: "$502",
  boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.15)",
  minWidth: "275px",
  width: "84vw",
  boxSizing: "border-box",
  overflowY: "auto",
  transition: "400ms ease all",
  transform: "translate(calc(100% + 40px), 0)",
  visibility: "hidden",

  variants: {
    state: {
      open: {
        transform: "translate(0, 0)",
        visibility: "visible",
      },
    },
  },
});

const EmptyState = () => (
  <Flex
    css={{
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Icon variant="checklist" size={40} color="grey6" />
    <Text>You have no talents shortlisted yet.</Text>
    <Text variant="meta" color="grey4">
      We recommend shortlisting double the number of talents you need.
    </Text>
  </Flex>
);

interface ShortlistMobileStackProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
  showShortlistingReminder: boolean;
  setShowShortlistingReminder: (state: boolean) => void;
}

export const ShortlistMobileStack = ({
  open,
  onContinue,
  onClose,
  showShortlistingReminder,
  setShowShortlistingReminder,
}: ShortlistMobileStackProps) => {
  const {
    draftV2: { talentUsers, removeTalentUser },
  } = useStoreModel("jobs");
  const talentVerticals = getTalentVerticals(talentUsers);
  const history = useHistory();
  const { loggedIn } = useStoreModel("currentUser");
  const { pathname } = useLocation();

  return (
    <Box
      data-test-id="ShortlistMobileStack"
      css={{
        "@bp4": {
          display: "none",
        },
      }}
    >
      <Backdrop state={open ? "open" : undefined} onClick={onClose} />
      <Wrapper state={open ? "open" : undefined}>
        <Flex
          css={{ py: "$11", px: "$8", height: "100%", flexDirection: "column" }}
        >
          <Flex
            css={{
              pb: "30px",
              height: "54px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text variant="h3">{`Shortlist (${talentUsers.length})`}</Text>
            <Box data-test-id="ShortlistMobileStackClose" onClick={onClose}>
              <Icon size={24} variant="closeCrossDark" color="grey2" />
            </Box>
          </Flex>

          <Flex
            css={{
              flexDirection: "column",
              maxHeight: "calc(100% - 48px - 54px - 57px)",
            }}
          >
            {pathname.includes("/jobs/new/shortlist") &&
              showShortlistingReminder && (
                <Flex
                  css={{
                    p: "$4",
                    mb: "$3",
                    borderRadius: "12px",
                    backgroundColor: "$grey1",
                    gap: "$3",
                  }}
                >
                  <Icon
                    size={16}
                    variant="excessiveShortlisting"
                    css={{ mt: "$2" }}
                  />
                  <Text variant="meta" color={"black"} css={{ flex: 1 }}>
                    We recommend shortlisting double the number of talents you
                    need.
                  </Text>
                  <Box
                    onClick={() => {
                      setShowShortlistingReminder(false);
                    }}
                    css={{ ml: "auto" }}
                  >
                    <Icon size={10} variant="cross" color="grey6" />
                  </Box>{" "}
                </Flex>
              )}

            {talentUsers.length > 0 ? (
              <Box
                css={{
                  overflowY: talentUsers.length > 0 ? "scroll" : "hidden",
                  borderBottom: "1px solid $grey2",
                  pb: "$5",
                }}
              >
                <ShortlistVerticals
                  talent={talentUsers}
                  talentVerticals={talentVerticals}
                  isRemovable={(u) =>
                    !!talentUsers.map((t) => t.id).includes(u.id)
                  }
                  onRemove={(u) => {
                    removeTalentUser(u.id);
                    if (talentUsers.length === 0) {
                      setShowShortlistingReminder(false);
                    }
                  }}
                  withinShortlistButton={true}
                />
              </Box>
            ) : (
              <EmptyState />
            )}
          </Flex>

          {!loggedIn && (
            <Flex
              onClick={() => history.push("/sign-in")}
              css={{
                height: "57px",
                justifyContent: "center",
                mx: "$8",
                gap: "6px",
                alignItems: "center",
                borderBottom: "1px solid $grey2",
                mb: "$3",
              }}
            >
              <Icon variant="user" size={16} />
              <Text variant="mini">Sign in</Text>
            </Flex>
          )}

          {!!talentUsers.length && (
            <Flex
              css={{
                alignItems: "flex-end",
                flex: 1,
              }}
            >
              <Flex
                css={{
                  alignItems: "center",
                  flex: 1,
                  gap: "$3",
                }}
              >
                {!pathname.includes("/jobs/new/shortlist") && (
                  <Button
                    variant="white"
                    onClick={() => history.push("/jobs/new/shortlist")}
                    css={{ flex: 1 }}
                  >
                    Shortlist more
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={onContinue}
                  css={{ flex: 1, border: "2px solid $black" }}
                >
                  Continue
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Wrapper>
    </Box>
  );
};
