import React from "react";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { LinkButton } from "src/ccl/navigation";
import { sendToAmplitude } from "src/utils/analytics";

export const NoBookmarkedCreativesCard = () => (
  <Box
    css={{
      pl: "$14",
      borderTop: "1px solid $grey3",
      borderBottom: "1px solid $grey3",
      pt: "$8",
      pb: "$18",
      "@bp2": {
        pl: "unset",
        py: "unset",
        maxWidth: 426,
        borderTop: "unset",
        borderBottom: "unset",
      },
    }}
    data-test-id="BookmarkedCreatives"
  >
    <Text variant="h2" css={{ pb: "$16" }}>
      All Bookmarks
    </Text>
    <Flex>
      <Box css={{ width: 115 }}>
        <Box
          css={{
            border: "1px solid red",
            background: "linear-gradient(180deg, #FEE196 0%, #FFA800 100%)",
            width: 100,
            height: 100,
            position: "absolute",
            filter: "blur(25px)",
          }}
        ></Box>
        <Icon
          variant="bookmark"
          solid={true}
          css={{
            width: 50,
            height: 65,
            position: "relative",
            top: 18,
            left: 25,
          }}
        />
      </Box>
      <Box css={{ pl: "$17" }}>
        <Text css={{ color: "$grey6", "@bp3": { color: "unset" } }}>
          Save all your favourite creatives in one place with bookmarks.
        </Text>
        <LinkButton
          to="/jobs/new/shortlist"
          css={{ mt: "$6" }}
          onClick={() =>
            sendToAmplitude(
              "booker dashboard - clicks bookmarks browse creatives empty state",
            )
          }
        >
          Browse Creatives
        </LinkButton>
      </Box>
    </Flex>
  </Box>
);
