import React from "react";
import { Icon } from "src/ccl/document";
import { TalentProfile, User } from "src/graphql/generated/types";
import { Flex, Box } from "src/ccl/layout";

export const ReviewForm = ({
  talent,
  onReview,
}: {
  talent: User;
  onReview?: (talent: User, rating: number) => void;
}) => {
  const talentProfile = talent.profile as TalentProfile;
  const review = talentProfile.jobReview;

  return (
    <Flex css={{ gap: "$2" }} data-test-id="TalentReviewForm">
      {[1, 2, 3, 4, 5].map((n) => (
        <Box
          data-test-id={`TalentReviewStar-${n}`}
          key={n}
          onClick={() => onReview && onReview(talent, n)}
          css={{ "&:hover": { cursor: onReview ? "pointer" : undefined } }}
        >
          <Icon
            variant={review && n <= review.rating ? "star" : "starOutline"}
            size={24}
          />
        </Box>
      ))}
    </Flex>
  );
};
