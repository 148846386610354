import { gql } from "@apollo/client";
import { JOB_VERTICAL_DETAILS_FRAGMENT } from "./JobDetails";

const TALENT_PROFILE_FRAGMENT = gql`
  fragment TalentProfile on TalentProfile {
    id
    location
    vertical
    averageRating
    totalReviews
  }
`;

export const SHORTLIST_DETAILS_FRAGMENT = gql`
  fragment ShortlistDetails on Job {
    id
    slug
    state
    vertical
    ...JobVerticalDetails
    talent {
      id
      name
      state
      talent {
        id
        name
        slug
        featuredAsset {
          mediaUrl
        }
        profile {
          ...TalentProfile
        }
      }
    }
  }
  ${TALENT_PROFILE_FRAGMENT}
  ${JOB_VERTICAL_DETAILS_FRAGMENT}
`;
