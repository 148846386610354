import {
  Maybe,
  User,
  Job,
  FileUploadInput,
  PortfolioImage,
} from "./generated/types";
export * from "./generated/types";

export type Assignee = Pick<
  User,
  "id" | "name" | "featuredAsset" | "assignedLiveJobCount"
>;

export interface Edge {
  node: unknown;
}

export interface EdgeList {
  edges?: Array<Maybe<Edge>>;
}

export type JobAddressDetails = Pick<
  Job,
  "addressLine1" | "addressLine2" | "city" | "postcode" | "country"
>;

export interface DraftPortfolioImage extends PortfolioImage {
  fileData?: FileUploadInput;
}

export type JobConfirmDetails = Pick<
  Job,
  "addressLine1" | "addressLine2" | "city" | "postcode" | "country"
>;

export type ReviewSortColumnName =
  | "rating"
  | "bookerName"
  | "jobName"
  | "createdAt"
  | "creativeName";

export type PackageSortColumnName =
  | "name"
  | "state"
  | "booker_name"
  | "agency_name"
  | "created_at";

export type ReviewContext = "agencyReviews" | "talentReviews";

export enum JobDateRangeOption {
  NONE = "",
  ThisWeek = "This Week",
  LastSevenDays = "Last 7 Days",
  ThisMonth = "This Month",
  LastThirtyDays = "Last 30 Days",
  LastMonth = "Last Month",
  Custom = "Custom",
}

export enum CreativeSearchRefineOption {
  All = "",
  RecentlyBooked = "recentlyBooked",
  Bookmarked = "bookmarked",
}

export type ProfileFormContext = "create" | "edit" | "talentOnboard";
