import React, { useEffect, useState } from "react";
import type * as Stitches from "@stitches/react";
import { Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { mergeCss } from "src/ccl";

interface FilterGroupProps {
  title: React.ReactNode;
  expandedTitle?: React.ReactNode;
  expanded: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
  containerCss?: Stitches.CSS;
  children?: React.ReactNode;
}

const defaultContainerCss: Stitches.CSS = {
  justifyContent: "space-between",
  alignItems: "center",
  padding: "$6",
  cursor: "pointer",
  borderTopWidth: 1,
  borderTopColor: "$grey3",
  borderTopStyle: "solid",
};

export const FilterGroup = ({
  title,
  expandedTitle = title,
  expanded,
  onExpand,
  onCollapse,
  children,
  containerCss = defaultContainerCss,
}: FilterGroupProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const isCollapsed = !isExpanded;

  useEffect(() => {
    if (isCollapsed && onCollapse) {
      onCollapse();
    }
    if (isExpanded && onExpand) {
      onExpand();
    }
  }, [isCollapsed]);

  return (
    <>
      <Flex
        css={mergeCss({ px: "$6", py: "$4" }, containerCss)}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        role="button"
        aria-pressed={isCollapsed ? "true" : "false"}
      >
        {isCollapsed && (
          <Text
            css={{
              fontSize: "$16",
              lineHeight: "26px",
              fontWeight: "$bold",
            }}
          >
            {title}
          </Text>
        )}
        {isExpanded && (
          <Text
            css={{
              fontSize: "$16",
              lineHeight: "26px",
              fontWeight: "$bold",
            }}
          >
            {expandedTitle}
          </Text>
        )}
        <Icon variant={isCollapsed ? "chevronDown" : "chevronUp"} size={16} />
      </Flex>

      {isExpanded && children}
    </>
  );
};
