import { gql } from "@apollo/client";

export const AGENT_PACKAGE_LIST_QUERY = gql`
  query AgentPackageList(
    $first: Int
    $after: String
    $state: PackageState
    $name: String
    $sortOrder: SortOrderInput
    $creatorName: String
    $agencyName: String
  ) {
    me {
      id
      agency {
        id
        packages(
          first: $first
          after: $after
          state: $state
          name: $name
          sortOrder: $sortOrder
          creatorName: $creatorName
          agencyName: $agencyName
        ) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              booker {
                name
              }
              creator {
                name
                agency {
                  name
                }
              }
              slug
              bookerName
              createdAt
              shortlistCount
              state
              jobSlug
              jobState
              isTemplate
            }
          }
        }
      }
    }
  }
`;
