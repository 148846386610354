import React, { useCallback, useEffect, useState } from "react";
import { CSS } from "@stitches/react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/client";
import { PreviewList } from "./PreviewList";
import { useStoreModel } from "src/hooks";
import { Box, Flex } from "src/ccl/layout";
import { Loading } from "src/components";
import { AssetImage, Icon, Text, Img } from "src/ccl/document";
import { ValidationBlock } from "src/ccl/feedback";
import { computeChecksum } from "src/utils/checksum";
import {
  Asset,
  Mutation,
  MutationGeneratePresignedUrlArgs,
} from "src/graphql/types";
import { mergeCss, ResponsiveValue, styled } from "src/ccl/stitches";
import { GENERATE_PRESIGNED_URL_MUTATION } from "src/graphql/mutations";
import { UploadedFile } from "src/entities";
import { AssetImageSize } from "src/ccl/document/assetImage/types";

const ALLOWED_FILE_TYPES = {
  pdf: ["application/pdf"],
  image: ["image/jpeg", "image/png", "image/webp"],
  all: ["application/pdf", "image/jpeg", "image/png", "image/webp"],
};

type FileTypes = keyof typeof ALLOWED_FILE_TYPES;
type AttachmentStoreNames = "deck_files" | "uploads";

interface FileDragDropUploadProps {
  contentNoFiles: React.ReactNode;
  contentWithFiles?: React.ReactNode;
  contentHovering?: React.ReactNode;
  onUpload: (files: UploadedFile[]) => void;
  onRemove?: (file: UploadedFile) => void;
  type: FileTypes;
  attachmentStoreName: AttachmentStoreNames;
  multiple?: boolean;
  maxFileCount?: number;
  maxFileSizeMb?: number;
  existingFileCount?: number;
  showPreviewList?: boolean;
  loading?: boolean;
  showPreviewImage?: boolean;
  showPlaceholder?: boolean;
  showMaxFileCountReached?: boolean;
  existingImage?: Asset;
  withBorder?: boolean;
  altText?: string;
  containerCss?: CSS;
  placeholderVariant?: "default" | "grey";
  isReplaceable?: boolean;
  imageCss?: CSS;
  assetImageSize?: ResponsiveValue<AssetImageSize>;
  assetImageContainer?: CSS;
  assetImageCss?: CSS;
}

const StyledFlex = styled(Flex, {
  height: 120,
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  outline: "none",
});

export const FileDragDropUpload = ({
  type,
  multiple = true,
  contentHovering = <Text>Drop files here...</Text>,
  contentNoFiles,
  contentWithFiles,
  maxFileSizeMb,
  maxFileCount = 0,
  existingFileCount,
  onUpload,
  onRemove,
  attachmentStoreName,
  showPreviewList = true,
  loading = false,
  showPreviewImage = false,
  showPlaceholder = true,
  placeholderVariant = "default",
  showMaxFileCountReached = true,
  existingImage,
  withBorder = false,
  altText = "Uploaded asset",
  containerCss = {},
  isReplaceable = false,
  imageCss = {},
  assetImageSize = {
    "@initial": {
      width: 150,
      aspectRatio: 1,
    },
  },
  assetImageContainer = { width: "$19" },
  assetImageCss = {},
}: FileDragDropUploadProps) => {
  const [generatePresignedUrl] = useMutation<
    Mutation,
    MutationGeneratePresignedUrlArgs
  >(GENERATE_PRESIGNED_URL_MUTATION);

  const { uploadFile: uploadFileToS3 } = useStoreModel("s3");
  const [temporaryImage, setTemporaryImage] = useState<File>();
  useEffect(() => {
    if (temporaryImage && !existingImage) {
      setTemporaryImage(undefined);
    }
    if (!existingImage) {
      setUploadedFilesToPreview([]);
    }
  }, [temporaryImage, existingImage]);

  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<
    { title: string; body?: string } | undefined
  >();
  const [uploadedFilesToPreview, setUploadedFilesToPreview] = useState<
    UploadedFile[]
  >([]);
  const tooManyFilesError = {
    title: `Max limit of ${maxFileCount} files reached`,
    body: `Delete some files to add more`,
  };

  let filesLeftToUpload = 0; // unlimited
  if (maxFileCount > 0) {
    const existing = existingFileCount || 0;
    const alreadyUploaded = uploadedFilesToPreview.length;

    filesLeftToUpload = maxFileCount - existing - alreadyUploaded;
  }

  const onDropAcceptedCallback = useCallback(
    async (acceptedFiles: File[]) => {
      setError(undefined);
      if (
        maxFileSizeMb &&
        acceptedFiles.some((f) => f.size >= 1024 * 1024 * maxFileSizeMb)
      ) {
        setError({
          title: "File size too large",
          body: `Each file must be under ${maxFileSizeMb}MB`,
        });
        return;
      }

      setIsUploading(true);

      let uploadedFiles: UploadedFile[] = [];

      for (const file of acceptedFiles) {
        const checksum = await computeChecksum(file);

        const generateUrlRsp = await generatePresignedUrl({
          variables: {
            attachment: attachmentStoreName,
            byteSize: file.size,
            checksum,
            contentType: file.type,
            filename: file.name,
          },
        });

        const data = generateUrlRsp?.data?.generatePresignedUrl;

        if (data) {
          const headers: Headers = new Headers();
          data.headers.forEach((h) => {
            headers.append(h.key, h.value);
          });

          const uploadRsp = await uploadFileToS3.request({
            url: data.url,
            headers: headers,
            body: file,
          });
          if (type === "image") {
            setTemporaryImage(file);
          }

          if (uploadRsp) {
            uploadedFiles = [
              {
                key: data.key,
                filename: file.name,
                contentType: file.type,
                contentLength: file.size,
                mediaUrl: URL.createObjectURL(file),
              },
              ...uploadedFiles,
            ];
          }
        }
      }

      onUpload(uploadedFiles);
      setUploadedFilesToPreview([...uploadedFilesToPreview, ...uploadedFiles]);

      setIsUploading(false);
    },
    [uploadedFilesToPreview, onUpload],
  );

  const onDropRejectedCallack = useCallback(
    async (rejectedFiles: FileRejection[]) => {
      if (rejectedFiles[0].errors.length) {
        if (rejectedFiles[0].errors[0].message === "Too many files") {
          setError(tooManyFilesError);
        }
        if (rejectedFiles[0].errors[0].message.includes("File type must")) {
          setError({
            title: "Incorrect file type",
            body: rejectedFiles[0].errors[0].message.replace(
              /\s[^\s]+\//g,
              " ",
            ),
          });
        }
      } else {
        setError({ title: "Something went wrong" });
      }
    },
    [],
  );

  const isDisabled = isReplaceable
    ? isUploading
    : maxFileCount === 1 && filesLeftToUpload < 1;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: onDropAcceptedCallback,
    onDropRejected: onDropRejectedCallack,
    accept: ALLOWED_FILE_TYPES[type],
    disabled: isDisabled,
    multiple,
    maxFiles: filesLeftToUpload,
  });

  let content: React.ReactNode;

  if (isUploading || loading) {
    content = (
      <>
        <Loading />
        <Text>Uploading...</Text>
      </>
    );
  } else if (filesLeftToUpload <= 0 && showMaxFileCountReached) {
    content = (
      <>
        <Text>Max limit of {maxFileCount} files reached</Text>
        <Text variant="meta" color="red">
          Delete some files to add more
        </Text>
      </>
    );
  } else if (isDragActive) {
    content = contentHovering;
  } else if (existingImage || temporaryImage || uploadedFilesToPreview.length) {
    content = contentWithFiles;
  } else {
    content = <>{contentNoFiles}</>;
  }

  const PlaceholderIcon = ({
    placeholderVariant,
  }: {
    placeholderVariant: "default" | "grey";
  }) => (
    <Flex
      css={{
        width: "$17",
        height: "$17",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: placeholderVariant === "default" ? "$black" : "$grey2",
      }}
    >
      <Icon
        variant="cameraPhoto"
        color={placeholderVariant === "default" ? "white" : "black"}
        size={20}
      />
    </Flex>
  );

  const PreviewImage = () => {
    const previewImage = existingImage
      ? existingImage
      : temporaryImage
      ? {
          mediaUrl: URL.createObjectURL(temporaryImage),
          width: 0,
          height: 0,
          id: "",
          sortWeight: 0,
        }
      : undefined;

    if (previewImage?.mediaUrl?.startsWith("blob:")) {
      return (
        <Img
          css={mergeCss(
            {
              width: "40%",
              height: "100%",
              objectFit: "cover",
            },
            imageCss,
          )}
          src={previewImage.mediaUrl}
        />
      );
    } else if (previewImage) {
      return (
        <Box css={assetImageContainer} data-test-id="UploadAsset">
          <AssetImage
            asset={previewImage}
            size={assetImageSize}
            alt={altText}
            crop="clip"
            containerCss={assetImageCss}
          />
        </Box>
      );
    }

    if (showPlaceholder) {
      return (
        <Box css={{ width: "$17" }}>
          <PlaceholderIcon placeholderVariant={placeholderVariant} />
        </Box>
      );
    }
    return <></>;
  };

  const hasPreviewImage =
    showPreviewImage && (existingImage || temporaryImage || showPlaceholder);

  return (
    <>
      <StyledFlex
        css={mergeCss(
          {
            border: withBorder ? "2px dashed black" : "none",
            backgroundColor: isDragActive ? "$grey1" : "$white",
            cursor: isDisabled ? "not-allowed" : "pointer",
          },
          containerCss,
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        {hasPreviewImage && <PreviewImage />}
        {content}
      </StyledFlex>

      {error && (
        <ValidationBlock
          variant="error"
          title={error.title}
          body={error.body}
          css={{ mt: "$4" }}
        />
      )}

      {uploadedFilesToPreview.length > 0 && showPreviewList && (
        <PreviewList
          files={uploadedFilesToPreview}
          onRemove={(file) => {
            setUploadedFilesToPreview(
              uploadedFilesToPreview.filter((f) => f.key !== file.key),
            );
            onRemove && onRemove(file);
          }}
        />
      )}
    </>
  );
};
