import { gql } from "@apollo/client";

export const CREATE_BOOKER_MUTATION = gql`
  mutation CreateBooker(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $company: String!
    $kind: String
    $role: String
    $headcountEstimate: CompanyHeadcountEstimate!
    $website: String
    $surfacedFrom: String
    $password: String!
  ) {
    createBooker(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      company: $company
      kind: $kind
      role: $role
      headcountEstimate: $headcountEstimate
      website: $website
      surfacedFrom: $surfacedFrom
      password: $password
    ) {
      id
      name
      bookerProfile {
        company
        website
        kind
      }
    }
  }
`;
