import React from "react";
import * as Stitches from "@stitches/react";
import { ResponsiveText } from "./ResponsiveText";
import { Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { InfoBanner } from "src/ccl/document/infoBanner";

export const LegacyShortlistButton = ({
  css = {},
  onClick,
  isShortlisted,
  disabled,
}: {
  disabled: boolean;
  isShortlisted: boolean;
  onClick: () => void;
  css?: Stitches.CSS;
}) => {
  return (
    <Button
      data-test-id="shortlistButton"
      title={
        disabled
          ? "Creatives cannot be removed after a job has been approved"
          : undefined
      }
      onClick={onClick}
      disabled={disabled}
      variant={isShortlisted ? "green" : "default"}
      css={{
        width: "fit-content",
        ...css,
      }}
    >
      <InfoBanner
        icon={isShortlisted ? "tick" : "plus"}
        withIcon={true}
        variant="centered"
        css={{ display: "inline-flex" }}
        color={isShortlisted ? "white" : "black"}
      >
        <Text
          variant="small"
          color={isShortlisted ? "white" : "black"}
          css={{ fontFamily: "$sansWide", textTransform: "uppercase" }}
        >
          {isShortlisted && "Shortlisted"}
          {!isShortlisted && (
            <ResponsiveText initial="Shortlist" bp2="Add to shortlist" />
          )}
        </Text>
      </InfoBanner>
    </Button>
  );
};
