import React from "react";
import { capitalize, camelCase } from "lodash";
import { Avatar, Carousel, Pill } from "src/ccl/blocks";
import {
  AvailableIcon,
  Icon,
  ImageGallery,
  Review,
  TalentCardProps,
  Text,
} from "src/ccl/document";
import { TalentProfile, TalentVertical, User } from "src/graphql/types";
import { Box, Flex } from "src/ccl/layout";
import { getInitials, uppercaseFirstLetter } from "src/utils/lang";
import { InfoBanner } from "src/ccl/document/infoBanner";
import { Link } from "src/ccl/navigation";
import { styled } from "src/ccl/stitches";
import { Tooltip } from "src/ccl/feedback";
import { getFullTravelLocation, verticalIconMapAlt } from "src/utils/user";
import { ShortlistButton, LegacyShortlistButton } from "src/components";
import { BookmarkCircle } from "src/ccl/data-entry";
import { BookmarkAndCollectionCircle } from "src/components/dashboards/booker/bookmarks/collections/BookmarkAndCollectionCircle";
import { baseUrl } from "src/config";

const MAX_GALLERY_ITEMS = 6;
const MAX_BADGES = 2;

export interface MultiImageTalentCardProps extends TalentCardProps {
  shortlistToggle: (talent: User, isShorlisted: boolean) => void;
  isShortlisted?: (talent: User) => boolean;
  inListView?: boolean;
  analyticsEventPage?: string;
  openTalentProfileModal?: (slug: string) => void;
  analyticsProperty?: string;
}

const Container = styled(Flex, {
  borderTop: "1px solid $grey3",
  borderBottom: "1px solid $grey3",
  py: "$6",
  pl: "$6",
  flexDirection: "column",
  mx: "-18px",
  "@bp2": {
    mx: 0,
    border: "1px solid $grey3",
    flexDirection: "initial",
    py: "$8",
    pl: "$8",
  },
  variants: {
    state: {
      listView: {
        border: "revert",
        borderBottom: "1px solid $grey3",
        p: "revert",
        pb: "$9",
        height: "275px",
      },
    },
  },
});

const TravelLocationsContainer = styled(Flex, {
  backgroundColor: "$black",
  width: "28px",
  height: "28px",
  borderRadius: "50%",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: "$200",
  justifyContent: "center",
  alignItems: "center",
});

const ShortlistButtonContainer = styled("div", {
  position: "absolute",
  bottom: 18,
  right: 38,
  zIndex: "$100",
});

export const MultiImageTalentCard = ({
  talent,
  shortlistToggle,
  isShortlisted,
  url,
  travelLocation,
  isCheckable,
  handleBookmark,
  isBookmarkDisabled,
  inListView,
  openTalentProfileModal,
  onAddToCollection,
  analyticsEventPage,
  analyticsProperty,
}: MultiImageTalentCardProps) => {
  const { name, featuredAsset } = talent;
  const profile = talent.profile as TalentProfile;
  const {
    primaryLocation,
    portfolioImages,
    portfolioImageCategories,
    lastPortfolioUploads,
    verticalData,
    averageRating,
    totalReviews,
  } = profile;
  const fullTravelLocation =
    travelLocation &&
    getFullTravelLocation(travelLocation, profile.additionalLocations);
  const matchesTravelLocation =
    travelLocation &&
    profile.primaryLocation?.name
      .toLowerCase()
      .includes(travelLocation?.toLowerCase());
  const locationColor = travelLocation
    ? matchesTravelLocation
      ? "black"
      : "grey6"
    : "black";
  let imageGalleryUrls: string[] = [];
  const categories: Set<string> = new Set();
  const shortlisted = !!isShortlisted && isShortlisted(talent);

  if (lastPortfolioUploads) {
    imageGalleryUrls = lastPortfolioUploads.map((u) => u.mediaUrl);
  } else {
    portfolioImages.forEach((image) => {
      if (imageGalleryUrls.length < MAX_GALLERY_ITEMS) {
        imageGalleryUrls.push(image.mediaUrl);
      }
    });
  }

  const creativeType: string[] =
    JSON.parse(
      verticalData?.find((d) => d.key === "creative_type")?.value || "null",
    ) || [];

  const buttonDisabled =
    (shortlisted && isCheckable && !isCheckable(talent)) || false;
  const toggleShortlist = () =>
    isCheckable && isCheckable(talent) && shortlistToggle(talent, !shortlisted);

  const tags = portfolioImageCategories.map(({ name }) => name);

  return (
    <Container
      data-test-id="MultiImageTalentCard"
      css={
        inListView
          ? {
              "@bp3": {
                border: "revert",
                borderBottom: "1px solid $grey3",
                maxWidth: "1385px",
              },
            }
          : {
              "@bp3": {
                [`&:hover ${BookmarkCircle}`]: {
                  opacity: 0.5,
                },
              },
            }
      }
      state={inListView ? "listView" : undefined}
    >
      <Box
        css={{
          width: inListView ? "330px" : "271px",
          pr: inListView ? "$8" : undefined,
          flexShrink: 0,
        }}
      >
        <Box
          css={
            inListView
              ? { display: "flex", justifyContent: "space-between" }
              : {
                  display: "inline-flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  justifyContent: "space-between",
                }
          }
        >
          <Box>
            <Flex>
              <Box
                css={
                  inListView
                    ? { position: "relative" }
                    : { pl: "10px", pt: "10px", position: "relative" }
                }
              >
                {fullTravelLocation && (
                  <TravelLocationsContainer>
                    <Tooltip
                      content={`Willing to travel to ${capitalize(
                        fullTravelLocation,
                      )}`}
                      alignment="left"
                    >
                      <Icon variant="travelLocation" size={16} color="white" />
                    </Tooltip>
                  </TravelLocationsContainer>
                )}
                <Box
                  onClick={() => {
                    openTalentProfileModal &&
                      openTalentProfileModal(talent.slug);
                  }}
                >
                  <Avatar
                    css={inListView ? { cursor: "pointer" } : {}}
                    size={inListView ? "$18" : "$largeAvatar"}
                    initials={getInitials(name)}
                    imageUrl={featuredAsset?.mediaUrl}
                  />
                </Box>

                {inListView && (
                  <Icon
                    css={{ position: "absolute", bottom: 0, right: 2 }}
                    size={30}
                    variant={verticalIconMapAlt[profile.vertical]}
                  />
                )}
              </Box>
              {!inListView && (
                <Box css={{ ml: "$6" }}>
                  <Link
                    to={
                      url
                        ? url(talent.slug)
                        : `${baseUrl}/creatives/${talent.slug}`
                    }
                    style={{ color: "black" }}
                  >
                    <Text variant="h4">{name}</Text>
                  </Link>
                  <Flex>
                    {primaryLocation && (
                      <InfoBanner
                        icon="location"
                        variant="tightCentered"
                        color={locationColor}
                        css={{ mt: "$1" }}
                      >
                        <Text variant="meta" color={locationColor}>
                          {primaryLocation.name}
                        </Text>
                      </InfoBanner>
                    )}
                    {averageRating && (
                      <Review
                        average={averageRating}
                        total={totalReviews}
                        css={{
                          ml: "$3",
                        }}
                      />
                    )}
                  </Flex>
                </Box>
              )}
            </Flex>
            {!inListView && (
              <Flex
                css={{
                  flexWrap: "wrap",
                  mt: "$7",
                }}
              >
                {creativeType.map((type, index) => (
                  <Pill key={`creativeType-${index}`} variant="roundedTertiary">
                    <Box>
                      <InfoBanner
                        icon={camelCase(type) as AvailableIcon}
                        color="white"
                        css={{ alignItems: "center" }}
                      >
                        <Text color="white" variant="meta">
                          {uppercaseFirstLetter(type.split("_")[0])}
                        </Text>
                      </InfoBanner>
                    </Box>
                  </Pill>
                ))}
                {Array.from(categories)
                  .slice(0, MAX_BADGES)
                  .map((category, index) => (
                    <Pill key={index} variant="roundedSecondary">
                      <Text variant="meta" color="black">
                        {uppercaseFirstLetter(category)}
                      </Text>
                    </Pill>
                  ))}
                {categories.size > MAX_BADGES && (
                  <>
                    <Pill key={MAX_BADGES} variant="roundedSecondary">
                      <Text variant="meta" color="black">
                        ... and {categories.size - MAX_BADGES} more
                      </Text>
                    </Pill>
                  </>
                )}
              </Flex>
            )}
          </Box>
          <Flex></Flex>
          <Flex>
            {!inListView && (
              <LegacyShortlistButton
                onClick={toggleShortlist}
                isShortlisted={shortlisted}
                disabled={buttonDisabled}
                css={{
                  display: "none",
                  "@bp2": { display: "initial" },
                }}
              />
            )}
            {handleBookmark && (
              <Box css={{ ml: "auto" }}>
                <BookmarkAndCollectionCircle
                  onAddToCollection={
                    onAddToCollection
                      ? () => onAddToCollection(talent)
                      : undefined
                  }
                  analyticsEventPage={analyticsEventPage}
                  size="large"
                  css={{
                    display: "none",
                    transition: "opacity 0.25s",
                    "@bp3": {
                      display: "block",
                    },
                    backgroundColor: inListView ? "white" : undefined,
                    border: inListView
                      ? "1px solid rgba(0, 0, 0, 0.2)"
                      : undefined,
                    "&:checked": { border: "none" },
                  }}
                  checked={talent.bookmarked}
                  onClick={(e) => {
                    handleBookmark(
                      (e.target as HTMLInputElement).checked,
                      talent.id,
                    );
                    if (isBookmarkDisabled) {
                      e.preventDefault();
                    }
                  }}
                  // React throws errors about a read-only form if
                  // there is no onChange prop supplied to an input field
                  onChange={() => {}}
                  data-test-id="MultiImageTalentCardBookmark"
                />
              </Box>
            )}
          </Flex>
        </Box>
        {inListView && (
          <Flex
            css={{
              flexDirection: "column",
              width: "100%",
              height: "142px",
              pt: "17px",
              pb: "14px",
            }}
          >
            <Flex
              onClick={() => {
                openTalentProfileModal && openTalentProfileModal(talent.slug);
              }}
            >
              <Text
                variant="meta"
                weight="bold"
                css={{
                  "&:hover": { textDecoration: "underline", cursor: "pointer" },
                }}
              >
                {talent.name}
              </Text>
            </Flex>
            <Flex css={{ gap: "0px", mt: "$3" }}>
              {primaryLocation && (
                <InfoBanner
                  icon="location"
                  variant="tightCentered"
                  color={locationColor}
                >
                  <Text variant="meta">{primaryLocation.name}</Text>
                </InfoBanner>
              )}
              {talent.groups?.[0]?.name && (
                <>
                  <Icon
                    size={8}
                    variant="dot"
                    css={{ alignSelf: "center", mx: "$3" }}
                  />
                  <Text variant="meta">{talent.groups?.[0]?.name}</Text>
                </>
              )}

              {averageRating && (
                <Review
                  average={averageRating}
                  total={totalReviews}
                  textVariant="meta"
                  css={{
                    ml: "$5",
                  }}
                />
              )}
            </Flex>
            <Flex css={{ mt: "auto", gap: "5px" }}>
              {Array.from(tags)
                .slice(0, MAX_BADGES)
                .map((tag, i) => (
                  <Pill
                    key={i}
                    variant="roundedSecondary"
                    css={{
                      backgroundColor: "$grey1",
                      height: "30px",
                      m: "revert",
                    }}
                  >
                    <Text variant="meta" color="grey6">
                      {tag}
                    </Text>
                  </Pill>
                ))}
              {tags.length > MAX_BADGES && (
                <>
                  <Pill
                    key={MAX_BADGES}
                    variant="roundedSecondary"
                    css={{
                      backgroundColor: "$grey1",
                      height: "30px",
                      m: "revert",
                    }}
                  >
                    <Text variant="meta" color="grey6">
                      +{tags.length - MAX_BADGES}
                    </Text>
                  </Pill>
                </>
              )}
            </Flex>
          </Flex>
        )}
      </Box>

      {!inListView && (
        <Box
          css={{
            flex: 1,
            my: "$6",
            "@bp2": {
              my: "$0",
              ml: "$14",
            },
          }}
        ></Box>
      )}

      <Box
        css={
          inListView
            ? {
                position: "relative",
                "@bp3": { width: "738px" },
                "@bp5": { width: "1055px" },
              }
            : {}
        }
      >
        {imageGalleryUrls.length > 0 && !inListView && (
          <ImageGallery
            assetsUrls={imageGalleryUrls}
            alt={`A picture ${
              profile.vertical === TalentVertical.FashionModel ? "of" : "by"
            } ${talent.name}`}
          />
        )}
        {inListView && (
          <>
            <Carousel
              context="listView"
              ArrowCss={{
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {imageGalleryUrls.map((url, i) => (
                <div
                  key={`${url}-${i}`}
                  onClick={() =>
                    openTalentProfileModal &&
                    openTalentProfileModal(talent.slug)
                  }
                  style={{ minWidth: "fit-content", height: "fit-content" }}
                >
                  <img
                    src={`${url}?h=300`}
                    alt={`A picture ${
                      profile.vertical === TalentVertical.FashionModel
                        ? "of"
                        : "by"
                    } ${talent.name}`}
                    style={{ height: "243px", cursor: "pointer" }}
                  />
                </div>
              ))}
              <Box css={{ minHeight: "calc(100% + 1px)", minWidth: "7px" }} />
            </Carousel>
            <Box
              css={{
                height: "calc(100% + 1px)",
                width: "23px",
                background:
                  "linear-gradient(90deg, #FFFFFF 10.42%, rgba(255, 255, 255, 0) 100%)",
                position: "absolute",
                right: "-1px",
                bottom: "-1px",
                transform: "rotate(180deg)",
              }}
            />
            <ShortlistButtonContainer data-test-id="TalentCardShortlistButton">
              <ShortlistButton
                shortlisted={shortlisted}
                toggleShortlist={toggleShortlist}
                css={{ opacity: 1 }}
              />
            </ShortlistButtonContainer>
          </>
        )}
      </Box>

      {!inListView && (
        <Flex css={{ width: "fit-content", mt: "$6" }}>
          <LegacyShortlistButton
            onClick={toggleShortlist}
            isShortlisted={shortlisted}
            disabled={buttonDisabled}
            css={{
              "@bp2": { display: "none" },
            }}
          />
          {handleBookmark && (
            <Box css={{ ml: "$10" }}>
              <BookmarkAndCollectionCircle
                onAddToCollection={
                  onAddToCollection
                    ? () => onAddToCollection(talent)
                    : undefined
                }
                analyticsEventPage={analyticsEventPage}
                size="large"
                css={{
                  display: "block",
                  "@bp2": { display: "none" },
                }}
                checked={talent.bookmarked}
                onClick={(e) => {
                  handleBookmark(
                    (e.target as HTMLInputElement).checked,
                    talent.id,
                  );
                  if (isBookmarkDisabled) {
                    e.preventDefault();
                  }
                }}
                // React throws errors about a read-only form if
                // there is no onChange prop supplied to an input field
                onChange={() => {}}
                data-test-id="TalentCardBookmark"
                analyticsProperty={analyticsProperty}
              />
            </Box>
          )}
        </Flex>
      )}
    </Container>
  );
};
