import { gql } from "@apollo/client";

export const AUTH_QUERY = gql`
  query Auth($email: String!, $password: String!) {
    auth(email: $email, password: $password) {
      jwt
      unauthorized
    }
  }
`;
