import { gql } from "@apollo/client";
import { NOTE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const CREATE_AGENCY_TALENT_NOTE_MUTATION = gql`
  mutation CreateAgencyTalentNote($talentId: ID!, $body: String!) {
    createAgencyTalentNote(talentId: $talentId, body: $body) {
      ...NoteDetails
    }
  }
  ${NOTE_DETAILS_FRAGMENT}
`;
