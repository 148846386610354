import { gql } from "@apollo/client";

export const CHATROOMS_LIST_QUERY = gql`
  query Me($filters: ChatroomFilterInput) {
    me {
      id
      chatrooms(filters: $filters) {
        nodes {
          id
          name
          createdAt
          job {
            id
            name
            slug
            startDate
          }
          kind
          lastSentAt
          chatroomParticipants {
            isAdmin
            participant {
              id
              name
              featuredAsset {
                mediaUrl
              }
              talentProfile {
                vertical
              }
              kinds
            }
          }
        }
      }
    }
  }
`;
