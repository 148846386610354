import { useState } from "react";
import { ShareProfileLink } from "./ShareProfileLink";
import { Flex, Grid } from "src/ccl/layout";
import { Icon, SignInModal, Text } from "src/ccl/document";
import {
  SocialMediaPlatform,
  TalentProfile,
  User,
} from "src/graphql/generated/types";
import { ExternalLink } from "src/ccl/navigation";
import { convertFollowerCount, getSocialMedia } from "src/utils/socialMedia";
import { useStoreModel } from "src/hooks";

interface LinksSectionProps {
  talent: User;
  isLegacy?: boolean;
}

export const LinksSection = ({
  talent,
  isLegacy = true,
}: LinksSectionProps) => {
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };

  const { loggedIn } = useStoreModel("currentUser");
  const profile = talent.profile as TalentProfile;

  const instragram = getSocialMedia(profile, SocialMediaPlatform.Instagram);
  const tiktok = getSocialMedia(profile, SocialMediaPlatform.Tiktok);
  const youtube = getSocialMedia(profile, SocialMediaPlatform.Youtube);

  return (
    <Flex css={{ flexDirection: "column", gap: "$4", "@bp2": { gap: "$8" } }}>
      {isLegacy && (
        <Grid
          css={{ gridColumns: 1, gridGap: "$4", "@bp2": { gridColumns: 2 } }}
        >
          {instragram && (
            <ExternalLink
              to={
                loggedIn ? `https://www.instagram.com/${instragram.handle}` : ""
              }
              onClick={(e) => {
                if (!loggedIn) {
                  e.preventDefault();
                  toggleSignInModal();
                }
              }}
              target="_blank"
              eventName={"talentProfile:instagramLink"}
              css={{
                display: "flex",
                alignItems: "center",
                fontSize: "$14",
                lineHeight: "$22",
                textDecoration: "none",
              }}
            >
              <Flex css={{ alignItems: "center", gap: "$3" }}>
                <Icon variant="instagramColour" size={30} />
                <Flex css={{ flexDirection: "column" }}>
                  <Text variant="mini">
                    {convertFollowerCount(instragram.followerCount)} Followers
                  </Text>
                  {loggedIn && (
                    <Flex
                      css={{
                        alignItems: "center",
                        gap: "$2",
                      }}
                    >
                      @{instragram.handle}
                      <Icon variant="linkOut" size={14} />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </ExternalLink>
          )}

          {tiktok && (
            <ExternalLink
              to={loggedIn ? `https://www.tiktok.com/@${tiktok.handle}` : ""}
              onClick={(e) => {
                if (!loggedIn) {
                  e.preventDefault();
                  toggleSignInModal();
                }
              }}
              target="_blank"
              eventName={"talentProfile:tiktokLink"}
              css={{
                display: "flex",
                alignItems: "center",
                fontSize: "$14",
                lineHeight: "$22",
                textDecoration: "none",
              }}
            >
              <Flex css={{ alignItems: "center", gap: "$3" }}>
                <Icon variant="tiktok" size={30} />
                <Flex css={{ flexDirection: "column" }}>
                  <Text variant="mini">
                    {convertFollowerCount(tiktok.followerCount)} Followers
                  </Text>
                  {loggedIn && (
                    <Flex
                      css={{
                        alignItems: "center",
                        gap: "$2",
                      }}
                    >
                      @{tiktok.handle}
                      <Icon variant="linkOut" size={14} />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </ExternalLink>
          )}

          {youtube && (
            <ExternalLink
              to={loggedIn ? `https://www.youtube.com/@${youtube.handle}` : ""}
              onClick={(e) => {
                if (!loggedIn) {
                  e.preventDefault();
                  toggleSignInModal();
                }
              }}
              target="_blank"
              eventName={"talentProfile:tiktokLink"}
              css={{
                display: "flex",
                alignItems: "center",
                fontSize: "$14",
                lineHeight: "$22",
                textDecoration: "none",
              }}
            >
              <Flex css={{ alignItems: "center", gap: "$3" }}>
                <Icon variant="playCircle" size={30} />
                <Flex css={{ flexDirection: "column" }}>
                  <Text variant="mini">
                    {convertFollowerCount(youtube.followerCount)} Followers
                  </Text>
                  {loggedIn && (
                    <Flex
                      css={{
                        alignItems: "center",
                        gap: "$2",
                      }}
                    >
                      @{youtube.handle}
                      <Icon variant="linkOut" size={14} />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </ExternalLink>
          )}
        </Grid>
      )}
      <ShareProfileLink slug={talent.slug} />

      <SignInModal isOpen={signInModalOpen} onClose={toggleSignInModal} />
    </Flex>
  );
};
