import { gql } from "@apollo/client";
import { PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT } from "src/graphql/fragments/PortfolioImageCategoryDetails";
import { PORTFOLIO_IMAGE_DETAILS_FRAGMENT } from "src/graphql/fragments/PortfolioImageDetails";

export const TALENT_PROFILE_DETAILS_FRAGMENT = gql`
  fragment TalentProfileDetails on TalentProfile {
    id
    contractUrl
    cardUrl
    dateOfBirth
    agency {
      name
      id
    }
    bookers {
      id
      name
    }
    vertical
    gender
    primaryLocation {
      name
    }
    additionalLocations {
      name
    }
    dateOfBirth
    biography
    instagramUsername
    hobbies
    verticalData {
      key
      value
    }
    minDayRate
    maxDayRate
    isContactOpen
    isContactSelect
    gender
    published
    dietaryRequirements
    pastClients
    pronouns
    website
    selfManaged
    portfolioImages {
      ...PortfolioImageDetails
    }
    portfolioImageCategories {
      ...PortfolioImageCategoryDetails
    }
    socialMediaFields {
      platform
      handle
      followerCount
    }
  }
  ${PORTFOLIO_IMAGE_DETAILS_FRAGMENT}
  ${PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT}
`;
