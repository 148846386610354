import { camelCase } from "lodash";
import { ConversionType } from "./../graphql/generated/types";
import { MeasurementDisplay } from "src/graphql/types";

export const cmToInches = (value: number) => value * 0.393700787;

export const cmToFeetAndInches = (
  value: number,
): { feet: number; inches: number } => {
  const totalInches = value * 0.393700787;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.floor(totalInches % 12);
  return { feet, inches };
};

export const cmToMetersAndCentimeters = (
  value: number,
): { meters: number; centimeters: number } => {
  const meters = Math.floor(value / 100);
  const centimeters = value % 100;
  return { meters, centimeters };
};

export const euToUkShoeSize = (euSize: number): number => {
  const euToUkSizeMap: { [euSize: number]: number } = {
    33: 1,
    34: 1.5,
    35: 2,
    36: 3,
    37: 4,
    38: 5,
    39: 6,
    40: 6.5,
    41: 7,
    42: 8,
    43: 9,
    44: 10,
    45: 10.5,
    46: 11,
    47: 12,
    48: 13,
    49: 14,
    50: 15,
  };
  return euToUkSizeMap[euSize];
};

export const formatMetric = (
  value: number,
  displayUnit?: MeasurementDisplay,
) => {
  let val;

  switch (displayUnit) {
    case MeasurementDisplay.FeetAndInches:
      val = cmToFeetAndInches(value);
      return `${val.feet.toFixed(0)}'${val.inches.toFixed(0)}"`;
    case MeasurementDisplay.Inches:
      val = cmToInches(value);
      return `${val.toFixed(0)}"`;
    case MeasurementDisplay.Centimeters:
      return `${value}cm`;
    case MeasurementDisplay.MetersAndCentimeters:
      val = cmToMetersAndCentimeters(value);
      return `${val.meters.toFixed(0)}m${val.centimeters.toFixed(0)}cm`;
    case MeasurementDisplay.EuShoe:
      return `EU ${value}`;
    case MeasurementDisplay.UkShoe:
      return `UK ${euToUkShoeSize(value)}`;
    default:
      return value;
  }
};

export const formatMetricV2 = (value: number, displayUnit: ConversionType) => {
  let val;

  switch (camelCase(displayUnit)) {
    case "feetAndInches":
      val = cmToFeetAndInches(value);
      return `${val.feet.toFixed(0)}'${val.inches.toFixed(0)}"`;
    case "inches":
      val = cmToInches(value);
      return `${val.toFixed(0)}"`;
    case "centimeters":
      return `${value}cm`;
    case "metersAndCentimeters":
      val = cmToMetersAndCentimeters(value);
      return `${val.meters.toFixed(0)}m${val.centimeters.toFixed(0)}cm`;
    case "euShoe":
      return `EU ${value}`;
    case "ukShoe":
      return `UK ${euToUkShoeSize(value)}`;
    default:
      return value;
  }
};
