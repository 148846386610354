import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Box } from "src/ccl/layout";
import { ResetModal } from "src/components/formLayout/ResetModal";
import { Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { ActionItem, StepSummary, GoBack } from "src/components/formLayout";

interface FormLayoutActionBarProps {
  title: string;
  goBackTo?: string | (() => void | Promise<void>);
  resetUrl?: string;
  resetCopy?: string;
  onReset?: () => void;
  onBack?: () => void;
  ctaText?: string;
  currentStepIndex?: number;
  steps?: string[];
  disabled?: boolean;
  onClick?: () => void;
  buttonType?: "button" | "submit";
  helpComponent?: React.ReactNode;
}

export const FormLayoutActionBar = ({
  title,
  goBackTo,
  resetUrl,
  resetCopy,
  onReset,
  onBack,
  currentStepIndex,
  ctaText,
  steps,
  disabled = false,
  onClick = () => {},
  buttonType = "submit",
  helpComponent,
}: FormLayoutActionBarProps) => {
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const history = useHistory();

  return (
    <Box data-test-id="FormLayoutActionBar">
      {resetUrl && resetCopy && (
        <ResetModal
          isOpen={showResetModal}
          copy={resetCopy}
          onCancel={() => setShowResetModal(!showResetModal)}
          onConfirm={() => {
            onReset && onReset();
            history.push(resetUrl);
          }}
        />
      )}

      <Flex
        css={{
          alignItems: "center",
          maxHeight: "70px",
          "@bp3": { maxHeight: "$17" },
        }}
      >
        <Box css={{ flex: 1 }}>
          <Text
            variant="h2"
            css={{ mb: "$3", display: "none", "@bp2": { display: "block" } }}
          >
            {title}
          </Text>
          <Text
            css={{ mb: "$2", fontWeight: "bold", "@bp2": { display: "none" } }}
          >
            {title}
          </Text>
          <Flex
            css={{
              alignItems: "center",
              justifyContent: "space-between",
              "@bp2": {
                width: "fit-content",
              },
            }}
          >
            {steps && currentStepIndex === 0 && (
              <>
                {goBackTo && (
                  <GoBack goBackTo={goBackTo}>
                    <ActionItem
                      icon="chevronLeft"
                      text="Back"
                      onClick={onBack}
                    />
                  </GoBack>
                )}

                {helpComponent}

                <StepSummary
                  steps={steps}
                  currentStep={currentStepIndex}
                  slimline
                />
              </>
            )}
            {steps && !!currentStepIndex && goBackTo && (
              <>
                <Flex>
                  <GoBack goBackTo={goBackTo}>
                    <ActionItem
                      icon="chevronLeft"
                      text="Back"
                      onClick={onBack}
                    />
                  </GoBack>
                  <ActionItem
                    icon="reset"
                    text="Reset"
                    onClick={() => setShowResetModal(!showResetModal)}
                  />

                  {helpComponent}
                </Flex>
                <StepSummary
                  steps={steps}
                  currentStep={currentStepIndex}
                  slimline
                />
              </>
            )}
          </Flex>
        </Box>
        {ctaText && (
          <Button
            data-test-id="CAJContinueButton"
            type={buttonType}
            variant="primary"
            disabled={disabled}
            onClick={onClick}
          >
            {ctaText}
          </Button>
        )}
      </Flex>
    </Box>
  );
};
