import React from "react";
import type * as Stitches from "@stitches/react";
import { styled } from "src/ccl";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const PrimitiveRadio = (props: InputProps) => (
  <input
    onChange={props.onChange}
    type="radio"
    id={props.id || `form-${props.name}`}
    value={props.value}
    {...props}
  />
);

const StyledRadio = styled(PrimitiveRadio, {
  position: "relative",
  margin: 0,
  width: 47,
  minWidth: 47,
  height: 47,
  minHeight: 47,
  appearance: "none",
  backgroundColor: "$white",
  border: "2px solid $black",
  borderRadius: "99999px",

  "&:checked": {
    "&::before": {
      content: "' '",
      display: "block",
      position: "absolute",
      inset: 0,
      backgroundColor: "$black",
      border: "2px solid $black",
      borderRadius: "99999px",
    },
  },

  variants: {
    variant: {
      rebrand: {
        position: "relative",

        borderRadius: "$round",

        border: "1.5px solid $grey3",
        "&::before": {
          content: "",
          position: "absolute",
          borderRadius: "$round",
        },

        "&:hover:not(:checked):not(:disabled)": {
          borderWidth: "2px",
          "&::before": {
            inset: "30%",
            backgroundColor: "$grey3",
          },
        },

        "&:checked": {
          borderColor: "$black",
          backgroundImage: "none",
          backgroundColor: "initial",
          "&::before": {
            inset: "20%",
            backgroundColor: "$black",
          },
        },

        "&:disabled": {
          borderColor: "$grey4",
          backgroundImage: "none",
          backgroundColor: "$grey3",
        },
      },
    },
    size: {
      xs: {
        width: 20,
        minWidth: 20,
        height: 20,
        minHeight: 20,
        "&::before": { margin: 5 },
      },
      s: {
        width: 28,
        height: 28,
        minWidth: 28,
        minHeight: 28,
      },
      sm: {
        width: 26,
        minWidth: 26,
        height: 26,
        minHeight: 26,
        "&::before": { margin: 6 },
      },
      m: {
        width: 42,
        height: 42,
        minWidth: 42,
        minHeight: 42,
      },
      md: {
        width: 47,
        minWidth: 47,
        height: 47,
        minHeight: 47,
        "&::before": { margin: 12 },
      },
      l: { width: 55, height: 55, minWidth: 55, minHeight: 55 },
      lg: {
        width: 60,
        minWidth: 60,
        height: 60,
        minHeight: 60,
        "&::before": { margin: 15 },
      },
    },
  },
});

export type RadioProps = Stitches.ComponentProps<typeof StyledRadio>;

export const Radio = ({
  variant,
  name,
  id,
  checked,
  value,
  onChange,
  css,
  size = "md",
}: RadioProps) => (
  <StyledRadio
    variant={variant}
    name={name}
    id={id || `form-${name}`}
    value={value}
    defaultChecked={checked}
    onChange={onChange}
    size={size}
    css={css}
  />
);
