import { gql } from "@apollo/client";

export const JOB_DETAILS_CHATROOM_QUERY = gql`
  query JobDetailsChatroom($jobId: ID!) {
    job(id: $jobId) {
      id
      name
      startDate
      endDate
      kind
      virtual
      fullAddress
      state
      location
      description
      brand
      headcountEstimate
      travelExpenseCover
      currency
      nudityType
      selfTapeRequired
      cateringProvided
      usages {
        form
        length
        location
        mediaType
      }
      additionalNotes
      booker {
        id
        name
        slug
      }
      assignedAgent {
        id
        name
        slug
        featuredAsset {
          mediaUrl
        }
      }
      jobTalent {
        id
        name
        assets {
          mediaUrl
        }
        profile {
          vertical
        }
        talent {
          slug
        }
      }
    }
  }
`;
