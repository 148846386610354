import { useReducer } from "react";
import {
  QueryVerticalTalentSearchArgs,
  VerticalFieldInput,
} from "src/graphql/types";

export enum SearchParamsActionKind {
  SetGeneric = "SET_GENERIC",
  ResetGeneric = "RESET_GENERIC",
  SetVerticalSpecific = "SET_VERTICAL_SPECIFIC",
  ResetVerticalSpecific = "RESET_VERTICAL_SPECIFIC",
  Replace = "REPLACE",
  ResetAll = "RESET_ALL",
  SetVertical = "SET_VERTICAL",
}

export interface SearchParamsAction {
  kind: SearchParamsActionKind;
  payload: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const reduceFields = (
  newArr: VerticalFieldInput[],
  oldArr: VerticalFieldInput[],
) => {
  oldArr.forEach((oldV) => {
    if (!newArr.some((newV) => newV.name === oldV.name)) {
      newArr.push(oldV);
    }
  });
  return newArr;
};

const removeFields = (fields: VerticalFieldInput[], names: string[]) => {
  return fields.filter(
    // endsWith catches range filters with min and max values
    (f) => !names.some((n) => n.endsWith(f.name)),
  );
};

/**
 * For setting filter state actions, the payload should be a subset of the resultant state.
 * e.g.
 * - for a generic filter where the key lives in the root searchParams object,
 * provide an object with the expected key and value like { gender: "MALE" }
 *
 * - for a vertical specific filter, include an array of values to be merged with
 * the existing state like [{ name: "hair_colour", value: ['fitzpatrick-i'] }]
 *
 * for reset filter state actions, provide an array of keys to be removed from the state
 *
 * for replace, a full valid QueryVerticalTalentSearchArgs object
 */
const reduceWithDefaults = (
  state: QueryVerticalTalentSearchArgs,
  action: SearchParamsAction,
  defaults: QueryVerticalTalentSearchArgs,
) => {
  const { kind, payload } = action;
  switch (kind) {
    case SearchParamsActionKind.SetGeneric:
      return {
        ...state,
        ...payload,
      };
    case SearchParamsActionKind.SetVerticalSpecific:
      return {
        ...state,
        fields: reduceFields(payload, state.fields || []),
      };
    case SearchParamsActionKind.ResetGeneric: {
      // we can't just `delete state[key]` for each of these, as
      // React thinks oldState === newState, so we have to be a bit silly
      const newState = (payload as string[]).reduce((prev, key) => {
        const newVal =
          defaults[key as keyof QueryVerticalTalentSearchArgs] || undefined;

        return {
          ...prev,
          [key]: newVal,
        };
      }, state);

      return newState;
    }
    case SearchParamsActionKind.ResetVerticalSpecific:
      return {
        ...state,
        fields: removeFields(state.fields || [], payload),
      };
    case SearchParamsActionKind.Replace:
      return payload;
    case SearchParamsActionKind.ResetAll:
      return defaults;
    case SearchParamsActionKind.SetVertical:
      return {
        ...defaults,
        ...payload,
      };
  }
};

export const useVerticalTalentSearchParams = (
  params: QueryVerticalTalentSearchArgs,
  defaults?: QueryVerticalTalentSearchArgs,
) => {
  // places where we aren't caching queries won't pass defaults, as the
  // params value passed to this hook will never change. In this case we
  // can use the initial params as defaults
  const reducer = (
    state: QueryVerticalTalentSearchArgs,
    action: SearchParamsAction,
  ) => reduceWithDefaults(state, action, defaults || params);

  return useReducer(reducer, params);
};
