import { gql } from "@apollo/client";

export const CREATE_MESSAGE_MUTATION = gql`
  mutation CreateMessage($content: String!, $chatroomId: ID!) {
    addMessageToChatroom(content: $content, chatroomId: $chatroomId) {
      content
      id
    }
  }
`;
