import { Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button, Dropdown } from "src/ccl/navigation";

const DropdownItem = ({
  text,
  color,
  disabled,
  onClick,
}: {
  text: string;
  color: string;
  disabled: boolean;
  onClick: () => void;
}) => (
  <Flex
    onClick={disabled ? undefined : onClick}
    css={{
      gap: "$3",
      p: "$4",
      borderRadius: "8px",
      alignItems: "center",
      cursor: disabled ? undefined : "pointer",
      "&:hover": { backgroundColor: disabled ? undefined : "$grey1" },
      opacity: disabled ? 0.5 : undefined,
    }}
  >
    <Text variant="b2" css={{ color }}>
      {text}
    </Text>
  </Flex>
);

export const PortfolioSelectionDropdown = ({
  onAdd,
  onDelete,
  disabled,
}: {
  onAdd: () => void;
  onDelete: () => void;
  disabled: boolean;
}) => (
  <Dropdown
    data-test-id="PortfolioSelectionDropdown"
    bodyCss={{
      borderRadius: "8px",
      p: "$5",
      width: 228,
      border: "unset",
    }}
    position="right"
    launcher={
      <Button
        variant="circle"
        css={{
          backgroundColor: "$grey2",
          "&:hover": { backgroundColor: "$grey1" },
        }}
      >
        <Icon variant="moreDots" size={18} color="grey6" />
      </Button>
    }
  >
    <DropdownItem
      text="Add to category"
      color="black"
      disabled={disabled}
      onClick={onAdd}
    />
    <DropdownItem
      text="Delete images"
      color="#D32014"
      disabled={disabled}
      onClick={onDelete}
    />
  </Dropdown>
);
