import { Text, Icon } from "src/ccl/document";
import { Flex } from "src/ccl/layout";

export const EmptyState = ({
  subject,
  subtitle = "Try adjusting your filters",
}: {
  subject: string;
  subtitle?: string;
}) => (
  <Flex
    css={{
      my: "$7",
      flexDirection: "column",
      alignItems: "center",
      gap: "18px",
    }}
  >
    <Icon variant="noSearchResults" size={60} color="grey3" />
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
      }}
    >
      <Text variant="nh4" color="grey5">
        No {subject} found
      </Text>

      <Text variant="b2" color="grey6" css={{ fontWeight: "$regular" }}>
        {subtitle}
      </Text>
    </Flex>
  </Flex>
);
