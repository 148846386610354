import { gql } from "@apollo/client";

export const MARK_INVOICE_PAID_MUTATION = gql`
  mutation MarkInvoicePaid($invoiceId: ID!) {
    markInvoicePaid(invoiceId: $invoiceId) {
      id
      state
    }
  }
`;
