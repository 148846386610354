import type * as Stitches from "@stitches/react";
import React, { useEffect, useState } from "react";
import { mergeCss, styled } from "src/ccl/stitches";
import { OutsideClickHandler } from "src/ccl/util/OutsideClickHandler";

const Wrapper = styled("div", {
  position: "relative",
  perspective: "800px",
  zIndex: "$400",
});

const LauncherLink = styled("span", {
  textDecoration: "none",
  cursor: "pointer",
});

const Body = styled("div", {
  position: "absolute",
  bottom: 0,
  border: "1px $grey3 solid",
  boxShadow: "$focus",
  transform: "translate(0, calc(100% + 16px)) rotateX(-15deg)",
  transformOrigin: "50% -16px",
  opacity: 0,
  transition: "250ms ease all",
  visibility: "hidden",
  padding: "$4",
  backgroundColor: "$white",

  variants: {
    position: {
      left: {
        left: 0,
      },
      right: {
        right: 0,
      },
      center: {
        left: "50%",
        transform: "translate(-50%, calc(100% + 16px)) rotateX(-15deg)",
      },
    },
    offset: {
      none: {
        transform: "translate(0, 100%) rotateX(-15deg)",
        transformOrigin: "50% 0",
      },
    },
    state: {
      open: {
        opacity: 1,
        visibility: "visible",
        transform: "translate(0, calc(100% + 16px)) rotateX(0deg)",
      },
      openNoOffset: {
        opacity: 1,
        visibility: "visible",
        transform: "translate(0, 100%) rotateX(0deg)",
      },
    },
  },
  compoundVariants: [
    {
      position: "center",
      state: "open",
      css: {
        transform: "translate(-50%, calc(100% + 16px)) rotateX(0deg)",
      },
    },
  ],
});

export interface DropdownProps {
  launcher: React.ReactNode;
  position?: "left" | "right" | "center";
  bodyCss?: Stitches.CSS;
  wrapperCss?: Stitches.CSS;
  noOffset?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  withHoverEffect?: boolean;
  dropdownOpen?: boolean;
  children?: React.ReactNode;
}

export const Dropdown = ({
  launcher,
  position = "center",
  bodyCss = {},
  wrapperCss = {},
  noOffset = false,
  children,
  onOpen,
  onClose,
  withHoverEffect = false,
  dropdownOpen,
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (dropdownOpen !== undefined && withHoverEffect) {
      setOpen(dropdownOpen);
      setHovered(dropdownOpen);
    }
  }, [dropdownOpen]);

  const handleMouseOver = () => {
    !open && setHovered(true);
  };
  const handleMouseLeave = () => {
    !open && setHovered(false);
  };

  return (
    <Wrapper
      css={wrapperCss}
      onMouseOver={() => {
        withHoverEffect && handleMouseOver();
      }}
      onMouseLeave={() => {
        withHoverEffect && handleMouseLeave();
      }}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false);
          setHovered(false);
          onClose?.();
        }}
      >
        <LauncherLink
          onClick={() => {
            const newState = !open;
            setOpen(newState);
            if (!newState) {
              onClose?.();
            } else {
              onOpen?.();
            }
          }}
        >
          {launcher}
        </LauncherLink>
        <Body
          position={position}
          state={
            open || hovered ? (noOffset ? "openNoOffset" : "open") : undefined
          }
          css={mergeCss(bodyCss, {})}
          offset={noOffset ? "none" : undefined}
        >
          {children}
        </Body>
      </OutsideClickHandler>
    </Wrapper>
  );
};
