import React from "react";
import type * as Stitches from "@stitches/react";
import { Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";

export const SideNavigationGroup = ({
  css = {},
  children,
}: {
  css?: Stitches.CSS;
  children?: React.ReactNode;
}) => (
  <Flex
    css={mergeCss(
      {
        mt: "$6",
        flexDirection: "column",
        display: "none",
        "@bp2": {
          display: "block",
        },
      },
      css,
    )}
  >
    {children}
  </Flex>
);
