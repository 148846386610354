import React from "react";
import {
  useHistory,
  useLocation,
  useRouteMatch,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import {
  Profile,
  Portfolio,
  LegacyProfile,
} from "src/components/dashboards/agents/talentManagement/add";
import NotFound from "src/pages/NotFound";
import { useTiara, useStoreModel, useFeatures } from "src/hooks";
import { verticalEnum, verticalMap } from "src/utils/user";
import { FullHeaderPage } from "src/ccl/templates";

export const AddTalent = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const v = location.search.substring(3);

  const { featureEnabled } = useFeatures();

  const history = useHistory();

  if (!v) {
    history.replace("?v=models");
  }
  const vertical = verticalEnum(v || "");

  useTiara({ title: `Add a ${verticalMap[vertical]}` });
  const draftTalent = useStoreModel("draftTalent");

  if (
    draftTalent.details &&
    draftTalent.details.talentProfile.vertical !== vertical
  ) {
    // We have some partially saved data from a different vertical, so we need
    // to get rid of it
    draftTalent.reset();
  }

  const redirectPath = {
    profile: "profile",
    portfolio: "portfolio",
  }[draftTalent.status];

  const renderProfileComponent = () => {
    const isNewDashboard = localStorage.getItem("isNewDashboard");
    if (!isNewDashboard && featureEnabled("tpa_updated_creative_upload")) {
      return (
        <FullHeaderPage>
          <Profile vertical={vertical} />
        </FullHeaderPage>
      );
    }

    if (isNewDashboard && featureEnabled("tpa_updated_creative_upload")) {
      return <Profile vertical={vertical} />;
    }

    return <LegacyProfile vertical={vertical} />;
  };

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/${redirectPath}`} />
      <Route path={`${path}/profile`}>{renderProfileComponent()}</Route>
      <Route path={`${path}/portfolio`}>
        <Portfolio vertical={vertical} />
      </Route>
      <Route component={NotFound} path="*" />
    </Switch>
  );
};
