import { action, Action } from "easy-peasy";
import { JobFilterInput } from "src/graphql/types";

export interface AgencyDashboardFiltersModel {
  filters: JobFilterInput;
  setFilters: Action<AgencyDashboardFiltersModel, JobFilterInput>;
}

export const agencyDashboardFiltersModel: AgencyDashboardFiltersModel = {
  filters: {},
  setFilters: action((state, nextFilters) => {
    state.filters = nextFilters;
  }),
};
