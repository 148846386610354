import React from "react";
import cajEmptyCard from "src/assets/icons/empty-state-caj-card.svg";
import { Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches";
import { Button, Link } from "src/ccl/navigation";
import { sendToAmplitude } from "src/utils/analytics";

const StyledManagementImage = styled("img", {
  width: 210,
  height: 125,
  "@bp2": {
    width: 350,
    height: 208,
  },
});

export const LatestJobsCAJCard = () => {
  return (
    <Link
      css={{ textDecoration: "none" }}
      to="/jobs/new/discipline"
      onClick={() =>
        sendToAmplitude(
          "booker dashboard - clicks create a job latest jobs cta",
        )
      }
    >
      <Flex
        data-test-id="LJCAJCard"
        css={{
          border: "1px solid $grey2",
          borderRadius: "8px",
          height: "296px",
          width: "206px",
          minWidth: "206px",
          padding: "$7",
          display: "none",
          "@bp2": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          },
          "&:hover": {
            border: "1px solid $grey6",
            boxShadow: "$subtle",
          },
          "&:active": {
            background: "rgba(0,0,0,0.02)",
            boxShadow: "unset",
          },
        }}
      >
        <Text variant="h4">Create a new job</Text>
        <StyledManagementImage src={cajEmptyCard} aria-hidden="true" />
        <Button variant="primary">Create Job</Button>
      </Flex>
    </Link>
  );
};
