import React from "react";
import { styled } from "@stitches/react";
import { Checkbox } from "src/ccl/data-entry";
import { Grid, Box, Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";

const StyledLabel = styled("label", {
  fontWeight: "$bold",
  mb: "$2",
  display: "block",
});

interface LegacyRadioSelectProps {
  options: {
    value: string;
    description?: string;
    colour?: string;
    label?: string;
    imageUrl?: string;
  }[];
  selectedOption: string;
  setSelectedOption: (val: string) => void;
  optional?: boolean;
  label?: string;
  name?: string;
}

export const LegacyRadioSelect = ({
  options,
  selectedOption,
  setSelectedOption,
  optional = false,
  label,
  name,
}: LegacyRadioSelectProps) => (
  <>
    {label && (
      <Flex css={{ alignItems: "center" }}>
        <StyledLabel>{label}</StyledLabel>
        {optional && (
          <Text variant="meta" color="grey6" css={{ ml: "$3" }}>
            (optional)
          </Text>
        )}
      </Flex>
    )}
    <Grid css={{ columnGap: "$6", gridColumns: 2 }} id={`form-${name}`}>
      {options.map((option) => (
        <Grid
          key={option.value}
          css={{
            gridTemplateColumns: "47px auto",
            columnGap: "$2",
            mb: "$4",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={selectedOption === option.value}
            onChange={() => setSelectedOption(option.value)}
            id={option.value}
          />

          <Box as="label" htmlFor={option.value} css={{ cursor: "pointer" }}>
            <Text css={{ ml: "$4" }}>{option.label}</Text>
          </Box>
        </Grid>
      ))}
    </Grid>
  </>
);
