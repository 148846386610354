import { useEffect, useRef, useState } from "react";

export const useCountdown = () => {
  const [secondsLeft, setSecondsLeft] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (secondsLeft <= 0) {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      setSecondsLeft((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [secondsLeft]);

  const setCountdownSeconds = (seconds: number) => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    setSecondsLeft(seconds);
  };

  return { secondsLeft, setCountdownSeconds };
};
