import React, { useState } from "react";
import { Loading } from "src/components";
import { RadioGroup, SelectReact } from "src/ccl/data-entry";
import { Box } from "src/ccl/layout";
import { VerticalConfiguration, TalentVertical } from "src/graphql/types";
import { useVerticalConfigurations } from "src/hooks";
import { verticalEnum } from "src/utils/user";

interface VerticalSelectionGroupProps {
  vertical: TalentVertical;
  setVertical: (v: TalentVertical) => void;
  collapsed: boolean;
}

export const VerticalSelectionGroup = ({
  setVertical,
  vertical,
  collapsed,
}: VerticalSelectionGroupProps) => {
  const { loading, configurations: verticalConfigurations } =
    useVerticalConfigurations();

  const options = (verticalConfigurations || []).map(
    (config: VerticalConfiguration) => ({
      id: config.id as TalentVertical,
      value: config.id as TalentVertical,
      mediaUrl: config.selectionImage,
      label: `${config.label}s`.toUpperCase(),
      labelText: `${config.label}s`.toUpperCase(),
    }),
  );

  const [backgroundVertical, setBackgroundVertical] = useState(vertical);

  const hoveredCallback = (optionId: string) => {
    const hoveredVertical = verticalEnum(optionId);
    setBackgroundVertical(hoveredVertical);
  };
  const background = options.find(
    (option) => option.value === backgroundVertical,
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Box data-test-id="VerticalSelector">
      <Box
        css={{
          transition: "all 0.15s ease-in-out",
          height: collapsed ? 46 : 124,
          width: 324,
          "@short": {
            height: 46,
          },
        }}
      >
        <Box
          css={{
            display: "none",
            "@short": {
              display: "block",
            },
          }}
        >
          <SelectReact
            fontFamily="sansWide"
            fontSize={12}
            color="white"
            backgroundColor="black"
            value={options.find(({ value }) => value === vertical)}
            onChange={(option) =>
              option && setVertical(option.value as TalentVertical)
            }
            options={options.filter(({ value }) => value !== vertical)}
          />
        </Box>
        <Box css={{ "@short": { display: "none" } }}>
          {collapsed && (
            <SelectReact
              fontFamily="sansWide"
              fontSize={12}
              color="white"
              backgroundColor="black"
              value={options.find(({ value }) => value === vertical)}
              onChange={(option) =>
                option && setVertical(option.value as TalentVertical)
              }
              options={options.filter(({ value }) => value !== vertical)}
            />
          )}
          {!collapsed && options && (
            <Box
              css={{
                background: `url(${background?.mediaUrl})`,
                transition: "ease-in-out 0.3s background",
                border: "2px solid $black",
              }}
            >
              <Box
                css={{
                  backdropFilter: "blur(4px)",
                  transition: "ease 0.3s backdrop-filter",
                  "&:hover": {
                    backdropFilter: "blur(0px)",
                  },
                  "@bp4": {
                    p: "$4",
                  },
                }}
              >
                <RadioGroup
                  size="xs"
                  color="white"
                  options={options}
                  name="verticalSelection"
                  setSelectedOption={(v) => {
                    setVertical(v as TalentVertical);
                  }}
                  selectedOption={vertical}
                  setHoveredOption={hoveredCallback}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
