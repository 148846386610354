import React from "react";
import type * as Stitches from "@stitches/react";
import { styled } from "src/ccl";
import blurredModels1X from "src/assets/agentDashboard/blurredModels.png";
import blurredModels2X from "src/assets/agentDashboard/blurredModels@2x.png";

const Wrapper = styled("img", {
  position: "absolute",
  top: "-35px",
  right: "initial",
  zIndex: -1,
  height: "190px",
  maxWidth: "530px",
  overflow: "hidden",
  "@bp1": {
    height: "190px",
    maxWidth: "530px",
  },
  "@bp2": {
    height: "initial",
    maxWidth: "initial",
  },
});

export const BlurredModels = ({ css = {} }: { css?: Stitches.CSS }) => (
  <Wrapper
    aria-hidden="true"
    srcSet={`${blurredModels1X}, ${blurredModels2X} 2x`}
    src={blurredModels1X}
    css={css}
  />
);
