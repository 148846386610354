import React from "react";
import { Box } from "src/ccl/layout";
import { AssetImage, AssetImageType, Icon, Img, Text } from "src/ccl/document";
import { Pill } from "src/ccl/blocks";
import { styled } from "src/ccl";
import { DragHandleContainer } from "src/components/DragHandle";

const DeletePill = styled(Pill, {
  "@bp2": {
    display: "none",
  },
  position: "absolute",
  bottom: "$3",
  width: 150,
  left: "50%",
  transform: "translateX(-55%)", // not sure why this isn't 50%, but it's off center at 50%
  zIndex: "9",
  cursor: "pointer",
  backgroundColor: "$grey1",
  py: "$3",
  px: "$5",
  borderRadius: "$pill",
  boxShadow: "$focus",
  "&:hover": {
    backgroundColor: "$grey2",
  },
});

const DeleteButton = ({
  onClick,
}: {
  onClick: (e: React.SyntheticEvent) => void;
}) => (
  <DeletePill onClick={onClick} data-test-id="DeleteImageButton">
    <Icon variant="trash" size={16} css={{ mr: "$2" }} color="red" />
    <Text variant="mini" color="red">
      Delete Photo
    </Text>
  </DeletePill>
);

export const Asset = <T extends AssetImageType>({
  item,
  imageAlt,
  onClick,
  onRemove,
  isDragging = false,
  selected,
  isSelecting,
  onSelect,
}: {
  item: T;
  imageAlt: string;
  onClick?: (asset: T) => void;
  onRemove?: (asset: T) => void;
  isDragging?: boolean;
  selected?: boolean;
  isSelecting?: boolean;
  onSelect?: (item: T) => void;
}) => (
  <Box
    data-test-id="Asset"
    css={{
      opacity: isDragging ? "0.3" : "1",
      cursor: onClick ? "pointer" : "initial",
      position: "relative",
      width: "100%",
      "&:hover": {
        [`& ${DeletePill}`]: {
          display: "flex",
        },
      },
    }}
    onClick={() => onClick?.(item)}
  >
    {isSelecting && onSelect && (
      <DragHandleContainer
        css={{
          top: "unset",
          bottom: 10,
          right: 10,
          cursor: "pointer",
          backgroundColor: selected ? "$green" : undefined,
        }}
        onClick={(e) => {
          e.stopPropagation();
          onSelect(item);
        }}
        data-test-id="SelectCheckbox"
      >
        <Icon variant="check" size={20} color={selected ? "white" : "grey3"} />
      </DragHandleContainer>
    )}
    {!isSelecting && (
      <DeleteButton
        onClick={(e) => {
          e.stopPropagation();
          onRemove?.(item);
        }}
      />
    )}
    {item.mediaUrl.startsWith("blob:") ? (
      <Img
        alt={imageAlt}
        src={item.mediaUrl}
        css={{
          borderRadius: "$lg",
          objectFit: "cover",
          width: 850,
          aspectRatio: 1,
          "@bp2": {
            width: 236,
            height: 300,
          },
        }}
      />
    ) : (
      <AssetImage
        containerCss={{ borderRadius: "$lg" }}
        imgCss={{ borderRadius: "$lg" }}
        asset={item}
        size={{
          "@initial": {
            width: 500,
            aspectRatio: 1.12,
          },
          "@bp4": {
            aspectRatio: 1.28,
          },
        }}
        alt={imageAlt}
        crop="clip"
      />
    )}
  </Box>
);
