import React from "react";
import { CSS, VariantProps } from "@stitches/react";
import { Icon, Animation } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { iconSizes, tokens } from "src/ccl/stitches/theme";
import { mergeCss } from "src/ccl/stitches";
import spinnerAnimation from "src/assets/animations/spinner.json";

interface LoadingProps extends VariantProps<typeof Box> {
  iconSize?: keyof typeof iconSizes;
  css?: CSS;
  variant?: "dots" | "spinner";
  color?: keyof typeof tokens.colors;
}

export const Loading = ({
  iconSize = 40,
  css = {},
  variant = "dots",
  color,
}: LoadingProps) => (
  <Flex
    css={mergeCss(
      {
        alignItems: "center",
        justifyContent: "center",
        margin: 5,
      },
      css,
    )}
  >
    {variant === "spinner" ? (
      <Box css={{ height: iconSize, width: iconSize }}>
        <Animation source={spinnerAnimation} loop />
      </Box>
    ) : (
      <Icon variant="loading" size={iconSize} color={color} />
    )}
  </Flex>
);
