import React from "react";
import { SelectReact } from "src/ccl/data-entry";
import { BaseFilter, BaseFilterProps } from "src/ccl/filtering/baseFilter";

export interface SelectFilterOption {
  label: string;
  value: string;
}

interface SelectReactFilterProps extends BaseFilterProps {
  options?: SelectFilterOption[];
  id?: string;
  onChange: (value: SelectFilterOption | undefined) => void;
  onReset?: () => void;
  initialValues?: SelectFilterOption;
  value?: SelectFilterOption;
  isClearable?: boolean;
  maxMenuHeight?: number;
}

export const SelectReactFilter = ({
  options,
  id,
  onChange,
  initialValues,
  value,
  onReset,
  isClearable,
  maxMenuHeight,
  ...filterProps
}: SelectReactFilterProps) => {
  return (
    <BaseFilter {...filterProps} onReset={onReset}>
      <SelectReact
        variant="rebrand"
        placeholder={filterProps.helperText}
        isSearchable={isClearable}
        isClearable={isClearable}
        id={id}
        initialValues={initialValues}
        value={value}
        options={options}
        onChange={onChange}
        maxMenuHeight={maxMenuHeight}
      />
    </BaseFilter>
  );
};
