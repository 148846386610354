import React from "react";
import { UrlStateRangeFilter } from "./UrlStateRangeFilter";
import { UrlStateSelectFilter } from "./UrlStateSelectFilter";
import { UrlStateTextFilter } from "./UrlStateTextFilter";
import { UrlStateMultiselectFilter } from "./UrlStateMultiselectFilter";
import { UrlStateFilterGroup } from "./UrlStateFilterGroup";
import { useURLScalarSearchParam } from "src/hooks";
import { PreferenceToggleFilter } from "src/ccl/filtering";
import { Filter, FilterContext, FilterKind, Gender } from "src/graphql/types";

interface SchemaFilterProps {
  filter: Filter;
  expanded: boolean;
  context: FilterContext;
}

export const UrlStateSchemaFilter = ({
  filter,
  context,
}: SchemaFilterProps) => {
  const [gender] = useURLScalarSearchParam<Gender>(
    "gender",
    (v) => v as Gender,
    (v) => v as string,
  );

  if (
    !filter.contexts.includes(context) ||
    (gender && filter.excludedGenders?.includes(gender))
  ) {
    return <></>;
  }
  switch (filter.kind) {
    case FilterKind.Range:
      return <UrlStateRangeFilter filter={filter} expanded={false} />;
    case FilterKind.Select:
      return <UrlStateSelectFilter filter={filter} expanded={false} />;
    case FilterKind.Text:
      return <UrlStateTextFilter filter={filter} expanded={false} />;
    case FilterKind.MultiSelect:
      return <UrlStateMultiselectFilter filter={filter} expanded={false} />;
    case FilterKind.FilterGroup:
      return (
        <UrlStateFilterGroup
          filter={filter}
          expanded={false}
          context={context}
        />
      );
    case FilterKind.Toggle:
      return (
        <PreferenceToggleFilter
          name={filter.name}
          textUnchecked={filter.labelUnchecked || ""}
          textChecked={filter.labelChecked || ""}
          checkedValue={filter.checkedValue || ""}
          uncheckedValue={filter.uncheckedValue || ""}
        />
      );
    default:
      return <></>;
  }
};
