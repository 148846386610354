import React from "react";
import { Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { ParagraphRenderer } from "src/components";
interface DetailProps {
  title: string;
  body?: string | null;
  fontWeight?: string;
  splitNewLines?: boolean;
  children?: React.ReactNode;
}

export const Detail = ({
  title,
  body,
  fontWeight,
  splitNewLines = false,
  children,
}: DetailProps) => {
  if (body === null) {
    return null;
  }

  const formattedBody = () => {
    if (body && body.indexOf("\n") !== -1 && splitNewLines) {
      return <ParagraphRenderer text={body} />;
    } else {
      return <Text css={{ fontWeight: fontWeight }}>{body}</Text>;
    }
  };

  return (
    <Box css={{ mb: "$9" }}>
      <Text color="black" variant="meta" css={{ mb: "$2", fontWeight: "700" }}>
        {title}
      </Text>
      {formattedBody()}
      {children && children}
    </Box>
  );
};
