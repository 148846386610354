import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CollectionList } from "./CollectionList";
import { CollectionsSideBarEmpty } from "./CollectionsSidebarEmpty";
import { Box, Flex, Grid } from "src/ccl/layout";
import {
  BookerProfile,
  Query,
  SavedTalent,
  SavedTalentCollection,
  User,
} from "src/graphql/types";
import { Icon, Text } from "src/ccl/document";
import { Input } from "src/ccl/data-entry";
import { Pill } from "src/ccl/blocks";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";
import { extractNodes } from "src/utils/lang";
import { BOOKER_BOOKMARKED_TALENT } from "src/graphql/queries/booker/BookmarkedTalent";

const buildBookmarksCollectionItem = (
  memberCount: number,
  talent: User,
): SavedTalentCollection => {
  return {
    id: "",
    memberCount: memberCount,
    members: {
      totalCount: memberCount,
      edges: [{ node: talent, cursor: "" }],
      nodes: [],
      pageInfo: {
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    },
    name: "All bookmarks",
    slug: "bookmarks",
    talentVerticals: [],
  };
};

export const LegacyCollectionSidebar = ({
  removeFromCollectionText,
  onCreateCollection,
  collectionName,
  setCollectionName,
  collections,
  loading,
}: {
  removeFromCollectionText: string;
  onCreateCollection: () => void;
  collectionName: string;
  setCollectionName: (name: string) => void;
  collections: SavedTalentCollection[];
  loading: boolean;
}) => {
  const [collectionNameValue, setCollectionNameValue] = useDebouncedInputValue(
    collectionName,
    setCollectionName,
  );

  const history = useHistory();

  const { data: bookmarkedTalentData, loading: allBookmarksLoading } =
    useQuery<Query>(BOOKER_BOOKMARKED_TALENT);

  const bookerProfile = bookmarkedTalentData?.me?.profile as BookerProfile;
  const totalBookmarkedTalentCount = bookerProfile?.savedTalent?.totalCount;
  const featuredBookmarkedTalent = extractNodes<SavedTalent>(
    bookerProfile?.savedTalent,
  )[0];

  const allBookmarksCollectionItem = buildBookmarksCollectionItem(
    totalBookmarkedTalentCount || 0,
    featuredBookmarkedTalent?.talent,
  );

  const {
    setNodeRef,
    isOver,
    active: showRemoveFromCollections,
  } = useDroppable({
    id: "removeFromCollections",
  });

  if (!loading && !collectionNameValue && !collections.length) {
    return <CollectionsSideBarEmpty onCreateCollection={onCreateCollection} />;
  }

  return (
    <Box
      data-test-id="BookmarkCollectionsSidebar"
      css={{
        display: "none",
        "@bp3": {
          display: "flex",
          flexDirection: "column",
          gap: "21px",
        },
        top: 40,
        position: "sticky",
        border: "1px solid $grey2",
        borderRadius: "$lg",
        px: "$7",
        pt: "$8",
        pb: "$11",
        mb: "$17",
        boxShadow: "$focus",
        height: "fit-content",
      }}
    >
      <Flex css={{ gap: "$5" }}>
        <Icon variant="bookmark" css={{ height: 23 }} />
        <Text variant="h3">Collections</Text>
      </Flex>

      {showRemoveFromCollections ? (
        <Flex
          ref={setNodeRef}
          css={{
            mt: "$7",
            mb: "30px",
            py: "10px",
            px: "$5",
            width: "284px",
            backgroundColor: isOver ? "$black" : "$grey1",
            alignItems: "center",
            gap: "10px",
            borderRadius: "$md",
          }}
        >
          <Icon
            variant="crossedCircle"
            css={{ width: 25, height: 25 }}
            color={isOver ? "white" : "grey6"}
          />
          <Text
            color={isOver ? "white" : "grey4"}
            css={{ fontWeight: "500", fontSize: "$14" }}
          >
            {removeFromCollectionText}
          </Text>
        </Flex>
      ) : (
        <Grid css={{ gridColumns: "16px 1fr", gridColumnGap: "$5" }}>
          <Icon variant="dragArrow" css={{ mt: "$2" }} color="grey6" />
          <Text color="grey6">Drag and drop creatives to collections</Text>
        </Grid>
      )}
      <Pill
        variant="roundedFilter"
        css={{
          backgroundColor: "$grey1",
          m: "unset",
          width: "306px",
          py: "$4",
        }}
      >
        <Flex
          css={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Input
            name={"Collection name"}
            type="text"
            placeholder="Search for collection"
            onChange={(e) => setCollectionNameValue(e.target.value)}
            value={collectionNameValue}
            css={{
              backgroundColor: "$grey1",
              height: "30px",
              border: "none",
              "::placeholder": {
                color: "grey6",
              },
              "@bp4": { height: "$6" },
            }}
          />
          <Icon
            variant="searchOutline"
            size={24}
            css={{
              mr: "$3",
              flexShrink: 0,
            }}
            color={"grey6"}
          />
        </Flex>
      </Pill>
      <CollectionList
        loading={loading || allBookmarksLoading}
        collections={[allBookmarksCollectionItem, ...collections]}
        onClickItem={(c) => {
          if (!c.id) {
            history.push(`/dashboard/client/bookmarks`);
          } else {
            history.push(`/dashboard/client/collections/${c.slug}`);
          }
        }}
        onCreateNewCollection={onCreateCollection}
        isItemSelected={(c) => {
          const regexp = new RegExp(`${c.slug}$`);
          return regexp.test(history.location.pathname);
        }}
      />
    </Box>
  );
};
