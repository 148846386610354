import React from "react";
import type * as Stitches from "@stitches/react";
import { Avatar } from "./Avatar";
import { Flex, Box } from "src/ccl/layout";
import { getInitials } from "src/utils/lang";

interface AvatarUser {
  name: string;
  featuredAsset?: {
    mediaUrl: string;
  };
}

interface CAJAvatarCollectionProps {
  avatarCss?: Stitches.CSS;
  icons?: JSX.Element[];
  overlap?: number;
  reverseOverlap?: boolean;
  size?: number;
  users: AvatarUser[];
}

export const CAJAvatarCollection = ({
  avatarCss,
  users,
  size,
}: CAJAvatarCollectionProps) => {
  const overlap = 10;
  const avatarSize = size || 40 + 3;

  const numberOfUsersToDisplay = Math.min(users.length, 5);
  const usersToDisplay = users.slice(0, numberOfUsersToDisplay);

  return (
    <Flex css={{ flexDirection: "row-reverse" }}>
      {usersToDisplay.map(({ name, featuredAsset }, index) => {
        if (users.length > numberOfUsersToDisplay && index === 0) {
          return (
            <Box key={index}>
              <Avatar
                css={{
                  position: "relative",
                  width: `${avatarSize - 6}px`,
                  height: `${avatarSize - 6}px`,
                  backgroundColor: "$grey2",
                  display: "flex",
                  "@bp2": { display: "none" },
                  ...avatarCss,
                }}
                fontVariant="$body2"
                fontColor="grey6"
                initials={`+${users.length - 3}`}
                imageUrl={undefined}
              />
              <Avatar
                css={{
                  position: "relative",
                  width: `${avatarSize}px`,
                  height: `${avatarSize}px`,
                  backgroundColor: "$grey2",
                  display: "none",
                  "@bp2": { display: "flex" },
                  ...avatarCss,
                }}
                fontVariant="$body2"
                fontColor="grey6"
                initials={`+${users.length - numberOfUsersToDisplay + 1}`}
                imageUrl={undefined}
              />
            </Box>
          );
        } else if (users.length === 5 && index === 0) {
          return (
            <Box key={index}>
              <Avatar
                css={{
                  position: "relative",
                  width: `${avatarSize - 6}px`,
                  height: `${avatarSize - 6}px`,
                  right: `-${overlap * index}px`,
                  border: "3px solid $white",
                  display: "none",
                  "@bp2": {
                    display: "block",
                    width: `${avatarSize}px`,
                    height: `${avatarSize}px`,
                    right: `-${overlap * index}px`,
                  },
                  ...avatarCss,
                }}
                initials={getInitials(name)}
                imageUrl={featuredAsset?.mediaUrl}
              />
              <Avatar
                css={{
                  position: "relative",
                  width: `${avatarSize - 6}px`,
                  height: `${avatarSize - 6}px`,
                  backgroundColor: "$grey2",
                  display: "flex",
                  "@bp2": { display: "none" },
                  ...avatarCss,
                }}
                fontVariant="$body2"
                fontColor="grey6"
                initials={`+${users.length - 3}`}
                imageUrl={undefined}
              />
            </Box>
          );
        } else {
          return (
            <Avatar
              key={index}
              css={{
                position: "relative",
                width: `${avatarSize - 6}px`,
                height: `${avatarSize - 6}px`,
                right: `-${overlap * index}px`,
                border: "3px solid $white",
                display: index > 3 ? "none" : "block",
                "@bp2": {
                  display: "block",
                  width: `${avatarSize}px`,
                  height: `${avatarSize}px`,
                  right: `-${overlap * index}px`,
                },
                ...avatarCss,
              }}
              initials={getInitials(name)}
              imageUrl={featuredAsset?.mediaUrl}
            />
          );
        }
      })}
    </Flex>
  );
};
