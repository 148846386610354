import type * as Stitches from "@stitches/react";
import React from "react";
import { Icon } from "src/ccl/document";
import { AvailableIcon } from "src/ccl/document/icon";
import { Button } from "src/ccl/navigation";
import { mergeCss, styled } from "src/ccl/stitches";

export type PillVariant =
  | "regular"
  | "menuPill"
  | "cta"
  | "rounded"
  | "roundedSecondary"
  | "announcement"
  | "roundedTertiary"
  | "roundedFilter"
  | "rebrand";

export interface PillProps {
  variant?: PillVariant;
  suffixIcon?: AvailableIcon;
  onSuffixIconClick?: () => void;
  onClick?: (e: React.SyntheticEvent) => void;
  css?: Stitches.CSS;
  children: React.ReactNode;
}

export const PillContainer = styled("div", {
  display: "flex",
  margin: "$2 $0 $2 $3",
  borderWidth: 1,
  borderRadius: "$sm",
  overflow: "hidden",
  alignItems: "center",
  variants: {
    variant: {
      regular: {
        borderColor: "$grey6",
        borderStyle: "solid",
      },
      menuPill: {
        backgroundColor: "$grey2",
        borderRadius: "7px",
        padding: "4px 10px",
      },
      cta: {
        backgroundColor: "$black",
        color: "$white",
        px: "$3",
        py: "$2",
        textDecoration: "none",
        fontSize: "$14",
        cursor: "pointer",
      },
      rounded: {
        borderRadius: "$xl",
        backgroundColor: "$white",
        padding: "$2 $4",
        boxShadow: "$focus",
      },
      announcement: {
        borderRadius: "$xl",
        backgroundColor: "$turquoiseDark",
        padding: "$2 $4",
      },
      roundedSecondary: {
        padding: "$2 $4",
        borderRadius: "$xl",
        backgroundColor: "$grey2",
      },
      roundedTertiary: {
        padding: "$2 $4",
        borderRadius: "$xl",
        backgroundColor: "$grey6",
        fontWeight: "$bold",
      },
      roundedFilter: {
        width: "fit-content",
        whiteSpace: "nowrap",
        padding: "$2 $3",
        borderRadius: "$pill",
        border: "1px solid $grey3",
      },
      rebrand: {
        width: "fit-content",
        height: "40px",
        whiteSpace: "nowrap",
        padding: "8px 14px",
        borderRadius: "8px",
        border: "1px solid $grey3",
      },
    },
  },
});

export const PillRemoveButton = ({
  onClick,
  onMouseDown,
  onTouchEnd,
  icon,
}: {
  icon: AvailableIcon;
  onClick?: () => void;
  onTouchEnd?: () => void;
  onMouseDown?: () => void;
}) => (
  <Button
    data-test-id="PillRemove"
    onClick={onClick}
    onTouchEnd={onTouchEnd}
    onMouseDown={onMouseDown}
    variant="unstyled"
    css={{
      backgroundColor: "$grey2",
      padding: "$3",
      display: "flex",
      alignItems: "center",

      "&:hover": {
        backgroundColor: "$red",

        path: {
          fill: "$white",
        },
      },
    }}
  >
    <Icon variant={icon} size={12} />
  </Button>
);

export const Pill = ({
  children,
  suffixIcon,
  onSuffixIconClick,
  onClick,
  css = {},
  ...containerProps
}: PillProps) => (
  <PillContainer
    data-test-id="FTGPill"
    css={mergeCss(
      {
        cursor: onClick ? "pointer" : "initial",
      },
      css,
    )}
    onClick={onClick}
    {...containerProps}
  >
    {children}
    {suffixIcon && (
      <PillRemoveButton onClick={onSuffixIconClick} icon={suffixIcon} />
    )}
  </PillContainer>
);
