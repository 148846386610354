import { CSS } from "@stitches/react";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { mergeCss } from "src/ccl";

export interface ConfirmModalProps extends ModalProps {
  confirmButtonTitle: string;
  confirmButtonVariant?: string;
  confirmButtonLoadingTitle?: string;
  cancelButtonTitle?: string;
  onCancel: () => void;
  onConfirm: () => void;
  isDisabled?: boolean;
  subtitle?: string;
  flexCss?: CSS;
}

export const ConfirmModal = ({
  isOpen,
  showCloseButton,
  title,
  width,
  cancelButtonTitle = "Cancel",
  confirmButtonTitle,
  confirmButtonVariant = "primaryCta",
  confirmButtonLoadingTitle,
  onCancel,
  onConfirm,
  isDisabled = false,
  subtitle,
  children,
  flexCss = {},
  ...modalProps
}: ConfirmModalProps) => {
  const loadingText = confirmButtonLoadingTitle || "...";

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      titleVariant="nh3"
      titleCss={{
        marginBottom: 0,
        fontSize: "$22 !important",
      }}
      width={width}
      showCloseButton={showCloseButton}
      onClose={onCancel}
      {...modalProps}
    >
      <Box data-test-id="confirm-modal-body">{children}</Box>
      {subtitle && <Text variant="b2">{subtitle}</Text>}
      <Flex
        css={mergeCss(flexCss, {
          flexDirection: "column-reverse",
          mt: "$9",
          justifyContent: "space-between",
          "@bp1": { flexDirection: "row" },
        })}
      >
        <Button
          data-test-id="ConfirmButtonSecondary"
          variant="secondaryCta"
          onClick={onCancel}
          css={{
            width: "100%",
            "@bp1": { width: "initial" },
            fontFamily: "$sansNew",
            fontWeight: "$bold",
            fontSize: "$14",
          }}
        >
          {cancelButtonTitle}
        </Button>

        <Button
          data-test-id="ConfirmButtonPrimary"
          variant={confirmButtonVariant}
          onClick={onConfirm}
          disabled={isDisabled}
          css={{
            width: "100%",
            mb: "$5",
            "@bp1": { width: "initial", mb: 0 },
            fontFamily: "$sansNew",
            fontWeight: "$bold",
            fontSize: "$14",
          }}
        >
          {isDisabled ? loadingText : confirmButtonTitle}
        </Button>
      </Flex>
    </Modal>
  );
};
