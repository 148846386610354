import React from "react";
import { Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { LinkButton } from "src/ccl/navigation";
import { BlurredModels } from "src/components/dashboards/agents";

export const NoAgencyTalent = ({ vertical }: { vertical: string }) => (
  <Box
    css={{
      position: "relative",
      py: "$11",
      px: "$5",
      mt: "$11",
      mb: "$8",
      "@bp2": {
        py: "$16",
        px: "$17",
        mt: "$9",
        mb: "$16",
      },
    }}
  >
    <BlurredModels
      css={{
        left: 0,
        top: 250,
        "@bp2": { left: "initial", right: 0, top: 20 },
      }}
    />

    <Text
      variant={{ "@initial": "h2", "@bp2": "h1" }}
      css={{ maxWidth: "550px" }}
    >
      Start adding your talent
    </Text>
    <Text
      variant={{ "@initial": "body", "@bp2": "impact" }}
      css={{
        maxWidth: "400px",
        my: "$8",
        "@bp2": {
          my: "$15",
        },
      }}
    >
      You currently have no {vertical.replace(/s$/, "")} profiles. Ready to
      start adding some?
    </Text>
    <LinkButton
      to={`/dashboard/agent/talent/new/profile?v=${vertical}`}
      variant="primary"
    >
      Add a {vertical.replace(/s$/, "")} profile
    </LinkButton>
  </Box>
);
