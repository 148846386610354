import { Checkbox } from "src/ccl/data-entry";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";

interface FilterProps {
  variant?: "dropdown" | "pill" | "roundedButton";
  checked: boolean;
  onClick: () => void;
  label: string;
  icon?: AvailableIcon;
  noCheckbox?: boolean;
}

export const FilterOption = (props: FilterProps) => {
  if (props.variant === "pill") {
    return <PillFilterOption {...props} />;
  }
  if (props.variant === "roundedButton") {
    return <RoundedButtonFilterOption {...props} />;
  }
  return <DropdownFilterOption {...props} />;
};

const DropdownFilterOption = (props: FilterProps) => (
  <Flex
    data-test-id={`FilterOption-${props.label}`}
    css={{
      alignItems: "center",
      py: "$3",
      px: "$4",
      "&:Hover": {
        cursor: "pointer",
        backgroundColor: "$grey1",
      },
      borderRadius: "8px",
    }}
    onClick={props.onClick}
  >
    {!props.noCheckbox && (
      <Checkbox
        css={{
          cursor: "pointer",
          my: "auto",
          mr: "$4",
          backgroundColor: "$grey1",
          width: 18,
          height: 18,
        }}
        value={props.label}
        checked={props.checked}
        onChange={() => {}}
      />
    )}
    {props.icon && <Icon variant={props.icon} css={{ mr: "$3" }} />}
    <Text>{props.label}</Text>
  </Flex>
);

const PillFilterOption = (props: FilterProps) => (
  <Flex
    data-test-id={`FilterOption-${props.label}`}
    css={{
      alignItems: "center",
      py: "$3",
      px: "$4",
      "&:Hover": {
        cursor: "pointer",
        backgroundColor: "$grey1",
      },
      background: props.checked ? "$black" : "none",
      border: "1.5px solid $grey2",
      borderRadius: "$pill",
    }}
    onClick={props.onClick}
  >
    {props.icon && (
      <Icon
        variant={props.icon}
        css={{ mr: "$3" }}
        color={props.checked ? "white" : "black"}
      />
    )}
    <Text variant="b3" color={props.checked ? "white" : "black"}>
      {props.label}
    </Text>
  </Flex>
);

const RoundedButtonFilterOption = (props: FilterProps) => (
  <Flex
    data-test-id={`FilterOption-${props.label}`}
    css={{
      alignItems: "center",
      py: "$6",
      px: "$10",
      "&:Hover": {
        cursor: "pointer",
        backgroundColor: props.checked ? "$grey4" : "$grey1",
      },
      background: props.checked ? "$black" : "none",
      border: "1.5px solid $grey2",
      borderRadius: "$lg",
    }}
    onClick={props.onClick}
  >
    {props.icon && (
      <Icon
        variant={props.icon}
        css={{ mr: "$3" }}
        color={props.checked ? "white" : "black"}
        size={18}
      />
    )}
    <Text variant="b3" color={props.checked ? "white" : "black"}>
      {props.label}
    </Text>
  </Flex>
);
