import { gql } from "@apollo/client";

export const BOOKER_DASHBOARD_TEAM_HELP_PANEL_QUERY = gql`
  query BookerDashboardTeamHelpPanel {
    me {
      staffUsers {
        id
        name
        featuredAsset {
          mediaUrl
        }
      }
    }
  }
`;
