import { SortOrderInput, SortDirection } from "src/graphql/types";

export const currentFieldName = (
  fieldName: string,
  sortOrder: SortOrderInput,
): boolean => {
  return fieldName === sortOrder.column;
};

export const currentDirectionFromSortOrder = (
  fieldName: string,
  sortOrder: SortOrderInput,
): SortDirection => {
  return currentFieldName(fieldName, sortOrder)
    ? sortOrder.direction
    : SortDirection.Asc;
};
