export const getLocalStorageItem = <T extends string>(key: string): T | null =>
  window.localStorage.getItem(key) as T;

export const updateLocalStorageItem = (key: string, payload: string | null) => {
  if (payload === null || payload === "null") {
    window.localStorage.removeItem(key);
  } else {
    window.localStorage.setItem(key, payload);
  }
};
