import React from "react";
import { Icon } from "src/ccl/document";
import { ExternalLink } from "src/ccl/navigation";

export const RemittanceDownload = ({ url }: { url: string }) => (
  <ExternalLink
    to={url}
    eventName="account:payments:remittanceLetter"
    target="_blank"
  >
    <Icon variant="download" size={20} />
  </ExternalLink>
);
