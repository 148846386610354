import { gql } from "@apollo/client";

export const BOOKER_TALENT_COLLECTION_MEMBERSHIPS = gql`
  query BookerTalentCollectionMemberships($talentId: ID!) {
    me {
      profile {
        ... on BookerProfile {
          talentCollectionMemberships(id: $talentId) {
            id
          }
        }
      }
    }
  }
`;
