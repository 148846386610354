import React, { Suspense, useEffect, useState } from "react";
import { StoreProvider } from "easy-peasy";
import { ApolloProvider } from "@apollo/client";
import Modal from "react-modal";
import { store } from "./models";
import { useStoreInitialisers } from "./hooks";
import { Static } from "./pages/Static";
import { setAffiliateFromUtmParams } from "./utils/affiliate";
import { protectedLazy } from "./utils/protectedLazy";
import { EnvironmentBanner } from "./components/EnvironmentBanner";
import { graphQLClient, graphQLClientWithCableLink } from "./graphql/client";
import { Loading, RefreshNotification } from "./components";
import { Router } from "./Router";
import { initializeFirebase } from "./libs/firebase";

const Dashboard = protectedLazy(
  () => import("./pages/dashboard/DashboardRouter"),
);
const Account = protectedLazy(() => import("./pages/account/Account"));
const Packages = protectedLazy(() => import("./pages/packages/Packages"));
const Blog = protectedLazy(() => import("./pages/blog/Blog"));
const AgentOnboarding = protectedLazy(
  () => import("./pages/agentOnboarding/AgentOnboarding"),
);
const TalentOnboarding = protectedLazy(
  () => import("./pages/talentOnboarding/TalentOnboarding"),
);
const SelfManagedTalentOnboarding = protectedLazy(
  () => import("./pages/talentOnboarding/SelfManagedTalentOnboarding"),
);

const NotFound = protectedLazy(() => import("./pages/NotFound"));

const CreateJob = protectedLazy(() =>
  import("./pages/createJob/CreateJob").then((module) => ({
    default: module.CreateJob,
  })),
);
const About = protectedLazy(() =>
  import("./pages/marketing/about/About").then((module) => ({
    default: module.About,
  })),
);
const Careers = protectedLazy(() =>
  import("./pages/marketing/careers/Careers").then((module) => ({
    default: module.Careers,
  })),
);
const Podcast = protectedLazy(() =>
  import("./pages/marketing/podcast/Podcast").then((module) => ({
    default: module.Podcast,
  })),
);
const ForTalent = protectedLazy(() =>
  import("./pages/marketing/forModels/ForTalent").then((module) => ({
    default: module.ForTalent,
  })),
);
const ForBookers = protectedLazy(() =>
  import("./pages/marketing/forBookers/ForBookers").then((module) => ({
    default: module.ForBookers,
  })),
);
const ForAgencies = protectedLazy(() =>
  import("./pages/marketing/forAgencies/ForAgencies").then((module) => ({
    default: module.ForAgencies,
  })),
);

const ConfirmPasswordReset = protectedLazy(() =>
  import("./pages/auth").then((module) => ({
    default: module.ConfirmPasswordReset,
  })),
);

const StartPasswordReset = protectedLazy(() =>
  import("./pages/auth").then((module) => ({
    default: module.StartPasswordReset,
  })),
);

const SignIn = protectedLazy(() =>
  import("./pages/auth").then((module) => ({
    default: module.SignIn,
  })),
);
const PaymentVerification = protectedLazy(() =>
  import("./pages/PaymentVerification").then((module) => ({
    default: module.PaymentVerification,
  })),
);
const AgencyDirectory = protectedLazy(() =>
  import("./pages/agencyHomepage/AgencyDirectory").then((module) => ({
    default: module.AgencyDirectory,
  })),
);
const AgencyHomepage = protectedLazy(() =>
  import("./pages/agencyHomepage/AgencyHomepage").then((module) => ({
    default: module.AgencyHomepage,
  })),
);
const JoinBookingCompany = protectedLazy(() =>
  import("./pages/bookerOnboarding").then((module) => ({
    default: module.JoinBookingCompany,
  })),
);

const DeskRental = protectedLazy(() =>
  import("./pages/DeskRental").then((module) => ({
    default: module.DeskRental,
  })),
);

const CreateAccountV2 = protectedLazy(() =>
  import("./pages/account/onboarding/CreateAccountV2").then((module) => ({
    default: module.CreateAccountV2,
  })),
);

const EmailVerification = protectedLazy(() =>
  import("./pages/account/onboarding/EmailVerification").then((module) => ({
    default: module.EmailVerification,
  })),
);

const CreateAccountOnboarding = protectedLazy(() =>
  import("./pages/account/onboarding/CreateAccountOnboarding").then(
    (module) => ({
      default: module.CreateAccountOnboarding,
    }),
  ),
);

const AccountNotFound = protectedLazy(() =>
  import("./pages/auth/NotFound").then((module) => ({
    default: module.AccountNotFound,
  })),
);

const VerifyEmail = protectedLazy(() =>
  import("./pages/verifyEmail/VerifyEmail").then((module) => ({
    default: module.VerifyEmail,
  })),
);

const Homepage = protectedLazy(() =>
  import("./pages/marketing/homepage/Homepage").then((module) => ({
    default: module.Homepage,
  })),
);

const BuilderLandingPage = protectedLazy(() =>
  import("./pages/BuilderLandingPage").then((module) => ({
    default: module.BuilderLandingPage,
  })),
);

initializeFirebase();

setAffiliateFromUtmParams();

Modal.setAppElement("#root");

export const App = () => {
  useStoreInitialisers(store);

  const [client, setClient] = useState(graphQLClient);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setIsLoggedIn(!!store.getState().currentUser.accessToken);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (client) {
        client.stop();
      }
      setClient(graphQLClientWithCableLink());
    }
  }, [isLoggedIn]);

  return (
    <StoreProvider store={store}>
      <ApolloProvider client={client}>
        <Suspense fallback={<Loading />}>
          <EnvironmentBanner />
          <RefreshNotification />

          <Router
            Homepage={Homepage}
            SignIn={SignIn}
            StartPasswordReset={StartPasswordReset}
            ConfirmPasswordReset={ConfirmPasswordReset}
            Blog={Blog}
            About={About}
            ForTalent={ForTalent}
            ForClients={ForBookers}
            ForAgencies={ForAgencies}
            Careers={Careers}
            Podcast={Podcast}
            Account={Account}
            CreateAccount={CreateAccountV2}
            CreateJob={CreateJob}
            Dashboard={Dashboard}
            DeskRental={DeskRental}
            SelfManagedTalentOnboarding={SelfManagedTalentOnboarding}
            TalentOnboarding={TalentOnboarding}
            AgentOnboarding={AgentOnboarding}
            JoinBookingCompany={JoinBookingCompany}
            Packages={Packages}
            BuilderLandingPage={BuilderLandingPage}
            Static={Static}
            NotFound={NotFound}
            PaymentVerification={PaymentVerification}
            AgencyDirectory={AgencyDirectory}
            AgencyHomepage={AgencyHomepage}
            EmailVerification={EmailVerification}
            CreateAccountOnboarding={CreateAccountOnboarding}
            AccountNotFound={AccountNotFound}
            VerifyEmail={VerifyEmail}
          />
        </Suspense>
      </ApolloProvider>
    </StoreProvider>
  );
};
