import { gql } from "@apollo/client";

export const REMOVE_SAVED_TALENT_FROM_COLLECTIONS = gql`
  mutation RemoveSavedTalentFromCollections(
    $talentId: ID!
    $collectionIds: [ID!]!
  ) {
    removeSavedTalentFromCollections(
      talentId: $talentId
      collectionIds: $collectionIds
    ) {
      id
    }
  }
`;
