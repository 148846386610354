import { ApolloError, gql } from "@apollo/client";
import {
  computed,
  Computed,
  ActionOn,
  actionOn,
  thunk,
  Action,
  action,
  Thunk,
  ThunkOn,
  thunkOn,
} from "easy-peasy";
import { requestModel, RequestModel } from "./requestModel";
import { StoreModel } from ".";
import { graphQLClient } from "src/graphql/client";
import { Query } from "src/graphql/types";

export interface FeaturesModel extends RequestModel<FeaturesModel> {
  setEnabledFeatures: Action<FeaturesModel, string[]>;
  enabledFeatures: string[] | null;
  featureEnabled: Computed<FeaturesModel, (feature: string) => boolean>;
  onGetData: ActionOn<FeaturesModel>;
  onLogIn: ThunkOn<FeaturesModel, void, StoreModel>;
  request: Thunk<
    FeaturesModel,
    { force?: boolean } | undefined,
    void,
    StoreModel
  >;
  onAppMount: Thunk<FeaturesModel, void, void, StoreModel>;
}

export const featuresModel: FeaturesModel = {
  ...requestModel,
  enabledFeatures: null,
  setLoading: action((state) => {
    state.enabledFeatures = null;
    state.status = "loading";
  }),
  setEnabledFeatures: action((state, payload) => {
    state.enabledFeatures = payload;
    state.status = "loaded";
  }),
  featureEnabled: computed((state) => (feature) => {
    return (state.enabledFeatures || []).includes(feature);
  }),
  onLogIn: thunkOn(
    (_actions, storeActions) => storeActions.currentUser.logIn,
    async (actions) => {
      actions.request({ force: true });
    },
  ),
  request: thunk(async (actions, payload, { getStoreState }) => {
    const force = payload === undefined ? false : payload.force;

    if (getStoreState().features.enabledFeatures !== null && !force) {
      return;
    }

    actions.setLoading();

    try {
      const result = await graphQLClient.query<Query>({
        query: gql`
          query EnabledFeatures {
            enabledFeatures
          }
        `,
        fetchPolicy: "network-only",
      });

      if (result.error) {
        throw result.error;
      }

      actions.setEnabledFeatures(result.data.enabledFeatures);
    } catch (e) {
      if (e instanceof Error || e instanceof ApolloError) {
        actions.setError(e.message);
      } else {
        actions.setError("Something went wrong");
      }
    }
  }),
  onGetData: actionOn(
    (actions) => actions.request,
    (state) => {
      if (!window.gloss) {
        window.gloss = {};
      }

      const doNotTrack =
        (state.enabledFeatures || []).includes("do_not_track") || false;

      window.gloss.disableTracking = doNotTrack;
    },
  ),
  onAppMount: thunk((actions) => {
    actions.request();
  }),
};
