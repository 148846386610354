import React from "react";
import type * as Stitches from "@stitches/react";
import { mergeCss, styled } from "src/ccl/stitches/config";
import { AvailableIcon, Icon } from "src/ccl/document/icon";
import { tokens, iconSizes } from "src/ccl/stitches/theme";
import { Flex } from "src/ccl/layout";

interface InfoBannerProps {
  icon?: AvailableIcon;
  iconSize?: keyof typeof iconSizes;
  variant?: "bordered" | "centered" | "tightCentered";
  color?: keyof typeof tokens.colors;
  css?: Stitches.CSS;
  withIcon?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

const Container = styled(Flex, {
  alignItems: "baseline",

  svg: {
    mr: "$4",
  },

  variants: {
    variant: {
      bordered: {
        padding: "$5",
        border: "2px solid black",
      },

      centered: {
        alignItems: "center",

        svg: {
          mr: "$3",
        },
      },

      tightCentered: {
        alignItems: "center",

        svg: {
          mr: "$2",
        },
      },
    },
  },
});

export const InfoBanner = ({
  children,
  withIcon = true,
  icon = "information",
  iconSize = 16,
  color,
  variant,
  css = {},
  onClick,
}: InfoBannerProps) => (
  <Container variant={variant} css={mergeCss({}, css)} onClick={onClick}>
    {withIcon && (
      <Icon
        variant={icon}
        size={iconSize}
        css={{ flexShrink: 0 }}
        color={color}
      />
    )}

    {children}
  </Container>
);
