import React, { useEffect, useRef, useState } from "react";
import { CSS } from "@stitches/react";
import { CarouselControl } from "./CarouselControl";
import { useCalculatedGutter } from "./utils";
import { Flex, Box } from "src/ccl/layout";
import { mergeCss } from "src/ccl";

const isScrolled90Percent = (inner: HTMLDivElement, outer: HTMLDivElement) => {
  const targetScroll = inner.offsetWidth * 0.9;
  return targetScroll <= outer.scrollLeft + outer.offsetWidth;
};

interface CarouselProps {
  fetchMore?: () => void;
  padSides?: boolean;
  gap?: number;
  context?: "listView";
  ArrowCss?: CSS;
  children: React.ReactNode;
}

enum Direction {
  left = -1,
  right = 1,
}

export const Carousel = ({
  fetchMore,
  gap = 16,
  padSides = false,
  context,
  ArrowCss = {},
  children,
}: CarouselProps) => {
  const childrenLength = React.Children.toArray(children).length;

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const gutter = useCalculatedGutter();
  const outer = useRef<HTMLDivElement>(null);
  const inner = useRef<HTMLDivElement>(null);

  const scroll = (direction: Direction) => {
    if (outer.current && inner.current) {
      const scrollWidth = inner.current.offsetWidth / childrenLength;

      outer.current.scrollLeft += scrollWidth * direction;
    }
  };

  useEffect(() => {
    if (!inner.current || !outer.current) {
      return;
    }

    if (inner.current.offsetWidth > outer.current.offsetWidth) {
      if (!showRightArrow) {
        setShowRightArrow(true);
      }
    }
  }, [childrenLength]);

  const onScroll = () => {
    if (
      !isFetchingMore &&
      fetchMore &&
      inner.current &&
      outer.current &&
      isScrolled90Percent(inner.current, outer.current)
    ) {
      setIsFetchingMore(true);
      fetchMore();
      setIsFetchingMore(false);
    }
    if (!outer.current) {
      return;
    }
    if (outer.current.scrollLeft === 0) {
      setShowLeftArrow(false);
    } else if (!showLeftArrow) {
      setShowLeftArrow(true);
    }

    if (
      outer.current.scrollLeft + outer.current.offsetWidth >
      outer.current.scrollWidth - 20 // don't show on the last card
    ) {
      setShowRightArrow(false);
    } else if (!showRightArrow) {
      setShowRightArrow(true);
    }
  };

  const inListView = context === "listView";

  const getIconColor = (direction: "left" | "right") => {
    if (!inListView) {
      return "black";
    }
    if (direction === "right" && !showRightArrow) {
      return "grey3";
    }
    if (direction === "left" && !showLeftArrow) {
      return "grey3";
    } else {
      return "black";
    }
  };

  return (
    <Box
      data-test-id="carousel-root"
      css={{
        alignItems: "center",
        position: "relative",
        [`& ${Flex}`]: {
          opacity: 1,
        },
        "&:hover": {
          [`& ${Flex}`]: {
            opacity: 1,
          },
        },
      }}
    >
      <CarouselControl
        icon="chevronLeft"
        iconColor={getIconColor("left")}
        iconSize={inListView ? 14 : undefined}
        gutter={gutter}
        onClick={() => scroll(Direction.left)}
        showArrows={inListView ? true : showLeftArrow}
        css={mergeCss(
          {
            opacity: 0,
            position: "absolute",
            left: 0,
            top: outer.current
              ? outer.current.offsetHeight / 2 - 20
              : undefined,
            "@bp2": {
              left: padSides ? gutter - 20 : inListView ? 0 : -20,
            },
          },
          ArrowCss,
        )}
      />
      <CarouselControl
        icon="chevronRight"
        iconColor={getIconColor("right")}
        iconSize={inListView ? 14 : undefined}
        gutter={gutter}
        onClick={() => scroll(Direction.right)}
        showArrows={inListView ? true : showRightArrow}
        css={mergeCss(
          {
            opacity: 0,
            position: "absolute",
            top: outer.current
              ? outer.current.offsetHeight / 2 - 20
              : undefined,
            right: 0,
            "@bp2": {
              right: padSides ? gutter - 20 : inListView ? 0 : -20,
            },
          },
          ArrowCss,
        )}
      />
      <Flex
        data-test-id="carousel-outer"
        css={{
          overflowX: "auto",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
        ref={outer}
        onScroll={onScroll}
      >
        <Flex
          data-test-id="carousel-inner"
          css={{
            gap: gap,
            "@bp2": { px: padSides ? gutter : undefined },
          }}
          ref={inner}
        >
          {children}
        </Flex>
      </Flex>
    </Box>
  );
};
