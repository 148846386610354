import { ErrorMessage } from "formik";
import React, { PropsWithChildren } from "react";
import { ComponentProps, CSS } from "@stitches/react";
import { ValidationBlock } from "src/ccl/feedback";
import { Text } from "src/ccl/document";
import { mergeCss, styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";
import { Box, Flex } from "src/ccl/layout";

export const Fieldset = styled("fieldset", {
  spaceY: "$5",
  border: "none",
  padding: "unset",
});

export const Label = styled("label", {
  fontSize: "$16",
  lineHeight: "$26",
  variants: {
    layout: {
      block: {
        display: "block",
        spaceY: "$2",
        mb: "$6",
      },
      column: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        "@bp1": {
          spaceX: "$4",
          spaceY: 0,
          textAlign: "right",
          "& > :first-child": { width: "33%" },
        },
      },
      inline: {
        display: "flex",
        alignItems: "center",
        spaceX: "$2",
        spaceY: 0,
        mb: "$6",
      },
    },
  },
});

export interface FieldProps extends ComponentProps<typeof Box> {
  label?: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  labelWeight?: keyof typeof tokens.fontWeights;
  name?: string;
  layout?: "block" | "column" | "inline";
  color?: keyof typeof tokens.colors;
  variant?: string;
  wrapperComponent?: React.FC<PropsWithChildren>;
  css?: CSS;
  labelCss?: CSS;
  validationContainerCss?: CSS;
  validationTitleCss?: CSS;
}

export const Field = ({
  children,
  label,
  secondaryLabel,
  labelWeight = "bold",
  name,
  layout = "block",
  css = {},
  labelCss = {},
  validationContainerCss = {},
  validationTitleCss = {},
  color,
  variant,
  wrapperComponent: WrapperComponent = Box,
}: FieldProps) => {
  const items = [
    <Flex
      css={{
        justifyContent: "space-between",
        alignItems: "flex-end",
        mt: "$0",
      }}
      key="label"
    >
      {label && (
        <Text
          variant={variant}
          color={color}
          css={mergeCss(
            {
              fontWeight: labelWeight,
              ml: layout === "inline" ? "$2" : undefined,
            },
            labelCss,
          )}
        >
          {label}
        </Text>
      )}

      {secondaryLabel && (
        <Text variant="meta" color="grey6" css={mergeCss({}, labelCss)}>
          {secondaryLabel}
        </Text>
      )}
    </Flex>,
    children,
  ];

  return (
    <WrapperComponent>
      <Label layout={layout} css={mergeCss({}, css)}>
        {layout === "inline" ? items.reverse() : items}
      </Label>
      {name && (
        <ErrorMessage name={name} key="error">
          {(message) => (
            <ValidationBlock
              title={message}
              variant="error"
              data-test-id={`FormFieldError-${name}`}
              css={validationContainerCss}
              errorTitleCss={validationTitleCss}
            />
          )}
        </ErrorMessage>
      )}
    </WrapperComponent>
  );
};
