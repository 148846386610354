import React from "react";
import { useParams } from "react-router-dom";
import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import NotFound from "./NotFound";
import { useTiara, useRequest, useStoreModel } from "src/hooks";
import { FullHeaderPage } from "src/ccl/templates";
import { ContentfulRichText } from "src/components";
import { Text } from "src/ccl/document";
import { ContentContainer } from "src/ccl/layout";

interface StaticParams {
  slug: string;
}

export const Static = () => {
  const { slug } = useParams<StaticParams>();
  const contentful = useStoreModel("contentful");
  useRequest(contentful.request, {
    contentType: "page",
    "fields.slug": slug || "",
  });

  const page = contentful.data?.items[0];
  const title = page?.fields.title as string;
  const body = page?.fields.body as ContentfulDocument;

  useTiara({ title });

  if (contentful.status === "loading") {
    return <></>;
  }

  if (contentful.status === "error" || !page) {
    return <NotFound />;
  }

  return (
    <FullHeaderPage>
      <ContentContainer>
        <Text variant={{ "@initial": "h2", "@bp1": "h1" }} css={{ mb: "$8" }}>
          {title}
        </Text>
        <ContentfulRichText
          document={body}
          assets={contentful.data?.includes?.Asset}
        />
      </ContentContainer>
    </FullHeaderPage>
  );
};
