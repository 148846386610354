import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { breakpoints } from "src/ccl/stitches";

export const useCalculatedGutter = () => {
  /*
     so we need to align the CreateJob card in the carousel with the other elements
     on the page that have mx: auto. HOWEVER. Since we want this component to scroll
     all the way off screen on the left and right, we can't just mx: auto the parent
     container.
     The below calculates the required gutter based on the assumption that at BP2
     we limit the width to 1100px and at BP5 we limit to 1440px.
   */
  const [gutter, setGutter] = useState(0);

  useEffect(() => {
    const applyGutter = debounce(
      () => setGutter(calculateGutter(window.innerWidth)),
      100,
    );
    applyGutter();
    window.addEventListener("resize", applyGutter);
    return () => window.removeEventListener("resize", applyGutter);
  });
  return gutter;
};

export const calculateGutter = (windowWidth: number) => {
  if (windowWidth > breakpoints.bp5) {
    const width = (windowWidth - 1440) / 2;
    return width > 0 ? width + 12 : 12;
  } else {
    const width = (windowWidth - 1100) / 2;
    return width > 0 ? width + 12 : 12;
  }
};
