import { styled } from "src/ccl/stitches";
import { Flex } from "src/ccl/layout/flex";

export const Container = styled(Flex, {
  px: "$6",
  mx: " auto",
  width: "100%",

  "@sm": {
    px: "$9",
  },

  "@xl": {
    px: "$16",
    maxWidth: "1500px",
  },
});
