import { Icon, Text } from "src/ccl/document";
import { Box, Grid } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";

interface ISSOButtonProps {
  googleAction: () => void;
  appleAction: () => void;
  isSignInForm?: boolean;
}
export const SSOButton: React.FC<ISSOButtonProps> = ({
  googleAction,
  appleAction,
  isSignInForm = false,
}) => {
  return (
    <>
      <Box>
        <Button
          variant="userRoleSelection"
          css={{
            marginBottom: "20px",
            borderColor: "$grey2",
          }}
          data-testid="ios-sso-btn"
          onClick={appleAction}
          type="button"
        >
          <Icon variant="apple" size={30} />
          <Text
            variant="b2Bold"
            css={{
              fontWeight: "$bold !important",
              lineHeight: "$24",
            }}
          >
            Sign {`${isSignInForm ? "in" : "up"}`} with Apple
          </Text>
        </Button>
        <Button
          variant="userRoleSelection"
          css={{
            marginBottom: "20px",
            borderColor: "$grey2",
          }}
          data-testid="google-sso-btn"
          onClick={googleAction}
          type="button"
        >
          <Icon variant="google" size={30} />
          <Text
            variant="b2Bold"
            css={{
              fontWeight: "$bold !important",
              lineHeight: "$24",
            }}
          >
            Sign {`${isSignInForm ? "in" : "up"}`} with Google
          </Text>
        </Button>
      </Box>

      <Grid
        css={{
          alignItems: "baseline",
          gridColumns: 3,
          gridGap: "$5",
        }}
      >
        <Box css={{ background: "$grey3", height: "$1", width: "100%" }}></Box>
        <Text css={{ width: "165px" }}>
          Or sign {`${isSignInForm ? "in" : "up"}`} with email
        </Text>
        <Box css={{ background: "$grey3", height: "$1", width: "100%" }}></Box>
      </Grid>
    </>
  );
};
