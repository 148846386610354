import { gql } from "@apollo/client";
import {
  JOB_DETAILS_FRAGMENT,
  TALENT_DETAILS_FRAGMENT,
  JOB_VERTICAL_DETAILS_FRAGMENT,
  INVOICE_DETAILS_FRAGMENT,
} from "src/graphql/fragments";

export const BOOKER_DASHBOARD_JOB_SHOW_QUERY = gql`
  query BookerDashboardJobShow($jobId: ID!) {
    job(id: $jobId) {
      ...JobDetails
      ...JobVerticalDetails
      state
      spansMultipleDays
      cancelledAt
      cancellationReason
      commission
      currency
      invoiceFeeWithoutVat
      vatAmount
      totalPayable
      fullAddress
      addressLine1
      addressLine2
      city
      country
      postcode
      rejectionReason
      rejectionNote
      canReview
      unpaidInvoiceCount
      totalInvoiceCount
      paymentViaExternalPlatform
      paymentProcessing
      extraFeesAmount
      hasUnresolvedDisputes
      paymentMethod
      headcountEstimate
      travelExpenseCover
      nudityType
      additionalNotes
      completedAt
      payableInvoices {
        ...InvoiceDetails
      }
      feeItems {
        description
        amount
      }
      usages {
        mediaType
        form
        location
        length
      }
      invoices {
        ...InvoiceDetails
      }
      jobDisputes {
        createdAt
      }
      assignedAgent {
        name
        email
        featuredAsset {
          mediaUrl
        }
      }
      talent {
        ...TalentDetails
        email
        phoneNumber
        formattedRejectionReason
        rejectionNote
        talent {
          id
          name
          slug
          country
          email
          featuredAsset {
            mediaUrl
          }
          profile {
            ... on TalentProfile {
              id
              dietaryRequirements
              averageRating
              jobReview(jobId: $jobId) {
                id
                rating
              }
              primaryLocation {
                name
              }
              agency {
                id
                name
                slug
                firstParty
              }
              pronouns
              vertical
              location
              lastPortfolioUpload {
                id
                mediaUrl
                width
                height
              }
            }
          }
        }
      }
    }
  }
  ${JOB_DETAILS_FRAGMENT}
  ${JOB_VERTICAL_DETAILS_FRAGMENT}
  ${TALENT_DETAILS_FRAGMENT}
  ${INVOICE_DETAILS_FRAGMENT}
`;
