import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ApolloError } from "@apollo/client";
import { Text } from "src/ccl/document";
import { LineItem, LineItemKind } from "src/graphql/types";
import { Box, Flex } from "src/ccl/layout";
import { FormTab, Tab, TabbedContainer } from "src/ccl/blocks";

import { Field, FormikInput, FormikCheckbox } from "src/ccl/data-entry";
import { Button } from "src/ccl/navigation";
import { ValidationBlock } from "src/ccl/feedback";

const FormFieldContainer = ({ children }: { children?: React.ReactNode }) => (
  <Flex
    css={{
      spaceX: 0,
      mt: "$4",
      flexDirection: "column",
      "@bp2": {
        mt: "$6",
        spaceX: "$6",
        flexDirection: "row",
      },
    }}
  >
    {children}
  </Flex>
);

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError("Must be a number")
    .required("Required")
    .min(0.01, "Must be more than £0")
    .max(10000, "Must be less than £10,000")
    .test("is-decimal", "Must be a valid monetary amount", (value) =>
      /^\d{1,5}\.?\d{0,2}?$/.test(value + ""),
    ),
  description: Yup.string().required("Required"),
  manuallyPaid: Yup.boolean(),
});

interface AdditionalPaymentFormProps {
  title?: React.ReactNode;
  onSubmit: (
    lineItem: Omit<LineItem, "id" | "jobTalentId" | "alreadyPaid">,
  ) => void;
  createPaymentError?: ApolloError;
  onClose?: () => void;
  loading: boolean;
}

export const AdditionalPaymentForm = ({
  title = "Payment type",
  onSubmit,
  createPaymentError,
  onClose,
  loading,
}: AdditionalPaymentFormProps) => {
  const [paymentKind, setPaymentKind] = useState<LineItemKind>(
    LineItemKind.Expense,
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        amount: 0,
        description: "",
        manuallyPaid: false,
      }}
      onSubmit={() => {}}
    >
      {({ resetForm, values, setValues, validateForm }) => (
        <Form>
          <Text weight="bold" css={{ mb: "$3" }}>
            {title}
          </Text>

          <TabbedContainer
            initialActiveId="expense"
            onTabChange={(newPaymentKind) => {
              if (newPaymentKind === "expense") {
                setPaymentKind(LineItemKind.Expense);
              } else if (newPaymentKind === "additionalFee") {
                setValues({
                  ...values,
                  manuallyPaid: false,
                });
                setPaymentKind(LineItemKind.AdditionalFee);
              }
            }}
          >
            <Tab
              id="expense"
              title={(active) => (
                <FormTab active={active} title="Expense" icon="expense" />
              )}
            >
              <FormFieldContainer>
                <Box css={{ width: "100%", "@bp2": { width: "50%" } }}>
                  <Field label="Amount" name="amount">
                    <FormikInput name="amount" inputPrefix="£" type="number" />
                  </Field>

                  <Field label="Description" name="description">
                    <FormikInput
                      name="description"
                      type="text"
                      placeholder="e.g. train tickets to London"
                    />
                  </Field>
                </Box>

                <Box css={{ width: "100%", "@bp2": { width: "50%" } }}>
                  <Box css={{ pt: 0, "@bp2": { pt: "$8" } }}>
                    <Field
                      label="Already reimbursed?"
                      labelWeight="regular"
                      name="manuallyPaid"
                      layout="inline"
                    >
                      <FormikCheckbox
                        name="manuallyPaid"
                        css={{ width: 24, height: 24 }}
                      />
                    </Field>

                    <Text
                      variant="meta"
                      color="grey6"
                      css={{ mt: "$3", ml: "$8" }}
                    >
                      If the talent has already been paid for this expense (e.g.
                      if we paid it for them using the company card)
                    </Text>
                  </Box>
                </Box>
              </FormFieldContainer>
            </Tab>

            <Tab
              id="additionalFee"
              title={(active) => (
                <FormTab
                  active={active}
                  title="Additional fee"
                  titleCondensed="Fee"
                  icon="additionalFee"
                />
              )}
            >
              <FormFieldContainer>
                <Box css={{ width: "100%", "@bp2": { width: "50%" } }}>
                  <Field label="Amount" name="amount">
                    <FormikInput name="amount" inputPrefix="£" type="number" />
                  </Field>
                </Box>

                <Box css={{ width: "100%", "@bp2": { width: "50%" } }}>
                  <Field label="Description" name="description">
                    <FormikInput
                      name="description"
                      placeholder="e.g. train tickets to London"
                      type="text"
                    />
                  </Field>
                </Box>
              </FormFieldContainer>
            </Tab>
          </TabbedContainer>
          {createPaymentError && (
            <ValidationBlock
              variant="error"
              size="sm"
              css={{ my: "$3" }}
              title="Couldn't create additional payment"
              body={createPaymentError.message}
            />
          )}
          <Flex
            css={{
              flexDirection: "column-reverse",
              mt: "$9",
              justifyContent: "space-between",
              "@bp2": { flexDirection: "row" },
            }}
          >
            <Button
              variant="secondary"
              onClick={onClose && onClose}
              css={{ width: "100%", "@bp2": { width: "initial" } }}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              onClick={() => {
                validateForm(values).then(
                  ({ amount, description, manuallyPaid }) => {
                    if (amount || description || manuallyPaid) {
                      return;
                    }
                    onSubmit({
                      ...values,
                      currency: "GBP",
                      kind: paymentKind,
                      outboundPayment: undefined,
                    });
                    resetForm();
                  },
                );
              }}
              css={{
                width: "100%",
                mb: "$5",
                "@bp2": { width: "initial", mb: 0 },
              }}
            >
              {loading ? "Saving payment..." : "Save payment"}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
