import { gql } from "@apollo/client";

export const CREATE_BOOKER_PROFILE_MUTATION = gql`
  mutation CreateBookerProfile(
    $company: String!
    $role: String
    $website: String
    $headcountEstimate: CompanyHeadcountEstimate!
  ) {
    createBookerProfile(
      company: $company
      role: $role
      website: $website
      headcountEstimate: $headcountEstimate
    ) {
      id
      name
      profile {
        ... on BookerProfile {
          kind
          website
          company
          role
        }
      }
    }
  }
`;
