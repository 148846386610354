import { CSS, ComponentProps } from "@stitches/react";
import React from "react";
import { Text } from "src/ccl/document";
import { mergeCss, styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";

const Wrapper = styled("div", {
  borderRadius: "$round",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "$black",
});

const Image = styled("img", {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

export interface AvatarProps extends ComponentProps<typeof Wrapper> {
  initials?: string;
  imageUrl?: string;
  size?: `$${keyof typeof tokens.sizes}`;
  fontVariant?: string;
  fontColor?: keyof typeof tokens.colors;
  testId?: string;
  css?: CSS;
}

export const Avatar = ({
  initials,
  imageUrl,
  size = "$avatar",
  css = {},
  fontVariant = "mini",
  testId,
  fontColor = "white",
}: AvatarProps) => (
  <Wrapper
    data-test-id={testId}
    css={mergeCss({ width: size, height: size }, css)}
  >
    {imageUrl ? (
      <Image
        src={`${imageUrl}${imageUrl.includes("imgix") ? "?w=140" : ""}`}
        aria-hidden="true"
      />
    ) : (
      <Text color={fontColor} variant={fontVariant}>
        {initials}
      </Text>
    )}
  </Wrapper>
);
