import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { FormikCheckbox, FormikInput, Field } from "src/ccl/data-entry";
import {
  Mutation,
  MutationCreateAgencyTalentInvitationArgs,
  TalentVertical,
} from "src/graphql/types";
import { Flex } from "src/ccl/layout";
import { ValidationBlock } from "src/ccl/feedback";
import { CREATE_AGENCY_TALENT_INVITATION } from "src/graphql/mutations";
import { verticalMap } from "src/utils/user";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

interface InviteTalentModalProps extends ModalProps {
  vertical: TalentVertical;
}

export const InviteTalentModal = ({
  vertical,
  ...modalProps
}: InviteTalentModalProps) => {
  const [inviteTalent, { data, loading, error }] = useMutation<
    Mutation,
    MutationCreateAgencyTalentInvitationArgs
  >(CREATE_AGENCY_TALENT_INVITATION);

  return (
    <Modal title={`Invite ${verticalMap[vertical]}`} {...modalProps}>
      <Formik
        initialValues={{ email: "", vertical: vertical, published: true }}
        onSubmit={async (values) => inviteTalent({ variables: values })}
        validationSchema={validationSchema}
      >
        <Form>
          {error && (
            <ValidationBlock
              title="Invitation Failed"
              variant="error"
              body={error.message}
            />
          )}
          {data && (
            <ValidationBlock title="Invitation sent!" variant="success" />
          )}
          <Text>
            They will receive an email like that will allow them to create their
            own profile and onboard themselves
          </Text>
          <Flex css={{ py: "$5", flexDirection: "column" }}>
            <Field label="email" name="email">
              <FormikInput
                type="text"
                name="email"
                variant="rebrand"
                placeholder="talent@email.com"
              />
            </Field>
            <Field label="Published?" name="published">
              <Text>
                Uncheck this to hide the talent immediately upon onboarding
              </Text>
              <FormikCheckbox name="published" variant="rebrand" />
            </Field>

            <Button
              variant="primary"
              css={{ mt: "$7", justifySelf: "right" }}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send invite"}
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  );
};
