import { useState } from "react";
import { getDaysInMonth, startOfDay, startOfMonth } from "date-fns";
import { isInRange, numArrayOfLength } from "./util";
import { DateRangeInput } from "src/graphql/types";

export const useRangePickerState = (
  onChange: (range: DateRangeInput) => void,
) => {
  const [startDate, setStartDate] = useState<Date>(startOfDay(new Date()));
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [currentMonth, setCurrentMonth] = useState(startDate.getMonth());
  const [currentYear, setCurrentYear] = useState(startDate.getFullYear());

  const nextMonth = () => {
    const date = new Date(currentYear, currentMonth + 1);
    if (date.getFullYear() !== currentYear) {
      setCurrentYear(date.getFullYear());
    }
    setCurrentMonth(date.getMonth());
  };
  const previousMonth = () => {
    const date = new Date(currentYear, currentMonth - 1);
    if (date.getFullYear() !== currentYear) {
      setCurrentYear(date.getFullYear());
    }
    setCurrentMonth(date.getMonth());
  };
  const setDate = (d: number) => {
    displayedMonth.setDate(d);

    const newDate = displayedMonth;
    let newStartDate = startDate;
    let newEndDate = endDate;

    if (newDate < startDate) {
      newStartDate = newDate;
      setStartDate(newDate);
    } else if (!endDate || newDate > endDate) {
      newEndDate = newDate;
      setEndDate(newDate);
    } else if (isInRange(d, newDate, startDate, endDate)) {
      newStartDate = newDate;
      newEndDate = undefined;

      setStartDate(newDate);
      setEndDate(undefined);
    }

    if (newStartDate && newEndDate) {
      onChange({ startDate: newStartDate, endDate: newEndDate });
    }
  };

  const displayedMonth = new Date(currentYear, currentMonth, 1);
  const daysInMonth = numArrayOfLength(getDaysInMonth(displayedMonth));
  const startOfMonthIndex = startOfMonth(displayedMonth).getDay();
  const emptyDays = numArrayOfLength(startOfMonthIndex - 1); // the blank cells at the start of the month
  const daysOfMonth = [...emptyDays.map(() => undefined), ...daysInMonth];

  return {
    nextMonth,
    previousMonth,
    setDate,
    daysOfMonth,
    displayedMonth,
    startDate,
    endDate,
  };
};
