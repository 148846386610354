import React from "react";
import { CSS } from "@stitches/react";
import { Box, Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";
import { Icon } from "src/ccl/document/icon";

const positionStyle = {
  bottom: {
    bottom: "$6",
    left: "50%",
    top: "unset",
    transform: "translateX(-50%)",
    "@bp2": {
      bottom: "$12",
      width: "auto",
      gap: "$8",
    },
  },
  "top-right": {
    top: "$15",
    right: "$10",
    bottom: "unset",
    left: "unset",
    transform: "unset",
    "@bp2": {
      bottom: "unset",
      width: "auto",
      gap: "$8",
    },
  },
};

interface PopupNotificationProps {
  children: React.ReactNode;
  css?: CSS;
  showClose?: boolean;
  onClose?: () => void;
  position?: "top-right" | "bottom";
}

export const PopupNotification = ({
  children,
  css = {},
  showClose = false,
  onClose,
  position = "bottom",
}: PopupNotificationProps) => {
  return (
    <Flex
      data-test-id="PopupNotification"
      css={mergeCss(
        {
          position: "fixed",
          width: "90%",
          backgroundColor: "$white",
          px: "$6",
          py: "$5",
          boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.2)",
          borderRadius: "$md",
          zIndex: "$999",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "$5",
          ...positionStyle[position],
        },
        css,
      )}
    >
      {children}
      {showClose && onClose && (
        <Box
          css={{ cursor: "pointer" }}
          onClick={() => {
            onClose();
          }}
        >
          <Icon variant="cross" size={14} color="grey5" />
        </Box>
      )}
    </Flex>
  );
};
