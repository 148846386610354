import React, { Dispatch } from "react";
import { VerticalFilters } from "./VerticalFilters";
import { Modal } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import {
  Filter,
  FilterContext,
  QueryVerticalTalentSearchArgs,
  TalentVertical,
} from "src/graphql/types";
import { SearchParamsAction } from "src/hooks/useVerticalTalentSearchParams";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  filters: Filter[];
  setSearchParams: Dispatch<SearchParamsAction>;
  searchParams: QueryVerticalTalentSearchArgs;
  expanded: boolean | undefined;
  talentLoading: boolean | undefined;
  totalCount?: number;
  vertical: TalentVertical;
}

export const FilterModal = ({
  isOpen,
  onClose,
  onCancel,
  filters,
  setSearchParams,
  searchParams,
  expanded,
  talentLoading,
  totalCount,
}: FilterModalProps) => {
  return (
    <Modal
      title="Filter"
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
      width="wide"
      context="createJob"
      subtitleBold={false}
      titleCss={{
        marginTop: "$5",
      }}
      subtitle={
        talentLoading
          ? "Loading..."
          : totalCount !== undefined && (
              <>
                Results: <strong>{totalCount}</strong>
              </>
            )
      }
      withBodyPadding={false}
    >
      {filters && (
        <VerticalFilters
          filters={filters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          context={FilterContext.CreateAJob}
          expanded={expanded}
          css={{ px: "$0", "@bp4": { pt: "$6" } }}
        />
      )}
      <Flex
        css={{
          mt: "$4",
          mb: "$4",
          mx: "$4",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Button variant="secondary" onClick={onCancel}>
          Clear filters
        </Button>

        <Button variant="primary" onClick={onClose}>
          Apply filter
        </Button>
      </Flex>
    </Modal>
  );
};
