import { useQuery } from "@apollo/client";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { InvoiceItem } from "./InvoiceItem";
import { Text } from "src/ccl/document";
import { Box, Grid, LegacyContainer } from "src/ccl/layout";
import { Breadcrumbs, Link } from "src/ccl/navigation";
import { FullHeaderPage } from "src/ccl/templates";
import { Loading } from "src/components";
import { ApiError } from "src/components/errors";
import { BOOKER_DASHBOARD_JOB_INVOICES_QUERY } from "src/graphql/queries";
import { JobState, Query } from "src/graphql/types";
import { useStoreModel, useTiara } from "src/hooks";
import NotFound from "src/pages/NotFound";

interface JobInvoicesParams {
  slug?: string;
}

interface JobInvoicesProps {
  LayoutComponent?: FC;
}

export const JobInvoices: FC<JobInvoicesProps> = ({
  LayoutComponent = FullHeaderPage,
}) => {
  const { slug } = useParams<JobInvoicesParams>();

  const { isBooker, isFirstPartyAgent } = useStoreModel("currentUser");

  const { error, data, loading } = useQuery<Query>(
    BOOKER_DASHBOARD_JOB_INVOICES_QUERY,
    {
      variables: {
        jobId: slug || "",
      },
    },
  );

  const job = data?.job;

  useTiara({
    title: `${job?.name} - Invoices`,
  });

  if (loading) {
    return (
      <LayoutComponent>
        <LegacyContainer variant="gutter">
          <Loading css={{ minHeight: "60vh" }} />
        </LegacyContainer>
      </LayoutComponent>
    );
  }

  if (!job || error) {
    return (
      <LayoutComponent>
        <LegacyContainer variant="gutter" css={{ my: "$8" }}>
          <ApiError />
        </LegacyContainer>
      </LayoutComponent>
    );
  }

  if (![JobState.Completed, JobState.Finalized].includes(job.state)) {
    return <NotFound />;
  }

  const invoices = job.invoices || [];

  return (
    <>
      <LayoutComponent>
        <LegacyContainer
          css={{ minHeight: "50vh", mb: "$14", "@bp3": { mb: "$18" } }}
          variant="gutter"
        >
          <Breadcrumbs
            css={{
              mb: "$9",
              mt: "$9",
              "@bp2": { pt: "$8", mb: "$8" },
              "@bp4": { pt: "$16" },
            }}
          >
            <Link to={`/dashboard/${isBooker ? "booker" : "agent"}/jobs`}>
              All jobs
            </Link>
            <Link
              to={`/dashboard/${isBooker ? "booker" : "agent"}/jobs/${
                job.slug
              }`}
            >
              {job.name}
            </Link>
            <Link
              to={`/dashboard/${isBooker ? "booker" : "agent"}/jobs/${
                job.slug
              }/invoices`}
            >
              View invoices
            </Link>
          </Breadcrumbs>

          <Box css={{ mt: "$10", mb: "$4" }}>
            <Text variant={"h4"} as="h2">
              Invoices
            </Text>
          </Box>

          {invoices.length === 0 && (
            <Text>There aren&apos;t any invoices for this job yet.</Text>
          )}

          {invoices.length > 0 && (
            <Box>
              {isBooker && (
                <Box>
                  <Text>
                    To generate a PDF, simply click on any of the following
                    invoices and your download should begin in a few seconds.
                  </Text>
                  <Text>
                    Note, some invoices can take up to 30 seconds to generate.
                  </Text>
                </Box>
              )}

              {isFirstPartyAgent && (
                <Text>
                  Note: pending invoices are waiting for the booker to begin the
                  process of paying. Only the booker is able to start this
                  process and generate an invoice.
                </Text>
              )}

              <Grid
                css={{
                  gridTemplateColumns: "1fr",
                  rowGap: "$5",
                  mt: "$10",
                  "@bp3": {
                    rowGap: "$4",
                  },
                  "@bp5": {
                    gridTemplateColumns: "1fr 1fr",
                    columnGap: "$8",
                  },
                }}
              >
                {invoices.map((invoice) => (
                  <InvoiceItem
                    key={`invoice-${invoice.id}`}
                    invoice={invoice}
                  />
                ))}
              </Grid>
            </Box>
          )}
        </LegacyContainer>
      </LayoutComponent>
    </>
  );
};
