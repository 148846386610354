import { createStitches } from "@stitches/react";
import { merge } from "lodash";
import { tokens } from "./theme";
import { utils } from "./utils";

export const breakpoints = {
  bp1: 640,
  bp2: 832,
  bp3: 1024,
  bp4: 1200,
  bp5: 1600,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: tokens,
  media: {
    bp1: `(min-width: ${breakpoints.bp1}px)`,
    bp2: `(min-width: ${breakpoints.bp2}px)`,
    bp3: `(min-width: ${breakpoints.bp3}px)`,
    bp4: `(min-width: ${breakpoints.bp4}px)`,
    bp5: `(min-width: ${breakpoints.bp5}px)`,
    short: "(max-height: 700px)",
    sm: `(min-width: ${breakpoints.sm}px)`,
    md: `(min-width: ${breakpoints.md}px)`,
    lg: `(min-width: ${breakpoints.lg}px)`,
    xl: `(min-width: ${breakpoints.xl}px)`,
  },
  utils: utils,
});

export const mergeCss = (css: {}, additionalCSS: {}) =>
  merge(css, additionalCSS);
