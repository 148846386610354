import { useHistory } from "react-router-dom";
import React from "react";
import { CSS } from "@stitches/react";
import { Box, ContentContainer, Grid } from "src/ccl/layout";
import { VerticalSelectionCard } from "src/ccl/document";
import { TalentVertical, VerticalConfiguration } from "src/graphql/types";
import { useVerticalConfigurations } from "src/hooks";
import { Loading } from "src/components";
import { ApiError } from "src/components/errors";
import { sendToAmplitude, trackEvent } from "src/utils/analytics";
import { mergeCss } from "src/ccl";

export const VerticalSelector = ({
  children,
  bgCss = {},
  containerCss = {},
  wrap = false,
  amplitudeEvent,
}: {
  bgCss?: CSS;
  children?: React.ReactNode;
  containerCss?: CSS;
  wrap?: boolean;
  amplitudeEvent?: string;
}) => {
  const {
    loading,
    error,
    configurations: verticalConfigurations,
  } = useVerticalConfigurations();

  const history = useHistory();

  if (loading) {
    return <Loading />;
  }
  if (!verticalConfigurations || error) {
    return <ApiError />;
  }

  const onContinue = (vertical: TalentVertical) => {
    amplitudeEvent &&
      sendToAmplitude(amplitudeEvent, {
        vertical,
      });
    trackEvent("homepage:verticalSelection", { vertical });
    history.push(`/jobs/new/shortlist?verticals=${vertical}`);
  };

  const wrapCss = {
    gridAutoColumns: "minmax(200px, 100%)",
    gridAutoFlow: "column",
    gridGap: "$3",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@bp2": {
      gridColumns: verticalConfigurations.length,
      justifyContent: "initial",
      gridGap: "$8",
      overflow: "visible",
    },
  };

  const homeCss = {
    gridColumns: 1,
    gridGap: "$6",
    justifyContent: "center",
    "@bp1": {
      gridColumns: verticalConfigurations.length,
      justifyContent: "initial",
      gridGap: "$9",
    },
  };

  return (
    <Box
      css={mergeCss(
        {
          backgroundColor: "$black",
          position: "relative",
          top: "-1px",
          pt: "$16",
          pb: "$8",
          "@bp2": {
            pb: "$14",
          },
        },
        bgCss,
      )}
    >
      <ContentContainer
        css={mergeCss(
          {
            position: "relative",
            mt: "-80px",
            zIndex: "$300",
          },
          containerCss,
        )}
      >
        {wrap ? (
          <Grid css={wrapCss}>
            {verticalConfigurations.map(
              (config: VerticalConfiguration, index: number) => (
                <VerticalSelectionCard
                  key={index}
                  config={config}
                  index={index}
                  onClick={() => {
                    onContinue(config.id);
                  }}
                  isNew={config.newVertical}
                />
              ),
            )}
          </Grid>
        ) : (
          <Grid css={homeCss}>
            {verticalConfigurations.map(
              (config: VerticalConfiguration, index: number) => (
                <VerticalSelectionCard
                  key={index}
                  config={config}
                  index={index}
                  onClick={() => {
                    onContinue(config.id);
                  }}
                  isNew={config.newVertical}
                />
              ),
            )}
          </Grid>
        )}
        {children}
      </ContentContainer>
    </Box>
  );
};
