import { RadioImage } from "src/ccl/data-entry/radio/RadioImage";
import { Radio } from "src/ccl/data-entry";
import { Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { Pill } from "src/ccl/blocks";
import { FieldOption } from "src/graphql/types";

interface ProfileRadioSelectProps {
  option: FieldOption;
  selectedOption: string;
  setSelectedOption: (val: string) => void;
  name?: string;
}

export const ProfileRadioSelect = ({
  option,
  selectedOption,
  setSelectedOption,
  name,
}: ProfileRadioSelectProps) => {
  const selected = selectedOption === option.value;

  return (
    <Pill
      variant="rebrand"
      css={{
        display: "grid",
        cursor: "pointer",
        justifyContent: option.imageUrl || option.colour ? "normal" : "center",
        alignItems: "center",
        border: selected ? "2px solid $black" : "1.5px solid $grey2",
        px: "2px",
        margin: 0,
        height: "initial",
        width: "100%",
      }}
      onClick={() => setSelectedOption(option.value)}
    >
      <Radio
        css={{
          display: "none",
        }}
        checked={selected}
        onChange={() => setSelectedOption(option.value)}
        id={`${name}-${option.value}`}
      />

      <Flex
        as="label"
        htmlFor={option.value}
        css={{
          alignItems: "center",
          cursor: "pointer",
          gap: "10px",
          width: option.icon ? "min-content" : undefined,
        }}
      >
        {(option.imageUrl || option.icon || option.colour) && (
          <RadioImage option={option} selected={selected} />
        )}
        <Text
          variant="meta"
          css={{
            margin: "0 auto",
            color: selected ? "$black" : "$grey6",
            fontWeight: selected ? "bold" : "normal",
            textAlign: "center",
          }}
        >
          {option.description || option.label}
        </Text>
      </Flex>
    </Pill>
  );
};
