import { useState } from "react";
import { getDaysInMonth, startOfDay, startOfMonth } from "date-fns";
import { numArrayOfLength } from "./util";

export const useDatePickerState = (
  onChange: (date: Date) => void,
  initialDate?: Date,
) => {
  const value = initialDate ? new Date(initialDate) : new Date();
  const initial = startOfDay(value || new Date());
  const today = startOfDay(new Date());
  const [date, setInternalDate] = useState(initial >= today ? initial : today);
  const [currentMonth, setCurrentMonth] = useState(date.getMonth());
  const [currentYear, setCurrentYear] = useState(date.getFullYear());

  const nextMonth = () => {
    const date = new Date(currentYear, currentMonth + 1);
    if (date.getFullYear() !== currentYear) {
      setCurrentYear(date.getFullYear());
    }
    setCurrentMonth(date.getMonth());
  };
  const previousMonth = () => {
    const date = new Date(currentYear, currentMonth - 1);
    if (date.getFullYear() !== currentYear) {
      setCurrentYear(date.getFullYear());
    }
    setCurrentMonth(date.getMonth());
  };

  const setDate = (d: number) => {
    displayedMonth.setDate(d);

    setInternalDate(displayedMonth);
    onChange(displayedMonth);
  };

  const displayedMonth = new Date(currentYear, currentMonth, 1);
  const daysInMonth = numArrayOfLength(getDaysInMonth(displayedMonth));
  const startOfMonthIndex = startOfMonth(displayedMonth).getDay();
  const emptyDays = numArrayOfLength(startOfMonthIndex); // the blank cells at the start of the month
  const daysOfMonth = [...emptyDays.map(() => undefined), ...daysInMonth];

  return {
    nextMonth,
    previousMonth,
    setDate,
    daysOfMonth,
    displayedMonth,
    date,
  };
};
