import React, { useEffect, useState } from "react";
import type * as Stitches from "@stitches/react";
import { mergeCss, styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";
import { Text } from "src/ccl/document/text";
import { Box, Flex } from "src/ccl/layout";

export type ValidationVariant = "error" | "warning" | "success";
interface ValidationProps {
  title: React.ReactNode;
  body?: string | React.ReactNode;
  variant: ValidationVariant;
  size?: "sm" | "lg";
  css?: Stitches.CSS;
  fadeAfterMs?: number;
  errorTitleCss?: Stitches.CSS;
}

const colorMap: Record<ValidationProps["variant"], keyof typeof tokens.colors> =
  {
    error: "red",
    warning: "orange",
    success: "green",
  };

const Container = styled(Flex, {
  alignItems: "stretch",
  fontWeight: "$regular",
  gap: "$4",
  mb: "$7",
  variants: {
    variant: {
      error: { borderLeftColor: colorMap["error"] },
      warning: { borderLeftColor: colorMap["warning"] },
      success: { borderLeftColor: colorMap["success"] },
    },
  },
});

export const ValidationBlock = ({
  variant,
  title,
  body,
  size = "lg",
  fadeAfterMs,
  css = {},
  errorTitleCss = {},
  ...props
}: ValidationProps) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (fadeAfterMs === undefined) {
      return;
    }

    const timeout = setTimeout(() => {
      setVisible(false);
    }, fadeAfterMs);

    return () => clearTimeout(timeout);
  }, []);

  const visibilityCss = fadeAfterMs
    ? {
        transition: "0.3s ease all",
        opacity: visible ? 1 : 0,
        overflow: "hidden",
      }
    : {};

  return (
    <Container variant={variant} css={mergeCss(visibilityCss, css)} {...props}>
      <Box
        css={{
          width: "3px",
          alignSelf: "stretch",
          backgroundColor: colorMap[variant],
          borderRadius: "4px",
        }}
      />
      <Flex
        css={{
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Text
          variant={size === "sm" ? "meta" : "body"}
          css={mergeCss(
            { ...(body && { fontWeight: "$bold" }) },
            errorTitleCss,
          )}
        >
          {title}
        </Text>
        {typeof body === "string" ? (
          <Text variant="meta" color="grey6">
            {body}
          </Text>
        ) : (
          body
        )}
      </Flex>
    </Container>
  );
};
