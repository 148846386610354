import { Action, action } from "easy-peasy";
import { updateLocalStorageItem, getLocalStorageItem } from "src/utils/storage";

export type AgencyTableViewMode = "detail" | "simple";

export interface AgencyTableModePreferenceModel {
  preference: AgencyTableViewMode;
  setPreference: Action<AgencyTableModePreferenceModel, AgencyTableViewMode>;
}

const localStorageKey = "agencyTableMode";
const initialLocalStorageValue =
  getLocalStorageItem<AgencyTableViewMode>(localStorageKey);

export const agencyTableModePreferenceModel: AgencyTableModePreferenceModel = {
  preference: initialLocalStorageValue || "simple",
  setPreference: action((state, newPreference) => {
    state.preference = newPreference;
    updateLocalStorageItem(localStorageKey, newPreference);
  }),
};
