import React from "react";
import * as Stitches from "@stitches/react";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { styled, mergeCss } from "src/ccl/stitches";
import { SortDirection } from "src/graphql/types";
import { sendToAmplitude } from "src/utils/analytics";

export interface TableHeaderItemProps {
  widthPercentage: number;
  fieldName?: string;
  active?: boolean;
  currentSortDirection?: SortDirection;
  onSortChange?: (sortField: string, sortDirection: SortDirection) => void;
  css?: Stitches.CSS;
  children?: React.ReactNode;
  defaultDirection?: SortDirection;
  amplitudeEvent?: string;
}

const HeaderColumnWrapper = styled(Flex, {
  paddingY: "$2",
  fontSize: "$12",
  textTransform: "uppercase",
  fontWeight: "bold",
  alignItems: "center",
  paddingRight: "$3",
  "&:first-child": {
    paddingLeft: 2,
  },
});

export const TableHeaderItem = ({
  widthPercentage,
  fieldName,
  active = false,
  onSortChange,
  currentSortDirection = SortDirection.Asc,
  children,
  css = {},
  defaultDirection = SortDirection.Desc,
  amplitudeEvent,
}: TableHeaderItemProps) => {
  let iconVariant: AvailableIcon = "sortDefault";
  if (active && currentSortDirection === SortDirection.Asc) {
    iconVariant = "sortAsc";
  } else if (active && currentSortDirection === SortDirection.Desc) {
    iconVariant = "sortDesc";
  }

  return (
    <HeaderColumnWrapper
      css={mergeCss(
        {
          width: `${widthPercentage}%`,
          py: "$2",
          cursor: onSortChange ? "pointer" : "initial",
        },
        css,
      )}
      onClick={() => {
        if (!onSortChange || !fieldName) {
          return;
        }

        onSortChange(
          fieldName,
          active
            ? currentSortDirection === SortDirection.Asc
              ? SortDirection.Desc
              : SortDirection.Asc
            : defaultDirection,
        );
        if (amplitudeEvent && currentSortDirection) {
          sendToAmplitude(amplitudeEvent, { sortApplied: fieldName });
        }
      }}
    >
      {onSortChange && fieldName && (
        <Icon
          variant={iconVariant}
          color={active ? "black" : "grey6"}
          size={16}
        />
      )}

      <Text
        css={{
          color: active ? "black" : "grey6",
          ml: "$2",
        }}
      >
        {children}
      </Text>
    </HeaderColumnWrapper>
  );
};
