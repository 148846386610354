import React, { ReactNode, useState } from "react";
import type * as Stitches from "@stitches/react";
import { Flex, Box } from "src/ccl/layout";
import { Text, Icon } from "src/ccl/document";
import { mergeCss, styled } from "src/ccl/stitches";

export interface BaseFilterProps {
  name?: string;
  label: ReactNode;
  onReset?: () => void;
  expanded?: boolean;
  labelCss?: Stitches.CSS;
  filterCss?: Stitches.CSS;
  wrapperCss?: Stitches.CSS;
  helperText?: string;
  onExpand?: () => void;
  onCollapse?: () => void;
  labelSuffix?: ReactNode;
  children?: React.ReactNode;
}

const Wrapper = styled(Box, {
  py: "$3",
  borderTopWidth: 1,
  borderTopColor: "$grey3",
  borderTopStyle: "solid",
  "&:first-child": {
    borderTopStyle: "none",
  },
});

export const BaseFilter = ({
  label,
  onReset,
  expanded: initiallyExpanded = false,
  wrapperCss = {},
  labelCss = {},
  filterCss = {},
  children,
  helperText,
  onExpand = () => {},
  onCollapse = () => {},
  labelSuffix,
}: BaseFilterProps) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  const expand = () => {
    setExpanded(true);
    onExpand();
  };
  const collapse = () => {
    setExpanded(false);
    onCollapse();
  };

  return (
    <Wrapper css={mergeCss({ px: "$6", py: "$4" }, wrapperCss)}>
      <Flex
        css={mergeCss(
          {
            px: "$6",
            py: "$2",
            cursor: "pointer",
            alignItems: "center",
          },
          labelCss,
        )}
        onClick={() => (expanded ? collapse() : expand())}
      >
        <Flex css={{ gap: "$4" }}>
          <Text variant="nh4">{label}</Text>
          {labelSuffix && (
            <Box
              css={{
                backgroundColor: "$black",
                color: "$white",
                borderRadius: "$round",
                width: 26,
                textAlign: "center",
                pt: "$1",
              }}
            >
              {labelSuffix}
            </Box>
          )}
        </Flex>

        {onReset && expanded && (
          <Text
            data-test-id={`${label}-filter-reset-button`}
            variant="meta"
            css={{
              pl: "$4",
              color: "$grey6",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onReset && onReset();
            }}
          >
            Clear filter
          </Text>
        )}

        <Text
          data-test-id={`${label}-filter-collapse-button`}
          variant="meta"
          css={{
            color: "$grey6",
            marginLeft: "auto",
            cursor: "pointer",
          }}
        >
          {expanded ? (
            <Icon variant="chevronUp" />
          ) : (
            <Icon variant="chevronDown" />
          )}
        </Text>
      </Flex>

      {expanded && (
        <>
          {helperText && (
            <Flex css={{ mr: -5, py: "$2" }}>
              <Icon
                variant="information"
                color="grey6"
                size={20}
                css={{ pr: "$2" }}
              />
              <Text variant="meta" color="grey6">
                {helperText}
              </Text>
            </Flex>
          )}
          <Box css={mergeCss({ py: "$3", px: "$6" }, filterCss)}>
            {children}
          </Box>
        </>
      )}
    </Wrapper>
  );
};
