import React, { Dispatch } from "react";
import type * as Stitches from "@stitches/react";
import { camelCase } from "lodash";
import { Box, Flex } from "src/ccl/layout";
import {
  Filter,
  FilterKind,
  QueryVerticalTalentSearchArgs,
  FilterContext,
  DataType,
  Gender,
} from "src/graphql/types";
import {
  FilterGroup,
  RangeFilter,
  TextFilter,
  MultiSelectFilter,
  MultiSelectFilterOption,
  SelectReactFilter,
  PreferenceToggleFilter,
  SelectFilterOption,
} from "src/ccl/filtering";
import { uppercaseFirstLetter } from "src/utils/lang";
import {
  SearchParamsAction,
  SearchParamsActionKind,
} from "src/hooks/useVerticalTalentSearchParams";
import { LocationFilter } from "src/components/filterableTalentGrid";
import { mergeCss } from "src/ccl/stitches";

const locationOptions: MultiSelectFilterOption[] = [
  {
    label: "Current",
    value: "PRIMARY_ONLY",
    icon: "location",
  },
  {
    label: "Can travel to",
    value: "ADDITIONAL_ONLY",
    icon: "travelLocation",
  },
];

const transformValues = (options: MultiSelectFilterOption[]) =>
  options.map((opts) => ({
    ...opts,
    ...{ value: opts.value.toUpperCase().replace("-", "_") },
  }));

const getArray = (minValue: number, maxValue: number, step = 1) => {
  const res = [];
  for (let i = minValue; i <= maxValue; i += step) {
    res.push(i);
  }
  return res;
};

const rangeFilter = (
  filter: Filter,
  setSearchParams: Dispatch<SearchParamsAction>,
  searchParams: QueryVerticalTalentSearchArgs,
  expanded: boolean,
) => {
  const minKey = camelCase(`min_${filter.name}`);
  const maxKey = camelCase(`max_${filter.name}`);
  const minValue =
    searchParams[minKey as keyof QueryVerticalTalentSearchArgs] ||
    searchParams.fields?.find((f) => f.name === filter.name)?.value[0];

  const maxValue =
    searchParams[maxKey as keyof QueryVerticalTalentSearchArgs] ||
    searchParams.fields
      ?.find((f) => f.name === filter.name)
      ?.value.slice(-1)[0];

  const handleSet = (nextMin: number, nextMax: number) => {
    if (filter.verticalSpecific) {
      setSearchParams({
        kind: SearchParamsActionKind.SetVerticalSpecific,
        payload: [{ name: filter.name, value: getArray(nextMin, nextMax) }],
      });
    } else {
      setSearchParams({
        kind: SearchParamsActionKind.SetGeneric,
        payload: {
          [minKey]: nextMin,
          [maxKey]: nextMax,
        },
      });
    }
  };

  const handleReset = () => {
    if (filter.verticalSpecific) {
      setSearchParams({
        kind: SearchParamsActionKind.ResetVerticalSpecific,
        payload: [filter.name],
      });
    } else {
      setSearchParams({
        kind: SearchParamsActionKind.ResetGeneric,
        payload: [minKey, maxKey],
      });
    }
  };

  return (
    <RangeFilter
      name={filter.name}
      label={filter.label}
      minimum={filter.minValue || 0}
      maximum={filter.maxValue || 1}
      values={[minValue || filter.minValue, maxValue || filter.maxValue]}
      expanded={expanded || minValue || maxValue}
      onChange={handleSet}
      onReset={handleReset}
      labelCss={{
        px: 0,
        py: 0,
      }}
      filterCss={{
        pb: 0,
        pt: "$3",
        px: 0,
      }}
      sliderPrefix={filter.rangeLabelPrefix}
      imperialMeasurementDisplay={filter.imperialMeasurementDisplay}
      metricMeasurementDisplay={filter.metricMeasurementDisplay}
      helperText={filter.helperText}
    />
  );
};

const selectFilter = (
  filter: Filter,
  setSearchParams: Dispatch<SearchParamsAction>,
  searchParams: QueryVerticalTalentSearchArgs,
  expanded: boolean,
  getResetDependencies?: (gender: Gender) => string[],
) => {
  let param = filter.verticalSpecific
    ? searchParams.fields?.find((f) => f.name === filter.name)?.value
    : searchParams[filter.name as keyof QueryVerticalTalentSearchArgs];
  if (!param) {
    param = "all";
  }

  const value =
    param &&
    filter.options &&
    filter.options.find((o) => o.value === param.toString());

  const handleReset = () => {
    const resetActionKind = filter.verticalSpecific
      ? SearchParamsActionKind.ResetVerticalSpecific
      : SearchParamsActionKind.ResetGeneric;

    setSearchParams({
      kind: resetActionKind,
      payload: [filter.name],
    });
  };

  const handleSet = (option?: SelectFilterOption) => {
    if (!option && !filter.isClearable) {
      return;
    }
    const setActionKind = filter.verticalSpecific
      ? SearchParamsActionKind.SetVerticalSpecific
      : SearchParamsActionKind.SetGeneric;

    const value =
      filter.dataType === DataType.Integer
        ? parseInt(option?.value || "")
        : option?.value;

    if (!value || value === "all") {
      handleReset();
    } else {
      const payload = filter.verticalSpecific
        ? [{ name: filter.name, value: value }]
        : { [filter.name]: value };

      if (getResetDependencies) {
        setSearchParams({
          kind: SearchParamsActionKind.ResetVerticalSpecific,
          payload: getResetDependencies(value as Gender),
        });
      }

      setSearchParams({
        kind: setActionKind,
        payload: payload,
      });
    }
  };

  return (
    <SelectReactFilter
      value={value}
      expanded={expanded || (value && value.label !== "All")}
      name={filter.name}
      id={filter.name}
      label={filter.label}
      isClearable={filter.isClearable}
      options={filter.options || []}
      onChange={handleSet}
      onReset={handleReset}
      labelCss={{
        px: 0,
        py: 0,
      }}
      filterCss={{
        pb: 0,
        pt: "$3",
        px: 0,
      }}
      helperText={filter.helperText}
    />
  );
};

const textFilter = (
  filter: Filter,
  setSearchParams: Dispatch<SearchParamsAction>,
  searchParams: QueryVerticalTalentSearchArgs,
  expanded: boolean,
) => {
  const param =
    searchParams[filter.name as keyof QueryVerticalTalentSearchArgs];
  const value = param || "";

  const handleSet = (value: string) => {
    const setActionKind = filter.verticalSpecific
      ? SearchParamsActionKind.SetVerticalSpecific
      : SearchParamsActionKind.SetGeneric;
    const payload = filter.verticalSpecific
      ? [{ name: filter.name, value: value }]
      : { [filter.name]: value };
    setSearchParams({
      kind: setActionKind,
      payload: payload,
    });
  };

  const handleReset = () => {
    const resetActionKind = filter.verticalSpecific
      ? SearchParamsActionKind.ResetVerticalSpecific
      : SearchParamsActionKind.ResetGeneric;

    setSearchParams({
      kind: resetActionKind,
      payload: [filter.name],
    });
  };

  return (
    <TextFilter
      value={value}
      name={filter.name}
      label={uppercaseFirstLetter(filter.name)}
      expanded={expanded || value !== ""}
      onChange={handleSet}
      onReset={handleReset}
      filterCss={{
        px: 0,
        pb: 0,
      }}
      labelCss={{
        px: 0,
      }}
      helperText={filter.helperText}
    />
  );
};

const multiSelectFilter = (
  filter: Filter,
  setSearchParams: Dispatch<SearchParamsAction>,
  searchParams: QueryVerticalTalentSearchArgs,
  expanded: boolean,
) => {
  let values;
  if (filter.verticalSpecific) {
    values = searchParams?.fields?.find((f) => f.name === filter.name)?.value;
  } else {
    values = searchParams[filter.name as keyof QueryVerticalTalentSearchArgs];
  }

  const handleSet = (values?: string[]) => {
    if (!values) {
      return;
    }
    const filterValues =
      filter.dataType === DataType.Integer
        ? values.map((v: string) => parseInt(v))
        : values;

    const setActionKind = filter.verticalSpecific
      ? SearchParamsActionKind.SetVerticalSpecific
      : SearchParamsActionKind.SetGeneric;

    const payload = filter.verticalSpecific
      ? [{ name: filter.name, value: filterValues }]
      : { [filter.name]: filterValues };
    setSearchParams({
      kind: setActionKind,
      payload: payload,
    });
  };

  const handleReset = () => {
    const resetActionKind = filter.verticalSpecific
      ? SearchParamsActionKind.ResetVerticalSpecific
      : SearchParamsActionKind.ResetGeneric;

    setSearchParams({
      kind: resetActionKind,
      payload: [filter.name],
    });
  };

  return (
    <MultiSelectFilter
      name={filter.name}
      label={uppercaseFirstLetter(filter.label)}
      expanded={expanded || !!values}
      onChange={handleSet}
      onReset={handleReset}
      options={filter.options as MultiSelectFilterOption[]}
      filterCss={{
        px: 0,
        pb: 0,
      }}
      labelCss={{
        px: 0,
      }}
      helperText={filter.helperText}
      values={values || filter.defaultOptions || []}
    />
  );
};

const filterGroup = (
  filter: Filter,
  setSearchParams: Dispatch<SearchParamsAction>,
  searchParams: QueryVerticalTalentSearchArgs,
  expanded: boolean,
) => {
  const hasChildValues = !!filter.childFilters?.some((cf) => {
    return !!searchParams.fields?.find((f) => f.name === cf.name)?.value;
  });
  const hasChildGroups = !!filter.childFilters?.some((cf) => {
    return cf.kind === FilterKind.FilterGroup;
  });

  return (
    <FilterGroup
      title={filter.label || ""}
      expandedTitle={
        filter.expandedTitleFilter
          ? renderFilter(
              filter.expandedTitleFilter,
              setSearchParams,
              searchParams,
              false,
            )
          : filter.label
      }
      expanded={expanded || hasChildValues}
      onCollapse={() => {
        setSearchParams({
          kind: SearchParamsActionKind.ResetVerticalSpecific,
          payload: filter.childFilters?.map((f) => f.name),
        });
      }}
      containerCss={{
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: hasChildGroups ? "$grey1" : undefined,
      }}
    >
      {filter.childFilters?.map((filter, i) => (
        <Box key={i}>
          {searchParams.gender &&
          filter.excludedGenders?.includes(searchParams.gender) ? (
            <></>
          ) : (
            renderFilter(filter, setSearchParams, searchParams, false)
          )}
        </Box>
      ))}
    </FilterGroup>
  );
};

const preferenceToggleFilter = (filter: Filter) => (
  <PreferenceToggleFilter
    name={filter.name}
    textUnchecked={filter.labelUnchecked || ""}
    textChecked={filter.labelChecked || ""}
    checkedValue={filter.checkedValue || ""}
    uncheckedValue={filter.uncheckedValue || ""}
  />
);

const renderFilter = (
  filter: Filter,
  setSearchParams: Dispatch<SearchParamsAction>,
  searchParams: QueryVerticalTalentSearchArgs,
  expanded: boolean,
  getResetDependencies?: (gender: Gender) => string[],
) => {
  switch (filter.kind) {
    case FilterKind.Range:
      return rangeFilter(filter, setSearchParams, searchParams, expanded);
    case FilterKind.Select:
      return selectFilter(
        filter,
        setSearchParams,
        searchParams,
        expanded,
        getResetDependencies,
      );
    case FilterKind.Text:
      return textFilter(filter, setSearchParams, searchParams, expanded);
    case FilterKind.MultiSelect:
      return multiSelectFilter(filter, setSearchParams, searchParams, expanded);
    case FilterKind.FilterGroup:
      return filterGroup(filter, setSearchParams, searchParams, expanded);
    case FilterKind.Toggle:
      return preferenceToggleFilter(filter);
    default:
      return <></>;
  }
};

interface VerticalFilterProps {
  filters: Filter[];
  setSearchParams: Dispatch<SearchParamsAction>;
  searchParams: QueryVerticalTalentSearchArgs;
  context: FilterContext;
  expanded?: boolean;
  css?: Stitches.CSS;
}

export const VerticalFilters = ({
  filters,
  searchParams,
  setSearchParams,
  context,
  expanded = true,
  css = {},
}: VerticalFilterProps) => {
  const isInContext = (filter: Filter) => filter.contexts.includes(context);
  const getResetDependencies = (gender: Gender) => {
    const genderedFilters =
      filters
        .find((f) => f.name === "measurements")
        ?.childFilters?.filter((f) => f.excludedGenders?.includes(gender)) ||
      [];
    return genderedFilters.map((f) => f.name);
  };

  return (
    <Flex css={mergeCss({ flexDirection: "column" }, css)}>
      {filters.filter(isInContext).map((filter, i) => (
        <Box key={i}>
          {filter.name === "location" ? (
            <LocationFilter
              location={searchParams.location || ""}
              locationContext={searchParams.locationContext}
              name="location"
              label="Location"
              filterCss={{
                px: 0,
                pb: 0,
              }}
              labelCss={{
                px: 0,
              }}
              options={transformValues(locationOptions)}
              expanded={expanded}
              onLocationChange={(value) =>
                setSearchParams({
                  kind: SearchParamsActionKind.SetGeneric,
                  payload: {
                    location: value,
                  },
                })
              }
              onReset={() => {
                setSearchParams({
                  kind: SearchParamsActionKind.ResetGeneric,
                  payload: ["location", "locationContext"],
                });
              }}
              onContextChange={(context) => {
                setSearchParams({
                  kind: SearchParamsActionKind.SetGeneric,
                  payload: {
                    locationContext: context,
                  },
                });
              }}
            />
          ) : filter.name === "gender" ? (
            renderFilter(
              filter,
              setSearchParams,
              searchParams,
              expanded,
              getResetDependencies,
            )
          ) : (
            renderFilter(filter, setSearchParams, searchParams, expanded)
          )}
        </Box>
      ))}
    </Flex>
  );
};
