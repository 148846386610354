import React from "react";
import { Filter } from "src/graphql/types";
import { useURLStringSearchParam } from "src/hooks";
import {
  SelectFilterOption,
  SelectReactFilter,
} from "src/ccl/filtering/selectReactFilter";

export const UrlStateSelectFilter = ({
  expanded,
  filter,
}: {
  expanded: boolean;
  filter: Filter;
}) => {
  const [param, setParam] = useURLStringSearchParam(filter.name);

  const handleSet = (option?: SelectFilterOption) =>
    setParam(option?.value || "");
  if (!filter.options) {
    return <></>;
  }
  return (
    <SelectReactFilter
      wrapperCss={{ border: "unset" }}
      labelCss={{ px: "unset" }}
      filterCss={{ pt: "$3", px: "unset" }}
      isClearable={true}
      expanded={expanded || param !== ""}
      options={filter.options}
      label={filter.label}
      labelSuffix={param ? 1 : undefined}
      onChange={handleSet}
      value={filter.options.find(({ value }) => value === param)}
      maxMenuHeight={200}
    />
  );
};
