import { action, Action, Thunk, thunk } from "easy-peasy";
import { ApolloError } from "@apollo/client";
import { requestModel, RequestModel } from "./requestModel";
import { StoreModel } from ".";
import { Query, VerticalConfiguration } from "src/graphql/types";
import { graphQLClient } from "src/graphql/client";
import { VERTICAL_CONFIGURATION } from "src/graphql/queries/VerticalConfiguration";

export interface VerticalConfigurationsModel
  extends RequestModel<VerticalConfigurationsModel> {
  configurations: VerticalConfiguration[] | null;
  setConfigurations: Action<
    VerticalConfigurationsModel,
    VerticalConfiguration[] | null
  >;
  request: Thunk<
    VerticalConfigurationsModel,
    { force?: boolean } | undefined,
    void,
    StoreModel
  >;
  reset: Thunk<VerticalConfigurationsModel>;
}

export const verticalConfigurationsModel: VerticalConfigurationsModel = {
  ...requestModel,
  configurations: null,
  setLoading: action((state) => {
    state.configurations = null;
    state.status = "loading";
  }),
  setConfigurations: action((state, payload) => {
    state.configurations = payload;
    state.status = "loaded";
  }),
  request: thunk(async (actions, payload, { getStoreState }) => {
    const force = payload === undefined ? false : payload.force;
    if (getStoreState().verticalConfigurations.configurations && !force) {
      return;
    }

    actions.setLoading();

    try {
      const result = await graphQLClient.query<Query>({
        query: VERTICAL_CONFIGURATION,
      });

      if (result.error) {
        throw result.error;
      }

      const configurations = result.data.verticalConfigurations;

      if (configurations) {
        actions.setConfigurations(configurations);
      } else {
        throw new Error("No configurations found");
      }
    } catch (e) {
      if (e instanceof Error || e instanceof ApolloError) {
        actions.setError(e.message);
      } else {
        actions.setError("Something went wrong");
      }
    }
  }),
  reset: thunk((actions) => {
    actions.setConfigurations(null);
    actions.request();
  }),
};
