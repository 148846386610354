import React from "react";
import {
  CreateNewListItem,
  DroppableListItem,
  Icon,
  Text,
} from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import addImage from "src/assets/icons/addImage.svg";
import { SelectReact } from "src/ccl/data-entry";
import { Button } from "src/ccl/navigation";
import { PortfolioImage, PortfolioImageCategory } from "src/graphql/types";

export const PortfolioCategoryList = ({
  portfolioImages,
  portfolioCategories,
  activeCategory,
  onSelectCategory,
  onCreateCategory,
  onDeleteCategory,
  onEditCategory,
  isSelecting,
}: {
  portfolioImages: PortfolioImage[];
  portfolioCategories: PortfolioImageCategory[];
  activeCategory?: PortfolioImageCategory;
  onSelectCategory: (category?: PortfolioImageCategory) => void;
  onCreateCategory: () => void;
  onDeleteCategory: () => void;
  onEditCategory: () => void;
  isSelecting: boolean;
}) => {
  const selectCategoryByName = (selectedName?: string) =>
    onSelectCategory(
      portfolioCategories?.find(({ name }) => name === selectedName),
    );

  return (
    <Box>
      <Box css={{ "@bp2": { display: "none" } }}>
        <Text css={{ fontWeight: "$bold", pb: "$5" }}>Category</Text>
        <Flex css={{ gap: "$4" }}>
          <SelectReact
            boxCss={{ opacity: isSelecting ? 0.5 : undefined }}
            isDisabled={isSelecting}
            value={{
              value: activeCategory?.name || "",
              label: activeCategory?.name || "All photos",
            }}
            onChange={(option) => {
              if (option?.value === "new") {
                onCreateCategory();
              } else {
                selectCategoryByName(option?.value);
              }
            }}
            variant="rebrand"
            options={[
              { label: "Add new category", value: "new" },
              { label: "All photos", value: "" },
            ].concat(
              portfolioCategories?.map((category) => ({
                label: category.name,
                value: category.name,
              })) || [],
            )}
          />
          {activeCategory && (
            <Button
              data-test-id="EditCategoryButton"
              variant="secondary"
              css={{ borderRadius: "$md" }}
              onClick={onEditCategory}
            >
              <Icon variant="pencil" color="grey6" size={20} />
            </Button>
          )}
        </Flex>
      </Box>
      <Flex
        css={{
          display: "none",
          "@bp2": {
            display: "flex",
          },
          flexDirection: "column",
          gap: "$5",
          width: 284,
          pr: "$7",
        }}
      >
        <Text css={{ fontWeight: "$bold" }}>Category</Text>
        <Grid
          css={{
            gridColumns: "16px 1fr",
            gridColumnGap: "$5",
            display: "none",
            "@bp2": { display: "grid" },
          }}
        >
          <Icon variant="dragArrow" css={{ mt: "$2" }} color="grey6" />
          <Text color="grey6" variant="meta">
            Drag and drop photos to organise them into categories
          </Text>
        </Grid>
        <Flex
          css={{
            pr: "$3",
            flexDirection: "column",
            gap: "$4",
            maxHeight: "50vh",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "$grey3",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "$grey2",
              borderRadius: "5px",
              height: "81px",
            },
          }}
        >
          <CreateNewListItem
            onCreateNewItem={onCreateCategory}
            cta="Add new category"
            boxHeight={60}
            disabled={isSelecting}
          />
          <DroppableListItem
            disabled={isSelecting}
            isActive={!activeCategory}
            imageSize={60}
            image={portfolioImages?.[0]?.mediaUrl}
            name="All photos"
            count={portfolioImages?.length}
            entity="photo"
            onClick={() => !isSelecting && onSelectCategory(undefined)}
            emptyIcon={addImage}
          />
          {(portfolioCategories || []).map((category, i) => (
            <DroppableListItem
              key={i}
              onEdit={onEditCategory}
              onDelete={onDeleteCategory}
              isActive={activeCategory?.name === category.name}
              imageSize={60}
              name={category.name}
              image={category.portfolioImages?.[0]?.mediaUrl}
              count={category.portfolioImages.length}
              entity="photo"
              onClick={() => !isSelecting && onSelectCategory(category)}
              emptyIcon={addImage}
              disabled={isSelecting}
            />
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};
