import React from "react";
import { Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import {
  jobBloomBookingFee,
  jobPlatformFee,
  jobPriceForCreatives,
  jobTotalEstimateValue,
  jobTotalVatValue,
  jobTransactionFee,
} from "src/utils/job";
import { Button, Link } from "src/ccl/navigation";
import { isVerticalMember } from "src/utils/user";
import { formatCurrency } from "src/utils/currencyFormatter";
import { Job, JobTalent } from "src/graphql/types";

const SubFee = ({
  title,
  formattedAmount,
}: {
  title: string;
  formattedAmount: string;
}) => (
  <Flex
    css={{ justifyContent: "space-between" }}
    data-test-id={`ConfirmJob-${title.replace(" ", "-")}`}
  >
    <Text color="grey4" variant="meta">
      {title}
    </Text>
    <Text color="grey4" variant="meta">
      {formattedAmount}
    </Text>
  </Flex>
);

interface FeesBreakdownProps {
  job: Job;
  selectedJobTalent: JobTalent[];
  onClickHowFeesWork: () => void;
  onCancel: () => void;
  onContinue: () => void;
}

export const FeesBreakdown = ({
  job,
  selectedJobTalent,
  onClickHowFeesWork,
  onCancel,
  onContinue,
}: FeesBreakdownProps) => {
  const relevantBudgets = job.jobVerticals.filter(({ vertical }) =>
    selectedJobTalent.some(({ talent }) => isVerticalMember(vertical, talent)),
  );

  const feeValues = relevantBudgets.map((vb) => {
    const confirmedCount = selectedJobTalent.filter(({ talent }) =>
      isVerticalMember(vb.vertical, talent),
    ).length;

    return (vb.budget || 0) * confirmedCount;
  });

  const totalFees =
    jobBloomBookingFee(feeValues) +
    jobPlatformFee(feeValues) +
    jobTransactionFee(feeValues);

  return (
    <>
      <Text variant="h2" css={{ mb: "$9" }}>
        Confirm Job
      </Text>
      <Flex
        css={{
          borderColor: "$grey2",
          borderWidth: 1,
          borderStyle: "solid",
          gap: "$4",
          p: "$6",
          flexDirection: "column",
          mb: "$10",
        }}
      >
        <Flex
          css={{ justifyContent: "space-between" }}
          data-test-id="ConfirmJobCreativeFees"
        >
          <Text>Total creative fees</Text>
          <Text>
            {formatCurrency(jobPriceForCreatives(feeValues), job.currency)}
          </Text>
        </Flex>

        <Flex
          css={{ justifyContent: "space-between" }}
          data-test-id="ConfirmJobFeesAndServices"
        >
          <Text>Fees and services</Text>
          <Text>{formatCurrency(totalFees, job.currency)}</Text>
        </Flex>

        <Flex
          css={{
            flexDirection: "column",
            gap: "$3",
            pl: "$4",
            pb: "$2",
            borderLeftColor: "$grey3",
            borderLeftWidth: 2,
            borderLeftStyle: "solid",
          }}
        >
          <SubFee
            title="Booking fee"
            formattedAmount={formatCurrency(
              jobBloomBookingFee(feeValues),
              job.currency,
            )}
          />
          <SubFee
            title="Platform fee"
            formattedAmount={formatCurrency(
              jobPlatformFee(feeValues),
              job.currency,
            )}
          />
          <SubFee
            title="Transaction fee"
            formattedAmount={formatCurrency(
              jobTransactionFee(feeValues),
              job.currency,
            )}
          />

          <Link
            to="#"
            onClick={() => onClickHowFeesWork()}
            data-test-id="HowFeesWorkLink"
          >
            <Text variant="meta" color="grey4">
              How our fees work
            </Text>
          </Link>
        </Flex>

        <Flex
          css={{ justifyContent: "space-between" }}
          data-test-id="ConfirmJobVat"
        >
          <Text>VAT</Text>
          <Text>
            {formatCurrency(jobTotalVatValue(feeValues), job.currency)}
          </Text>
        </Flex>

        {(job.extraFeesAmount || 0) > 0 && (
          <Flex
            css={{ justifyContent: "space-between" }}
            data-test-id="ConfirmJobAdditionalExpenses"
          >
            <Text>Additional expenses</Text>
            <Text
              css={{ justifySelf: "end" }}
              data-test-id="job-amount-extra-fees"
            >
              {formatCurrency(job.extraFeesAmount || 0, job.currency)}
            </Text>
          </Flex>
        )}

        <Flex
          css={{ justifyContent: "space-between" }}
          data-test-id="ConfirmJobTotal"
        >
          <Text weight="bold">Total fee</Text>
          <Text weight="bold">
            {formatCurrency(
              jobTotalEstimateValue(feeValues, job.extraFeesAmount),
              job.currency,
            )}
          </Text>
        </Flex>
      </Flex>

      <Flex css={{ gap: "$7", flexDirection: "column", mb: "$14" }}>
        <Text>
          You are confirming the selected creatives for your job. When you pay,
          it will finalise your job details, date, fee and creative selection.
        </Text>

        <Text weight="bold">
          You won&apos;t be able to edit the job after confirming but we may be
          able to make urgent changes for you.
        </Text>
      </Flex>

      <Flex css={{ justifyContent: "space-between" }}>
        <Button variant="secondary" onClick={onCancel}>
          Back
        </Button>

        <Button variant="primary" onClick={onContinue}>
          Continue
        </Button>
      </Flex>
    </>
  );
};
