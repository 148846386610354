import { startCase } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Button, Link } from "src/ccl/navigation";
import {
  LineItem,
  LineItemKind,
  OutboundPaymentState,
  PaymentBatchState,
} from "src/graphql/types";
import { useCountdown } from "src/hooks";
import { formatCurrency } from "src/utils/currencyFormatter";

interface AdditionalPaymentItemProps {
  lineItem: LineItem;
  onDelete?: (lineItemId: string) => void;
}
export const AdditionalPaymentItem: React.FC<AdditionalPaymentItemProps> = ({
  lineItem,
  onDelete,
}) => {
  const paymentFailed =
    lineItem.outboundPayment?.state === OutboundPaymentState.Failed;
  const paymentBatch = lineItem.outboundPayment?.paymentBatch;
  const paymentIsSent = paymentBatch?.state === PaymentBatchState.Sent;
  const batchIsSealed = paymentBatch?.state === PaymentBatchState.Sealed;
  const colour = paymentIsSent ? "grey6" : "black";

  const { secondsLeft, setCountdownSeconds } = useCountdown();
  const lineItemIdToBeDeletedRef = useRef<string | null>(null);
  const [shouldUndoDelete, setShouldUndoDelete] = useState<boolean>(false);
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (
      secondsLeft === 0 &&
      !shouldUndoDelete &&
      lineItemIdToBeDeletedRef.current !== null
    ) {
      onDelete?.(lineItemIdToBeDeletedRef.current);
      lineItemIdToBeDeletedRef.current = null; // Reset the ref value
    }
  }, [secondsLeft, shouldUndoDelete, onDelete]);

  const handleDelete = (lineItemId: string) => {
    setShouldUndoDelete(false);
    lineItemIdToBeDeletedRef.current = lineItemId;
    setCountdownSeconds(5);
  };

  const handleUndo = () => {
    setShouldUndoDelete(true);
    setCountdownSeconds(0);
  };

  let placeholderRightIcon = (
    <Box>
      {onDelete && lineItem.kind !== LineItemKind.JobPayment && (
        <Button onClick={() => handleDelete(lineItem.id)} variant="unstyled">
          <Icon variant="trashWithCircle" size={28} />
        </Button>
      )}
    </Box>
  );

  //   Need to update this when design is already available
  if (paymentFailed) {
    placeholderRightIcon = (
      <Flex css={{ my: "auto" }}>
        <Icon
          variant="cross"
          size={16}
          color="red"
          css={{ my: "auto", pr: 5 }}
        />
        <Text variant="meta" color="red">
          Payment failed In{" "}
          <Link to="/dashboard/agent/payments" css={{ color: "inherit" }}>
            Batch #{lineItem.outboundPayment?.paymentBatch?.id}
          </Link>
        </Text>
      </Flex>
    );
  }

  //   Need to update this when design is already available
  if (paymentIsSent) {
    placeholderRightIcon = (
      <Flex css={{ my: "auto" }}>
        <Icon
          variant="tick"
          size={16}
          color="green"
          css={{ my: "auto", pr: 5 }}
        />
        <Text variant="meta" color="green">
          Paid In{" "}
          <Link to="/dashboard/agent/payments" css={{ color: "inherit" }}>
            Batch #{lineItem.outboundPayment?.paymentBatch?.id}
          </Link>
        </Text>
      </Flex>
    );
  }

  //   Need to update this when design is already available
  if (batchIsSealed) {
    placeholderRightIcon = (
      <Flex css={{ my: "auto" }}>
        <Icon
          variant="lock"
          size={24}
          color="grey6"
          css={{ my: "auto", pr: 5 }}
        />
      </Flex>
    );
  }
  return (
    <Flex
      css={{
        alignItems: "center",
        borderBottom: "1px solid $grey3",
        py: "6px",
      }}
    >
      <Box css={{ width: "100%" }}>
        <Text color={colour} variant="modalB2" css={{ fontWeight: "$medium" }}>
          {formatCurrency(lineItem.amount, lineItem.currency)} -{" "}
          {startCase(lineItem.kind.toLowerCase().replace("_", " "))}
        </Text>

        <Text
          variant="modalB2"
          css={{ color: "$grey6", lineHeight: "22px", fontSize: "$14" }}
        >
          {lineItem.manuallyPaid && "Already paid: "}
          {startCase(lineItem.description)}
        </Text>
      </Box>

      {secondsLeft > 0 && (
        <Flex
          css={{
            alignItems: "center",
            width: "100%",
            flexShrink: "2",
            gap: "6px",
            cursor: "pointer",
          }}
          onClick={handleUndo}
        >
          <Icon variant="undoAlt" size={14} color="grey5" />
          <Text
            variant="b2Bold"
            css={{ fontSize: "14px", fontWeight: "$bold", color: "$grey5" }}
          >
            Undo ({`${secondsLeft}s`})
          </Text>
        </Flex>
      )}
      {secondsLeft === 0 && placeholderRightIcon}
    </Flex>
  );
};
