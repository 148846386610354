import { gql } from "@apollo/client";
import { JOB_VERTICAL_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const BOOKER_DASHBOARD_LIST_JOBS_QUERY = gql`
  query BookerDashboardListJobs(
    $filters: JobFilterInput
    $sortOrder: SortOrderInput
    $first: Int!
    $after: String
  ) {
    me {
      id
      jobs(
        filters: $filters
        sortOrder: $sortOrder
        first: $first
        after: $after
      ) @connection(key: "jobs", filter: ["filters", "sortOrder"]) {
        totalCount
        pageInfo {
          startCursor
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            slug
            brand
            state
            startDate
            endDate
            vertical
            unpaidInvoiceCount
            totalInvoiceCount
            paymentViaExternalPlatform
            ...JobVerticalDetails
            talent {
              id
              state
              email
              phoneNumber
              talent {
                id
                name
                slug
                featuredAsset {
                  mediaUrl
                }
                profile {
                  ... on TalentProfile {
                    id
                    agency {
                      name
                    }
                    vertical
                    location
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${JOB_VERTICAL_DETAILS_FRAGMENT}
`;
