import React from "react";
import { NoteContainer } from "./styles";
import { Text } from "src/ccl/document/text";
import { Icon } from "src/ccl/document/icon";
import { Button } from "src/ccl/navigation";

interface NoteProps {
  showDeleteButton?: boolean;
  deleteButtonDisabled?: boolean;
  onDelete?: () => void;
  heading?: React.ReactNode;
  children?: React.ReactNode;
  variant?: "v2";
}

export const Note = ({
  showDeleteButton = false,
  deleteButtonDisabled = false,
  onDelete = () => {},
  heading,
  children,
  variant,
}: NoteProps) => (
  <NoteContainer data-test-id="Note" variant={variant}>
    {showDeleteButton && (
      <Button
        variant="unstyled"
        data-test-id="DeleteNoteButton"
        css={{
          backgroundColor: "$black",
          borderRadius: "50%",
          width: 20,
          height: 20,
          cursor: "pointer",
          position: "absolute",
          top: "12px",
          right: "12px",
          outline: "none",
        }}
        onClick={onDelete}
        disabled={deleteButtonDisabled}
      >
        <Icon
          variant="deleteCircle"
          size={14}
          css={{ display: "block", margin: "auto" }}
        />
      </Button>
    )}
    {heading}
    <Text>{children}</Text>
  </NoteContainer>
);
