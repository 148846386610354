import React from "react";
import type * as Stitches from "@stitches/react";
import { ContentContainer, Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";

export interface SubNavProps {
  children: React.ReactNode;
  css?: Stitches.CSS;
  flexCss?: Stitches.CSS;
}

export const SubNav = ({ children, css = {}, flexCss = {} }: SubNavProps) => {
  return (
    <Flex
      css={mergeCss(
        { backgroundColor: "$black", justfiyContent: "center" },
        flexCss,
      )}
    >
      <ContentContainer
        variant="noGutter"
        css={mergeCss(
          {
            display: "flex",
            height: "100%",
            backgroundColor: "$black",
            marginBottom: "$2",
            width: "100%",
          },
          css,
        )}
      >
        {children}
      </ContentContainer>
    </Flex>
  );
};
