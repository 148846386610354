import React from "react";
import { BookerDashboardJobsTalent } from "./BookerDashboardJobsTalent";
import { JobStatePill } from "./JobStatePill";
import { JobDetails } from "./JobDetails";
import { JobTitle } from "./JobTitle";
import { BookerDashboardCreativeStatus } from "./BookerDashboardCreativeStatus";
import { Flex, Box } from "src/ccl/layout";
import { AvailableIcon, availableIcons, Icon, Text } from "src/ccl/document";
import { Job, JobTalent, TalentVertical } from "src/graphql/types";
import { jobCardTitleForBooker } from "src/utils/job";
import { verticalMap } from "src/utils/user";
import { styled } from "src/ccl/stitches";
import { BookerJobStateTheme } from "src/graphql/colors";
import { Pill } from "src/ccl/blocks";
import { uppercaseFirstLetter } from "src/utils/lang";
import { Link } from "src/ccl/navigation";
import { sendToAmplitude } from "src/utils/analytics";

export enum JobCardHeightWidthMap {
  "350px" = 323,
  "296px" = 505,
}

const iconMap: { [k in TalentVertical]: AvailableIcon } = {
  [TalentVertical.FashionModel]: "polaroids",
  [TalentVertical.Photographer]: "cameraPhoto",
  [TalentVertical.Hmua]: "hairStylist",
  [TalentVertical.Influencer]: "influencer",
};

const VerticalPill = ({
  variant,
  vertical,
}: {
  variant: AvailableIcon;
  vertical: string;
}) => (
  <Pill
    variant="rounded"
    css={{
      boxShadow: "none",
      border: "1px solid $black",
      background: "rgba(255,255,255,0)",
    }}
  >
    <Flex css={{ alignItems: "center" }}>
      <Icon variant={variant} size={16} />
      <Text css={{ ml: "$3" }}>{vertical}</Text>
    </Flex>
  </Pill>
);

const MultiVerticalPill = () => (
  <Pill
    variant="rounded"
    css={{
      boxShadow: "none",
      border: "1px solid $black",
      background: "rgba(255,255,255,0)",
    }}
  >
    <Flex css={{ alignItems: "center" }}>
      <Icon variant="excessiveShortlisting" size={16} />
      <Text css={{ ml: "$3" }}>Team booking</Text>
    </Flex>
  </Pill>
);

const JobCardContainer = styled(Link, {
  display: "flex",
  flexDirection: "column",
  textDecoration: "none",
  borderRadius: "9px",
  border: "1px solid $grey3",
  p: "$6",
  height: "350px",
  width: "323px",
  "@bp2": {
    width: "505px",
    height: "296px",
  },
  "&:hover": {
    border: "1px solid $grey6",
    boxShadow: "$subtle",
  },
  "&:active": {
    border: "1px solid $grey6",
    boxShadow: "unset",
  },
});

export const BookerDashboardJobCard = ({ job }: { job: Job }) => {
  const [, jobStateColorSecondary, jobStateColorTertiary] =
    BookerJobStateTheme[job.state];

  const firstJobVertical = job?.jobVerticals[0].vertical;
  const prettyVertical = uppercaseFirstLetter(verticalMap[firstJobVertical]);

  const jobTalent = job?.talent as JobTalent[];
  const jobVerticalsCount = job?.jobVerticals.length;

  return (
    <Box
      css={{
        background: `linear-gradient(45deg, ${jobStateColorSecondary} 0px, #FFFFFF 200px), #FFFFFF`,
      }}
      onClick={() =>
        sendToAmplitude("client dashboard - clicks latest jobs job card")
      }
    >
      <JobCardContainer
        to={`/dashboard/client/jobs/${job.slug}`}
        css={{
          "&:active": {
            background: "rgba(0,0,0,0.02)",
          },
        }}
        data-test-id={`job-${job.id}`}
      >
        <Flex css={{ flexDirection: "column", pl: "$3" }}>
          <JobTitle job={job} />
          <JobDetails job={job} />
        </Flex>
        <Flex
          css={{
            mt: "auto",
            flexWrap: "wrap",
            "@bp2": { flexWrap: "nowrap" },
          }}
        >
          <JobStatePill
            title={jobCardTitleForBooker(job)}
            backgroundColor={jobStateColorSecondary}
            textColor={jobStateColorTertiary}
          />
          {jobVerticalsCount === 1 ? (
            <VerticalPill
              vertical={prettyVertical}
              variant={iconMap[firstJobVertical] as keyof typeof availableIcons}
            />
          ) : (
            <MultiVerticalPill />
          )}
        </Flex>

        {job.talent && job.talent.length > 0 && (
          <Flex
            css={{
              mt: "auto",
              flexWrap: "wrap",
              "@bp2": { flexWrap: "nowrap" },
            }}
          >
            <BookerDashboardJobsTalent talent={jobTalent} job={job} />
            <BookerDashboardCreativeStatus
              jobState={job.state}
              creatives={job.talent}
              showMeta={true}
            />
          </Flex>
        )}
      </JobCardContainer>
    </Box>
  );
};
