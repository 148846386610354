import { useEffect, useState } from "react";
import { breakpoints } from "src/ccl";
/*
     The design of some pages calls for the postioning of the child elements
     to change dramatically between mobile and desktop. We could solve this by
     rendering some components twice and then `display: "none"`ing them in
     some breakpoints, but sometimes these components are pretty complex
     components that can call and mutations and stuff, so rendering them
     twice gives me the willies.
     
     Instead, lets reimplement CSS breakpoints in JS
*/
export const useIsMobile = (bp: number = breakpoints.bp2) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < bp);
  const handleResize = () => {
    if (window.innerWidth < bp !== isMobile) {
      setIsMobile(!isMobile);
    }
  };
  useEffect(() => {
    addEventListener("resize", handleResize);
    return () => removeEventListener("resize", handleResize);
  });
  return isMobile;
};
