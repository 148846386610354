import { gql } from "@apollo/client";
import { ADDRESS_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const UPDATE_BOOKING_COMPANY_MUTATION = gql`
  mutation UpdateBookingCompany(
    $name: String
    $companyRegistrationNumber: String
    $registeredAddress: AddressInput
    $tradingAddress: AddressInput
    $phoneNumber: String
    $companyEmailAddress: String
    $billingContact: String
    $freelancer: Boolean
  ) {
    updateBookingCompany(
      name: $name
      companyRegistrationNumber: $companyRegistrationNumber
      registeredAddress: $registeredAddress
      tradingAddress: $tradingAddress
      phoneNumber: $phoneNumber
      companyEmailAddress: $companyEmailAddress
      billingContact: $billingContact
      freelancer: $freelancer
    ) {
      name
      companyRegistrationNumber
      registeredAddress {
        ...AddressDetails
      }
      tradingAddress {
        ...AddressDetails
      }
      phoneNumber
      companyEmailAddress
      billingContact
      billingDetailsPresent
      freelancer
    }
  }
  ${ADDRESS_DETAILS_FRAGMENT}
`;
