import React from "react";
import { Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { PaymentsList } from "src/components/payments";
import { OutboundPayment } from "src/graphql/types";

interface PaymentsHistoryProps {
  payments: OutboundPayment[];
}

export const PaymentsHistory = ({ payments }: PaymentsHistoryProps) => (
  <>
    <Text variant={{ "@initial": "h3", "@bp2": "h2" }} css={{ mb: "$5" }}>
      Payments history
    </Text>

    <Box css={{ mt: "$9" }}>
      <PaymentsList
        payments={payments}
        emptyStateTitle={
          <Text>All your payments from Contact will be collated here.</Text>
        }
        fullStateTitle={
          <Text>
            All your payments from Contact are collated here. You can download a
            PDF remittance letter for each.
          </Text>
        }
      />
    </Box>
  </>
);
