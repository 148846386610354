import {
  closestCenter,
  CollisionDetection,
  pointerWithin,
  Modifier,
  MeasuringConfiguration,
  MeasuringStrategy,
  DropAnimation,
  Over,
} from "@dnd-kit/core";
import { getEventCoordinates, CSS } from "@dnd-kit/utilities";

export const pointerWithinOrClosestCenter: CollisionDetection = (args) => {
  const pointerCollisions = pointerWithin(args);

  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  return closestCenter(args).filter(
    (value) => value.data?.value && value.data?.value < 250,
  );
};

export const dropAnimationShrinkToTarget = (
  over: Over | null,
): DropAnimation => ({
  keyframes({ transform, active, dragOverlay }) {
    if (!over) {
      return [];
    }
    const overlayHeightOffset = (dragOverlay.rect.height / 2) * 0.3;
    const overlayWidthOffset = (dragOverlay.rect.width / 2) * 0.3;
    const top = over.rect.top - active.rect.top;
    const left = over.rect.left - active.rect.left;
    const xOffset = over.rect.width / 2;
    const yOffset = over.rect.height / 2;
    const x = left + xOffset - overlayWidthOffset;
    const y = top + yOffset - overlayHeightOffset;

    return [
      { transform: CSS.Transform.toString(transform.initial) },
      {
        transform: CSS.Transform.toString({
          scaleX: 0.3,
          scaleY: 0.3,
          x,
          y,
        }),
      },
    ];
  },
});

export const snapCenterToCursor: Modifier = ({
  activatorEvent,
  overlayNodeRect,
  transform,
}) => {
  if (overlayNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent);

    if (!activatorCoordinates) {
      return transform;
    }

    const offsetX = activatorCoordinates.x - overlayNodeRect.left;
    const offsetY = activatorCoordinates.y - overlayNodeRect.top;

    return {
      ...transform,
      x: transform.x + offsetX - overlayNodeRect.width / 2,
      y: transform.y + offsetY - overlayNodeRect.height / 2,
    };
  }

  return transform;
};

export const defaultMeasuring: MeasuringConfiguration = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};
