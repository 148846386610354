import { gql } from "@apollo/client";
import {
  JOB_DETAILS_FRAGMENT,
  JOB_DETAILS_SPLIT_ADDRESS_FRAGMENT,
} from "src/graphql/fragments";

export const UPDATE_JOB_MUTATION = gql`
  mutation UpdateJob(
    $jobId: ID!
    $virtual: Boolean
    $kind: JobKind
    $name: String
    $brand: String
    $location: String
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $description: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $postcode: String
    $country: String
    $deckFiles: [FileUploadInput!]
    $usage: String
    $invoiceBillingName: String
    $invoiceBillingAddress: String
    $excludeVat: Boolean
    $headcountEstimate: HeadcountEstimate
    $nudityType: NudityInvolvement
    $selfTapeRequired: Boolean
    $travelExpenseCover: Float
    $allTravelFeesCovered: Boolean
    $cateringProvided: Boolean
    $removeTalentIds: [ID!]
  ) {
    updateJob(
      jobId: $jobId
      virtual: $virtual
      kind: $kind
      name: $name
      brand: $brand
      location: $location
      startDate: $startDate
      endDate: $endDate
      description: $description
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      postcode: $postcode
      country: $country
      deckFiles: $deckFiles
      usage: $usage
      invoiceBillingName: $invoiceBillingName
      invoiceBillingAddress: $invoiceBillingAddress
      excludeVat: $excludeVat
      headcountEstimate: $headcountEstimate
      nudityType: $nudityType
      selfTapeRequired: $selfTapeRequired
      travelExpenseCover: $travelExpenseCover
      allTravelFeesCovered: $allTravelFeesCovered
      cateringProvided: $cateringProvided
      removeTalentIds: $removeTalentIds
    ) {
      ...JobDetails
      ...JobDetailsSplitAddress
    }
  }
  ${JOB_DETAILS_FRAGMENT}
  ${JOB_DETAILS_SPLIT_ADDRESS_FRAGMENT}
`;
