import { DropdownPill } from "src/ccl/blocks";
import { FilterOption } from "src/ccl/filtering";

interface Owner {
  label: string;
  value: string;
}

interface OwnerFilterProps {
  variant?: "dropdown" | "pill";
  owner: Owner | undefined;
  setOwner: (value: string) => void;
  ownerOptions: Owner[];
}

export const OwnerOptions = ({
  variant,
  owner,
  setOwner,
  ownerOptions,
}: OwnerFilterProps) => (
  <>
    <FilterOption
      variant={variant}
      key={`filter-option-clear`}
      label="All"
      checked={owner === undefined}
      onClick={() => setOwner("")}
    />
    {ownerOptions.map((option, i) => (
      <FilterOption
        variant={variant}
        key={`filter-option-${i}`}
        label={option.label}
        checked={owner?.value === option.value}
        onClick={() => setOwner(option.value)}
      />
    ))}
  </>
);

export const OwnerFilter = ({ ...props }: OwnerFilterProps) => {
  const active = props.owner !== undefined;
  return (
    <DropdownPill
      active={active}
      text="Owner"
      icon={active ? "newFacesInvert" : "newFaces"}
    >
      <OwnerOptions {...props} />
    </DropdownPill>
  );
};
