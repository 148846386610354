import { gql } from "@apollo/client";

export const AGENCY_DETAILS = gql`
  query Agency(
    $id: ID!
    $after: String
    $first: Int
    $agentName: String
    $roles: [AgentProfileRole!]
    $sortOrder: SortOrderInput
  ) {
    agency(id: $id) {
      id
      name
      slug
      location
      firstParty
      logo {
        id
        mediaUrl
      }
      coverPhoto {
        id
        mediaUrl
      }
      bio
      specialities
      pastClients
      instagramUsername
      tiktokUsername
      twitterUsername
      commissionPercentage
      lastCommisionRateHistoryUpdate
      paymentsEmail
      agentInvitations {
        id
        email
        createdAt
      }
      agents(
        after: $after
        first: $first
        agentName: $agentName
        roles: $roles
        sortOrder: $sortOrder
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          node {
            id
            name
            agentProfile {
              id
              role
              assets {
                mediaUrl
              }
            }
            createdAt
          }
        }
      }
      hideTalent
    }
  }
`;
