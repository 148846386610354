import { gql } from "@apollo/client";
import { ADDRESS_DETAILS_FRAGMENT } from "./AddressDetails";

export const BOOKING_COMPANY_DETAILS_FRAGMENT = gql`
  fragment BookingCompanyDetails on BookingCompany {
    name
    companyRegistrationNumber
    registeredAddress {
      ...AddressDetails
    }
    tradingAddress {
      ...AddressDetails
    }
    phoneNumber
    companyEmailAddress
    billingContact
    billingDetailsPresent
    freelancer
  }
  ${ADDRESS_DETAILS_FRAGMENT}
`;
