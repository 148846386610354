import React from "react";
import { Flex } from "src/ccl/layout";
import { Text, Icon } from "src/ccl/document";

export const CreateNewListItem = ({
  onCreateNewItem,
  cta,
  boxHeight = 70,
  disabled = false,
}: {
  onCreateNewItem: () => void;
  cta: string;
  boxHeight?: number;
  disabled?: boolean;
}) => (
  <Flex
    data-test-id="CreateNewListItem"
    css={{
      opacity: disabled ? 0.5 : undefined,
      borderRadius: "5px",
      background: "$grey1",
      alignItems: "center",
      gap: "$3",
      p: "$3",
      cursor: disabled ? "not-allowed" : "pointer",
      "&:hover": {
        backgroundColor: disabled ? undefined : "$grey2",
      },
    }}
    onClick={disabled ? undefined : onCreateNewItem}
  >
    <Flex
      css={{
        width: boxHeight,
        height: boxHeight,
        border: "1px dashed $grey4",
        borderRadius: "7px",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <Icon
        variant="plus"
        css={{
          width: 22,
          height: 22,
        }}
        color="grey4"
      />
    </Flex>
    <Text color="grey4" css={{ fontWeight: "$bold", fontSize: "14px" }}>
      {cta}
    </Text>
  </Flex>
);
