import { gql } from "@apollo/client";
import { INVOICE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const RESET_INVOICE_PAYMENT_METHOD_MUTATION = gql`
  mutation ResetInvoicePaymentMethod($invoiceId: ID!) {
    resetInvoicePaymentMethod(invoiceId: $invoiceId) {
      ...InvoiceDetails
    }
  }
  ${INVOICE_DETAILS_FRAGMENT}
`;
