import { snakeCase } from "snake-case";
import map from "map-obj"; // eslint-disable-line import/default

const transformKey = (input: string | number | symbol) => {
  if (input === "addressLine1") {
    return "address_line_1";
  }

  if (input === "addressLine2") {
    return "address_line_2";
  }

  return snakeCase(input.toString());
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const snakeCaseKeys = (input: any) =>
  map(input, (key, value) => [transformKey(key), value], { deep: true });
