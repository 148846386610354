import React from "react";
import { Avatar } from "src/ccl/blocks/avatar";
import { howItWorksList, resourcesList } from "src/utils/navbar";
import { AvailableIcon, Icon, LegacyLogo, Text } from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Link, LinkButton, SupportLauncher } from "src/ccl/navigation";
import { styled } from "src/ccl/stitches";
import greyCircle from "src/assets/icons/grey-circle.svg";
import { sendToAmplitude } from "src/utils/analytics";

const Backdrop = styled("div", {
  backgroundColor: "rgba(20, 20, 20, 0.8)",
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: "$501",
  backdropFilter: "blur(5px)",
  transition: "400ms ease all",
  opacity: 0,
  visibility: "hidden",

  variants: {
    state: {
      open: {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
});

const Wrapper = styled("div", {
  position: "fixed",
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "$white",
  zIndex: "$502",
  boxShadow: "0 0 40px rgba(0, 0, 0, 0.5)",
  minWidth: "275px",
  width: "80vw",
  boxSizing: "border-box",
  overflowY: "auto",
  transition: "400ms ease all",
  transform: "translate(calc(100% + 40px), 0)",
  visibility: "hidden",

  variants: {
    state: {
      open: {
        transform: "translate(0, 0)",
        visibility: "visible",
      },
    },
  },
});

const Fade = styled("div", {
  opacity: 0,
  transition: "400ms ease all",

  variants: {
    state: {
      open: {
        opacity: 1,
      },
    },
  },
});

const primaryLinkStyles = {
  textDecoration: "none",
  color: "$black",
  display: "flex",
  marginBottom: "$5",
  "&:hover": {
    color: "$grey3",
  },

  "&:last-child": {
    mb: 0,
  },
};

const PrimaryLink = styled("a", primaryLinkStyles);

const secondaryLinkStyles = {
  textDecoration: "none",
  color: "$black",
  display: "flex",
};

const SecondaryLink = styled(Link, secondaryLinkStyles);

const greyCircleStyle = {
  background: `url(${greyCircle})`,
  width: "30px",
  height: "30px",
  mr: "$4",
  alignItems: "center",
  justifyContent: "center",
};

const CloseLink = styled("button", {
  color: "$black",
  backgroundColor: "$white",
  border: "none",
  textDecoration: "$none",
  height: "fit-content",
  cursor: "pointer",
});

const FullDivider = styled("hr", {
  height: "1px",
  border: "none",
  backgroundColor: "$grey2",
});

const Divider = styled("hr", {
  height: "1px",
  border: "none",
  mx: "$5",
  backgroundColor: "$grey2",
});

interface SubMenuProps {
  title: string;
  children: React.ReactNode;
}

const SubMenu = ({ title, children }: SubMenuProps) => {
  return (
    <Box css={{ px: "$4", py: "$5" }}>
      <Text variant="mini" css={{ color: "$grey6", mb: "$6" }}>
        {title}
      </Text>
      {children}
    </Box>
  );
};

interface SubMenuLinkProps {
  icon: AvailableIcon;
  linkTo: string;
  title: string;
  onClick: () => void;
  openInNewWindow?: boolean;
}

const SubMenuLink = ({
  icon,
  linkTo,
  title,
  openInNewWindow = false,
  onClick,
}: SubMenuLinkProps) => {
  return (
    <PrimaryLink
      href={linkTo}
      onClick={onClick}
      target={openInNewWindow ? "_blank" : "_self"}
    >
      <Flex css={greyCircleStyle}>
        <Icon
          variant={icon}
          size={16}
          css={{
            display: "block",

            "@bp2": { display: "none" },
          }}
        />
      </Flex>
      <Text variant="$meta" css={{ fontWeight: "bold" }}>
        {title}
      </Text>
    </PrimaryLink>
  );
};
export interface NavigationPaneProps {
  open?: boolean;
  signedOut: boolean;
  booker: boolean;
  onClose?: () => void;
  onSignOut?: () => void;
  ctaInfo: { text?: string; link?: string };
  avatarImageUrl?: string;
  avatarInitials?: string;
}

export const NavigationPane = ({
  open = false,
  onClose = () => {},
  onSignOut,
  signedOut,
  ctaInfo,
  avatarImageUrl,
  avatarInitials,
  booker,
}: NavigationPaneProps) => {
  return (
    <Box>
      <Backdrop state={open ? "open" : undefined} onClick={onClose} />
      <Wrapper state={open ? "open" : undefined} data-test-id="MobileNavPane">
        <Fade state={open ? "open" : undefined}>
          <Flex
            css={{
              justifyContent: "space-between",
              alignItems: "center",
              px: "$4",
              py: "$4",
            }}
          >
            {signedOut ? (
              <LegacyLogo variant="bigMark" color="black" />
            ) : (
              <Avatar
                css={{
                  backgroundColor: "$grey2",
                  border: avatarImageUrl ? "none" : "2px solid $black",
                }}
                fontColor="black"
                initials={avatarInitials}
                imageUrl={avatarImageUrl}
              />
            )}
            <Flex css={{ flexDirection: "row", alignItems: "center" }}>
              <LinkButton
                variant="header"
                to={ctaInfo.link}
                data-test-id="MobileCTAButton"
              >
                {ctaInfo.text}
                <Icon variant="chevronRight" size={12} css={{ ml: "$4" }} />
              </LinkButton>
              <CloseLink
                onClick={onClose}
                data-test-id="CloseMobileNav"
                css={{ marginLeft: "$5" }}
              >
                <Icon variant="cross" color="black" size={16} />
              </CloseLink>
            </Flex>
          </Flex>
          <FullDivider />
          <Grid
            css={{
              gridColumns: 2,
              alignItems: "center",
              height: "$15",
            }}
          >
            <Flex
              css={{
                borderRight: "1px solid $grey3",
                height: "$8",
                justifyContent: "center",
              }}
            >
              <SupportLauncher
                data-test-id="BurgerNeedHelp"
                color="black"
                iconSize={16}
                css={{
                  marginRight: "auto",
                }}
                iconCss={{
                  display: "block",
                }}
              />
            </Flex>

            {signedOut && (
              <SecondaryLink
                to="/sign-in"
                onClick={() => sendToAmplitude("top nav - sign in")}
                css={{ justifyContent: "center" }}
              >
                <Icon
                  variant="user"
                  size={16}
                  css={{
                    display: "block",
                    mr: "$2",
                    "@bp2": { display: "none" },
                  }}
                />
                <Text variant="mini"> Sign in</Text>
              </SecondaryLink>
            )}
            {!signedOut && (
              <SecondaryLink
                to="/"
                onClick={(e) => {
                  e.preventDefault();

                  if (onSignOut) {
                    onSignOut();
                  }
                }}
                css={{ justifyContent: "center" }}
              >
                <Icon
                  variant="user"
                  size={16}
                  css={{
                    display: "block",
                    mr: "$2",
                    "@bp2": { display: "none" },
                  }}
                />
                <Text variant="mini"> Sign Out</Text>
              </SecondaryLink>
            )}
          </Grid>
          <FullDivider />
          <SubMenu title="How it works">
            {howItWorksList.map((item, key) => {
              return (
                <SubMenuLink
                  key={key}
                  icon={item.icon as AvailableIcon}
                  linkTo={item.linkTo}
                  title={item.title}
                  onClick={() => sendToAmplitude(`top nav - ${item.eventName}`)}
                />
              );
            })}
          </SubMenu>
          <Divider />
          <SubMenu title="Resources">
            {resourcesList.map((item, key) => {
              return (
                <SubMenuLink
                  key={key}
                  icon={item.icon as AvailableIcon}
                  linkTo={booker ? item.bookerLinkTo : item.linkTo}
                  title={item.title}
                  openInNewWindow={item.openInNewWindow}
                  onClick={() => sendToAmplitude(`top nav - ${item.eventName}`)}
                />
              );
            })}
          </SubMenu>

          {signedOut && (
            <>
              <FullDivider />
              <Box css={{ px: "$4", py: "$5" }}>
                <SecondaryLink
                  to="/for/agencies"
                  onClick={() => sendToAmplitude("top nav - for agencies")}
                  css={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text variant="mini" color="black">
                    For agencies
                  </Text>
                  <Icon variant="arrowRight" size={10} css={{ mr: "$2" }} />
                </SecondaryLink>
              </Box>
              <FullDivider />
            </>
          )}
        </Fade>
      </Wrapper>
    </Box>
  );
};
