import { AvailableIcon } from "src/ccl/document";
import { tokens } from "src/ccl/stitches/theme";
import {
  User,
  Assignee,
  TalentLocation,
  Pronouns,
  TalentVertical,
  VerticalConfiguration,
  Job,
  TalentProfile,
  JobTalent,
  JobTalentState,
  JobKind,
  CompanyHeadcountEstimate,
} from "src/graphql/types";

export const assigneeAvatarData = (user: User | undefined) => {
  let assignees: Assignee[] = [];

  if (user === undefined) {
    return assignees;
  }

  if (user?.agencyUsers) {
    assignees = user.agencyUsers;
  }

  if (user) {
    assignees = [
      {
        id: user.id,
        name: user.name,
        featuredAsset: user.featuredAsset,
        assignedLiveJobCount: user.assignedLiveJobCount,
      },
      ...assignees,
    ];
  }

  return assignees;
};

export const getFullTravelLocation: (
  t: string,
  additionalLocations: TalentLocation[],
) => string | undefined = (travelLocation, additionalLocations) => {
  const locations = (additionalLocations || []).filter((l) =>
    l.name.toLowerCase().startsWith(travelLocation.toLowerCase()),
  );

  if (locations.length === 0) {
    return;
  }

  return locations
    .sort((a, b) => a.name.length - b.name.length)[0]
    .name.split(",")[0];
};

export const pronounsMap = {
  [Pronouns.HeHim]: "He/Him",
  [Pronouns.SheHer]: "She/Her",
  [Pronouns.TheyThem]: "They/Them",
  [Pronouns.ZeHir]: "Ze/Hir",
  [Pronouns.HeThey]: "He/They",
  [Pronouns.SheThey]: "She/They",
};

export const legacyPronounsMap: Record<string, string> = {
  "He/Him": "he_him",
  "She/Her": "she_her",
  "They/Them": "they_them",
  "Ze/Hir": "ze_hir",
  "He/They": "he_they",
  "She/They": "she_they",
};

export const verticalMap = {
  [TalentVertical.FashionModel]: "model",
  [TalentVertical.Photographer]: "photographer",
  [TalentVertical.Hmua]: "hair & makeup artist",
  [TalentVertical.Influencer]: "influencer",
};

export const verticalJobTypeMap = {
  [TalentVertical.FashionModel]: "modelling",
  [TalentVertical.Photographer]: "photography",
  [TalentVertical.Hmua]: "hair & makeup",
  [TalentVertical.Influencer]: "influencer",
};

export const verticalIconMap: Record<TalentVertical, AvailableIcon> = {
  [TalentVertical.FashionModel]: "polaroids",
  [TalentVertical.Photographer]: "cameraPhoto",
  [TalentVertical.Hmua]: "hairStylist",
  [TalentVertical.Influencer]: "influencer",
};

export const verticalIconMapAlt: Record<string, AvailableIcon> = {
  [TalentVertical.FashionModel]: "polaroidsAlt",
  [TalentVertical.Photographer]: "cameraPhotoAlt",
  [TalentVertical.Hmua]: "hairStylistAlt",
  [TalentVertical.Influencer]: "influencer",
};

export const verticalIconMapAltOutline: Record<string, AvailableIcon> = {
  [TalentVertical.FashionModel]: "polaroidAltOutline",
  [TalentVertical.Photographer]: "cameraPhotoAltOutline",
  [TalentVertical.Hmua]: "hairStylistAltOutline",
  [TalentVertical.Influencer]: "influencer",
};

export const verticalIconBackgroundMap: Record<
  string,
  keyof typeof tokens.colors
> = {
  [TalentVertical.FashionModel]: "lightOrange",
  [TalentVertical.Photographer]: "lightGreen",
  [TalentVertical.Hmua]: "lightPurple",
  [TalentVertical.Influencer]: "lightPurple",
};

export const jobTypeMap: Record<string, string> = {
  [JobKind.InfluencerBrief]: "Influencer",
  [JobKind.StudentAndGradProject]: "Student project",
  [JobKind.Ecom]: "Ecommerce",
};

export const jobTypeIconMap: Record<string, AvailableIcon> = {
  [JobKind.InfluencerBrief]: "influencer",
  [JobKind.Fitting]: "fitting",
  [JobKind.Other]: "other",
  [JobKind.Gifting]: "gifting",
  [JobKind.StudentAndGradProject]: "studentProject",
  [JobKind.TestShoot]: "testShoot",
  [JobKind.MusicVideo]: "musicVideo",
  [JobKind.Lookbook]: "lookbook",
  [JobKind.Casting]: "casting",
  [JobKind.Campaign]: "campaign",
  [JobKind.Commercial]: "commerical",
  [JobKind.Ecom]: "ecom",
  [JobKind.Editorial]: "editorial",
  [JobKind.FashionWeek]: "fashionWeek",
  [JobKind.Events]: "events",
};

export const verticalEnum = (vertical: string) => {
  switch (vertical.replace(/%20/g, " ").toLowerCase()) {
    case "models":
      return TalentVertical.FashionModel;
    case "model":
      return TalentVertical.FashionModel;
    case "hair & makeup artists":
      return TalentVertical.Hmua;
    default:
      return vertical.replace(/s$/, "").toLocaleUpperCase() as TalentVertical;
  }
};

export const getConfigForVertical = (
  configs: VerticalConfiguration[],
  vertical: TalentVertical,
) => {
  return configs.find(
    (config: VerticalConfiguration) => config.name === vertical?.toLowerCase(),
  );
};

export const getVerticalConfigForJob = (
  configs: VerticalConfiguration[],
  job: Job,
) => {
  const talent = job.talent?.[0];
  if (!talent) {
    return undefined;
  }

  const profile = talent.talent.profile as TalentProfile;
  const vertical = profile.vertical;

  return getConfigForVertical(configs, vertical);
};

export const getDeliverableValuesFromConfig = (
  config: VerticalConfiguration | undefined,
) => {
  if (!config) {
    return [];
  }

  const field = config.jobCreationSchema.detailsFormFields.find(
    (field) => field.name === "Deliverables",
  );

  if (!field) {
    return [];
  }

  return field.props?.checkboxOptions || [];
};

export const getTalentVerticals = (talentUsers: User[]) =>
  Array.from(
    new Set(talentUsers.map((u) => (u.profile as TalentProfile).vertical)),
  );

export const confirmedVerticalMember = (
  vertical: TalentVertical,
  jobTalent: JobTalent,
): boolean => {
  const user = jobTalent.talent;
  return (
    isVerticalMember(vertical, user) &&
    [JobTalentState.Confirmed, JobTalentState.Paid].includes(jobTalent.state)
  );
};

export const isVerticalMember = (
  vertical: TalentVertical,
  user: User,
): boolean => (user.profile as TalentProfile).vertical === vertical;

export const companyHeadcountEstimateMap = {
  [CompanyHeadcountEstimate.OneToFive]: "1-5",
  [CompanyHeadcountEstimate.SixToTwenty]: "6-20",
  [CompanyHeadcountEstimate.TwentyOneToFifty]: "21-50",
  [CompanyHeadcountEstimate.FiftyOneToOneHundred]: "51-100",
  [CompanyHeadcountEstimate.OneHundredOneToFiveHundred]: "101-500",
  [CompanyHeadcountEstimate.AboveFiveHundred]: "500+",
};

export const surfacedFromOptions = [
  "Social media",
  "Through a friend",
  "Search engine (Google, Yahoo, Bing)",
  "Blog or publication",
  "Spoke to one of our team",
  "Other",
];

export const initialAccountValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  company: "",
  kind: "",
  role: "",
  headcountEstimate: "",
  website: "",
  surfacedFrom: "",
  password: "",
};

export const niceResponseTimeText = (avResponseTimeMinutes: number) => {
  if (avResponseTimeMinutes < 60) {
    return "an hour";
  } else if (avResponseTimeMinutes < 240) {
    return `${Math.ceil(avResponseTimeMinutes / 60)} hours`;
  } else if (avResponseTimeMinutes < 1440) {
    return "1 day";
  }
};

export const getBoundaries = (group: string) => {
  if (group === "New Faces") {
    return { lower: 300, middle: 600, upper: 601 };
  } else if (group === "Development") {
    return { lower: 400, middle: 800, upper: 801 };
  } else if (group === "Mainboard") {
    return { lower: 600, middle: 999, upper: 1000 };
  }
};

export const getPriceIndicator = (group: string, minDayRate?: number) => {
  let getPriceIndicator = null;
  const boundaries = getBoundaries(group);

  if (!minDayRate || !boundaries) {
    return null;
  } else if (minDayRate <= boundaries.lower) {
    getPriceIndicator = "£";
  } else if (minDayRate <= boundaries.middle) {
    getPriceIndicator = "££";
  } else if (minDayRate >= boundaries.upper) {
    getPriceIndicator = "£££";
  }
  return getPriceIndicator;
};
