import React from "react";
import { useLocation } from "react-router-dom";
import { includes } from "lodash";
import { Grid } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { BookerProfile, User } from "src/graphql/types";
import { formatDate } from "src/utils/dates";
import { Link } from "src/ccl/navigation";
import { pluralize } from "src/utils/lang";

export interface BookerListItemProps {
  booker: User;
}

export const BookerListItem = ({
  booker: { name, jobs, createdAt, profile, slug },
}: BookerListItemProps) => {
  const { pathname } = useLocation();
  const isTeamPage = includes(pathname, "/clients/teams");
  const bookerProfile = profile as BookerProfile;
  const kind = bookerProfile?.kind;

  return (
    <Grid
      data-test-id="BookerListItem"
      css={{
        py: "$6",
        borderBottom: "1px solid $grey3",
        gridAutoFlow: "column",
        gridTemplateRows: "1fr 1fr",

        "@md": {
          gridTemplateRows: "auto",
          gridTemplateColumns: "repeat(10, 1fr)",
        },
      }}
    >
      <Link
        to={
          isTeamPage
            ? `${pathname}/${slug}`
            : `/dashboard/agent/clients/${slug}`
        }
        css={{
          fontSize: "$14",
          "@md": { fontSize: "initial", gridColumn: "span 4" },
        }}
      >
        {name}
      </Link>
      <Text
        variant="meta"
        css={{
          color: !kind ? "$orange" : "$grey6",
          "@md": {
            gridColumn: "span 2",
            color: !kind ? "$orange" : "initial",
          },
        }}
      >
        {kind ?? "Unknown"}
      </Text>
      <Text
        variant="meta"
        css={{
          justifySelf: "flex-end",
          "@md": { gridColumn: "span 2", justifySelf: "initial" },
        }}
      >
        <Text as="span" variant="meta" css={{ "@md": { display: "none" } }}>
          Posted{" "}
        </Text>
        {pluralize(jobs?.totalCount || 0, "job")}
      </Text>
      <Text
        variant="meta"
        css={{
          justifySelf: "flex-end",
          color: "$grey6",
          "@md": {
            gridColumn: "span 2",
            justifySelf: "initial",
            color: "initial",
          },
        }}
      >
        <Text
          as="span"
          color="grey6"
          variant="meta"
          css={{ "@md": { display: "none" } }}
        >
          Joined{" "}
        </Text>
        {formatDate(createdAt)}
      </Text>
    </Grid>
  );
};
