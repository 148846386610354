import React from "react";
import type * as Stitches from "@stitches/react";
import { styled } from "@stitches/react";
import { ParagraphRenderer } from "./ParagraphRenderer";
import { HelperBox, Text, Icon } from "src/ccl/document";
import { Flex, Box, BoxProps } from "src/ccl/layout";
import { expandableHelperBoxProps, JobHelpBox } from "src/utils/job";
import { useIsMobile } from "src/hooks/useIsMobile";
interface PayProps {
  title: string;
  explainer: string;
  budgetInfo: string;
  importantInfo: string;
}

interface HelperBoxProps extends BoxProps {
  collapsible?: boolean;
  collapsed?: boolean;
  css?: Stitches.CSS;
}

export const DatesHelperBox = ({
  collapsible,
  collapsed,
  ...props
}: HelperBoxProps) => (
  <Box {...props}>
    <HelperBox
      title="Dates"
      active={false}
      collapsible={collapsible}
      collapsed={collapsed}
      icon="information"
      css={{
        mb: "$9",
        "@bp2": {
          display: collapsible ? "none" : undefined,
          mb: "$6",
        },
      }}
    >
      <Text variant="meta">
        Book one-day jobs or multi-day jobs. If you don’t need each creative for
        the whole time, you can include this info further down the form.
      </Text>
    </HelperBox>
  </Box>
);

const PayDescriptorBox = ({
  explainer,
  budgetInfo,
  importantInfo,
}: {
  explainer: string;
  budgetInfo: string;
  importantInfo: string;
}) => (
  <Text variant="meta">
    {explainer}
    <Text css={{ my: "$4" }} variant="meta">
      {budgetInfo}
    </Text>
    <Text css={{ fontWeight: "bold" }} as="span" variant="meta">
      {importantInfo}
    </Text>
  </Text>
);

export const PayHelperBox = ({
  collapsible,
  collapsed,
  ...props
}: HelperBoxProps & {
  copy: PayProps;
}) => {
  return (
    <Box {...props}>
      <HelperBox
        title="Pay per creative"
        active={false}
        collapsible={collapsible}
        collapsed={collapsed}
        icon="information"
        css={{
          mb: "$9",
          "@bp2": {
            display: collapsible ? "none" : undefined,
            mb: "$6",
          },
        }}
      >
        <PayDescriptorBox
          explainer="This helps us check that your budget is appropriate and lets creatives know what to expect."
          budgetInfo="Write the total amount you'll pay each creative, including all shoot hours, any prep time, and any other deliverables you expect from them (but excluding agency fees)."
          importantInfo="You can adjust the fee after discussing the project with your preferred creatives. You'll only pay after the job's complete."
        />
      </HelperBox>
    </Box>
  );
};

const DescriptiveBox = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <Box css={{ my: "$4" }}>
    <Text variant="meta" css={{ fontWeight: "bold" }}>
      {title}
    </Text>
    <Text variant="meta">{description}</Text>
  </Box>
);

export const DescriptionHelperBox = ({
  collapsible,
  collapsed,
  ...props
}: HelperBoxProps) => {
  const descriptions = [
    {
      title: "Job type",
      description: "For example: editorial, campaign, ecom",
    },
    {
      title: "Travel",
      description: "Can creatives expense travel costs?",
    },
    {
      title: "Project concept",
      description: "Is there a creative concept or core theme?",
    },
    {
      title: "Creative roles",
      description:
        "What are your expectations? What creatives are involved in the project? If there's anything you think would be useful for creatives to know, include it here. For example, if you need specialist skills, or you have any strict deadlines on deliverables for your job.",
    },
    {
      title: "More information needed?",
      description:
        "If you need more information before finalising your creatives, please include it here and share your email so people on your shortlist can reply.",
    },
  ];
  const summary =
    "Summarise your job. This will help us approve it and allow your shortlisted creatives to make a decision. Please include the following:";
  return (
    <Box {...props}>
      <HelperBox
        title="Job description"
        active={false}
        collapsible={collapsible}
        collapsed={collapsed}
        icon="information"
        css={{
          mb: "$9",
          "@bp2": {
            display: collapsible ? "none" : undefined,
            mb: "$6",
          },
        }}
      >
        <Text variant="meta">{summary}</Text>
        {descriptions.map((d, index) => (
          <DescriptiveBox
            key={`description-${index}`}
            title={d.title}
            description={d.description}
          />
        ))}
      </HelperBox>
    </Box>
  );
};

export const UsageHelperBox = ({
  collapsible,
  collapsed,
  ...props
}: HelperBoxProps) => (
  <Box {...props}>
    <HelperBox
      title="Usage"
      active={false}
      collapsible={collapsible}
      collapsed={collapsed}
      icon="information"
      css={{
        "@bp2": {
          display: collapsible ? "none" : undefined,
          mb: "$6",
        },
      }}
    >
      <Text css={{ my: "$4" }} variant="meta">
        Explain how the shoot deliverables will be used, and for how long. This
        will likely impact the final fee.
      </Text>

      <Text variant="meta">
        For example:{" "}
        <Text css={{ fontWeight: "bold" }} as="span" variant="meta">
          UK website for 1 year, global social media channels for 3 months, all
          international stores for 6 months.
        </Text>
      </Text>
    </HelperBox>
  </Box>
);

interface ExpandableHelperBoxProps {
  focusedHelpBox: JobHelpBox;
  currentHelpBox: JobHelpBox;
  context?: string;
  title?: string;
}

export const ExpandableDatesHelperBox = ({
  focusedHelpBox,
  currentHelpBox,
  context,
}: ExpandableHelperBoxProps) => {
  const expanded = focusedHelpBox === currentHelpBox;
  const iconVariant =
    context === "CJDetails" || context === "EditDetails" ? "arrow" : "chevron";

  return (
    <Box data-test-id={`${context}${currentHelpBox}`}>
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text
          variant={
            context === "CJDetails" || context === "EditDetails" ? "h3" : "mini"
          }
        >
          {"Dates"}
        </Text>
        <Icon
          size={context === "CJDetails" || context === "EditDetails" ? 24 : 14}
          variant={expanded ? `${iconVariant}Down` : `${iconVariant}Right`}
        />
      </Flex>

      {expanded && (
        <Text
          variant="meta"
          css={
            context === "CJDetails" || context === "EditDetails"
              ? { my: "$6", width: "95%" }
              : { my: "$4", width: "95%" }
          }
          data-test-id={`${context}${currentHelpBox}-contents`}
        >
          Book one-day jobs or multi-day jobs. If you don&apos;t need each
          creative for the whole time, you can include this info further down
          the form.
        </Text>
      )}
    </Box>
  );
};

const ExpandablePayDescriptorBox = ({
  explainer,
  budgetInfo,
  importantInfo,
  context,
}: {
  explainer: string;
  budgetInfo: string;
  importantInfo: string;
  context?: string;
}) => (
  <Text
    variant="meta"
    css={
      context === "CJDetails" || context === "EditDetails"
        ? { my: "$6", width: "95%" }
        : { my: "$4", width: "95%" }
    }
    data-test-id={`${context}PayHelperBox-contents`}
  >
    {explainer}
    <Text css={{ my: "$2" }} variant="meta">
      {budgetInfo}
    </Text>
    <Text css={{ fontWeight: "bold" }} as="span" variant="meta">
      {importantInfo}
    </Text>
  </Text>
);

export const ExpandablePayHelperBox = ({
  focusedHelpBox,
  currentHelpBox,
  context,
}: ExpandableHelperBoxProps) => {
  const expanded = focusedHelpBox === currentHelpBox;

  return (
    <Box data-test-id={`${context}${currentHelpBox}`}>
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text
          variant={
            context === "CJDetails" || context === "EditDetails" ? "h3" : "mini"
          }
        >
          {"Pay per creative"}
        </Text>
        {context === "EditDetails" && (
          <Icon size={24} variant={expanded ? `arrowDown` : `arrowRight`} />
        )}
        {context === "HelperBoxesModal" && (
          <Icon size={14} variant={expanded ? `chevronDown` : `chevronRight`} />
        )}
      </Flex>
      {expanded && (
        <ExpandablePayDescriptorBox
          explainer="This helps us check that your budget is appropriate and lets creatives know what to expect."
          budgetInfo="Write the total amount you'll pay each creative, including all shoot hours, any prep time, and any other deliverables you expect from them (but excluding agency fees)."
          importantInfo="You can adjust the fee after discussing the project with your preferred creatives. You'll only pay after the job's complete."
          context={context}
        />
      )}
    </Box>
  );
};

const ExpandableDescriptiveBox = ({
  title,
  description,
  context,
}: {
  title: string;
  description: string;
  context?: string;
}) => (
  <Box
    css={
      context === "CJDetails" || context === "EditDetails"
        ? { my: "$6", width: "95%" }
        : { my: "$2", width: "95%" }
    }
  >
    <Text variant="meta" css={{ fontWeight: "bold" }}>
      {title}
    </Text>
    <Text variant="meta">{description}</Text>
  </Box>
);

export const ExpandableDescriptionHelperBox = ({
  focusedHelpBox,
  currentHelpBox,
  context,
}: ExpandableHelperBoxProps) => {
  const descriptions = [
    {
      title: "Job type",
      description: "For example: editorial, campaign, ecom",
    },
    {
      title: "Travel",
      description: "Can creatives expense travel costs?",
    },
    {
      title: "Project concept",
      description: "Is there a creative concept or core theme?",
    },
    {
      title: "Creative roles",
      description:
        "What are your expectations? What creatives are involved in the project? If there's anything you think would be useful for creatives to know, include it here. For example, if you need specialist skills, or you have any strict deadlines on deliverables for your job.",
    },
    {
      title: "More information needed?",
      description:
        "If you need more information before finalising your creatives, please include it here and share your email so people on your shortlist can reply.",
    },
  ];
  const summary =
    "Summarise your job. This will help us approve it and allow your shortlisted creatives to make a decision. Please include the following:";
  const expanded = focusedHelpBox === currentHelpBox;
  const iconVariant =
    context === "CJDetails" || context === "EditDetails" ? "arrow" : "chevron";
  return (
    <Box data-test-id={`${context}${currentHelpBox}`}>
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text
          variant={
            context === "CJDetails" || context === "EditDetails" ? "h3" : "mini"
          }
        >
          Job description
        </Text>
        <Icon
          size={context === "CJDetails" || context === "EditDetails" ? 24 : 14}
          variant={expanded ? `${iconVariant}Down` : `${iconVariant}Right`}
        />
      </Flex>
      {expanded && (
        <>
          <Text
            variant="meta"
            data-test-id={`${currentHelpBox}-contents`}
            css={
              context === "CJDetails" || context === "EditDetails"
                ? { my: "$6", width: "95%" }
                : { mt: "$4", width: "95%" }
            }
          >
            {summary}
          </Text>

          <Box
            css={
              context === "CJDetails" || context === "EditDetails"
                ? {}
                : { mb: "$4" }
            }
            data-test-id={`${context}${currentHelpBox}-contents`}
          >
            {descriptions.map((d, index) => (
              <ExpandableDescriptiveBox
                key={`description-${index}`}
                title={d.title}
                description={d.description}
                context={context}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export const PackageDescriptionHelperBox = ({
  focusedHelpBox,
  currentHelpBox,
  context,
}: ExpandableHelperBoxProps) => {
  const expanded = focusedHelpBox === currentHelpBox;
  const iconVariant =
    context === "CJDetails" || context === "EditDetails" ? "arrow" : "chevron";
  const title = "Job description (optional)";
  return (
    <Box data-test-id={`${context}${currentHelpBox}`}>
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text
          variant={
            context === "CJDetails" || context === "EditDetails" ? "h3" : "mini"
          }
        >
          {title}
        </Text>
        <Icon
          size={context === "CJDetails" || context === "EditDetails" ? 24 : 14}
          variant={expanded ? `${iconVariant}Down` : `${iconVariant}Right`}
        />
      </Flex>
      {expanded && (
        <>
          <Text
            variant="meta"
            data-test-id={`${currentHelpBox}-contents`}
            css={
              context === "CJDetails" || context === "EditDetails"
                ? { my: "$6", width: "95%" }
                : { mt: "$4", width: "95%" }
            }
          >
            <ParagraphRenderer
              text={`If you already have a detailed brief, you can add the information to the package and it will automatically pull into the flow for the booker when they create a job from the package.

If not, that's fine, the booker can fill any details in when they go ahead with your package.`}
            />
          </Text>
        </>
      )}
    </Box>
  );
};

export const ExpandableUsageHelperBox = ({
  focusedHelpBox,
  currentHelpBox,
  context,
}: ExpandableHelperBoxProps) => {
  const expanded = focusedHelpBox === currentHelpBox;
  const iconVariant =
    context === "CJDetails" || context === "EditDetails" ? "arrow" : "chevron";
  return (
    <Box data-test-id={`${context}${currentHelpBox}`}>
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text
          variant={
            context === "CJDetails" || context === "EditDetails" ? "h3" : "mini"
          }
        >
          {"Usage"}
        </Text>
        <Icon
          size={context === "CJDetails" || context === "EditDetails" ? 24 : 14}
          variant={expanded ? `${iconVariant}Down` : `${iconVariant}Right`}
        />
      </Flex>
      <Flex css={{ flexDirection: "column" }}>
        {expanded && (
          <>
            <Text
              css={
                context === "CJDetails" || context === "EditDetails"
                  ? { my: "$6", width: "95%" }
                  : { mt: "$4", width: "95%" }
              }
              variant="meta"
              data-test-id={`${context}${currentHelpBox}-contents`}
            >
              Explain how the shoot deliverables will be used, and for how long.
              This will likely impact the final fee.
            </Text>

            <Text
              variant="meta"
              css={
                context === "CJDetails" || context === "EditDetails"
                  ? { marginBottom: "$6", width: "95%" }
                  : { mt: "$2", mb: "$4", width: "95%" }
              }
            >
              For example:{" "}
              <Text css={{ fontWeight: "bold" }} as="span" variant="meta">
                UK website for 1 year, global social media channels for 3
                months, all international stores for 6 months.
              </Text>
            </Text>
          </>
        )}
      </Flex>
    </Box>
  );
};

export const PackageBookerHelperBox = ({
  focusedHelpBox,
  currentHelpBox,
}: ExpandableHelperBoxProps) => {
  const expanded = focusedHelpBox === currentHelpBox;
  const isMobile = useIsMobile();
  const iconVariant = isMobile ? "chevron" : "arrow";
  return (
    <Box data-test-id={`${currentHelpBox}`}>
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text variant={{ "@initial": "mini", "@bp3": "h3" }}>
          Booker&apos;s Details
        </Text>
        <Icon
          size={isMobile ? 14 : 24}
          variant={expanded ? `${iconVariant}Down` : `${iconVariant}Right`}
        />
      </Flex>
      {expanded && (
        <Box
          data-test-id={`${currentHelpBox}-contents`}
          css={{ mt: "$4", width: "95%", "@bp3": { mt: "$6" } }}
        >
          <Text variant="meta">
            <ParagraphRenderer
              text={`Who are you sending the package to? What company does the booker work for? Add details of the the booker to help you keep track.

The details you provide here are not shared with anyone else, Contact won't use them to get in touch with any of your clients unless they book your creatives through us!`}
            />
          </Text>
        </Box>
      )}
    </Box>
  );
};

export const OptionalCreativeDetailsHelperBox = ({
  currentHelpBox,
  context,
}: ExpandableHelperBoxProps) => (
  <Box data-test-id={`${context}${currentHelpBox}`}>
    <Flex
      css={{
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        pb: "$4",
      }}
    >
      <Text variant="h3">Creative Details (optional)</Text>
    </Flex>
    <Text>
      <ParagraphRenderer
        text={`You don’t need to provide these details, but if you know what the booker needs already, or you want to indicate the sorts of budgets you expect for your creatives, you can include them here.

Anything provided here will be pulled into the flow when the booker turns the package into a job.`}
      />
    </Text>
  </Box>
);

export const PackageNameHelperBox = ({
  focusedHelpBox,
  currentHelpBox,
}: ExpandableHelperBoxProps) => {
  const expanded = focusedHelpBox === currentHelpBox;
  const isMobile = useIsMobile();
  const iconVariant = isMobile ? "chevron" : "arrow";
  return (
    <Box data-test-id={`${currentHelpBox}`}>
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text variant={{ "@initial": "mini", "@bp3": "h3" }}>Package name</Text>
        <Icon
          size={isMobile ? 14 : 24}
          variant={expanded ? `${iconVariant}Down` : `${iconVariant}Right`}
        />
      </Flex>
      {expanded && (
        <Box
          data-test-id={`${currentHelpBox}-contents`}
          css={{ mt: "$4", width: "95%", "@bp3": { mt: "$6" } }}
        >
          <Text variant="meta">
            Name it something relevant and unique so it&apos;s easy to find -
            try to include the brand name and the campaign, e.g. &apos;Nike -
            SS23 casting&apos;
          </Text>
        </Box>
      )}
    </Box>
  );
};

interface ExpandableHelperBoxesProps {
  context: "CJDetails" | "HelperBoxesModal" | "EditDetails";
  focusedHelpBox: JobHelpBox;
  setFocusedHelpBox: React.Dispatch<React.SetStateAction<JobHelpBox>>;
  helperBoxes: (keyof typeof helperBoxMap)[];
  title?: string;
}

const Container = styled("div", {
  variants: {
    style: {
      HelperBoxesModal: {
        borderBottom: "1px solid #CFCFCF",
        paddingTop: "11px",
        paddingBottom: "11px",
        minHeight: "34px",
        gap: "13px",
      },
      CJDetails: {
        borderTop: "4px solid $grey6",
        borderLeft: "4px solid $grey6",
        borderRight: "4px solid $grey6",
        boxSizing: "border-box",
        boxShadow: "0px 4px 0px #888888",
        padding: "24px",

        "&:last-child": {
          borderBottom: "4px solid $grey6",
        },
      },
      EditDetails: {
        borderTop: "4px solid $grey6",
        borderLeft: "4px solid $grey6",
        borderRight: "4px solid $grey6",
        boxSizing: "border-box",
        boxShadow: "0px 4px 0px #888888",
        padding: "24px",

        "&:last-child": {
          borderBottom: "4px solid $grey6",
        },
      },
    },
  },
});

const TitleContainer = styled("div", {
  variants: {
    style: {
      HelperBoxesModal: {
        borderBottom: "1px solid #CFCFCF",
        paddingTop: "11px",
        paddingBottom: "11px",
        gap: "13px",
        height: "72px",
        marginTop: "10px",
        fontWeight: "400",
      },
      CJDetails: {
        marginBottom: "30px",
      },
      EditDetails: {
        marginBottom: "30px",
      },
    },
  },
});

export const helperBoxMap = {
  PayHelperBox: ExpandablePayHelperBox,
  DescriptionHelperBox: ExpandableDescriptionHelperBox,
  UsageHelperBox: ExpandableUsageHelperBox,
  DatesHelperBox: ExpandableDatesHelperBox,
  OptionalCreativeDetailsHelperBox: OptionalCreativeDetailsHelperBox,
  PackageDescriptionHelperBox: PackageDescriptionHelperBox,
  PackageNameHelperBox: PackageNameHelperBox,
  PackageBookerHelperBox: PackageBookerHelperBox,
};

export const ExpandableHelperBoxes = ({
  context,
  focusedHelpBox,
  setFocusedHelpBox,
  helperBoxes,
  title = "Job details guide",
}: ExpandableHelperBoxesProps) => {
  const toggleCurrentlyFocused = (
    focusedHelpBox: JobHelpBox,
    currentHelpBox: JobHelpBox,
  ): void => {
    focusedHelpBox === currentHelpBox
      ? setFocusedHelpBox(null)
      : setFocusedHelpBox(currentHelpBox);
  };
  return (
    <Box>
      <TitleContainer style={context}>
        <Text variant={context === "HelperBoxesModal" ? "h3" : "h2"}>
          {title}
        </Text>
      </TitleContainer>
      {helperBoxes.map((helpBox, i) => {
        const HelpBox = helperBoxMap[helpBox];
        return (
          <Container
            key={i}
            onClick={() => {
              toggleCurrentlyFocused(focusedHelpBox, helpBox);
            }}
            style={context}
          >
            <HelpBox
              {...expandableHelperBoxProps(helpBox, focusedHelpBox, context)}
            />
          </Container>
        );
      })}
    </Box>
  );
};
