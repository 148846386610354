/**
 * Transform a string containing '{{HOST}}' to use the current hostname
 * e.g. where the current domain is contact.xyz
 * "https://mysubdomain.{{HOST}}/mylocation"
 * becomes
 * "https://mysubdomain.contact.xyz/mylocation"
 */
export const useLocationAwareURL = (link: string) => {
  const location = new URL(window.location.href);
  return link.replace("{{HOST}}", location.host);
};
