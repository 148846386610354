import React from "react";
import { useTiara } from "src/hooks";
import { FullHeaderPage } from "src/ccl/templates";
import { NotFoundError } from "src/components/errors";

interface NotFoundProps {
  message?: React.ReactNode;
}

const NotFound = ({ message }: NotFoundProps) => {
  useTiara({ title: "Not found" });

  return (
    <FullHeaderPage>
      <NotFoundError message={message} />
    </FullHeaderPage>
  );
};

// For code splitting
export default NotFound; // eslint-disable-line
