import React from "react";

import { Modal, ModalProps, Text } from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import {
  DateRangeInput,
  JobState,
  JobDateRangeOption,
} from "src/graphql/types";

import { Button } from "src/ccl/navigation";
import { FilterDropdown } from "src/ccl/filtering";
import {
  DateRangeOption,
  JobStateOptions,
  SearchFilter,
} from "src/components/filtering";
import { useDateRangeFilterState } from "src/components/filtering/jobFilters/useDateRangeFilterState";

interface JobFilterModalProps extends ModalProps {
  nameOrBrand: string;
  setNameOrBrand: (value: string) => void;
  states: JobState[];
  setStates: (states: JobState[]) => void;
  setDateRange: (dateRange?: DateRangeInput) => void;
  jobCount?: number;
  filtersActive: boolean;
  onReset: () => void;
  availableJobStates: JobState[];
}
export const JobFilterModal = ({
  nameOrBrand,
  setNameOrBrand,
  states,
  setStates,
  setDateRange,
  filtersActive,
  jobCount,
  onReset,
  availableJobStates,
  ...modalProps
}: JobFilterModalProps) => {
  const [, buildRangeOptionProps] = useDateRangeFilterState(setDateRange);
  return (
    <Modal
      withBodyPadding={false}
      titleCss={{ paddingTop: "$4" }}
      title="Filters"
      {...modalProps}
    >
      <Box data-test-id="JobFilterModal">
        <Box
          css={{
            px: "$5",
            maxHeight: "80vh",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              backgroundColor: "$white",
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "$grey3",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "$grey2",
              borderRadius: "5px",
              height: "81px",
            },
          }}
        >
          <FilterDropdown title="Search">
            <SearchFilter
              withinModal={true}
              placeholder="Search jobs"
              search={nameOrBrand}
              onChange={setNameOrBrand}
            />
          </FilterDropdown>

          <FilterDropdown title="Status">
            <JobStateOptions
              availableStates={availableJobStates}
              states={states}
              onChange={setStates}
            />
          </FilterDropdown>

          <FilterDropdown title="Date range">
            <Grid
              css={{
                gridColumns: "1fr 1fr 1fr",
                pb: "$3",
                maxWidth: 350,
                columnGap: "$3",
                mx: "auto",
              }}
            >
              <DateRangeOption
                label="This Week"
                {...buildRangeOptionProps(JobDateRangeOption.ThisWeek)}
              />
              <DateRangeOption
                label="Last 7 Days"
                {...buildRangeOptionProps(JobDateRangeOption.LastSevenDays)}
              />
              <DateRangeOption
                label="This Month"
                {...buildRangeOptionProps(JobDateRangeOption.ThisMonth)}
              />
            </Grid>
            <Grid
              css={{
                gridColumns: "1fr 1fr",
                pb: "$3",
                maxWidth: 250,
                columnGap: "$3",
                mx: "auto",
              }}
            >
              <DateRangeOption
                label="Last 30 Days"
                {...buildRangeOptionProps(JobDateRangeOption.LastThirtyDays)}
              />
              <DateRangeOption
                label="Last Month"
                {...buildRangeOptionProps(JobDateRangeOption.LastMonth)}
              />
            </Grid>
          </FilterDropdown>
        </Box>
        <Flex
          css={{ pt: "$6", width: "100%", justifyContent: "center", gap: "$3" }}
        >
          <Text color="grey6">{`${jobCount || "No"} jobs found`}</Text>
          {filtersActive && (
            <Text
              css={{
                textDecoration: "underline",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={onReset}
            >
              Clear Filters
            </Text>
          )}
        </Flex>
        <Flex css={{ height: "48px", mt: "$7", mb: "$5", mx: "$5" }}>
          <Button
            data-test-id="ConfirmButtonSecondary"
            variant="secondary"
            onClick={modalProps.onClose}
            css={{ mr: "auto" }}
          >
            Close
          </Button>

          <Button
            data-test-id="ConfirmButtonPrimary"
            variant="primary"
            onClick={modalProps.onClose}
            css={{}}
          >
            Search
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};
