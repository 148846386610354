import { gql } from "@apollo/client";

export const CREATE_BOOKER_SSO_MUTATION = gql`
  mutation CreateBooker(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $company: String!
    $kind: String
    $headcountEstimate: CompanyHeadcountEstimate!
    $website: String
    $surfacedFrom: String
  ) {
    createBooker(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      company: $company
      kind: $kind
      headcountEstimate: $headcountEstimate
      website: $website
      surfacedFrom: $surfacedFrom
    ) {
      id
    }
  }
`;
