import React from "react";

import { Modal, ModalProps } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";

export interface ExplanationModalProps extends ModalProps {
  onCancel: () => void;
  primaryCta?: React.FC;
}

export const ExplanationModal = ({
  isOpen,
  showCloseButton,
  title,
  width,
  onCancel,
  primaryCta: PrimaryCta,
  children,
}: ExplanationModalProps) => (
  <Modal
    isOpen={isOpen}
    title={title}
    width={width}
    showCloseButton={showCloseButton}
    onClose={onCancel}
  >
    {children}

    <Flex css={{ mt: "$9", justifyContent: "space-between" }}>
      <Button
        variant="secondary"
        onClick={onCancel}
        css={{ width: "100%", "@bp2": { width: "initial" } }}
      >
        Close
      </Button>

      {PrimaryCta && <PrimaryCta />}
    </Flex>
  </Modal>
);
