import { gql } from "@apollo/client";
import { PACKAGE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const DELETE_PACKAGE_MUTATION = gql`
  mutation DeletePackage($packageId: ID!) {
    deletePackage(packageId: $packageId) {
      ...PackageDetails
    }
  }
  ${PACKAGE_DETAILS_FRAGMENT}
`;
