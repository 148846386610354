import {
  getAuth,
  IdTokenResult,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { environmentName, firebaseConfig } from "src/config";
import { sha256 } from "src/utils/hash";

export enum EProvider {
  google = "google.com",
  apple = "apple.com",
}

export const initializeFirebase = () => initializeApp(firebaseConfig);

export const auth = async (
  providerId: EProvider,
): Promise<(UserCredential & { nonce: string }) | undefined> => {
  try {
    // Mocking firebase response using playwright test is difficult to implement that is why we decided to add and environment variable to do this and be able to test SSO sign-in and sign-up with firebase.
    if (environmentName === "test") {
      return mockAuth();
    } else {
      const auth = getAuth();
      const unhashNonce = crypto.randomUUID();
      const hashNonce = await sha256(unhashNonce);

      const provider = new OAuthProvider(providerId);
      provider.setCustomParameters({ nonce: hashNonce });

      const result = await signInWithPopup(auth, provider);

      return { ...result, nonce: unhashNonce };
    }
  } catch (error) {
    console.error("firebase auth error:", error);
    return undefined;
  }
};

// this is here because can't be move outside of src folder
export const mockAuth = (): UserCredential & { nonce: string } & {
  _tokenResponse: { oauthIdToken: string };
} => {
  return {
    user: {
      email: "test@contact.xyz",
      emailVerified: false,
      isAnonymous: false,
      metadata: { creationTime: "", lastSignInTime: "" },
      providerData: [],
      refreshToken: "",
      tenantId: null,
      delete: function (): Promise<void> {
        throw new Error("Function not implemented.");
      },
      getIdToken: function (): Promise<string> {
        return Promise.resolve("asdjgshjghj");
      },
      getIdTokenResult: function (): Promise<IdTokenResult> {
        throw new Error("Function not implemented.");
      },
      reload: function (): Promise<void> {
        throw new Error("Function not implemented.");
      },
      toJSON: function (): object {
        throw new Error("Function not implemented.");
      },
      displayName: null,
      phoneNumber: null,
      photoURL: null,
      providerId: "",
      uid: "",
    },
    providerId: "google.com",
    _tokenResponse: {
      oauthIdToken: "saghjsdaghsadghghj",
    },
    operationType: "signIn",
    nonce: "Asdas",
  };
};
