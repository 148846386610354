import React from "react";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Field, FormikInput } from "src/ccl/data-entry";
import { INVITE_BOOKER_MUTATION } from "src/graphql/mutations";
import { Button } from "src/ccl/navigation";
import { ValidationBlock } from "src/ccl/feedback";
import { sendToAmplitude } from "src/utils/analytics";
import { Scalars } from "src/graphql/types";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email("Must be a valid email address"),
});

export const InviteBookerModal = ({
  isOpen,
  onClose,
  bookingCompanyId,
}: ModalProps & { bookingCompanyId: Scalars["ID"] }) => {
  const [createBookerInvitation, { loading, error }] = useMutation(
    INVITE_BOOKER_MUTATION,
    {
      update(cache, { data: { inviteUserToBookingCompany } }) {
        cache.modify({
          id: `BookingCompany:${bookingCompanyId}`,
          fields: {
            bookerInvitations(existingInvitations = []) {
              const newInvitationRef = cache.writeFragment({
                data: inviteUserToBookingCompany,
                fragment: gql`
                  fragment BookerInvitationDetails on BookerInvitation {
                    id
                    email
                  }
                `,
              });
              return [...existingInvitations, newInvitationRef];
            },
          },
        });
      },
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={true}>
      <Box css={{ padding: "$5" }}>
        <Text variant="h2" css={{ mb: "$6" }}>
          Add team member
        </Text>
        <Text css={{ mb: "$6" }}>
          Enter the email address you wish to send an invitation to.
        </Text>

        <Formik
          validationSchema={validationSchema}
          initialValues={{ email: "" }}
          onSubmit={async (values) => {
            await createBookerInvitation({
              variables: values,
            });
            if (!error) {
              onClose?.();
              sendToAmplitude(
                "booker settings manage company members - invites booker",
                {
                  booking_company_id: bookingCompanyId,
                },
              );
            }
          }}
        >
          <Form>
            <Field name="email" label="Email address">
              <FormikInput
                id="form-email"
                name="email"
                type="email"
                variant="rebrand"
              />
            </Field>
            {error && <ValidationBlock title={error.message} variant="error" />}

            <Flex css={{ justifyContent: "space-between", mt: "$6" }}>
              <Button variant="secondaryCta" type="reset" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primaryCta" type="submit" disabled={loading}>
                {loading ? "Sending..." : "Send invitation"}
              </Button>
            </Flex>
          </Form>
        </Formik>
      </Box>
    </Modal>
  );
};
