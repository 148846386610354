import { format, parseISO, startOfDay } from "date-fns";

export const timeFormat = "HH:mm";
export const timeFormatV2 = "HH:mm a";

export const formatDate = (
  date: Date | string,
  dateFormat = "dd LLLL yyyy",
) => {
  if (typeof date === "string") {
    return format(parseISO(date), dateFormat);
  } else {
    return format(date, dateFormat);
  }
};

export const formatDisplayDate = (
  date: Date | string,
  dateFormat = "dd LLLL yyyy",
) => {
  const formattedDate =
    typeof date === "string"
      ? format(parseISO(date), dateFormat)
      : format(date, dateFormat);
  return format(new Date(formattedDate), "dd/MM/yyyy");
};

export const isBetween = (before: Date, current: Date, after: Date) => {
  const startOfBefore = startOfDay(before);
  const startOfCurrent = startOfDay(current);
  const startOfAfter = startOfDay(after);

  return startOfBefore <= startOfCurrent && startOfCurrent <= startOfAfter;
};
