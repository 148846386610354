import React from "react";
import { CAJFormCard } from "./createJob";
import { Loading } from "./Loading";
import { ApiError } from "./errors";
import { ShortlistVerticals } from "./ShortlistVerticals";
import { AdditionalVerticalUpsell } from "./dashboards/booker";
import { MakeChanges } from "./MakesChanges";
import { Box, Flex, Grid } from "src/ccl/layout";
import { JobDetailItem, JobUsages, ParagraphRenderer } from "src/components";
import { Icon, Review, Text } from "src/ccl/document";
import { Button, Link } from "src/ccl/navigation";
import { commissionMultiplier, vatRate } from "src/config";
import { formatCurrency } from "src/utils/currencyFormatter";
import { formatDate, timeFormat } from "src/utils/dates";
import { uppercaseFirstLetter } from "src/utils/lang";
import {
  CreateJobVerticalInput,
  HeadcountEstimate,
  JobKind,
  MutationCreateJobArgs,
  NudityInvolvement,
  TalentProfile,
  User,
} from "src/graphql/types";
import {
  getConfigForVertical,
  getDeliverableValuesFromConfig,
  verticalMap,
} from "src/utils/user";
import { styled } from "src/ccl";
import { UploadedFile } from "src/entities";
import { TotalFeeEstimate } from "src/ccl/document/totalFeeEstimate";
import { useVerticalConfigurations, useFeatures } from "src/hooks";
import { CJFormCardHeader } from "src/pages/createJob/CJFormCardHeader";
import {
  JobTypeIconMap,
  missingVerticals,
  totalFeesValues,
} from "src/utils/job";
import { getEnumKeyByEnumValue } from "src/utils/getEnumKeyByEnumValue";

const DeliverableList = styled("ul", {
  listStyle: "initial",
  ml: "$6",
});

interface JobSummaryProps {
  jobDetails: MutationCreateJobArgs;
  jobVerticals: CreateJobVerticalInput[];
  deckFiles: UploadedFile[] | null;
  shortlistedTalent: User[];
  isTalentRemovable?: boolean;
  onRemoveShortlistedTalent?: (talent: User) => void;
  ctaText?: string;
  ctaDisabled?: boolean;
  packageSlug?: string;
}

export const JobSummary = ({
  jobDetails,
  jobVerticals,
  deckFiles,
  shortlistedTalent,
  isTalentRemovable = true,
  onRemoveShortlistedTalent,
  ctaText,
  ctaDisabled = false,
  packageSlug,
}: JobSummaryProps) => {
  const { loading, error, configurations } = useVerticalConfigurations();
  const { featureEnabled } = useFeatures();
  const isPackage = packageSlug !== undefined;

  const missingJobVerticals = missingVerticals(
    jobVerticals.map((jv) => jv.vertical),
  );
  if (loading) {
    return <Loading />;
  }

  if (error || !configurations) {
    return <ApiError />;
  }

  return (
    <Grid
      css={{
        gridTemplateColumns: "1fr",
        gap: "$6",
        "@bp2": { gridTemplateColumns: "3fr 2fr", gap: "$10" },
        "@bp5": {
          maxWidth: "1160px",
          gap: "$20",
          px: "$6",
        },
      }}
    >
      <Grid
        css={{
          "@bp2": { mb: "$0" },
          "@bp5": { width: "574px" },
          gap: "$6",
        }}
      >
        <CAJFormCard
          headerComponent={
            <CJFormCardHeader
              title="Job details"
              iconVariant="jobs"
              showPencil={true}
              linkTo={
                packageSlug
                  ? `/packages/${packageSlug}/details`
                  : "/jobs/new/details"
              }
            />
          }
        >
          <Grid css={{ gap: "$6" }}>
            <JobDetailItem
              title="Job Type"
              body={
                <Text weight="bold">
                  {getEnumKeyByEnumValue(JobKind, jobDetails.kind as string)}
                </Text>
              }
            />
            <JobDetailItem
              title="Creative discipline"
              body={
                <Text weight="bold">
                  {jobVerticals
                    .map(
                      ({ vertical }) =>
                        `${uppercaseFirstLetter(verticalMap[vertical])}s`,
                    )
                    .join(", ")}
                </Text>
              }
            />
            <JobDetailItem title="Job name" body={jobDetails.name} />
            <JobDetailItem
              title="Job type"
              body={jobDetails.virtual ? "Virtual" : "Onsite"}
            />
            <JobDetailItem title="Brand" body={jobDetails.brand} />
            <JobDetailItem title="Location" body={jobDetails.location} />
            <JobDetailItem
              title={
                !jobDetails.spansMultipleDays ||
                jobDetails.startDate === jobDetails.endDate
                  ? "Date"
                  : "Dates"
              }
              body={
                <>
                  {formatDate(jobDetails.startDate)}
                  {jobDetails.spansMultipleDays &&
                    jobDetails.startDate !== jobDetails.endDate &&
                    ` - ${formatDate(jobDetails.endDate)}`}
                </>
              }
            />

            {deckFiles && deckFiles.length > 0 && (
              <JobDetailItem
                title="Deck or moodboard"
                body={
                  <>
                    {deckFiles.map((deckFile, i) => (
                      <Text key={i}>{deckFile.filename}</Text>
                    ))}
                  </>
                }
              />
            )}

            <JobDetailItem
              title="Description"
              body={<ParagraphRenderer text={jobDetails.description} />}
            />
            {!featureEnabled("job_details_revamp") && jobDetails.usage && (
              <Box css={{ mb: "$3" }}>
                <JobDetailItem
                  title="Usage"
                  body={<ParagraphRenderer text={jobDetails.usage} />}
                />
              </Box>
            )}
            {featureEnabled("job_details_revamp") && (
              <>
                {jobDetails.headcountEstimate && (
                  <JobDetailItem
                    title="Headcount Estimate"
                    body={getEnumKeyByEnumValue(
                      HeadcountEstimate,
                      jobDetails.headcountEstimate,
                    )}
                  />
                )}

                <JobDetailItem
                  title="Travel Expenses Covered"
                  body={
                    jobDetails.travelExpenseCover
                      ? formatCurrency(jobDetails.travelExpenseCover)
                      : jobDetails.allTravelFeesCovered
                      ? "Fully Covered"
                      : "No"
                  }
                />

                <JobDetailItem
                  title="Nudity Involved"
                  body={
                    jobDetails.nudityType
                      ? getEnumKeyByEnumValue(
                          NudityInvolvement,
                          jobDetails.nudityType,
                        )
                      : "No"
                  }
                />

                <JobDetailItem
                  title="Self Tape Required"
                  body={jobDetails.selfTapeRequired ? "Yes" : "No"}
                />

                <JobDetailItem
                  title="Catering Provided"
                  body={jobDetails.cateringProvided ? "Yes" : "No"}
                />

                {jobDetails?.usages && jobDetails.usages.length > 0 && (
                  <JobDetailItem
                    title="Usages"
                    body={<JobUsages usages={jobDetails.usages} />}
                  />
                )}

                {jobDetails.additionalNotes && (
                  <JobDetailItem
                    title="Additional Notes"
                    body={
                      <ParagraphRenderer text={jobDetails.additionalNotes} />
                    }
                  />
                )}
              </>
            )}
          </Grid>
        </CAJFormCard>
        {jobVerticals.map(
          (
            {
              jobLength,
              budget,
              deliverables,
              talentRequired,
              vertical,
              timeslots,
            },
            i,
          ) => {
            const verticalConfig = getConfigForVertical(
              configurations,
              vertical,
            );
            const defaultDeliverableValues =
              getDeliverableValuesFromConfig(verticalConfig);
            return (
              <CAJFormCard
                key={i}
                testId={`CAJFormCard.${vertical}`}
                headerComponent={
                  <CJFormCardHeader
                    title={`${verticalMap[vertical]} details`}
                    iconVariant={JobTypeIconMap[vertical]}
                    linkTo={
                      isPackage
                        ? `/packages/${packageSlug}/creative-details`
                        : `/jobs/new/creative-details?v=${verticalMap[vertical]}`
                    }
                  />
                }
              >
                <Grid css={{ gap: "$6" }}>
                  <JobDetailItem
                    title={`How long do you need each ${verticalMap[vertical]} for?`}
                    body={jobLength}
                  />

                  {deliverables && deliverables?.length > 0 && (
                    <JobDetailItem
                      title="Deliverables"
                      body={
                        <DeliverableList>
                          {deliverables.map(
                            (deliverable: string, i: number) => (
                              <li key={i}>
                                {defaultDeliverableValues.includes(deliverable)
                                  ? deliverable
                                  : `Other: ${deliverable}`}
                              </li>
                            ),
                          )}
                        </DeliverableList>
                      }
                    />
                  )}

                  <JobDetailItem
                    title={`Cost per ${verticalMap[vertical]}`}
                    body={
                      <>
                        <Text>{formatCurrency(budget)} </Text>
                        <Text variant="meta">
                          (
                          {`${formatCurrency(
                            budget * (vatRate + 1) * (commissionMultiplier + 1),
                          )} with fees + VAT`}
                          )
                        </Text>
                      </>
                    }
                  />

                  <JobDetailItem
                    title={`How many ${verticalMap[vertical]}s do you require?`}
                    body={talentRequired}
                  />

                  {featureEnabled("job_details_revamp") &&
                    timeslots &&
                    timeslots.length > 0 && (
                      <Box
                        css={{
                          gridColumn: "1/3",
                        }}
                      >
                        <JobDetailItem
                          title="Job Times"
                          body={timeslots.map(({ from, to }, index) => (
                            <Text key={`timeslot-${index}`}>
                              {formatDate(from)} {formatDate(from, timeFormat)}{" "}
                              - {formatDate(to, timeFormat)}
                            </Text>
                          ))}
                        />
                      </Box>
                    )}
                </Grid>
              </CAJFormCard>
            );
          },
        )}
        {ctaText && (
          <Button
            variant="primary"
            disabled={ctaDisabled}
            css={{
              width: "100%",
              height: "$16",
              mt: "$4",
              display: "none",
              "@bp4": { display: "block" },
            }}
            type="submit"
          >
            {ctaText}
          </Button>
        )}
      </Grid>
      <Box
        css={{
          "@bp5": {
            width: "474px",
          },
        }}
      >
        <TotalFeeEstimate values={totalFeesValues(jobVerticals)} />
        <Box
          css={{
            mt: "$6 ",
            mb: "$7",
            pb: "$8",
            pt: "$6",
            px: "$9",
            border: "1px solid $grey6",
            borderRadius: "10px",
            "@bp2": {
              border: "1px solid $grey3",
              boxShadow: "$focus",
            },
          }}
        >
          <Flex
            css={{
              justifyContent: "space-between",
              pt: "$5",
              pb: "$5",
              gap: "$4",
              flexDirection: "column",
              borderBottom: "1px solid $grey2",
              "@bp4": {
                flexDirection: "row",
              },
            }}
          >
            <Text variant="h3">Shortlist ({shortlistedTalent.length})</Text>
            {packageSlug && (
              <Link to={`/packages/${packageSlug}`}>
                <Flex css={{ alignItems: "center", gap: "$3", py: "auto" }}>
                  <Box
                    css={{
                      backgroundColor: "$grey3",
                      borderRadius: "$round",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <Icon
                      variant="plus"
                      size={10}
                      css={{ mb: "1px", ml: "5px" }}
                    />
                  </Box>
                  Edit shortlist
                </Flex>
              </Link>
            )}
          </Flex>
          <Box
            css={{
              maxHeight: "450px",
              marginRight: "-15px",
              paddingRight: "15px",
              "@bp5": {
                maxHeight: "714px",
              },
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "$grey3",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "$grey2",
                borderRadius: "5px",
                height: "81px",
              },
            }}
          >
            <ShortlistVerticals
              withAddMore={isPackage ? false : true}
              talent={shortlistedTalent}
              talentVerticals={jobVerticals.map(({ vertical }) => vertical)}
              isRemovable={() => isTalentRemovable}
              onRemove={onRemoveShortlistedTalent}
              getSubHeading={(t) => {
                const profile = t.profile as TalentProfile;
                const { primaryLocation, totalReviews, averageRating } =
                  profile;

                let subtext = primaryLocation.name.split(",")[0];
                if (t.country) {
                  subtext += t.country === "GB" ? ", UK" : `, ${t.country}`;
                }

                return (
                  <Flex>
                    <Text variant="meta" css={{ color: "$grey6" }}>
                      {subtext}
                    </Text>
                    {averageRating && (
                      <Review
                        color="grey6"
                        average={averageRating}
                        total={totalReviews}
                        css={{
                          marginLeft: "$4",
                        }}
                      />
                    )}
                  </Flex>
                );
              }}
            />
          </Box>
        </Box>

        {!isPackage && missingJobVerticals.length > 0 && (
          <Box css={{ mb: "$11" }}>
            <AdditionalVerticalUpsell
              additionalVerticals={missingJobVerticals}
              to="/jobs/new/shortlist"
              page="job summary"
            />
          </Box>
        )}

        <MakeChanges
          text="You can edit your shortlist or job details right up until you confirm
        the job."
        />
      </Box>
    </Grid>
  );
};
