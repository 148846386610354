import React from "react";
import type * as Stitches from "@stitches/react";
import { keyframes, styled } from "@stitches/react";
import { Box } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";
import { mergeCss } from "src/ccl";

type PulsatingIconProps = Stitches.VariantProps<typeof Box> & {
  color: keyof typeof tokens.colors;
  backgroundColor: keyof typeof tokens.colors;
  css?: Stitches.CSS;
  variant?: "small" | "medium";
  children?: React.ReactNode;
};

const pulse = (color: string) =>
  keyframes({
    from: { boxShadow: `0 0 0 0 ${color}` },
    "70%": { boxShadow: `0 0 0 16px ${color}00` },
    to: { boxShadow: `0 0 0 0 ${color}00` },
  });

const StyledIcon = styled("div", {
  borderRadius: "50%",

  variants: {
    variant: {
      small: {
        minWidth: "16px",
        minHeight: "16px",
      },
      medium: {
        minWidth: "20px",
        minHeight: "20px",
      },
    },
  },
});

export const PulsatingIcon = ({
  color,
  backgroundColor,
  css = {},
  variant = "medium",
  children,
}: PulsatingIconProps) => (
  <StyledIcon
    css={mergeCss(
      {
        backgroundColor: backgroundColor,
        animation: `${pulse(color)} 2s infinite`,
      },
      css,
    )}
    variant={variant}
  >
    {children}
  </StyledIcon>
);
