import { TalentProfile } from "src/graphql/types";

export const getSocialMedia = (
  profile: TalentProfile,
  socialMediaPlatform: string,
) => {
  if (!profile.socialMediaFields) {
    return null;
  }
  for (const socialMedia of profile.socialMediaFields) {
    if (socialMedia.platform === socialMediaPlatform) {
      return socialMedia;
    }
  }
  return null;
};

export const convertFollowerCount = (followerCount: number) => {
  if (followerCount >= 1000000) {
    return (followerCount / 1000000).toFixed(0) + "M";
  }
  if (followerCount >= 1000) {
    return (followerCount / 1000).toFixed(0) + "K";
  }
  return followerCount.toString();
};

export const addCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
