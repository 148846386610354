import React from "react";
import { Box } from "src/ccl/layout";

export const SideNavigation = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <Box
    css={{
      width: "100%",
      maxWidth: "initial",
      display: "flex",
      mb: "$5",
      borderBottomColor: "$black",
      borderBottomWidth: 3,
      borderBottomStyle: "solid",
      overflowX: "scroll",
      overflowY: "visible",

      "&::-webkit-scrollbar": {
        display: "none",
      },
      backgroundColor: "$grey1",

      "@bp2": {
        width: "90%",
        maxWidth: 223,
        display: "block",
        borderBottomStyle: "none",
        overflow: "initial",
        backgroundColor: "initial",
      },
      "@bp4": {
        maxWidth: 324,
      },
    }}
  >
    {children}
  </Box>
);
