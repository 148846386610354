import { includes } from "lodash";
import { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Icon, TalentListItem, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { commissionMultiplier } from "src/config";
import { TalentProfile, TalentVertical } from "src/graphql/types";
import { useStoreModel } from "src/hooks";
import { formatCurrency } from "src/utils/currencyFormatter";
import { jobPriceForModels } from "src/utils/job";
import { getPriceIndicator } from "src/utils/user";

interface ShortlistSummaryProps {
  isCollapsable?: boolean;
  isComputationVisible?: boolean;
}

export const ShortlistSummary: FC<ShortlistSummaryProps> = ({
  isCollapsable = true,
  isComputationVisible = true,
}) => {
  const { pathname } = useLocation();
  const { draftV2 } = useStoreModel("jobs");
  const { isFirstPartyAgent } = useStoreModel("currentUser");
  const { talentUsers, setTalentUsers, jobVerticals } = draftV2;

  const history = useHistory();
  const [showShortlistSummary, setShowShortlistSummary] = useState(true);

  const totalFee = jobVerticals.reduce(
    (acc, cur) => acc + jobPriceForModels(cur.budget, cur.talentRequired),
    0,
  );

  const talentRequiredEntered = jobVerticals.find(
    (jv) => jv.talentRequired > 0,
  );
  const bookingFee = totalFee * commissionMultiplier;
  const vatFee = (totalFee + bookingFee) * commissionMultiplier;
  const feesEntered = totalFee > 0 || talentRequiredEntered;

  const isEditPage = includes(pathname, "edit");

  return (
    <Flex
      css={{
        flexDirection: "column",
        display: "none",
        "@bp3": { height: "fit-content", display: "flex", width: "389px" },
      }}
    >
      <Flex
        css={{
          justifyContent: "space-between",
          paddingRight: "$2",
          mb: "$11",
        }}
      >
        <Flex css={{ gap: "$5" }}>
          <Icon size={30} variant="checklist" />
          <Text
            data-test-id="ShortlistSummaryNumber"
            variant="h4"
          >{`${talentUsers?.length} Shortlisted`}</Text>
        </Flex>
        {!isFirstPartyAgent && (
          <Flex css={{ alignItems: "center" }}>
            <Flex
              css={{ cursor: "pointer", alignItems: "center" }}
              onClick={() =>
                history.push(
                  isEditPage ? `${pathname}/shortlist` : "/jobs/new/shortlist",
                )
              }
            >
              <Icon variant="plusCircle" />
              <Text
                variant="b3Bold"
                css={{ textTransform: "uppercase", paddingLeft: "$3" }}
              >
                shortlist More
              </Text>
            </Flex>
            {isCollapsable && (
              <Box
                css={{ cursor: "pointer" }}
                onClick={() => {
                  setShowShortlistSummary(!showShortlistSummary);
                }}
              >
                <Icon
                  size={18}
                  variant="selectArrowOutline"
                  css={{
                    transform: showShortlistSummary
                      ? "rotate(180deg)"
                      : "rotate(270deg)",
                    transition: "400ms ease all",
                    ml: "$5",
                    mt: "$1",
                    mr: "$2",
                  }}
                />
              </Box>
            )}
          </Flex>
        )}
      </Flex>
      <Flex
        css={{
          flexDirection: "column",
          gap: "$8",
          overflow: "hidden scroll",
          transition: "200ms ease all",
          maxHeight: showShortlistSummary ? "462px" : "0px",
          marginBottom: showShortlistSummary ? "$12" : "0px",
        }}
      >
        {talentUsers?.map((talent) => (
          <TalentListItem
            showVertical={true}
            showTalentDescriptorText={false}
            key={talent.id}
            talent={talent}
            isRemovable={(talent) =>
              !!talentUsers
                ?.map((talentUser) => talentUser.id)
                .includes(talent.id)
            }
            onRemove={(talent) =>
              setTalentUsers(
                (talentUsers || []).filter(
                  (talentUser) => talentUser.id !== talent.id,
                ),
              )
            }
            isRemoveText
            openInNewWindow
            getSubHeading={(talent) => (
              <Flex
                css={{
                  alignItems: "center",
                  gap: "4px",
                  mt: "$3",
                  "@bp5": { gap: "6px" },
                }}
              >
                <Icon size={16} variant="location" />
                <Text variant="meta">
                  {(talent.profile as TalentProfile).primaryLocation.name}
                </Text>
                {(talent.profile as TalentProfile).vertical ===
                  TalentVertical.FashionModel &&
                  talent.groups && (
                    <>
                      <Icon
                        size={8}
                        variant="dot"
                        css={{ alignSelf: "center", mx: "$2" }}
                      />
                      <Text variant="meta">{talent.groups[0].name}</Text>
                      <Icon
                        size={8}
                        variant="dot"
                        css={{
                          alignSelf: "center",
                          mx: "$2",
                          display: getPriceIndicator(
                            talent.groups[0].name,
                            (talent.profile as TalentProfile).minDayRate,
                          )
                            ? undefined
                            : "none",
                        }}
                      />
                      <Text variant="meta">
                        {getPriceIndicator(
                          talent.groups[0].name,
                          (talent.profile as TalentProfile).minDayRate,
                        )}
                      </Text>
                    </>
                  )}
              </Flex>
            )}
            avatarSizes={{ width: 60, height: 70 }}
            avatarCss={{ borderRadius: "6px" }}
            variant="noMargin"
          />
        ))}
      </Flex>
      {isComputationVisible && (
        <Flex
          css={{
            borderTop: "1px solid $grey3",
            justifyContent: feesEntered ? "revert" : "center",
            alignItems: feesEntered ? "revert" : "flex-end",
            height: feesEntered ? "283px" : "51px",
            flexDirection: feesEntered ? "column" : "row",
          }}
        >
          {feesEntered ? (
            <>
              <Flex css={{ gap: "$7", flexDirection: "column", pt: "$7" }}>
                <Flex css={{ justifyContent: "space-between" }}>
                  <Text>Total creative fees</Text>
                  <Text>{formatCurrency(totalFee)}</Text>
                </Flex>
                <Flex css={{ justifyContent: "space-between" }}>
                  <Text>Booking fees</Text>
                  <Text>{formatCurrency(bookingFee)}</Text>
                </Flex>
                <Flex css={{ justifyContent: "space-between" }}>
                  <Text>VAT</Text>
                  <Text>{formatCurrency(vatFee)}</Text>
                </Flex>
                <Text css={{ textDecoration: "underline" }}>
                  How our fees work
                </Text>
                <Flex css={{ pt: "$7", alignItems: "center" }}>
                  <Icon size={18} variant="alert" />
                  <Text weight="bold" css={{ ml: "$3" }}>
                    Total fee estimate
                  </Text>
                  <Text weight="bold" css={{ ml: "auto" }}>
                    {formatCurrency(bookingFee + vatFee + totalFee)}
                  </Text>
                </Flex>
              </Flex>
            </>
          ) : (
            <Text variant="body" color="grey6" weight="bold">
              Fees are calculated after details are specified.
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
};
