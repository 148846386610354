import React from "react";
import { BaseFilter, BaseFilterProps } from "src/ccl/filtering/baseFilter";
import { Input } from "src/ccl/data-entry";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";

interface TextFilterProps extends BaseFilterProps {
  value?: string;
  onChange: (value: string) => void;
}

export const TextFilter = ({
  value: initialValue = "",
  onChange,
  onReset,
  expanded,
  ...filterProps
}: TextFilterProps) => {
  const [value, setValue] = useDebouncedInputValue(
    initialValue || "",
    onChange,
  );

  return (
    <BaseFilter
      {...filterProps}
      expanded={expanded || !!value?.length}
      onReset={() => {
        setValue("");
        onReset && onReset();
      }}
    >
      <Input
        name={filterProps.name}
        type="text"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
        }}
      />
    </BaseFilter>
  );
};
