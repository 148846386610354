import type * as Stitches from "@stitches/react";
import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { LegacyHeader } from "./LegacyHeader";
import { ProfileDropdown } from "./ProfileDropdown";
import {
  ContactForDropdown,
  HowItWorksDropdown,
  ResourcesDropdown,
} from "./dropdowns";
import { styled } from "src/ccl";
import { NavigationPane } from "src/ccl/blocks/navigationPane";
import { Icon, Logo, Text } from "src/ccl/document";
import { Box, LegacyContainer as LayoutContainer } from "src/ccl/layout";
import {
  HeaderLink,
  Link,
  LinkButton,
  SupportLauncher,
} from "src/ccl/navigation";
import { ShortlistHeaderButton, TalentSelectionHelpLink } from "src/components";
import { UserKindContext, useFeatures } from "src/hooks";
import {
  ScrollDirection,
  useScrollDirection,
} from "src/hooks/useScrollDirection";
import { sendToAmplitude } from "src/utils/analytics";

type DropdownMenu = "howItWorks" | "resources" | "contactFor";

const Wrapper = styled(Box, {
  position: "relative",
  zIndex: "$500",
  py: "$4",
  "@bp2": { py: 0, px: "$7" },
});

const Container = styled(LayoutContainer, {
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  justifyContent: "space-between",
  px: "$4",
  "@bp2": {
    px: 0,
    overflow: "visible",
  },
});

const LinkContainer = styled(Box, {
  display: "flex",
  alignItems: "center",
  gap: "$4",

  "@bp2": {
    minHeight: "$avatar",
    gap: "$4",
  },
  "@bp3": {
    gap: "$6",
  },
  "@bp4": {
    gap: "$12",
  },
});

const DesktopHeaderLink = styled(HeaderLink, {
  display: "none",
  mr: 0,
  py: "$12",

  "@bp2": {
    display: "flex",
    alignItems: "center",
  },
});

export interface HeaderProps {
  variant?: "rebrand";
  css?: Stitches.CSS;
  context: UserKindContext;
  avatarImageUrl?: string;
  avatarInitials?: string;
  userName?: string;
  userEmail?: string;
  onSignOut?: () => void;
  onMouseEnter?: () => void;
  fullPageHeaderChildren?: React.ReactNode;
}

export const Header = ({
  variant,
  css,
  context,
  avatarImageUrl,
  avatarInitials,
  userName,
  userEmail,
  onSignOut,
  onMouseEnter,
  fullPageHeaderChildren,
}: HeaderProps) => {
  const { featureEnabled } = useFeatures();
  const path = useLocation().pathname;
  const headerRef = useRef<HTMLDivElement>(null);
  const scrollDirection = useScrollDirection();
  const [navigationPaneOpen, setNavigationPaneOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<DropdownMenu | null>(null);

  const jobsContext = context === "client" || context === "signedOut";
  const isSticky =
    (jobsContext && path.includes("/jobs/new/shortlist")) ||
    path.includes("/agency");

  const ctaInfo = jobsContext
    ? { text: "Book creatives", link: "/jobs/new" }
    : { text: "Dashboard", link: "/dashboard" };

  if (
    !featureEnabled("unified_dashboards") &&
    (context === "agent" || context === "TPA" || context === "talent")
  ) {
    return (
      <LayoutContainer css={css || {}}>
        <LegacyHeader
          context={context}
          avatarImageUrl={avatarImageUrl}
          avatarInitials={avatarInitials}
          userName={userName}
          userEmail={userEmail}
          onSignOut={onSignOut}
          onMouseEnter={onMouseEnter}
          fullPageHeaderChildren={fullPageHeaderChildren}
        />
      </LayoutContainer>
    );
  }

  if (featureEnabled("unified_dashboards") && fullPageHeaderChildren) {
    return <>{fullPageHeaderChildren}</>;
  }

  return (
    <Box
      ref={headerRef}
      css={{
        position: isSticky ? "sticky" : "initial",
        top:
          scrollDirection === ScrollDirection.down
            ? (headerRef.current?.offsetHeight || 0) * -1
            : 0,
        transition: "0.5s ease all",
        zIndex: "$500",
        background: variant ? "none" : "$white",
      }}
      data-test-id="Header"
    >
      <Box
        css={{
          backgroundColor: "$black",
          opacity: dropdownOpen === null ? 0 : 0.7,
          position: "fixed",
          inset: 0,
          zIndex: "$400",
          visibility: dropdownOpen === null ? "hidden" : "visible",
          transition: "opacity 150ms ease",
        }}
      />

      <Wrapper>
        <NavigationPane
          open={navigationPaneOpen}
          onClose={() => setNavigationPaneOpen(false)}
          onSignOut={() => {
            onSignOut && onSignOut();
            setNavigationPaneOpen(false);
          }}
          signedOut={context === "signedOut"}
          booker={context === "client"}
          ctaInfo={ctaInfo}
          avatarImageUrl={avatarImageUrl}
          avatarInitials={avatarInitials}
        />
        <Container>
          <Link
            data-test-id="HeaderLogo"
            to={context === "signedOut" ? "/" : "/dashboard"}
            onClick={() =>
              sendToAmplitude("top nav - clicks contact home button")
            }
          >
            <Box css={{ display: "none", "@bp2": { display: "block" } }}>
              <Logo variant="full" color={variant && "white"} />
            </Box>
            <Box css={{ display: "block", "@bp2": { display: "none" } }}>
              <Logo color={variant && "white"} />
            </Box>
          </Link>
          <LinkContainer>
            <HowItWorksDropdown
              variant={variant}
              onOpen={() => setDropdownOpen("howItWorks")}
              onClose={() => setDropdownOpen(null)}
              isActive={dropdownOpen === null || dropdownOpen === "howItWorks"}
            />
            <ResourcesDropdown
              variant={variant}
              onOpen={() => setDropdownOpen("resources")}
              onClose={() => setDropdownOpen(null)}
              isActive={dropdownOpen === null || dropdownOpen === "resources"}
              booker={context === "client"}
            />

            {context === "signedOut" && (
              <ContactForDropdown
                variant={variant}
                onOpen={() => setDropdownOpen("contactFor")}
                onClose={() => setDropdownOpen(null)}
                isActive={
                  dropdownOpen === null || dropdownOpen === "contactFor"
                }
              />
            )}

            <TalentSelectionHelpLink
              iconSize={24}
              iconCss={{ display: "block" }}
              css={{
                display: "none",
              }}
              linkCss={{
                "@bp2": { display: "none" },
              }}
              color={variant ? "white" : "black"}
            />

            <SupportLauncher
              data-test-id="HeaderNeedHelp"
              longContent="Help"
              color={
                dropdownOpen === null ? (variant ? "white" : "black") : "grey6"
              }
              iconSize={16}
              css={{
                display: "none",
                "@bp2": { display: "block" },
                "@bp3": { display: "none" },
              }}
              longContentCSS={{
                "&:hover": { color: variant ? "$white" : "$grey6" },
              }}
              iconCss={{
                display: "block",
                width: "24px",
                height: "24px",
                "@bp3": {
                  width: "16px",
                  height: "16px",
                },
              }}
              wrapperCSS={{
                "&:hover": {
                  color: variant ? "$white" : "$grey6",
                  "svg path": { fill: variant ? "$white" : "$grey6" },
                },
              }}
            />

            {context === "signedOut" && (
              <DesktopHeaderLink data-test-id="HeaderSignIn" to="/sign-in">
                <Icon
                  variant="user"
                  size={16}
                  color={
                    dropdownOpen === null
                      ? variant
                        ? "white"
                        : "black"
                      : "grey6"
                  }
                />
                <Text
                  variant="mini"
                  css={{
                    ml: "$2",
                    color:
                      dropdownOpen === null
                        ? variant
                          ? "$white"
                          : "$black"
                        : "$grey6",
                  }}
                >
                  Sign in
                </Text>
              </DesktopHeaderLink>
            )}

            {context !== "signedOut" && (
              <Box css={{ display: "none", "@bp2": { display: "block" } }}>
                <ProfileDropdown
                  avatarImageUrl={avatarImageUrl}
                  avatarInitials={avatarInitials}
                  onSignOut={onSignOut}
                  userName={userName}
                  userEmail={userEmail}
                  context={context}
                />
              </Box>
            )}

            {jobsContext ? (
              <ShortlistHeaderButton ref={headerRef} />
            ) : (
              <LinkButton
                data-test-id="HeaderDashboardCTA"
                to={jobsContext ? "/jobs/new" : "/dashboard"}
                onClick={() =>
                  jobsContext && sendToAmplitude("top nav - clicks book talent")
                }
                onMouseEnter={() =>
                  jobsContext && onMouseEnter && onMouseEnter()
                }
              >
                {jobsContext ? "Book Creatives" : "Dashboard"}
                <Icon variant="chevronRight" size={12} css={{ ml: "$4" }} />
              </LinkButton>
            )}
          </LinkContainer>
        </Container>
      </Wrapper>
    </Box>
  );
};
