import * as Yup from "yup";
import { FormikValues } from "formik";
import { getValidationErrors } from "./validationHelpers";
import { NoBudgetValidationJobKinds } from "src/constants/validationExclusions";

export const JobValidation = async (
  values: FormikValues,
  validateTalentRequired: boolean,
) => {
  const { virtual } = values;
  const startDate = values.startDate
    ? new Date(values.startDate)
    : new Date(values.startYear, values.startMonth - 1, values.startDay);
  const endDate = values.endDate
    ? new Date(values.endDate)
    : new Date(values.endYear, values.endMonth - 1, values.endDay);
  const beginningOfToday = new Date();
  beginningOfToday.setHours(0, 0, 0, 0);

  let talentRequired = Yup.number();
  if (validateTalentRequired) {
    talentRequired = talentRequired.min(1, "Must be greater than 1");
  }

  let endDateValidation = Yup.date();
  if (values.spansMultipleDays) {
    endDateValidation = endDateValidation.test(
      "afterStartDate",
      "Must end after start date",
      () => endDate >= startDate,
    );
  }

  let schema: Record<string, Yup.SchemaOf<unknown>> = {
    kind: Yup.string().trim().required("Required"),
    name: Yup.string().trim().required("Required"),
    brand: Yup.string().trim().required("Required"),
    budget: Yup.number().min(0, "Can't be less than 0").required("Required"),
    talentRequired: talentRequired.required("Required"),
    description: Yup.string().trim().required("Required"),
    usage: Yup.string().trim().required("Required"),
    jobLength: Yup.string().trim().required("Required"),
    startDate: Yup.date().test(
      "afterBeginningToday",
      "Must be in the future",
      () => startDate >= beginningOfToday,
    ),
    endDate: endDateValidation,
  };

  if (!virtual) {
    schema = {
      ...schema,
      location: Yup.string().trim().required("Required"),
    };
  }

  return getValidationErrors(values, Yup.object().shape(schema));
};

export const jobDetailsValidation = async (
  values: FormikValues,
  options: {
    shouldStartAfterToday: boolean;
    travelExpenseRequired?: boolean;
    nudityTypeRequired?: boolean;
    revampFieldsActivated?: boolean;
  },
) => {
  const { virtual } = values;
  const startDate = values.startDate
    ? new Date(values.startDate)
    : new Date(values.startYear, values.startMonth - 1, values.startDay);
  const endDate = values.endDate
    ? new Date(values.endDate)
    : new Date(values.endYear, values.endMonth - 1, values.endDay);
  const beginningOfToday = new Date();
  beginningOfToday.setHours(0, 0, 0, 0);

  let endDateValidation = Yup.date();
  if (values.spansMultipleDays) {
    endDateValidation = endDateValidation.test(
      "afterStartDate",
      "Must end after start date",
      () => endDate >= startDate,
    );
  }

  let schema: Record<string, Yup.SchemaOf<unknown>> = {
    kind: Yup.string().trim().required("Required").nullable(true),
    name: Yup.string().trim().required("Required"),
    brand: Yup.string().trim().required("Required"),
    description: Yup.string().trim().required("Required"),
    startDate: Yup.date().test(
      "afterBeginningToday",
      "Must be in the future",
      () =>
        options.shouldStartAfterToday ? startDate >= beginningOfToday : true,
    ),
    endDate: endDateValidation,
  };

  if (!options.revampFieldsActivated) {
    schema = {
      ...schema,
      usage: Yup.string().trim().required("Required"),
    };
  }

  if (!virtual) {
    schema = {
      ...schema,
      location: Yup.string().trim().required("Required"),
    };
  }

  if (options.revampFieldsActivated) {
    schema = {
      ...schema,
      headcountEstimate: Yup.string().trim().required("Required"),
      usages: Yup.array().of(
        Yup.object().shape({
          form: Yup.array().min(1, "Required"),
          length: Yup.string().trim().required("Required"),
          location: Yup.string().trim().required("Required"),
          mediaType: Yup.string().trim().required("Required"),
        }),
      ),
    };
  }

  if (options.travelExpenseRequired && options.revampFieldsActivated) {
    schema = {
      ...schema,
      travelExpenseCover: Yup.string().trim().required("Required"),
    };
  }

  if (options.nudityTypeRequired && options.revampFieldsActivated) {
    schema = {
      ...schema,
      nudityType: Yup.string().trim().required("Required"),
    };
  }

  return getValidationErrors(values, Yup.object().shape(schema));
};

export const jobVerticalValidation = async (values: FormikValues) => {
  const talentRequired = Yup.number().min(1, "Must be greater than 1");

  const verticalSchema: Record<string, Yup.SchemaOf<unknown>> = {
    budget: Yup.number()
      .min(0, "Can't be less than 0")
      .required("Required")
      .test("budget", "Invalid budget", function (value) {
        if (!NoBudgetValidationJobKinds.includes(values.kind)) {
          const jobLength = Number(this.parent.jobLength);
          const budget = value ?? 0;
          if (jobLength >= 1 && jobLength <= 3 && budget < 30) {
            return this.createError({
              message: "Minimum should be £30",
            });
          }
          if (jobLength >= 4 && jobLength <= 7 && budget < 100) {
            return this.createError({
              message: "Minimum should be £100",
            });
          }
          if (jobLength >= 8 && budget < 180) {
            return this.createError({
              message: "Minimum should be £180",
            });
          }
        }
        return true;
      }),
    talentRequired: talentRequired.nullable().required("Required"),
    jobLength: Yup.string().trim().nullable().required("Required"),
  };

  const schema = Yup.object().shape({
    jobVerticals: Yup.array().of(Yup.object().shape(verticalSchema)),
  });

  return getValidationErrors(values, schema);
};
