import React, { ReactNode } from "react";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { useToggle } from "src/hooks";
import { iconSizes, tokens } from "src/ccl/stitches/theme";

export const FilterDropdown = ({
  icon,
  iconSize = 18,
  title,
  children,
  fontSize,
  titleSuffix,
}: {
  icon?: AvailableIcon;
  iconSize?: keyof typeof iconSizes;
  title: string;
  children: ReactNode;
  titleSuffix?: ReactNode;
  fontSize?: keyof typeof tokens.fontSizes;
}) => {
  const [isExpanded, toggleExpanded] = useToggle([]);
  return (
    <Box
      data-test-id={`FilterModalDropdown-${title}`}
      css={{ borderBottom: "1px solid $grey1" }}
    >
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          pt: "$6",
          pb: "$4",
          cursor: "pointer",
        }}
        onClick={toggleExpanded}
      >
        <Flex
          css={{
            alignItems: "center",
            gap: "$4",
          }}
        >
          {icon && <Icon variant={icon} size={iconSize} />}
          <Text
            css={{
              fontSize: fontSize ? fontSize : "$20",
              lineHeight: "26px",
              fontWeight: "$bold",
            }}
          >
            {title}
          </Text>
          {titleSuffix && (
            <Box
              css={{
                backgroundColor: "$black",
                color: "$white",
                borderRadius: "$round",
                width: 26,
                height: 26,
                textAlign: "center",
                pt: "$1",
              }}
            >
              {titleSuffix}
            </Box>
          )}
        </Flex>
        <Icon
          variant={isExpanded ? "chevronUp" : "chevronDown"}
          color={isExpanded ? "black" : "grey4"}
          size={16}
        />
      </Flex>
      {isExpanded && children}
    </Box>
  );
};
