import { gql } from "@apollo/client";

export const GENERATE_PRESIGNED_URL_MUTATION = gql`
  mutation GeneratePresignedUrl(
    $attachment: String!
    $byteSize: Int!
    $checksum: String!
    $contentType: String!
    $filename: String
  ) {
    generatePresignedUrl(
      attachment: $attachment
      byteSize: $byteSize
      checksum: $checksum
      contentType: $contentType
      filename: $filename
    ) {
      headers {
        key
        value
      }
      key
      url
    }
  }
`;
