import React from "react";
import {
  BookerJobStats,
  BookerProfile,
} from "src/components/dashboards/booker";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { LinkButton } from "src/ccl/navigation";
import { useStoreModel } from "src/hooks";

export const BookerSummary = ({
  completedJobCount,
  upcomingJobCount,
  nextJobStartDate,
  loading,
}: {
  loading: boolean;
  nextJobStartDate?: string;
  upcomingJobCount?: number;
  completedJobCount?: number;
}) => {
  const currentUser = useStoreModel("currentUser");
  const user = currentUser.me;

  const showJobStats =
    loading || upcomingJobCount !== 0 || completedJobCount !== 0;

  return (
    <Flex
      css={{
        paddingBottom: "$8",
        flexDirection: "column",
        maxWidth: "1391px",
        margin: "0 auto",
        "@bp5": { flexDirection: showJobStats ? "row" : "column", pb: "$17" },
      }}
    >
      {user && (
        <>
          <Box
            css={{
              "@bp2": {
                display: "flex",
                px: "$7",
                justifyContent: "space-between",
                alignSelf: "center",
                minWidth: "100%",
              },
              "@bp3": {
                minWidth: "revert",
                width: "100%",
                maxWidth: "1100px",
              },
              "@bp5": {
                px: "revert",
                width: showJobStats ? "50%" : undefined,
                maxWidth: showJobStats ? undefined : "inherit",
                display: showJobStats ? "flex" : undefined,
                justifyContent: showJobStats ? "flex-start" : undefined,
                alignItems: showJobStats ? "center" : undefined,
              },
            }}
          >
            <Box>
              <BookerProfile user={user} showJobStats={showJobStats} />
            </Box>
            <LinkButton
              to="/account/settings"
              css={{
                display: "none",
                "@bp2": {
                  gridColumn: "3",
                  alignSelf: "normal",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "$5",
                  minWidth: "fit-content",
                },
                "@bp5": { display: showJobStats ? "none" : "flex" },
              }}
              variant="simplified"
              data-test-id="DashboardEditButton"
            >
              <Icon size={14} variant="pencil" />
              <Text css={{ fontSize: "14px" }}>Edit profile</Text>
            </LinkButton>
          </Box>
          {showJobStats && (
            <Box
              css={{
                paddingTop: "$7",
                "@bp2": { display: "flex", justifyContent: "center" },
                "@bp5": {
                  width: "50%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                },
              }}
            >
              <BookerJobStats
                nextJobDate={nextJobStartDate}
                upcomingJobs={upcomingJobCount}
                completedJobs={completedJobCount}
                loading={loading}
              />
            </Box>
          )}
        </>
      )}
    </Flex>
  );
};
