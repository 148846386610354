import React from "react";
import type * as Stitches from "@stitches/react";
import { mergeCss, styled } from "src/ccl/stitches";
import check from "src/assets/icons/check.svg";
import inverseCheck from "src/assets/icons/invertedCheck.svg";

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const PrimitiveCheckbox = ({ ...props }: InputProps) => (
  <input {...props} type="checkbox" id={props.id || `form-${props.name}`} />
);

const StyledCheckbox = styled(PrimitiveCheckbox, {
  position: "relative",
  margin: 0,
  appearance: "none",
  backgroundColor: "$white",
  border: "2px solid $black",

  "&:focus": {
    boxShadow: "inset 0 0 0 1px $black",
  },

  "&:disabled": {
    backgroundColor: "$grey2",
    borderColor: "$grey6",
  },

  "&:checked": {
    backgroundImage: `url(${check})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
    backgroundColor: "$black",
    boxShadow: "none",
    color: "$white",
  },

  variants: {
    variant: {
      radio: {
        border: "2px solid $grey2",
        borderRadius: "$round",
        width: 34,
        height: 34,
        cursor: "pointer",

        "&:hover:not(:checked):not(:disabled)": {
          border: "2px solid $grey4",
          "&::before": {
            content: "' '",
            display: "block",
            position: "absolute",
            inset: 0,
            backgroundColor: "$grey4",
            border: "6px solid $white",
            borderRadius: "99999px",
          },
        },

        "&:checked": {
          border: "2px solid $black",
          "&::before": {
            content: "' '",
            display: "block",
            position: "absolute",
            inset: 0,
            backgroundColor: "$black",
            border: "6px solid $white",
            borderRadius: "99999px",
          },
        },
      },
      inverse: {
        "&:checked": {
          backgroundImage: `url(${inverseCheck})`,
          backgroundColor: "$white",
        },
      },
      rebrand: {
        position: "relative",

        borderRadius: "4px",

        border: "1.5px solid $grey3",
        "&::before": {
          content: "",
          position: "absolute",
          borderRadius: "1px",
        },

        "&:hover:not(:checked):not(:disabled)": {
          borderWidth: "2px",
          "&::before": {
            inset: "25%",
            backgroundColor: "$grey4",
          },
        },

        "&:checked": {
          borderColor: "$black",
          backgroundImage: "none",
          backgroundColor: "initial",
          "&::before": {
            inset: "10%",
            backgroundColor: "$black",
          },
        },

        "&:disabled": {
          borderColor: "$grey4",
          backgroundImage: "none",
          backgroundColor: "$grey3",
          "&::before": {
            inset: "10%",
            backgroundColor: "$grey4",
          },
        },
      },
    },
  },
});

export type CheckboxProps = Stitches.ComponentProps<typeof StyledCheckbox>;

export const Checkbox = ({ css = {}, ...props }: CheckboxProps) => (
  <StyledCheckbox
    id={props.id || `form-${props.name}`}
    css={mergeCss({ width: 47, height: 47 }, css)}
    {...props}
  />
);
