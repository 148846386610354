import React from "react";
import { Box, Flex } from "src/ccl/layout";
import { Icon } from "src/ccl/document";
import { ExternalLink } from "src/ccl/navigation";
import { DeckFile } from "src/graphql/types";

interface DeckFileListProps {
  deckFiles: DeckFile[];
  analyticsEvent?: string;
  onRemove: (file: DeckFile) => void;
}

export const DeckFileList = ({
  deckFiles,
  onRemove,
  analyticsEvent,
}: DeckFileListProps) => (
  <Box as="ul" css={{ mb: "$4", "@bp2": { mb: "$6" } }}>
    {deckFiles?.map((deckFile, i) => (
      <Flex
        as="li"
        key={i}
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          borderTopWidth: 1,
          borderTopColor: "$grey3",
          borderTopStyle: "solid",
          "&:first-child": {
            borderTopStyle: "none",
          },
          py: "$3",
        }}
      >
        <ExternalLink
          to={deckFile.url}
          eventName={analyticsEvent}
          target="_blank"
        >
          {deckFile.filename}
        </ExternalLink>
        <Box
          css={{ ml: "$3", cursor: "pointer" }}
          data-test-id="DeckFileDelete"
          onClick={() => {
            onRemove(deckFile);
          }}
        >
          <Icon variant="cross" size={14} />
        </Box>
      </Flex>
    ))}
  </Box>
);
