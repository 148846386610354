import { action, Action, persist } from "easy-peasy";
import { DocumentNode } from "graphql";
import { Query, QueryVerticalTalentSearchArgs } from "src/graphql/types";
import { graphQLClient } from "src/graphql/client";

export interface CachedQuery {
  query: DocumentNode;
  variables: QueryVerticalTalentSearchArgs;
  data: Query;
}

type QueryCache = "createJob" | "editJob";
interface SetCachedQueryPayload {
  context: QueryCache;
  query: CachedQuery;
}

export interface QueryCacheModel {
  setCachedQuery: Action<QueryCacheModel, SetCachedQueryPayload>;
  evictCachedQuery: Action<QueryCacheModel, QueryCache>;
  createJob: CachedQuery | null;
  editJob: CachedQuery | null;
}

const persistInLocalStorage = (model: QueryCacheModel) =>
  persist(model, { storage: "localStorage" });

export const queryCache: QueryCacheModel = persistInLocalStorage({
  setCachedQuery: action((state, { context, query }) => {
    state[context] = query;
  }),
  evictCachedQuery: action((state, context) => {
    graphQLClient.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "verticalTalentSearch",
    });
    state[context] = null;
  }),
  createJob: null,
  editJob: null,
});
