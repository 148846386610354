import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { SSOButton } from "./SSOButton";
import { Field, FormikInput } from "src/ccl/data-entry";
import { Icon, InfoBanner, Text } from "src/ccl/document";
import { ValidationBlock } from "src/ccl/feedback";
import { Box, Flex } from "src/ccl/layout";
import { Button, Link } from "src/ccl/navigation";
import { PopupNotification } from "src/components";
import { useStoreModel } from "src/hooks";

export interface SignInFormProps {
  handleSignInAppleSSO?: () => void;
  handleSignInGoogleSSO?: () => void;
  showCta?: boolean;
  isModal?: boolean;
  createAccountLink?: string;
  hideLink?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef?: any; // this is used for the CAJ sign in page where the submit CTA is outside the form
  onSignIn?: () => void;
  welcomeText?: string;
  backLinkText?: string;
  isLoggedOutState?: boolean;
}

export const SignInForm = ({
  handleSignInAppleSSO,
  handleSignInGoogleSSO,
  showCta = true,
  isModal,
  createAccountLink = "/account/new",
  hideLink,
  formRef,
  onSignIn,
  welcomeText,
  backLinkText,
  isLoggedOutState,
}: SignInFormProps) => {
  const currentUser = useStoreModel("currentUser");
  const { draftV2 } = useStoreModel("jobs");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be an email address")
          .required("Required"),
        password: Yup.string().required("Required"),
      })}
      validateOnBlur={false}
      onSubmit={async (props) => {
        if (isLoggedOutState) {
          draftV2.setLoggedOutState(true);
        }
        await currentUser.logIn(props);
        onSignIn?.();
      }}
    >
      <Form>
        <Flex
          css={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            "@sm": {
              width: isModal ? "100%" : "390px",
            },
            "@xl": {
              width: isModal ? "100%" : "442px",
            },
          }}
        >
          <Flex
            css={{
              flex: 1,
              flexDirection: "column",
              gap: isModal ? "32px" : "36px",
            }}
          >
            <Flex css={{ flexDirection: "column", gap: "18px" }}>
              {!hideLink && !isModal && (
                <Link to="/" css={{ textDecoration: "none" }}>
                  <Icon variant="chevronLeft" size={12} />
                  <Text
                    as="span"
                    css={{
                      paddingLeft: "11px",
                      fontFamily: "$sansNew",
                      fontSize: "$14",
                      fontWeight: "$medium",
                      lineHeight: "19px",
                      letterSpacing: "0.02em",
                    }}
                  >
                    {backLinkText ?? "Back to homepage"}
                  </Text>
                </Link>
              )}
              <Text
                css={{
                  fontFamily: "$sansNew",
                  fontSize: "$22",
                  fontWeight: "$bold",
                  lineHeight: "26px",
                  letterSpacing: "0.02em",
                  "@lg": {
                    fontFamily: isModal ? "$sansNew" : "$serif",
                    fontSize: isModal ? "$22" : "$52",
                    fontWeight: "$bold",
                    lineHeight: isModal ? "26px" : "62px",
                  },
                }}
              >
                Sign In
              </Text>
              <Text
                css={{
                  fontFamily: "$sansNew",
                  fontSize: "$16",
                  fontWeight: "$medium",
                  lineHeight: "25px",
                  letterSpacing: "0.02em",
                }}
              >
                {welcomeText ??
                  "Welcome to Contact. Sign in to your account to continue."}
              </Text>
            </Flex>

            {handleSignInAppleSSO && handleSignInGoogleSSO && (
              <SSOButton
                appleAction={handleSignInAppleSSO}
                googleAction={handleSignInGoogleSSO}
                isSignInForm
              />
            )}

            <Flex
              css={{
                flexDirection: "column",
                gap: isModal ? "26px" : "32px",
              }}
            >
              <>
                <Field
                  variant="b2Bold"
                  name="email"
                  label="Email Address"
                  css={{ marginBottom: "0" }}
                >
                  <FormikInput
                    variant="rebrand"
                    id="sign-in-id"
                    type="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    css={{
                      height: "62px",
                      borderRadius: "8px",
                      border: "2px solid $grey3",
                      "&:focus": {
                        border: "2px solid $black",
                      },
                    }}
                  />
                </Field>
                <Flex css={{ flexDirection: "column", gap: "12px" }}>
                  <Field
                    variant="b2Bold"
                    name="password"
                    label="Password"
                    css={{ marginBottom: "0" }}
                  >
                    <FormikInput
                      variant="rebrand"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      inputSuffix={
                        <Box
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          <Icon
                            variant="eye"
                            size={24}
                            css={{
                              verticalAlign: "middle",
                            }}
                          />
                        </Box>
                      }
                      suffixCss={{ cursor: "pointer" }}
                      css={{
                        height: "62px",
                        borderRadius: "8px",
                        border: "2px solid $grey3",
                        "&:focus": {
                          border: "2px solid $black",
                        },
                      }}
                    />
                  </Field>
                  <Link to="/reset-password">
                    <Text
                      css={{
                        color: "$grey5",
                        fontFamily: "$sansNew",
                        fontSize: "$14",
                        fontWeight: "$medium",
                        lineHeight: "19px",
                        letterSpacing: "0.02em",
                      }}
                    >
                      Forgot your password?
                    </Text>
                  </Link>
                </Flex>
              </>

              {showCta && (
                <Button
                  variant="primaryCta"
                  type="submit"
                  disabled={currentUser.loading}
                >
                  {currentUser.loading ? "Signing in..." : "Sign In"}
                </Button>
              )}
              <Flex css={{ justifyContent: "center" }}>
                <InfoBanner
                  css={{
                    alignItems: "center",
                  }}
                >
                  <Text
                    css={{
                      color: "$grey6",
                      fontFamily: "$sansNew",
                      fontSize: "$14",
                      fontWeight: "$medium",
                      lineHeight: "19px",
                      letterSpacing: "0.02em",
                    }}
                  >
                    New here?{" "}
                    <Link
                      to={createAccountLink}
                      css={{
                        color: "$grey6",
                      }}
                    >
                      Create an account here.
                    </Link>
                  </Text>
                </InfoBanner>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {currentUser.authState && (
          <PopupNotification
            showClose
            onClose={() => currentUser.setAuthState(null)}
          >
            {currentUser.authState === "unauthorized" && (
              <ValidationBlock
                title="Invalid email / password combination"
                body={
                  <Text variant="meta" color="grey6">
                    You can{" "}
                    <Link to="/reset-password" css={{ color: "$grey6" }}>
                      reset your password
                    </Link>{" "}
                    if you&apos;re struggling to sign in.
                  </Text>
                }
                variant="error"
                css={{
                  m: "$0",
                }}
              />
            )}
            {currentUser.authState === "error" && (
              <ValidationBlock
                title="An error occurred"
                body="Sorry, we're having trouble logging you in. Please try again later."
                variant="error"
                css={{
                  m: "$0",
                }}
              />
            )}
          </PopupNotification>
        )}
      </Form>
    </Formik>
  );
};
