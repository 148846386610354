import React from "react";
import { formatDistanceToNow } from "date-fns";
import { Job, JobTalent, JobTalentState } from "src/graphql/types";
import { jobTalentStateTitle } from "src/utils/jobTalent";
import { Text, Icon } from "src/ccl/document";
import { JobTalentStateColors } from "src/graphql/colors";
import {
  jobCompleted,
  jobDescriptionForBooker,
  jobIsLive,
  jobPendingApproval,
} from "src/utils/job";
import { Tooltip } from "src/ccl/feedback";
import { Flex } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";
import { RejectionReasonTooltip } from "src/utils/rejectionReasonTooltip";
interface ShortlistTalentSubheadingProps {
  jobTalent: JobTalent;
  job: Job;
}

export const ShortlistTalentSubheading = ({
  jobTalent,
  job,
}: ShortlistTalentSubheadingProps) => {
  if (jobPendingApproval(job.state)) {
    return (
      <Text color="grey6" variant="meta">
        Will be notified when job is approved
      </Text>
    );
  }

  if (jobIsLive(job) && jobTalent.state === JobTalentState.Pending) {
    return (
      <Tooltip content="They will now respond via Contact" alignment="left">
        <Flex css={{ my: "$1" }}>
          <Icon
            variant="plane"
            size={16}
            color="grey6"
            css={{ flexShrink: 0 }}
          />
          <Text color="grey6" variant="meta" css={{ pl: "$2" }}>
            {jobTalent?.notifiedAt
              ? `Notified ${formatDistanceToNow(
                  new Date(jobTalent?.notifiedAt),
                )} ago`
              : `Will be notified soon`}
          </Text>
        </Flex>
      </Tooltip>
    );
  }

  if (!jobIsLive(job)) {
    return (
      <Text color={jobCompleted(job.state) ? "black" : "grey6"} variant="meta">
        {jobDescriptionForBooker(job)}
      </Text>
    );
  }

  return (
    <Text
      variant="meta"
      css={{
        mb: "$2",
        color: JobTalentStateColors[
          jobTalent.state
        ] as keyof typeof tokens.colors,
        display: [JobTalentState.Accepted, JobTalentState.Rejected].includes(
          jobTalent.state,
        )
          ? "flex"
          : "block",
        fontWeight:
          jobTalent.state === JobTalentState.Accepted ? "$bold" : "$regular",
        alignItems: "center",
      }}
    >
      {jobTalent.state === JobTalentState.Accepted && (
        <Icon variant="tick" color="green" size={16} css={{ mr: "$2" }} />
      )}
      {jobTalent.state === JobTalentState.Rejected && (
        <RejectionReasonTooltip jobTalent={jobTalent} />
      )}
      {jobTalentStateTitle(jobTalent.state)}
    </Text>
  );
};
