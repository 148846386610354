import React from "react";

import { Icon, Modal, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import {
  categorisationExplainers,
  categorisationRequirements,
} from "src/utils/categorisations";

interface CategoryGuideModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CategoryGuideModal = ({
  isOpen,
  onClose,
}: CategoryGuideModalProps) => {
  return (
    <Modal isOpen={isOpen} showCloseButton onClose={onClose} width="wide">
      <Text variant="h2" css={{ pb: "$8" }}>
        Talent categories
      </Text>
      <Text variant="body" css={{ pb: "$7" }}>
        Talent are automatically assigned to a talent category, based upon their
        job count and total income. The default category can be adjusted to
        account for factors such as experience, etc.
      </Text>
      <Flex>
        <Icon variant="newFaces" size={24} css={{ mr: "$4" }} />
        <Text variant="h4">New faces</Text>
      </Flex>
      {categorisationRequirements["New Faces"].map((requirement, i) => (
        <Text variant="meta" color="grey6" key={i}>
          {requirement}
        </Text>
      ))}
      <Text variant="body" css={{ pt: "$6", pb: "$7" }}>
        {categorisationExplainers["New Faces"]}
      </Text>
      <Flex>
        <Icon variant="development" size={24} css={{ mr: "$4" }} />
        <Text variant="h4">Development</Text>
      </Flex>
      {categorisationRequirements["Development"].map((requirement, i) => (
        <Text variant="meta" color="grey6" key={i}>
          {requirement}
        </Text>
      ))}
      <Text variant="body" css={{ pt: "$6", pb: "$7" }}>
        {categorisationExplainers["Development"]}
      </Text>
      <Flex>
        <Icon variant="mainboard" size={24} css={{ mr: "$4" }} />
        <Text variant="h4">Mainboard</Text>
      </Flex>
      {categorisationRequirements["Mainboard"].map((requirement, i) => (
        <Text variant="meta" color="grey6" key={i}>
          {requirement}
        </Text>
      ))}
      <Text variant="body" css={{ pt: "$6" }}>
        {categorisationExplainers["Mainboard"]}
      </Text>
      <Button onClick={onClose} css={{ mt: "$11", "@bp2": { mt: "$13" } }}>
        Close
      </Button>
    </Modal>
  );
};
