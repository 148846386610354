import React from "react";
import { NoBookmarkCollectionsCard } from "./collections";
import { SavedTalentCollection, User } from "src/graphql/types";
import { Link, LinkButton } from "src/ccl/navigation";
import { Flex, Box } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { sendToAmplitude } from "src/utils/analytics";
import { CollectionCard } from "src/components/collections";
import { extractNodes } from "src/utils/lang";
import bookmarksGold from "src/assets/icons/bookmarksGold.svg";
import { styled } from "src/ccl/stitches";
import { Carousel } from "src/ccl/blocks";
import { Loading } from "src/components/Loading";

const GoldLargeBookmark = styled(Box, {
  flexShrink: "0",
  background: `url(${bookmarksGold}) no-repeat center`,
  height: "72px",
  marginTop: "10%",
  flex: 1,
});

const CreateCollectionCard = () => {
  return (
    <Link
      css={{ textDecoration: "none" }}
      to="/dashboard/client/collections"
      onClick={() =>
        sendToAmplitude(
          "client dashboard - clicks create collection empty state",
        )
      }
    >
      <Flex
        css={{
          backgroundColor: "$black",
          borderRadius: "8px",
          flexDirection: "column-reverse",
          justifyContent: "space-between",
          cursor: "pointer",
          height: "217px",
          width: "250px",
          "@bp2": {
            width: "222px",
            height: "296px",
          },
          "@bp4": {
            width: "250px",
            height: "338px",
          },
        }}
        data-test-id="CreateCollectionCard"
      >
        <Flex
          css={{
            px: "28px",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "18px",
          }}
        >
          <Text
            css={{
              color: "$white",
              fontWeight: "bold",
            }}
          >
            Add a new collection
          </Text>
          <Icon variant={"chevronRight"} color={"white"} />
        </Flex>
        <GoldLargeBookmark />
      </Flex>
    </Link>
  );
};

export interface BookmarkCollectionsProps {
  bookmarkCollections: SavedTalentCollection[];
  fetchMoreCollections?: () => void;
  loading: boolean;
}

export const BookmarkCollections = ({
  loading,
  bookmarkCollections,
  fetchMoreCollections,
}: BookmarkCollectionsProps) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {bookmarkCollections.length === 0 ? (
        <NoBookmarkCollectionsCard />
      ) : (
        <Box css={{ overflowX: "hidden", overflowY: "visible", px: "$6" }}>
          <Flex
            css={{
              justifyContent: "space-between",
              py: "$7",
              flexDirection: "column",
              gap: "20px",
              "@bp2": { px: "$0", flexDirection: "row", gap: "0px" },
            }}
            data-test-id="BookmarkCollections"
          >
            <Box>
              <Text variant="mini" css={{ fontWeight: "$medium" }}>
                Recently Added
              </Text>
              <Text variant={{ "@initial": "h3", "@bp2": "h2" }}>
                Your collections
              </Text>
            </Box>

            <Box>
              <LinkButton
                variant="mini"
                to="/dashboard/client/collections"
                css={{ textDecoration: "none" }}
                onClick={() => {
                  sendToAmplitude(
                    "client dashboard - clicks view all collections",
                  );
                }}
              >
                View all
              </LinkButton>
            </Box>
          </Flex>
          <Carousel gap={21} fetchMore={fetchMoreCollections}>
            {bookmarkCollections.length === 1 && (
              <Flex css={{ gap: "20px", "@bp4": { gap: "30px" } }}>
                <CreateCollectionCard />
              </Flex>
            )}
            {bookmarkCollections.map((collection, i) => (
              <CollectionCard
                key={i}
                name={collection.name}
                members={extractNodes<User>(collection.members)}
                linkTo={`/dashboard/client/collections/${collection.slug}`}
                talentVerticals={collection.talentVerticals}
                memberCount={collection.memberCount}
                collectionCardAmplitudeData="client dashboard - clicks collection"
              />
            ))}
          </Carousel>
        </Box>
      )}
    </>
  );
};
