import { gql } from "@apollo/client";
import { PORTFOLIO_IMAGE_DETAILS_FRAGMENT } from "./PortfolioImageDetails";

export const PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT = gql`
  fragment PortfolioImageCategoryDetails on PortfolioImageCategory {
    id
    name
    portfolioImages {
      ...PortfolioImageDetails
    }
  }
  ${PORTFOLIO_IMAGE_DETAILS_FRAGMENT}
`;
