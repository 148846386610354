import React from "react";
import type * as Stitches from "@stitches/react";
import { Page, PageProps } from "./Page";
import { SimpleHeader } from "src/ccl/blocks";

interface SimpleHeaderPageProps extends PageProps {
  context?: "CAJ";
  css?: Stitches.CSS;
  containerCSS?: Stitches.CSS;
  simpleHeaderPageChildren?: React.ReactNode;
  onClick?: () => void;
  displaySupportLauncher?: boolean;
  containerVariant?: "noGutter" | "gutter" | "full";
  titleChildren?: React.ReactNode;
  children?: React.ReactNode;
}
export const SimpleHeaderPage = ({
  title,
  children,
  simpleHeaderPageChildren,
  css = {},
  containerCSS = {},
  onClick,
  displaySupportLauncher,
  containerVariant,
  titleChildren,
  ...boxProps
}: SimpleHeaderPageProps) => {
  return (
    <>
      <Page
        header={
          <SimpleHeader
            title={title}
            css={css}
            onClick={onClick}
            displaySupportLauncher={displaySupportLauncher}
            containerVariant={containerVariant}
            containerCSS={containerCSS}
            titleChildren={titleChildren}
          >
            {simpleHeaderPageChildren}
          </SimpleHeader>
        }
        {...boxProps}
      >
        {children}
      </Page>
    </>
  );
};
