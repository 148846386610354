import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { CollectionList } from "./CollectionList";
import { ModalProps, Modal, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import {
  SavedTalentCollection,
  MutationCreateSavedTalentCollectionArgs,
  Query,
  BookerProfile,
  Scalars,
  User,
} from "src/graphql/types";
import {
  BOOKER_SAVED_TALENT_COLLECTIONS,
  BOOKER_TALENT_COLLECTION_MEMBERSHIPS,
} from "src/graphql/queries/booker";
import { UPDATE_SAVED_TALENT_COLLECTIONS } from "src/graphql/mutations";
import { extractNodes } from "src/utils/lang";
import { Input } from "src/ccl/data-entry";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";

interface AddToCollectionsModalProps extends ModalProps {
  talent: User;
  onCreateNewCollection: () => void;
  amplitudePage?: string;
  newCollectionId?: string;
  analyticsProperty?: string;
}

export const AddToCollectionsModal = ({
  talent,
  onCreateNewCollection,
  onClose,
  isOpen,
  amplitudePage,
  newCollectionId,
  analyticsProperty,
}: AddToCollectionsModalProps) => {
  const [collectionName, setCollectionName] = useState("");
  const [collectionNameValue, setCollectionNameValue] = useDebouncedInputValue(
    collectionName,
    setCollectionName,
  );

  const [selectedCollections, setSelectedCollections] = useState<
    Scalars["ID"][]
  >(newCollectionId ? [newCollectionId] : []);

  const [updateSavedTalentCollections] =
    useMutation<MutationCreateSavedTalentCollectionArgs>(
      UPDATE_SAVED_TALENT_COLLECTIONS,
    );

  const {
    data,
    loading: collectionsLoading,
    refetch: refetchCollections,
  } = useQuery<Query>(BOOKER_SAVED_TALENT_COLLECTIONS, {
    variables: {
      name: collectionName,
    },
    fetchPolicy: "no-cache",
  });

  const { refetch: refetchMemberships, loading: membershipsLoading } =
    useQuery<Query>(BOOKER_TALENT_COLLECTION_MEMBERSHIPS, {
      variables: {
        talentId: talent.id,
      },
      onCompleted: (data) => {
        if (!data?.me?.profile) {
          return;
        }
        const profile = data.me.profile as BookerProfile;
        if (!profile.talentCollectionMemberships) {
          return;
        }
        setSelectedCollections(
          profile.talentCollectionMemberships.map(({ id }) => id),
        );
      },
    });

  const bookerProfile = data?.me?.profile as BookerProfile;
  const collections = extractNodes<SavedTalentCollection>(
    bookerProfile?.savedTalentCollections,
  );

  const loading = collectionsLoading || membershipsLoading;

  useEffect(() => {
    if (isOpen) {
      refetchCollections();
      refetchMemberships();
    }
  }, [collectionName, isOpen]);

  useEffect(() => {
    if (newCollectionId) {
      setSelectedCollections((current) => current.concat(newCollectionId));
    } else {
      setSelectedCollections((current) =>
        current.filter((c) => c !== newCollectionId),
      );
    }
  }, [newCollectionId]);

  return (
    <Modal
      showCloseButton={true}
      onClose={onClose}
      isOpen={isOpen}
      width="responsive"
      parentCss={{ "@bp3": { px: "$11", py: "$6" } }}
      withTitlePadding={false}
    >
      <Flex
        data-test-id="AddToCollectionsModal"
        css={{
          flexDirection: "column",
          gap: "$9",
        }}
      >
        <Text variant="nh3">Add to collection</Text>
        <Flex
          css={{
            alignItems: "center",
            pr: "$7",
          }}
        >
          <Input
            variant="rebrand"
            placeholder="Search collections"
            value={collectionNameValue}
            onChange={(e) => setCollectionNameValue(e.target.value)}
          />
        </Flex>
        <CollectionList
          loading={loading}
          collections={collections}
          onCreateNewCollection={onCreateNewCollection}
          onClickItem={(c) => {
            if (selectedCollections.includes(c.id)) {
              setSelectedCollections(
                selectedCollections.filter((s) => s !== c.id),
              );
            } else {
              setSelectedCollections(selectedCollections.concat(c.id));
            }
          }}
          collectionListItemAmplitudeData={(c) => {
            if (selectedCollections.includes(c.id)) {
              return `${amplitudePage} - removes from a collection`;
            } else {
              return `${amplitudePage} - adds to a collection`;
            }
          }}
          analyticsProperty={analyticsProperty}
          isItemSelected={(c) => selectedCollections.includes(c.id)}
        />
        <Flex
          css={{
            flexDirection: "column-reverse",
            gap: "18px",
            "@sm": {
              flexDirection: "row",
              justifyContent: "space-between",
            },
          }}
        >
          <Button
            variant="secondaryCta"
            onClick={onClose}
            css={{
              width: "100%",
              "@sm": {
                width: "fit-content",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primaryCta"
            css={{
              width: "100%",
              "@sm": {
                width: "fit-content",
              },
            }}
            onClick={async () => {
              await updateSavedTalentCollections({
                variables: {
                  talentId: talent.id,
                  collectionIds: selectedCollections,
                },
              });
              onClose?.();
            }}
          >
            {loading ? "loading..." : "Add to category"}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
