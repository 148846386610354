import React from "react";
import { FilterPane } from "./FilterPane";
import { Flex, Box } from "src/ccl/layout";

export interface FilterableContentProps {
  verticalSelector?: React.ReactElement;
  filterPaneContent: React.ReactNode;
  filterPaneSummary: React.ReactNode;
  totalCount?: number;
  variant?: "edit";
  context?: "editJob" | "createJob" | "agentDashboard";
  filterPaneTop?: number;
  children?: React.ReactNode;
}

export const FilterableContent = ({
  verticalSelector,
  filterPaneContent,
  filterPaneSummary,
  totalCount,
  children,
  variant,
  context,
  filterPaneTop,
}: FilterableContentProps) => {
  let paneTop = 0;

  if (filterPaneTop) {
    paneTop = filterPaneTop;
  } else {
    if (context === "editJob") {
      paneTop = 120;
    } else {
      paneTop = context === "agentDashboard" ? 135 : 210;
    }
  }

  return (
    <Flex
      css={{
        alignItems: "flex-start",
        flexDirection: "column",
        mt: 0,
        "@bp4": {
          flexDirection: "row",
        },
      }}
    >
      <Flex
        css={{
          flexDirection: "column",
          gap: "$7",
          position: "sticky",
          zIndex: "$200",
          top: paneTop,
          "@bp2": {
            top: context === "agentDashboard" ? 75 : undefined,
          },
        }}
      >
        {verticalSelector && (
          <Box
            css={{
              display: "none",
              "@bp4": { display: "block", maxWidth: 300 },
            }}
          >
            {verticalSelector}
          </Box>
        )}
        <FilterPane
          variant={variant}
          totalCount={totalCount}
          summaryContent={filterPaneSummary}
          css={{
            display: context === "agentDashboard" ? "block" : "none",
            borderColor: "$black",
            borderLeftStyle: "solid",
            borderRightStyle: "solid",
            borderTopStyle: "solid",
            "@bp4": {
              display: "block",
            },
            "@short": {
              maxHeight: "45vh",
            },
            zIndex: 0,
          }}
        >
          {filterPaneContent}
        </FilterPane>
      </Flex>
      <Box
        css={{
          alignSelf: "stretch",
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Flex>
  );
};
