import React from "react";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { UploadedFile } from "src/entities";

interface PreviewListProps {
  files: UploadedFile[];
  onRemove: (file: UploadedFile) => void;
}

export const PreviewList = ({ files, onRemove }: PreviewListProps) => (
  <Box as="ul">
    {files.map((file, i) => (
      <Flex
        as="li"
        key={i}
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          borderBottomWidth: 1,
          borderBottomColor: "$grey3",
          borderBottomStyle: "solid",
          py: "$3",
        }}
      >
        <Flex css={{ alignItems: "center" }}>
          <Icon variant="check" color="green" size={14} css={{ mr: "$3" }} />
          <Text>
            <Text as="span" css={{ fontWeight: "bold" }}>
              Uploaded:{" "}
            </Text>
            {file.filename}
          </Text>
        </Flex>

        <Box
          css={{ ml: "$3", cursor: "pointer" }}
          data-test-id="DeckFileDelete"
          onClick={() => {
            onRemove(file);
          }}
        >
          <Icon variant="cross" size={14} />
        </Box>
      </Flex>
    ))}
  </Box>
);
