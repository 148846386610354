import { gql } from "@apollo/client";

export const AGENT_DASHBOARD_JOBS_ACTIVITY_STREAM_QUERY = gql`
  query AgentDashboardJobsActivityStream($first: Int!, $after: String) {
    me {
      activityStream(first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            username
            message
            timestamp
          }
        }
      }
    }
  }
`;
