import React from "react";
import type * as Stitches from "@stitches/react";
import { availableIcons, Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";

export const JobDetailItem = ({
  title,
  body,
  icon,
  iconColor,
  v2,
  textCss,
}: {
  title: React.ReactNode;
  body: React.ReactNode;
  icon?: keyof typeof availableIcons;
  iconColor?: keyof typeof tokens.colors;
  v2?: boolean;
  textCss?: Stitches.CSS;
}) => (
  <Box
    data-test-id={`${
      typeof title === "string" ? title.toLowerCase().replace(/\s+/, "-") : ""
    }`}
    css={{ wordBreak: "break-word" }}
  >
    <Flex css={{ alignItems: "center" }}>
      {icon && (
        <Icon variant={icon} size={16} color={iconColor} css={{ mr: "$2" }} />
      )}

      <Text
        color={v2 ? "black" : "grey6"}
        variant={v2 ? "b2Bold" : "b2"}
        css={{
          mt: "$2",
          mb: "$2",
          fontWeight: 700,
          ...(!v2 && { fontSize: "$14" }),
          ...textCss,
        }}
      >
        {title}
      </Text>
    </Flex>

    {body}
  </Box>
);
