import React, { useState, useEffect, useRef } from "react";
import * as Stitches from "@stitches/react";
import { Box } from "src/ccl/layout";

export const Animation = ({
  source,
  css,
  loop = false,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  source: any;
  css?: Stitches.CSS;
  loop?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }
    if (ref.current) {
      setLoaded(true);
      import("lottie-web").then((Lottie) => {
        if (ref.current) {
          Lottie.default.loadAnimation({
            container: ref.current as Element,
            renderer: "svg",
            loop: loop,
            autoplay: true,
            animationData: source,
          });
        }
      });
    }
  }, [ref.current, loaded, loop, source]);

  return <Box ref={ref} css={css} />;
};
