import { useState } from "react";
import { useQuery } from "@apollo/client";
import { KanbanJobCard, KanbanSortSelector } from ".";
import { Flex, Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { extractNodes, niceStateText } from "src/utils/lang";
import {
  Query,
  Job,
  JobState,
  JobFilterInput,
  SortDirection,
  SortOrderInput,
  Assignee,
} from "src/graphql/types";
import {
  AGENT_DASHBOARD_KANBAN_OWN_CONTEXT_QUERY,
  AGENT_DASHBOARD_KANBAN_QUERY,
} from "src/graphql/queries";

const JobsPerColumn = 5;

interface KanbanColumnProps {
  isOwnContext: boolean;
  jobState: JobState;
  assignees: Assignee[];
  filters: JobFilterInput;
  setTotalJobCount: (count: number) => void;
}

export const KanbanColumn = ({
  isOwnContext,
  jobState,
  assignees,
  filters,
  setTotalJobCount,
}: KanbanColumnProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  // sort order
  const defaultSortOrder: SortOrderInput = {
    column: "start_date",
    direction:
      jobState === JobState.Completed ? SortDirection.Desc : SortDirection.Asc,
  };
  const [sortOrder, setSortOrder] = useState<SortOrderInput>(defaultSortOrder);

  // query
  const querySpec = isOwnContext
    ? AGENT_DASHBOARD_KANBAN_OWN_CONTEXT_QUERY
    : AGENT_DASHBOARD_KANBAN_QUERY;
  const { loading, data, fetchMore, error } = useQuery<Query>(querySpec, {
    variables: {
      filters: {
        ...filters,
        states: [jobState],
      },
      sortOrder: sortOrder,
      first: JobsPerColumn,
    },
    onCompleted: (data) => setTotalJobCount(data?.me?.jobs?.totalCount || 0),
  });

  // map into cards
  const jobResult = data?.me?.jobs;
  const jobsByState = extractNodes<Job>(jobResult);
  const jobCards = jobsByState.map((job: Job, i: number) => (
    <KanbanJobCard key={i} job={job} assignees={assignees} />
  ));

  // render
  return (
    <Flex
      css={{
        width: "300px",
        flexGrow: 1,
        flexDirection: "column",
        gap: "$3 0",
        "@lg": {
          width: "463px",
          height: "460px",
        },
      }}
    >
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text variant="nh3">{niceStateText(jobState)}</Text>
        <KanbanSortSelector
          jobState={jobState}
          sortOrder={sortOrder}
          onSortChange={(sortByAttribute, direction) =>
            setSortOrder({
              column: sortByAttribute,
              direction: direction,
            })
          }
        />
      </Flex>
      <Flex
        css={{
          flexDirection: "column",
          flexWrap: "wrap",
          gap: "$3",
          background: "#F8F8F8",
          borderRadius: "$2",
          padding: "10px",
        }}
      >
        {loading ? (
          <Text color="grey3">Loading</Text>
        ) : error ? (
          <Text color="red">There&apos;s been an error</Text>
        ) : jobCards.length > 0 ? (
          jobCards
        ) : (
          <Text color="grey3">No Jobs</Text>
        )}
        {!loading && jobResult?.pageInfo.hasNextPage && (
          <Box css={{ textAlign: "center", my: "$6" }}>
            <Button
              variant="primaryCta"
              disabled={isLoadingMore}
              onClick={async () => {
                setIsLoadingMore(true);
                await fetchMore({
                  variables: {
                    first: JobsPerColumn,
                    after: jobResult.pageInfo.endCursor || "",
                  },
                });
                setIsLoadingMore(false);
              }}
            >
              {isLoadingMore ? "Loading..." : "Load more"}
            </Button>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
