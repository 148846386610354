import { gql } from "@apollo/client";

export const UPDATE_AGENCY_MUTATION = gql`
  mutation UpdateAgency(
    $agencyId: ID
    $name: String
    $location: String
    $logo: FileUploadInput
    $commissionPercentage: Int
    $paymentsEmail: String
    $bio: String
    $pastClients: [String!]
    $specialities: [String!]
    $instagramUsername: String
    $twitterUsername: String
    $tiktokUsername: String
    $coverPhoto: FileUploadInput
    $hideTalent: Boolean
    $isPrivate: Boolean
  ) {
    updateAgency(
      agencyId: $agencyId
      name: $name
      location: $location
      logo: $logo
      commissionPercentage: $commissionPercentage
      paymentsEmail: $paymentsEmail
      bio: $bio
      pastClients: $pastClients
      specialities: $specialities
      instagramUsername: $instagramUsername
      twitterUsername: $twitterUsername
      tiktokUsername: $tiktokUsername
      coverPhoto: $coverPhoto
      hideTalent: $hideTalent
      isPrivate: $isPrivate
    ) {
      id
      name
      location
      commissionPercentage
      paymentsEmail
      bio
      pastClients
      specialities
      instagramUsername
      twitterUsername
      tiktokUsername
      hideTalent
      isPrivate
    }
  }
`;
