import { useRef, useState, useCallback, useEffect } from "react";

type IntersectionObserverResult = [
  (node: Element | null) => void,
  IntersectionObserverEntry | undefined,
];

export const useIntersect = (
  options?: IntersectionObserverInit,
): IntersectionObserverResult => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  useEffect(() => {
    return () => {
      const observer = observerRef.current;

      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const refCallback = useCallback((node: Element | null) => {
    const getObserver = () => {
      if (!observerRef.current) {
        observerRef.current = new IntersectionObserver(([entry]) => {
          setEntry(entry);
        }, options);
      }

      return observerRef.current;
    };

    const observer = getObserver();
    observer.disconnect();

    if (node) {
      observer.observe(node);
    }
  }, []);

  return [refCallback, entry];
};
