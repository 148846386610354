import React from "react";
import { Avatar } from "src/ccl/blocks";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { LinkButton } from "src/ccl/navigation";
import { User } from "src/graphql/types";
import { getInitials } from "src/utils/lang";
import { BlobBackgroundContainer } from "src/ccl/ornamental";

interface BookerProfileProps {
  user: User;
  showJobStats?: boolean;
}

export const BookerProfile = ({ user, showJobStats }: BookerProfileProps) => {
  const { name, featuredAsset, email } = user;

  return (
    <BlobBackgroundContainer css={{ display: "flex" }}>
      <Avatar
        initials={getInitials(name)}
        imageUrl={featuredAsset?.mediaUrl}
        fontVariant="h4"
        css={{
          my: "$7",
          ml: "$7",
          mr: "$4",
          width: "75px",
          minWidth: "75px",
          height: "75px",
          "@bp2": {
            width: "$18",
            minWidth: "$18",
            height: "$18",
            my: "$7",
            mx: "$7",
          },
        }}
      />
      <Box
        css={{ mt: "$5", alignItems: "start" }}
        data-test-id="ProfileComponent"
      >
        <Text variant="marker">Welcome back</Text>
        <Text variant="h3" css={{ mt: "$3", "@bp2": { display: "none" } }}>
          {name}
        </Text>
        <Text
          variant="h2"
          css={{ mt: "$3", display: "none", "@bp2": { display: "revert" } }}
        >
          {name}
        </Text>
        <Grid
          css={{
            mt: "$5",
            flexDirection: "column",
            gridColumns: 1,
            alignItems: "start",
            "@bp2": {
              flexDirection: "row",
              gridColumns: 2,
              rowGap: "$3",
              columnGap: "$6",
            },
          }}
        >
          <Flex css={{ alignItems: "center" }}>
            <Icon size={20} variant="mail" color="grey6" css={{ mr: "$3" }} />
            <Text
              variant="meta"
              color="grey6"
              css={{
                overflow: "hidden",
                width: "220px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                "@bp2": { width: "100%", overflow: "revert" },
              }}
            >
              {email}
            </Text>
          </Flex>
          {user.phoneNumber && (
            <Flex css={{ alignItems: "center" }}>
              <Icon
                size={16}
                variant="phone"
                css={{ mr: "$4", "@bp2": { mr: "$3" } }}
                color="grey6"
              />
              <Text variant="meta" color="grey6">
                {user.phoneNumber}
              </Text>
            </Flex>
          )}
          <LinkButton
            to="/account/settings"
            css={{
              display: "none",
              "@bp5": {
                display: showJobStats ? "flex" : "none",
                justifyContent: "space-between",
                marginTop: "$5",
                width: "fit-content",
              },
            }}
            variant="simplified"
            data-test-id="DashboardEditButton"
          >
            <Icon size={14} variant="pencil" />
            <Text css={{ fontSize: "14px" }}>Edit profile</Text>
          </LinkButton>
        </Grid>
      </Box>
    </BlobBackgroundContainer>
  );
};
