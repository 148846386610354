import React from "react";
import { last } from "lodash";
import { Flex, Box } from "src/ccl/layout";
import { Icon, PulsatingIcon, Text } from "src/ccl/document";
import { Job, JobState, Invoice } from "src/graphql/types";
import { tokens } from "src/ccl/stitches/theme";

const pulsatingIconStates = [JobState.Approved, JobState.Finalized];

export const JobTitle = ({ job }: { job: Job }) => (
  <Flex css={{ pb: "$3", "@bp2": { pb: "$4" }, alignItems: "center" }}>
    <Box css={{ alignSelf: "baseline", pt: "$3" }}>
      {job.state === JobState.Completed ? (
        <Icon variant={"tick"} size={16} css={{ marginRight: "10px" }} />
      ) : pulsatingIconStates.includes(job.state) ? (
        <PulsatingIcon
          color={tokens.colors["green"] as keyof typeof tokens.colors}
          backgroundColor={"$green" as keyof typeof tokens.colors}
          variant="small"
          css={{ marginRight: "10px" }}
        />
      ) : (
        <></>
      )}
    </Box>
    <Text
      variant="h4"
      css={{
        textAlign: "left",
        textDecoration:
          job.state === JobState.Expired ? "line-through" : undefined,
        maxWidth: "262px",
        "@bp2": {
          maxWidth: "425px",
          whiteSpace: "noWrap",
        },
        "@bp5": { maxWidth: "440px" },
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {job.name}
    </Text>
    <Text
      css={{
        textAlign: "left",
        marginLeft: "10px",
        color: "$red",
        maxWidth: "262px",
        "@bp2": {
          maxWidth: "425px",
          whiteSpace: "noWrap",
        },
        "@bp5": { maxWidth: "440px" },
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {job.state === JobState.Completed
        ? (last(job.latestPaidInvoices) as Invoice)?.invoiceNumber
        : ""}
    </Text>
  </Flex>
);
