import React from "react";
import { BookerDashboardJobsTalent } from "./BookerDashboardJobsTalent";
import { JobDetails } from "./JobDetails";
import { JobTitle } from "./JobTitle";
import { BookerDashboardCreativeStatus } from "./BookerDashboardCreativeStatus";
import { ViewInvoiceButton } from "./ViewInvoiceButton";
import { Flex, Box } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches";
import {
  AgencyJobState,
  Job,
  JobState,
  JobTalent,
  TalentVertical,
} from "src/graphql/types";
import { AgentJobStateTheme, BookerJobStateTheme } from "src/graphql/colors";
import { sendToAmplitude } from "src/utils/analytics";
import { jobCardTitleForAgent, jobCardTitleForBooker } from "src/utils/job";
import { Link } from "src/ccl/navigation";
import { Pill } from "src/ccl/blocks";
import { verticalIconMap } from "src/utils/user";

const JobCardContainer = styled(Box, {
  width: "100%",
  border: "1px solid $grey3",
  py: "$5",
  pl: "$9",
  pr: "$7",
  "&:hover": {
    borderTop: "1px solid $grey6",
    borderBottom: "1px solid $grey6",
    borderRight: "1px solid $grey6",
    boxShadow: "$subtle",
  },

  borderRadius: "5px",
  borderLeftWidth: "12px",
  borderLeftStyle: "solid",

  justifyItems: "space-between",
});

const VerticalWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid $grey3",
  height: "$11",
  mb: "$4",
});

const VerticalPill = ({ verticals }: { verticals: TalentVertical[] }) => (
  <VerticalWrapper
    css={{
      width: verticals.length > 1 ? "fit-content" : "$11",
      borderRadius: verticals.length > 1 ? "$pill" : "$round",
      px: verticals.length > 1 ? "$4" : "unset",
    }}
  >
    <Flex
      css={{
        gridColumns: verticals.length,
        gap: "$4",
      }}
    >
      {verticals.map((vertical, i) => (
        <Icon
          variant={verticalIconMap[vertical]}
          key={i}
          color="grey6"
          size={18}
        />
      ))}
    </Flex>
  </VerticalWrapper>
);

export const JobsIndexJobCardMobile = ({
  job,
  dashboard,
}: {
  job: Job;
  dashboard: "agent" | "client";
}) => {
  const [jobStateColor, jobStateColorSecondary, jobStateColorTertiary] =
    dashboard === "client"
      ? BookerJobStateTheme[job.state]
      : AgentJobStateTheme[job.agencyJobState as AgencyJobState];

  const jobTalent = job?.talent as JobTalent[];

  return (
    <Link
      to={`/dashboard/${dashboard}/jobs/${job.slug}`}
      data-test-id="MobileJobCard"
      onClick={() =>
        sendToAmplitude("client jobs page - clicks latest jobs job card")
      }
      css={{
        textDecoration: "none",
        "@bp2": { display: "none" },
      }}
    >
      <JobCardContainer
        css={{
          borderLeftColor: jobStateColor,
        }}
      >
        <Flex css={{ gap: "$2", flexDirection: "column" }}>
          <Box css={{ height: "60px" }}>
            <JobTitle job={job} />
          </Box>
          <JobDetails job={job} />
          <Flex css={{ gap: "$4", mt: "$5" }}>
            <Box css={{ ml: "-8px", mt: "-6px" }}>
              <Pill
                variant="roundedTertiary"
                css={{
                  width: "max-content",
                  backgroundColor: jobStateColorSecondary,
                }}
              >
                <Text
                  css={{ color: jobStateColorTertiary, fontWeight: "$medium" }}
                >
                  {dashboard === "client"
                    ? jobCardTitleForBooker(job)
                    : jobCardTitleForAgent(job)}
                </Text>
              </Pill>
            </Box>
            <VerticalPill
              verticals={job.jobVerticals.map(({ vertical }) => vertical)}
            />
          </Flex>
        </Flex>

        <Flex css={{ gap: "$3", width: "100%", alignSelf: "flex-end" }}>
          {job.talent && job.talent.length > 0 && (
            <>
              <BookerDashboardJobsTalent
                talent={jobTalent}
                job={job}
                size={48}
                overlap={12}
              />
              <Flex
                css={{
                  textAlign: "right",
                  flexDirection: "column",
                  justifyContent: "center",
                  ml: "auto",
                }}
              >
                <Text variant="meta" color="grey6">
                  {jobTalent.length > 5 && `+${jobTalent.length - 5} more`}
                </Text>
                <BookerDashboardCreativeStatus
                  jobState={job.state}
                  creatives={job.talent}
                  showMeta={false}
                />
              </Flex>
            </>
          )}
        </Flex>

        {dashboard === "client" &&
          job.state === JobState.Completed &&
          job.paymentViaExternalPlatform &&
          job.totalInvoiceCount > 0 && (
            <Box css={{ mt: "$7" }}>
              <ViewInvoiceButton
                unpaidInvoiceCount={job.unpaidInvoiceCount}
                url={`/dashboard/client/jobs/${job.slug}/invoices`}
                buttonCss={{ width: "100%" }}
              />
            </Box>
          )}
      </JobCardContainer>
    </Link>
  );
};
