import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CategoryGuideModal } from "./CategoryGuideModal";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import {
  MutationResetGroupToDefaultArgs,
  MutationUpdateAgencyTalentArgs,
  User,
} from "src/graphql/generated/types";
import { categorisationExplainers } from "src/utils/categorisations";
import options from "src/data/filterOptions.json";
import { SelectReact } from "src/ccl/data-entry";
import {
  RESET_CATEGORY_MUTATION,
  UPDATE_CATEGORY_MUTATION,
} from "src/graphql/mutations";
import { tokens } from "src/ccl/stitches/theme";
import { icons } from "src/ccl/document/icon/icons";

interface TalentCategorisationProps {
  talent: User;
  isFirstPartyAgent: boolean;
}

// This is not ideal because it’s not typechecked between frontend and
// backend. Ideally we’d use something like protobuf here.
interface EnumEntry {
  label: string;
  value: any;
}

export const TalentCategorisation = ({
  talent,
  isFirstPartyAgent,
}: TalentCategorisationProps) => {
  const group = talent.groups ? talent.groups[0] : undefined;
  const defaultGroup = talent.defaultGroup?.name;
  const categoriesExplainer = (category: string) =>
    categorisationExplainers[category as keyof typeof categorisationExplainers];
  const [isCategoryGuideModalShowing, setIsCategoryGuideModalShowing] =
    useState(false);

  // By only casting the empty default value as the EnumEntry type, if there is
  // a value present in the JSON typescript will use that, instead. This will
  // help catch potential configuration drift.
  const categories = "groups" in options ? options.groups : ([] as EnumEntry[]);

  const [updateCategory] = useMutation<MutationUpdateAgencyTalentArgs>(
    UPDATE_CATEGORY_MUTATION,
  );
  const [resetCategory, { loading: resetCategoryLoading }] =
    useMutation<MutationResetGroupToDefaultArgs>(RESET_CATEGORY_MUTATION);

  const manuallySet = group && group.manuallySet;

  let backgroundColor = tokens.colors.white;
  if (manuallySet) {
    backgroundColor = tokens.colors.turquoisePale;
  }

  const displayCategories = categories.map((group) => {
    if (defaultGroup === group.label && manuallySet) {
      return {
        label: `${group.label} (reset to default)`,
        value: group.value.toString(),
      };
    } else {
      return {
        label: `${group.label}`,
        value: group.value.toString(),
      };
    }
  });

  let iconVariant;
  if (
    group &&
    talent.defaultGroup &&
    manuallySet &&
    group?.id > talent.defaultGroup?.id
  ) {
    iconVariant = "categoryLabelUp";
  } else if (group && !manuallySet) {
    iconVariant = "check";
  } else {
    iconVariant = "categoryLabelDown";
  }

  return (
    <Grid
      css={{
        gridColumns: 1,
        width: "100%",
        background: "$grey1",
        p: "$6",
        borderRadius: "0 0 $lg $lg",
        "@bp2": { gridColumns: 2 },
      }}
    >
      {group && (
        <>
          <Flex css={{ flexDirection: "column", pr: "$10" }}>
            <Box>
              <Icon variant="categoryLabel" size={20} />
              <Text
                variant="h3"
                as="span"
                css={{ pl: "$4" }}
                data-test-id="TalentCategoryNameLabel"
              >
                {group.name}
              </Text>
            </Box>
            <Text variant="meta" css={{ pt: "$4" }}>
              {categoriesExplainer(group.name)}
            </Text>
          </Flex>
          <Box
            css={{
              pt: "$6",
              "@bp3": { pt: "$0" },
            }}
          >
            <SelectReact
              variant="rebrand"
              value={
                group !== undefined
                  ? {
                      label: group.name,
                      value: group.id,
                    }
                  : undefined
              }
              options={displayCategories}
              label="Change their category"
              onChange={async (option) => {
                if (option) {
                  await updateCategory({
                    variables: {
                      talentId: talent.id,
                      groupIds: [parseInt(option.value)],
                    },
                  });
                }
              }}
              backgroundColor={backgroundColor as keyof typeof tokens.colors}
              additionalContent={
                isFirstPartyAgent && (
                  <Flex
                    css={{
                      justifyContent: "flex-end",
                    }}
                    onClick={() => setIsCategoryGuideModalShowing(true)}
                  >
                    <Icon variant="information" size={14} css={{ mt: "$2" }} />
                    <Text
                      variant="meta"
                      as="span"
                      css={{
                        display: "none",
                        textDecoration: "underline",
                        cursor: "pointer",
                        pl: "$3",
                        whiteSpace: "nowrap",
                        "@bp2": { display: "inline-block" },
                      }}
                    >
                      Category guide
                    </Text>
                  </Flex>
                )
              }
            />

            <Flex
              css={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                pt: "$3",
              }}
            >
              {isFirstPartyAgent && (
                <Flex>
                  <Icon
                    variant={iconVariant as keyof typeof icons}
                    size={16}
                    color={manuallySet ? "grey3" : "grey6"}
                  />
                  <Text
                    variant="mini"
                    color={manuallySet ? "turquoiseDark" : "grey6"}
                    css={{ pl: "$3" }}
                  >
                    {manuallySet
                      ? "Manually selected"
                      : "Automatically assigned"}
                  </Text>
                </Flex>
              )}

              <Box>
                {isFirstPartyAgent && group.manuallySet && (
                  <Text
                    css={{
                      color: "$turquoiseDark",
                      textDecoration: "underline",
                      cursor: "pointer",
                      textAlign: "right",
                    }}
                    onClick={async () => {
                      if (!resetCategoryLoading) {
                        await resetCategory({
                          variables: { talentId: talent.id },
                        });
                      }
                    }}
                  >
                    <Text
                      variant="meta"
                      color="turquoiseDark"
                      as="span"
                      css={{ display: "none", "@bp2": { display: "block" } }}
                    >
                      Reset to default
                    </Text>
                    <Text
                      variant="meta"
                      color="turquoiseDark"
                      as="span"
                      css={{ display: "block", "@bp2": { display: "none" } }}
                    >
                      Reset
                    </Text>
                  </Text>
                )}
              </Box>
            </Flex>
          </Box>
        </>
      )}
      {isCategoryGuideModalShowing && (
        <CategoryGuideModal
          isOpen={isCategoryGuideModalShowing}
          onClose={() => setIsCategoryGuideModalShowing(false)}
        />
      )}
    </Grid>
  );
};
