import { styled } from "src/ccl/stitches";

export const NoteContainer = styled("div", {
  background: "$grey1",
  borderRadius: "$4",
  mb: "$9",
  padding: "$7",
  position: "relative",
  variants: {
    variant: {
      v2: {
        background: "$white",
        border: "2px solid $grey1",
        borderRadius: "32px 32px 32px 0px",
        mb: "$5",
      },
    },
  },
});
