import { sumBy } from "lodash";
import { LineItemKind, OutboundPayment } from "src/graphql/types";

const amountByKind = (
  payment: OutboundPayment,
  kind: LineItemKind,
  includeAlreadyPaid: boolean,
) => {
  const payments = (payment.lineItems || []).filter((ap) => {
    if (includeAlreadyPaid) {
      return ap.kind === kind;
    } else {
      return ap.kind === kind && !ap.manuallyPaid;
    }
  });

  if (payments.length === 0) {
    return 0;
  }

  return sumBy(payments, (e) => e.amount);
};

export const expenseAmount = (
  payment: OutboundPayment,
  includeAlreadyPaid = false,
) => amountByKind(payment, LineItemKind.Expense, includeAlreadyPaid);

export const additionalFeeAmount = (
  payment: OutboundPayment,
  includeAlreadyPaid = false,
) => amountByKind(payment, LineItemKind.AdditionalFee, includeAlreadyPaid);

export const jobPaymentAmount = (payment: OutboundPayment) =>
  payment.lineItems?.find((p) => p.kind === LineItemKind.JobPayment)
    ?.amount as number;
