import { gql } from "@apollo/client";

export const CREATE_JOB_MUTATION = gql`
  mutation CreateJob(
    $additionalNotes: String
    $affiliate: String
    $brand: String!
    $cateringProvided: Boolean
    $deckFiles: [FileUploadInput!]
    $description: String!
    $endDate: ISO8601Date!
    $headcountEstimate: HeadcountEstimate
    $jobVerticals: [CreateJobVerticalInput!]!
    $kind: JobKind!
    $location: String
    $name: String!
    $nudityType: NudityInvolvement
    $selfTapeRequired: Boolean
    $startDate: ISO8601Date!
    $talentIds: [ID!]!
    $travelExpenseCover: Float
    $allTravelFeesCovered: Boolean
    $usage: String!
    $usages: [UsageInput!]
    $virtual: Boolean
  ) {
    createJob(
      additionalNotes: $additionalNotes
      affiliate: $affiliate
      brand: $brand
      cateringProvided: $cateringProvided
      deckFiles: $deckFiles
      description: $description
      endDate: $endDate
      headcountEstimate: $headcountEstimate
      jobVerticals: $jobVerticals
      kind: $kind
      location: $location
      name: $name
      nudityType: $nudityType
      selfTapeRequired: $selfTapeRequired
      startDate: $startDate
      talentIds: $talentIds
      travelExpenseCover: $travelExpenseCover
      allTravelFeesCovered: $allTravelFeesCovered
      usage: $usage
      usages: $usages
      virtual: $virtual
    ) {
      id
      name
      slug
    }
  }
`;
