import { useLocation } from "react-router-dom";
import { capitalize } from "lodash";
import { Icon, Review } from "src/ccl/document";
import { Text } from "src/ccl/document/text";
import { Tooltip } from "src/ccl/feedback";
import { Flex } from "src/ccl/layout";
import { Link } from "src/ccl/navigation";
import { SocialMediaPlatform, TalentProfile, User } from "src/graphql/types";
import { sendToAmplitude } from "src/utils/analytics";
import { useStoreModel, useUserKindContext } from "src/hooks";
import {
  niceResponseTimeText,
  verticalIconMapAlt,
  verticalMap,
} from "src/utils/user";
import { convertFollowerCount, getSocialMedia } from "src/utils/socialMedia";

interface AgentTalentCardMetaProps {
  talent: User;
}

export const AgentTalentCardMeta = ({ talent }: AgentTalentCardMetaProps) => {
  const { pathname } = useLocation();
  const currentUser = useStoreModel("currentUser");
  const userKindContext = useUserKindContext(currentUser);

  const agencyName = currentUser.me?.agency?.name;

  const talentProfile = talent.profile as TalentProfile;
  const { primaryLocation, averageRating, totalReviews } = talentProfile;

  const responseTime = talent.averageResponseTimeMinutes;
  const displayResponseTime = responseTime && responseTime < 1440;

  const instragram = getSocialMedia(
    talentProfile,
    SocialMediaPlatform.Instagram,
  );
  const tiktok = getSocialMedia(talentProfile, SocialMediaPlatform.Tiktok);

  return (
    <Flex css={{ flexDirection: "column" }}>
      <Flex
        css={{
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex
          css={{
            alignItems: "center",
          }}
        >
          <Tooltip
            content={capitalize(verticalMap[talentProfile.vertical])}
            css={{ zIndex: "250" }}
            hover="noMobileHover"
          >
            <Icon
              size={30}
              variant={verticalIconMapAlt[talentProfile.vertical]}
              css={{ justifySelf: "flex-start", mr: "$3" }}
            />
          </Tooltip>
          <Link
            to={`${pathname}/${talent.slug}`}
            onClick={() =>
              sendToAmplitude("clicks creatives profile", {
                url: pathname,
                userType: userKindContext,
                agency: agencyName,
              })
            }
            css={{
              textDecoration: "none",
              "&:hover": {
                color: undefined,
                textDecoration: "underline",
              },
            }}
          >
            <Text variant="b2Bold">{talent.name}</Text>
          </Link>
        </Flex>
        <Flex
          css={{
            alignItems: "center",
            justifyContent: "center",
            gap: "$3",
          }}
        >
          {((currentUser.isFirstPartyAgent && talentProfile.isFeatured) ||
            (!currentUser.isFirstPartyAgent &&
              talentProfile.isAgencyFeatured)) && (
            <Tooltip
              alignment="right"
              css={{
                zIndex: "250",
                justifySelf: "flex-end",
                marginLeft: "auto",
              }}
              wrapperCss={{
                borderRadius: "4px",
              }}
              content="Featured Talent"
              hover="noMobileHover"
            >
              <Icon variant="featured" size={24} color="purple" />
            </Tooltip>
          )}
          {!!displayResponseTime && (
            <Tooltip
              alignment="right"
              css={{
                zIndex: "250",
                justifySelf: "flex-end",
                marginLeft: "auto",
              }}
              content={`Typically responds in ${niceResponseTimeText(
                responseTime,
              )}`}
              hover="noMobileHover"
            >
              <Icon size={24} variant="responseTime" />
            </Tooltip>
          )}
          {instragram && (
            <Flex css={{ alignItems: "center", gap: "$3" }}>
              <Icon variant="instagram" size={20} />
              <Text variant="mini">
                {convertFollowerCount(instragram.followerCount)}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        css={{
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex css={{ alignItems: "center", gap: "6px" }}>
          <Icon size={16} variant="location" />
          <Text variant="b3" color="grey5">
            {primaryLocation.name}
          </Text>
        </Flex>
        {averageRating && (
          <Review
            average={averageRating}
            total={totalReviews}
            textVariant={"mini"}
            color="grey5"
          />
        )}
        {tiktok && (
          <Flex css={{ alignItems: "center", gap: "$3" }}>
            <Icon variant="tiktok" size={20} />
            <Text variant="mini">
              {convertFollowerCount(tiktok.followerCount)}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
