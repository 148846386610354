import React from "react";
import * as Stitches from "@stitches/react";
import { Box, Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl";

interface CAJFormCardProps {
  testId?: string;
  footerComponent?: React.ReactElement;
  headerComponent?: React.ReactElement;
  css?: Stitches.CSS;
  children?: React.ReactNode;
}

export const CAJFormCard = ({
  children,
  testId,
  footerComponent,
  headerComponent,
  css = {},
}: CAJFormCardProps) => {
  return (
    <Box
      data-test-id={testId ? testId : "CAJFormCard"}
      css={mergeCss(
        {
          backgroundColor: "$grey1",
          minWidth: "280px",
          px: "$6",
          "@bp3": { px: "$12" },
        },
        css,
      )}
    >
      {headerComponent}

      <Box
        css={{
          paddingBottom: footerComponent ? "$9" : "$0",
          "@bp3": {
            paddingBottom: footerComponent ? "$12" : "$7",
          },
          "@bp5": {
            paddingBottom: "$7",
            display: "revert",
          },
        }}
      >
        {children}
        <Box>
          <Flex
            data-test-id="CAJFormCardFooter"
            css={{
              flexDirection: "column",
              gap: "$4",
              paddingTop: "$6",
              borderTop: footerComponent ? "1px solid $grey3" : "",
              "@bp2": { gap: "$6" },
            }}
          >
            {footerComponent}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
