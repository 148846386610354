import React from "react";
import { SelectReactFilter, TextFilter } from "src/ccl/filtering";
import { bookerKindOptions } from "src/components/dashboards/agents";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";

interface BookerFiltersProps {
  nameOrEmail: string;
  bookerType: string;
  onNameOrEmailChange: (nameOrEmail: string) => void;
  onBookerTypeChange: (bookerType: string) => void;
}

export const BookerFilters = ({
  nameOrEmail,
  bookerType,
  onNameOrEmailChange,
  onBookerTypeChange,
}: BookerFiltersProps) => {
  const [nameOrEmailValue, setNameOrEmailValue] = useDebouncedInputValue(
    nameOrEmail,
    (val) => onNameOrEmailChange(val),
  );
  const clientTypes = [
    {
      label: "All clients",
      value: "",
    },
    ...bookerKindOptions,
  ];

  return (
    <>
      <TextFilter
        wrapperCss={{ px: 0 }}
        label="Client name or email"
        value={nameOrEmailValue}
        onChange={setNameOrEmailValue}
        onReset={() => setNameOrEmailValue("")}
      />
      <SelectReactFilter
        wrapperCss={{ px: 0 }}
        label="Client type"
        initialValues={
          clientTypes.find((client) => client.value === bookerType) ||
          clientTypes[0]
        }
        onChange={(val) => onBookerTypeChange(val?.value || "")}
        onReset={() => onBookerTypeChange("")}
        options={clientTypes}
      />
    </>
  );
};
