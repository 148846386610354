import { gql } from "@apollo/client";
import {
  BOOKER_DASHBOARD_SAVED_TALENT_COLLECTIONS_FRAGMENT,
  BOOKER_DASHBOARD_HOMEPAGE_LIST_JOBS_FRAGMENT,
  BOOKER_DASHBOARD_SUMMARY_FRAGMENT,
  BOOKER_DASHBOARD_SAVED_TALENT_FRAGMENT,
} from "src/graphql/fragments";

export const BOOKER_DASHBOARD_HOME_QUERY = gql`
  query BookerDashboardHome(
    $savedTalentEndCursor: String
    $savedTalentCollectionsEndCursor: String
    $jobsEndCursor: String
  ) {
    me {
      ...BookerDashboardSummary
      ...BookerDashboardSavedTalent
      ...BookerDashboardSavedTalentCollections
      ...BookerDashboardListJobs
    }
  }
  ${BOOKER_DASHBOARD_SUMMARY_FRAGMENT}
  ${BOOKER_DASHBOARD_SAVED_TALENT_FRAGMENT}
  ${BOOKER_DASHBOARD_SAVED_TALENT_COLLECTIONS_FRAGMENT}
  ${BOOKER_DASHBOARD_HOMEPAGE_LIST_JOBS_FRAGMENT}
`;
