import React from "react";
import { Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { ResponsiveText } from "src/components";
import { User } from "src/graphql/types";
import { CJMobileStickyCTA } from "src/pages/createJob/CJMobileStickyCTA";
import { niceResponseTimeText } from "src/utils/user";

const getCtaText = (isPackage: boolean, isShortlisted: boolean) => {
  if (isPackage) {
    return isShortlisted ? "Optioned" : "Option";
  } else {
    return isShortlisted ? "Shortlisted" : "Add to shortlist";
  }
};

interface TalentProfileFooterProps {
  talent: User;
  isShortlisted: boolean;
  shortlistButtonClick: () => void;
  context: "package" | "job";
}

export const TalentProfileFooter = ({
  talent,
  isShortlisted,
  shortlistButtonClick,
  context,
}: TalentProfileFooterProps) => {
  const responseTime = talent.averageResponseTimeMinutes;
  const displayResponseTime = responseTime !== undefined && responseTime < 1440;
  const ctaText = getCtaText(context === "package", isShortlisted);
  return (
    <>
      <Flex
        css={{
          display: "none",
          "@bp2": {
            display: "flex",
            position: "sticky",
            width: "100%",
            bottom: "-1px",
            borderTop: "1px solid $grey3",
            borderRadius: "0 0 10px 10px",
            background: "$white",
            height: "73px",
            justifyContent: "space-between",
            boxShadow: "0px -4px 20px 5px #0000001A",
            px: "$11",
            zIndex: "$500",
          },
        }}
        data-test-id="TalentProfileFooter"
      >
        <Flex css={{ py: "$7", alignItems: "center" }}>
          <Icon size={20} variant="responseTime" css={{ mr: "$4" }} />
          {!displayResponseTime && <Text>They are open to new work.</Text>}
          {displayResponseTime && (
            <Text variant="new">
              {" "}
              They are open to new work and typically respond within{" "}
              <Text variant="new" as="span" weight="bold">
                {niceResponseTimeText(responseTime)}
              </Text>
              .
            </Text>
          )}
        </Flex>
        <Button
          variant={isShortlisted ? "green" : "primary"}
          onClick={shortlistButtonClick}
          disabled={false}
          css={{ width: 348, my: "$4", borderRadius: "$lg" }}
          data-test-id="TalentProfileFooterShortlistButton"
        >
          <Text
            variant="nSmall"
            color="white"
            css={{ fontFamily: "$sansWide", textTransform: "uppercase" }}
          >
            {isShortlisted
              ? context === "package"
                ? "Optioned"
                : "Shortlisted"
              : null}
            {!isShortlisted && (
              <ResponsiveText
                css={{
                  fontFamily: "$sansNew",
                  fontWeight: "$bold",
                  fontSize: "$14",
                }}
                initial={context === "package" ? "Option" : "Shortlist"}
              />
            )}
          </Text>
        </Button>
      </Flex>
      <CJMobileStickyCTA
        text={ctaText}
        onClick={shortlistButtonClick}
        buttonVariant="white"
        css={{ position: "fixed", "@bp2": { display: "none" } }}
      />
    </>
  );
};
