import React from "react";
import { useStoreModel } from "src/hooks";
import { Toggle } from "src/ccl/data-entry";

interface PreferenceToggleFilterProps {
  name: string;
  textUnchecked: string;
  textChecked: string;
  checkedValue: string;
  uncheckedValue: string;
}

export const PreferenceToggleFilter = ({
  name,
  checkedValue,
  uncheckedValue,
  ...props
}: PreferenceToggleFilterProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const preferences = useStoreModel("preferences") as any;
  const option = preferences[name as string];
  return (
    <Toggle
      id={name}
      checked={option.preference === checkedValue}
      onCheck={() => {
        option.setPreference(checkedValue);
      }}
      onUncheck={() => {
        option.setPreference(uncheckedValue);
      }}
      uncheckedGreyBackground
      {...props}
    />
  );
};
