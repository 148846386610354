export const setAffiliateFromUtmParams = () => {
  const params = new URLSearchParams(window.location.search);

  const medium = params.get("utm_medium");
  if (medium !== "affiliate") {
    return;
  }

  const source = params.get("utm_source");
  if (!source || source === "") {
    return;
  }

  setAffiliate(source);
};

export const setAffiliate = (affiliate: string) => {
  sessionStorage.setItem("affiliate", affiliate);
};

export const getAffiliate = (): string | null =>
  sessionStorage.getItem("affiliate");
