import React from "react";
import { StatCard } from "src/ccl/blocks/statCard";
import { Flex } from "src/ccl/layout";

export interface BookerJobStatsProps {
  nextJobDate?: string;
  upcomingJobs?: number;
  completedJobs?: number;
  loading: boolean;
}

export const BookerJobStats = (props: BookerJobStatsProps) => {
  const { nextJobDate, upcomingJobs, completedJobs, loading } = props;

  const validateDateStringFormat = (dateString: string | null): boolean => {
    const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    return dateString ? !!dateString.match(regex) : false;
  };

  const hasCorrectStringDateFormat = validateDateStringFormat(
    nextJobDate || "",
  );

  const getDaysUntilNextJob = (nextJobDate: string): number => {
    const todaysDate = new Date().toISOString().slice(0, 10);
    const now = new Date(todaysDate).getTime();
    const future = new Date(nextJobDate).getTime();
    return Math.floor((future - now) / (1000 * 60 * 60 * 24));
  };

  const daysUntilNextJob =
    nextJobDate && hasCorrectStringDateFormat
      ? getDaysUntilNextJob(nextJobDate)
      : null;

  const validateDayCount = (count: number | null): number | null => {
    return count !== null ? (count >= 0 && !isNaN(count) ? count : null) : null;
  };

  const validDayCount = validateDayCount(daysUntilNextJob);

  return (
    <Flex
      css={{
        gap: "$7",
        overflow: "scroll",
        px: "$5",
        "@bp2": {
          overflow: "hidden",
          width: "1100px",
          px: "$7",
          justifyContent: "space-between",
        },
        "@bp5": {
          px: "revert",
        },
      }}
    >
      <StatCard
        context="nextJob"
        stat={validDayCount}
        icon="timer"
        text="until your next job"
        amplitudeTracking="booker dashboard - clicks days until next job card"
        loading={loading}
      />
      <StatCard
        context="upcomingJobs"
        stat={upcomingJobs === undefined ? 0 : upcomingJobs}
        icon="calendar"
        text="upcoming jobs"
        amplitudeTracking="booker dashboard - clicks upcoming jobs card"
        loading={loading}
      />
      <StatCard
        context="completedJobs"
        stat={completedJobs === undefined ? 0 : completedJobs}
        icon="check"
        text="completed jobs"
        amplitudeTracking="booker dashboard - clicks completed jobs card"
        loading={loading}
      />
    </Flex>
  );
};
