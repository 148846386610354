import React from "react";
import { SubLevelHeader, SubLevelHeaderProps } from "src/ccl/blocks";
import { Box } from "src/ccl/layout";

interface DashboardContentProps extends SubLevelHeaderProps {
  children: React.ReactNode;
}

export const DashboardContent = ({
  children,
  ...subLevelHeaderProps
}: DashboardContentProps) => {
  return (
    <SubLevelHeader {...subLevelHeaderProps}>
      {/* Box needs to be removed, this is just so it's easy to see the sticky buttons on mob */}
      <Box css={{ height: "900px" }}>{children}</Box>
    </SubLevelHeader>
  );
};
