import React, { useState } from "react";
import { FilterOption } from "src/ccl/filtering";
import { DropdownPill } from "src/ccl/blocks/pill/DropdownPill";
import { Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";

const DEFAULT_ROWS = 5;
const EXPANDED_ROWS = 7;

export const BrandFilterOptions = ({
  variant,
  onChange,
  brands,
  brandOptions,
}: BrandFilterProps) => {
  return (
    <>
      {brandOptions.map((brand, i) => {
        const checked = brands.includes(brand);
        return (
          <FilterOption
            variant={variant}
            key={i}
            label={brand}
            onClick={() => {
              if (checked) {
                onChange(brands.filter((b) => b !== brand));
              } else {
                onChange([brand, ...brands]);
              }
            }}
            checked={checked}
          />
        );
      })}
    </>
  );
};

interface BrandFilterProps {
  variant?: "dropdown" | "pill";
  brands: string[];
  brandOptions: string[];
  onChange: (brands: string[]) => void;
}

export const BrandFilter = ({
  brands,
  brandOptions,
  onChange,
}: BrandFilterProps) => {
  const [expanded, setExpanded] = useState(false);
  const rows = expanded ? EXPANDED_ROWS : DEFAULT_ROWS;
  return (
    <DropdownPill active={brands.length > 0} icon="polaroids" text="Brand">
      {brandOptions.length === 0 ? (
        <Text>You haven&apos;t booked any brands</Text>
      ) : (
        <>
          <Box
            css={{
              height:
                brandOptions.length < rows
                  ? 42 * (brandOptions.length || 1)
                  : rows * 42,
              overflowY: expanded ? "scroll" : "hidden",
              "&::-webkit-scrollbar": {
                backgroundColor: "$white",
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "$grey3",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "$grey2",
                borderRadius: "5px",
                height: "81px",
              },
            }}
          >
            <BrandFilterOptions
              brands={brands}
              brandOptions={brandOptions}
              onChange={onChange}
            />
          </Box>
          {!expanded && (
            <Box css={{ py: "$5", px: "$4" }}>
              <Text
                css={{
                  px: "$4",
                  pt: "$2",
                  textDecoration: "underline",
                  "&:Hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => setExpanded(true)}
              >
                See More
              </Text>
            </Box>
          )}
        </>
      )}
    </DropdownPill>
  );
};
