import React from "react";
import { capitalize } from "lodash";
import { DropdownPill } from "src/ccl/blocks/pill/DropdownPill";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { Scalars, TalentVertical, VerticalFieldInput } from "src/graphql/types";
import { verticalMap } from "src/utils/user";
import { Pill } from "src/ccl/blocks";

export const AdvancedFiltersDropdown = ({
  verticals,
  onClick,
  fields,
  advancedFilters,
  selectedFilter,
  setSelectedFilter,
}: {
  verticals: TalentVertical[];
  onClick: (vertical?: TalentVertical) => void;
  fields: VerticalFieldInput[];
  advancedFilters: Record<string, Scalars["VerticalFieldValueScalar"]>;
  selectedFilter?: string | null;
  setSelectedFilter?: (filter: string | null) => void;
}) => {
  if (!verticals.some((v) => v)) {
    verticals = Object.keys(verticalMap) as TalentVertical[];
  }

  const active =
    fields.some((f) => f) || Object.values(advancedFilters).some((v) => v);

  return verticals.length === 1 ? (
    <>
      <Pill
        css={{
          background: active ? "$black" : undefined,
          color: active ? "$white" : undefined,
        }}
        variant="rebrand"
        onClick={() => onClick()}
      >
        <Flex
          css={{
            alignItems: "center",
            mx: "$3",
            mr: "$4",
            "&:hover": { cursor: "pointer" },
          }}
        >
          <Icon
            variant="filterOutline"
            size={24}
            css={{
              m: "$3",
            }}
            color={active ? "white" : "grey6"}
          />
          <Text color={active ? "white" : "grey6"}>Advanced filters</Text>
        </Flex>
      </Pill>
      {/* This is a placeholder for the Icon in the dropdown pill below, otherwise the layout changes*/}
      <Box
        css={{
          mr: "26px",
        }}
      ></Box>
    </>
  ) : (
    <Box
      css={{
        // This makes sure the dropdowns don't intersect with the pills when they are on 2 rows
        "@bp4": {
          zIndex: "$300",
        },
        "@bp5": {
          zIndex: "initial",
        },
      }}
    >
      <DropdownPill
        active={active}
        icon="filterOutline"
        text="Advanced filters"
        withHoverEffect
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      >
        <Box css={{ py: "$5" }}>
          {verticals.map((vertical, i) => (
            <Flex
              key={i}
              onClick={() => onClick(vertical)}
              css={{
                justifyContent: "space-between",
                width: 247,
                py: "$3",
                px: "$4",
                borderRadius: "$md",
                "&:hover": { cursor: "pointer", backgroundColor: "$grey1" },
              }}
            >
              <Text>{capitalize(verticalMap[vertical])}s</Text>
              <Icon variant="chevronRight" css={{ my: "auto" }} />
            </Flex>
          ))}
        </Box>
      </DropdownPill>
    </Box>
  );
};
