import React from "react";
import { CSS, VariantProps } from "@stitches/react";
import { AvailableIcon, availableIcons } from "./availableIcons";
import { tokens, iconSizes } from "src/ccl/stitches/theme";
import { mergeCss, styled } from "src/ccl/stitches";
import { Box } from "src/ccl/layout";

export interface IconProps extends VariantProps<typeof Box> {
  variant: AvailableIcon;
  size?: keyof typeof iconSizes;
  color?: keyof typeof tokens.colors;
  className?: string;
  css?: CSS;
  solid?: boolean;
}

const StyledSvg = styled("svg", {});

export const Icon = ({
  variant,
  size = 16,
  color = "black",
  className,
  css = {},
  solid = true,
}: IconProps) => {
  const icon = availableIcons[variant];
  return (
    <StyledSvg
      viewBox={icon.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      css={mergeCss(
        {
          width: size,
          height: size,
        },
        css,
      )}
    >
      {icon.children(tokens.colors[color], solid)}
    </StyledSvg>
  );
};
