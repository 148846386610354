import React from "react";
import { SubNavItem } from "src/ccl/navigation/subNav/SubNavItem";
import { useIsMobile } from "src/hooks/useIsMobile";

export const TPAHeader = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <SubNavItem
        to="/dashboard/agent/jobs"
        match={/\/dashboard\/agent\/jobs/}
        title="Jobs"
        icon="jobs"
        css={{ width: "100%" }}
      />
      <SubNavItem
        to="/dashboard/agent/talent"
        match={/\/dashboard\/agent\/talent(|\/new)/}
        title="Talent"
        icon="user"
        css={{ width: "100%" }}
      />
      <SubNavItem
        to="/dashboard/agent/packages"
        match={/\/dashboard\/agent\/packages/}
        title="Packages"
        icon="package"
        css={{ width: "100%" }}
      />
      <SubNavItem
        to="/account/settings"
        match={/\/account/}
        title={isMobile ? undefined : "Settings"}
        icon="settings"
        css={{ "@initial": { width: "25%" }, "@bp2": { width: "100%" } }}
      />
    </>
  );
};
