import React from "react";
import { BlurredModels } from "./BlurredModels";
import { Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { LinkButton } from "src/ccl/navigation";

export const AgencyEmptyState = ({
  headingContent,
  leadInContent,
  buttonTo,
  buttonContent,
}: {
  headingContent: React.ReactNode;
  leadInContent: React.ReactNode;
  buttonTo?: string;
  buttonContent?: React.ReactNode;
}) => (
  <Box
    css={{
      position: "relative",
      pb: 200,
      overflowX: "hidden",
      "@bp2": { pb: 0 },
    }}
  >
    <Box
      css={{
        width: "100%",
        mt: "$15",
        position: "relative",
        "@bp2": {
          mt: "$20",
          width: "60%",
          pb: 0,
        },
        "@bp3": {
          width: "40%",
        },
      }}
    >
      <Text
        variant={{
          "@initial": "h2",
          "@bp2": "h1",
        }}
        css={{
          mb: "$5",
          "@bp1": {
            mb: "$5",
          },
          "@bp2": {
            mb: "$9",
          },
        }}
      >
        {headingContent}
      </Text>

      <Text
        variant="h4"
        css={{
          fontWeight: "$regular",
          mb: "$5",
          width: "100%",
          "@bp1": {
            mb: "$12",
          },
        }}
      >
        {leadInContent}
      </Text>

      {buttonTo && buttonContent && (
        <LinkButton variant="primary" to={buttonTo}>
          {buttonContent}
        </LinkButton>
      )}
    </Box>

    <BlurredModels
      css={{
        left: 0,
        top: "initial",
        "@bp2": {
          top: 110,
          right: 0,
          left: "initial",
        },
      }}
    />
  </Box>
);
