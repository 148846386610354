import React from "react";
import { ComponentProps } from "@stitches/react";
import { Text } from "src/ccl/document";

interface EmojiProps extends ComponentProps<typeof Text> {
  emoji: string;
  label: string;
}

export const Emoji = ({ label, emoji, css = {} }: EmojiProps) => (
  <Text role="img" aria-label={label} aria-hidden="false" as="span" css={css}>
    {emoji}
  </Text>
);
