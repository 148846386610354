import { buildOptionProps } from "./util";
import { FilterOption } from "src/ccl/filtering";
import { DropdownPill } from "src/ccl/blocks";
import { JobState } from "src/graphql/types";

const stateLabelMap: Record<JobState, string> = {
  [JobState.PendingApproval]: "Pending",
  [JobState.Approved]: "Creatives responding",
  [JobState.AwaitingPayment]: "Awaiting payment",
  [JobState.Finalized]: "Confirmed",
  [JobState.Completed]: "Completed",
  [JobState.Cancelled]: "Cancelled",
  [JobState.Expired]: "Expired",
  [JobState.Rejected]: "Rejected",
};

interface JobStateFilterProps {
  variant?: "dropdown" | "pill";
  availableStates: JobState[];
  states: JobState[];
  onChange: (states: JobState[]) => void;
}

export const JobStateOptions = ({
  variant,
  availableStates,
  states,
  onChange,
}: JobStateFilterProps) => (
  <>
    <FilterOption
      variant={variant}
      label="All"
      {...buildOptionProps(states, onChange)}
    />
    {availableStates.map((state, i) => (
      <FilterOption
        variant={variant}
        key={`filter-option-${i}`}
        label={stateLabelMap[state]}
        {...buildOptionProps(states, onChange, state)}
      />
    ))}
  </>
);

export const JobStateFilter = ({ ...props }: JobStateFilterProps) => {
  const active = props.states.length > 0;
  return (
    <DropdownPill
      active={active}
      text="Status"
      icon={active ? "newFacesInvert" : "newFaces"}
    >
      <JobStateOptions {...props} />
    </DropdownPill>
  );
};
