import { gql } from "@apollo/client";

export const DELETE_OUTBOUND_PAYMENT_MUTATION = gql`
  mutation DeleteOutboundPaymentMutation($outboundPaymentId: ID!) {
    deleteOutboundPayment(outboundPaymentId: $outboundPaymentId) {
      id
      state
    }
  }
`;
