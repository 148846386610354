import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Text, Icon } from "src/ccl/document";
import { Link } from "src/ccl/navigation";
import { sendToAmplitude } from "src/utils/analytics";
import { SubNavItem } from "src/ccl/navigation/subNav/SubNavItem";

export const BookerNav = () => {
  const { path } = useRouteMatch();

  const onSettingsPage =
    path === "/account/settings" ||
    path === "/account/password" ||
    path === "/account/delete";

  return (
    <Box
      css={{
        mb: "$6",
        width: "100%",
        backgroundColor: "$white",
        "@bp2": { backgroundColor: "$grey1", mb: "$9" },
        "@bp5": { mb: "$11" },
      }}
    >
      <Grid
        css={{
          px: "$0",
          gridColumns: "2fr 1.5fr 2.5fr 1fr",
          backgroundColor: "$white",
          borderTop: "1px solid $grey2",
          borderBottom: "1px solid $grey2",
          "@bp2": {
            gridColumns: 4,
            px: "$7",
            maxWidth: "1100px",
            margin: "0 auto",
            height: "60px",
            backgroundColor: "$grey1",
            border: "none",
          },
          "@bp5": {
            maxWidth: "1440px",
          },
        }}
      >
        <SubNavItem
          to="/dashboard/client"
          match={/^\/dashboard\/client$/}
          title="Home"
          icon="dashboard"
          tracking="client dashboard - clicks home in sub nav"
        />
        <SubNavItem
          to="/dashboard/client/jobs"
          match={/\/dashboard\/client\/jobs/}
          title="Jobs"
          icon="jobs"
          tracking="client dashboard - clicks jobs in sub nav"
        />
        <SubNavItem
          to="/dashboard/client/collections"
          match={/\/dashboard\/client\/(collections|bookmarks)/}
          title="Bookmarks"
          icon="bookmark"
          tracking="client dashboard - clicks bookmarks in sub nav"
        />

        <Link
          to={"/account/settings"}
          onClick={() =>
            sendToAmplitude("client dashboard - clicks settings sub nav")
          }
          css={{
            backgroundColor: onSettingsPage ? "black" : "inherit",
            textDecoration: "none",
            borderLeft: "1px solid $grey2",
            "@bp2": { borderLeft: "none" },
          }}
          data-test-id="NavSettingsButton"
        >
          <Flex
            css={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              gap: "$4",
            }}
            data-test-id="profileIcon"
          >
            <Icon
              color={onSettingsPage ? "white" : "black"}
              variant="settings"
              size={20}
            />
            <Text
              css={{ display: "none", "@bp2": { display: "block" } }}
              weight="bold"
              color={onSettingsPage ? "white" : "black"}
              variant="settings"
            >
              Settings
            </Text>
          </Flex>
        </Link>
      </Grid>
    </Box>
  );
};
