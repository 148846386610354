import React from "react";
import { Icon, Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";
import { JobStateBackground, JobStateColors } from "src/graphql/colors";
import { Job, JobState, JobTalentState } from "src/graphql/types";
import { jobStateTitle } from "src/utils/job";
import { uppercaseFirstLetter } from "src/utils/lang";
import { verticalMap } from "src/utils/user";

const agentDescriptions: Record<JobState, string> = {
  [JobState.PendingApproval]: "",
  [JobState.Approved]: "",
  [JobState.AwaitingPayment]: "",
  [JobState.Finalized]: "This job's going ahead.",
  [JobState.Completed]: "",
  [JobState.Expired]: "The job's client didn't confirm in time.",
  [JobState.Cancelled]: "",
  [JobState.Rejected]: "",
};

const getAgentDescriptionText = (job: Job) => {
  const prettyVertical =
    job.jobVerticals.length > 1
      ? "creative"
      : verticalMap[job.jobVerticals[0].vertical];
  if (job.startDate === JobState.Approved) {
    return `${uppercaseFirstLetter(
      prettyVertical,
    )}s must now respond before the client can confirm the job.`;
  }

  return agentDescriptions[job.state];
};

const bookerDescriptions: Record<JobState, string> = {
  [JobState.PendingApproval]: "Please wait for Contact to approve the job.",
  [JobState.Approved]: "",
  [JobState.AwaitingPayment]: "",
  [JobState.Finalized]: "",
  [JobState.Completed]: "🎉",
  [JobState.Expired]: "Your job wasn't confirmed in time.",
  [JobState.Cancelled]: "",
  [JobState.Rejected]: "",
};

const getBookerDescriptionText = (job: Job) => {
  let description = bookerDescriptions[job.state];
  const prettyVertical =
    job.jobVerticals.length > 1
      ? "creative"
      : verticalMap[job.jobVerticals[0].vertical];

  if (job.state === JobState.Approved) {
    const accepted = (job.talent || []).filter(
      (jt) => jt.state === JobTalentState.Accepted,
    ).length;
    const total = (job.talent || []).length;
    if (total === 1) {
      description =
        accepted === 1
          ? `The ${prettyVertical} has accepted the job.`
          : `The ${prettyVertical} hasn't accepted the job yet.`;
    } else {
      description = `${accepted} of ${total} ${prettyVertical}s have accepted so far.`;
    }
  } else if (job.state === JobState.Finalized) {
    const delta = new Date(job.startDate).getTime() - Date.now();
    if (delta > 0) {
      const daysUntilStart = Math.round(delta / (1000 * 3600 * 24));
      description = `${daysUntilStart} ${
        daysUntilStart === 1 ? "day" : "days"
      } until your job starts!`;
    } else {
      description = "";
    }
  }

  return description;
};

const getTalentDescriptionText = (job: Job) => {
  if (job.state === JobState.Finalized) {
    return "Confirmed";
  }

  return uppercaseFirstLetter(job.state.toLowerCase());
};

const getTransitionableText = (job: Job, context: Context) => {
  let text: string | null = null;

  if (
    job.state === JobState.Approved &&
    (job.talent || []).filter((jt) => jt.state === JobTalentState.Accepted)
      .length > 0
  ) {
    text =
      context === "client"
        ? "Job is available to be confirmed"
        : "Waiting for client confirmation";
  }

  return text;
};

const Container = styled("div", {
  width: "fit-content",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  px: "$3",
  py: "$2",
  mt: "$3",
  borderRadius: "$pill",
});

const TransitionableContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  pt: 0,
  "@bp2": {
    pt: "$1",
  },
});

const TransitionableText = styled(Text, {
  py: "$1",
  ml: "$2",
  fontSize: "$14",
});

const DescriptionText = styled(Text, {
  color: "$grey6",
  fontSize: "$14",
  pt: 0,
  "@bp2": {
    pt: "$1",
  },
});

type Context = "client" | "agent" | "talent";

interface JobStateLabelProps {
  job: Job;
  context: Context;
  variant?: "simple" | "full";
}

export const JobStateLabel = ({
  job,
  context,
  variant = "full",
}: JobStateLabelProps) => {
  const { state } = job;
  const backgroundColor = JobStateBackground[
    state
  ] as keyof typeof tokens.colors;
  const color = JobStateColors[state] as keyof typeof tokens.colors;

  const descriptions: Record<Context, string> = {
    agent: getAgentDescriptionText(job),
    client: getBookerDescriptionText(job),
    talent: getTalentDescriptionText(job),
  };

  const description = descriptions[context];
  const transitionableText = getTransitionableText(job, context);

  const title =
    job.state === JobState.Approved &&
    variant === "simple" &&
    context === "talent"
      ? "In Shortlist"
      : jobStateTitle(job.state);

  return (
    <Container
      css={{
        background: `$${backgroundColor}` as keyof typeof tokens.colors,
      }}
    >
      <Text
        variant="b3Bold"
        css={{ color: `$${color}` as keyof typeof tokens.colors }}
      >
        {title}
      </Text>

      {variant === "full" && (
        <>
          {transitionableText && (
            <TransitionableContainer>
              <Icon variant="ripple" size={20} color={color} />

              <TransitionableText>{transitionableText}</TransitionableText>
            </TransitionableContainer>
          )}

          {description !== "" && (
            <DescriptionText>{description}</DescriptionText>
          )}
        </>
      )}
    </Container>
  );
};
