import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { noop } from "lodash";
import { useStoreModel, useUserKindContext } from "src/hooks";
import { sendToAmplitude } from "src/utils/analytics";
import { SideNavigationMiniItem } from "src/ccl/navigation";
import { Flex, Box } from "src/ccl/layout";
import { MoreDropdown } from "src/components";
import {
  TalentProfile,
  User,
  MutationResendOnboardEmailArgs,
  Mutation,
  MutationSendPaymentVerificationEmailArgs,
} from "src/graphql/types";
import { SEND_PAYMENT_VERIFICATION_EMAIL_MUTATION } from "src/graphql/mutations";
import { RESEND_ONBOARD_EMAIL_MUTATION } from "src/graphql/mutations/agent/ResendOnboardEmail";
import { ValidationBlock } from "src/ccl/feedback";
import { baseUrl } from "src/config";

interface ADEditProfileNavProps {
  user?: User;
  loaded?: boolean;
}

export const ADEditProfileNav = ({
  user,
  loaded = false,
}: ADEditProfileNavProps) => {
  const [resentOnboardingEmail, setResentOnboardingEmail] = useState(false);
  const [resentPaymentVerificationEmail, setResentPaymentVerificationEmail] =
    useState(false);
  const { pathname } = useLocation();
  const currentUser = useStoreModel("currentUser");
  const { isFirstPartyAgent } = currentUser;
  const userKindContext = useUserKindContext(currentUser);
  const agencyName = currentUser.me?.agency?.name;

  const slug = user?.slug || "";
  const profile = user?.profile as TalentProfile;

  const [resendOnboardingEmail, { error: resendOnboardingError }] = useMutation<
    Mutation,
    MutationResendOnboardEmailArgs
  >(RESEND_ONBOARD_EMAIL_MUTATION, {
    variables: {
      userId: user?.id || "",
    },
    onCompleted: (data) => {
      if (data.resendOnboardEmail?.id) {
        setResentOnboardingEmail(true);
        setTimeout(() => setResentOnboardingEmail(false), 5000);
      }
    },
    onError: noop,
  });

  const [
    resendPaymentVerificationEmail,
    { error: resendPaymentVerificationError },
  ] = useMutation<Mutation, MutationSendPaymentVerificationEmailArgs>(
    SEND_PAYMENT_VERIFICATION_EMAIL_MUTATION,
    {
      variables: {
        userId: user?.id || "",
      },
      onCompleted: (data) => {
        if (data.sendPaymentVerificationEmail?.id) {
          setResentPaymentVerificationEmail(true);
          setTimeout(() => setResentPaymentVerificationEmail(false), 5000);
        }
      },
      onError: noop,
    },
  );

  const ActionItems = () => (
    <>
      {isFirstPartyAgent && (
        <>
          <SideNavigationMiniItem
            to="#"
            onClick={() => !resentOnboardingEmail && resendOnboardingEmail()}
            text={
              resendOnboardingError
                ? "Something went wrong..."
                : resentOnboardingEmail
                ? "Sent!"
                : "Resend onboarding email"
            }
            icon="plane"
          />
          {user && !user.trustshareVerified && (
            <SideNavigationMiniItem
              to="#"
              onClick={() =>
                !resentPaymentVerificationEmail &&
                resendPaymentVerificationEmail()
              }
              text={
                resendPaymentVerificationError
                  ? "Something went wrong..."
                  : resentPaymentVerificationEmail
                  ? "Sent!"
                  : "Resend Trustshare verification email"
              }
              icon="plane"
            />
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <Flex
        css={{
          width: "100%",
          "@bp2": { width: "25%", flexDirection: "column" },
          "@bp4": { width: "33%" },
        }}
        data-test-id="ADEditProfileNav"
      >
        <MoreDropdown>
          {loaded && (
            <>
              {resendOnboardingError && (
                <ValidationBlock
                  variant="error"
                  title={resendOnboardingError.message}
                />
              )}
              <SideNavigationMiniItem
                linkType="external"
                to={`${baseUrl}/creatives/${slug}`}
                eventName={"agencyDashboard:editTalent:sendEmail"}
                target="_blank"
                text="View public profile"
                onClick={() => {
                  sendToAmplitude("creative profile - views public profile", {
                    url: pathname,
                    userType: userKindContext,
                    agency: agencyName,
                  });
                }}
                icon="eye"
              />

              {profile.cardUrl && (
                <SideNavigationMiniItem
                  linkType="external"
                  to={profile.cardUrl}
                  eventName={"agencyDashboard:editTalent:downloadCard"}
                  target="_blank"
                  text="Download PDF card"
                  onClick={() => {
                    sendToAmplitude("creative profile - downloads PDF", {
                      url: pathname,
                      userType: userKindContext,
                      agency: agencyName,
                    });
                  }}
                  icon="download"
                />
              )}

              <ActionItems />
            </>
          )}
        </MoreDropdown>

        <Box css={{ display: "none", "@bp2": { display: "block" } }}>
          {loaded && (
            <Box css={{ width: "100%", "@bp1": { width: "90%" } }}>
              {resendOnboardingError && (
                <ValidationBlock
                  variant="error"
                  title={resendOnboardingError.message}
                />
              )}

              <ActionItems />
            </Box>
          )}
        </Box>
      </Flex>
    </>
  );
};
