import React from "react";
import { UrlStateSchemaFilter } from "./UrlStateSchemaFilter";
import { Filter, FilterContext } from "src/graphql/types";
import { FilterGroup } from "src/ccl/filtering";
import { useURLStringSearchParam } from "src/hooks";
import { Flex, Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";

export const UrlStateFilterGroup = ({
  filter,
  expanded,
  context,
}: {
  filter: Filter;
  expanded: boolean;
  context: FilterContext;
}) => {
  const activeChildFilters: string[] = [];
  filter.childFilters?.forEach((f) => {
    const [val] = useURLStringSearchParam(f.name);
    if (val !== "") {
      activeChildFilters.push(f.name);
    }
  });
  const title =
    activeChildFilters.length > 0 ? (
      <Flex css={{ gap: "$4" }}>
        <Text css={{ fontWeight: "$bold" }}>{filter.label}</Text>
        <Box
          css={{
            backgroundColor: "$black",
            color: "$white",
            borderRadius: "$round",
            width: 26,
            textAlign: "center",
          }}
        >
          {activeChildFilters.length}
        </Box>
      </Flex>
    ) : (
      filter.label
    );

  return (
    <FilterGroup
      title={title}
      expandedTitle={
        filter.expandedTitleFilter ? (
          <UrlStateSchemaFilter
            filter={filter.expandedTitleFilter}
            expanded={false}
            context={context}
          />
        ) : undefined
      }
      expanded={expanded || activeChildFilters.length > 0}
      onCollapse={() => {}}
      containerCss={{
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      {filter.childFilters?.map((filter, i) => (
        <UrlStateSchemaFilter
          key={i}
          filter={filter}
          expanded={activeChildFilters.includes(filter.name)}
          context={context}
        />
      ))}
    </FilterGroup>
  );
};
