import { Computed, computed } from "easy-peasy";
import { CurrentUserModel } from "./currentUser";

export interface UtilsModel {
  avatarInitials: Computed<CurrentUserModel, string | undefined>;
}

export const utilsModel: UtilsModel = {
  avatarInitials: computed((state) =>
    state.me?.name
      ?.split(" ")
      ?.map((n) => n[0])
      ?.join(""),
  ),
};
