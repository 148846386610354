import React from "react";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { TalentVertical } from "src/graphql/types";
import { styled } from "src/ccl";
import { LinkButton } from "src/ccl/navigation";
import { JobTypeIconMap } from "src/utils/job";
import { verticalMap } from "src/utils/user";
import { sendToAmplitude } from "src/utils/analytics";

const Legend = styled("legend", {
  width: "auto",
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  "@bp3": {
    width: "90%",
  },
});

const Fieldset = styled("fieldset", {
  border: "2px solid $grey2",
});

const VerticalLink = ({
  vertical,
  to,
  page,
}: {
  vertical: TalentVertical;
  to: string;
  page: string;
}) => (
  <LinkButton
    data-test-id="UpsellLink"
    css={{
      backgroundColor: "$white",
      display: "block",
      width: "100%",
      p: "$0",
      border: "1px solid $black",
      "&:hover": { backgroundColor: "$grey1" },
    }}
    to={to}
    onClick={() =>
      sendToAmplitude(`${page} - clicks vertical upsell`, {
        vertical: vertical,
      })
    }
  >
    <Flex css={{ p: "$4", alignItems: "center" }}>
      <Icon variant={JobTypeIconMap[vertical]} size={20} />
      <Text variant="mini" css={{ ml: "$4", flexGrow: 1, textAlign: "left" }}>
        Add {verticalMap[vertical]}s
      </Text>
      <Icon variant="chevronRight" size={16} />
    </Flex>
  </LinkButton>
);

interface AdditionalVerticalUpsellProps {
  additionalVerticals: TalentVertical[];
  page: string;
  to: string;
  verticalUrlParam?: string;
}

export const AdditionalVerticalUpsell = ({
  additionalVerticals,
  page,
  to,
  verticalUrlParam = "vertical",
}: AdditionalVerticalUpsellProps) => (
  <Box data-test-id="AdditionalVerticalUpsell">
    <Fieldset>
      <Legend>
        <Flex css={{ px: "$4", alignItems: "center", "@bp5": { px: "$3" } }}>
          <Box css={{ display: "block", "@bp3": { display: "none" } }}>
            <Icon variant="community" size={40} />
          </Box>
          <Box css={{ display: "none", "@bp3": { display: "block" } }}>
            <Icon variant="community" size={60} />
          </Box>
          <Text
            variant={{ "@initial": "buttonText", "@bp4": "h3" }}
            css={{ ml: "$3", "@bp1": { ml: "$4" } }}
          >
            Need more creatives?
          </Text>
        </Flex>
      </Legend>

      <Box css={{ p: "$6" }}>
        <Text css={{ mb: "$8" }} color="grey6">
          Add other types of creatives to your shortlist.
        </Text>

        <Flex css={{ flexDirection: "column", rowGap: "$3" }}>
          {additionalVerticals.map((v) => (
            <VerticalLink
              key={`additional-vertical-upsell-${v}`}
              vertical={v}
              to={`${to}?${verticalUrlParam}=${v.toLowerCase()}`}
              page={page}
            />
          ))}
        </Flex>
      </Box>
    </Fieldset>
  </Box>
);
