import { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Icon, Modal, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { UploadedFile } from "src/entities";
import { GENERATE_PRESIGNED_URL_MUTATION } from "src/graphql/mutations";
import { Mutation, MutationGeneratePresignedUrlArgs } from "src/graphql/types";
import { useStoreModel } from "src/hooks";
import { FeaturedAsset } from "src/models/agencies/addTalentDraft";
import { computeChecksum } from "src/utils/checksum";

interface CropModalProps {
  isOpen: boolean;
  onClose: () => void;
  featuredAsset?: FeaturedAsset;
  onUpload: (files: UploadedFile[]) => void;
}

export const CropModal = ({
  isOpen,
  onClose,
  featuredAsset,
  onUpload,
}: CropModalProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [cropping, setCropping] = useState(false);

  const [generatePresignedUrl] = useMutation<
    Mutation,
    MutationGeneratePresignedUrlArgs
  >(GENERATE_PRESIGNED_URL_MUTATION);
  const { uploadFile: uploadFileToS3 } = useStoreModel("s3");

  const onCrop = () => {
    setCropping(true);
    if (typeof cropperRef.current?.cropper !== "undefined") {
      cropperRef.current?.cropper.getCroppedCanvas().toBlob(async (blob) => {
        if (blob) {
          const croppedFile = new File(
            [blob],
            `${Date.now()}-cropped-image.jpg`,
            {
              type: "image/jpeg",
              lastModified: Date.now(),
            },
          );

          const checksum = await computeChecksum(croppedFile);

          const generateUrlRsp = await generatePresignedUrl({
            variables: {
              attachment: "uploads",
              byteSize: croppedFile.size,
              checksum,
              contentType: croppedFile.type,
              filename: croppedFile.name,
            },
          });

          const data = generateUrlRsp?.data?.generatePresignedUrl;

          if (data) {
            const headers: Headers = new Headers();
            data.headers.forEach((h) => {
              headers.append(h.key, h.value);
            });

            const uploadRsp = await uploadFileToS3.request({
              url: data.url,
              headers: headers,
              body: croppedFile,
            });

            if (uploadRsp) {
              onUpload([
                {
                  key: data.key,
                  filename: croppedFile.name,
                  contentType: croppedFile.type,
                  contentLength: croppedFile.size,
                  mediaUrl: URL.createObjectURL(croppedFile),
                },
              ]);
            }
          }
        }

        setCropping(false);
        onClose();
      });
    }
  };

  return (
    <Modal
      width="wide"
      isOpen={isOpen}
      showCloseButton={true}
      onClose={onClose}
    >
      <Flex
        data-test-id="CropModal"
        css={{
          flexDirection: "column",
          gap: "$9",
        }}
      >
        <Flex
          css={{
            flexDirection: "column",
            gap: "$7",
          }}
        >
          <Text variant="nh3">Crop profile image</Text>
          <Flex
            css={{
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Icon variant="dragArrow" color="grey6" />
            <Text variant="b3">Drag and scale to crop the image</Text>
          </Flex>
        </Flex>
        <Box>
          <Cropper
            src={featuredAsset?.mediaUrl}
            style={{ height: 400, width: "100%" }}
            guides={false}
            ref={cropperRef}
            background={false}
          />
        </Box>
        <Flex
          css={{
            flexDirection: "column-reverse",
            gap: "18px",
            "@sm": {
              flexDirection: "row",
              justifyContent: "space-between",
            },
          }}
        >
          <Button
            variant="secondaryCta"
            onClick={onClose}
            css={{
              width: "100%",
              "@sm": {
                width: "fit-content",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primaryCta"
            css={{
              width: "100%",
              "@sm": {
                width: "fit-content",
              },
            }}
            onClick={onCrop}
            disabled={cropping}
          >
            {cropping ? "Loading..." : "Crop"}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
