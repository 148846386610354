import { Box } from "src/ccl/layout";
import { FilterContext, TalentVertical } from "src/graphql/types";
import { useVerticalConfigurations } from "src/hooks";
import { getConfigForVertical } from "src/utils/user";
import { Loading } from "src/components/Loading";
import { UrlStateSchemaFilter } from "src/ccl/filtering";

export const AdvancedFilters = ({
  heightConstrained,
  vertical,
  context,
}: {
  heightConstrained?: boolean;
  vertical: TalentVertical;
  context: FilterContext;
}) => {
  const { configurations, loading: configsLoading } =
    useVerticalConfigurations();
  const verticalConfig =
    vertical && configurations
      ? getConfigForVertical(configurations, vertical)
      : undefined;

  return (
    <Box
      css={{
        maxHeight: heightConstrained ? "60vh" : "initial",
        minHeight: heightConstrained ? "350px" : "initial",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          backgroundColor: "$white",
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "$grey3",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "$grey2",
          borderRadius: "5px",
          height: "81px",
        },
      }}
    >
      {!verticalConfig || configsLoading ? (
        <Loading />
      ) : (
        <>
          {verticalConfig.advancedFilters.map((filter, i) => (
            <UrlStateSchemaFilter
              key={i}
              filter={filter}
              expanded={false}
              context={context}
            />
          ))}
        </>
      )}
    </Box>
  );
};
