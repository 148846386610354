import React from "react";
import { Box, Flex } from "src/ccl/layout";
import { Img, Text } from "src/ccl/document";
import { LinkButton } from "src/ccl/navigation";
import { sendToAmplitude } from "src/utils/analytics";
import noCollections from "src/assets/collections/noCollections.png";

export const NoBookmarkCollectionsCard = () => (
  <Box
    css={{
      pl: "$14",
      borderTop: "1px solid $grey3",
      borderBottom: "1px solid $grey3",
      pt: "$8",
      pb: "$18",
      "@bp2": {
        pl: "unset",
        py: "unset",
        maxWidth: 540,
        borderTop: "unset",
        borderBottom: "unset",
      },
    }}
    data-test-id="BookmarkCollections"
  >
    <Text variant="h2" css={{ pb: "$16" }}>
      Your collections
    </Text>
    <Flex>
      <Img
        src={noCollections}
        css={{
          width: "144px",
          height: "144px",
          mr: "$5",
          "@bp3": { width: "200px", height: "200px", mr: "$10" },
        }}
      />

      <Box>
        <Text css={{ color: "$grey6", "@bp3": { color: "unset" } }}>
          Organise your bookmarked creatives with collections.
        </Text>
        <LinkButton
          to="/dashboard/client/collections"
          css={{ mt: "$6" }}
          variant="buttonText"
          onClick={() =>
            sendToAmplitude(
              "client dashboard - clicks create collection empty state",
            )
          }
        >
          Create collection
        </LinkButton>
      </Box>
    </Flex>
  </Box>
);
