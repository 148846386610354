import React, { useState } from "react";
import { styled } from "src/ccl";
import { Flex } from "src/ccl/layout";
import { PortfolioImage as PortfolioImageType } from "src/graphql/types";

const Img = styled("img");

const loadImages = (sources: string[], onLoad: () => void) => {
  let loadedCount = 0;
  for (let i = 0; i < sources.length; i++) {
    const img = new Image();
    img.src = sources[0];
    img.onload = () => {
      loadedCount++;
      if (loadedCount === sources.length) {
        onLoad();
      }
    };
  }
};

const buildImageUrl = (
  sourceUrl: string,
  width: number | undefined,
  height: number | undefined,
  dpr: number,
) => {
  const params = new URLSearchParams();
  params.set("auto", "format");
  if (width !== undefined) {
    params.set("w", width.toString());
  }
  if (height !== undefined) {
    params.set("h", height.toString());
  }
  params.set("dpr", dpr.toString());
  return `${sourceUrl}?${params.toString()}`;
};

interface FullImageProps {
  asset: PortfolioImageType;
  maxWidth?: number;
  maxHeight?: number;
  alt: string;
}

export const PortfolioImage = ({
  asset,
  maxWidth,
  maxHeight,
  alt,
}: FullImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const oneXSrc = buildImageUrl(asset.mediaUrl, maxWidth, maxHeight, 1);
  const twoXSrc = buildImageUrl(asset.mediaUrl, maxWidth, maxHeight, 2);

  loadImages([oneXSrc, twoXSrc], () => setLoaded(true));

  let aspectRatio = "auto";
  if (
    maxWidth !== undefined &&
    maxHeight !== undefined &&
    maxWidth === maxHeight
  ) {
    aspectRatio = "1";
  } else if (
    maxHeight === undefined &&
    maxWidth === undefined &&
    asset.height &&
    asset.width
  ) {
    aspectRatio = `${asset.width / asset.height}`;
  }

  return (
    <Flex
      css={{
        width: "100%",
        aspectRatio: aspectRatio,
        height: loaded ? "auto" : maxHeight ? `${maxHeight}px` : "auto",
        backgroundColor: loaded ? "transparent" : "$white",
        margin: "0 0 0.5em",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Img
        css={{
          transition: "0.3s opacity ease",
          opacity: loaded ? 1 : 0,
          borderRadius: "$lg",
        }}
        src={oneXSrc}
        srcSet={`${oneXSrc}, ${twoXSrc} 2x`}
        alt={alt}
      />
    </Flex>
  );
};
