import { gql } from "@apollo/client";

export const AGENCY_COMMISSION_RATES_HISTORY = gql`
  query Agency(
    $id: ID!
    $first: Int!
    $after: String
    $sortOrder: SortOrderInput
  ) {
    agency(id: $id) {
      id
      lastCommisionRateHistoryUpdate
      commissionRateHistory(
        first: $first
        after: $after
        sortOrder: $sortOrder
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            updatedAt
            percentage
          }
        }
      }
    }
  }
`;
