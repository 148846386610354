import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { map } from "lodash";
import { JobTalentRejectionReasons } from "./JobTalentRejectionReasons";
import { Modal, Text } from "src/ccl/document";
import { Flex, Box } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { Checkbox } from "src/ccl/data-entry";
import {
  JobTalentRejectionReason,
  JobTalentStateEvent,
  Mutation,
  MutationTalentJobResponseArgs,
  Query,
} from "src/graphql/types";
import { CHANGE_JOB_TALENT_RESPONSE_STATE_MUTATION } from "src/graphql/mutations";
import { JOB_TALENT_REJECTION_REASONS_QUERY } from "src/graphql/queries";

const responseDescriptions: Record<
  JobTalentStateEvent,
  { label: string; description: string }
> = {
  [JobTalentStateEvent.Accept]: {
    label: "Accepted",
    description: "Accept the shortlist invitation",
  },
  [JobTalentStateEvent.AwaitConfirmation]: {
    label: "", // this event/state is never returned from the API
    description: "",
  },
  [JobTalentStateEvent.Confirm]: {
    label: "Confirmed",
    description: "Confirm the talent will be working on the job",
  },
  [JobTalentStateEvent.Dispute]: {
    label: "Disputed",
    description: "Mark as disputed, something went wrong.",
  },
  [JobTalentStateEvent.Pay]: { label: "Paid", description: "Pay the talent" },
  [JobTalentStateEvent.Reject]: {
    label: "Unavailable",
    description:
      "Reject on the talent's behalf; they don't want to work on the job",
  },
  [JobTalentStateEvent.RejectByBooker]: {
    label: "Rejected by booker",
    description: "Reject on the booker's behalf; the talent isn't a good fit",
  },
  [JobTalentStateEvent.Approve]: {
    label: "",
    description: "",
  },
};

interface EditCreativeResponseModalProps {
  isOpen: boolean;
  options: JobTalentStateEvent[];
  onCancel: () => void;
  onConfirm: () => void;
  jobId: string;
  jobTalentId: string;
  creativeName: string;
}

export const EditCreativeResponseModal = ({
  isOpen,
  onCancel,
  onConfirm,
  options,
  jobId,
  jobTalentId,
  creativeName,
}: EditCreativeResponseModalProps) => {
  const [selectedOption, setSelectedOption] =
    useState<JobTalentStateEvent | null>(null);
  const [rejectionReason, setRejectionReason] = useState<string | undefined>(
    undefined,
  );
  const [rejectionNote, setRejectionNote] = useState<string | undefined>(
    undefined,
  );

  const [changeJobTalentReponseState, { loading }] = useMutation<
    Mutation,
    MutationTalentJobResponseArgs
  >(CHANGE_JOB_TALENT_RESPONSE_STATE_MUTATION);

  const { data } = useQuery<Query>(JOB_TALENT_REJECTION_REASONS_QUERY);
  const rejectionReasons = data?.jobTalentRejectionReasons || [];

  const submitButtonDisabled = () => {
    if (selectedOption === null) {
      return true;
    }

    if (selectedOption === JobTalentStateEvent.Reject) {
      if (rejectionReason === undefined) {
        return true;
      }

      if (
        rejectionReason === JobTalentRejectionReason.Other &&
        (rejectionNote === undefined || rejectionNote.trim() === "")
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Edit Creative Response"
      onClose={onCancel}
      width="wide"
    >
      <Text>
        Adjust the response of <strong>{creativeName}</strong>? Changing the
        response will alert the creative and the booker via email.
      </Text>

      <Box css={{ mt: "$6" }}>
        {map(options, (option) => (
          <React.Fragment key={`edit-creative-response-${option}`}>
            <Flex css={{ mb: "$4", alignItems: "center" }}>
              <Checkbox
                checked={selectedOption === option}
                onChange={() => setSelectedOption(option)}
                id={`job-talent-response-${option}`}
              />

              <Box
                as="label"
                htmlFor={`job-talent-response-${option}`}
                css={{ cursor: "pointer" }}
              >
                <Text css={{ ml: "$4" }}>
                  {responseDescriptions[option]?.label}
                </Text>
                <Text variant="meta" color="grey6" css={{ ml: "$4" }}>
                  {responseDescriptions[option]?.description}
                </Text>
              </Box>
            </Flex>

            <Flex
              css={{
                flexDirection: "column",
                borderLeftColor: "$grey3",
                borderLeftWidth: 4,
                borderLeftStyle: "solid",
                ml: "$2",
                pl: "$3",
                mt: "$5",
                mb: "$3",
                "@bp3": {
                  ml: "$16",
                  pl: "$6",
                },
              }}
            >
              {option === JobTalentStateEvent.Reject &&
                selectedOption === JobTalentStateEvent.Reject && (
                  <JobTalentRejectionReasons
                    rejectionReasons={rejectionReasons}
                    onReasonChange={(reason) => setRejectionReason(reason)}
                    onNoteChange={(note) => setRejectionNote(note)}
                    selectedRejectionReason={rejectionReason}
                  />
                )}
            </Flex>
          </React.Fragment>
        ))}
      </Box>

      <Flex
        css={{
          flexDirection: "column-reverse",
          mt: "$9",
          justifyContent: "space-between",
          "@bp2": { flexDirection: "row" },
        }}
      >
        <Button
          variant="secondary"
          onClick={onCancel}
          css={{ width: "100%", "@bp2": { width: "initial" } }}
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          data-test-id="EditCreativeResponseChangeStateButton"
          onClick={() => {
            if (selectedOption !== null) {
              changeJobTalentReponseState({
                variables: {
                  jobId: jobId,
                  jobTalentId: jobTalentId,
                  response: selectedOption,
                  rejectionReason:
                    rejectionReason === undefined
                      ? undefined
                      : (rejectionReason as JobTalentRejectionReason),
                  rejectionNote: rejectionNote,
                },
              });

              onConfirm();
            }
          }}
          disabled={submitButtonDisabled()}
          css={{
            width: "100%",
            mb: "$5",
            "@bp2": { width: "initial", mb: 0 },
          }}
        >
          {loading ? "Loading..." : "Change state"}
        </Button>
      </Flex>
    </Modal>
  );
};
