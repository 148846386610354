import { gql } from "@apollo/client";

export const BOOKING_COMPANY_MEMBERS_QUERY = gql`
  query BookingCompanyMembers {
    me {
      id
      bookerProfile {
        bookingCompany {
          id
          members {
            id
            name
            featuredAsset {
              mediaUrl
            }
          }
          bookerInvitations {
            id
            email
            createdAt
            isExistingAccount
          }
        }
      }
    }
  }
`;
