import React from "react";
import { styled } from "src/ccl/stitches";

export interface ProgressBarProps {
  max: number;
  progress: number;
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "row",
  borderWidth: 2,
  borderColor: "$black",
  borderStyle: "solid",
  backgroundColor: "$black",
});

const Box = styled("div", {
  height: "4px",
  backgroundColor: "$white",
});

export const ProgressBar = ({ max, progress }: ProgressBarProps) => (
  <Container>
    <Box css={{ width: `${(progress / max) * 100}%` }} />
  </Container>
);
