import { Thunk, thunk, action, Action, computed, Computed } from "easy-peasy";
import { BlogPost, transformEntity } from "src/models/contentful/blogPost";
import {
  ContentfulResEntity,
  ContentfulReqEntity,
} from "src/entities/contentful";
import { Status } from "src/entities";
import { ContentfulApiError, contentfulRequest } from "src/utils/contentful";

export interface ContentfulModel {
  status: Status;
  data?: ContentfulResEntity | null;
  error?: ContentfulApiError;
  setLoading: Action<ContentfulModel>;
  setData: Action<ContentfulModel, ContentfulResEntity>;
  setError: Action<ContentfulModel, ContentfulApiError>;
  request: Thunk<ContentfulModel, ContentfulReqEntity>;
  blogPost: Computed<ContentfulModel, BlogPost | null>;
  blogPosts: Computed<ContentfulModel, BlogPost[]>;
}

export const contentfulModel: ContentfulModel = {
  status: "pending",
  setLoading: action((state) => {
    state.data = null;
    state.status = "loading";
  }),
  setData: action((state, payload) => {
    state.data = payload;
    state.status = "loaded";
  }),
  setError: action((state, payload) => {
    state.error = payload;
    state.status = "error";
  }),
  request: thunk(async (actions, req) => {
    actions.setLoading();

    try {
      const data = await contentfulRequest(req);
      actions.setData(data);
    } catch (e) {
      if (e instanceof ContentfulApiError) {
        actions.setError(e);
      }
    }
  }),
  blogPost: computed((state) =>
    state.data?.items.length === 1
      ? transformEntity(state.data, state.data?.items[0])
      : null,
  ),
  blogPosts: computed((state) => {
    const items = state.data?.items || [];

    const posts = items.map(
      (entity) => state.data && transformEntity(state.data, entity),
    );
    return posts.filter((p) => p !== null) as BlogPost[];
  }),
};
