import React, { PropsWithChildren, useEffect, useState } from "react";
import type * as Stitches from "@stitches/react";
import { Form } from "formik";
import { Box, ContentContainer, Flex } from "..";
import { StickyActionBar } from "src/components";
import { mergeCss } from "src/ccl/stitches";
import { useIntersect } from "src/hooks/useIntersect";

interface FormLayoutProps {
  header?: React.ReactNode;
  accessory?: React.ReactNode;
  actionBar?: React.ReactNode;
  widthConstrained?: boolean;
  wrapperComponent?: React.FC<PropsWithChildren>;
  actionBarAlwaysVisible?: boolean;
  actionBarCSS?: Stitches.CSS;
  headerCSS?: Stitches.CSS;
  wrapperCSS?: Stitches.CSS;
  flexCSS?: Stitches.CSS;
  accessoryCSS?: Stitches.CSS;
  children?: React.ReactNode;
  variant?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const FormLayout = ({
  header,
  accessory,
  actionBar,
  wrapperComponent: WrapperComponent = Form,
  widthConstrained = true,
  actionBarAlwaysVisible = false,
  children,
  actionBarCSS = {},
  headerCSS = {},
  wrapperCSS = {},
  flexCSS = {},
  accessoryCSS = {
    "@bp2": {
      width: accessory ? "75%" : "100%",
    },
    "@bp4": {
      width: accessory ? "80%" : "100%",
    },
  },
  variant = "gutter",
}: FormLayoutProps) => {
  const Wrapper = widthConstrained ? ContentContainer : Box;
  const [showActionBarOnDesktop, setShowActionBarOnDesktop] = useState(false);
  const [actionBarSentinelRef, entry] = useIntersect();

  useEffect(() => {
    if (!entry) {
      return;
    }
    setShowActionBarOnDesktop(!entry.isIntersecting);
  }, [entry]);

  return (
    <WrapperComponent>
      <Wrapper variant={variant} css={mergeCss({}, wrapperCSS)}>
        <Box
          css={mergeCss(
            {
              display: "none",

              "@bp4": { display: "block" },
            },
            headerCSS,
          )}
        >
          {header}
        </Box>
        <div ref={actionBarSentinelRef} />
        <Flex
          css={mergeCss({ flexWrap: "wrap", alignItems: "stretch" }, flexCSS)}
        >
          {accessory}
          <Box css={mergeCss({ width: "100%" }, accessoryCSS)}>{children}</Box>
        </Flex>
      </Wrapper>

      {actionBar && (
        <StickyActionBar
          css={mergeCss({}, actionBarCSS)}
          visible={actionBarAlwaysVisible || showActionBarOnDesktop}
          animated
        >
          {actionBar}
        </StickyActionBar>
      )}
    </WrapperComponent>
  );
};
