import { gql } from "@apollo/client";

export const BOOKER_BRANDS_QUERY = gql`
  query BookerBrands {
    me {
      profile {
        ... on BookerProfile {
          brands
        }
      }
    }
  }
`;
