import type * as Stitches from "@stitches/react";
import { startOfToday } from "date-fns";
import { difference, sum } from "lodash";
import { jobTalentWithState } from "./jobTalent";
import { isVerticalMember } from "./user";
import { niceStateText } from "./lang";
import {
  AgencyJobState,
  Assignee,
  CreateJobVerticalInput,
  Job,
  JobCancellationReason,
  JobKind,
  JobRejectionReason,
  JobState,
  JobTalent,
  JobTalentState,
  JobVertical,
  MediaLocation,
  MediaType,
  MutationCreateJobArgs,
  PaymentMethod,
  TalentProfile,
  TalentVertical,
  VerticalConfiguration,
  HeadcountEstimate,
  DeckFile,
} from "src/graphql/types";
import { formatDate, isBetween } from "src/utils/dates";
import { commissionMultiplier } from "src/config";
import { AvailableIcon } from "src/ccl/document";

export const jobStateTitle = (state: JobState): string => {
  return {
    PENDING_APPROVAL: "Pending Approval",
    APPROVED: "Approved by Contact",
    AWAITING_PAYMENT: "Awaiting Payment",
    FINALIZED: "Confirmed",
    COMPLETED: "Completed",
    EXPIRED: "Expired",
    CANCELLED: "Cancelled",
    REJECTED: "Rejected",
  }[state];
};

export const jobPriceForModels = (budget: number, talentRequired: number) =>
  budget * talentRequired;

export const jobBookingFee = (budget: number, talentRequired: number) =>
  jobPriceForModels(budget, talentRequired) * commissionMultiplier;

export const jobVatValue = (budget: number, talentRequired: number) =>
  (jobPriceForModels(budget, talentRequired) +
    jobBookingFee(budget, talentRequired)) *
  commissionMultiplier;

export const jobTotalValue = (budget: number, talentRequired: number) =>
  jobPriceForModels(budget, talentRequired) +
  jobBookingFee(budget, talentRequired) +
  jobVatValue(budget, talentRequired);

export const jobPriceForCreatives = (values: number[]) => sum(values);

export const jobTotalBookingFee = (values: number[]) =>
  jobPriceForCreatives(values) * commissionMultiplier;

export const BloomBookingFeePercentage = 12;
export const jobBloomBookingFee = (values: number[]) =>
  jobPriceForCreatives(values) * (BloomBookingFeePercentage / 100);

export const PlatformFeePercentage = 5;
export const jobPlatformFee = (values: number[]) =>
  jobPriceForCreatives(values) * (PlatformFeePercentage / 100);

export const TransactionFeePercentage = 3;
export const jobTransactionFee = (values: number[]) =>
  jobPriceForCreatives(values) * (TransactionFeePercentage / 100);

export const jobTotalVatValue = (values: number[]) => {
  return (
    (jobPriceForCreatives(values) + jobTotalBookingFee(values)) *
    commissionMultiplier
  );
};

export const jobPaidUpfront = (job: Job) =>
  !!(job.paymentMethod && job.paymentMethod !== PaymentMethod.Invoice);

export const JobInvoiceFeePercentage = 4.9;
export const TPABloomFeePercentage = 4.9;

export const jobTotalEstimateValue = (
  values: number[],
  additionalFeeAmount = 0,
) =>
  jobPriceForCreatives(values) +
  jobTotalBookingFee(values) +
  jobTotalVatValue(values) +
  additionalFeeAmount;

export const totalFeesValues = (
  jobVerticals: JobVertical[] | CreateJobVerticalInput[],
) => jobVerticals.map(({ budget, talentRequired }) => budget * talentRequired);

export const totalFeesValuesWithMultiplier = (
  jobVerticals: JobVertical[] | CreateJobVerticalInput[],
) => sum(totalFeesValues(jobVerticals)) * commissionMultiplier;

export const isZeroBudget = (job: Job) =>
  totalFeesValues(job.jobVerticals).every((budget) => budget === 0);

export const jobTitleForBooker = (job: Job): string => {
  if (jobPendingApproval(job.state)) {
    return "Pending approval by Contact";
  }

  if (jobApproved(job.state) && !jobAllTalentResponded(job)) {
    return "Creatives responding";
  }

  if (jobApproved(job.state) && jobAllTalentResponded(job)) {
    return "All creatives have responded";
  }

  if (jobHappeningToday(job)) {
    return "Job happening today";
  }

  if (jobPaymentProcessing(job)) {
    return "Payment processing";
  }

  if (jobCancelled(job.state)) {
    return "Job has been cancelled";
  }

  if (jobExpired(job.state)) {
    return "Job expired";
  }

  if (jobRejected(job.state)) {
    return "Job rejected";
  }

  if (jobCompleted(job.state)) {
    return "Job completed";
  }

  return jobStateTitle(job.state);
};

export const jobTitleForFPA = (job: Job) => {
  if (jobPaymentProcessing(job)) {
    return "Payment processing";
  }
  return jobStateTitle(job.state);
};

export const jobTitleForTPA = (job: Job) => {
  if (jobAwaitingPayment(job.state)) {
    return jobStateTitle(JobState.Approved);
  }
  return jobStateTitle(job.state);
};

export const jobCardTitleForBooker = (job: Job): string => {
  if (jobPendingApproval(job.state)) {
    return "Pending approval";
  }

  if (jobApproved(job.state) && !jobAllTalentResponded(job)) {
    return "Creatives responding";
  }

  if (jobApproved(job.state) && jobAllTalentResponded(job)) {
    return "Ready to confirm";
  }

  if (jobCancelled(job.state)) {
    return "Cancelled";
  }

  if (jobExpired(job.state)) {
    return "Expired";
  }

  if (jobRejected(job.state)) {
    return "Rejected";
  }

  return jobStateTitle(job.state);
};

export const jobDescriptionForBooker = (job: Job): string => {
  if (jobCompleted(job.state)) {
    return "Job is complete";
  }

  if (jobCancelled(job.state)) {
    return "Job has been cancelled";
  }

  if (jobExpired(job.state)) {
    return "Job has expired";
  }

  return "Job was rejected";
};

export const jobCardTitleForAgent = (job: Job): string => {
  switch (job.agencyJobState) {
    case AgencyJobState.PendingTalentResponse:
      return "Awaiting response";
    case AgencyJobState.PendingBookerResponse:
      return "Awaiting confirmation";
    case AgencyJobState.Confirmed:
      return "Confirmed";
    case AgencyJobState.RejectedByBooker:
      return "Released";
    case AgencyJobState.RejectedByTalent:
      return "Rejected by creative";
    case AgencyJobState.Completed:
      return "Completed";
    case AgencyJobState.Paid:
      return "Paid";
    case AgencyJobState.Cancelled:
      return "Cancelled";
    default:
      return "";
  }
};

export const JobTalentAccepted = (state: JobTalentState): boolean =>
  state === JobTalentState.Accepted || state === JobTalentState.Confirmed;

export const jobTalentRejected = (state: JobTalentState): boolean =>
  state === JobTalentState.Rejected ||
  state === JobTalentState.RejectedByBooker;

export const jobAllTalentResponded = (job: Job): boolean => {
  return (
    !job?.talent?.map((a) => a.state)?.includes(JobTalentState.Pending) || false
  );
};

export const jobHasRespondedTalent = (job: Job): boolean =>
  jobHasRespondedTalentCount(job) > 0;

export const jobByTalentStateCount =
  (state: JobTalentState) =>
  (job: Job): number => {
    return (
      job?.talent?.reduce((acc, t) => {
        if (t.state === state) {
          return acc + 1;
        }
        return acc;
      }, 0) || 0
    );
  };

export const jobHasRespondedTalentCount = (job: Job) =>
  (job.talent || []).filter((jt) => jt.state !== JobTalentState.Pending).length;

export const jobHasAcceptedTalentCount = jobByTalentStateCount(
  JobTalentState.Accepted,
);

export const jobIsLive = (job: Job): boolean =>
  ![
    JobState.Cancelled,
    JobState.Expired,
    JobState.Rejected,
    JobState.Completed,
  ].includes(job.state);

export const jobHappeningToday = (job: Job) => {
  const today = startOfToday();

  return (
    job.state === JobState.Finalized &&
    isBetween(new Date(job.startDate), today, new Date(job.endDate))
  );
};

export const jobConfirmed = (state: JobState): boolean =>
  state === JobState.Finalized;

export const jobAwaitingPayment = (state: JobState) =>
  state === JobState.AwaitingPayment;

export const jobPaymentProcessing = (job: Job) =>
  job.paymentProcessing && jobAwaitingPayment(job.state);

export const jobApproved = (state: JobState): boolean =>
  state === JobState.Approved;

export const jobCompleted = (state: JobState): boolean =>
  state === JobState.Completed;

export const jobFinalized = (state: JobState): boolean =>
  state === JobState.Finalized;

export const jobCancelled = (state: JobState): boolean =>
  state === JobState.Cancelled;

export const jobExpired = (state: JobState): boolean =>
  state === JobState.Expired;

export const jobRejected = (state: JobState): boolean =>
  state === JobState.Rejected;

export const jobPendingApproval = (state: JobState) =>
  state === JobState.PendingApproval;

export const jobIsCancellable = (state: JobState) =>
  [
    JobState.PendingApproval,
    JobState.Approved,
    JobState.Finalized,
    JobState.AwaitingPayment,
  ].includes(state);

export const jobIsEditable = (state: JobState, isFPA?: boolean): boolean =>
  [JobState.PendingApproval, ...(isFPA ? [JobState.Approved] : [])].includes(
    state,
  );
export const jobIsResubmittable = (job: Job): boolean =>
  job.state === JobState.Rejected &&
  (job.rejectionReason === JobRejectionReason.InappropriateBudget ||
    job.rejectionReason === JobRejectionReason.InsufficientInformation);

export const jobFormattedDate = (job: Job): string | undefined => {
  let str = formatDate(job.startDate);

  if (job.spansMultipleDays) {
    str = `${str} to ${formatDate(job.endDate)}`;
  }

  return str;
};

export const ExcessiveShortlistMultiplier = 4;

export const excessiveShortlist = (
  talentRequired: number,
  shortlistCount: number,
) => {
  return shortlistCount > talentRequired * ExcessiveShortlistMultiplier;
};

export const filterShortlist = (
  vertical: TalentVertical,
  talent: JobTalent[],
): JobTalent[] | [] => {
  return talent.filter((t) => isVerticalMember(vertical, t.talent));
};

export const excessivelyShortlistedVerticals = (job: Job) => {
  const talent = job.talent || [];

  return job.jobVerticals.filter(
    (jv) =>
      filterShortlist(jv.vertical, talent).length >
      jv.talentRequired * ExcessiveShortlistMultiplier,
  );
};

export const jobHasNotReceivedPaymentWhenCompleted = (job: Job): boolean => {
  if (job.state !== JobState.Completed) {
    return false;
  }

  return !job.paymentReceived;
};

export const jobHasUnpaidModels = (job: Job): boolean => {
  if (job.state !== JobState.Completed) {
    return false;
  }

  return (
    job.talent !== undefined &&
    job.talent.every((t) => t.state !== JobTalentState.Paid)
  );
};

export const allConfirmedTalentArePaid = (creatives: JobTalent[]) =>
  talentWhoWorkedOnJob(creatives).every(
    (jt) => jt.state === JobTalentState.Paid,
  );

export const talentWhoWorkedOnJob = (creatives: JobTalent[] | undefined) => {
  if (creatives === undefined) {
    return [];
  }

  return jobTalentWithState(creatives, [
    JobTalentState.Confirmed,
    JobTalentState.Paid,
  ]);
};

export const canAddMoreCreatives = (state: JobState) =>
  [
    JobState.PendingApproval,
    JobState.Approved,
    JobState.Finalized,
    JobState.Completed,
  ].includes(state);

export const sortedJobAssignees = (
  assignees: Assignee[],
  assignedId: string | undefined,
) => {
  // Return the currently assigned user first, followed by remaining
  // assignees sorted by number of assigned jobs, then by name
  const currentlyAssigned = assignees.find((a) => a.id === assignedId);

  const rest = assignees
    .filter((a) => a !== currentlyAssigned)
    .sort(
      (a, b) =>
        (b?.assignedLiveJobCount || 0) - (a?.assignedLiveJobCount || 0) ||
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );

  let result: Assignee[] = [];

  if (currentlyAssigned) {
    result = [currentlyAssigned];
  }

  return [...result, ...rest];
};

export type JobHelpBox =
  | "PayHelperBox"
  | "DescriptionHelperBox"
  | "UsageHelperBox"
  | "DatesHelperBox"
  | "OptionalCreativeDetailsHelperBox"
  | "PackageNameHelperBox"
  | "PackageBookerHelperBox"
  | "PackageDescriptionHelperBox"
  | null;
export const focusableHelperBoxProps = (
  name: JobHelpBox,
  focusedHelpBox: JobHelpBox,
  context?: string,
) => {
  return {
    css: {
      position: focusedHelpBox === name ? "sticky" : "initial",
      display:
        focusedHelpBox === null || focusedHelpBox === name ? "block" : "none",
      top: "$2",
    } as Stitches.CSS,
    "data-test-id": `${context}${name}`,
  };
};

export const expandableHelperBoxProps = (
  name: JobHelpBox,
  focusedHelpBox: JobHelpBox,
  context?: string,
) => {
  return {
    css: {
      position: "initial",
      display: "block",
      top: "$2",
    } as Stitches.CSS,
    focusedHelpBox: focusedHelpBox,
    currentHelpBox: name,
    context: context,
  };
};

export const JobLengthPlaceholder = "e.g. 4 hours";

export interface JobFormFields
  extends Omit<MutationCreateJobArgs, "budget" | "kind"> {
  startDay?: number;
  startMonth?: number;
  startYear?: number;
  endDay?: number;
  endMonth?: number;
  endYear?: number;
  spansMultipleDays: boolean;
  budget?: number;
  kind?: JobKind;
  existingDeckFiles?: DeckFile[];
}

export const calculateJobDates = (values: {
  startYear?: number;
  startDay?: number;
  startMonth?: number;
  endDay?: number;
  endMonth?: number;
  endYear?: number;
  spansMultipleDays: boolean;
}) => {
  const startDate = new Date(
    Date.UTC(
      values.startYear as number,
      (values.startMonth as number) - 1,
      values.startDay as number,
    ),
  );

  let endDate;
  if (values.spansMultipleDays) {
    endDate = new Date(
      Date.UTC(
        values.endYear as number,
        (values.endMonth as number) - 1,
        values.endDay as number,
      ),
    );
  } else {
    endDate = startDate;
  }

  return { startDate, endDate };
};

export const getJobLabelLengthFromConfiguration = (
  config: VerticalConfiguration,
) => {
  return (
    config?.jobCreationSchema.detailsFormFields.find(
      (field) => field.name === "JobLengthField",
    )?.props?.label ?? null
  );
};

export enum JobContext {
  BookerEdit = "bookerEdit",
  AgentEdit = "agentEdit",
  Create = "create",
}

export const JobTypeIconMap: Record<TalentVertical, AvailableIcon> = {
  [TalentVertical.FashionModel]: "polaroids",
  [TalentVertical.Photographer]: "cameraPhoto",
  [TalentVertical.Hmua]: "hairStylist",
  [TalentVertical.Influencer]: "influencer",
};

export const getFirstJobTalentVertical = (job: Job) => {
  if (job?.talent?.length && job.talent[0].talent.profile) {
    return (job.talent[0].talent.profile as TalentProfile).vertical;
  } else {
    return TalentVertical.FashionModel;
  }
};

export const missingVerticals = (existingVerticals: TalentVertical[]) => {
  const allVerticals = Object.values(TalentVertical);

  return difference(allVerticals, existingVerticals);
};

export const buildJobVertical = (vertical: TalentVertical) => {
  return {
    vertical: vertical,
    budget: 0,
    deliverables: [],
    talentRequired: 0,
    jobLength: "",
    timeslots: [
      {
        from: [],
        to: [],
      },
    ],
  };
};

export const buildJobVerticalV2 = (vertical: TalentVertical) => {
  return {
    vertical: vertical,
    budget: 0,
    talentRequired: 0,
    jobLength: "",
  };
};
export const cancelJobModalOptions = (isBooker?: boolean) => {
  let options = Array.from(Object.values(JobCancellationReason), (value) => ({
    value,
    label: niceStateText(value),
  }));

  // NOTE: This is temporary, as i am still suggesting that we decom rates too high and change to budget too low instead for consistency of data
  options = options.filter((item) => item.value !== "RATES_TOO_HIGH");
  options.push({
    value: JobCancellationReason.RatesTooHigh,
    label: "Rates too low",
  });

  if (isBooker) {
    return options.filter((item) => item.value !== "UNRESPONSIVE_BOOKER");
  }

  return options;
};

export const jobCancelledReasonText = (
  cancelledAt: Date | string,
  cancellationReason?: string,
) => {
  const text = `This job was cancelled on ${formatDate(cancelledAt)}`;
  switch (cancellationReason) {
    case JobCancellationReason.BookedElsewhere:
      return `${text} because you booked creatives on another platform. Changed your mind? Resubmit the job.`;
    case JobCancellationReason.DateChanged:
      return `${text} because the date changed. You can resubmit the job once you’ve confirmed a new date.`;
    case JobCancellationReason.JobNotHappening:
      return `${text} because it’s no longer going ahead. If this changes, you can resubmit the job.`;
    case JobCancellationReason.Other:
      return `${text} because things didn’t quite work out. You can always resubmit the job.`;
    case JobCancellationReason.RatesTooHigh:
      return `${text} because the budget wasn’t quite right. Feel free to resubmit the job with a higher budget.`;
    case JobCancellationReason.UnavailableCreatives:
      return `${text} because the right creatives weren’t available. Please resubmit the job with a new shortlist.`;
    case JobCancellationReason.UnresponsiveBooker:
      return `${text} because we didn't get a response from you in time. You can always resubmit the job.`;
    case JobCancellationReason.UnresponsiveCreatives:
      return `${text} because you didn’t hear back from the right creatives in time. If you’re still looking for someone, you can resubmit this job with a different shortlist.`;
    default:
      return `${text}.`;
  }
};

export const jobRejectionReasonText = (rejectionReason?: string) => {
  const text = `We've rejected your job because`;
  switch (rejectionReason) {
    case JobRejectionReason.InappropriateBudget:
      return `${text} the fee's too low for this type of work. You can resubmit the job with a higher rate.`;
    case JobRejectionReason.InsufficientInformation:
      return `${text} it didn't have enough information. You can resubmit the job with more details.`;
    default:
      return `${text} it's not quite right for us. We hope you'll submit a new job soon.`;
  }
};

export const UsageMediaTypeMap: Record<MediaType, string> = {
  [MediaType.AllDigitalMedia]: "All digital media",
  [MediaType.AllPrintMedia]: "All print media",
  [MediaType.B2BSales]: "B2B sales",
  [MediaType.Broadcast]: "Broadcast",
  [MediaType.Brochures]: "Brochures",
  [MediaType.Casting]: "Casting",
  [MediaType.Cinema]: "Cineman",
  [MediaType.Collateral]: "Collateral",
  [MediaType.DigitalPosters]: "Digital posters",
  [MediaType.DirectMail]: "Direct mail",
  [MediaType.Editorial]: "Editorial",
  [MediaType.Exhibition]: "Exhibition",
  [MediaType.Internal]: "Internal",
  [MediaType.InternationalMedia]: "International media",
  [MediaType.Intranet]: "Intranet",
  [MediaType.InStore]: "In-store",
  [MediaType.Marketing]: "Marketing",
  [MediaType.Ooh]: "OOH",
  [MediaType.Other]: "Other",
  [MediaType.Packaging]: "Packaging",
  [MediaType.PaidSocialMedia]: "Paid social media",
  [MediaType.Portfolio]: "Portfolio",
  [MediaType.Pos]: "POS",
  [MediaType.PressPr]: "Press/PR",
  [MediaType.PrintAdvertising]: "Print advertising",
  [MediaType.RightsForThirdPartyChannels]: "Rights for third party channels",
  [MediaType.Show]: "Show",
  [MediaType.SocialMedia]: "Social media",
  [MediaType.Tvc]: "TVC",
  [MediaType.Website]: "Website",
  [MediaType.Youtube]: "Youtube",
};

export const UsageMediaLocationMap: Record<MediaLocation, string> = {
  [MediaLocation.Uk]: "UK",
  [MediaLocation.Asia]: "Asia",
  [MediaLocation.Emea]: "EMEA",
  [MediaLocation.Europe]: "Europe",
  [MediaLocation.NorthAmerica]: "North America",
  [MediaLocation.SouthAmerica]: "South America",
  [MediaLocation.Global]: "Global",
  [MediaLocation.OnlineOnly]: "Online only",
  [MediaLocation.Other]: "Other",
};

export const headcountEstimateMap = {
  [HeadcountEstimate.ZeroToFive]: "0-5",
  [HeadcountEstimate.SixToTen]: "6-10",
  [HeadcountEstimate.ElevenToTwenty]: "11-20",
  [HeadcountEstimate.TwentyOneToThirty]: "21-30",
  [HeadcountEstimate.AboveThirty]: "30+",
};
