import { gql } from "@apollo/client";
import {
  JOB_PARTS_FRAGMENT,
  NOTE_DETAILS_FRAGMENT,
  ASSIGNEE_PARTS_FRAGMENT,
} from "src/graphql/fragments";

export const AGENT_DASHBOARD_KANBAN_QUERY = gql`
  query AgentDashboardKanban(
    $filters: JobFilterInput
    $sortOrder: SortOrderInput
    $first: Int!
    $after: String
  ) {
    me {
      id
      jobs(
        filters: $filters
        sortOrder: $sortOrder
        first: $first
        after: $after
      ) @connection(key: "jobs", filter: ["filters", "sortOrder"]) {
        totalCount
        pageInfo {
          startCursor
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...JobParts
          }
        }
      }
    }
  }
  ${JOB_PARTS_FRAGMENT}
  ${ASSIGNEE_PARTS_FRAGMENT}
  ${NOTE_DETAILS_FRAGMENT}
`;

export const AGENT_DASHBOARD_KANBAN_OWN_CONTEXT_QUERY = gql`
  query AgentDashboardKanbanOwnContext(
    $filters: JobFilterInput
    $sortOrder: SortOrderInput
    $first: Int!
    $after: String
  ) {
    me {
      id
      jobs(
        filters: $filters
        sortOrder: $sortOrder
        first: $first
        after: $after
      ) @connection(key: "jobs", filter: ["filters", "sortOrder"]) {
        totalCount
        pageInfo {
          startCursor
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...JobParts
          }
        }
      }
    }
  }
  ${JOB_PARTS_FRAGMENT}
  ${ASSIGNEE_PARTS_FRAGMENT}
  ${NOTE_DETAILS_FRAGMENT}
`;
