import React from "react";
import { ComponentProps, CSS } from "@stitches/react";
import { styled } from "src/ccl";

export const InputStyles = {
  appearance: "none",
  backgroundColor: "$white",
  border: "2px solid $black",
  fontVariantNumeric: "tabular-nums",
  fontFamily: "$sans",
  fontSize: "$16",
  lineHeight: "$26",
  minWidth: 150,
  outline: "none",
  width: "100%",
  height: "50px",
  padding: "$4",
  boxSizing: "border-box",

  "&:focus": {
    boxShadow: "inset 0 0 0 1px $black",
  },
  "&:read-only": {
    color: "$grey6",
  },
  "&:disabled": {
    backgroundColor: "$grey1",
    borderColor: "$grey6",
    color: "$grey6",
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  "&::placeholder": {
    color: "$grey6",
  },
  variants: {
    variant: {
      rebrand: {
        height: "61px",
        borderRadius: "8px",
        border: "2px solid $grey3",
        "&:placeholder-shown": {
          border: "2px solid $grey2",
          "&:hover": {
            border: "2px solid $grey4",
          },
        },
        "&:hover": {
          border: "2px solid $grey4",
        },
      },
    },
  },
};

const StyledInput = styled("input", InputStyles);

export interface InputProps extends ComponentProps<typeof StyledInput> {
  inputPrefix?: React.ReactNode;
  inputSuffix?: React.ReactNode;
  prefixCss?: CSS;
  suffixCss?: CSS;
  type: string;
  variant?: "rebrand";
}

export const Input = (props: Partial<InputProps>) => (
  <StyledInput
    id={props.id || `form-${props.name}`}
    type={props.type}
    {...props}
  />
);
