import { gql } from "@apollo/client";
import {
  JOB_DETAILS_FRAGMENT,
  JOB_VERTICAL_DETAILS_FRAGMENT,
  JOB_DETAILS_SPLIT_ADDRESS_FRAGMENT,
  NOTE_DETAILS_FRAGMENT,
  PAYMENT_DETAILS_FRAGMENT,
  TALENT_DETAILS_FRAGMENT,
  LINE_ITEM_FRAGMENT,
} from "src/graphql/fragments";

export const AGENT_DASHBOARD_SHOW_JOB_QUERY = gql`
  query AgentDashboardShowJob($jobId: ID!) {
    me {
      id
      name
      availableFeatures
      agencyUsers {
        id
        name
        featuredAsset {
          mediaUrl
        }
      }
    }
    job(id: $jobId) {
      ...JobDetails
      ...JobDetailsSplitAddress
      ...JobVerticalDetails
      state
      spansMultipleDays
      fullAddress
      commission
      totalCommission
      totalPayable
      vatAmount
      currency
      createdAt
      paymentReceived
      imagesReceived
      invoiceBillingName
      invoiceBillingAddress
      excludeVat
      paymentViaExternalPlatform
      extraFeesAmount
      totalInvoiceCount
      hasUnresolvedDisputes
      trustshareProjectId
      paymentMethod
      paymentProcessing
      completedAt
      invoices {
        id
        paidAt
        state
      }
      paymentBreakdown {
        recipientName
        recipientType
        amount
        jobTalentId
        isAgencyPaid
        isPaid
        bankAccount {
          accountNumber
          iban
          kind
          ownerName
          sortCode
        }
        jobAgencyId
        commissionBreakdown {
          agencyCommissionFee
          additionalCommissionFee
          platformFee
          transactionFee
          contactFee
        }
        agencyBreakdown {
          recipientName
          recipientType
          amount
          bookingCommissionFee
          agencyCommissionFee
        }
        contactFeeBreakdown {
          platformFee
          transactionFee
          bookingFee
          invoiceFee
          vat
        }
      }
      feeItems {
        description
        amount
      }

      jobDisputes {
        id
      }
      unpaidLineItems {
        id
        amount
        description
        kind
      }
      package {
        creator {
          name
          slug
        }
        name
        slug
      }
      booker {
        name
        slug
      }
      assignedAgent {
        id
        featuredAsset {
          mediaUrl
        }
        name
        email
      }
      conflicts {
        jobTalent {
          id
        }
        conflictingJobs {
          slug
          name
        }
      }
      activityStream {
        nodes {
          message
          timestamp
          username
        }
      }
      talent {
        ...TalentDetails
        transitionableEvents
        firstPaidJob
        formattedRejectionReason
        rejectionNote
        paidAt
        lineItems {
          ...LineItemDetails
        }
        outboundPayments {
          ...PaymentDetails
          lineItems {
            ...LineItemDetails
          }
          paymentBatch {
            id
            state
          }
        }
        talent {
          id
          name
          slug
          email
          featuredAsset {
            mediaUrl
          }
          profile {
            ... on TalentProfile {
              id
              vertical
              location
              agency {
                id
                name
                slug
                firstParty
              }
            }
          }
        }
      }
      notes {
        ...NoteDetails
      }
    }
  }
  ${PAYMENT_DETAILS_FRAGMENT}
  ${JOB_DETAILS_FRAGMENT}
  ${JOB_VERTICAL_DETAILS_FRAGMENT}
  ${JOB_DETAILS_SPLIT_ADDRESS_FRAGMENT}
  ${TALENT_DETAILS_FRAGMENT}
  ${NOTE_DETAILS_FRAGMENT}
  ${LINE_ITEM_FRAGMENT}
`;
