import { gql } from "@apollo/client";

export const DELETE_PORTFOLIO_IMAGE_CATEGORY_MUTATION = gql`
  mutation DeletePortfolioImageCategory($portfolioImageCategoryId: ID!) {
    deletePortfolioImageCategory(
      portfolioImageCategoryId: $portfolioImageCategoryId
    ) {
      success
    }
  }
`;
