import type * as Stitches from "@stitches/react";
import { styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";

export const TextVariants: {
  [v: string]: Stitches.CSS;
} = {
  mega: {
    fontSize: "$64",
    lineHeight: "$70",
    fontFamily: "$sansWide",
    fontWeight: "$bold",
    textTransform: "uppercase",
    fontDisplay: "swap",
  },
  h1: {
    fontSize: "$40",
    lineHeight: "$48",
    fontFamily: "$sansWide",
    fontWeight: "$bold",
    textTransform: "uppercase",
    fontDisplay: "swap",
  },
  h2: {
    fontSize: "$24",
    lineHeight: "$32",
    fontFamily: "$sansWide",
    fontWeight: "$bold",
    textTransform: "uppercase",
    fontDisplay: "swap",
  },
  h3: {
    fontSize: "$18",
    lineHeight: "$24",
    fontFamily: "$sansWide",
    fontWeight: "$bold",
    textTransform: "uppercase",
    fontDisplay: "swap",
  },
  h4: {
    fontSize: "$20",
    lineHeight: "$30",
    fontWeight: "$bold",
  },
  mini: {
    fontSize: "$12",
    lineHeight: "$18",
    fontWeight: "$bold",
    textTransform: "uppercase",
    fontDisplay: "swap",
  },
  small: {
    fontSize: "$12",
    lineHeight: "$18",
  },
  impact: {
    fontSize: "$20",
    lineHeight: "$30",
  },
  body: {
    fontSize: "$16",
    lineHeight: "$26",
  },
  meta: {
    fontSize: "$14",
    lineHeight: "$22",
  },
  sch1: {
    fontSize: "$40",
    lineHeight: "$46",
    fontWeight: "$bold",
  },
  sch2: {
    fontSize: "$30",
    lineHeight: "$36",
    fontWeight: "$bold",
  },
  marker: {
    fontSize: "$16",
    lineHeight: "$24",
    fontFamily: "$marker",
  },
  markerBig: {
    fontSize: "28px",
    lineHeight: "34px",
    fontFamily: "$marker",
  },
  buttonText: {
    fontFamily: "$sansWide",
    fontSize: "$12",
    fontWeight: "$medium",
    lineHeight: "$16",
    textTransform: "uppercase",
    fontDisplay: "swap",
  },
  h0: {
    fontFamily: "$serif",
    fontSize: "$64",
    fontWeight: "$bold",
    lineHeight: "$normal",
  },
  nh1: {
    fontFamily: "$serif",
    fontSize: "$52",
    fontWeight: "$bold",
    lineHeight: "$normal",
  },
  nh2: {
    fontFamily: "$serif",
    fontSize: "$34",
    fontWeight: "$bold",
    lineHeight: "$normal",
  },
  nh3: {
    fontFamily: "$sansNew",
    fontSize: "$22",
    letterSpacing: "$wide",
    fontWeight: "$bold",
    lineHeight: "$normal",
  },
  nh4: {
    fontFamily: "$sansNew",
    fontSize: "$18",
    letterSpacing: "$wide",
    fontWeight: "$bold",
    lineHeight: "$normal",
  },
  title: {
    fontFamily: "$sansNew",
    fontSize: "$16",
    letterSpacing: "$wide",
    fontWeight: "$bold",
    lineHeight: "$normal",
    textTransform: "uppercase",
    fontDisplay: "swap",
  },
  button: {
    fontFamily: "$sansNew",
    fontSize: "$14",
    letterSpacing: "$wide",
    fontWeight: "$bold",
    lineHeight: "$tight",
    textTransform: "uppercase",
    fontDisplay: "swap",
  },
  buttonSmall: {
    fontFamily: "$sansNew",
    fontSize: "$12",
    letterSpacing: "$wide",
    fontWeight: "$bold",
    lineHeight: "$normal",
    textTransform: "uppercase",
    fontDisplay: "swap",
  },
  b1: {
    fontFamily: "$sansNew",
    fontSize: "$20",
    letterSpacing: "$wide",
    fontWeight: "$regular",
    lineHeight: "$normal",
  },
  b1Bold: {
    fontFamily: "$sansNew",
    fontSize: "$20",
    letterSpacing: "$wide",
    fontWeight: "$semiBold",
    lineHeight: "$normal",
  },
  b2: {
    fontFamily: "$sansNew",
    fontSize: "$16",
    letterSpacing: "$wide",
    fontWeight: "$medium",
    lineHeight: "$loose",
  },
  b2Bold: {
    fontFamily: "$sansNew",
    fontSize: "$16",
    letterSpacing: "$wide",
    fontWeight: "$semiBold",
    lineHeight: "$loose",
  },
  b3: {
    fontFamily: "$sansNew",
    fontSize: "$14",
    letterSpacing: "$wide",
    fontWeight: "$medium",
    lineHeight: "$relaxed",
  },
  b3Bold: {
    fontFamily: "$sansNew",
    fontSize: "$14",
    letterSpacing: "$wide",
    fontWeight: "$semiBold",
    lineHeight: "$relaxed",
  },
  modalB1: {
    fontFamily: "$sans",
    fontSize: "$16",
    lineHeight: "26px",
  },
  modalB2: {
    fontFamily: "$sansNew",
    fontSize: "$16",
    lineHeight: "26px",
  },
  headline3: {
    fontFamily: "$sansNew",
    fontSize: "$22",
    fontWeight: "$bold",
    lineHeight: "26.4px",
    letterSpacing: "0.44px",
  },
  headline4: {
    fontFamily: "$sansNew",
    fontSize: "$18",
    fontWeight: "$bold",
    lineHeight: "18px",
    letterSpacing: "0.36px",
  },
  rebrand: {
    fontSize: "$16",
    lineHeight: "$26",
    fontFamily: "$sansNew",
    color: "$black",
  },
  new: {
    fontFamily: "$sansNew",
  },
  nSmall: {
    fontFamily: "$sansNew",
    fontSize: "$12",
    lineHeight: "$18",
  },
};

export const Text = styled("div", {
  fontFamily: "$sans",
  color: "$black",
  ...TextVariants.body,

  variants: {
    variant: { ...TextVariants },
    color: Object.fromEntries(
      Object.keys(tokens.colors).map((color) => [
        color,
        { color: `$${color}` },
      ]),
    ) as Record<keyof typeof tokens.colors, { color: string }>,
    weight: {
      bold: {
        fontWeight: "$bold",
      },
    },
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "center",
      },
    },
  },
});
