import React from "react";
import type * as Stitches from "@stitches/react";
import { styled } from "src/ccl/stitches";

export const LegacyContainer = styled("div", {
  margin: "0 auto",
  maxWidth: "1100px",
  width: "100%",

  "@bp5": {
    maxWidth: "1450px",
  },

  variants: {
    variant: {
      noGutter: {
        px: "$0",
      },
      gutter: {
        px: "$6",
        "@bp1": { px: "$7" },
        "@bp2": { px: "$8" },
      },
      full: {
        px: "$6",
        maxWidth: "100%",
        "@bp1": { px: "$7" },
        "@bp2": { px: "$4" },
        "@bp5": {
          maxWidth: "100%",
        },
      },
    },
  },
});

export type ContainerProps = Stitches.ComponentProps<typeof LegacyContainer>;

const UnforwardedContentContainer: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ContainerProps
> = (props, ref) => <LegacyContainer variant="gutter" {...props} ref={ref} />;

export const ContentContainer = React.forwardRef(UnforwardedContentContainer);
