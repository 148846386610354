import React, { useState } from "react";
import { truncate } from "lodash";
import type * as Stitches from "@stitches/react";
import { Icon, Text } from "src/ccl/document";
import { ParagraphRenderer } from "src/components";
import { Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl";

interface ReadMoreBoxProps {
  copy: string;
  charLength?: number;
  lineCount?: number;
  clickOpenCopy: string;
  variant?: "dark";
  expandedCss?: Stitches.CSS;
}

export const ReadMoreBox = ({
  copy,
  charLength,
  lineCount,
  clickOpenCopy = "Read more",
  variant,
  expandedCss = {},
}: ReadMoreBoxProps) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <Text
        variant="meta"
        css={{
          display: "-webkit-box",
          WebkitLineClamp: isReadMore ? lineCount : "none",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        <ParagraphRenderer
          text={
            isReadMore
              ? truncate(copy, {
                  length: charLength,
                  separator: /,? +/,
                })
              : copy
          }
          css={mergeCss(
            {
              color: variant === "dark" ? "$white" : "$black",
              fontFamily: "$sans",
              mb: "$6",
            },
            expandedCss,
          )}
        />
      </Text>

      {isReadMore && (
        <Flex
          css={{
            alignItems: "center",
            cursor: "pointer",
            mt: "$6",
          }}
          onClick={toggleReadMore}
        >
          <Icon
            variant={isReadMore ? "chevronDown" : "chevronUp"}
            size={14}
            color={variant === "dark" ? "white" : "black"}
          />
          <Text
            css={{
              textDecoration: "underline",
              color: variant === "dark" ? "white" : "black",
              ml: "$3",
              fontFamily: "$sansNew",
            }}
          >
            {clickOpenCopy}
          </Text>
        </Flex>
      )}
    </>
  );
};
