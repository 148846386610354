import { gql } from "@apollo/client";

export const SAVED_TALENT_COLLECTION_DETAILS_FRAGMENT = gql`
  fragment SavedTalentCollectionDetails on SavedTalentCollection {
    id
    name
    slug
    memberCount
    talentVerticals
    members(first: 4) {
      totalCount
      edges {
        node {
          featuredAsset {
            mediaUrl
          }
        }
      }
    }
  }
`;
