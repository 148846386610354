import { gql } from "@apollo/client";
import {
  PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT,
  PORTFOLIO_IMAGE_DETAILS_FRAGMENT,
} from "src/graphql/fragments";

export const AGENT_TALENT_PORTFOLIO_QUERY = gql`
  query AgentTalentPortfolio($id: ID!) {
    me {
      agency {
        talent(id: $id) {
          profile {
            ... on TalentProfile {
              portfolioImages {
                ...PortfolioImageDetails
              }
              portfolioImageCategories {
                ...PortfolioImageCategoryDetails
              }
            }
          }
        }
      }
    }
  }
  ${PORTFOLIO_IMAGE_DETAILS_FRAGMENT}
  ${PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT}
`;
