import { gql } from "@apollo/client";

export const UPDATE_INVOICE_MUTATION = gql`
  mutation UpdateInvoice(
    $invoiceId: ID!
    $poNumber: String
    $paymentMethod: PaymentMethod
  ) {
    updateInvoice(
      invoiceId: $invoiceId
      poNumber: $poNumber
      paymentMethod: $paymentMethod
    ) {
      id
      poNumber
    }
  }
`;
