import SparkMD5 from "spark-md5";

const chunkSize = 2097152; // 2MB

export const computeChecksum = (file: File): Promise<string> => {
  let currentChunk = 0;
  const chunks = Math.ceil(file.size / chunkSize);
  const md5Buffer = new SparkMD5.ArrayBuffer();
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onload = (e) => {
      if (!e.target || !e.target.result) {
        return reject("Could not find target result to compute file hash");
      }

      md5Buffer.append(e.target.result as ArrayBuffer);
      currentChunk++;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const binaryDigest = md5Buffer.end(true);
        const base64digest = btoa(binaryDigest);
        resolve(base64digest);
      }
    };

    fileReader.onerror = () => {
      reject("Could not compute file hash");
    };

    const loadNext = () => {
      const start = currentChunk * chunkSize;
      const end =
        start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(file.slice(start, end));
    };

    loadNext();
  });
};
