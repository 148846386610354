import { gql } from "@apollo/client";

export const ASSOCIATE_ASSET_MUTATION = gql`
  mutation AssociateAsset($assetId: ID!) {
    associateAsset(assetId: $assetId) {
      id
      mediaUrl
    }
  }
`;
