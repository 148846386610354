import React from "react";
import { ComponentProps, CSS } from "@stitches/react";
import { mergeCss, styled } from "src/ccl/stitches";

const Image = styled("img", {});

interface BlogImageProps extends ComponentProps<typeof Image> {
  alt: string;
  src: string;
  css?: CSS;
}

export const BlogImage = ({ css = {}, src, ...props }: BlogImageProps) => (
  <Image
    src={`${src}?w=1380`}
    {...props}
    css={mergeCss(
      {
        margin: "0 auto",
        display: "block",
      },
      css,
    )}
  />
);
