import { gql } from "@apollo/client";
import { ASSIGNEE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const ASSIGN_JOB_MUTATION = gql`
  mutation AssignJob($jobId: ID!, $assigneeId: ID!) {
    assignJob(jobId: $jobId, assigneeId: $assigneeId) {
      ...AssigneeDetails
    }
  }
  ${ASSIGNEE_DETAILS_FRAGMENT}
`;
