import { styled } from "src/ccl";
import { AvailableIcon, Icon, Modal, ModalProps, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { SortDirection, SortOrderInput } from "src/graphql/types";

interface JobSortModalProps extends ModalProps {
  sortOrder: SortOrderInput;
  setSortOrder: (sortOrderInput: SortOrderInput) => void;
}

const Divider = styled("hr", {
  width: "100%",
  height: "1px",
  border: "none",
  backgroundColor: "$grey1",
});

const sortOptions: {
  text: string;
  icon: AvailableIcon;
  sortOrder: SortOrderInput;
}[] = [
  {
    icon: "jobs",
    text: "Job (A-Z)",
    sortOrder: { column: "name", direction: SortDirection.Asc },
  },
  {
    icon: "jobs",
    text: "Job (Z-A)",
    sortOrder: { column: "name", direction: SortDirection.Desc },
  },
  {
    icon: "newFacesInvert",
    text: "Status (A-Z)",
    sortOrder: { column: "state", direction: SortDirection.Asc },
  },
  {
    icon: "newFacesInvert",
    text: "Status (Z-A)",
    sortOrder: { column: "state", direction: SortDirection.Desc },
  },
  {
    icon: "user",
    text: "Client (A-Z)",
    sortOrder: { column: "booker", direction: SortDirection.Asc },
  },
  {
    icon: "user",
    text: "Client (Z-A)",
    sortOrder: { column: "booker", direction: SortDirection.Desc },
  },
  {
    icon: "calendar",
    text: "Job date (Latest-Earliest)",
    sortOrder: { column: "start_date", direction: SortDirection.Desc },
  },
  {
    icon: "calendar",
    text: "Job date (Earliest-Latest)",
    sortOrder: { column: "start_date", direction: SortDirection.Asc },
  },
  {
    icon: "calendar",
    text: "Posted date (Latest-Earliest)",
    sortOrder: { column: "created_at", direction: SortDirection.Desc },
  },
  {
    icon: "calendar",
    text: "Posted date (Earliest-Latest)",
    sortOrder: { column: "created_at", direction: SortDirection.Asc },
  },
];

const SortOption = ({
  text,
  icon,
  selectedSortOption,
  sortOrder,
  setSortOrder,
}: {
  text: string;
  icon: AvailableIcon;
  selectedSortOption: SortOrderInput;
  sortOrder: SortOrderInput;
  setSortOrder: (sortOrderInput: SortOrderInput) => void;
}) => {
  const selected =
    selectedSortOption.column === sortOrder.column &&
    selectedSortOption.direction === sortOrder.direction;

  return (
    <Flex
      onClick={() =>
        setSortOrder({
          column: sortOrder.column,
          direction: sortOrder.direction,
        })
      }
      css={{
        backgroundColor: selected ? "$black" : undefined,
        alignItems: "center",
        gap: "$3",
        py: "$3",
        px: "$4",
        borderRadius: "$lg",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Icon variant={icon} size={16} color={selected ? "white" : "black"} />
      <Text color={selected ? "white" : "black"}>{text}</Text>
    </Flex>
  );
};

export const JobSortModal = ({
  isOpen,
  onClose,
  sortOrder,
  setSortOrder,
}: JobSortModalProps) => {
  return (
    <Modal
      title="Sort"
      titleVariant="nh3"
      titleCss={{
        marginBottom: 0,
        fontSize: "$22 !important",
      }}
      showCloseButton
      withBodyPadding={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex
        css={{
          pt: "$5",
          px: "$5",
        }}
      >
        <Divider />
      </Flex>
      <Flex
        data-test-id="BookerSortModal"
        css={{
          flexDirection: "column",
          gap: "32px",
          padding: "$9 $5",
          maxHeight: "595px",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            backgroundColor: "$white",
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "$grey3",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "$grey2",
            borderRadius: "5px",
            height: "81px",
          },
        }}
      >
        <Box>
          {sortOptions.map((option, i) => (
            <SortOption
              key={`sort-order-option-${i}`}
              {...option}
              selectedSortOption={sortOrder}
              setSortOrder={setSortOrder}
            />
          ))}
        </Box>
      </Flex>
    </Modal>
  );
};
