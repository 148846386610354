import { gql } from "@apollo/client";

export const CREATE_ASSET_MUTATION = gql`
  mutation CreateAsset(
    $kind: String!
    $file: FileUploadInput!
    $assetableId: ID!
    $assetableType: String!
  ) {
    createAsset(
      kind: $kind
      file: $file
      assetableId: $assetableId
      assetableType: $assetableType
    ) {
      id
      mediaUrl
    }
  }
`;
