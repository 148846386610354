import React from "react";
import { Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { JobState, JobTalent, JobTalentState } from "src/graphql/types";

interface BookerDashboardCreativeStatusProps {
  creatives: JobTalent[];
  jobState: JobState;
  showMeta: boolean;
}

export const BookerDashboardCreativeStatus = ({
  creatives,
  jobState,
  showMeta,
}: BookerDashboardCreativeStatusProps) => {
  const acceptedCreatives = creatives.filter(
    (c) => c.state === JobTalentState.Accepted,
  );
  const confirmedCreatives = creatives.filter(
    (c) => c.state === JobTalentState.Confirmed,
  );
  const creativesCount = creatives.length;

  const CreativeStatusText = () => {
    const green =
      jobState === JobState.Finalized ||
      (acceptedCreatives.length > 0 && jobState === JobState.Approved);

    let copy = `${creatives.length} shortlisted`;
    if (acceptedCreatives.length > 0 && jobState === JobState.Approved) {
      copy = `${acceptedCreatives.length}/${creatives.length} ACCEPTED`;
    } else if (
      jobState === JobState.Finalized ||
      jobState === JobState.Completed
    ) {
      copy = `${confirmedCreatives.length} booked`;
    }
    return (
      <Text
        variant="mini"
        color={green ? "green" : "grey6"}
        css={{ py: "$1", whiteSpace: "nowrap" }}
      >
        {copy}
      </Text>
    );
  };

  return (
    <Flex
      css={{
        pt: "$2",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",

        "@bp2": {
          width: "fit-content",
          flexDirection: "column",
          justifyContent: "center",
        },
      }}
    >
      {showMeta && (
        <Text variant="meta" color="grey6">
          {creativesCount > 5 && `+${creativesCount - 5} more`}
        </Text>
      )}

      <CreativeStatusText />
    </Flex>
  );
};
