import { gql } from "@apollo/client";

export const SSO_AUTH_QUERY = gql`
  query authSso($idToken: String!, $providerId: String!, $nonce: String) {
    authSso(idToken: $idToken, providerId: $providerId, nonce: $nonce) {
      jwt
      unauthorized
    }
  }
`;
