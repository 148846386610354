import React, { useState, useEffect } from "react";
import { LegacyVerticalTitle } from "src/components/talent/talentProfile/LegacyVerticalTitle";
import { Box, ContentContainer, Grid } from "src/ccl/layout";
import {
  Asset,
  PortfolioImage,
  ProfileAttribute,
  TalentProfile,
  TalentVertical,
  User,
  VerticalConfiguration,
} from "src/graphql/types";
import { useHandleBookmark, useStoreModel } from "src/hooks";
import { AssetImage, SignInModal } from "src/ccl/document";
import { verticalMap } from "src/utils/user";
import { LinksSection } from "src/components/talent/talentProfile/LinksSection";
import { Biography } from "src/components/talent/talentProfile/Biography";
import { AttributesSection } from "src/components/talent/talentProfile/AttributesSection";
import {
  MainDetails,
  LegacyShortlistButton,
  TalentProfileMeasurements,
} from "src/components";

export interface VerticalTalentProfileHeaderProps {
  vertical: TalentVertical;
  talent: User;
  profileAttributes: ProfileAttribute[];
  isRemovable: boolean;
  isShortlisted: boolean;
  showShortlistButton: boolean;
  shortlistButtonClick: () => void;
  handleBookmark?: (isChecked: boolean, talentId: string) => void;
  isBookmarkDisabled?: boolean;
}

interface LegacyTalentProfileHeaderProps {
  verticalConfig: VerticalConfiguration;
  talent: User;
  isRemovable: boolean;
  isShortlisted: boolean;
  shortlistButtonClick: (shortlisted: boolean) => void;
  showShortlistButton: boolean;
  context?: "CAJ";
  onAddToCollection?: (talent: User) => void;
}

const TalentProfileFeaturedAssets = ({ talent }: { talent: User }) => {
  const profile = talent.profile as TalentProfile;

  let assets: (PortfolioImage | Asset)[] = [];

  if (profile.vertical === TalentVertical.FashionModel) {
    const polaroids =
      profile.portfolioImageCategories.find(({ name }) => name === "Polaroids")
        ?.portfolioImages || [];

    if (talent.featuredAsset) {
      assets.push(talent.featuredAsset);
    }
    assets = [...assets, ...polaroids];
  } else {
    assets = [...assets, ...(profile.lastPortfolioUploads || [])];
  }

  if (
    talent.featuredAsset &&
    profile.vertical === TalentVertical.FashionModel &&
    assets.length <= 2
  ) {
    return (
      <Box>
        <AssetImage
          asset={talent.featuredAsset}
          size={{
            "@initial": {
              width: 600,
              aspectRatio: 1.14,
            },
            "@bp1": { width: 800, aspectRatio: 1.14 },
            "@bp5": { width: 680, aspectRatio: 1.3 },
          }}
          alt={`Image of ${talent.name}`}
        />
      </Box>
    );
  }

  return (
    <Grid
      css={{
        "@bp5": {
          gridTemplateColumns: "repeat(3, 177px)",
          gridTemplateRows: "repeat(2, 230px)",
          gap: "7px",
        },
      }}
    >
      <>
        <Box
          css={{
            "@bp5": {
              gridRow: "span 2",
              gridColumn: assets.length === 1 ? "2 / span 2" : "span 2",
            },
          }}
        >
          <AssetImage
            asset={assets[0]}
            size={{
              "@initial": {
                width: 600,
                aspectRatio: 1.14,
              },
              "@bp1": { width: 800, aspectRatio: 1.14 },
              "@bp5": { width: 360, height: 467 },
            }}
            alt={`Image of ${talent.name}`}
          />
        </Box>

        {assets.slice(1, 3).map((asset, index) => (
          <Box
            key={`featuredAsset-${index}`}
            css={{
              display: "none",
              "@bp5": {
                display: "inherit",
              },
            }}
          >
            <AssetImage
              asset={asset}
              size={{
                width: 177,
                height: 230,
              }}
              alt={`Image of ${talent.name}`}
            />
          </Box>
        ))}
      </>
    </Grid>
  );
};

export const LegacyTalentProfileHeader = ({
  verticalConfig,
  talent,
  isRemovable,
  isShortlisted,
  shortlistButtonClick,
  showShortlistButton,
  context,
  onAddToCollection,
}: LegacyTalentProfileHeaderProps) => {
  const { isBooker, loggedIn } = useStoreModel("currentUser");
  const { handleBookmark: toggleBookmark, loading: bookmarkLoading } =
    useHandleBookmark();
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };
  const handleBookmark = isBooker
    ? toggleBookmark
    : !loggedIn
    ? toggleSignInModal
    : undefined;

  useEffect(() => {
    if (loggedIn && signInModalOpen) {
      toggleSignInModal();
    }
  }, [loggedIn]);

  const vertical = verticalConfig.id;
  const profile = talent.profile as TalentProfile;
  const creativeType: string[] =
    JSON.parse(
      profile.verticalData?.find((d) => d.key === "creative_type")?.value ||
        "null",
    ) || [];
  const prettyCreativeType: string =
    creativeType.length === 1
      ? creativeType[0].replace(/_/g, " ")
      : verticalMap[vertical];
  const props = {
    vertical: vertical,
    talent: talent,
    profileAttributes: verticalConfig.profileAttributes,
    isRemovable: isRemovable,
    isShortlisted: isShortlisted,
    shortlistButtonClick: () => shortlistButtonClick(isShortlisted),
    showShortlistButton: showShortlistButton,
    handleBookmark: handleBookmark,
    isBookmarkDisabled: !isBooker || bookmarkLoading,
  };

  return (
    <ContentContainer
      variant={
        context ? { "@initial": "gutter", "@bp2": "noGutter" } : "noGutter"
      }
    >
      <SignInModal isOpen={signInModalOpen} onClose={toggleSignInModal} />

      <Box
        data-test-id="TalentProfileHeader"
        css={{
          px: "$8",
        }}
      >
        <LegacyVerticalTitle
          vertical={vertical}
          agency={profile.agency}
          category={talent.groups?.[0]?.name}
          creativeType={prettyCreativeType}
        />

        <Grid
          css={{
            gridTemplateColumns: "1fr",
            gap: "$7",
            "@bp2": {
              gridTemplateColumns: "1fr 1fr",
            },
          }}
        >
          <Box
            css={{
              order: 1,
              "@bp2": {
                order: "inherit",
              },
            }}
          >
            <Box>
              <MainDetails
                talent={talent}
                isBookmarkDisabled={!isBooker || bookmarkLoading}
                handleBookmark={handleBookmark}
                onAddToCollection={
                  onAddToCollection
                    ? () => onAddToCollection(talent)
                    : undefined
                }
              />

              <Box css={{ pt: "$2", pb: "$9" }}>
                {showShortlistButton && (
                  <Box css={{ alignSelf: "start" }}>
                    <LegacyShortlistButton
                      isShortlisted={isShortlisted}
                      onClick={props.shortlistButtonClick}
                      disabled={!isRemovable}
                    />
                  </Box>
                )}
              </Box>

              <LinksSection talent={talent} />
            </Box>
            {profile.scrubbedBiography &&
              profile.scrubbedBiography.trim() !== "" && (
                <Biography body={profile.scrubbedBiography} />
              )}
            {props.profileAttributes && props.profileAttributes.length > 0 && (
              <Box
                css={{
                  pt: "$11",
                }}
              >
                <AttributesSection
                  attributes={props.profileAttributes}
                  profile={profile}
                />
                {vertical === TalentVertical.FashionModel && (
                  <TalentProfileMeasurements
                    talent={talent}
                    css={{ py: "$7", "@bp2": { pt: "$11", pb: "$0" } }}
                  />
                )}
              </Box>
            )}
          </Box>
          <TalentProfileFeaturedAssets talent={talent} />
        </Grid>
      </Box>
    </ContentContainer>
  );
};
