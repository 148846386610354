import React from "react";
import { CAJAvatarCollection } from "src/ccl/blocks/avatar/CAJAvatarCollection";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Link } from "src/ccl/navigation";
import { User } from "src/graphql/types";

interface CJFormCardHeaderProps {
  title: string;
  subtitle?: string;
  iconVariant: AvailableIcon;
  talentUsers?: User[];
  linkTo?: string;
  showPencil?: boolean;
  onClick?: () => void;
  headerComponent?: React.ReactNode;
}

export const CJFormCardHeader = ({
  title,
  subtitle,
  iconVariant,
  talentUsers,
  linkTo,
  showPencil = true,
  onClick,
  headerComponent = null,
}: CJFormCardHeaderProps) => {
  return (
    <Box css={{ pt: headerComponent !== null ? "$8" : 0 }}>
      {headerComponent}

      <Flex
        data-test-id="CAJAvatarModal"
        onClick={onClick && (() => onClick())}
        css={{
          cursor: onClick ? "pointer" : "default",
          py: "$6",
          gap: "$6",
          borderBottom: "1px solid $grey3",
          mb: "$6",
          "@bp3": {
            paddingBottom: "$6",
            paddingTop: "$9",
          },
        }}
      >
        <Flex
          css={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Flex
            css={{
              flexDirection: talentUsers
                ? "column"
                : !showPencil
                ? "column"
                : "row",
              width: "100%",
            }}
          >
            <Flex css={{ justifyContent: "space-between" }}>
              <Icon
                css={{
                  display: "none",
                  "@bp2": {
                    display: "block",
                  },
                }}
                size={30}
                variant={iconVariant}
              />
              <Icon
                css={{ "@bp2": { display: "none" } }}
                size={24}
                variant={iconVariant}
              />

              {talentUsers && <CAJAvatarCollection users={talentUsers} />}
            </Flex>

            <Flex css={{ justifyContent: "space-between", width: "100%" }}>
              <Box>
                <Text
                  variant={{
                    "@initial": "buttonText",
                    "@bp2": "h3",
                  }}
                  css={{
                    ml: talentUsers ? undefined : !showPencil ? "$0" : "$4",
                    mt: "$2",
                  }}
                >
                  {title}
                </Text>
                {subtitle && (
                  <Text css={{ pt: "$8" }} variant="meta">
                    {subtitle}
                  </Text>
                )}
              </Box>

              {!talentUsers && showPencil && (
                <Link to={linkTo}>
                  <Icon variant="pencil" color="grey6" size={20} />
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
