import React, { ReactNode } from "react";
import { styled } from "src/ccl";
import { Text, AvailableIcon, Icon } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Dropdown } from "src/ccl/navigation";
import { tokens } from "src/ccl/stitches/theme";
import { sendToAmplitude } from "src/utils/analytics";
import { uppercaseFirstLetterOfEachWord } from "src/utils/lang";
import {
  contactForList,
  howItWorksList,
  resourcesList,
} from "src/utils/navbar";

export const DropdownLauncher = styled("span", {
  fontSize: "$12",
  lineHeight: "$18",
  fontWeight: "$bold",
  textTransform: "uppercase",
  display: "none",
  alignItems: "center",

  "@bp2": {
    display: "flex",
  },
});

export const DropdownIcon = styled(Icon, {
  marginLeft: "$3",
});

const DropdownItemContainer = styled("a", {
  display: "flex",
  minWidth: "max-content",
  textDecoration: "none",
  alignItems: "center",
  py: "$4",
  px: "$5",
  borderRadius: "$md",

  "&:hover": {
    backgroundColor: "$grey1",
  },
});

const DropdownVerticalDescription = styled("div", {
  marginLeft: "$5",
});

export const DropDownItem = ({
  subtitle,
  icon,
  linkTo,
  title,
  eventName,
  openInNewWindow,
}: {
  title: string;
  subtitle: string;
  linkTo: string;
  icon: AvailableIcon;
  eventName: string;
  openInNewWindow?: boolean;
}) => (
  <DropdownItemContainer
    href={linkTo}
    target={openInNewWindow ? "_blank" : "_self"}
    onClick={() => sendToAmplitude(`top nav - ${eventName}`)}
    css={{
      "&:hover > *:first-child": {
        backgroundColor: "$white",
      },
    }}
  >
    <Flex
      css={{
        alignItems: "center",
        justifyContent: "center",
        width: 45,
        height: 45,
        backgroundColor: "$grey1",
        borderRadius: "$round",
      }}
    >
      <Icon variant={icon} size={24} />
    </Flex>
    <DropdownVerticalDescription>
      <Text css={{ fontWeight: "$bold" }}>{title}</Text>
      <Text variant="body" color="grey6">
        {subtitle}
      </Text>
    </DropdownVerticalDescription>
  </DropdownItemContainer>
);

export const HeaderDropdownWrapper = ({
  variant,
  title,
  onOpen,
  onClose,
  isActive,
  children,
}: {
  variant?: "rebrand";
  title: string;
  onOpen: () => void;
  onClose: () => void;
  isActive: boolean;
  children: ReactNode;
}) => (
  <Dropdown
    position="left"
    noOffset={true}
    bodyCss={{
      boxShadow: "$subtle",
      border: "none",
      width: "max-content",
      transform: `translate(-${tokens.space[6]}, 100%) rotateX(0deg)`,
    }}
    onOpen={onOpen}
    onClose={onClose}
    launcher={
      <DropdownLauncher
        data-test-id={`HeaderDropdown${uppercaseFirstLetterOfEachWord(
          title,
        )?.replaceAll(" ", "")}`}
        css={{
          py: "$12",
          color: isActive ? (variant ? "$white" : "$black") : "$grey6",
          "&:hover": {
            color: isActive ? (variant ? "$white" : "$black") : "$grey6",
            "svg path": {
              fill: isActive ? (variant ? "$white" : "$black") : "$grey6",
            },
          },
        }}
      >
        {title}
        <DropdownIcon
          variant="arrowDown"
          size={12}
          color={isActive ? (variant ? "white" : "black") : "grey6"}
        />
      </DropdownLauncher>
    }
  >
    <Box css={{ p: "$3" }}>{children}</Box>
  </Dropdown>
);

interface DropdownProps {
  variant?: "rebrand";
  onOpen: () => void;
  onClose: () => void;
  isActive: boolean;
  booker?: boolean;
}

export const HowItWorksDropdown = ({
  variant,
  onOpen,
  onClose,
  isActive,
}: DropdownProps) => (
  <HeaderDropdownWrapper
    variant={variant}
    title="How it works"
    onOpen={onOpen}
    onClose={onClose}
    isActive={isActive}
  >
    {howItWorksList.map((item, i) => {
      return (
        <DropDownItem
          key={i}
          title={item.title}
          subtitle={item.subtitle}
          icon={item.icon as AvailableIcon}
          linkTo={item.linkTo}
          eventName={item.eventName}
        />
      );
    })}
  </HeaderDropdownWrapper>
);

export const ResourcesDropdown = ({
  variant,
  onOpen,
  onClose,
  isActive,
  booker,
}: DropdownProps) => (
  <HeaderDropdownWrapper
    variant={variant}
    title="Resources"
    onOpen={onOpen}
    onClose={onClose}
    isActive={isActive}
  >
    {resourcesList.map((item, i) => {
      return (
        <DropDownItem
          key={i}
          title={item.title}
          subtitle={item.subtitle}
          icon={item.icon as AvailableIcon}
          linkTo={booker ? item.bookerLinkTo : item.linkTo}
          eventName={item.eventName}
          openInNewWindow={item.openInNewWindow}
        />
      );
    })}
  </HeaderDropdownWrapper>
);

export const ContactForDropdown = ({
  variant,
  onOpen,
  onClose,
  isActive,
}: DropdownProps) => (
  <HeaderDropdownWrapper
    variant={variant}
    title="Contact for"
    onOpen={onOpen}
    onClose={onClose}
    isActive={isActive}
  >
    {contactForList.map((item, i) => {
      return (
        <DropDownItem
          key={i}
          title={item.title}
          subtitle={item.subtitle}
          icon={item.icon as AvailableIcon}
          linkTo={item.linkTo}
          eventName={item.eventName}
        />
      );
    })}
  </HeaderDropdownWrapper>
);
