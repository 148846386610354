import React, { useEffect, useRef, useState } from "react";
import { styled } from "src/ccl/stitches/config";
import { useIntersect } from "src/hooks/useIntersect";

const defaultProps = {
  playsInline: true,
  muted: true,
  loop: true,
};

type VideoProps = React.ComponentPropsWithRef<"video">;

const UnstyledUnobtrusiveVideo = React.forwardRef(
  ({ ref, ...props }: VideoProps) => (
    <video {...defaultProps} {...props} ref={ref} />
  ),
);
UnstyledUnobtrusiveVideo.displayName = "UnstyledUnobtrusiveVideo";

export const UnobtrusiveVideo = styled(UnstyledUnobtrusiveVideo, {
  objectFit: "cover",
});

const UnstyledAutoplayVideo = React.forwardRef(
  ({ ref, ...props }: VideoProps) => (
    <video {...defaultProps} {...props} ref={ref} autoPlay />
  ),
);
UnstyledAutoplayVideo.displayName = "UnstyledAutoplayVideo";

export const AutoplayVideo = styled(UnstyledAutoplayVideo, {
  objectFit: "cover",
});

export const UnstyledViewportTriggeredVideo = ({
  ...props
}: React.ComponentPropsWithoutRef<"video">) => {
  const [sentinelRef, entry] = useIntersect();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [controlsVisible, setControlsVisible] = useState(false);

  useEffect(() => {
    if (!entry) {
      return;
    }
    if (entry.isIntersecting && videoRef.current) {
      const promise = videoRef.current.play();

      if (promise !== undefined) {
        promise.catch(() => {
          // Unable to play video, likely due to low power mode on iOS
          if (videoRef.current) {
            setControlsVisible(true);
          }
        });
      }
    }
  }, [entry]);

  return (
    <>
      <div ref={sentinelRef}></div>
      <video
        {...defaultProps}
        {...props}
        controls={controlsVisible}
        ref={videoRef}
      />
    </>
  );
};

export const ViewportTriggeredVideo = styled(UnstyledViewportTriggeredVideo, {
  objectFit: "cover",
});
