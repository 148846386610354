import React from "react";
import type * as Stitches from "@stitches/react";
import { mergeCss, styled } from "src/ccl/stitches";

export type ToolTipAlignment = "left" | "right" | "center" | "bottom";

export interface TooltipProps {
  content: React.ReactNode;
  active?: boolean;
  alignment?: ToolTipAlignment;
  css?: Stitches.CSS;
  wrapperCss?: Stitches.CSS;
  children?: React.ReactNode;
  hover?: "noMobileHover";
}

const RelativeContainer = styled("div", {
  position: "relative",
  zIndex: "1",
});

const Wrapper = styled("div", {
  pointerEvents: "none",
  position: "absolute",
  top: 0,
  padding: "$4",
  backgroundColor: "$black",
  color: "$white",
  opacity: 0,
  transition: "100ms ease opacity",
  fontSize: "$14",

  [`${RelativeContainer}:hover &`]: {
    opacity: 1,
  },

  variants: {
    alignment: {
      center: {
        transform: "translate(-50%, calc(-100% - 6px))",
        left: "50%",
      },
      left: {
        transform: "translate(0, calc(-100% - 6px))",
        left: 0,
      },
      right: {
        transform: "translate(0, calc(-100% - 6px))",
        right: 0,
      },
      bottom: {
        transform: "translate(-50%, 6px)",
        top: "100%",
        left: "50%",
      },
    },
    hover: {
      noMobileHover: {
        [`${RelativeContainer}:hover &`]: {
          opacity: 0,
        },
        "@bp3": {
          [`${RelativeContainer}:hover &`]: {
            opacity: 1,
          },
        },
      },
    },
  },
});

const Caret = styled("div", {
  width: "0",
  height: "0",
  position: "absolute",
  borderLeftWidth: "6px",
  borderLeftColor: "transparent",
  borderLeftStyle: "solid",
  borderRightWidth: "6px",
  borderRightColor: "transparent",
  borderRightStyle: "solid",
  borderTopColor: "$black",
  borderTopStyle: "solid",
  borderTopWidth: "6px",

  variants: {
    alignment: {
      center: {
        bottom: 0,
        left: "50%",
        transform: "translate(calc(50% - 12px), 6px)",
      },
      left: {
        bottom: 0,
        left: 0,
        transform: "translate(12px, 6px)",
      },
      right: {
        bottom: 0,
        right: 0,
        transform: "translate(-3px, 6px)",
      },
      bottom: {
        top: 0,
        left: "50%",
        transform: "translate(-50%, -6px) rotate(180deg)",
      },
    },
  },
});

export const Tooltip = ({
  content,
  active,
  alignment = "center",
  children,
  hover = undefined,
  css = {},
  wrapperCss = {},
}: TooltipProps) => (
  <RelativeContainer css={mergeCss({}, css)}>
    {children}
    <Wrapper
      hover={hover}
      alignment={alignment}
      css={mergeCss(
        active !== undefined
          ? {
              opacity: !active ? 0 : 1,
            }
          : {},
        wrapperCss,
      )}
    >
      <Caret alignment={alignment} />
      {content}
    </Wrapper>
  </RelativeContainer>
);
