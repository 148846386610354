import React from "react";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches";

export const DragHandleContainer = styled(Flex, {
  position: "absolute",
  top: "$3",
  zIndex: "$100",
  backgroundColor: "$grey1",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  cursor: "move",
  borderRadius: "$round",
  width: 38,
  height: 38,
});

export const DragHandle = ({
  index,
  attributes,
  listeners,
}: {
  index: number;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}) => (
  <>
    <DragHandleContainer
      {...(attributes || {})}
      {...(listeners || {})}
      data-test-id={`DragHandle${index}`}
      css={{
        left: "$4",
      }}
    >
      <Flex
        css={{
          "@bp2": { display: "none" },
        }}
      >
        <Icon variant="drag" size={20} css={{ "@bp2": { mt: "$2" } }} />
      </Flex>
      <Text css={{ display: "none", "@bp2": { display: "block" } }}>
        {index + 1}
      </Text>
    </DragHandleContainer>
    <DragHandleContainer
      data-test-id={`MobileIndexIndicator${index}`}
      css={{
        "@bp2": { display: "none" },
        right: "$4",
      }}
    >
      <Text>{index + 1}</Text>
    </DragHandleContainer>
  </>
);
