import { KanbanColumn } from ".";
import { Box, Flex } from "src/ccl/layout";
import { JobState, JobFilterInput, Assignee } from "src/graphql/types";

const ColumnOrder: JobState[] = [
  JobState.PendingApproval,
  JobState.Approved,
  JobState.AwaitingPayment,
  JobState.Finalized,
  JobState.Completed,
  JobState.Cancelled,
  JobState.Rejected,
  JobState.Expired,
];

interface KanbanBoardProps {
  isOwnContext: boolean;
  assignees: Assignee[];
  filters: JobFilterInput;
  setColumnJobCount: (count: number, column: string) => void;
}

export const KanbanBoard = ({
  isOwnContext,
  assignees,
  filters,
  setColumnJobCount,
}: KanbanBoardProps) => {
  return (
    <Box
      css={{
        width: "fit-content",
        margin: "1em 0",
        pl: "$4", // stops the popover looking weird on the far left
        paddingBottom: "$16", // allows for the popover to not get cut by the overflow
        "@bp2": {
          margin: "0",
        },
      }}
    >
      <Flex
        css={{
          paddingTop: "$3",
          gap: "10px",
        }}
      >
        {ColumnOrder.map((jobState: JobState, i: number) => (
          <KanbanColumn
            key={i}
            jobState={jobState}
            isOwnContext={isOwnContext}
            assignees={assignees}
            filters={filters}
            setTotalJobCount={(count: number) => {
              setColumnJobCount(count, jobState);
            }}
          />
        ))}
      </Flex>
    </Box>
  );
};
