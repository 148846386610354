import React from "react";
import { capitalize, camelCase } from "lodash";
import { Flex, Box } from "src/ccl/layout";
import { AvailableIcon, InfoBanner, Text } from "src/ccl/document";
import { ProfileAttribute, TalentProfile } from "src/graphql/types";
import { toList, uppercaseFirstLetter } from "src/utils/lang";
import { Pill } from "src/ccl/blocks";

const getFieldValue = (
  field: string,
  profile: TalentProfile,
  verticalSpecific?: boolean,
) => {
  if (verticalSpecific && profile.verticalData) {
    const data = profile.verticalData.find((d) => d.key === field);

    if (data) {
      return JSON.parse(data.value).map((i: string) => capitalize(i));
    } else {
      return null;
    }
  } else if (Object.keys(profile).includes(field)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (profile as any)[field];
  }

  return null;
};

const Attribute = ({
  profile,
  field,
  label,
  icon,
  verticalSpecific,
}: ProfileAttribute & { profile: TalentProfile }) => {
  const fieldValue = getFieldValue(field, profile, verticalSpecific);
  const creativeType: string[] =
    JSON.parse(
      profile.verticalData?.find((d) => d.key === "creative_type")?.value ||
        "null",
    ) || [];

  if (!fieldValue || fieldValue.length === 0) return null;

  return (
    <>
      {field === "creative_type" ? (
        <Flex css={{ mb: "$8" }}>
          {creativeType.map((type, index) => (
            <Pill key={`creativeType-${index}`} variant="roundedTertiary">
              <Text variant="meta" color="white">
                <InfoBanner
                  icon={camelCase(type) as AvailableIcon}
                  color="white"
                >
                  <Text color="white">
                    {uppercaseFirstLetter(type.split("_")[0])}
                  </Text>
                </InfoBanner>
              </Text>
            </Pill>
          ))}
        </Flex>
      ) : (
        <Box css={{ mb: "$8" }}>
          <InfoBanner
            icon={icon as AvailableIcon}
            variant="tightCentered"
            color="grey6"
          >
            <Text color="grey6" variant="mini">
              {label}
            </Text>
          </InfoBanner>
          <Text variant="body">{toList(fieldValue)}</Text>
        </Box>
      )}
    </>
  );
};

interface AttributesSectionProps {
  attributes: ProfileAttribute[];
  profile: TalentProfile;
}

export const AttributesSection = ({
  attributes,
  profile,
}: AttributesSectionProps) => (
  <Box data-test-id="ProfileAttributes">
    {attributes.map((attr, index) => (
      <Attribute key={index} profile={profile} {...attr} />
    ))}
  </Box>
);
