import React, { useRef } from "react";
import type * as Stitches from "@stitches/react";
import AliceCarousel from "react-alice-carousel";
import { Icon } from "src/ccl/document/icon";
import { Box, Flex } from "src/ccl/layout";
import { styled } from "src/ccl/stitches";

import "react-alice-carousel/lib/alice-carousel.css";
import { urlAddQuery } from "src/utils/url";

const Container = styled(Box, {
  position: "relative",
  overflow: "hidden",
  borderRadius: "14px",
});

const ControlBtn = styled("button", {
  background: "transparent",
  border: "none",
  zIndex: 10,
});

const ControlContainer = styled("div", {
  position: "absolute",
  display: "flex",
  justifyContent: "space-between",
  top: "50%",
  width: "100%",
});

const ControlWrapper = styled(Flex, {
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "$round",
  border: "2px solid $white",
  backgroundColor: "$black",
  px: "$3",
  py: "$3",
  cursor: "pointer",
  zIndex: "$100",
  variants: {
    context: {
      gridView: {
        border: "none",
        backgroundColor: "$white",
      },
    },
  },

  "@bp2": {
    [`${ControlContainer}:hover & `]: {
      display: "flex",
    },
  },
});

export interface ImageGalleryProps {
  assetsUrls: string[];
  css?: Stitches.CSS;
  alt: string;
  context?: "gridView";
  onClick?: () => void;
}

export const ImageGallery = ({
  assetsUrls,
  alt,
  css,
  context,
  onClick,
}: ImageGalleryProps) => {
  const carousel = useRef<AliceCarousel>(null);
  const inGridView = context === "gridView";
  const urlParams = inGridView
    ? "h=500&w=500&fit=crop&auto=format&crop=faces"
    : "h=300";

  const items = assetsUrls.map((url, i) => (
    <div data-value={i + 1} key={`${url}-${i}`} onClick={onClick}>
      <img
        src={urlAddQuery(url, urlParams)}
        alt={alt}
        style={{ borderRadius: "14px" }}
      />
    </div>
  ));

  return (
    <Container css={css}>
      <ControlContainer>
        <ControlBtn>
          <ControlWrapper
            context={context}
            onClick={(e) => {
              carousel?.current?.slidePrev(e);
            }}
          >
            <Icon variant="chevronLeft" size={16} color="black" />
          </ControlWrapper>
        </ControlBtn>
        <ControlBtn>
          <ControlWrapper
            context={context}
            onClick={(e) => {
              carousel?.current?.slideNext(e);
            }}
          >
            <Icon variant="chevronRight" size={16} color="black" />
          </ControlWrapper>
        </ControlBtn>
      </ControlContainer>
      <AliceCarousel
        key="carousel"
        mouseTracking
        touchTracking
        autoWidth
        disableDotsControls
        disableButtonsControls
        items={items}
        ref={carousel}
      />
    </Container>
  );
};
