import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { JobsGuideModalProgressBar } from "src/components/dashboards/booker";
import { Job, JobState } from "src/graphql/types";
import { Icon, Modal, Text } from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import { useStoreModel } from "src/hooks";

interface JobsGuideModalProps {
  job: Job;
  isOpen: boolean;
  onClose: () => void;
}
interface LocationState {
  jobJustCreated: boolean;
}

export const JobsGuideModal = ({
  job,
  isOpen,
  onClose,
}: JobsGuideModalProps) => {
  const jobState = job.state;
  const [stage, setStage] = useState(1);
  const currentUser = useStoreModel("currentUser");
  const { state } = useLocation<LocationState>();

  useEffect(() => {
    const stages = {
      [JobState.PendingApproval]: 2,
      [JobState.Approved]: 3,
      [JobState.AwaitingPayment]: 3,
      [JobState.Finalized]: 4,
      [JobState.Completed]: 5,
      [JobState.Expired]: 6,
      [JobState.Cancelled]: 7,
      [JobState.Rejected]: 8,
    };
    setStage(stages[jobState]);
  }, [jobState]);

  if (!currentUser.me) {
    return null;
  }

  const isJobNewlyCreatedAndPendingApproval =
    state?.jobJustCreated && stage === 2;

  const jobCreationMessage = isJobNewlyCreatedAndPendingApproval
    ? `Congrats, ${currentUser.me.name}! You've created a job.`
    : "Awesome! You've created a job.";

  const jobDetailsMessage = isJobNewlyCreatedAndPendingApproval
    ? "Remember, you can edit your shortlist or job details right up until you confirm the job."
    : "You've created a job. Remember, you can edit your shortlist or job details right up until you confirm the job.";

  return (
    <Modal isOpen={isOpen} showCloseButton onClose={onClose} width="super-wide">
      <Grid
        data-test-id="JobsGuideModal"
        css={{
          gridTemplateColumns: "1fr",
          px: "$0",
          py: "$3",
          rowGap: "50px",
          "@bp2": {
            px: "$3",
            py: "$7",
            gridTemplateColumns: "1fr 1.5fr",
          },
          "@bp4": { px: "$6", pt: "$9", pb: "$5" },
        }}
      >
        <Flex
          css={{
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Flex css={{ flexDirection: "column" }}>
            <Text
              weight="bold"
              variant={{ "@initial": "nh2", "@md": "nh1" }}
              css={{ mb: "30px" }}
            >
              Here&apos;s what happens next{" "}
              <Icon
                variant="linkOut"
                size={28}
                css={{
                  display: "none",
                  "@bp1": { ml: "$5", display: "initial" },
                }}
              />
            </Text>

            <Text weight="bold">{jobCreationMessage}</Text>
            <Text css={{ mt: "$3" }}>{jobDetailsMessage}</Text>
          </Flex>
        </Flex>
        <Box>
          <JobsGuideModalProgressBar state={jobState} stage={stage} />
        </Box>
      </Grid>
    </Modal>
  );
};
