import { styled } from "@stitches/react";
import type * as Stitches from "@stitches/react";
import React from "react";
import { Panel, Text } from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";
import { formatCurrency } from "src/utils/currencyFormatter";
import {
  jobPriceForCreatives,
  jobTotalBookingFee,
  jobTotalVatValue,
  jobTotalEstimateValue,
} from "src/utils/job";

interface TotalFeeEstimateProps {
  values: number[];
  variant?: "CAJ" | "JobManagement";
  css?: Stitches.CSS;
  currency?: string;
}

export const TotalFeeEstimate = ({
  values,
  variant = "CAJ",
  css = {},
  currency = "gbp",
}: TotalFeeEstimateProps) => {
  const Divider = styled("hr", {
    height: "1px",
    border: "none",
    backgroundColor: "$grey3",
  });

  return (
    <Panel
      css={mergeCss(
        { backgroundColor: "$grey1", border: "none", boxShadow: "none" },
        css,
      )}
    >
      <Box
        css={{ p: "$6 $8", "@bp3": { p: "$7 $9 $5 $9" } }}
        data-test-id="TotalFeeEstimate"
      >
        <Text
          variant={variant === "JobManagement" ? "mini" : "h3"}
          color={variant === "JobManagement" ? "grey6" : "black"}
        >
          {variant === "JobManagement" ? "Cost summary" : "Total Fee Estimate"}
        </Text>
        <Grid
          css={{
            py: "$4",
            gridColumns: 2,
            gridRowGap: "$4",
            gridTemplateColumns: "auto 50px",
            gridRows: 3,
          }}
        >
          <Text variant="meta" color="grey6">
            Total creative fees
          </Text>
          <Text
            data-test-id="total-fee-estimate-creative-fees"
            variant="meta"
            color="grey6"
            css={{ justifySelf: "end" }}
          >
            {formatCurrency(jobPriceForCreatives(values), currency)}
          </Text>
          <Text variant="meta" color="grey6">
            Booking fees
          </Text>
          <Text
            data-test-id="total-fee-estimate-booking-fee"
            variant="meta"
            color="grey6"
            css={{ justifySelf: "end" }}
          >
            {formatCurrency(jobTotalBookingFee(values), currency)}
          </Text>
          <Text variant="meta" color="grey6">
            VAT
          </Text>
          <Text
            data-test-id="total-fee-estimate-vat"
            variant="meta"
            color="grey6"
            css={{ justifySelf: "end" }}
          >
            {formatCurrency(jobTotalVatValue(values), currency)}
          </Text>
        </Grid>
        <Divider />
        <Flex
          css={{
            justifyContent:
              variant === "JobManagement" ? "space-between" : "flex-end",
            pt: "$4",
          }}
        >
          {variant === "JobManagement" && (
            <Text
              variant={variant === "JobManagement" ? "body" : "h3"}
              css={{
                fontWeight: variant === "JobManagement" ? "bold" : "normal",
              }}
            >
              Total fee estimate
            </Text>
          )}
          <Text
            data-test-id="total-fee-estimate-total"
            variant={variant === "JobManagement" ? "body" : "h3"}
            css={{
              fontWeight: variant === "JobManagement" ? "bold" : "normal",
            }}
          >
            {formatCurrency(jobTotalEstimateValue(values), currency)}
          </Text>
        </Flex>
      </Box>
    </Panel>
  );
};
