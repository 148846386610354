import React from "react";
import { ConfirmModal, ModalWidth, Text } from "src/ccl/document";

export const ResetCAJCopy =
  "This will clear your shortlist and you’ll lose your progress so far. Do you wish to continue?";
export const ResetNewTalentCopy =
  "Are you sure you want to start again? This will remove all of the profile details you have entered so far.";

interface ResetModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  copy: string;
  title?: string;
  confirmButtonTitle?: string;
  width?: ModalWidth;
  context?: "createJob" | "editJob";
}

export const ResetModal = ({
  onCancel,
  onConfirm,
  isOpen,
  copy,
  title = "Start again?",
  confirmButtonTitle = "Start again",
  width,
  context,
}: ResetModalProps) => (
  <ConfirmModal
    width={width}
    title={title}
    confirmButtonTitle={confirmButtonTitle}
    isOpen={isOpen}
    onCancel={onCancel}
    onConfirm={onConfirm}
    context={context}
  >
    <Text>{copy}</Text>
  </ConfirmModal>
);
