import React from "react";
import { useHistory } from "react-router-dom";
import { Icon, Modal, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";

export const AgencyCommissionSettingFeatureModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const history = useHistory();
  return (
    <Modal
      width="normal"
      onClose={onClose}
      isOpen={isOpen}
      showCloseButton={true}
      modalStyles={{ width: 539, transition: "0.3s ease-in" }}
      overlayCss={{ transition: "0.3s ease-in" }}
    >
      <Flex css={{ justifyContent: "space-around" }}>
        <Icon variant="moneyBag" css={{ width: 50, height: 50 }} />
      </Flex>
      <Text variant={"h2"} css={{ py: "$5", textAlign: "center" }}>
        Choose your commission
      </Text>
      <Text variant="body" css={{ textAlign: "center" }}>
        Now you can choose exactly how much commission per job goes to your
        creatives automatically. We take the job fee, and split it straight away
        to ensure you’re paid on time.
      </Text>
      <Flex css={{ justifyContent: "space-between", pt: "$9", pb: "$3" }}>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            history.push("/account/agency-settings/");
            onClose();
          }}
        >
          Go to settings
        </Button>
      </Flex>
    </Modal>
  );
};
