import React from "react";
import { TogglePill } from "src/ccl/blocks";
import { FilterOption } from "src/ccl/filtering";

interface HideContactTalentFilterProps {
  hideContactTalent: boolean;
  setHideContactTalent: (v: boolean) => void;
}

export const HideContactTalentFilter = ({
  hideContactTalent,
  setHideContactTalent,
}: HideContactTalentFilterProps) => (
  <TogglePill
    icon={hideContactTalent ? "eyeHidden" : "eye"}
    iconSize={24}
    checked={hideContactTalent}
    setChecked={setHideContactTalent}
    content="Hide Contact creatives"
    withHoverEffect
  />
);

export const HideContactTalentFilterOptions = ({
  hideContactTalent,
  setHideContactTalent,
}: HideContactTalentFilterProps) => (
  <>
    <FilterOption
      label="Hide Contact creatives"
      checked={hideContactTalent}
      onClick={() => setHideContactTalent(!hideContactTalent)}
    />
  </>
);
