import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Job,
  JobCancellationReason,
  MutationCancelJobArgs,
} from "src/graphql/types";
import { Text, OptionsModal, Modal, Icon } from "src/ccl/document";
import { CANCEL_JOB_MUTATION } from "src/graphql/mutations";
import { SelectReact, Textarea } from "src/ccl/data-entry";
import { Box, Flex } from "src/ccl/layout";
import { useNewDashboard } from "src/hooks/useNewDashboard";
import { Button } from "src/ccl/navigation";
import { Loading } from "src/components/Loading";

interface CancelJobModalProps {
  job: Job;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  refetch: () => void;
  options: { value: JobCancellationReason; label: string }[];
}

export const CancelJobModal = ({
  job,
  isOpen,
  onCancel,
  onConfirm,
  refetch,
  options,
}: CancelJobModalProps) => {
  const { isNewDashboard } = useNewDashboard();
  const [cancellationNote, setCancellationNote] = useState<string | undefined>(
    undefined,
  );
  const [cancelJob] = useMutation<MutationCancelJobArgs>(CANCEL_JOB_MUTATION);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const onClickCancel = async () => {
    setIsLoading(true);
    await cancelJob({
      variables: {
        jobId: job.id,
        cancellationReason: reason,
        cancellationNote,
      },
    });
    onConfirm();
    refetch();
  };

  if (isNewDashboard) {
    return (
      <Modal
        isOpen={isOpen}
        title="Cancel job"
        titleVariant="nh3"
        titleCss={{
          marginTop: 0,
          fontSize: "$22 !important",
        }}
        onClose={onCancel}
        width="wide"
        showCloseButton
      >
        <SelectReact
          id="reasonField"
          placeholder="Select a reason"
          options={options}
          value={undefined}
          onChange={(value) => setReason(value?.value)}
          variant="rebrand"
        />
        <Box css={{ mt: "$6" }}>
          <Text css={{ mb: "$3" }}>Add an optional note:</Text>
          <Textarea
            variant="rebrand"
            css={{
              height: "$19",
            }}
            onChange={(e) => setCancellationNote(e.target.value)}
            placeholder="Is there anything else you'd like to share? Could we have done anything to improve your experience?"
          />
        </Box>
        <Flex
          css={{
            mt: "$14",
            justifyContent: "flex-start",
            flexDirection: "column-reverse",
            "@bp1": {
              justifyContent: "space-between",
              flexDirection: "row",
            },
          }}
        >
          <Button
            variant="secondaryCta"
            type="reset"
            onClick={onCancel}
            css={{
              padding: "21px 24px",
              height: "fit-content",
              fontFamily: "$sansNew",
              fontSize: "14px",
              fontWeight: "$bold",
            }}
          >
            CLOSE
          </Button>
          <Box>
            <Button
              variant="primaryCta"
              type="submit"
              css={{
                mb: "$5",
                mr: "$0",
                width: "100%",
                padding: "21px 24px",
              }}
              data-test-id="cancel-button"
              onClick={onClickCancel}
              disabled={!reason || isLoading}
            >
              {isLoading ? (
                <Loading color="white" css={{ height: "$4" }} />
              ) : (
                <Flex
                  css={{
                    gap: "$3",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "$sansNew",
                    fontSize: "14px",
                    fontWeight: "$bold",
                  }}
                >
                  <Icon variant="cross" color="white" size={12} />
                  CANCEL JOB
                </Flex>
              )}
            </Button>
          </Box>
        </Flex>
      </Modal>
    );
  }

  return (
    <OptionsModal
      title="Cancel Job"
      subTitle={<Text>Please select a reason for cancellation</Text>}
      isOpen={isOpen}
      confirmButtonTitle="Cancel Job"
      confirmButtonLoadingTitle="Cancelling..."
      cancelButtonTitle="Close"
      onCancel={onCancel}
      onConfirm={async (reason) => {
        await cancelJob({
          variables: {
            jobId: job.id,
            cancellationReason: reason,
            cancellationNote,
          },
        });
        onConfirm();
        refetch();
      }}
      options={options}
    >
      <Box css={{ mt: "$6" }}>
        <Text css={{ mb: "$3" }}>Add an optional note:</Text>
        <Textarea
          rows={3}
          onChange={(e) => setCancellationNote(e.target.value)}
          placeholder="Is there anything else you'd like to share? Could we have done anything to improve your experience?"
        />
      </Box>
    </OptionsModal>
  );
};
