import React, { useState } from "react";
import type * as Stitches from "@stitches/react";
import { Box, Flex } from "src/ccl/layout";
import { mergeCss, styled } from "src/ccl/stitches";
import { Icon, Text } from "src/ccl/document";

const Wrapper = styled(Box, {
  width: "100%",
  borderWidth: 2,
  borderColor: "$black",
  borderBottomStyle: "solid",
  borderLeftStyle: "none",
  borderRightStyle: "none",
  borderTopStyle: "none",
  boxShadow: "$focus",
  marginRight: "$9",
  marginBottom: "$7",
  marginTop: "$5",
  flexShrink: 0,
  position: "sticky",
  left: 0,
  maxHeight: "calc(100vh - 150px)",
  overflow: "auto",
  zIndex: "$300",
  backgroundColor: "$white",
  "@bp4": {
    marginBottom: "$0",
    marginTop: "$0",
    width: 324,
    borderRightStyle: "solid",
    borderTopStyle: "solid",
    borderLeftStyle: "solid",
  },

  variants: {
    variant: {
      edit: {
        marginRight: "$0",
      },
    },
  },
});

const Header = styled(Box, {
  backgroundColor: "$grey1",
  py: "$3",
  px: "$5",
  fontSize: "$14",
  textAlign: "right",
  borderBottomWidth: 1,
  borderBottomColor: "$grey3",
  borderBottomStyle: "solid",
});

export interface FilterPaneProps {
  summaryContent: React.ReactNode;
  mobileContent?: string;
  totalCount?: number;
  expandedHeaderContent?: React.ReactNode;
  unexpandedHeaderContent?: React.ReactNode;
  css?: Stitches.CSS;
  variant?: "edit";
  children?: React.ReactNode;
}

export const FilterPane = ({
  summaryContent,
  mobileContent = "Filters",
  totalCount,
  expandedHeaderContent = "Hide filters",
  unexpandedHeaderContent = "Show filters",
  children,
  css = {},
  variant,
}: FilterPaneProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Wrapper
      data-test-id="FCFilterPane"
      css={mergeCss(
        {
          maxHeight: "60vh",
        },
        css,
      )}
      variant={variant}
    >
      <Header
        css={{
          position: "sticky",
          top: "0",
          zIndex: "$200",
        }}
      >
        <Flex
          css={{
            alignItems: "center",
            display: "flex",
            "@bp4": { display: "none" },
          }}
        >
          <Box
            css={{ cursor: "pointer" }}
            onClick={() => {
              setExpanded(!expanded);
              // react-range needs a nudge to position the thumbs correctly
              // on iOS
              window.dispatchEvent(new Event("resize"));
            }}
          >
            <Icon
              variant="expand"
              size={14}
              css={{
                transform: expanded ? "rotate(180deg)" : undefined,
              }}
            />
            <Text
              as="span"
              variant="body"
              weight="bold"
              css={{
                ml: "$3",
                display: "inline-block",
                "@bp2": { display: "none" },
              }}
            >
              {mobileContent}
            </Text>
            {totalCount !== undefined && (
              <Text
                as="span"
                variant="meta"
                color="grey6"
                css={{
                  ml: "$2",
                  display: "inline-block",
                  "@bp2": { display: "none" },
                }}
              >
                ({totalCount})
              </Text>
            )}
            <Text
              as="span"
              css={{
                fontSize: "$16",
                fontWeight: "$bold",
                ml: "$3",
                display: "none",
                "@bp2": { display: "inline-block" },
              }}
            >
              {expanded ? expandedHeaderContent : unexpandedHeaderContent}
            </Text>
          </Box>

          <Text css={{ ml: "auto" }}>{summaryContent}</Text>
        </Flex>
        <Box css={{ display: "none", "@bp4": { display: "block", py: "$4" } }}>
          {summaryContent}
        </Box>
      </Header>
      <Box
        css={{
          display: expanded ? "block" : "none",
          "@bp4": {
            display: "block",
          },
        }}
      >
        {children}
      </Box>
    </Wrapper>
  );
};
