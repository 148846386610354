import React from "react";
import { User } from "src/graphql/generated/types";
import { Box, Flex } from "src/ccl/layout";
import { AssetImage, Text } from "src/ccl/document";
import { Pill } from "src/ccl/blocks";

export const SelectionSummaryCreativeList = ({
  users,
  isRemovable,
  onRemove,
}: {
  users: User[];
  isRemovable: (u: User) => boolean;
  onRemove: (u: User) => void;
}) => (
  <Flex
    css={{
      flexWrap: "wrap",
      ml: "$4",
    }}
  >
    {users.reverse().map((u) => (
      <Pill
        css={{
          mt: "$3",
          height: "$9",
        }}
        variant="regular"
        suffixIcon={isRemovable(u) ? "cross" : undefined}
        onSuffixIconClick={() => {
          if (isRemovable(u)) {
            onRemove(u);
          }
        }}
        key={u.id}
      >
        <Flex css={{ display: "none", "@bp4": { display: "flex" } }}>
          <Box css={{ width: 24, mr: "$3" }}>
            {u.featuredAsset && (
              <AssetImage
                asset={u.featuredAsset}
                size={{
                  "@initial": {
                    width: 24,
                    aspectRatio: 1,
                  },
                }}
                alt={`A picture of ${u.name}`}
                imgCss={{ display: "block" }}
              />
            )}
          </Box>
          <Text
            variant="meta"
            color="grey6"
            css={{
              pr: "$2",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "50px",
              "@bp5": {
                maxWidth: "100px",
              },
            }}
          >
            {u.name}
          </Text>
        </Flex>
      </Pill>
    ))}
  </Flex>
);
