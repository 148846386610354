import React from "react";
import type * as Stitches from "@stitches/react";
import { Flex, Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { LinkButton } from "src/ccl/navigation";

interface ViewInvoiceButtonProps {
  unpaidInvoiceCount: number;
  url: string;
  buttonCss?: Stitches.CSS;
}

export const ViewInvoiceButton = ({
  unpaidInvoiceCount,
  url,
  buttonCss = {},
}: ViewInvoiceButtonProps) => (
  <Box data-test-id="view-invoices-button" css={{ position: "relative" }}>
    {unpaidInvoiceCount > 0 && (
      <Flex
        data-test-id="view-invoices-button-unpaid-count"
        css={{
          position: "absolute",
          top: -8,
          right: -8,
          backgroundColor: "$red",
          color: "$white",
          fontSize: "$12",
          border: "3px solid $white",
          borderRadius: "$round",
          alignItems: "center",
          justifyContent: "center",
          width: 24,
          height: 24,
          pointerEvents: "none",
        }}
      >
        <Text
          weight="bold"
          css={{
            fontSize: "$12",
            color: "$white",
          }}
        >
          {unpaidInvoiceCount}
        </Text>
      </Flex>
    )}

    <LinkButton to={url} css={buttonCss}>
      View invoices
    </LinkButton>
  </Box>
);
