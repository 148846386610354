import { gql } from "@apollo/client";

export const META_FRAGMENT = gql`
  fragment Meta on UserConnection {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
  }
`;

export const USER_BASE_FRAGMENT = gql`
  fragment UserBase on User {
    id
    name
    slug
    country
  }
`;

export const USER_ASSET_FRAGMENT = gql`
  fragment UserAsset on User {
    featuredAsset {
      mediaUrl
    }
  }
`;

export const USER_PROFILE_FRAGMENT = gql`
  fragment UserProfile on TalentProfile {
    vertical
    verticalData {
      key
      value
    }
    id
    averageRating
    totalReviews
    location
    published
    primaryLocation {
      name
    }
    additionalLocations {
      name
    }
    minDayRate
    isFeatured
    isAgencyFeatured
  }
`;
