import React, { useState } from "react";
import {
  FeesBreakdown,
  HowFeesWork,
  TermsAndConditions,
  JobAddress,
} from "./jobConfirmModal";
import { Job, JobTalent } from "src/graphql/types";
import { Modal } from "src/ccl/document";

type ModalState =
  | "FeesBreakdown"
  | "HowFeesWork"
  | "JobAddress"
  | "TermsAndConditions";

interface ConfirmJobModalProps {
  job: Job;
  selectedJobTalent: JobTalent[];
  isOpen: boolean;
  onCancel: () => void;
  refetchJob: () => void;
}

export const ConfirmJobModal = ({
  job,
  selectedJobTalent,
  isOpen,
  onCancel,
  refetchJob,
}: ConfirmJobModalProps) => {
  const [modalState, setModalState] = useState<ModalState>("FeesBreakdown");

  return (
    <Modal
      width="wide"
      isOpen={isOpen}
      showCloseButton={true}
      onClose={onCancel}
    >
      {modalState === "FeesBreakdown" && (
        <FeesBreakdown
          job={job}
          selectedJobTalent={selectedJobTalent}
          onClickHowFeesWork={() => setModalState("HowFeesWork")}
          onCancel={onCancel}
          onContinue={() => setModalState("JobAddress")}
        />
      )}

      {modalState === "HowFeesWork" && (
        <HowFeesWork onBackClick={() => setModalState("FeesBreakdown")} />
      )}

      {modalState === "JobAddress" && (
        <JobAddress
          job={job}
          onBack={() => setModalState("FeesBreakdown")}
          onContinue={() => {
            setModalState("TermsAndConditions");
          }}
        />
      )}
      {modalState === "TermsAndConditions" && (
        <TermsAndConditions
          refetchJob={refetchJob}
          job={job}
          selectedJobTalent={selectedJobTalent}
          onBack={() => setModalState("JobAddress")}
          onCancel={onCancel}
        />
      )}
    </Modal>
  );
};
