import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Flex } from "src/ccl/layout";
import { Icon, InfoBanner, Text } from "src/ccl/document";
import { useStoreModel } from "src/hooks";
import { Button, Link } from "src/ccl/navigation";
import { ShortlistVerticals } from "src/components/ShortlistVerticals";
import { getTalentVerticals } from "src/utils/user";
import { pluralize } from "src/utils/lang";
import { User } from "src/graphql/types";

const EmptyState = () => (
  <Box css={{ textAlign: "center", mt: "$11" }}>
    <Icon variant="starSearch" size={80} color="grey3" />
    <Text color="grey6">
      Add talents to build your shortlist. You can also create a job and add
      talents later.
    </Text>
  </Box>
);

export interface ShortlistProps {
  jobName: string;
  onContinue: () => void;
}

export const Shortlist = ({ jobName, onContinue }: ShortlistProps) => {
  const {
    draftV2: { talentUsers, removeTalentUser },
  } = useStoreModel("jobs");
  const history = useHistory();
  const { pathname } = useLocation();
  const talentVerticals = getTalentVerticals(talentUsers);
  const numberOfTalent = talentUsers.length;
  const currentUser = useStoreModel("currentUser");
  const [initialTalentUsers, setInitialTalentUsers] =
    useState<User[]>(talentUsers);

  const isLoggedIn = currentUser?.loggedIn;
  const talentDiff = numberOfTalent - initialTalentUsers.length;

  useEffect(() => {
    if (talentUsers.length !== 0) {
      return;
    }
    setInitialTalentUsers(talentUsers);
  }, [talentUsers]);

  return (
    <Box data-test-id="Shortlist" css={{ width: "100%", height: "100vh" }}>
      <Flex
        css={{
          pt: "$11",
          pl: "$8",
          height: "100%",
          flexDirection: "column",
          boxShadow: "0px 4px 20px 0px #00000014",
        }}
      >
        <Flex
          css={{
            pb: "30px",
            height: "54px",
            alignItems: "center",
            gap: "$4",
            whiteSpace: "nowrap",
            overflowX: "visible",
            textOverflow: "clip",
          }}
        >
          <Text variant="nh3">Edit Shortlist</Text>
          <Text variant="body">{`${pluralize(
            numberOfTalent,
            "talent",
            "talents",
          )} shortlisted`}</Text>
        </Flex>

        <Box
          css={{
            overflowX: "hidden",
            borderBottom: "1px solid $grey2",
            pb: "$5",
            flex: 1,
          }}
        >
          {numberOfTalent === 0 ? (
            <EmptyState />
          ) : (
            <ShortlistVerticals
              url={(slug) => `/jobs/new/shortlist?profile=${slug}&t=all`}
              talent={talentUsers}
              talentVerticals={talentVerticals}
              isRemovable={(u) => !!talentUsers.map((t) => t.id).includes(u.id)}
              onRemove={(u) => {
                removeTalentUser(u.id);
              }}
              withinShortlistButton={true}
              removeText="Remove"
              avatarSizes={{
                width: 72,
                height: 90,
              }}
              initialTalentUsers={initialTalentUsers.map((u) => u.id)}
            />
          )}
        </Box>

        <>
          <Text
            weight="bold"
            css={{
              mt: "$6",
              mb: "$4",
            }}
          >
            {Math.abs(talentDiff)} talents{" "}
            {talentDiff < 0 ? "removed from" : "added to"} shortlist
          </Text>
          <Text
            variant="meta"
            color="grey6"
            css={{
              mb: "$9",
            }}
          >
            {numberOfTalent === 0 ? (
              "Start adding talents in the Marketplace to your shortlist."
            ) : (
              <span>
                You are about to add the following talents selected above to the
                job <strong>{jobName}.</strong>
              </span>
            )}
          </Text>

          {isLoggedIn ? (
            <Flex
              css={{
                flexDirection: "column",
                gap: "14px",
              }}
            >
              {![
                "/jobs/new/shortlist",
                "/dashboard/client/talent/marketplace",
                "/dashboard/client/talent/select",
                "/dashboard/client/talent/open",
              ].includes(pathname) && (
                <Button variant="primaryCta" onClick={onContinue}>
                  Update Shortlist
                </Button>
              )}
            </Flex>
          ) : (
            <Flex css={{ flexDirection: "column", gap: "14px" }}>
              <Button
                type="submit"
                variant="primaryCta"
                onClick={() => history.push("/jobs/new/sign-in")}
              >
                Sign In to continue
              </Button>
              <Flex css={{ justifyContent: "center" }}>
                <InfoBanner
                  css={{
                    alignItems: "center",
                  }}
                >
                  <Text
                    css={{
                      color: "$grey6",
                      fontFamily: "$sansNew",
                      fontSize: "$14",
                      fontWeight: "$medium",
                      lineHeight: "19px",
                      letterSpacing: "0.02em",
                    }}
                  >
                    New here?{" "}
                    <Link
                      to="/jobs/new/sign-up"
                      css={{
                        color: "$grey6",
                      }}
                    >
                      Create an account here.
                    </Link>
                  </Text>
                </InfoBanner>
              </Flex>
            </Flex>
          )}
        </>
      </Flex>
    </Box>
  );
};
