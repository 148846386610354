import { gql } from "@apollo/client";

export const UPDATE_AGENCY_TALENT_MUTATION = gql`
  mutation UpdateAgencyTalent(
    $talentId: ID!
    $name: String
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $county: String
    $country: String
    $postcode: String
    $groupIds: [ID!]
    $talentProfile: UpdateTalentProfileInput
  ) {
    updateAgencyTalent(
      talentId: $talentId
      name: $name
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      county: $county
      country: $country
      postcode: $postcode
      groupIds: $groupIds
      talentProfile: $talentProfile
    ) {
      id
      featuredAsset {
        mediaUrl
      }
      profile {
        ... on TalentProfile {
          gender
          unhideContactSelectRequest {
            requestReason
            status
          }
          isFeatured
          isAgencyFeatured
          isContactSelect
          isContactOpen
        }
      }
    }
  }
`;
