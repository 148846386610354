import { useFormikContext } from "formik";
import { ProfileVerticalFieldsForm } from "./ProfileVerticalFieldsForm";
import { TalentFormValues } from "./formConfigV2";
import SocialMedia from "./SocialMedia";
import { Box, Flex } from "src/ccl/layout";
import { Checkbox } from "src/ccl/data-entry";
import { Text } from "src/ccl/document";
import { useStoreModel } from "src/hooks";
import {
  Asset,
  ProfileFormContext,
  TalentVertical,
  UpdateTalentProfileInput,
  User,
  VerticalConfiguration,
} from "src/graphql/types";
import {
  PaymentDetails,
  TalentDetails,
} from "src/components/dashboards/agents/talentManagement";

import { UploadedFile } from "src/entities";

interface ProfileFormProps {
  user?: User;
  values: TalentFormValues;
  vertical: TalentVertical;
  verticalConfig: VerticalConfiguration;
  onProfilePictureUpload?: (asset: UploadedFile) => void;
  profilePicture?: Asset;
  context: ProfileFormContext;
}

export const ProfileForm = ({
  values,
  vertical,
  verticalConfig,
  user,
  profilePicture,
  onProfilePictureUpload,
  context,
}: ProfileFormProps) => {
  const talentProfile = values.talentProfile as UpdateTalentProfileInput;
  const { setFieldValue, errors } = useFormikContext();
  const { isFirstPartyAgent } = useStoreModel("currentUser");

  return (
    <Box>
      <TalentDetails
        user={user}
        talentProfile={talentProfile}
        profilePicture={profilePicture}
        onProfilePictureUpload={onProfilePictureUpload}
        context={context}
        vertical={vertical}
      />

      <ProfileVerticalFieldsForm
        vertical={vertical}
        verticalConfig={verticalConfig}
        talentProfile={talentProfile}
        values={values}
        errors={errors}
      />

      <SocialMedia
        talentProfile={talentProfile}
        context={context}
        vertical={vertical}
      />

      <PaymentDetails values={values} context={context} />

      {isFirstPartyAgent && (
        <Box
          css={{
            mb: "$9",
            "@bp2": { mb: "$16" },
          }}
        >
          <Text variant="nh3" css={{ mt: "$16", mb: "$4" }}>
            Temporarily hide
          </Text>
          <Text variant="b3">
            Useful if the talent does not currently want bookings, but we do not
            want to commit to fully deleting the profile.
          </Text>
          <Flex css={{ alignItems: "center", mt: "$3" }}>
            <Checkbox
              variant="rebrand"
              name="profile-published"
              css={{ width: 24, height: 24 }}
              checked={!values?.talentProfile?.published}
              onChange={() => {
                setFieldValue(
                  "talentProfile.published",
                  !values?.talentProfile?.published,
                );
              }}
            />
            <Text
              as="label"
              htmlFor="form-profile-published"
              css={{ ml: "$3" }}
            >
              Hide this profile from future clients
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
