import { Icon, InfoBanner, Panel, Text } from "src/ccl/document";
import { HorizontalProgressIcon } from "src/ccl/jobs";
import { Box, Flex } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";
import { SubTextBasedOnJobState } from "src/components/dashboards/booker/SubTextBasedOnJobState";
import { JobStateColors } from "src/graphql/colors";
import { Job } from "src/graphql/types";
import {
  jobAllTalentResponded,
  jobApproved,
  jobAwaitingPayment,
  jobCancelled,
  jobFinalized,
  jobIsLive,
  jobPaidUpfront,
  jobPendingApproval,
  jobTitleForBooker,
} from "src/utils/job";

const WaitTimeTextBasedOnJobState = ({ job }: { job: Job }) => (
  <Box css={{ overflowX: "hidden" }}>
    <Text
      variant="meta"
      color="grey6"
      css={{
        fontWeight: "$bold",
        "@bp1": {
          transform: jobPendingApproval(job.state)
            ? "translate(calc(33% - 8px), 0)"
            : jobApproved(job.state)
            ? "translate(calc(66% - 8px), 0)"
            : undefined,
        },
      }}
    >
      {jobAllTalentResponded(job)
        ? "All responded"
        : jobPendingApproval(job.state)
        ? "Average wait: 7 hrs"
        : "Average wait: 20 hrs"}
    </Text>
  </Box>
);

interface ProgressPanelProps {
  job: Job;
  openJobsGuide: () => void;
  onChoosePaymentMethodClick: () => void;
  onReportIssueClick: () => void;
  onPayInvoice: () => void;
}

export const ProgressPanel = ({
  job,
  openJobsGuide,
  onChoosePaymentMethodClick,
  onReportIssueClick,
  onPayInvoice,
}: ProgressPanelProps) => {
  const isAwaitingPayment = jobAwaitingPayment(job.state);
  const isFinalized = jobFinalized(job.state);
  const isLive = jobIsLive(job);

  const jobStateColor = JobStateColors[job.state];

  const jobTitle = jobTitleForBooker(job);

  const progressModalEnabled = isLive && !isAwaitingPayment;

  return (
    <Panel
      variant="secondary"
      css={{
        boxShadow: "$focus",
        p: "$6",
        cursor: progressModalEnabled ? "pointer" : "initial",
      }}
      onClick={progressModalEnabled ? openJobsGuide : undefined}
    >
      <Flex
        css={{
          flexDirection: "column",
          gap: "$6",
        }}
      >
        <Flex
          css={{
            justifyContent: "space-between",
            alignItems: "center",
            gap: "$10",
          }}
        >
          <Box>
            {jobCancelled(job.state) ? (
              <Text
                variant={{ "@initial": "body", "@bp1": "h3", "@bp4": "h2" }}
                css={{
                  fontWeight: "$bold",
                  color: `$${jobStateColor}` as keyof typeof tokens.colors,
                }}
              >
                Job cancelled
              </Text>
            ) : (
              <Text
                variant={{ "@initial": "body", "@bp1": "h3", "@bp4": "h2" }}
                css={{
                  fontWeight: "$bold",
                  color: `$${jobStateColor}` as keyof typeof tokens.colors,
                }}
              >
                {jobTitle}
              </Text>
            )}
          </Box>
          {!isAwaitingPayment && isLive && (
            <Flex
              css={{
                alignItems: "center",
                display: "none",
                "@bp1": {
                  display: "flex",
                  alignSelf: "start",
                },
              }}
            >
              <InfoBanner iconSize={14} variant="tightCentered">
                <Text
                  variant="meta"
                  css={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                  }}
                  onClick={openJobsGuide}
                >
                  Job guide
                </Text>
              </InfoBanner>
            </Flex>
          )}
        </Flex>
        {isLive && !isFinalized && !isAwaitingPayment && (
          <Flex css={{ "@bp1": { display: "none" }, alignItems: "center" }}>
            <Icon variant="timer" size={16} color="grey6" css={{ mr: "$3" }} />
            <WaitTimeTextBasedOnJobState job={job} />
          </Flex>
        )}
        <SubTextBasedOnJobState
          job={job}
          onChoosePaymentMethod={onChoosePaymentMethodClick}
          onReportIssue={onReportIssueClick}
          onPayInvoice={onPayInvoice}
        />
        {!job.hasUnresolvedDisputes &&
          isLive &&
          !isAwaitingPayment &&
          !jobPaidUpfront(job) && (
            <Box>
              <HorizontalProgressIcon job={job} />
              {!isAwaitingPayment && !isFinalized && (
                <Box css={{ display: "none", "@bp1": { display: "block" } }}>
                  <WaitTimeTextBasedOnJobState job={job} />
                </Box>
              )}
            </Box>
          )}
      </Flex>
    </Panel>
  );
};
