import { gql } from "@apollo/client";

export const NOTE_DETAILS_FRAGMENT = gql`
  fragment NoteDetails on Note {
    id
    body
    createdAt
    user {
      name
      featuredAsset {
        mediaUrl
      }
    }
  }
`;
