import { styled } from "src/ccl";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { SearchFilter } from "src/components/filtering";
import { BookerTypeFilterOptions } from "src/components/dashboards/agents/bookers/BookerTypeFilter";

interface BookerFiltersModalProps extends ModalProps {
  nameOrEmail: string;
  setNameOrEmail: (search: string) => void;
  bookerKind: string;
  setBookerKind: (kind: string) => void;
}

const Divider = styled("hr", {
  width: "100%",
  height: "1px",
  border: "none",
  backgroundColor: "$grey1",
});

export const FilterContainer = ({
  label,
  children,
}: {
  label: string;
  children: JSX.Element;
}) => {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "18px",
      }}
    >
      <Text variant="nh4">{label}</Text>
      {children}
    </Flex>
  );
};

export const BookerFiltersModal = ({
  isOpen,
  onClose,
  nameOrEmail,
  setNameOrEmail,
  bookerKind,
  setBookerKind,
}: BookerFiltersModalProps) => {
  return (
    <Modal
      title="Filters"
      titleVariant="nh3"
      titleCss={{
        marginBottom: 0,
        fontSize: "$22 !important",
      }}
      showCloseButton
      withBodyPadding={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex
        css={{
          pt: "$5",
          px: "$5",
        }}
      >
        <Divider />
      </Flex>
      <Flex
        data-test-id="BookerFiltersModal"
        css={{
          flexDirection: "column",
          gap: "32px",
          padding: "$9 $5",
          maxHeight: "595px",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            backgroundColor: "$white",
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "$grey3",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "$grey2",
            borderRadius: "5px",
            height: "81px",
          },
        }}
      >
        <FilterContainer label="Name">
          <SearchFilter
            placeholder="Search by name or email"
            search={nameOrEmail}
            onChange={setNameOrEmail}
            withinModal={true}
            showClearButton
          />
        </FilterContainer>
        <FilterContainer label="Booker type">
          <Flex
            css={{
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <BookerTypeFilterOptions
              variant="pill"
              bookerKind={bookerKind}
              setBookerKind={setBookerKind}
            />
          </Flex>
        </FilterContainer>
      </Flex>
    </Modal>
  );
};
