import { gql } from "@apollo/client";
import { PORTFOLIO_IMAGE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const CREATE_PORTFOLIO_IMAGES_MUTATION = gql`
  mutation CreatePortfolioImages(
    $talentProfileId: ID!
    $files: [FileUploadInput!]!
    $portfolioImageCategoryId: ID
  ) {
    createPortfolioImages(
      talentProfileId: $talentProfileId
      files: $files
      portfolioImageCategoryId: $portfolioImageCategoryId
    ) {
      ...PortfolioImageDetails
    }
  }
  ${PORTFOLIO_IMAGE_DETAILS_FRAGMENT}
`;
