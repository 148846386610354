import { gql } from "@apollo/client";
import { PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT } from "src/graphql/fragments/PortfolioImageCategoryDetails";
import { PORTFOLIO_IMAGE_DETAILS_FRAGMENT } from "src/graphql/fragments/PortfolioImageDetails";

export const AGENT_TALENT_INDEX_USERS = gql`
  query AgentTalentIndexUsers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $name: String
    $gender: Gender
    $genders: [Gender!]
    $inSameAgency: Boolean
    $vertical: TalentVertical
    $verticals: [TalentVertical!]
    $fields: [VerticalFieldInput!]
    $sortField: String
    $sortDirection: SortDirection
    $excludedIds: [Int!]
    $locationContext: TalentSearchLocationContext
    $location: String
    $locations: [String!]
    $minAge: Int
    $maxAge: Int
    $minDayRate: Int
    $maxDayRate: Int
    $hobbies: [String!]
    $random: Boolean
    $group: TalentSearchGroup
    $groupId: Int
    $availableFrom: ISO8601Date
    $availableTo: ISO8601Date
    $isContactOpen: Boolean
    $isContactSelect: Boolean
  ) {
    verticalTalentSearch(
      after: $after
      before: $before
      first: $first
      last: $last
      name: $name
      gender: $gender
      genders: $genders
      inSameAgency: $inSameAgency
      vertical: $vertical
      verticals: $verticals
      fields: $fields
      sortField: $sortField
      sortDirection: $sortDirection
      excludedIds: $excludedIds
      locationContext: $locationContext
      location: $location
      locations: $locations
      minAge: $minAge
      maxAge: $maxAge
      minDayRate: $minDayRate
      maxDayRate: $maxDayRate
      hobbies: $hobbies
      random: $random
      group: $group
      groupId: $groupId
      availableFrom: $availableFrom
      availableTo: $availableTo
      isContactOpen: $isContactOpen
      isContactSelect: $isContactSelect
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          id
          name
          slug
          email
          completedJobCount
          createdAt
          phoneNumber
          groups {
            id
            manuallySet
            name
          }
          featuredAsset {
            mediaUrl
          }
          agency {
            id
          }
          averageResponseTimeMinutes
          profile {
            ... on TalentProfile {
              vertical
              socialMediaFields {
                platform
                handle
                followerCount
              }
              portfolioImages {
                ...PortfolioImageDetails
              }
              portfolioImageCategories {
                ...PortfolioImageCategoryDetails
              }
              primaryLocation {
                name
              }
              instagramUsername
              minDayRate
              maxDayRate
              missingInformation
              missingMeasurements
              published
              averageRating
              totalReviews
              isFeatured
              isAgencyFeatured
            }
          }
        }
      }
    }
  }
  ${PORTFOLIO_IMAGE_DETAILS_FRAGMENT}
  ${PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT}
`;
