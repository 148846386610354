import React, { useState } from "react";
import { Pill } from "src/ccl/blocks";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { iconSizes } from "src/ccl/stitches/theme";

export const TogglePill = ({
  checked,
  setChecked,
  icon,
  content,
  withHoverEffect = false,
  iconSize = 20,
}: {
  checked: boolean;
  setChecked: (v: boolean) => void;
  icon: AvailableIcon;
  content: string;
  withHoverEffect?: boolean;
  iconSize?: keyof typeof iconSizes;
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      onMouseOver={() => {
        withHoverEffect && setHovered(true);
      }}
      onMouseLeave={() => {
        withHoverEffect && setHovered(false);
      }}
    >
      <Pill
        variant="rebrand"
        onClick={() => (checked ? setChecked(false) : setChecked(true))}
        css={{
          borderColor: checked || hovered ? "$black" : "$grey3",
          backgroundColor: checked ? "$black" : "inherit",
          width: "fit-content",
          cursor: "pointer",
        }}
      >
        <Icon
          variant={icon}
          color={!checked && hovered ? "black" : checked ? "white" : "grey6"}
          size={iconSize}
          css={{
            ml: "$5",
            mr: "$3",
            my: "$3",
          }}
        />
        <Text
          color={!checked && hovered ? "black" : checked ? "white" : "grey6"}
          css={{
            mr: "$4",
          }}
        >
          {content}
        </Text>
      </Pill>
    </Box>
  );
};
