import React from "react";
import { Text, Icon, AvailableIcon } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Link } from "src/ccl/navigation";
import {
  BloomBookingFeePercentage,
  PlatformFeePercentage,
  TransactionFeePercentage,
} from "src/utils/job";

const FeeItem = ({
  iconVariant,
  title,
  body,
}: {
  iconVariant: AvailableIcon;
  title: string;
  body: string;
}) => (
  <Box>
    <Flex css={{ alignItems: "center", gap: "$6" }}>
      <Icon variant={iconVariant} size={24} />
      <Text variant="h4">{title}</Text>
    </Flex>

    <Text css={{ mt: "$3" }}>{body}</Text>
  </Box>
);

interface HowFeesWorkProps {
  onBackClick: () => void;
}

export const HowFeesWork = ({ onBackClick }: HowFeesWorkProps) => (
  <Box>
    <Flex css={{ alignItems: "center", gap: "$6" }}>
      <Link
        to="#"
        onClick={() => onBackClick()}
        data-test-id="ConfirmJobHowFeesWorkBack"
      >
        <Icon variant="chevronLeft" size={24} />
      </Link>
      <Text variant="h2">How our fees work</Text>
    </Flex>

    <Flex css={{ flexDirection: "column", gap: "$8", mb: "$6", mt: "$9" }}>
      <FeeItem
        iconVariant="ecommerce"
        title={`${BloomBookingFeePercentage}% booking fee`}
        body="Covers talent management, job approval, any fee/usage negotiation, assisting with finding replacements if creatives drop out."
      />

      <FeeItem
        iconVariant="rebrandLogo"
        title={`${PlatformFeePercentage}% platform fee`}
        body="Payment for Contact's technology &mdash; ease of finding talent, managing jobs in the dashboard, automated payments, etc."
      />

      <FeeItem
        iconVariant="additionalFee"
        title={`${TransactionFeePercentage}% transaction fee`}
        body="Covers transaction and bank fees for all payments."
      />
    </Flex>
  </Box>
);
