import { gql } from "@apollo/client";

export const REJECT_JOB_MUTATION = gql`
  mutation RejectJob(
    $jobId: ID!
    $rejectionReason: JobRejectionReason!
    $rejectionNote: String
  ) {
    rejectJob(
      jobId: $jobId
      rejectionReason: $rejectionReason
      rejectionNote: $rejectionNote
    ) {
      id
      state
    }
  }
`;
