import React, { useState } from "react";
import { Checkbox, Input } from "src/ccl/data-entry";
import { OptionWrapper } from "src/ccl/filtering";
import { Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { TalentVertical } from "src/graphql/types";

const OTHER_CHECKBOX_LABEL = "Other (please describe)";

interface DeliverablesCheckboxesProps {
  checkboxOptions: string[];
  checkedValues: string[];
  onChange: (newValues: string[]) => void;
  vertical?: TalentVertical;
}

export const DeliverablesCheckboxes = ({
  checkboxOptions,
  checkedValues,
  onChange,
  vertical,
}: DeliverablesCheckboxesProps) => {
  const otherValue = checkedValues.find(
    (initial) => !checkboxOptions.includes(initial),
  );

  const [isOtherVisible, setIsOtherVisible] = useState(
    otherValue !== undefined,
  );

  const [additionalDeliverable, setAdditionalDeliverable] = useState<
    string | undefined
  >(otherValue);
  const otherCheckboxId = vertical
    ? `${vertical}.otherDeliverable`
    : "otherDeliverable";

  return (
    <>
      {checkboxOptions.map((option, index) => (
        <OptionWrapper
          key={index}
          htmlFor={option}
          css={{ alignItems: "center" }}
        >
          <Checkbox
            id={option}
            name={option}
            css={{ width: 24, height: 24 }}
            defaultChecked={checkedValues.includes(option)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(
                e.target.checked
                  ? [...checkedValues, option]
                  : checkedValues.filter((d) => d !== option),
              )
            }
          />
          <Box css={{ cursor: "pointer" }}>
            <Text css={{ ml: "$4" }}>{option}</Text>
          </Box>
        </OptionWrapper>
      ))}

      <OptionWrapper htmlFor={otherCheckboxId} css={{ alignItems: "center" }}>
        <Checkbox
          id={otherCheckboxId}
          name={otherCheckboxId}
          css={{ width: 24, height: 24 }}
          defaultChecked={additionalDeliverable !== undefined}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = e.target;

            if (checked) {
              setIsOtherVisible(true);
            } else {
              setIsOtherVisible(false);
              setAdditionalDeliverable(undefined);
              onChange(checkedValues);
            }
          }}
        />
        <Box css={{ cursor: "pointer" }}>
          <Text css={{ ml: "$4" }}>{OTHER_CHECKBOX_LABEL}</Text>
        </Box>
      </OptionWrapper>

      {isOtherVisible && (
        <Box css={{ pl: "$7", mb: "$8" }}>
          <Text css={{ fontWeight: "bold", mb: "$2" }}>Extra deliverable</Text>
          <Input
            name="deliverables"
            placeholder="e.g. casting"
            value={additionalDeliverable || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;

              setAdditionalDeliverable(value);

              const predefined = checkboxOptions.filter((val) =>
                checkedValues.includes(val),
              );

              onChange([...predefined, value]);
            }}
          />
        </Box>
      )}
    </>
  );
};
