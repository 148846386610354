import React from "react";
import type * as Stitches from "@stitches/react";
import { ComponentProps } from "@stitches/react";
import { Icon, LegacyLogo } from "src/ccl/document";
import { LegacyContainer, Flex, Box } from "src/ccl/layout";
import { SupportLauncher, Link } from "src/ccl/navigation";
import { mergeCss, styled } from "src/ccl/stitches";
import { Text } from "src/ccl/document/text";
import { useStoreModel, useUserKindContext } from "src/hooks";
import { ShortlistHeaderButton } from "src/components";
import { ProfileDropdown } from "src/ccl/blocks/header/ProfileDropdown";

const Wrapper = styled("div", {
  py: "$4",
  px: "$5",
  borderBottom: "2px $black solid",
  zIndex: "$999",

  "@bp2": {
    py: "$6",
  },
});

const StyledContainer = styled(LegacyContainer, {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const LogoLink = styled(Link, {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
});

const StyledTitle = styled(Text, {
  lineHeight: "$none",
  marginLeft: "$3",
  fontWeight: "$bold",
  textTransform: "uppercase",
  fontSize: "$12",

  "@bp2": {
    fontSize: "$20",
    marginLeft: "$4",
  },
});

export interface SimpleHeaderProps extends ComponentProps<typeof Wrapper> {
  title?: string;
  children?: React.ReactNode;
  css: Stitches.CSS;
  containerCSS?: Stitches.CSS;
  onClick?: () => void;
  displaySupportLauncher?: boolean;
  containerVariant?: "noGutter" | "gutter" | "full";
  titleChildren?: React.ReactNode;
}

export const SimpleHeader = ({
  title = "Back to homepage",
  children,
  titleChildren,
  css = {},
  containerCSS = {},
  onClick,
  containerVariant = "gutter",
  displaySupportLauncher = true,
}: SimpleHeaderProps) => {
  const currentUser = useStoreModel("currentUser");
  const { isBooker, loggedIn, avatarInitials, me } = currentUser;
  const showShortlistHeaderButton = isBooker || !loggedIn;
  const userKindContext = useUserKindContext(currentUser);
  const onSignOut = () => {
    currentUser.logOut();
  };

  return (
    <Wrapper css={css}>
      <StyledContainer
        variant={containerVariant}
        css={mergeCss({}, containerCSS)}
      >
        <LogoLink
          to={isBooker ? "/dashboard/client" : "/"}
          data-test-id="HomepageLink"
        >
          <Flex css={{ alignItems: "center", "@bp4": { display: "none" } }}>
            <LegacyLogo variant="mark" />
            {titleChildren ? titleChildren : <StyledTitle>{title}</StyledTitle>}
          </Flex>
          <Flex
            css={{
              alignItems: "center",
              display: "none",
              "@bp4": { display: "block" },
            }}
          >
            <LegacyLogo variant="full" />
          </Flex>
        </LogoLink>
        <Flex
          css={{
            justifyContent: "space-between",
            gap: "$7",
            alignItems: showShortlistHeaderButton ? "center" : undefined,
            "@bp4": {
              gap: showShortlistHeaderButton ? "0px" : undefined,
            },
          }}
        >
          {children}
          {displaySupportLauncher && (
            <SupportLauncher
              css={{
                display: "none",
                "@bp4": { display: "block" },
              }}
              onClick={onClick}
            >
              <Icon
                size={20}
                variant="supportCircle"
                color="black"
                css={{ display: "block", "@bp4": { display: "none" } }}
              />
            </SupportLauncher>
          )}

          {loggedIn && (
            <Box
              css={{
                display: "none",
                "@bp2": { display: "block" },
                "@bp4": {
                  ml: "$7",
                },
              }}
            >
              <ProfileDropdown
                avatarImageUrl={me?.featuredAsset?.mediaUrl}
                avatarInitials={avatarInitials}
                onSignOut={onSignOut}
                userName={me?.name}
                userEmail={me?.email}
                context={userKindContext}
              />
            </Box>
          )}
          {showShortlistHeaderButton && (
            <Box css={{ "@bp4": { ml: "$7" } }}>
              <ShortlistHeaderButton />
            </Box>
          )}
        </Flex>
      </StyledContainer>
    </Wrapper>
  );
};
