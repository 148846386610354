import React from "react";
import { styled } from "src/ccl/stitches";

type SvgProps = React.SVGAttributes<SVGElement>;

const PrimitiveSvg = ({ ...props }: SvgProps) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
);

export const Svg = styled(PrimitiveSvg, {});
