import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { JobItem, JobTableHeader } from ".";
import { EmptyState } from "src/components/dashboards/agents";
import { Box } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { ApiError } from "src/components/errors";
import {
  Query,
  SortOrderInput,
  JobFilterInput,
  Job,
  Assignee,
} from "src/graphql/types";
import { extractNodes } from "src/utils/lang";
import { Loading } from "src/components";
import {
  AGENT_DASHBOARD_JOB_LIST_OWN_CONTEXT_QUERY,
  AGENT_DASHBOARD_JOB_LIST_QUERY,
} from "src/graphql/queries";

const JobsPerPage = 20;

interface TableListProps {
  isOwnContext: boolean;
  assignees: Assignee[];
  filters: JobFilterInput;
  setTotalJobCount: (count: number) => void;
  sortOrder: SortOrderInput;
  setSortOrder: (sortOrderInput: SortOrderInput) => void;
}

export const TableList = ({
  isOwnContext,
  assignees,
  filters,
  setTotalJobCount,
  sortOrder,
  setSortOrder,
}: TableListProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const querySpec = isOwnContext
    ? AGENT_DASHBOARD_JOB_LIST_OWN_CONTEXT_QUERY
    : AGENT_DASHBOARD_JOB_LIST_QUERY;

  const { loading, error, data, fetchMore } = useQuery<Query>(querySpec, {
    variables: {
      filters: filters,
      sortOrder: sortOrder,
      first: JobsPerPage,
    },
    onCompleted: (data) => setTotalJobCount(data?.me?.jobs?.totalCount || 0),
  });

  const jobResult = data?.me?.jobs;
  const jobs = extractNodes<Job>(jobResult);

  if (error) {
    return (
      <>
        <ApiError />
      </>
    );
  }

  return (
    <>
      <JobTableHeader
        sortOrder={sortOrder}
        setSortOrder={(column, sortDirection) =>
          setSortOrder({ column, direction: sortDirection })
        }
      />

      {loading && <Loading />}
      {!loading && jobs.length === 0 && <EmptyState subject="jobs" />}
      {!loading &&
        jobs.map((job, i) => (
          <JobItem job={job} assignees={assignees} key={i} />
        ))}
      {!loading && jobResult?.pageInfo.hasNextPage && (
        <Box css={{ textAlign: "center", my: "$6" }}>
          <Button
            variant="primaryCta"
            disabled={isLoadingMore}
            onClick={async () => {
              setIsLoadingMore(true);
              await fetchMore({
                variables: {
                  first: JobsPerPage,
                  after: jobResult.pageInfo.endCursor || "",
                },
              });
              setIsLoadingMore(false);
            }}
          >
            {isLoadingMore ? "Loading..." : "Load more"}
          </Button>
        </Box>
      )}
    </>
  );
};
