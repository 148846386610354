import { formatDistanceToNow } from "date-fns";
import { JobAssigneeGrid } from "src/components/jobAssignees";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { Link } from "src/ccl/navigation";
import { Avatar } from "src/ccl/blocks";
import { formatDate } from "src/utils/dates";
import { niceStateText, getInitials, truncate } from "src/utils/lang";
import { FloatingPopover } from "src/ccl/feedback";
import { Assignee, Job } from "src/graphql/types";
import { JobStateBackground, JobStateColors } from "src/graphql/colors";
import { tokens } from "src/ccl/stitches/theme";
import { JobTypeIconMap } from "src/utils/job";
import { useStoreModel, useUserKindContext } from "src/hooks";

interface JobItemProps {
  job: Job;
  assignees: Assignee[];
}

const VirtualJob = () => (
  <>
    <Icon variant="virtual" size={20} color="grey6" css={{ mr: "$4" }} />
    <Text variant="mini" css={{ color: "$grey6" }}>
      Virtual job
    </Text>
  </>
);

export const JobItem = ({ job, assignees }: JobItemProps) => {
  const currentUser = useStoreModel("currentUser");
  const context = useUserKindContext(currentUser);
  const userRole = currentUser?.isAgent ? "agent" : context;

  const backgroundColor = JobStateBackground[
    job.state
  ] as keyof typeof tokens.colors;

  const latestNote = job.notes
    ?.slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )[0];

  const displayDate = () => {
    if (job.startDate === job.endDate) {
      return formatDate(job.startDate, "dd LLL yyyy").toString();
    } else {
      return `${formatDate(job.startDate, "dd LLL yyyy")} - \n${formatDate(
        job.endDate,
        "dd LLL yyyy",
      )}`;
    }
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        borderBottom: "1px solid $grey3",
      }}
    >
      {job.virtual && (
        <Flex
          css={{
            pt: "$5",
            alignItems: "center",
          }}
        >
          <VirtualJob />
        </Flex>
      )}

      <Grid
        css={{
          gridColumns: 2,
          gridGap: "$4",
          py: "$6",
          "@lg": {
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "row",
            gridGap: 0,
          },
        }}
      >
        <Flex
          css={{
            width: "initial",
            justifyContent: "space-between",
            "@lg": { width: "30%" },
          }}
        >
          <Box>
            <Flex
              css={{
                flexDirection: "column",
                gap: "$2",
                "@lg": { gap: 0, flexDirection: "row" },
              }}
            >
              <Box css={{ mr: "$4" }}>
                {job.jobVerticals.length > 1 ? (
                  <Icon variant="excessiveShortlisting" size={20} />
                ) : (
                  <Icon
                    variant={JobTypeIconMap[job.jobVerticals[0].vertical]}
                    size={20}
                  />
                )}
              </Box>

              <Box>
                <Link
                  to={`/dashboard/${userRole}/jobs/${job.slug}`}
                  css={{
                    color: JobStateColors[
                      job.state
                    ] as keyof typeof tokens.colors,
                    "@lg": { color: "$black" },
                    display: "inline-block",
                    pr: "$3",
                  }}
                >
                  <Text variant="b2">{job.name}</Text>
                </Link>
                <Text variant="b3" color="grey6" css={{ pt: "$2" }}>
                  {job.brand}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>

        <Flex
          css={{
            gridRow: 2,
            gridColumn: 2,
            justifyContent: "end",
            "@lg": { width: "10%", justifyContent: "start" },
          }}
        >
          <FloatingPopover
            direction="down"
            alignment={{ "@initial": "left", "@bp2": "right" }}
            wrapperCss={{
              transform: "translate(-10px, calc(100% + 6px))",
              "@lg": {
                transform: "translate(0, calc(100% + 6px))",
              },
            }}
            caretCss={{
              right: 0,
              "@lg": {
                left: "50%",
              },
            }}
            launcher={
              job.assignedAgent ? (
                <Avatar
                  imageUrl={job.assignedAgent.featuredAsset?.mediaUrl}
                  initials={getInitials(job.assignedAgent.name)}
                />
              ) : (
                <Flex
                  css={{
                    backgroundColor: "$grey2",
                    borderRadius: "$round",
                    width: "$avatar",
                    height: "$avatar",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                >
                  <Icon variant="plus" color="black" size={14} />
                </Flex>
              )
            }
          >
            <JobAssigneeGrid job={job} assignees={assignees} />
          </FloatingPopover>
        </Flex>

        <Flex
          css={{
            gridRow: 1,
            gridColumn: 2,
            justifyContent: "end",
            "@lg": { width: "10%", justifyContent: "start" },
          }}
        >
          <Text
            variant="b3Bold"
            color={JobStateColors[job.state] as keyof typeof tokens.colors}
            css={{
              width: "fit-content",
              height: "fit-content",
              background: `$${backgroundColor}` as keyof typeof tokens.colors,
              padding: "$3 $4",
              borderRadius: "$pill",
            }}
          >
            {niceStateText(job.state)}
          </Text>
        </Flex>

        <Box
          css={{
            display: "none",
            "@lg": { display: "block", width: "20%" },
          }}
        >
          <Text variant="b2">{job.booker.name}</Text>
        </Box>

        <Flex
          css={{
            width: "initial",
            mt: "$4",
            mr: "$8",
            flexDirection: "row",
            alignItems: "center",
            "@lg": { mr: 0, mt: 0, width: "15%" },
          }}
        >
          <Text variant="b2">
            <Text variant="b2" as="span" css={{ "@lg": { display: "none" } }}>
              Job date:{" "}
            </Text>{" "}
            {displayDate()}
          </Text>
        </Flex>

        <Flex
          css={{
            width: "initial",
            mt: "$4",
            flexDirection: "row",
            alignItems: "center",
            "@lg": { mt: 0, width: "15%" },
          }}
        >
          <Text variant="b2" css={{ whiteSpace: "pre-wrap" }}>
            <Text variant="b2" as="span" css={{ "@lg": { display: "none" } }}>
              Posted date:{" "}
            </Text>{" "}
            {formatDate(job.createdAt, "dd LLL yyyy")}
          </Text>
        </Flex>
      </Grid>

      {latestNote !== undefined && (
        <Flex
          css={{
            flexDirection: "row",
            borderTop: "1px solid $grey3",
            ml: 0,
            pt: "$3",
            pb: "$8",
            "@lg": { ml: "32px" },
          }}
        >
          <Box
            css={{
              textDecoration: "none",
              position: "relative",
              display: "block",
              mr: "$3",
            }}
          >
            <Text
              css={{
                position: "absolute",
                fontSize: "$12",
                fontWeight: "$bold",
                top: 5,
                left: -4,
                textShadow: "2px -1px 0 white",
                "@lg": {
                  bottom: -4,
                },
              }}
            >
              {job.notes?.length}
            </Text>
            <Icon variant="note" size={16} color="grey6" />
          </Box>

          <Text variant="meta">
            {formatDistanceToNow(new Date(latestNote.createdAt))} ago,{" "}
            {latestNote.user.name}:{" "}
          </Text>
          <Text color="grey6" variant="meta" css={{ pl: "2px" }}>
            {truncate(latestNote.body, 60)}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
