import type * as Stitches from "@stitches/react";
import React, { useState } from "react";
import { FullSelectionModal } from "./FullSelectionModal";
import { GoBack, ActionItem, ShareShortlistModal } from "src/components";
import { Icon, Panel, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Pill } from "src/ccl/blocks";
import { User } from "src/graphql/types";
import { Button } from "src/ccl/navigation";
import { mergeCss } from "src/ccl";
import { pluralize } from "src/utils/lang";

interface MobileTalentSelectionSummaryProps {
  talent: User[];
  goBackTo?: string | (() => void | Promise<void>);
  onBack?: () => void;
  openShortlistModal?: () => void;
  openFilterModal?: () => void;
  numberOfActiveFilters?: number;
  displayFilters?: Boolean;
  onRemove?: (user: User) => void;
  onProfile?: Boolean;
  isRemovable?: (user: User) => boolean;
  css?: Stitches.CSS;
  onContinue?: () => void;
}

export const MobileTalentSelectionSummary = ({
  talent,
  onRemove,
  isRemovable,
  goBackTo,
  onBack,
  openShortlistModal,
  openFilterModal,
  numberOfActiveFilters,
  displayFilters,
  onProfile,
  onContinue,
  css = {},
}: MobileTalentSelectionSummaryProps) => {
  const [fullSelectionModalIsOpen, setFullSelectionModalIsOpen] =
    useState(false);
  const [showShareShortlistModal, setShowShareShortlistModal] = useState(false);

  const switchModals = () => {
    setFullSelectionModalIsOpen(false);
    setShowShareShortlistModal(true);
  };

  return (
    <Panel
      css={mergeCss(
        {
          alignItems: "center",
          height: "68px",
          "@bp4": { display: "none" },
          mb: "$7",
        },
        css,
      )}
    >
      <Flex
        data-test-id="MobileTalentSelectionSummary"
        css={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ShareShortlistModal
          talent={talent}
          isOpen={showShareShortlistModal}
          onClose={() => setShowShareShortlistModal(false)}
        />

        <FullSelectionModal
          isOpen={fullSelectionModalIsOpen}
          isRemovable={isRemovable}
          onClose={() => setFullSelectionModalIsOpen(false)}
          talent={talent}
          onRemove={onRemove}
          buttonVariant="secondary"
          showShareShortlistButton
          flowContext="createJob"
          setShowShareShortlistModal={switchModals}
        />
        {goBackTo && (
          <GoBack goBackTo={goBackTo}>
            <ActionItem
              icon="chevronLeft"
              text="Back"
              onClick={onBack}
              css={{ textDecoration: "none" }}
            />
          </GoBack>
        )}
        {displayFilters ? (
          <>
            {!onProfile && (
              <Pill
                variant="menuPill"
                css={{
                  height: "30px",
                  backgroundColor:
                    numberOfActiveFilters && numberOfActiveFilters > 0
                      ? "$black"
                      : "$grey2",
                  zIndex: "9",
                  margin: "$0 $0 $0 $3",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={openFilterModal}
              >
                <Flex
                  css={{
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Icon
                    variant="filter"
                    size={14}
                    css={{
                      mr: "$2",
                    }}
                    color={
                      numberOfActiveFilters && numberOfActiveFilters
                        ? "white"
                        : "black"
                    }
                  />
                  <Text
                    variant="meta"
                    css={{
                      mr: "$3",
                      color:
                        numberOfActiveFilters && numberOfActiveFilters > 0
                          ? "$white"
                          : "$black",
                    }}
                    weight="bold"
                  >
                    {numberOfActiveFilters}
                  </Text>
                  <Text
                    variant="meta"
                    css={{
                      color:
                        numberOfActiveFilters && numberOfActiveFilters > 0
                          ? "$white"
                          : "$black",
                    }}
                  >
                    Filters
                  </Text>
                </Flex>
              </Pill>
            )}
            <Pill
              variant="menuPill"
              css={{
                height: "30px",
                backgroundColor: talent.length > 0 ? "$black" : "$grey2",
                zIndex: "9",
                margin: "$0 $0 $0 $3",
                borderWidth: 1,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={openShortlistModal}
            >
              <Flex css={{ alignItems: "center", width: "100%" }}>
                <Icon
                  variant="user"
                  size={14}
                  color={talent.length > 0 ? "white" : "black"}
                  css={{ mr: "$2" }}
                />
                <Text
                  variant="meta"
                  css={{
                    mr: "$3",
                    color: talent.length > 0 ? "$white" : "$black",
                  }}
                  weight="bold"
                >
                  {talent.length}
                </Text>
                <Text
                  variant="meta"
                  css={{ color: talent.length > 0 ? "$white" : "$black" }}
                >
                  Shortlisted
                </Text>
              </Flex>
            </Pill>
            {onContinue && (
              <Flex
                data-test-id="MobileTalentSelectionSummaryContinue"
                onClick={onContinue}
                css={{
                  alignItems: "center",
                  cursor: "pointer",
                  ml: "$3",
                  "@bp2": {
                    ml: "0px",
                  },
                }}
              >
                <Text
                  variant="meta"
                  css={{
                    display: "none",
                    "@bp1": { display: "block", mx: "$2" },
                  }}
                >
                  Continue
                </Text>
                <Icon variant="chevronRight" size={16} />
              </Flex>
            )}
          </>
        ) : (
          <>
            <Box>
              <Flex css={{ alignItems: "center", width: "100%" }}>
                <Icon
                  variant="user"
                  size={14}
                  color="black"
                  css={{ mr: "$2" }}
                />
                <Text
                  variant="meta"
                  css={{
                    mr: "$3",
                    color: "$black",
                  }}
                  weight="bold"
                >
                  {pluralize(
                    talent.length,
                    "shortlisted creative",
                    "shortlisted creatives",
                  )}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Button
                variant="link"
                css={{ ml: "$3", fontSize: "$14" }}
                onClick={() => {
                  setFullSelectionModalIsOpen(true);
                }}
              >
                View
              </Button>
            </Box>
          </>
        )}
      </Flex>
    </Panel>
  );
};
