import { gql } from "@apollo/client";

export const CREATE_AGENCY_TALENT_INVITATION = gql`
  mutation CreateAgencyTalentInvitation(
    $email: String!
    $vertical: TalentVertical!
    $published: Boolean
  ) {
    createAgencyTalentInvitation(
      email: $email
      vertical: $vertical
      published: $published
    ) {
      id
    }
  }
`;
