import React from "react";
import type * as Stitches from "@stitches/react";
import {
  Link as BaseInternalLink,
  LinkProps as BaseInternalLinkProps,
  NavLink as BaseInternalNavLink,
  NavLinkProps,
} from "react-router-dom";
import { mergeCss, styled } from "src/ccl/stitches/config";
import { buttonStyles } from "src/ccl/navigation/button";
import {
  trackEvent,
  AnalyticsValues,
  sendToAmplitude,
} from "src/utils/analytics";

export type BaseExternalLinkProps = BaseInternalLinkProps<{}> & {
  eventName?: string;
  amplitudeEvent?: string;
  eventValues?: Partial<AnalyticsValues>;
};

/*
  @todo: type linkStyles and linkButtonStyles
  See https://github.com/modulz/stitches/issues/304
*/
const linkStyles = {
  color: "$black",
  "&:hover": {
    color: "$grey6",
  },
};

const linkButtonStyles = {
  ...buttonStyles,
  display: "inline-block",
  textDecoration: "none",
};

const NavLink = styled("a", {
  px: "$5",
  py: "$3",
  display: "block",
  textDecoration: "none",
  "&:hover": {
    color: "$grey6",
  },
  "@bp2": {
    py: "$5",
  },
});

const BaseExternalLink = ({
  amplitudeEvent,
  eventName,
  eventValues,
  to,
  children,
  ...props
}: BaseExternalLinkProps) => (
  <a
    href={to}
    onClick={() =>
      (amplitudeEvent && sendToAmplitude(amplitudeEvent)) ||
      (eventName && trackEvent(eventName, eventValues))
    }
    {...props}
  >
    {children}
  </a>
);

interface StyledNavLinkProps extends NavLinkProps {
  css?: Stitches.CSS;
}

export const StyledNavLink = ({
  to,
  onClick,
  activeStyle,
  children,
  css = {},
}: StyledNavLinkProps) => (
  <NavLink
    css={mergeCss({ backgroundColor: "$grey1", color: "$black" }, css)}
    as={BaseInternalNavLink}
    to={to}
    onClick={onClick}
    activeStyle={activeStyle}
  >
    {children}
  </NavLink>
);

export const ExternalLink = styled(BaseExternalLink, linkStyles);
export const ExternalLinkButton = styled(BaseExternalLink, linkButtonStyles);

export const Link = styled(BaseInternalLink, linkStyles);
export const LinkButton = styled(BaseInternalLink, linkButtonStyles);

export type LinkProps = Stitches.ComponentProps<typeof Link>;
export type LinkButtonProps = Stitches.ComponentProps<typeof LinkButton>;
export type ExternalLinkProps = Stitches.ComponentProps<typeof ExternalLink>;
export type ExternalLinkButtonProps = Stitches.ComponentProps<
  typeof ExternalLinkButton
>;
