import { gql } from "@apollo/client";

export const TALENT_DETAILS_FRAGMENT = gql`
  fragment TalentDetails on JobTalent {
    id
    name
    state
    nudgedAt
    notifiedAt
  }
`;
