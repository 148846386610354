import { gql } from "@apollo/client";

export const PACKAGE_DETAILS_FRAGMENT = gql`
  fragment PackageDetails on Package {
    id
    slug
    kind
    name
    location
    brand
    description
    addressLine1
    addressLine2
    city
    county
    country
    postcode
    usage
    startDate
    endDate
    state
    virtual
    jobSlug
    jobState
    mayConvertToJob
    isTemplate
    clonedFrom {
      id
      slug
      name
    }
    hasClones
    deckFiles {
      id
      filename
      url
    }
    creator {
      agency {
        id
      }
    }
    agency {
      id
      name
    }
    booker {
      id
      name
      email
    }
    bookerName
    bookerEmail
    bookerPhoneNumber
    bookerCompany
    packageVerticals {
      vertical
      budget
      jobLength
      talentRequired
      deliverables
    }
    shortlistCount

    packageTalent {
      id
      state
      talent {
        id
        name
        slug
        city
        country
        featuredAsset {
          mediaUrl
        }
        profile {
          ... on TalentProfile {
            vertical
            lastPortfolioUpload {
              id
              mediaUrl
              width
              height
            }
            primaryLocation {
              name
            }
          }
        }
      }
    }
  }
`;
