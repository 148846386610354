import { ComponentProps, CSS } from "@stitches/react";
import React from "react";
import { mergeCss, styled } from "src/ccl";
import { Box, ContentContainer } from "src/ccl/layout";

const AnimatedBox = styled(Box, {
  transition: "0.3s ease all",
});

interface StickyActionBarProps extends ComponentProps<typeof Box> {
  animated?: boolean;
  visible?: boolean;
  css?: CSS;
}

export const StickyActionBar = ({
  children,
  animated = false,
  visible = true,
  css = {},
}: StickyActionBarProps) => {
  const Container = animated ? AnimatedBox : Box;

  return (
    <Container
      css={mergeCss(
        {
          backgroundColor: "$grey1",
          position: "sticky",
          bottom: 0,
          zIndex: "$999",
          visibility: "visible",
          py: "$5",
          "@bp2": {
            bottom: animated && !visible ? -100 : 0,
            visibility: visible ? "visible" : "hidden",
          },
        },
        css,
      )}
    >
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};
