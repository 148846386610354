import React from "react";
import { Box, ReadMoreBox } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { ParagraphRenderer } from "src/components/ParagraphRenderer";

interface BiographyProps {
  body: string;
  lineCount?: number;
}

export const Biography = ({ body, lineCount }: BiographyProps) => {
  return (
    <Box>
      {body.length < 250 && (
        <Text variant={"meta"} color="black" css={{ mt: "$5" }}>
          <ParagraphRenderer text={body} />
        </Text>
      )}

      {body.length >= 250 && (
        <Box css={{ mt: "$5" }}>
          <ReadMoreBox
            copy={body}
            charLength={347}
            lineCount={lineCount}
            clickOpenCopy="See Full Bio"
          />
        </Box>
      )}
    </Box>
  );
};
