import { gql } from "@apollo/client";

export const POST_COMPLETION_ACTION_MUTATION = gql`
  mutation JobPostCompletionAction(
    $jobId: ID!
    $action: JobPostCompletionAction!
    $completed: Boolean!
  ) {
    jobPostCompletionAction(
      jobId: $jobId
      action: $action
      completed: $completed
    ) {
      id
      paymentReceived
      imagesReceived
    }
  }
`;
