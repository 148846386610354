import React from "react";
import * as Stitches from "@stitches/react";
import { Icon, IconProps } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";

export const CarouselControl = ({
  icon,
  iconColor = "white",
  iconSize = 20,
  onClick,
  showArrows,
  css,
}: {
  icon: "chevronLeft" | "chevronRight";
  iconColor?: "white" | "black" | "grey3";
  iconSize?: IconProps["size"];
  gutter: number;
  onClick: () => void;
  showArrows: boolean;
  css: Stitches.CSS;
}) => (
  <Flex
    css={mergeCss(
      {
        "&:hover": { cursor: "pointer" },
        alignSelf: "center",
        zIndex: 1,
        display: showArrows ? "block" : "none",
        background: "black",
        border: "2px",
        borderStyle: "solid",
        borderColor: "white",
        borderRadius: "100%",
        width: "$11",
        height: "$11",
      },
      css,
    )}
    onClick={onClick}
  >
    <Icon
      size={iconSize}
      variant={icon}
      color={iconColor}
      css={{ m: iconSize !== 20 ? "0px" : "8px" }}
    />
  </Flex>
);
