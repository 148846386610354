import { gql } from "@apollo/client";

export const UPDATE_JOB_CONFIRM_MUTATION = gql`
  mutation UpdateJob(
    $jobId: ID!
    $addressLine1: String
    $addressLine2: String
    $city: String
    $country: String
    $postcode: String
  ) {
    updateJob(
      jobId: $jobId
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      country: $country
      postcode: $postcode
    ) {
      id
      addressLine1
      addressLine2
      city
      postcode
      country
    }
  }
`;
