import { useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { ConfirmModal } from "src/ccl/document";
import { DELETE_SAVED_TALENT_COLLECTION_MUTATION } from "src/graphql/mutations/booker/DeleteSavedTalentCollection";
import {
  Mutation,
  MutationDeleteSavedTalentCollectionArgs,
  SavedTalentCollection,
} from "src/graphql/types";

export const DeleteCollectionModal = ({
  collection,
  isOpen,
  onCancel,
}: {
  collection: SavedTalentCollection;
  isOpen: boolean;
  onCancel: () => void;
}) => {
  const [deleteCollection] = useMutation<
    Mutation,
    MutationDeleteSavedTalentCollectionArgs
  >(DELETE_SAVED_TALENT_COLLECTION_MUTATION);
  const history = useHistory();
  return (
    <ConfirmModal
      title={`Are you sure you want to delete your '${collection.name}' collection?`}
      subtitle={
        "You can't undo this later. Your collection will be permanently deleted."
      }
      isOpen={isOpen}
      confirmButtonTitle={"Delete collection"}
      cancelButtonTitle={"Back"}
      onCancel={onCancel}
      onConfirm={async () => {
        await deleteCollection({
          variables: {
            id: collection.id,
          },
        });
        history.push("/dashboard/client/bookmark-collections");
      }}
      titleVariant="h4"
      titleCss={{
        paddingTop: "$7",
      }}
      parentCss={{ px: "$6" }}
      flexCss={{ pb: "$6" }}
    />
  );
};
