import React from "react";
import { Job, JobTalentState, TalentVertical } from "src/graphql/types";
import { Flex } from "src/ccl/layout";
import { Text, Icon, InfoBanner } from "src/ccl/document";
import {
  jobPendingApproval,
  jobApproved,
  jobHasRespondedTalent,
  jobHasAcceptedTalentCount,
  jobConfirmed,
  jobIsLive,
  jobAwaitingPayment,
} from "src/utils/job";
import { pluralize } from "src/utils/lang";
import { isVerticalMember } from "src/utils/user";

interface ShortlistTitleProps {
  job: Job;
  talentVerticals: TalentVertical[];
}

export const ShortlistTitle = ({
  job,
  talentVerticals,
}: ShortlistTitleProps) => {
  const talentCount: number = job?.talent?.length || 0;

  if (jobPendingApproval(job.state)) {
    return (
      <>
        <Text variant="h3" color="orange" css={{ mb: "$5" }}>
          {pluralize(talentCount, "creative")} shortlisted
        </Text>
        <Text css={{ mb: "$5" }}>
          We&apos;ll notify the creatives on your shortlist as soon as your
          job&apos;s approved.
        </Text>
      </>
    );
  }

  if (jobApproved(job.state) && !jobHasRespondedTalent(job)) {
    return (
      <Flex css={{ alignItems: "center", gap: "$3", mb: "$3" }}>
        <Icon variant="responseTime" size={30} css={{ flexShrink: 0 }} />
        <Text variant="h3">Waiting for creatives to respond</Text>
      </Flex>
    );
  }

  if (jobApproved(job.state) && jobHasAcceptedTalentCount(job)) {
    const notEnoughTalentAccepted = talentVerticals.some((v) => {
      const jobVertical = job.jobVerticals.find((jv) => jv.vertical === v);
      if (!jobVertical || !job.talent) {
        return true;
      }

      const acceptedVerticalJobTalent = job.talent.filter(
        (jt) =>
          isVerticalMember(v, jt.talent) &&
          jt.state === JobTalentState.Accepted,
      );
      return acceptedVerticalJobTalent.length < jobVertical?.talentRequired;
    });

    return (
      <>
        <Text variant="h3" color="green" css={{ mb: "$5" }}>
          Select creatives to confirm job
        </Text>
        {notEnoughTalentAccepted && (
          <Flex
            css={{
              flexDirection: "column",
              mb: "$5",
              "@bp2": {
                mb: "$0",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              },
            }}
          >
            <InfoBanner
              variant="centered"
              icon="responseTime"
              iconSize={20}
              css={{
                mb: "$5",
              }}
            >
              <Text>Or wait for more of your shortlist to respond.</Text>
            </InfoBanner>
          </Flex>
        )}
      </>
    );
  }

  if (jobConfirmed(job.state) || jobAwaitingPayment(job.state)) {
    return (
      <>
        <Flex
          css={{
            flexWrap: "wrap",
            justifyContent: "flex-start",
            "@bp5": { justifyContent: "space-between" },
          }}
        >
          <Text variant="h2" color="green" css={{ width: "100%", pb: "$5" }}>
            Confirmed Creatives
          </Text>
        </Flex>
        <InfoBanner variant="bordered">
          <Text variant="meta">
            If you are booking for a full day, note that you must provide lunch
            or cover lunch expenses for all creatives. Note the model’s dietary
            or allergy requirements — Contact is not liable if you do not adhere
            to these requirements.
          </Text>
        </InfoBanner>
      </>
    );
  }

  if (!jobIsLive(job)) {
    return (
      <Text variant="h3" css={{ mb: "$5", color: "$grey6" }}>
        {pluralize(talentCount, "creative")} shortlisted
      </Text>
    );
  }

  return null;
};
