import React from "react";
import { TalentSelectionHelpLink } from "src/components";

export const CAJHeader = () => (
  <>
    <TalentSelectionHelpLink
      text="Guide"
      iconSize={20}
      linkCss={{ textDecoration: "none" }}
      iconCss={{ display: "none", "@bp4": { display: "block" } }}
      css={{
        display: "none",
        "@bp4": { display: "block", textDecoration: "none" },
      }}
      color="grey6"
      textVariant="mini"
    />
    <TalentSelectionHelpLink
      text="Guide"
      iconSize={20}
      iconCss={{ display: "block", "@bp4": { display: "none" } }}
      css={{
        display: "none",
      }}
      color="black"
      textVariant="mini"
    />
  </>
);
