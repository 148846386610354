import { gql } from "@apollo/client";

export const ASSIGNEE_DETAILS_FRAGMENT = gql`
  fragment AssigneeDetails on Job {
    id
    assignedAgent {
      id
      name
      featuredAsset {
        mediaUrl
      }
    }
  }
`;
