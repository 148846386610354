import { gql } from "@apollo/client";
import { PORTFOLIO_IMAGE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const SORT_PORTFOLIO_IMAGES_MUTATION = gql`
  mutation SortPortfolioImages(
    $portfolioImageCategoryId: ID
    $talentProfileId: ID
    $sortOrder: [UpdateSortOrderInput!]!
  ) {
    sortPortfolioImages(
      portfolioImageCategoryId: $portfolioImageCategoryId
      talentProfileId: $talentProfileId
      sortOrder: $sortOrder
    ) {
      ...PortfolioImageDetails
    }
  }
  ${PORTFOLIO_IMAGE_DETAILS_FRAGMENT}
`;
