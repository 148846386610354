export const buildOptionProps = <T>(
  options: T[],
  onChange: (options: T[]) => void,
  option?: T,
) => ({
  checked: option ? !!options.find((s) => s === option) : options.length === 0,
  onClick: option
    ? () => {
        if (options.find((s) => s === option)) {
          onChange(options.filter((s) => s !== option));
        } else {
          onChange(options.concat(option));
        }
      }
    : () => onChange([]),
});
