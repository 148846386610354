import React from "react";
import { format, formatDistanceToNow } from "date-fns";
import { styled } from "src/ccl";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { ActivityLog } from "src/graphql/types";
import { parseLinks } from "src/utils/linkParser";

interface JobActivityStreamModalProps extends ModalProps {
  activityStream: ActivityLog[];
}

const Item = styled(Box, {
  borderBottomColor: "$grey3",
  borderBottomStyle: "solid",
  borderBottomWidth: 1,
});

export const JobActivityStreamModal = ({
  activityStream,
  ...modalProps
}: JobActivityStreamModalProps) => (
  <Modal {...modalProps} title="Activity log" showCloseButton>
    {activityStream.map((item, i) => {
      return (
        <Item key={i} css={{ py: "$4", spaceY: "$3" }}>
          <Text
            variant="meta"
            color="grey6"
            title={format(new Date(item.timestamp), "dd/MM/yyyy HH:mm:ss")}
          >
            {formatDistanceToNow(new Date(item.timestamp))} ago
          </Text>

          <Text>
            {item.username}{" "}
            {parseLinks(
              item.message,
              window.location.origin,
              "agencyDashboard:activityStream",
            )}
          </Text>
        </Item>
      );
    })}
  </Modal>
);
