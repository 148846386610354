import React from "react";
import DefaultBG from "src/pages/agencyHomepage/assets/agency-default-cover.png";
import { AssetImage, AssetImageType, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";

interface CoverPhotoProps {
  image?: AssetImageType;
  name: string;
  location: string;
}

export const CoverPhoto = ({
  image = undefined,
  name,
  location,
}: CoverPhotoProps) => {
  return (
    <Box
      css={{
        position: "relative",
      }}
    >
      {image ? (
        <>
          <Box
            css={{
              position: "absolute",
              borderRadius: "$xl",
              zIndex: 100,
              height: "-webkit-fill-available",
              bottom: "0%",
              width: "100%",
              backgroundImage:
                "linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%)",
            }}
          />
          <AssetImage
            asset={image}
            size={{
              "@initial": {
                width: 600,
                height: 426,
                fit: "crop",
              },
              "@bp1": {
                width: 1000,
                height: 360,
                fit: "crop",
              },
              "@bp3": {
                width: 2000,
                height: 520,
                fit: "crop",
              },
            }}
            alt={`A cover photo of ${name} the agency`}
            imgCss={{
              borderRadius: "$xl",
            }}
            containerCss={{ borderRadius: "$xl" }}
          />
        </>
      ) : (
        <Box
          css={{
            borderRadius: "$xl",
            backgroundImage: `url(${DefaultBG})`,
            height: "246px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            "@bp1": {
              height: "360px",
              width: "100%",
            },
          }}
        />
      )}
      <Flex
        css={{
          flexDirection: "column",
          position: "absolute",
          bottom: "38%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 200,
        }}
      >
        <Text
          variant={{ "@initial": "nh2", "@bp2": "h0" }}
          css={{ color: "white", textAlign: "center" }}
        >
          {name}
        </Text>
        <Text
          data-test-id="CoverPhotoLocation"
          variant={{ "@initial": "b2", "@bp2": "b1" }}
          css={{ color: "white" }}
        >
          {location}
        </Text>
      </Flex>
    </Box>
  );
};
