import React, { PropsWithChildren } from "react";
import type * as Stitches from "@stitches/react";
import { StepSummaryV2 } from "./StepSummaryV2";
import { mergeCss, styled } from "src/ccl/stitches";
import { Box, Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { CJStep } from "src/pages/createJob/CJSteps";

interface FormLayoutHeaderDesktopV2Props {
  onClick?: () => void;
  ctaText?: string;
  goBackTo?: string | (() => void | Promise<void>);
  onBack?: () => void;
  title: string;
  disabled?: boolean;
  steps?: CJStep[];
  showProgressBar: boolean;
  currentStepIndex?: number;
  buttonType?: "button" | "submit";
  variant?: "caj" | "shortlist" | "shortlistProfile";
  headerCss?: Stitches.CSS;
  stepIndexCss?: Stitches.CSS;
  titleVariant?: string;
  wrapperComponent?: React.FC<PropsWithChildren>;
  titleCSS?: Stitches.CSS;
}

const Wrapper = styled(Box, {
  mb: "$5",
  margin: "0 auto",

  variants: {
    variant: {
      caj: {
        px: "$8",
        "@bp4": {
          px: "$0",
        },
      },
      shortlist: {
        px: "$8",
        "@bp4": {
          px: "$10",
        },
      },
      shortlistProfile: {
        backgroundColor: "$grey1",
      },
    },
  },
});

const Divider = styled("hr", {
  height: "2px",
  border: "none",
  mx: "-350px",
  boxShadow: "$outerShadow",
});

export const FormLayoutHeaderDesktopV2 = ({
  ctaText,
  disabled = false,
  onClick = () => {},
  buttonType = "submit",
  goBackTo,
  title,
  steps,
  showProgressBar,
  currentStepIndex,
  variant,
  headerCss = {},
  stepIndexCss = {},
  titleVariant = "h1",
  wrapperComponent,
  titleCSS = {},
}: FormLayoutHeaderDesktopV2Props) => {
  const Wrap = wrapperComponent || Wrapper;
  return (
    <>
      <Wrap variant={variant} data-test-id="FormLayoutHeader">
        <Flex
          css={mergeCss(
            {
              justifyContent: "space-between",
            },
            headerCss,
          )}
        >
          <Text
            css={mergeCss(
              {
                transition: "all 0.25s ease",
                alignSelf: "center",
              },
              titleCSS,
            )}
            variant={titleVariant}
          >
            {title}
          </Text>
          {ctaText && (
            <Button
              type={buttonType}
              variant="primary"
              disabled={disabled}
              onClick={onClick}
              css={{
                position: showProgressBar ? undefined : "relative",
                mb: showProgressBar ? undefined : "-25px",
                mt: showProgressBar ? undefined : "25px",
              }}
            >
              {ctaText}
            </Button>
          )}
        </Flex>
        <Flex
          css={mergeCss(
            {
              justifyContent: "space-between",
            },
            stepIndexCss,
          )}
        >
          {steps && currentStepIndex !== undefined && (
            <Box
              css={{
                flex: 1,
              }}
            >
              <StepSummaryV2
                expanded={showProgressBar}
                showProgressBar={showProgressBar || false}
                steps={steps}
                currentStep={currentStepIndex}
                goBackTo={goBackTo}
              />
            </Box>
          )}
        </Flex>
      </Wrap>
      {!showProgressBar && <Divider data-test-id="the-divider" />}
    </>
  );
};
