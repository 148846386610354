import React from "react";
import { Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { styled } from "src/ccl";

const Wrapper = styled(Flex, {
  borderColor: "$black",
  borderWidth: 3,
  borderStyle: "solid",
  paddingY: 2,
  paddingX: 3,
  marginBottom: 4,
  alignItems: "center",
});

export const MissingInfoAlert = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <Wrapper>
    <Icon variant="alert" size={16} />

    <Text variant="meta" css={{ ml: "$5" }}>
      {children}
    </Text>
  </Wrapper>
);
