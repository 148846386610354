import React, { useState } from "react";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Flex, Box } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { Checkbox } from "src/ccl/data-entry";

interface Option {
  value: string;
  label: string;
  description?: string;
}

export interface OptionsModalProps extends Omit<ModalProps, "showCloseButton"> {
  confirmButtonTitle: string;
  confirmButtonLoadingTitle?: string;
  cancelButtonTitle?: string;
  onCancel: () => void;
  onConfirm: (value: string) => void;
  isDisabled?: boolean;
  options: Option[];
  subTitle?: string | React.ReactNode;
}

export const OptionsModal = ({
  isOpen,
  title,
  subTitle,
  width,
  cancelButtonTitle = "Cancel",
  confirmButtonTitle,
  confirmButtonLoadingTitle,
  onCancel,
  onConfirm,
  options,
  isDisabled,
  children,
}: OptionsModalProps) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  const submittable: boolean = selectedOption === "";
  const loadingText: string = confirmButtonLoadingTitle || "...";

  const checkboxOptions = options.map((option) => (
    <Flex key={option.value} css={{ mb: "$4", alignItems: "center" }}>
      <Checkbox
        checked={selectedOption === option.value}
        onChange={() => setSelectedOption(option.value)}
        id={option.value}
      />

      <Box as="label" htmlFor={option.value} css={{ cursor: "pointer" }}>
        <Text css={{ ml: "$4" }}>{option.label}</Text>
        {option.description && (
          <Text variant="meta" color="grey6" css={{ ml: "$4" }}>
            {option.description}
          </Text>
        )}
      </Box>
    </Flex>
  ));

  return (
    <Modal isOpen={isOpen} title={title} width={width} onClose={onCancel}>
      <Box>{subTitle}</Box>
      <Box css={{ mt: "$6" }}>{checkboxOptions}</Box>

      {children}

      <Flex
        css={{
          flexDirection: "column-reverse",
          mt: "$9",
          justifyContent: "space-between",
          "@bp2": { flexDirection: "row" },
        }}
      >
        <Button
          variant="secondary"
          onClick={onCancel}
          css={{ width: "100%", "@bp2": { width: "initial" } }}
        >
          {cancelButtonTitle}
        </Button>

        <Button
          variant="primary"
          onClick={() => onConfirm(selectedOption)}
          disabled={isDisabled || submittable}
          css={{
            width: "100%",
            mb: "$5",
            "@bp2": { width: "initial", mb: 0 },
          }}
        >
          {isDisabled ? loadingText : confirmButtonTitle}
        </Button>
      </Flex>
    </Modal>
  );
};
