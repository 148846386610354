const figmaTokens = {
  0: "0",
  1: "2px",
  2: "4px",
  3: "8px",
  4: "12px",
  5: "16px",
  6: "20px",
  7: "24px",
  8: "28px",
  9: "32px",
  10: "36px",
  11: "40px",
  12: "44px",
  13: "48px",
  14: "52px",
  15: "56px",
  16: "60px",
  17: "80px",
  18: "100px",
  19: "140px",
  20: "200px",
};

export const tokens = {
  colors: {
    black: "#131313",
    white: "#fff",
    grey7: "#D9D9D9",
    grey6: "#6c6c6c",
    grey5: "#525252",
    grey4: "#888888",
    grey3: "#cfcfcf",
    grey2: "#e5e5e5",
    grey1: "#f3f3f3",
    green: "#17C53D",
    red: "#D32014",
    peach: "#EA627B",
    orange: "#F56600",
    purple: "#E64BFF",
    turquoise: "#B0FFEC",
    turquoiseDark: "#228390",
    turquoisePale: "#E2FFF8",
    turquoiseLight: "#E7FFF9",
    podcast: "#FF7E33",
    backgroundGreen: "rgba(23, 197, 61, 0.05)",
    backgroundRed: "rgba(211, 32, 20, 0.05)",
    gold: "#FFBC3B",
    darkGreen: "#03811F",
    lightOrange: "#FEE8D9",
    lightGreen: "#DCF6E2",
    lightPurple: "#FBE4FF",
    positive: "#12882C",
    rejected: "#D90E00",
    cancelled: "#C30046",
    pending: "#F56600",
    approved: "#A80EC1",
    hold: "#1B7C89",
    bookmark: "#E0A41D",
    positiveLight: "#EBFFEF",
    rejectedLight: "#FBE7E5",
    cancelledLight: "#FBE0E5",
    pendingLight: "#FEF0E5",
    approvedLight: "#FDF3FF",
    holdLight: "#E7FFF9",
  },
  fonts: {
    sans: "Shapiro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    sansWide:
      "Shapiro Wide, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    monospace: "Menlo, monospace",
    marker: "permanent_markerregular",
    serif: "Ogg, Georgia, Times New Roman, serif",
    sansNew: "LabilGrotesk, Roboto, Helvetica, Arial, sans-serif",
  },
  fontSizes: {
    9: "9px",
    12: "12px",
    14: "14px",
    16: "16px",
    18: "18px",
    20: "20px",
    22: "22px",
    24: "24px",
    30: "30px",
    34: "34px",
    40: "40px",
    48: "48px",
    52: "52px",
    64: "64px",
  },
  fontWeights: {
    regular: "300",
    medium: "500",
    semiBold: "600",
    bold: "700",
  },
  lineHeights: {
    tight: "1",
    normal: "1.2",
    relaxed: "1.4",
    loose: "1.6",
    18: "18px",
    22: "22px",
    24: "24px",
    26: "26px",
    30: "30px",
    32: "32px",
    46: "46px",
    48: "48px",
    52: "52px",
    70: "70px",
  },
  letterSpacings: {
    normal: "0",
    wide: "0.02em",
  },
  radii: {
    sm: "3px",
    md: "5px",
    lg: "10px",
    xl: "20px",
    round: "50%",
    pill: "9999px",
    0: "4px",
    1: "6px",
    2: "10px",
    3: "14px",
    4: "20px",
    12: "12px",
  },
  space: {
    ...figmaTokens,
  },
  sizes: {
    maxWidth: "1500px",
    full: "100%",
    avatar: "32px",
    largeAvatar: "48px",
    megaAvatar: "70px",
    ...figmaTokens,
  },
  zIndices: {
    100: 100,
    200: 200,
    300: 300,
    400: 400,
    500: 500,
    501: 501,
    502: 502,
    999: 999,
  },
  shadows: {
    focus: "0px 3px 5px rgba(0, 0, 0, 0.15)",
    reverseFloating: "0px -3px 5px rgba(0, 0, 0, 0.15)",
    dropWhite: "0px 6px 0px #fff",
    dropBlack: "0px 10px 40px rgba(0, 0, 0, 0.2)",
    innerDrop: "inset 0 5px 8px rgba(0, 0, 0, 0.3)",
    subtle: "0px 4px 20px rgba(0, 0, 0, 0.08)",
    innerShadow: "inset 0px -1px 0px #CFCFCF",
    outerShadow: "0px 2px 0px #000000, 0px 3px 5px rgba(0, 0, 0, 0.15)",
  },
};

export const bookerJobStates = {
  black: "#000",
  white: "#fff",
  grey2: "#e5e5e5",
  green: "#03811F",
  greenPale: "#DCF6E2",
  red: "#D32014",
  redPale: "#F8DEDC",
  peach: "#D22A49",
  peachPale: "#FCE8EB",
  orange: "#BB4E00",
  orangePale: "#FEE8D9",
  purple: "#BC00DA",
  purplePale: "#FBE4FF",
};

export const jobStates = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  pending_approval: tokens.colors.orange,
  approved: tokens.colors.purple,
  awaiting_payment: tokens.colors.turquoiseDark,
  finalized: tokens.colors.green,
  completed: tokens.colors.black,
  expired: tokens.colors.grey6,
  cancelled: tokens.colors.peach,
  rejected: tokens.colors.red,
  PENDING_APPROVAL: tokens.colors.orange,
  APPROVED: tokens.colors.purple,
  AWAITING_PAYMENT: tokens.colors.turquoiseDark,
  FINALIZED: tokens.colors.green,
  COMPLETED: tokens.colors.black,
  EXPIRED: tokens.colors.grey6,
  CANCELLED: tokens.colors.peach,
  REJECTED: tokens.colors.red,
};

export const jobTalentStates = {
  accepted: "#17C53D",
  awaiting_payment: "#17C53D",
  rejected: "#D32014",
  pending: "#888",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  rejected_by_booker: "#D32014",
  confirmed: "#17C53D",
  paid: "#17C53D",
  disputed: "#D32014",
  ACCEPTED: "#17C53D",
  AWAITING_CONFIRMATION: "#17C53D",
  CONFIRMED: "#17C53D",
  DISPUTED: "#D32014",
  PAID: "#17C53D",
  PENDING: "#888",
  REJECTED: "#D32014",
  REJECTED_BY_BOOKER: "#D32014",
  PENDING_AGENCY_APPROVAL: "#000000",
};

export const iconSizes = {
  6: "6px",
  8: "8px",
  10: "10px",
  12: "12px",
  14: "14px",
  16: "16px",
  18: "18px",
  20: "20px",
  24: "24px",
  28: "28px",
  30: "30px",
  32: "32px",
  40: "40px",
  60: "60px",
  80: "80px",
  100: "100px",
  120: "120px",
  150: "150px",
  170: "170px",
  195: "195px",
  215: "215px",
};
