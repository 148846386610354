import { AssetImageSize } from "./types";
import { ResponsiveValue } from "src/ccl/stitches";

export const isSingleSizeValue = (
  size: ResponsiveValue<AssetImageSize>,
): size is AssetImageSize => {
  const untypedSize: any = size; // eslint-disable-line @typescript-eslint/no-explicit-any

  return (
    typeof untypedSize.width !== "undefined" ||
    typeof untypedSize.height !== "undefined" ||
    typeof untypedSize.aspectRatio !== "undefined" ||
    typeof untypedSize.fit !== "undefined"
  );
};
