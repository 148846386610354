import { gql } from "@apollo/client";

export const BOOKER_QUERY = gql`
  query Booker(
    $id: ID!
    $first: Int!
    $after: String
    $sortOrder: SortOrderInput
  ) {
    booker(id: $id) {
      id
      name
      firstName
      lastName
      email
      phoneNumber
      createdAt
      lastActionAt
      featuredAsset {
        mediaUrl
      }
      jobs(first: $first, after: $after, sortOrder: $sortOrder)
        @connection(key: "jobs", filter: ["sortOrder"]) {
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            name
            slug
            startDate
            endDate
            spansMultipleDays
            brand
            state
            jobVerticals {
              vertical
            }
          }
        }
      }
      invoices {
        id
        state
        invoiceNumber
        paymentMethod
        paymentReference
        invoiceDate
        dueDate
        total
        trustshareProjectId
        xeroReference
        pdfUrl
        job {
          name
          slug
        }
      }
      notes {
        id
        body
        createdAt
        user {
          id
          name
        }
      }
      profile {
        ... on BookerProfile {
          autoApprovalDisabled
          website
          company
          kind
          role
          invoiceBillingName
          invoiceBillingAddress
          bookingCompany {
            waiveInvoiceFee
          }
          paymentDetails {
            accountNumber
            sortCode
            iban
            swift
          }
        }
      }
    }
  }
`;
