import React from "react";
import type * as Stitches from "@stitches/react";
import { Icon, Text } from "src/ccl/document";
import { ExternalLink } from "src/ccl/navigation";
import { mergeCss, styled } from "src/ccl/stitches";
import { iconSizes, tokens } from "src/ccl/stitches/theme";
import { TextVariants } from "src/ccl/document/text";
import { zendeskAvailable, zendeskOpen } from "src/utils/zendesk";
import { sendToAmplitude } from "src/utils/analytics";
import { useLocationAwareURL } from "src/hooks/useLocationAwareURL";
import { useStoreModel } from "src/hooks";

const StyledExternalLink = styled(ExternalLink, {
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
});

export interface SupportLauncherProps {
  color?: keyof typeof tokens.colors;
  textVariant?: keyof typeof TextVariants;
  longContent?: string;
  shortContent?: string;
  longContentCSS?: Stitches.CSS;
  wrapperCSS?: Stitches.CSS;
  css?: Stitches.CSS;
  iconCss?: Stitches.CSS;
  iconSize?: keyof typeof iconSizes;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const SupportLauncher = ({
  color = "grey6",
  textVariant = "mini",
  longContent,
  shortContent = "Help",
  longContentCSS = {},
  css = {},
  iconCss = {},
  wrapperCSS = {},
  iconSize = 20,
  children,
  onClick,
}: SupportLauncherProps) => {
  const supportUrl = useLocationAwareURL("https://support.{{HOST}}/hc/en-gb");
  const { featureEnabled } = useStoreModel("features");
  return (
    <StyledExternalLink
      to={supportUrl}
      target="_blank"
      onClick={(event) => {
        if (!featureEnabled("zendesk_enabled") || !zendeskAvailable()) {
          return;
        }
        event.preventDefault();
        zendeskOpen();
        sendToAmplitude("top nav - clicks help");
        onClick?.();
      }}
      eventName="header:help"
      css={wrapperCSS}
    >
      <Icon
        size={iconSize}
        variant="supportCircle"
        color={color}
        css={mergeCss(
          { display: "none", "@bp4": { display: "block" } },
          iconCss,
        )}
      />
      {children}
      {longContent && (
        <Text
          variant={textVariant}
          color={color}
          css={mergeCss(
            {
              display: "none",
              marginLeft: "$2",
              "@bp3": { display: "block" },
            },
            longContentCSS,
          )}
        >
          {longContent}
        </Text>
      )}
      <Text
        variant={textVariant}
        color={color}
        css={mergeCss(
          {
            marginLeft: "$2",
          },
          css,
        )}
      >
        {shortContent}
      </Text>
    </StyledExternalLink>
  );
};
