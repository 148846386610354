import { gql } from "@apollo/client";
import {
  BOOKING_COMPANY_DETAILS_FRAGMENT,
  TALENT_PROFILE_DETAILS_FRAGMENT,
} from "src/graphql/fragments";

export const ME_QUERY = gql`
  query Me {
    me {
      id
      slug
      availableFeatures
      name
      firstName
      lastName
      email
      phoneNumber
      kinds
      addressLine1
      addressLine2
      city
      country
      postcode
      registeringAs
      previousBrands
      previousLocations
      featuredAsset {
        mediaUrl
      }
      agency {
        id
        name
        firstParty
        commissionPercentage
      }
      profile {
        ... on AgentProfile {
          id
          role
        }
        ... on BookerProfile {
          bookingCompany {
            ...BookingCompanyDetails
          }
        }
        ... on TalentProfile {
          ...TalentProfileDetails
        }
      }
    }
  }
  ${TALENT_PROFILE_DETAILS_FRAGMENT}
  ${BOOKING_COMPANY_DETAILS_FRAGMENT}
`;
