import React, { ReactNode } from "react";
import { Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { ExternalLink, Link } from "src/ccl/navigation";
import { styled } from "src/ccl/stitches";
import { useStoreModel } from "src/hooks";

const Backdrop = styled("div", {
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: "$501",
  transition: "400ms ease all",
  visibility: "hidden",

  variants: {
    state: {
      open: {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
});

const Wrapper = styled("div", {
  position: "fixed",
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "$white",
  zIndex: "$502",
  minWidth: "312px",
  width: "80vw",
  boxSizing: "border-box",
  transition: "400ms ease all",
  transform: "translate(calc(100% + 40px), 0)",
  visibility: "hidden",
  p: "$4",
  overflowY: "scroll",
  height: "100%",

  variants: {
    state: {
      open: {
        transform: "translate(0, 0)",
        visibility: "visible",
      },
    },
  },
});

const CloseLink = styled("button", {
  color: "$black",
  backgroundColor: "$white",
  border: "none",
  cursor: "pointer",
  px: "0",
});

const primaryLinkStyles = {
  variant: "b2",
  textDecoration: "none",
  display: "block",
  marginBottom: "$6",
  "&:hover": {
    color: "$grey4",
  },

  "&:last-child": {
    mb: 0,
  },
};

const PrimaryExternalLink = styled(ExternalLink, primaryLinkStyles);

interface BurgerPanelProps {
  open: boolean;
  onClose: () => void;
  onSignOut?: () => void;
  burgerBar: ReactNode;
}

export const BurgerPanel = ({
  open = false,
  onClose = () => {},
  onSignOut,
  burgerBar,
}: BurgerPanelProps) => {
  const currentUser = useStoreModel("currentUser");

  return (
    <Box>
      <Backdrop state={open ? "open" : undefined} onClick={onClose} />
      <Wrapper state={open ? "open" : undefined} data-test-id="MobileNavPane">
        <Flex css={{ justifyContent: "space-between", pl: "$3" }}>
          <Text variant="nh4" css={{ pt: "$3" }}>
            Menu
          </Text>
          <CloseLink onClick={onClose} data-test-id="CloseBurgerNav">
            <Icon variant="cross" color="black" size={16} />
          </CloseLink>
        </Flex>
        <Box css={{ mt: "$10" }}>
          {burgerBar}
          <Box
            css={{
              pt: "$5",
              pb: "$9",
              pl: "$3",
              pr: "$5",
              width: "-webkit-fill-available",
            }}
          >
            <Text variant="b2Bold" css={{ mb: "$3" }}>
              {currentUser?.me?.name}
            </Text>
            <Text variant="b3" css={{ mb: "$3" }}>
              {currentUser.isAgent
                ? currentUser?.me?.agency?.name
                : currentUser?.me?.bookerProfile?.company}
            </Text>
            <Text variant="b3" color="grey5" css={{ mb: "$6" }}>
              {currentUser?.me?.email}
            </Text>
            <Box
              css={{
                borderTop: "1px solid $grey1",
              }}
            >
              <Link
                to="/account/password"
                css={{
                  textDecoration: "none",
                }}
              >
                <Text
                  variant="b2"
                  css={{
                    mt: "$6",
                    mb: "$9",
                    "&:hover": {
                      color: "$grey4",
                    },
                  }}
                >
                  Change password
                </Text>
              </Link>
              <PrimaryExternalLink
                to="/"
                eventName="navigationPane:signOut"
                onClick={(e) => {
                  e.preventDefault();

                  if (onSignOut) {
                    onSignOut();
                  }
                }}
              >
                Sign out
              </PrimaryExternalLink>
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </Box>
  );
};
