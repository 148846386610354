import { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { Box, Container, Flex } from "src/ccl/layout";
import { PortfolioForm } from "src/components/dashboards/agents";
import { Icon, Text } from "src/ccl/document";
import {
  TalentProfile,
  User,
  TalentVertical,
  Mutation,
  MutationDeletePortfolioImagesArgs,
  Query,
  MutationCategorisePortfolioImagesArgs,
  MutationCreatePortfolioImageCategoryArgs,
  MutationSortPortfolioImagesArgs,
  MutationCreatePortfolioImagesArgs,
  MutationUpdatePortfolioImageCategoryArgs,
  MutationDeletePortfolioImageCategoryArgs,
  Agency,
} from "src/graphql/types";
import {
  CATEGORISE_PORTFOLIO_IMAGES_MUTATION,
  CREATE_PORTFOLIO_IMAGE_CATEGORY_MUTATION,
  DELETE_PORTFOLIO_IMAGES_MUTATION,
  DELETE_PORTFOLIO_IMAGE_CATEGORY_MUTATION,
  SORT_PORTFOLIO_IMAGES_MUTATION,
  UPDATE_PORTFOLIO_IMAGE_CATEGORY_MUTATION,
  CREATE_PORTFOLIO_IMAGES_MUTATION,
} from "src/graphql/mutations";
import { AGENT_TALENT_PORTFOLIO_QUERY } from "src/graphql/queries";
import { ValidationBlock } from "src/ccl/feedback";
import { filterMap } from "src/utils/array";
import {
  PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT,
  PORTFOLIO_IMAGE_DETAILS_FRAGMENT,
} from "src/graphql/fragments";
import { Link } from "src/ccl/navigation";
import { FullHeaderPage } from "src/ccl/templates";
import { SectionHeader } from "src/components/SectionHeader";

interface PortfolioProps {
  agency?: Agency;
  user: User;
  vertical: TalentVertical;
}

export const LegacyPortfolio = ({ agency, user, vertical }: PortfolioProps) => {
  const [activeCategoryId, setActiveCategoryId] = useState<string>();
  const profile = user.profile as TalentProfile;

  const { portfolioImages, portfolioImageCategories } = profile;
  // we can't store the active category explicitly, as when we update the cache
  // the stored category isn't updated, and so changes to the category aren't rendered
  const activeCategory = portfolioImageCategories.find(
    ({ id }) => id === activeCategoryId,
  );

  const client = useApolloClient();
  const refetchPortfolio = () =>
    client.query<Query, { id: string }>({
      query: AGENT_TALENT_PORTFOLIO_QUERY,
      variables: {
        id: user.id,
      },
    });

  const [deleteImages, { error: deleteImagesError }] = useMutation<
    Mutation,
    MutationDeletePortfolioImagesArgs
  >(DELETE_PORTFOLIO_IMAGES_MUTATION, {
    onError: refetchPortfolio,
    onCompleted: (data, options) => {
      if (data?.deletePortfolioImages?.success) {
        const deletedImageIds: string[] = options?.variables?.portfolioImageIds;
        if (activeCategory) {
          client.cache.modify({
            id: activeCategory
              ? `PortfolioImageCategory:${activeCategory.id}`
              : `TalentProfile:${user.profile.id}`,
            fields: {
              portfolioImages: () =>
                activeCategory.portfolioImages.filter(
                  ({ id }) => !deletedImageIds.includes(id),
                ),
            },
          });
        } else {
          deletedImageIds.forEach((id) =>
            client.cache.evict({
              id: `PortfolioImage:${id}`,
            }),
          );
        }
      }
    },
  });

  const [categoriseImages, { error: categoriseImagesError }] = useMutation<
    Mutation,
    MutationCategorisePortfolioImagesArgs
  >(CATEGORISE_PORTFOLIO_IMAGES_MUTATION, {
    onError: refetchPortfolio,
    onCompleted: (data, options) => {
      const imageRefs: string[] = options?.variables?.portfolioImageIds.map(
        (id: string) => ({
          __ref: `PortfolioImage:${id}`,
        }),
      );
      options?.variables?.portfolioImageCategoryIds.forEach(
        (updatedId: string) => {
          client.cache.modify({
            id: `PortfolioImageCategory:${updatedId}`,
            fields: {
              portfolioImages: (existing) => existing.concat(imageRefs),
            },
          });
        },
      );
    },
  });

  const [createCategory, { error: createCategoryError }] = useMutation<
    Mutation,
    MutationCreatePortfolioImageCategoryArgs
  >(CREATE_PORTFOLIO_IMAGE_CATEGORY_MUTATION, {
    update: (cache, { data }) => {
      if (data?.createPortfolioImageCategory) {
        const category = cache.writeFragment({
          data: data?.createPortfolioImageCategory,
          fragment: PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT,
          fragmentName: "PortfolioImageCategoryDetails",
        });
        cache.modify({
          id: `TalentProfile:${user.profile.id}`,
          fields: {
            portfolioImageCategories: (existing) => existing.concat(category),
          },
        });
      }
    },
  });

  const [editCategory, { error: editCategoryError }] = useMutation<
    Mutation,
    MutationUpdatePortfolioImageCategoryArgs
  >(UPDATE_PORTFOLIO_IMAGE_CATEGORY_MUTATION, {
    update: (cache, { data }) => {
      if (data?.updatePortfolioImageCategory) {
        cache.writeFragment({
          data: data?.updatePortfolioImageCategory,
          fragment: PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT,
          fragmentName: "PortfolioImageCategoryDetails",
        });
      }
    },
  });

  const [sortImages, { error: sortImagesError }] = useMutation<
    Mutation,
    MutationSortPortfolioImagesArgs
  >(SORT_PORTFOLIO_IMAGES_MUTATION, {
    update: (cache, { data }) => {
      const updatedImages = data?.sortPortfolioImages?.map((image) =>
        cache.writeFragment({
          data: image,
          fragment: PORTFOLIO_IMAGE_DETAILS_FRAGMENT,
        }),
      );
      cache.modify({
        id: activeCategory
          ? `PortfolioImageCategory:${activeCategory.id}`
          : `TalentProfile:${user.profile.id}`,
        fields: {
          portfolioImages: () => updatedImages,
        },
      });
    },
  });

  const [createImages, { error: createImagesError }] = useMutation<
    Mutation,
    MutationCreatePortfolioImagesArgs
  >(CREATE_PORTFOLIO_IMAGES_MUTATION, {
    update: (cache, { data }) => {
      const newImages = data?.createPortfolioImages?.map((image) =>
        cache.writeFragment({
          data: image,
          fragment: PORTFOLIO_IMAGE_DETAILS_FRAGMENT,
        }),
      );
      cache.modify({
        id: `TalentProfile:${user.profile.id}`,
        fields: {
          portfolioImages: (existing) => existing.concat(newImages),
        },
      });
      if (activeCategory) {
        cache.modify({
          id: `PortfolioImageCategory:${activeCategory.id}`,
          fields: {
            portfolioImages: (existing) => existing.concat(newImages),
          },
        });
      }
    },
  });

  const [deleteCategory, { error: deleteCategoryError }] = useMutation<
    Mutation,
    MutationDeletePortfolioImageCategoryArgs
  >(DELETE_PORTFOLIO_IMAGE_CATEGORY_MUTATION, {
    onCompleted: (data, options) => {
      if (data.deletePortfolioImageCategory?.success) {
        client.cache.modify({
          id: `TalentProfile:${user.profile.id}`,
          fields: {
            portfolioImageCategories: () =>
              portfolioImageCategories.filter(
                ({ id }) => id !== options?.variables?.portfolioImageCategoryId,
              ),
          },
        });
      }
    },
  });

  const error =
    deleteImagesError ||
    categoriseImagesError ||
    createImagesError ||
    sortImagesError ||
    deleteCategoryError ||
    editCategoryError ||
    createCategoryError;

  return (
    <FullHeaderPage>
      <Flex css={{ flexDirection: "column", gap: "47px" }}>
        <Flex css={{ flexDirection: "column", gap: "30px" }}>
          <Container css={{ pt: "14px" }}>
            <Link
              to={
                agency
                  ? `/dashboard/agent/agencies/${agency.id}/talent/${user.slug}`
                  : `/dashboard/agent/talent/${user.slug}`
              }
              css={{ textDecoration: "none" }}
            >
              <Flex
                css={{
                  gap: "$4",
                  alignItems: "center",
                }}
              >
                <Icon variant="chevronLeft" size={28} />
                <Text variant="nh3">{user.name}</Text>
              </Flex>
            </Link>
          </Container>
          <Box
            css={{
              height: "$2",
              backgroundColor: "$grey1",
            }}
          >
            <Box
              css={{
                height: "100%",
                backgroundColor: "$black",
                width: `${(0 / 2) * 100}%`,
                transition: "0.5s ease width",
              }}
            ></Box>
          </Box>
        </Flex>
        <Container>
          <Flex css={{ flexDirection: "column", width: "100%" }}>
            {error && (
              <ValidationBlock
                variant="error"
                title="Operation failed"
                body={error.message}
              />
            )}

            <SectionHeader step="1" label="Edit portfolio" />

            <Text
              variant="b2Bold"
              color="grey5"
              css={{
                mb: "43px",
              }}
            >
              You must upload polaroids.{" "}
              <Text as="span" variant="b2" color="grey5">
                Include front, back and side angles (with full-length and
                close-up versions).
              </Text>
            </Text>

            <PortfolioForm
              vertical={vertical}
              portfolioImages={portfolioImages}
              portfolioCategories={portfolioImageCategories}
              onDeleteImages={(images) =>
                deleteImages({
                  variables: { portfolioImageIds: images.map(({ id }) => id) },
                })
              }
              onUpload={(images) =>
                createImages({
                  variables: {
                    talentProfileId: user.profile.id,
                    portfolioImageCategoryId: activeCategory?.id,
                    files: filterMap(images, ({ fileData }) => fileData),
                  },
                })
              }
              sortPortfolioImages={(images) =>
                sortImages({
                  variables: {
                    portfolioImageCategoryId: activeCategory?.id,
                    talentProfileId: activeCategory
                      ? undefined
                      : user.profile.id,
                    sortOrder: images.map(({ id }, i) => ({ id, weight: i })),
                  },
                })
              }
              activeCategory={activeCategory}
              onCategoriseImages={(images, categories) =>
                categoriseImages({
                  variables: {
                    portfolioImageIds: images.map(({ id }) => id),
                    portfolioImageCategoryIds: categories.map(({ id }) => id),
                  },
                })
              }
              onSelectCategory={(c) => setActiveCategoryId(c?.id)}
              onCreateCategory={(name) =>
                createCategory({
                  variables: { name: name, talentProfileId: user.profile.id },
                })
              }
              onEditActiveCategory={(name) =>
                editCategory({
                  variables: {
                    name: name,
                    portfolioImageCategoryId: activeCategory?.id || "",
                  },
                })
              }
              onDeleteActiveCategory={() =>
                deleteCategory({
                  variables: {
                    portfolioImageCategoryId: activeCategory?.id || "",
                  },
                })
              }
            />
          </Flex>
        </Container>
      </Flex>
    </FullHeaderPage>
  );
};
