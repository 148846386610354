import { gql } from "@apollo/client";
import {
  META_FRAGMENT,
  PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT,
  PORTFOLIO_IMAGE_DETAILS_FRAGMENT,
  USER_ASSET_FRAGMENT,
  USER_BASE_FRAGMENT,
  USER_PROFILE_FRAGMENT,
} from "src/graphql/fragments";

export const VERTICAL_TALENT_SEARCH_QUERY = gql`
  query VerticalTalentSearch(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $name: String
    $gender: Gender
    $genders: [Gender!]
    $vertical: TalentVertical
    $verticals: [TalentVertical!]
    $fields: [VerticalFieldInput!]
    $location: String
    $locations: [String!]
    $locationContext: TalentSearchLocationContext
    $minAge: Int
    $maxAge: Int
    $minDayRate: Int
    $maxDayRate: Int
    $hobbies: [String!]
    $random: Boolean
    $seed: Float
    $excludedIds: [Int!]
    $availableFrom: ISO8601Date
    $availableTo: ISO8601Date
    $group: TalentSearchGroup
    $groupIds: [Int!]
    $bookmarked: Boolean
    $recentlyBooked: Boolean
    $agencyId: ID
    $sortField: String
    $sortDirection: SortDirection
    $excludeAgencyIds: [ID!]
    $isFeatured: Boolean
    $isContactOpen: Boolean
    $isContactSelect: Boolean
  ) {
    verticalTalentSearch(
      after: $after
      before: $before
      first: $first
      last: $last
      name: $name
      gender: $gender
      genders: $genders
      vertical: $vertical
      verticals: $verticals
      fields: $fields
      location: $location
      locations: $locations
      locationContext: $locationContext
      minAge: $minAge
      maxAge: $maxAge
      minDayRate: $minDayRate
      maxDayRate: $maxDayRate
      hobbies: $hobbies
      random: $random
      seed: $seed
      excludedIds: $excludedIds
      availableFrom: $availableFrom
      availableTo: $availableTo
      group: $group
      groupIds: $groupIds
      bookmarked: $bookmarked
      recentlyBooked: $recentlyBooked
      agencyId: $agencyId
      sortField: $sortField
      sortDirection: $sortDirection
      excludeAgencyIds: $excludeAgencyIds
      isFeatured: $isFeatured
      isContactOpen: $isContactOpen
      isContactSelect: $isContactSelect
    ) {
      ...Meta
      edges {
        cursor
        node {
          ...UserBase
          ...UserAsset
          bookmarked
          groups {
            name
          }
          averageResponseTimeMinutes
          profile {
            ... on TalentProfile {
              ...UserProfile
              agency {
                id
              }
              lastPortfolioUpload {
                id
                mediaUrl
                width
                height
              }
              lastPortfolioUploads(count: 8) {
                id
                mediaUrl
                width
                height
              }
              portfolioImageCategories {
                ...PortfolioImageCategoryDetails
              }
              portfolioImages {
                ...PortfolioImageDetails
              }
              socialMediaFields {
                platform
                handle
                followerCount
              }
            }
          }
        }
      }
    }
  }
  ${META_FRAGMENT}
  ${USER_BASE_FRAGMENT}
  ${USER_ASSET_FRAGMENT}
  ${USER_PROFILE_FRAGMENT}
  ${PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT}
  ${PORTFOLIO_IMAGE_DETAILS_FRAGMENT}
`;
