import React, { useState, useEffect } from "react";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { isEqual } from "lodash";
import Bugsnag from "@bugsnag/js";
import useDeepCompareEffect from "use-deep-compare-effect";
import { VerticalSelectModal } from "..";
import { TalentSelectionSummary } from ".";
import { useHandleBookmark, useScrollToTop, useStoreModel } from "src/hooks";
import {
  AvailableIcon,
  Icon,
  SignInModal,
  TalentCardGrid,
  TalentCardGridProps,
} from "src/ccl/document";
import {
  Filter,
  Query,
  TalentSearchLocationContext,
  TalentVertical,
  User,
  VerticalConfigurationCard,
  FilterContext,
  QueryVerticalTalentSearchArgs,
} from "src/graphql/types";
import { Box, Flex, ContentContainer, ContainerProps } from "src/ccl/layout";
import { FilterableContent, VerticalFilters } from "src/components/filtering";
import { pluralize, extractNodes } from "src/utils/lang";
import { VERTICAL_TALENT_SEARCH_QUERY } from "src/graphql/queries";
import { verticalMap } from "src/utils/user";
import {
  SearchParamsActionKind,
  useVerticalTalentSearchParams,
} from "src/hooks/useVerticalTalentSearchParams";
import { determineNumberOfActiveFilters } from "src/utils/filtering";
import { VerticalSelectionGroup } from "src/ccl/jobs/verticalSelection";
import { sendToAmplitude } from "src/utils/analytics";
import { Button } from "src/ccl/navigation";

const PageSize = 20;
const defaultLocationContext = TalentSearchLocationContext.PrimaryAndAdditional;

const removeUndefinedProperties = (obj: Record<string, unknown>) => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj.key);
  return obj;
};

const TabButton = ({
  text,
  icon,
  active,
  onClick,
}: {
  text: string;
  icon: AvailableIcon;
  active: boolean;
  onClick: () => void;
}) => (
  <Box
    onClick={onClick}
    css={{
      "&:hover": { cursor: "pointer" },
      background: active ? "$grey2" : "unset",
      width: "fit-content",
      px: "$6",
      py: "$3",
      borderRadius: "4px",
    }}
  >
    <Flex css={{ gap: "$4", fontWeight: 500, alignItems: "center" }}>
      <Icon size={16} variant={icon} solid={active} />
      {text}
    </Flex>
  </Box>
);

export interface FilterableTalentGridProps
  extends Omit<TalentCardGridProps, "talent" | "loading" | "context"> {
  context: "editJob" | "createJob";
  initialSelection?: User[];
  selectedTalent?: User[];
  onSelectionChange: (selectedTalentUsers: User[]) => void;
  highlightSelectionSummary?: boolean;
  availableFrom?: Date;
  availableTo?: Date;
  remainingShortlistableTalentCount?: number;
  verticalFilters?: Filter[];
  vertical: TalentVertical;
  setVertical?: (v: TalentVertical) => void;
  maxShortlistCount?: number;
  url?: (slug: string) => string;
  cacheResults?: boolean;
  variant?: "edit";
  containerProps?: ContainerProps;
  goBackTo?: string | (() => void | Promise<void>);
  onBack?: () => void;
  verticalSelectorCollapsed?: boolean;
  filterPaneTop?: number;
  children?: React.ReactNode;
}

export const FilterableTalentGrid = ({
  context,
  initialSelection = [],
  selectedTalent = [],
  highlightSelectionSummary = false,
  remainingShortlistableTalentCount,
  onSelectionChange,
  availableFrom,
  availableTo,
  verticalFilters,
  isRemovable,
  isCheckable,
  vertical,
  setVertical,
  maxShortlistCount,
  url,
  cacheResults,
  variant,
  containerProps,
  goBackTo,
  onBack,
  verticalSelectorCollapsed,
  filterPaneTop,
  ...gridProps
}: FilterableTalentGridProps) => {
  const baseSearchParams: QueryVerticalTalentSearchArgs = {
    random: true,
    availableFrom: availableFrom,
    availableTo: availableTo,
    first: PageSize,
    vertical: vertical,
    fields: [],
    locationContext: defaultLocationContext,
    seed: Math.random(),
    bookmarked: false,
  };

  const { queryCache } = useStoreModel("jobs");
  const cachedQuery = queryCache[context];
  const initialParams =
    cachedQuery?.variables.vertical === vertical
      ? cachedQuery.variables
      : baseSearchParams;

  const [searchParams, setSearchParams] = useVerticalTalentSearchParams(
    initialParams,
    baseSearchParams,
  );

  const { isBooker, loggedIn } = useStoreModel("currentUser");
  const { handleBookmark: toggleBookmark, loading: bookmarkLoading } =
    useHandleBookmark(!!searchParams.bookmarked);

  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };
  useEffect(() => {
    if (loggedIn && signInModalOpen) {
      toggleSignInModal();
    }
  }, [loggedIn]);

  const handleBookmark = isBooker
    ? toggleBookmark
    : !loggedIn
    ? toggleSignInModal
    : undefined;

  const apolloClient = useApolloClient();
  const [
    getTalent,
    {
      data: talentData,
      loading: talentLoading,
      refetch: refetchUsers,
      fetchMore: fetchMoreUsers,
      called: talentCalled,
    },
  ] = useLazyQuery<Query>(VERTICAL_TALENT_SEARCH_QUERY, {
    variables: searchParams,
    fetchPolicy: context === "editJob" ? "network-only" : "cache-first",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (cacheResults) {
        const query = {
          query: VERTICAL_TALENT_SEARCH_QUERY,
          data: data,
          variables: searchParams,
        };
        queryCache.setCachedQuery({ context, query });
      }
    },
  });

  useEffect(() => {
    updateVertical(vertical);
  }, [vertical]);

  useDeepCompareEffect(() => {
    const shouldUseCachedQuery =
      cacheResults &&
      cachedQuery &&
      isEqual(cachedQuery.variables, searchParams);

    if (shouldUseCachedQuery) {
      apolloClient.writeQuery(cachedQuery);
      getTalent();
    } else if (!talentCalled) {
      getTalent();
    } else if (!talentLoading) {
      refetchUsers && refetchUsers(searchParams);
    }
  }, [removeUndefinedProperties(searchParams)]);

  const talentSearch = talentData?.verticalTalentSearch;

  if (talentSearch === null) {
    Bugsnag.notify(
      new Error("Null response for verticalTalentSearch"),
      (event) => {
        event.addMetadata("searchParams", searchParams);
      },
    );
  }

  const [isLoadingMoreUsers, setIsLoadingMoreUsers] = useState(false);

  const users = extractNodes<User>(talentSearch);
  const pageInfo = talentSearch?.pageInfo;
  const endCursor = pageInfo?.endCursor || "";
  const totalUsers = talentSearch?.totalCount;
  const noMoreUsers = totalUsers === users.length;

  useScrollToTop(searchParams);

  const numberOfActiveFilters = determineNumberOfActiveFilters(
    searchParams,
    baseSearchParams,
  );

  const updateVertical = (v: TalentVertical) => {
    setVertical && setVertical(v);
    setSearchParams({
      kind: SearchParamsActionKind.SetVertical,
      payload: {
        vertical: v,
      },
    });
  };

  return (
    <>
      {setVertical && (
        <VerticalSelectModal
          vertical={searchParams.vertical}
          setVertical={updateVertical}
        />
      )}
      <TalentSelectionSummary
        talent={selectedTalent}
        vertical={searchParams.vertical}
        maxShortlistCount={maxShortlistCount}
        remainingShortlistableTalentCount={remainingShortlistableTalentCount}
        isRemovable={(u) => (isRemovable !== undefined ? isRemovable(u) : true)}
        onRemove={(u) => {
          onSelectionChange(selectedTalent.filter((su) => su.id !== u.id));
          if (u.profile.__typename === "TalentProfile") {
            const creativeVertical = u.profile.vertical;
            sendToAmplitude(
              "creative select - clicks remove from shortlist cta",
              { creativeVertical },
            );
          }
        }}
        highlighted={highlightSelectionSummary}
        context={loggedIn && !isBooker ? "agent" : "booker"}
        selectionCss={{ "@bp4": { px: "$0" } }}
        flowContext={context}
        goBackTo={goBackTo}
        onBack={onBack}
        filterContext={FilterContext.CreateAJob}
        expanded={vertical !== TalentVertical.FashionModel}
        filters={verticalFilters || []}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        talentLoading={talentLoading}
        totalCount={talentSearch?.totalCount}
        numberOfActiveFilters={numberOfActiveFilters}
      />
      <FilterableContent
        context={context}
        variant={variant}
        verticalSelector={
          setVertical ? (
            <VerticalSelectionGroup
              collapsed={!!verticalSelectorCollapsed}
              vertical={searchParams.vertical}
              setVertical={updateVertical}
            />
          ) : undefined
        }
        filterPaneContent={
          <VerticalFilters
            context={FilterContext.CreateAJob}
            expanded={vertical !== TalentVertical.FashionModel}
            filters={verticalFilters || []}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        }
        filterPaneSummary={
          talentLoading
            ? "Loading..."
            : talentSearch && (
                <>
                  Results:{" "}
                  <strong>
                    {pluralize(
                      talentSearch?.totalCount || 0,
                      verticalMap[vertical].replace(/s$/, ""),
                    )}
                  </strong>
                </>
              )
        }
        filterPaneTop={filterPaneTop}
      >
        <ContentContainer {...containerProps}>
          <Box css={{ mb: "$9" }}>
            {isBooker && (
              <Flex
                css={{ px: "$5", pb: "$5", "@bp2": { px: "unset", pb: "$7" } }}
              >
                <TabButton
                  text="All"
                  icon="user"
                  active={!searchParams.bookmarked}
                  onClick={() =>
                    setSearchParams({
                      kind: SearchParamsActionKind.ResetGeneric,
                      payload: ["bookmarked"],
                    })
                  }
                />
                <TabButton
                  text="Bookmarks"
                  icon="bookmark"
                  active={!!searchParams.bookmarked}
                  onClick={() =>
                    setSearchParams({
                      kind: SearchParamsActionKind.SetGeneric,
                      payload: { bookmarked: true },
                    })
                  }
                />
              </Flex>
            )}
            <TalentCardGrid
              talent={users}
              loading={talentLoading && !isLoadingMoreUsers}
              url={url}
              isCheckable={(u) => {
                if (context === "createJob") {
                  if (isCheckable) {
                    return isCheckable(u);
                  } else {
                    return true;
                  }
                } else if (isRemovable) {
                  return isRemovable(u);
                } else {
                  return !initialSelection.map((su) => su.id).includes(u.id);
                }
              }}
              isChecked={(u) =>
                selectedTalent.map((su) => su.id).includes(u.id)
              }
              isDisabled={remainingShortlistableTalentCount === 0}
              onCheck={(u, checked) => {
                const creativeVertical = vertical;
                if (checked) {
                  onSelectionChange([...selectedTalent, u]);
                  sendToAmplitude("creative select - shortlists creative", {
                    creativeVertical,
                  });
                } else {
                  onSelectionChange(
                    selectedTalent.filter((su) => su.id !== u.id),
                  );
                  sendToAmplitude(
                    "creative select - clicks remove from shortlist cta",
                    { creativeVertical },
                  );
                }
              }}
              travelLocation={searchParams.location}
              locationContext={searchParams.locationContext}
              cardType={
                vertical === TalentVertical.FashionModel
                  ? VerticalConfigurationCard.Single
                  : VerticalConfigurationCard.Multiple
              }
              handleBookmark={handleBookmark}
              isBookmarkDisabled={!isBooker || bookmarkLoading}
              {...gridProps}
            />

            {users.length > 0 && isLoadingMoreUsers && (
              <Flex
                css={{
                  justifyContent: "center",
                  pt: "$3",
                  pb: "$2",
                  "@bp5": { pb: "$9" },
                }}
              >
                <Icon variant="loading" size={60} />
              </Flex>
            )}

            {talentCalled &&
              !talentLoading &&
              !isLoadingMoreUsers &&
              !noMoreUsers && (
                <Flex
                  css={{
                    justifyContent: "center",
                    my: "$10",
                    "@bp2": { my: "$12" },
                  }}
                >
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (!fetchMoreUsers || noMoreUsers) {
                        return;
                      }
                      setIsLoadingMoreUsers(true);

                      sendToAmplitude("creative select - clicks load more", {
                        vertical: vertical,
                        creatives_loaded: `${users.length} - ${
                          users.length + PageSize
                        }`,
                      });

                      fetchMoreUsers({
                        variables: {
                          after: endCursor,
                        },
                      }).then(() => {
                        setIsLoadingMoreUsers(false);
                      });
                    }}
                  >
                    Load more
                  </Button>
                </Flex>
              )}
          </Box>
        </ContentContainer>
        <SignInModal isOpen={signInModalOpen} onClose={toggleSignInModal} />
      </FilterableContent>
    </>
  );
};
