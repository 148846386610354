import { gql } from "@apollo/client";
import { USER_PARTS_FRAGMENT } from "src/graphql/fragments";

export const ASSIGNED_JOB_COUNTS_QUERY = gql`
  query AssignedJobCounts {
    me {
      ...UserParts
      agencyUsers {
        ...UserParts
      }
    }
  }
  ${USER_PARTS_FRAGMENT}
`;
