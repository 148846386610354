import type * as Stitches from "@stitches/react";
import React, { useState } from "react";
import { Box } from "src/ccl/layout";
import { mergeCss, ResponsiveValue, styled } from "src/ccl/stitches";
import { OutsideClickHandler } from "src/ccl/util/OutsideClickHandler";

const InnerWrapper = styled("div", {
  position: "absolute",
  top: "-6px",
  transition: "100ms ease opacity",
  opacity: 0,
  visibility: "hidden",
  zIndex: "$999",

  variants: {
    state: {
      up: {
        opacity: 1,
        visibility: "visible",
      },
      down: {
        top: undefined,
        bottom: "-6px",
        opacity: 1,
        visibility: "visible",
      },
    },
    alignment: {
      center: {
        left: "50%",
        transform: "translate(-50%, calc(-100% - 6px))",
      },
      left: {
        right: 0,
        transform: "translate(0, calc(-100% - 6px))",
      },
      right: {
        left: 0,
        transform: "translate(0, calc(-100% - 6px))",
      },
    },
  },
  compoundVariants: [
    {
      state: "down",
      alignment: "center",
      css: {
        transform: "translate(-50%, calc(100% + 6px))",
      },
    },
    {
      state: "down",
      alignment: "left",
      css: {
        transform: "translate(0, calc(100% + 6px))",
      },
    },
    {
      state: "down",
      alignment: "right",
      css: {
        transform: "translate(90%, calc(100% + 6px))",
      },
    },
  ],
});

const LauncherLink = styled("a", {
  textDecoration: "none",
  cursor: "pointer",
});

const Body = styled("div", {
  bottom: 0,
  boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.2)",
  zIndex: "$400",
  backgroundColor: "$white",
  display: "inline-block",
});

const Caret = styled("div", {
  width: 0,
  height: 0,
  position: "absolute",
  bottom: 0,
  variants: {
    variant: {
      up: {
        borderLeftWidth: "6px",
        borderLeftColor: "transparent",
        borderLeftStyle: "solid",
        borderRightWidth: "6px",
        borderRightColor: "transparent",
        borderRightStyle: "solid",
        borderTopColor: "$white",
        borderTopStyle: "solid",
        borderTopWidth: "6px",
        transform: "translateX(6px)",
      },
      down: {
        top: 0,
        borderLeftWidth: "6px",
        borderLeftColor: "transparent",
        borderLeftStyle: "solid",
        borderRightWidth: "6px",
        borderRightColor: "transparent",
        borderRightStyle: "solid",
        borderBottomColor: "$white",
        borderBottomStyle: "solid",
        borderBottomWidth: "6px",
        transform: "translate(-6px, -5px)",
      },
    },
    alignment: {
      center: {
        left: "50%",
      },
      left: {
        right: "5%",
      },
      right: {
        left: "5%",
      },
    },
  },
});

type Direction = "up" | "down";
type Alignment = "center" | "left" | "right";

export interface FloatingPopoverProps {
  launcher: React.ReactNode;
  direction?: ResponsiveValue<Direction>;
  alignment?: ResponsiveValue<Alignment>;
  wrapperCss?: Stitches.CSS;
  caretCss?: Stitches.CSS;
  children?: React.ReactNode;
  name?: string;
}

export const FloatingPopover = ({
  launcher,
  direction = "up",
  alignment = "center",
  wrapperCss = {},
  caretCss = {},
  children,
  name,
}: FloatingPopoverProps) => {
  const [open, setOpen] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <Box
        css={{ position: "relative" }}
        data-test-id={`FloatingPopover-${name}`}
      >
        <LauncherLink onClick={() => setOpen(!open)}>{launcher}</LauncherLink>

        <InnerWrapper
          state={open ? direction : undefined}
          alignment={alignment}
          css={mergeCss({}, wrapperCss)}
        >
          <Caret
            variant={direction}
            alignment={alignment}
            css={mergeCss({}, caretCss)}
          />
          <Body>{children}</Body>
        </InnerWrapper>
      </Box>
    </OutsideClickHandler>
  );
};
