import { gql } from "@apollo/client";

export const JOB_INFO_CHATROOM_QUERY = gql`
  query JobInfoChatroom($jobId: ID!) {
    job(id: $jobId) {
      id
      name
      state
      jobTalent {
        id
        name
        assets {
          mediaUrl
        }
        profile {
          vertical
        }
        talent {
          slug
        }
      }
    }
  }
`;
