import React, { useState } from "react";
import { Text, Modal } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { Box, Flex } from "src/ccl/layout";
import { User, TalentProfile, TalentVertical } from "src/graphql/types";
import { copyToClipboard } from "src/utils/clipboard";
import { pluralize } from "src/utils/lang";
import { verticalMap } from "src/utils/user";
import { baseUrl } from "src/config";
import { useNewDashboard } from "src/hooks/useNewDashboard";

interface ShareShortlistModalProps {
  talent: User[];
  isOpen: boolean;
  onClose: () => void;
  talentVerticals?: string[];
}

export const ShareShortlistModal = ({
  talent,
  isOpen,
  onClose,
  talentVerticals,
}: ShareShortlistModalProps) => {
  const [textHasBeenCopied, setTextHasBeenCopied] = useState(false);
  const talentClipped: string[] = [];
  const profile = talent[0]?.profile as TalentProfile;
  const vertical = profile?.vertical || TalentVertical.FashionModel;
  const verticalCount = talentVerticals?.length;
  const { isNewDashboard } = useNewDashboard();

  let verticalPlural = "";
  verticalCount && verticalCount > 1
    ? (verticalPlural = pluralize(talent.length, "creative"))
    : (verticalPlural = pluralize(talent.length, verticalMap[vertical]));

  const displayShortlistTalentClipboard = (talent: User[]) => {
    //make talent name and URL into string in an array for clipboard
    return talent.map((t) => {
      const url = `${baseUrl}/creatives/${t.slug}`;
      talentClipped.push(`${t.name} - ${url}`);

      return (
        <Flex
          key={t.id}
          css={{
            gap: "$4",
            overflow: "hidden",
            whiteSpace: "nowrap",
            "@bp2": { gap: "$2" },
          }}
        >
          <Text>{t.name}</Text>
          <Text>{url}</Text>
        </Flex>
      );
    });
  };

  const setupClipboard = () => {
    setTextHasBeenCopied(true);
    const clipped = talentClipped.join(`\n`);
    copyToClipboard(clipped);
  };

  if (isNewDashboard) {
    return (
      <Modal
        title="Share your shortlist"
        isOpen={isOpen}
        showCloseButton
        onClose={onClose}
        width="wide"
        context="createJob"
        titleCss={{ textTransform: "none" }}
        isNewDashboard
      >
        <Text css={{ mb: "$9", mt: "$6" }}>
          We&apos;ve included links to the{" "}
          {pluralize(talent.length, "talent", "talents")} on your shortlist
          below. Share with your team to get feedback.
        </Text>

        <Text weight="bold" css={{ fontFamily: "$sansNew" }}>
          Shortlist
        </Text>
        <Box
          css={{
            border: "2px solid $grey3",
            borderRadius: "$lg",
            p: "$4",
            minHeight: "124px",
          }}
        >
          {talent && displayShortlistTalentClipboard(talent)}
        </Box>
        <Flex
          css={{
            mt: "$9",
            justifyContent: "flex-start",
            flexDirection: "column-reverse",
            "@bp1": { justifyContent: "space-between", flexDirection: "row" },
          }}
        >
          <Button
            variant="secondaryV2"
            onClick={onClose}
            css={{
              mr: "$0",
              padding: "21px 24px",
              "@bp2": { width: "initial", mr: "$4" },
            }}
          >
            Cancel
          </Button>

          <Button
            variant="primaryV2"
            onClick={() => {
              setupClipboard();
            }}
            css={{
              mb: "$5",
              padding: "21px 24px",
              "@bp1": { width: "initial", mb: 0 },
              minWidth: "$18",
            }}
          >
            <Box css={{ "@bp1": { display: "none" } }}>
              {textHasBeenCopied ? "Copied!" : "Copy"}
            </Box>
            <Box css={{ display: "none", "@bp1": { display: "block" } }}>
              {textHasBeenCopied ? "Copied!" : "Copy to clipboard"}
            </Box>
          </Button>
        </Flex>
      </Modal>
    );
  }

  return (
    <Modal
      title="Share this shortlist"
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
      width="wide"
      context="createJob"
    >
      <Text css={{ my: "$9" }}>
        We&apos;ve included links to the {verticalPlural} on your shortlist
        below. Share with your team to get feedback.
      </Text>

      <Text weight="bold">Shortlist</Text>
      <Box
        css={{
          border: "2px solid $black",
          p: "$4",
        }}
      >
        {talent && displayShortlistTalentClipboard(talent)}
      </Box>
      <Flex
        css={{
          mt: "$9",
          justifyContent: "flex-start",
          flexDirection: "column-reverse",
          "@bp1": { justifyContent: "space-between", flexDirection: "row" },
        }}
      >
        <Button
          variant="secondary"
          onClick={onClose}
          css={{
            mr: "$0",
            "@bp2": { width: "initial", mr: "$4" },
          }}
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            setupClipboard();
          }}
          css={{
            mb: "$5",
            "@bp1": { width: "initial", mb: 0 },
            minWidth: "$18",
          }}
        >
          <Box css={{ "@bp1": { display: "none" } }}>
            {textHasBeenCopied ? "Copied!" : "Copy"}
          </Box>
          <Box css={{ display: "none", "@bp1": { display: "block" } }}>
            {textHasBeenCopied ? "Copied!" : "Copy to clipboard"}
          </Box>
        </Button>
      </Flex>
    </Modal>
  );
};
