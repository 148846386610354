import { gql } from "@apollo/client";

export const TALENT_ONBOARDING_QUERY = gql`
  query TalentOnboarding($token: String!) {
    talentOnboarding(token: $token) {
      email
      name
    }
  }
`;
