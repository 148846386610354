import { Action, action } from "easy-peasy";
import { updateLocalStorageItem, getLocalStorageItem } from "src/utils/storage";

export type AgencyKanbanViewMode = "status" | "simple";

export interface AgencyKanbanModePreferenceModel {
  preference: AgencyKanbanViewMode;
  setPreference: Action<AgencyKanbanModePreferenceModel, AgencyKanbanViewMode>;
}

const localStorageKey = "agencyTableMode";
const initialLocalStorageValue =
  getLocalStorageItem<AgencyKanbanViewMode>(localStorageKey);

export const agencyKanbanModePreferenceModel: AgencyKanbanModePreferenceModel =
  {
    preference: initialLocalStorageValue || "status",
    setPreference: action((state, newPreference) => {
      state.preference = newPreference;
      updateLocalStorageItem(localStorageKey, newPreference);
    }),
  };
