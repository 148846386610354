import { useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router";
import { LegacyTalentProfileHeader } from "./LegacyTalentProfileHeader";
import { Avatar } from "src/ccl/blocks";
import { Loading } from "src/components";
import { Flex, Grid, Box } from "src/ccl/layout";
import { TALENT_USER_QUERY } from "src/graphql/queries/booker/TalentUser";
import { Query, TalentProfile as profile, User } from "src/graphql/types";
import { useVerticalConfigurations } from "src/hooks";
import { Text } from "src/ccl/document";
import { getConfigForVertical } from "src/utils/user";
import { Button } from "src/ccl/navigation";
import { getInitials } from "src/utils/lang";
import NotFound from "src/pages/NotFound";
import { PortfolioImageGallery } from "src/pages/TalentProfile/PortfolioImageGallery";
import { sendToAmplitude } from "src/utils/analytics";
import { urlAddQuery } from "src/utils/url";

interface ShortlistTalentProfileProps {
  isRemovable: (user: User) => boolean;
  selectedTalent: User[];
  setSelectedTalent: (talent: User[]) => void;
}

export const ShortlistTalentProfile = ({
  isRemovable,
  selectedTalent,
  setSelectedTalent,
}: ShortlistTalentProfileProps) => {
  const { params } = useRouteMatch<{ slug: string }>();

  const {
    configurations: verticalConfigs,
    error: configError,
    loading: configLoading,
  } = useVerticalConfigurations();

  const {
    data,
    error: userError,
    loading: userLoading,
  } = useQuery<Query>(TALENT_USER_QUERY, {
    variables: {
      id: params.slug,
    },
    returnPartialData: true,
  });

  const talent = data?.talent;
  const talentProfile = talent?.profile as profile;
  const talentVertical = talentProfile?.vertical;

  if (configLoading || userLoading) {
    return <Loading />;
  }

  if (!talent) {
    return <NotFound />;
  }

  const verticalConfig = getConfigForVertical(
    verticalConfigs || [],
    (talent.profile as profile).vertical,
  );

  if (
    !verticalConfig ||
    (!configLoading && !userLoading && (!talent || userError || configError))
  ) {
    return <Text variant="h4">Oops, something went wrong</Text>;
  }

  const avatarSize = 48;
  const featuredImage = talent.featuredAsset
    ? urlAddQuery(
        talent.featuredAsset.mediaUrl,
        `w=${avatarSize}&h=${avatarSize}&crop=faces&fit=crop`,
      )
    : undefined;

  const isShortlisted = selectedTalent.some((t) => t.id === talent.id);
  const handleShortlist = () => {
    const creativeVertical = talentVertical;
    if (isShortlisted) {
      setSelectedTalent(selectedTalent.filter((t) => t.id !== talent.id));
      sendToAmplitude("creative select - clicks remove from shortlist cta", {
        creativeVertical,
      });
    } else {
      setSelectedTalent([talent, ...selectedTalent]);
      sendToAmplitude("creative select - shortlists creative", {
        creativeVertical,
      });
    }
  };
  return (
    <>
      <LegacyTalentProfileHeader
        talent={talent}
        verticalConfig={verticalConfig}
        isShortlisted={isShortlisted}
        isRemovable={isRemovable(talent)}
        shortlistButtonClick={handleShortlist}
        showShortlistButton={true}
        context="CAJ"
      />

      <Box css={{ mt: "$6", "@bp2": { mt: "$12" }, "@bp3": { mt: "$17" } }}>
        <PortfolioImageGallery talent={talent} />
      </Box>

      <Flex
        css={{
          justifyContent: "center",
          backgroundColor: "$black",
          py: "$17",
          "@bp2": { pb: "$19" },
          "@bp5": { pb: "$20" },
        }}
      >
        <Grid css={{ gridColumns: 1 }}>
          <Flex css={{ justifyContent: "center" }}>
            <Avatar
              initials={getInitials(talent?.name)}
              imageUrl={featuredImage}
              fontVariant="impact"
              css={{
                backgroundColor: featuredImage ? "$white" : "$black",
                border: "2px solid $white",
              }}
              size="$largeAvatar"
            />
          </Flex>
          <Text
            variant="impact"
            css={{
              color: "$white",
              fontWeight: "$bold",
              pt: "$6",
              pb: "$8",
              fontSize: "$20",
              "@bp2": {
                fontSize: "$30",
              },
            }}
          >
            {`Want to work with ${talent.name}?`}
          </Text>
          <Flex css={{ justifyContent: "center" }}>
            <Button
              css={{
                display: "flex",
                alignItems: "center",
                fontSize: "$12",
              }}
              variant={isShortlisted ? "green" : "default"}
              onClick={handleShortlist}
            >
              {isShortlisted ? "Shortlisted" : "Add to shortlist"}
            </Button>
          </Flex>
        </Grid>
      </Flex>
    </>
  );
};
