import { gql } from "@apollo/client";

export const PORTFOLIO_IMAGE_DETAILS_FRAGMENT = gql`
  fragment PortfolioImageDetails on PortfolioImage {
    id
    mediaUrl
    sortWeight
    width
    height
    createdAt
  }
`;
