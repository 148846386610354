import { gql } from "@apollo/client";
import { ASSIGNEE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const REMOVE_JOB_ASSIGNEE_MUTATION = gql`
  mutation RemoveJobAssignee($jobId: ID!) {
    removeJobAssignee(jobId: $jobId) {
      ...AssigneeDetails
    }
  }
  ${ASSIGNEE_DETAILS_FRAGMENT}
`;
