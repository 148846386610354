import React from "react";
import type * as Stitches from "@stitches/react";
import { styled } from "src/ccl/stitches";

const StyledSelect = styled("select", {
  appearance: "none",
  backgroundColor: "$white",
  backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%23a1a1aa' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 0.5rem center",
  backgroundSize: "1.5em 1.5em",
  border: "2px solid $black",
  color: "$black",
  fontVariantNumeric: "tabular-nums",
  fontFamily: "$sans",
  fontSize: "$16",
  lineHeight: "$26",
  minWidth: 150,
  outline: "none",
  width: "100%",
  height: "50px",
  padding: "$4",
  boxSizing: "border-box",

  "&:focus": {
    boxShadow: "inset 0 0 0 1px $black",
  },
  "&:disabled": {
    backgroundColor: "$grey1",
    borderColor: "$grey6",
    color: "$grey6",
    cursor: "not-allowed",
    pointerEvents: "none",
  },
});

export type SelectProps = Stitches.ComponentProps<typeof StyledSelect>;

export const Select = (props: SelectProps) => (
  <StyledSelect id={props.id || `form-${props.name}`} {...props} />
);
