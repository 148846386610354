import React, { useState } from "react";
import { Modal, ModalProps, SignInModal, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { CreativeSearchRefineOption } from "src/graphql/types";
import { Button } from "src/ccl/navigation";
import { useStoreModel } from "src/hooks";
import { SortOrderOption } from "src/components/filtering";

interface SortCreativesProps extends ModalProps {
  refineValue: CreativeSearchRefineOption;
  setRefineValue: (value: CreativeSearchRefineOption) => void;
  creativeCount?: number;
}

export const SortCreativesModal = ({
  refineValue,
  setRefineValue,
  creativeCount,
  ...modalProps
}: SortCreativesProps) => {
  const currentUser = useStoreModel("currentUser");
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };
  return (
    <Modal
      withBodyPadding={false}
      titleCss={{ paddingTop: "$7" }}
      title="Refine"
      {...modalProps}
    >
      <Box data-test-id="SortCreativesModal">
        <Box
          css={{
            height: 300,
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              backgroundColor: "$white",
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "$grey3",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "$grey2",
              borderRadius: "5px",
              height: "81px",
            },
          }}
        >
          <SortOrderOption
            label="All"
            icon="user"
            selected={refineValue === CreativeSearchRefineOption.All}
            onClick={() => {
              setRefineValue(CreativeSearchRefineOption.All);
            }}
          />
          <SortOrderOption
            label="Recently booked"
            icon="timer"
            selected={refineValue === CreativeSearchRefineOption.RecentlyBooked}
            onClick={() => {
              currentUser.loggedIn
                ? setRefineValue(CreativeSearchRefineOption.RecentlyBooked)
                : toggleSignInModal();
            }}
          />
          {currentUser.isBooker && (
            <SortOrderOption
              label="Bookmarked"
              icon="bookmark"
              selected={refineValue === CreativeSearchRefineOption.Bookmarked}
              onClick={() => {
                currentUser.loggedIn
                  ? setRefineValue(CreativeSearchRefineOption.Bookmarked)
                  : toggleSignInModal();
              }}
            />
          )}
        </Box>
        <Flex css={{ justifyContent: "center", mb: "$2" }}>
          <Text color="grey4">{`${creativeCount || "No"} ${
            creativeCount === 1 ? "creative" : "creatives"
          } found`}</Text>
        </Flex>
        <Flex
          css={{
            height: "48px",
            m: "$5",
            justifyContent: "space-between",
          }}
        >
          <Button variant="secondary" onClick={modalProps.onClose}>
            Close
          </Button>
          <Button
            data-test-id="ConfirmButtonPrimary"
            variant="primary"
            onClick={modalProps.onClose}
          >
            Apply
          </Button>
        </Flex>
      </Box>
      <SignInModal isOpen={signInModalOpen} onClose={toggleSignInModal} />
    </Modal>
  );
};
