export const bookerKinds = [
  "Independent fashion brand",
  "High street fashion brand",
  "Luxury fashion brand",
  "Ecommerce brand",
  "Other brand",
  "Myself (freelance)",
  "Creative agency/studio",
  "Casting agency",
  "Production company",
  "Magazine/publication",
  "Other",
];

export const bookerKindOtherOptions = ["Other", "Other brand"];

export const bookerKindOptions = bookerKinds.map((kind) => ({
  label: kind,
  value: kind,
}));

export const bookerTypes = [
  ...bookerKinds,
  {
    label: "Other",
    value: "Other",
  },
];

export const bookerRoles = [
  "Producer",
  "Production assistant",
  "Project manager",
  "Creative director",
  "Art director",
  "Stylist",
  "Photographer",
  "Designer",
  "Creative",
  "Casting director",
  "Brand founder",
  "Student",
  "Other",
];

export const bookerRoleOptions = bookerRoles.map((role) => ({
  label: role,
  value: role,
}));

export const bookerRoleOtherOptions = ["Other"];
