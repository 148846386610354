import { action, Action, persist } from "easy-peasy";
import { TalentVertical, User } from "src/graphql/types";

export interface JobResubmissionDraft {
  talentUsers: Record<string, User[] | null>;
  setTalentUsersForJob: Action<
    JobResubmissionDraft,
    { jobId: string; users: User[] }
  >;
  existingVerticals: Record<string, TalentVertical[]>;
  setExistingVerticalsForJob: Action<
    JobResubmissionDraft,
    { jobId: string; verticals: TalentVertical[] }
  >;
  resetJob: Action<JobResubmissionDraft, string>;
}

const persistInLocalStorage = (model: JobResubmissionDraft) =>
  persist(model, { storage: "localStorage" });

export const jobResubmissionDraft: JobResubmissionDraft = persistInLocalStorage(
  {
    talentUsers: {},
    setTalentUsersForJob: action((state, payload) => {
      state.talentUsers[payload.jobId] = payload.users;
    }),
    resetJob: action((state, jobId) => {
      delete state.talentUsers[jobId];
      delete state.existingVerticals[jobId];
    }),
    existingVerticals: {},
    setExistingVerticalsForJob: action((state, payload) => {
      state.existingVerticals[payload.jobId] = payload.verticals;
    }),
  },
);
