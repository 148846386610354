import { gql } from "@apollo/client";

export const AGENT_TALENT_BOOKER_JOBS_QUERY = gql`
  query AgentTalentBookerJobs($slug: ID!, $bookerId: ID!) {
    me {
      agency {
        talent(id: $slug) {
          profile {
            ... on TalentProfile {
              reviewableJobsForBooker(bookerId: $bookerId) {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
