export const formatCurrency = (amount: number, currency = "GBP") => {
  const options = {
    style: "currency",
    currency: currency,
  };

  if (amount % 1 === 0) {
    // Round number, so don't display any fractional numbers
    return amount.toLocaleString("en-GB", {
      ...options,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  } else {
    // Non-round number, so show the pence
    return amount.toLocaleString("en-GB", {
      ...options,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};

export const formatCurrencyWithTwoDecimal = (
  amount: number,
  currency = "GBP",
) => {
  const options = {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  return amount.toLocaleString("en-GB", options);
};
