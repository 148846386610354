import React, { useEffect, useState } from "react";
import {
  DndContext,
  MouseSensor,
  Over,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { AddToCategoriesModal } from "./AddToCategoriesModal";
import { CreatePortfolioCategoryModal } from "./CreatePortfolioCategoryModal";
import { EditPortfolioCategoryModal } from "./EditPortfolioCategoryModal";
import { PortfolioCategoryList } from "./PortfolioCategoryList";
import { PortfolioSelectionDropdown } from "./PortfolioSelectionDropdown";
import polaroidTopLeft from "./polaroidTopLeft.jpeg";
import polaroidBottomLeft from "./polaroidBottomLeft.jpeg";
import polaroidTopRight from "./polaroidTopRight.jpeg";
import polaroidBottomRight from "./polaroidBottomRight.jpeg";
import { Pill } from "src/ccl/blocks";
import { FileDragDropUpload } from "src/ccl/data-entry";
import { ConfirmModal, Icon, Img, Modal, Text } from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { SortableSelectableAssetGrid } from "src/components/dashboards/agents";
import { UploadedFile } from "src/entities";
import {
  DraftPortfolioImage,
  PortfolioImage,
  PortfolioImageCategory,
  TalentVertical,
} from "src/graphql/types";
import { useKeyboardShortcut } from "src/hooks";
import {
  defaultMeasuring,
  pointerWithinOrClosestCenter,
} from "src/utils/dragAndDrop";
import { filterMap } from "src/utils/array";

const IconCircle = ({
  text,
  variant,
}: {
  text: string;
  variant: "check" | "cross";
}) => (
  <Flex css={{ gap: "$4", alignItems: "center" }}>
    <Flex
      css={{
        minHeight: "31px",
        minWidth: "31px",
        borderRadius: "$round",
        background: variant === "check" ? "$green" : "$red",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon variant={variant} color="white" size={12} />
    </Flex>
    <Text variant="b3">{text}</Text>
  </Flex>
);
const GoodCircle = ({ text }: { text: string }) => (
  <IconCircle variant="check" text={text} />
);
const BadCircle = ({ text }: { text: string }) => (
  <IconCircle variant="cross" text={text} />
);

const PolaroidsInfoBox = () => {
  return (
    <Flex css={{ flexDirection: "column", gap: "$8" }}>
      <Flex css={{ gap: "14px", alignItems: "center" }}>
        <Icon variant="polaroidAltOutline" size={30} color="grey6" />
        <Text variant="nh3">What are polaroids?</Text>
      </Flex>

      <Text variant="b3">
        <strong>You must upload polaroids (up to 8 photos).</strong> Include
        front, back and side angles (with full-length and close-up versions).
        These will be set as your featured images by default.
      </Text>

      <Flex
        css={{
          gap: "18px",
          flexDirection: "column",
          "@lg": { flexDirection: "row" },
        }}
      >
        <Grid
          css={{
            gridColumns: 2,
            gridGap: "$3",
            columnGap: "$3",
            "@md": { maxWidth: 358 },
          }}
        >
          <Img src={polaroidTopLeft} css={{ borderRadius: 8 }} />
          <Img src={polaroidTopRight} css={{ borderRadius: 8 }} />
          <Img src={polaroidBottomLeft} css={{ borderRadius: 8 }} />
          <Img src={polaroidBottomRight} css={{ borderRadius: 8 }} />
        </Grid>

        <Flex css={{ flexDirection: "column", gap: "18px" }}>
          <Flex css={{ flexDirection: "column", gap: "$4" }}>
            <Text variant="b3Bold">Polaroids must follow these guidelines</Text>
            <Flex css={{ flexDirection: "column", gap: "11px" }}>
              <GoodCircle text="Have good lighting" />
              <GoodCircle text="Plain background" />
              <GoodCircle text="Taken within the last 3 months" />
              <GoodCircle text="Minimal / no makeup" />
            </Flex>
          </Flex>
          <Flex css={{ flexDirection: "column", gap: "$4" }}>
            <Text variant="b3Bold">Polaroids must not</Text>
            <Flex css={{ flexDirection: "column", gap: "11px" }}>
              <BadCircle text="Be low resolution and/or blurry" />
              <BadCircle text="Have filters/editing applied" />
              <BadCircle text="Have face hidden (i.e. no hats or sunglasses)" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Button
        variant="secondaryCta"
        css={{
          "@md": {
            width: "fit-content",
          },
        }}
      >
        Back
      </Button>
    </Flex>
  );
};

export const removeImages = ({
  toRemove,
  removeFrom,
}: {
  toRemove: PortfolioImage[];
  removeFrom: PortfolioImage[];
}) => {
  const removeIds = toRemove.map(({ id }) => id);
  return removeFrom.filter(({ id }) => !removeIds.includes(id));
};

export const PortfolioForm = ({
  portfolioImages,
  portfolioCategories,
  onDeleteImages,
  onUpload,
  sortPortfolioImages,
  activeCategory,
  onCategoriseImages,
  onSelectCategory,
  onCreateCategory,
  onEditActiveCategory,
  onDeleteActiveCategory,
  context,
  vertical,
}: {
  portfolioImages: PortfolioImage[];
  portfolioCategories: PortfolioImageCategory[];
  onUpload: (images: DraftPortfolioImage[]) => void;
  onDeleteImages: (images: PortfolioImage[]) => void;
  activeCategory?: PortfolioImageCategory;
  sortPortfolioImages: (images: PortfolioImage[]) => void;
  onCategoriseImages: (
    images: PortfolioImage[],
    categories: PortfolioImageCategory[],
  ) => void;
  onSelectCategory: (category?: PortfolioImageCategory) => void;
  onCreateCategory: (name: string) => void;
  onEditActiveCategory: (name: string) => void;
  onDeleteActiveCategory: () => void;
  context?: "addTalent";
  vertical: TalentVertical;
}) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [activeItemIndex, setActiveItemIndex] = useState<number>();

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedImages, setSelectedImages] = useState<PortfolioImage[]>([]);

  useEffect(() => {
    if (!isSelecting && selectedImages.length > 0) {
      setSelectedImages([]);
    }
  }, [isSelecting]);

  useKeyboardShortcut("Escape", () => isSelecting && setIsSelecting(false), [
    isSelecting,
  ]);

  useEffect(() => {
    if (
      vertical === TalentVertical.FashionModel &&
      !portfolioCategories?.find(({ name }) => name === "Polaroids")
    ) {
      onCreateCategory("Polaroids");
    }
  }, [vertical, portfolioCategories]);

  const [deleteImagesModalOpen, setDeleteImagesModalOpen] = useState(false);
  const [addToCategoryModalOpen, setAddToCategoryModalOpen] = useState(false);
  const [createCategoryModalOpen, setCreateCategoryModalOpen] = useState(false);
  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false);
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);
  const [polaroidInfoModalOpen, setPolaroidInfoModalOpen] = useState(false);

  const [previewModalAsset, setPreviewModalAsset] = useState<PortfolioImage>();
  const visibleImages = activeCategory
    ? activeCategory.portfolioImages
    : portfolioImages;

  const handleUpload = (files: UploadedFile[]) => {
    const newImages: DraftPortfolioImage[] = filterMap(files, (file, index) =>
      file.key
        ? {
            id: file.key || (portfolioImages.length + index).toString(),
            mediaUrl: file.mediaUrl || "",
            sortWeight: visibleImages.length + index,
            fileData: {
              key: file.key,
              contentType: file.contentType,
              contentLength: file.contentLength,
              filename: file.filename,
            },
          }
        : null,
    );

    onUpload(newImages);
  };

  const handleSort = (activeId: string, overId: string) => {
    const currentIndex = visibleImages.findIndex(({ id }) => id === activeId);
    const nextIndex = visibleImages.findIndex(({ id }) => id === overId);
    if (currentIndex === nextIndex) {
      return;
    }

    sortPortfolioImages(arrayMove(visibleImages, currentIndex, nextIndex));
    setActiveItemIndex(undefined);
  };
  const [dropTarget, setDropTarget] = useState<Over>();

  const selectDisabled = visibleImages.length === 0;
  return (
    <DndContext
      sensors={sensors}
      measuring={defaultMeasuring}
      collisionDetection={pointerWithinOrClosestCenter}
      onDragOver={({ over }) => {
        if (
          over &&
          portfolioCategories
            .map(({ name }) => name)
            .includes(over.id.toString())
        ) {
          setDropTarget(over);
        } else {
          setDropTarget(undefined);
        }
      }}
      onDragStart={(e) =>
        setActiveItemIndex(
          visibleImages.findIndex(({ id }) => e.active.id.toString() === id),
        )
      }
      onDragEnd={({ active, over }) => {
        if (!over || over.id === "All photos" || active.id === over.id) {
          return;
        }

        if (!over?.data?.current?.sortable) {
          const image = visibleImages.find(({ id }) => id === active.id);
          const category = portfolioCategories?.find(
            ({ name }) => name === over.id,
          );
          return image && category && onCategoriseImages([image], [category]);
        }

        return handleSort(active.id.toString(), over.id.toString());
      }}
      onDragCancel={() => setActiveItemIndex(undefined)}
    >
      <Box css={{ pb: "$10" }}>
        <Flex
          css={{
            flexGrow: 1,
            flexDirection: "column",
            gap: "$9",
            "@bp2": { flexDirection: "row", gap: "unset" },
          }}
        >
          <PortfolioCategoryList
            portfolioImages={portfolioImages}
            portfolioCategories={portfolioCategories}
            onCreateCategory={() => setCreateCategoryModalOpen(true)}
            activeCategory={activeCategory}
            onSelectCategory={onSelectCategory}
            onDeleteCategory={() => setDeleteCategoryModalOpen(true)}
            onEditCategory={() => setEditCategoryModalOpen(true)}
            isSelecting={isSelecting}
          />
          <Flex
            css={{
              flexDirection: "column",
              flexGrow: 1,
              "@bp2": { borderLeft: "1px solid $grey3", pl: "$8" },
            }}
          >
            <Flex
              css={{
                alignItems: "center",
                pb: "$5",
                justifyContent: "space-between",
              }}
            >
              <Flex css={{ gap: "6px", alignItems: "center" }}>
                <Text variant="b2Bold">
                  {isSelecting ? "Select" : "Upload"} photos
                </Text>
                {activeCategory?.name === "Polaroids" && (
                  <Button
                    variant="unstyled"
                    onClick={() => setPolaroidInfoModalOpen(true)}
                  >
                    <Icon variant="information" size={24} />
                  </Button>
                )}
              </Flex>
              <Flex css={{ gap: "$5" }}>
                {!isSelecting && visibleImages.length !== 0 && (
                  <FileDragDropUpload
                    attachmentStoreName="uploads"
                    maxFileCount={100}
                    contentNoFiles={
                      <Button variant="circle">
                        <Icon variant="plus" size={18} color="grey6" />
                      </Button>
                    }
                    contentWithFiles={
                      <Button variant="circle">
                        <Icon variant="plus" size={18} color="grey6" />
                      </Button>
                    }
                    onUpload={handleUpload}
                    type="image"
                    multiple={true}
                    showPreviewList={false}
                    containerCss={{
                      border: "none",
                      height: "fit-content",
                      width: "fit-content",
                      "@bp2": {
                        display: "none",
                      },
                    }}
                  />
                )}
                <Pill
                  data-test-id="SelectButton"
                  variant="roundedSecondary"
                  css={{
                    m: 0,
                    cursor: selectDisabled ? "not-allowed" : "pointer",
                    py: "$3",
                    "&:hover": {
                      backgroundColor: selectDisabled ? undefined : "$grey1",
                    },
                  }}
                  onClick={
                    selectDisabled
                      ? undefined
                      : () => setIsSelecting(!isSelecting)
                  }
                >
                  <Text variant="mini" color="grey6">
                    {isSelecting ? "Cancel" : "Select"}
                  </Text>
                </Pill>
                {isSelecting &&
                  selectedImages.length !== visibleImages.length && (
                    <Pill
                      variant="roundedSecondary"
                      css={{
                        m: 0,
                        cursor: selectDisabled ? "not-allowed" : "pointer",
                        py: "$3",
                        "&:hover": {
                          backgroundColor: selectDisabled
                            ? undefined
                            : "$grey1",
                        },
                      }}
                      onClick={
                        selectDisabled
                          ? undefined
                          : () => {
                              setSelectedImages(visibleImages);
                            }
                      }
                    >
                      <Text variant="mini" color="grey6">
                        Select all
                      </Text>
                    </Pill>
                  )}
                {isSelecting && (
                  <PortfolioSelectionDropdown
                    disabled={selectedImages.length === 0}
                    onAdd={() => setAddToCategoryModalOpen(true)}
                    onDelete={() => setDeleteImagesModalOpen(true)}
                  />
                )}
              </Flex>
            </Flex>
            <Flex
              css={{
                gap: "$5",
                pb: "$6",
                display: isSelecting ? undefined : "none",
                "@bp2": { display: "flex" },
              }}
            >
              <Flex
                css={{
                  width: 20,
                  height: 20,
                  backgroundColor: "$grey2",
                  borderRadius: "$round",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  variant={isSelecting ? "check" : "drag"}
                  color="black"
                  size={10}
                />
              </Flex>
              <Text color="grey6" variant="meta">
                {isSelecting
                  ? "Multi-select to move photos to categories"
                  : "Drag images to reorder"}
              </Text>
            </Flex>
            <SortableSelectableAssetGrid
              context={context}
              dropTarget={dropTarget}
              activeItemIndex={activeItemIndex}
              existingAssets={visibleImages}
              onRemove={(image) => {
                setSelectedImages([image]);
                setDeleteImagesModalOpen(true);
              }}
              onUpload={handleUpload}
              onItemClick={setPreviewModalAsset}
              isSelecting={isSelecting}
              onSelect={(image) => {
                if (selectedImages.map(({ id }) => id).includes(image.id)) {
                  setSelectedImages((current) =>
                    removeImages({ toRemove: [image], removeFrom: current }),
                  );
                } else {
                  setSelectedImages((current) => current.concat(image));
                }
              }}
              selectedAssetIds={selectedImages.map(({ id }) => id)}
            />
          </Flex>
        </Flex>

        <ConfirmModal
          isOpen={deleteImagesModalOpen}
          title="Are you sure?"
          confirmButtonTitle={`Yes, delete ${
            selectedImages.length > 1 ? "them" : "it"
          }`}
          onConfirm={() => {
            onDeleteImages(selectedImages);

            if (!isSelecting) {
              setSelectedImages([]);
            } else {
              setIsSelecting(false);
            }

            setDeleteImagesModalOpen(false);
          }}
          onCancel={() => {
            if (!isSelecting) {
              setSelectedImages([]);
            }
            setDeleteImagesModalOpen(false);
          }}
        >
          <Text>
            Are you sure you want to delete{" "}
            {selectedImages.length > 1 ? "these images?" : "this image?"}
          </Text>
        </ConfirmModal>
        <Modal
          isOpen={previewModalAsset !== undefined}
          onClose={() => {
            setPreviewModalAsset(undefined);
          }}
          showCloseButton
        >
          {previewModalAsset && (
            <Flex css={{ pt: "$4", justifyContent: "center" }}>
              <img src={previewModalAsset.mediaUrl} alt="A portfolio item" />
            </Flex>
          )}
        </Modal>
        {portfolioCategories && (
          <AddToCategoriesModal
            isOpen={addToCategoryModalOpen && !createCategoryModalOpen}
            categories={portfolioCategories}
            onCreateNewCategory={() => setCreateCategoryModalOpen(true)}
            onClose={() => setAddToCategoryModalOpen(false)}
            selectedImages={selectedImages}
            onAddToCategories={(selectedCategories) => {
              onCategoriseImages(selectedImages, selectedCategories);
              setAddToCategoryModalOpen(false);
              setIsSelecting(false);
            }}
          />
        )}
        {activeCategory && (
          <EditPortfolioCategoryModal
            category={activeCategory}
            existingCategories={portfolioCategories || []}
            isOpen={editCategoryModalOpen && !deleteCategoryModalOpen}
            onClose={() => setEditCategoryModalOpen(false)}
            onDelete={() => setDeleteCategoryModalOpen(true)}
            onEdit={(name) => {
              onEditActiveCategory(name);
              setEditCategoryModalOpen(false);
            }}
          />
        )}
        <CreatePortfolioCategoryModal
          existingCategories={portfolioCategories || []}
          isOpen={createCategoryModalOpen}
          onClose={() => setCreateCategoryModalOpen(false)}
          onCreate={(name) => {
            onCreateCategory(name);
            setCreateCategoryModalOpen(false);
          }}
        />
        <ConfirmModal
          isOpen={deleteCategoryModalOpen}
          title={`Are you sure you want to delete your '${activeCategory?.name}' category?`}
          showCloseButton={true}
          titleCss={{ textTransform: "inherit", fontFamily: "shapiro" }}
          flexCss={{ pt: "$7", pb: "$5" }}
          subtitle="You can't undo this later. Your category will be permanently deleted"
          onCancel={() => setDeleteCategoryModalOpen(false)}
          onClose={() => setDeleteCategoryModalOpen(false)}
          onConfirm={() => {
            onDeleteActiveCategory();
            setDeleteCategoryModalOpen(false);
            setEditCategoryModalOpen(false);
          }}
          confirmButtonTitle="Delete Category"
        />
        <Modal
          width="wide"
          isOpen={polaroidInfoModalOpen}
          showCloseButton={true}
          onClose={() => setPolaroidInfoModalOpen(false)}
        >
          <PolaroidsInfoBox />
        </Modal>
      </Box>
    </DndContext>
  );
};
