import React from "react";
import { Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { disableEnvironmentBanner, environmentName } from "src/config";

const colors: Record<string, string> = {
  development: "#141414",
  staging: "#0000cc",
};

export const EnvironmentBanner = () => {
  if (!colors[environmentName] || disableEnvironmentBanner) {
    return null;
  }

  return (
    <Box
      css={{
        backgroundColor: colors[environmentName],
        padding: "0.8rem 1.5rem",
      }}
    >
      <Text color="white" variant="mini" css={{ fontFamily: "$sansWide" }}>
        {environmentName}
      </Text>
    </Box>
  );
};
