import { Action, action } from "easy-peasy";
import { MeasurementUnit } from ".";
import { updateLocalStorageItem, getLocalStorageItem } from "src/utils/storage";

export interface MeasurementUnitPreferenceModel {
  preference: MeasurementUnit;
  setPreference: Action<MeasurementUnitPreferenceModel, MeasurementUnit>;
}

const localStorageKey = "profileUnitPreference";
const initialLocalStorageValue =
  getLocalStorageItem<MeasurementUnit>(localStorageKey);

export const unitPreferenceModel: MeasurementUnitPreferenceModel = {
  preference: initialLocalStorageValue || "metric",
  setPreference: action((state, newPreference) => {
    state.preference = newPreference;
    updateLocalStorageItem(localStorageKey, newPreference);
  }),
};
