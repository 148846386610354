import React, { useState } from "react";
import { keyframes, CSS } from "@stitches/react";
import { Icon, Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches/config";
import { Box } from "src/ccl/layout";
import { mergeCss } from "src/ccl";
import { sendToAmplitude } from "src/utils/analytics";

interface ShortlistButtonProps {
  shortlisted: boolean;
  toggleShortlist: () => void;
  css?: CSS;
  textCss?: CSS;
  analyticsEventPage?: string;
}

export const StyledShortlistButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "8px 15px",
  gap: "8px",
  width: "132px",
  height: "34px",
  boxShadow: "$subtle",
  borderRadius: "10px",
  border: "none",
  backgroundColor: "$white",
  color: "$black",
  transition: "opacity 0.25s",
  cursor: "pointer",
  "@bp3": {
    opacity: 0,
  },
  variants: {
    state: {
      shortlisted: {
        color: "$white",
        width: "107px",
        backgroundColor: "$grey4",
        justifyContent: "flex-start",
        "@bp3": {
          width: "34px",
          backgroundColor: "$green",
          justifyContent: "center",
        },
      },
    },
  },
});

const LeftChecklistContainer = styled("div", {
  position: "absolute",
  left: 18,
  top: 10,
  transform: "translate(-2px, 1px) rotate(-15deg)",
  transition: "transform 0.25s",
  "@bp3": { transform: "revert" },
});
const RightChecklistContainer = styled("div", {
  position: "absolute",
  left: 19,
  top: 10,
  transform: "translate(2px, -2px) rotate(4deg)",
  transition: "transform 0.25s",
  "@bp3": {
    transform: "revert",
  },
});

const StyledRemoveText = styled(Text, {
  fontSize: "$12",
  lineHeight: "$18",
  fontWeight: "$bold",
  textTransform: "uppercase",
  color: "$white",
});

const StyledTickIcon = styled(Icon, {
  flexShrink: 0,
});

const StyledRemoveIcon = styled(Icon, {
  flexShrink: 0,
  display: "none",
});

const onShortlistIcon = () =>
  keyframes({
    "0%": { opacity: 1 },
    "33%": { opacity: 1 },
    "70%": {
      opacity: 0,
    },
    "100%": {
      opacity: 0,
    },
  });

const onShortlist = (isMobile?: "isMobile") =>
  keyframes({
    "0%": {
      width: "132px",
      justifyContent: "flex-start",
      backgroundColor: "$green",
    },
    "10%": { width: "142px", height: "35px" },
    "50%": {
      width: isMobile ? "107px" : "98px",
      justifyContent: "flex-start",
      height: "34px",
    },
    "70%": {
      width: isMobile ? "107px" : "98px",
      justifyContent: "flex-start",
      backgroundColor: "$green",
    },
    "100%": {
      width: isMobile ? "107px" : "34px",
      justifyContent: isMobile ? "flex-start" : "center",
    },
  });

const onShortlistRemove = (isMobile?: "isMobile") =>
  keyframes({
    "0%": {
      width: isMobile ? "107px" : "34px",
      justifyContent: "center",
      backgroundColor: "$grey4",
    },
    "30%": {
      width: "116x",
      justifyContent: "flex-start",
      backgroundColor: "$grey4",
    },
    "50%": {
      width: "116px",
      justifyContent: "flex-start",
      height: "34px",
      backgroundColor: "$grey4",
    },
    "90%": { width: "142px", height: "35px", backgroundColor: "$white" },
    "100%": {
      width: "132px",
      justifyContent: "flex-start",
      backgroundColor: "$white",
    },
  });

const onShortlistText = () =>
  keyframes({
    "0%": { width: "46px" },
    "33%": { width: "46px", opacity: 1 },
    "70%": {
      width: "0px",
      height: "0px",
      position: "absolute",
      left: 37,
      top: 8,
      opacity: 0,
      zIndex: 0,
    },
    "100%": {
      width: "0px",
      height: "0px",
      position: "absolute",
      left: 37,
      top: 8,
      opacity: 0,
      zIndex: 0,
    },
  });

const onShortlistTextRemove = () =>
  keyframes({
    "0%": {
      width: "0px",
      height: "0px",
      position: "absolute",
      opacity: 0,
      left: 0,
      top: 8,
    },
    "40%": {
      width: "64px",
      height: "auto",
      position: "absolute",
      opacity: 1,
      left: 0,
      top: 8,
    },
    "85%": {
      width: "64px",
      height: "auto",
      position: "absolute",
      opacity: 1,
      left: 0,
      top: 8,
    },
    "90%": { width: "0px", height: "0px", opacity: 0 },
    "100%": { width: "0px", height: "0px", opacity: 0, visibility: "hidden" },
  });

export const ShortlistButton = ({
  shortlisted,
  toggleShortlist,
  css = {},
  analyticsEventPage,
}: ShortlistButtonProps) => {
  const [animate, setAnimate] = useState(false);
  const [reverseAnimate, setReverseAnimate] = useState(false);

  return (
    <StyledShortlistButton
      onClick={() => {
        toggleShortlist();
        setAnimate(!shortlisted);
        setReverseAnimate(shortlisted);
        analyticsEventPage &&
          shortlisted &&
          sendToAmplitude(`${analyticsEventPage} - un-shortlists creative`);
        analyticsEventPage &&
          !shortlisted &&
          sendToAmplitude(`${analyticsEventPage} - shortlists creative`);
      }}
      state={shortlisted ? "shortlisted" : undefined}
      css={mergeCss(
        shortlisted
          ? {
              animation: animate
                ? `${onShortlist("isMobile")} 1.5s forwards`
                : undefined,
              opacity: 1,
              backgroundColor: shortlisted && !animate ? "$grey4" : undefined,
              [`${StyledTickIcon}`]: {
                display: shortlisted && !animate ? "none" : undefined,
              },
              [`${StyledRemoveIcon}`]: {
                display: shortlisted && !animate ? "block" : undefined,
              },
              "@bp3": {
                animation: animate
                  ? `${onShortlist()} 1.5s forwards`
                  : undefined,
                backgroundColor: shortlisted && !animate ? "$green" : undefined,
                [`${StyledTickIcon}`]: {
                  display: "block",
                },
                [`${StyledRemoveIcon}`]: {
                  display: "none",
                },
                [`&:hover ${StyledTickIcon}`]: {
                  display: shortlisted && !animate ? "none" : undefined,
                },
                [`&:hover ${StyledRemoveIcon}`]: {
                  display: shortlisted && !animate ? "block" : undefined,
                },
                "&:hover": {
                  backgroundColor:
                    shortlisted && !animate ? "$grey4" : undefined,
                  transition: "background-color 0.25s",
                },
              },
            }
          : {
              animation: reverseAnimate
                ? `${onShortlistRemove("isMobile")} 1.5s forwards`
                : undefined,
              opacity: reverseAnimate ? 1 : undefined,
              "@bp3": {
                animation: reverseAnimate
                  ? `${onShortlistRemove()} 1.5s forwards`
                  : undefined,
                [`&:hover ${LeftChecklistContainer}`]: {
                  transform: "translate(-2px, 1px) rotate(-15deg)",
                  transition: "transform 0.25s",
                },
                [`&:hover ${RightChecklistContainer}`]: {
                  transform: "translate(2px, -2px) rotate(4deg)",
                  transition: "transform 0.25s",
                },
                "&:hover": {
                  backgroundColor: "$grey1",
                },
              },
            },
        css,
      )}
      onAnimationEnd={() => {
        setAnimate(false);
        setReverseAnimate(false);
      }}
    >
      {shortlisted ? (
        <>
          <StyledTickIcon
            size={14}
            variant={"tick"}
            color={"white"}
            css={{
              animation:
                !reverseAnimate && shortlisted
                  ? `${onShortlistIcon()} 2s forwards`
                  : undefined,
              "@bp3": { animation: "none" },
            }}
          />
          <StyledRemoveIcon size={14} variant={"trash"} color={"white"} />
        </>
      ) : (
        !reverseAnimate && (
          <Box css={{ height: "18px", width: "18px" }}>
            <LeftChecklistContainer>
              <Icon size={14} variant={"checklistLeft"} color={"black"} />
            </LeftChecklistContainer>
            <RightChecklistContainer>
              <Icon size={14} variant={"checklistRight"} color={"black"} />
            </RightChecklistContainer>
          </Box>
        )
      )}
      <>
        {!reverseAnimate && (
          <Text
            variant="mini"
            css={{
              color: "inherit",
              display: shortlisted && !animate ? "none" : undefined,

              animation:
                shortlisted && animate
                  ? `${onShortlistText()} 2s forwards`
                  : undefined,
            }}
          >
            {shortlisted ? "Added" : "Shortlist"}
          </Text>
        )}
        {!animate && reverseAnimate && !shortlisted && (
          <StyledRemoveText
            css={{
              opacity: "0",
              width: "0px",
              height: "0px",
              position: "absolute",
              left: 0,
              top: 8,
              display: "flex",
              gap: "8px",
              pl: "15px",
              animation:
                !shortlisted && reverseAnimate
                  ? `${onShortlistTextRemove()} 2s forwards`
                  : undefined,
            }}
          >
            <Icon variant="trash" color="white" css={{ flexShrink: 0 }} />
            Removed
          </StyledRemoveText>
        )}

        {!animate && !reverseAnimate && shortlisted && (
          <Text
            color="white"
            css={{
              "@bp3": { display: "none" },
            }}
            variant="mini"
          >
            Remove
          </Text>
        )}
      </>
    </StyledShortlistButton>
  );
};
