import React, { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import amplitude from "amplitude-js";

import "minireset.css";
import "./index.css";
import { BrowserRouter, useHistory, useLocation } from "react-router-dom";
import {
  amplitudeApiKey,
  bugsnagApiKey,
  environmentName,
  isProd,
} from "./config";

import { App } from "./App";
import { attachLocalStorageItem } from "./utils/errors";

Bugsnag.start({
  apiKey: bugsnagApiKey,
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: environmentName,
  enabledReleaseStages: ["production", "staging"],
  redactedKeys: [
    /^password$/i,
    /^account_?number$/i,
    /^sort_?code$/i,
    /^iban$/i,
  ],
  onError: function (event) {
    attachLocalStorageItem(event, "draftTalent", "draft_talent");
    attachLocalStorageItem(event, "jobs.draftV2", "draft_job");
  },
});

amplitude.getInstance().init(amplitudeApiKey, undefined, {
  includeReferrer: true,
  includeUtm: true,
  includeGclid: true,
  includeFbclid: true,
});

const ErrorBoundary =
  (isProd && Bugsnag.getPlugin("react")?.createErrorBoundary(React)) ||
  React.Fragment;

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    // the browser remembers scroll positions when you go back
    // we don't want to break that
    if (history.action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

const container = document.getElementById("root");

if (!container) throw new Error("container not found!");

const root = createRoot(container);

root.render(
  <StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </StrictMode>,
);
