import {
  contentfulAccessToken,
  contentfulSpaceId,
  contentfulPreviewAccessToken,
} from "src/config";
import { ContentfulResEntity, ContentfulReqEntity } from "src/entities/";

const buildQueryString = (
  { contentType, ...req }: ContentfulReqEntity,
  previewApi: boolean,
) => {
  const token = previewApi
    ? contentfulPreviewAccessToken
    : contentfulAccessToken;

  const params = {
    /* eslint-disable @typescript-eslint/naming-convention */
    access_token: token,
    content_type: contentType,
    /* eslint-enable @typescript-eslint/naming-convention */
    ...req,
  };

  return new URLSearchParams(params).toString();
};

export class ContentfulApiError extends Error {}

export const contentfulRequest = async (request: ContentfulReqEntity) => {
  const { usePreviewApi, ...rest } = request;
  const apiSubdomain =
    usePreviewApi && usePreviewApi === "true" ? "preview" : "cdn";

  const queryString = buildQueryString(rest, usePreviewApi === "true");
  const url = `https://${apiSubdomain}.contentful.com/spaces/${contentfulSpaceId}/entries?${queryString}`;

  const response = await fetch(url);

  if (!response.ok) {
    throw new ContentfulApiError("Non-200 response from Contentful");
  }

  const json: ContentfulResEntity = await response.json();

  return json;
};
