import React from "react";
import { Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";

interface DetailProps {
  title: string;
  body?: string | null;
  fontWeight?: keyof typeof tokens.fontWeights;
  splitNewLines?: boolean;
  children?: React.ReactNode;
}

export const Detail = ({
  title,
  body,
  fontWeight = "regular",
  splitNewLines = false,
  children,
}: DetailProps) => {
  if (body === null) {
    return null;
  }

  let formattedBody = null;

  if (body && body.indexOf("\n") !== -1 && splitNewLines) {
    formattedBody = body.split("\n").map((paragraph, i) => (
      <Text css={{ mt: "£3" }} key={i}>
        {paragraph}
      </Text>
    ));
  } else {
    formattedBody = <Text css={{ fontWeight: fontWeight }}>{body}</Text>;
  }

  return (
    <Box css={{ mb: "$9" }}>
      <Text color="black" css={{ mb: "$2", fontWeight: "700" }} variant="meta">
        {title}
      </Text>

      {formattedBody}

      {children && children}
    </Box>
  );
};
