import React from "react";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import {
  LineItem,
  OutboundPaymentState,
  PaymentBatchState,
} from "src/graphql/types";
import { formatCurrency } from "src/utils/currencyFormatter";
import { Button, Link } from "src/ccl/navigation";

const LineItemDetails = ({
  lineItem,
  onDelete,
}: {
  lineItem: LineItem;
  onDelete?: () => void;
}) => {
  const paymentFailed =
    lineItem.outboundPayment?.state === OutboundPaymentState.Failed;
  const paymentBatch = lineItem.outboundPayment?.paymentBatch;
  const paymentIsSent = paymentBatch?.state === PaymentBatchState.Sent;
  const batchIsSealed = paymentBatch?.state === PaymentBatchState.Sealed;
  const colour = paymentIsSent ? "grey6" : "black";
  return (
    <Flex
      css={{
        py: "$4",
        borderTop: "1px solid $grey3",
        "&:last-child": {
          borderBottom: "1px solid $grey3",
        },
      }}
    >
      <Box css={{ flex: 1 }}>
        <Text color={colour}>
          {formatCurrency(lineItem.amount, lineItem.currency)}{" "}
          {lineItem.kind.toLowerCase().replace("_", " ")}
        </Text>

        <Text variant="meta" color="grey6">
          {lineItem.manuallyPaid && "Already paid: "}
          {lineItem.description}
        </Text>
      </Box>

      {paymentFailed ? (
        <Flex css={{ my: "auto" }}>
          <Icon
            variant="cross"
            size={16}
            color="red"
            css={{ my: "auto", pr: 5 }}
          />
          <Text variant="meta" color="red">
            Payment failed In{" "}
            <Link to="/dashboard/agent/payments" css={{ color: "inherit" }}>
              Batch #{lineItem.outboundPayment?.paymentBatch?.id}
            </Link>
          </Text>
        </Flex>
      ) : paymentIsSent ? (
        <Flex css={{ my: "auto" }}>
          <Icon
            variant="tick"
            size={16}
            color="green"
            css={{ my: "auto", pr: 5 }}
          />
          <Text variant="meta" color="green">
            Paid In{" "}
            <Link to="/dashboard/agent/payments" css={{ color: "inherit" }}>
              Batch #{lineItem.outboundPayment?.paymentBatch?.id}
            </Link>
          </Text>
        </Flex>
      ) : batchIsSealed ? (
        <Flex css={{ my: "auto" }}>
          <Icon
            variant="lock"
            size={24}
            color="grey6"
            css={{ my: "auto", pr: 5 }}
          />
        </Flex>
      ) : (
        <Box>
          {onDelete && (
            <Button onClick={onDelete} variant="unstyled">
              <Icon variant="cross" size={16} />
            </Button>
          )}
        </Box>
      )}
    </Flex>
  );
};

interface AdditionalPaymentsListProps {
  lineItems: LineItem[];
  onDelete?: (lineItemId: string) => void;
}

export const AdditionalPaymentsList = ({
  lineItems,
  onDelete,
}: AdditionalPaymentsListProps) => (
  <>
    {lineItems.map((lineItem) => (
      <LineItemDetails
        key={lineItem.id}
        lineItem={lineItem}
        onDelete={
          onDelete
            ? () => {
                onDelete?.(lineItem.id);
              }
            : undefined
        }
      />
    ))}
  </>
);
