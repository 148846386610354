import {
  action,
  Action,
  actionOn,
  ActionOn,
  thunk,
  Thunk,
  computed,
  Computed,
} from "easy-peasy";
import Bugsnag from "@bugsnag/js";
import amplitude from "amplitude-js";
import { get } from "lodash";
import { CurrentUserModel } from "./currentUser";
import { AUTH_QUERY, ME_QUERY } from "src/graphql/queries";
import { StoreModel } from "src/models";
import { getLocalStorageItem, updateLocalStorageItem } from "src/utils/storage";
import { graphQLClient } from "src/graphql/client";
import { Auth, Query, User, UserKind } from "src/graphql/types";
import { Mixpanel } from "src/utils/mixpanel";
import * as firebase from "src/libs/firebase";
import { SSO_AUTH_QUERY } from "src/graphql/queries/SsoAuth";
import { CREATE_USER_MUTATION } from "src/graphql/mutations/auth/CreateUser";

interface AuthReq {
  email: string;
  password: string;
}

interface StoredAuth {
  jwt: string;
}

interface SSOReq {
  providerId: firebase.EProvider;
  kind?: UserKind;
}

type AuthState =
  | "alreadyExist"
  | "unauthorized"
  | "error"
  | "success"
  | "ssoNotExist";

export type Status = "error" | "pending" | "loading" | "loaded";

type AuthSSoReq = {
  providerId: firebase.EProvider;
  idToken: string;
  nonce: string;
};

export interface AuthModel {
  authData: Auth | null;
  authState: AuthState | null;
  setAuthState: Action<CurrentUserModel, AuthState | null>;
  resetAuthState: Action<CurrentUserModel>;
  setAuthData: Action<CurrentUserModel, Auth | null>;
  accessToken: string | null;
  setAccessToken: Action<CurrentUserModel, string | null>;
  onSetAccessToken: ActionOn<CurrentUserModel>;
  initialised: boolean;
  loading: Computed<CurrentUserModel, boolean>;
  setInitialised: Action<CurrentUserModel, boolean>;
  loggedIn: Computed<CurrentUserModel, boolean | null>;
  restoreSession: Thunk<CurrentUserModel, { jwt: string }, void, StoreModel>;
  logIn: Thunk<CurrentUserModel, AuthReq, void, StoreModel>;
  logOut: Thunk<CurrentUserModel, boolean | void, void, StoreModel>;
  onAppMount: Thunk<CurrentUserModel, void, void, StoreModel>;
  configureThirdParties: ActionOn<CurrentUserModel>;
  getMe: Thunk<CurrentUserModel>;
  me: User | null;
  status: Status;
  setStatus: Action<CurrentUserModel, Status>;
  setMe: Action<CurrentUserModel, User | null>;
  reset: Thunk<CurrentUserModel>;
  resetAnalytics: ActionOn<CurrentUserModel>;
  signUpWith: Thunk<CurrentUserModel, SSOReq, void, StoreModel>;
  signInWith: Thunk<CurrentUserModel, SSOReq, void, StoreModel>;
  authenticateSsoUser: Thunk<CurrentUserModel, AuthSSoReq, void, StoreModel>;
  oauthProvider: firebase.EProvider | null;
  setOauthProvider: Action<CurrentUserModel, firebase.EProvider | null>;
}

export const authModel: AuthModel = {
  authData: null,
  authState: null,
  status: "pending",
  me: null,
  setStatus: action((state, payload) => {
    state.status = payload;
  }),
  setMe: action((state, payload) => {
    state.me = payload;
  }),
  setAuthState: action((state, payload) => {
    state.authState = payload;
  }),
  resetAuthState: action((state) => {
    state.authState = null;
  }),
  accessToken: null,
  setAuthData: action((state, payload) => {
    state.authData = payload;
  }),
  setAccessToken: action((state, payload) => {
    state.accessToken = payload;
  }),
  onSetAccessToken: actionOn(
    (actions) => actions.setAccessToken,
    (state) => {
      updateLocalStorageItem(
        "auth",
        JSON.stringify({ jwt: state.accessToken }),
      );
    },
  ),
  initialised: false,
  setInitialised: action((state, payload) => {
    state.initialised = payload;
  }),
  loading: computed((state) => state.status === "loading"),
  loggedIn: computed((state) =>
    state.initialised
      ? !!state.authData?.jwt && state.authData.jwt !== ""
      : null,
  ),
  onAppMount: thunk(async (actions, _payload, { getStoreActions }) => {
    const authJson = getLocalStorageItem("auth");
    const authResponse = safeJsonParse(authJson);

    if (isAuthResponse(authResponse)) {
      await actions.restoreSession(authResponse);
      getStoreActions().verticalConfigurations.request({ force: true });
      getStoreActions().features.request({ force: true });
    } else {
      actions.logOut(true);
    }

    actions.setInitialised(true);
  }),
  configureThirdParties: actionOn(
    (actions) => actions.setMe,
    (state) => {
      if (state.me) {
        Bugsnag.setUser(state.me.id, state.me.email, state.me.name);
        amplitude.getInstance().setUserId(state.me.id);
        Mixpanel.identify(state.me);
      }
    },
  ),
  resetAnalytics: actionOn(
    (actions) => actions.reset,
    () => {
      Mixpanel.resetUser();
    },
  ),
  restoreSession: thunk(async (actions, payload, { getState }) => {
    actions.setAccessToken(payload.jwt);
    actions.setAuthData({
      ...payload,
      unauthorized: false,
    });
    await actions.getMe();
    if (getState().status === "error") {
      actions.logOut(true);
    }
  }),
  logIn: thunk(async (actions, payload) => {
    actions.setStatus("loading");

    try {
      const result = await graphQLClient.query<Query>({
        query: AUTH_QUERY,
        variables: {
          email: payload.email,
          password: payload.password,
        },
      });

      if (result.error) {
        throw result.error;
      }

      const auth = result.data.auth;

      if (auth) {
        actions.setAuthData(auth);
      }

      if (auth?.unauthorized) {
        actions.setAuthState("unauthorized");
      }

      if (auth?.jwt) {
        actions.setAccessToken(auth.jwt);
        actions.setAuthState("success");
        await actions.getMe();
      }

      actions.setStatus("loaded");
    } catch (e) {
      actions.setAuthState("error");
      actions.setStatus("error");
    }
  }),
  logOut: thunk(async (actions, forceReset, { getState, getStoreActions }) => {
    if (getState().loggedIn || forceReset) {
      actions.reset();
      graphQLClient.cache.evict({ id: "ROOT_QUERY" });
    }

    updateLocalStorageItem("auth", null);
    actions.setAccessToken(null);
    actions.setAuthData(null);
    actions.setAuthState(null);
    getStoreActions().verticalConfigurations.reset();
  }),
  getMe: thunk(async (actions) => {
    actions.setStatus("loading");

    try {
      const result = await graphQLClient.query<Query>({
        query: ME_QUERY,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
      });

      if (result.error) {
        throw result.error;
      }

      const me = result.data.me;
      if (!me) {
        actions.setStatus("error");
      } else {
        actions.setMe(me);
        actions.setStatus("loaded");
      }
    } catch (e) {
      actions.setAuthState("error");
      actions.setStatus("error");
    }
  }),
  signUpWith: thunk(async (actions, payload) => {
    actions.setStatus("loading");

    try {
      const signUpResult = await firebase.auth(payload.providerId);

      if (signUpResult?.user?.email) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const idToken = (signUpResult as any)?._tokenResponse?.oauthIdToken;

        try {
          await graphQLClient.mutate({
            mutation: CREATE_USER_MUTATION,
            variables: {
              email: signUpResult?.user?.email,
              registeringAs: payload.kind,
              firstName: "",
              lastName: "",
              ssoVerified: true,
            },
          });

          actions.authenticateSsoUser({
            idToken,
            providerId: payload.providerId,
            nonce: signUpResult?.nonce,
          });
        } catch (err: any) {
          // auto login if the user is already exist in our database
          if (err?.message === "Email has already been taken") {
            actions.authenticateSsoUser({
              idToken,
              providerId: payload.providerId,
              nonce: signUpResult?.nonce,
            });
            return;
          }
        }

        actions.setEmail(signUpResult.user.email);
      }
    } catch (e) {
      actions.setAuthState("error");
      actions.setStatus("error");
    }
  }),
  reset: thunk((actions) => {
    actions.setMe(null);
    actions.setStatus("pending");
  }),
  signInWith: thunk(async (actions, payload) => {
    actions.setStatus("loading");

    try {
      const signInResult = await firebase.auth(payload.providerId);

      if (signInResult) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const idToken = (signInResult as any)?._tokenResponse?.oauthIdToken;

        actions.authenticateSsoUser({
          idToken,
          providerId: payload.providerId,
          nonce: signInResult.nonce,
        });
      } else {
        actions.setAuthState("error");
        actions.setStatus("error");
      }
    } catch (e) {
      actions.setAuthState("error");
      actions.setStatus("error");
    }
  }),
  authenticateSsoUser: thunk(async (actions, payload) => {
    if (!payload?.idToken) {
      actions.setAuthState("error");
      return;
    }

    try {
      const result = await graphQLClient.query<Query>({
        query: SSO_AUTH_QUERY,
        variables: {
          idToken: payload?.idToken,
          providerId: payload.providerId,
          nonce: payload?.nonce,
        },
      });

      const auth = get(result, "data.authSso");

      if (auth) {
        actions.setAuthData(auth);
      }

      if (auth?.unauthorized) {
        actions.setAuthState("unauthorized");
      }
      if (auth?.jwt) {
        actions.setAccessToken(auth.jwt);
        actions.setAuthState("success");
        await actions.getMe();
      }

      actions.setStatus("loaded");
    } catch (error: any) {
      if (error?.message === "Account not found") {
        actions.setAuthState("ssoNotExist");
        return;
      }
    }
  }),
  oauthProvider: null,
  setOauthProvider: action((state, payload) => {
    state.oauthProvider = payload;
  }),
};

const isAuthResponse = (payload: unknown): payload is StoredAuth => {
  if (payload && (payload as StoredAuth).jwt) return true;
  return false;
};

const safeJsonParse = (str: string | null): object | null => {
  if (typeof str !== "string") return null;
  try {
    return JSON.parse(str);
  } catch (err) {
    return null;
  }
};
