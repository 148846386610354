import { gql } from "@apollo/client";
import {
  META_FRAGMENT,
  USER_ASSET_FRAGMENT,
  USER_BASE_FRAGMENT,
  USER_PROFILE_FRAGMENT,
} from "src/graphql/fragments";

export const BOOKER_SAVED_TALENT_COLLECTION_MEMBERS = gql`
  query BookerSavedTalentCollectionMembers(
    $collectionId: ID!
    $vertical: TalentVertical
    $name: String
  ) {
    me {
      profile {
        ... on BookerProfile {
          savedTalentCollection(id: $collectionId) {
            id
            name
            members(vertical: $vertical, name: $name) {
              ...Meta
              edges {
                node {
                  ...UserBase
                  ...UserAsset
                  bookmarked
                  groups {
                    name
                  }
                  averageResponseTimeMinutes
                  profile {
                    ... on TalentProfile {
                      ...UserProfile
                      lastPortfolioUpload {
                        id
                        mediaUrl
                        width
                        height
                      }
                      lastPortfolioUploads(count: 8) {
                        id
                        mediaUrl
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${META_FRAGMENT}
  ${USER_BASE_FRAGMENT}
  ${USER_ASSET_FRAGMENT}
  ${USER_PROFILE_FRAGMENT}
`;
