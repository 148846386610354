import { gql } from "@apollo/client";

const FILTER_PROPERTIES_FRAGMENT = gql`
  fragment FilterProperties on Filter {
    name
    label
    kind
    contexts
    minValue
    maxValue
    verticalSpecific
    dataType
    metricMeasurementDisplay
    imperialMeasurementDisplay
    excludedGenders
    isClearable
    options {
      label
      value
      colour
      description
    }
  }
`;
const FILTERS_FRAGMENT = gql`
  fragment VerticalConfigFilters on VerticalConfiguration {
    filters {
      ...FilterProperties
      labelChecked
      labelUnchecked
      checkedValue
      uncheckedValue
      helperText
      preferenceName
      rangeLabelPrefix
      expanded
      defaultOptions
      expandedTitleFilter {
        name
        label
        preferenceName
        kind
        contexts
        labelUnchecked
        labelChecked
        checkedValue
        uncheckedValue
      }
      childFilters {
        ...FilterProperties
        childFilters {
          ...FilterProperties
        }
      }
    }
    advancedFilters {
      ...FilterProperties
      labelChecked
      labelUnchecked
      checkedValue
      uncheckedValue
      helperText
      preferenceName
      rangeLabelPrefix
      expanded
      defaultOptions
      expandedTitleFilter {
        name
        label
        preferenceName
        kind
        contexts
        labelUnchecked
        labelChecked
        checkedValue
        uncheckedValue
      }
      childFilters {
        ...FilterProperties
      }
    }
  }

  ${FILTER_PROPERTIES_FRAGMENT}
`;

const FIELDS_FRAGMENT = gql`
  fragment VerticalConfigFields on VerticalConfiguration {
    fields {
      name
      label
      maxValue
      minValue
      filterable
      multiple
      dataType
      inputType
      excludedGenders
      unit
      required
      requiredGenders
      options {
        colour
        description
        label
        value
        imageUrl
        icon
      }
    }
  }
`;

const ORDERING_FRAGMENT = gql`
  fragment VerticalConfigOrdering on VerticalConfiguration {
    orderingOptions {
      label
      field
      direction
    }
  }
`;

const JOB_CREATION_SCHEMA_FRAGMENT = gql`
  fragment VerticalConfigJobCreationSchema on VerticalConfiguration {
    jobCreationSchema {
      detailsFormFields {
        name
        props {
          placeholder
          checkboxOptions
          label
          selectOptions {
            label
            value
          }
        }
      }
    }
  }
`;

const JOB_VERTICAL_DETAILS_FIELDS = gql`
  fragment VerticalConfigJobVerticalDetailsFields on VerticalConfiguration {
    jobVerticalDetailsFields {
      name
      props {
        placeholder
        checkboxOptions
        label
      }
    }
  }
`;

const PROFILE_ATTRIBUTES_FRAGMENT = gql`
  fragment VerticalConfigProfileAttributes on VerticalConfiguration {
    profileAttributes {
      field
      icon
      label
      verticalSpecific
    }
  }
`;

export const VERTICAL_CONFIGURATION = gql`
  query VerticalConfigurations {
    verticalConfigurations {
      cardType
      id
      image
      selectionImage
      label
      maxShortlistCount
      name
      newVertical
      ...VerticalConfigFilters
      ...VerticalConfigFields
      ...VerticalConfigOrdering
      ...VerticalConfigJobCreationSchema
      ...VerticalConfigJobVerticalDetailsFields
      ...VerticalConfigProfileAttributes
    }
  }
  ${FILTERS_FRAGMENT}
  ${FIELDS_FRAGMENT}
  ${ORDERING_FRAGMENT}
  ${JOB_CREATION_SCHEMA_FRAGMENT}
  ${JOB_VERTICAL_DETAILS_FIELDS}
  ${PROFILE_ATTRIBUTES_FRAGMENT}
`;
