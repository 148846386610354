import React from "react";
import type * as Stitches from "@stitches/react";
import { endOfDay, isFuture } from "date-fns";
import { size } from "lodash";
import { Loading, SimpleJobListItem } from "src/components";
import { Icon, Text } from "src/ccl/document";
import { Button, Link } from "src/ccl/navigation";
import { Box, Flex } from "src/ccl/layout";
import { sortByCreatedAt } from "src/utils/array";
import { Job, JobState } from "src/graphql/types";
import {
  SortDirection,
  SortOrderInput,
  User,
} from "src/graphql/generated/types";
import { TableHeader, TableHeaderItem } from "src/ccl/document/table";
import {
  currentDirectionFromSortOrder,
  currentFieldName,
} from "src/utils/sorting";

interface JobHistoryProps {
  user: User;
  loading: boolean;
  onLoadMore: () => void;
  hasMore: boolean;
  agencyId?: string;
  slug: string | undefined;
  withPaymentLink: boolean;
  containerCss?: Stitches.CSS;
  showHeading?: boolean;
  sortOrder?: SortOrderInput;
  onSortOrderChange?: (sortOrder: SortOrderInput) => void;
  isLegacy?: boolean;
}

const TableHeaderItemLabel = ({ text }: { text: string }) => (
  <Text variant="mini" color="grey6">
    {text}
  </Text>
);

const CustomTableHeader = ({
  sortOrder,
  setSortOrder,
}: {
  sortOrder: SortOrderInput;
  setSortOrder: (column: string, sortDirection: SortDirection) => void;
}) => (
  <TableHeader>
    <TableHeaderItem
      widthPercentage={40}
      fieldName="name"
      active={currentFieldName("name", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder("name", sortOrder)}
      onSortChange={setSortOrder}
    >
      <TableHeaderItemLabel text="Job" />
    </TableHeaderItem>
    <TableHeaderItem
      widthPercentage={40}
      fieldName="created_at"
      active={currentFieldName("created_at", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder(
        "created_at",
        sortOrder,
      )}
      onSortChange={setSortOrder}
    >
      <TableHeaderItemLabel text="Jobs date" />
    </TableHeaderItem>
    <TableHeaderItem
      widthPercentage={20}
      fieldName="state"
      active={currentFieldName("state", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder("state", sortOrder)}
      onSortChange={setSortOrder}
    >
      <TableHeaderItemLabel text="Status" />
    </TableHeaderItem>
  </TableHeader>
);

export const JobHistoryList = ({
  user,
  loading,
  onLoadMore,
  hasMore,
  agencyId,
  slug,
  withPaymentLink,
  containerCss,
  showHeading = true,
  sortOrder,
  onSortOrderChange,
  isLegacy = true,
}: JobHistoryProps) => {
  const jobs = user?.jobs?.edges || [];

  const shouldIncludeJob = (job: Job | undefined) => {
    if (job === undefined) {
      return false;
    }

    return job.state === JobState.Approved
      ? isFuture(endOfDay(new Date(job.endDate)))
      : true;
  };

  const jobHistory = sortByCreatedAt(
    jobs
      .map((edge) => edge?.node)
      .filter((job) => shouldIncludeJob(job)) as Job[],
  );

  const displayJobHistory = () => {
    if (jobHistory.length > 0) {
      return (
        <>
          {sortOrder && onSortOrderChange && (
            <CustomTableHeader
              sortOrder={sortOrder}
              setSortOrder={(column, sortDirection) =>
                onSortOrderChange({ column, direction: sortDirection })
              }
            />
          )}
          {jobHistory.map((job, index) => (
            <Box
              key={`job-history-job-${index}`}
              css={{
                borderBottomWidth: 1,
                borderBottomColor: "$grey3",
                borderBottomStyle: "solid",
                py: "$6",
              }}
            >
              <SimpleJobListItem
                job={job}
                jobLink={(slug) => `/dashboard/agent/jobs/${slug}`}
                context={
                  user.profile.__typename === "BookerProfile"
                    ? "client"
                    : "talent"
                }
              />
            </Box>
          ))}
          {loading && (
            <Box>
              <Loading />
            </Box>
          )}
          {!loading && hasMore && (
            <Flex css={{ justifyContent: "center" }}>
              <Button variant="primaryCta" onClick={onLoadMore}>
                Load more
              </Button>
            </Flex>
          )}
        </>
      );
    } else {
      return (
        <Flex
          css={{
            my: "$7",
            flexDirection: "column",
            alignItems: "center",
            gap: "18px",
          }}
        >
          <Icon variant="empty" color="grey6" size={40} />
          <Flex
            css={{
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Text variant="nh4" color="grey5">
              NO JOBS YET
            </Text>
            <Text variant="b2" color="grey6" css={{ fontWeight: "$regular" }}>
              {user.name} has no active shortlistings, or confirmed/completed
              jobs.
            </Text>
          </Flex>
        </Flex>
      );
    }
  };

  return (
    <>
      <Flex css={containerCss || {}}>
        {showHeading && (
          <Flex css={{ gap: "$4" }}>
            <Text variant="nh3">{isLegacy ? "Job" : "Booking"} history</Text>
            <Text as="span" variant="b1">
              {`${size(jobHistory) || "No"} ${
                size(jobHistory) === 1 ? "job" : "jobs"
              } found`}
            </Text>
          </Flex>
        )}
        {withPaymentLink && jobHistory.length > 0 && (
          <>
            <Flex css={{ mt: "$3", "@bp2": { mt: "$0" } }}>
              <Icon
                variant="moneyBudget"
                size={14}
                css={{ mr: "$3", mt: "$2" }}
              />
              <Link
                to={
                  agencyId
                    ? `/dashboard/agent/agencies/${agencyId}/talent/${slug}/edit/payments`
                    : `/dashboard/agent/talent/${slug}/edit/payments`
                }
              >
                View payment history
              </Link>
            </Flex>
          </>
        )}
      </Flex>
      <Box css={{ mt: "$6", pb: "$9" }}>{displayJobHistory()}</Box>
    </>
  );
};
