import { Radio, Textarea } from "src/ccl/data-entry";
import { Box, Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { JobTalentRejectionReason, KeyValue } from "src/graphql/types";

interface JobTalentRejectionReasonsProps {
  rejectionReasons: KeyValue[];
  onReasonChange: (reason: string) => void;
  onNoteChange: (note: string) => void;
  selectedRejectionReason: string | undefined;
}

export const JobTalentRejectionReasons = ({
  rejectionReasons,
  onReasonChange,
  onNoteChange,
  selectedRejectionReason,
}: JobTalentRejectionReasonsProps) => (
  <>
    {rejectionReasons.map((reason) => (
      <Flex
        key={`edit-creative-rejection-reason-${reason.key}`}
        css={{
          gap: "$4",
          ">*:not(:last-child)": {
            mb: "$5",
          },
        }}
      >
        <Radio
          size="sm"
          id={`rejection-reason-${reason.key}`}
          value={reason.key}
          name="creative-rejection-reason"
          checked={selectedRejectionReason === reason.key}
          onChange={(e) => {
            onReasonChange(e.target.value);
          }}
        />
        <Box
          as="label"
          htmlFor={`rejection-reason-${reason.key}`}
          css={{ cursor: "pointer" }}
        >
          <Text variant="b2">{reason.value}</Text>
        </Box>
      </Flex>
    ))}
    {selectedRejectionReason === JobTalentRejectionReason.Other && (
      <Textarea
        variant="rebrand"
        data-test-id="EditCreativeResponseOtherTextArea"
        rows={3}
        onChange={(e) => onNoteChange(e.target.value)}
      />
    )}
  </>
);
