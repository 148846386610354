import { gql } from "@apollo/client";

export const SET_INVOICE_FEE_MUTATION = gql`
  mutation SetInvoiceFee($invoiceId: ID!, $amount: Float!, $feeKind: FeeKind!) {
    setInvoiceFee(invoiceId: $invoiceId, amount: $amount, feeKind: $feeKind) {
      id
      invoiceNumber
      paymentReference
      paymentMethod
      state
      invoiceDate
      dueDate
      pdfUrl
      total
      currency
      feeItems {
        kind
        amount
        description
      }
    }
  }
`;
