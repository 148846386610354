import { gql } from "@apollo/client";
import { LINE_ITEM_FRAGMENT } from "src/graphql/fragments";

export const ADD_LINE_ITEM_MUTATION = gql`
  mutation AddLineItem(
    $jobTalentId: ID!
    $kind: LineItemKind!
    $amount: Float!
    $currency: String!
    $description: String!
    $manuallyPaid: Boolean!
  ) {
    addLineItem(
      jobTalentId: $jobTalentId
      kind: $kind
      amount: $amount
      currency: $currency
      description: $description
      manuallyPaid: $manuallyPaid
    ) {
      ...LineItemDetails
      outboundPayment {
        id
      }
    }
  }
  ${LINE_ITEM_FRAGMENT}
`;
