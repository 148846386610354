import React from "react";
import { Box } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";

interface StackedBorderContainerProps {
  color?: `$${keyof typeof tokens.colors}`;
  children?: React.ReactNode;
}

export const StackedBorderContainer = ({
  color = "$black",
  children,
  ...props
}: StackedBorderContainerProps) => (
  <div {...props}>
    <Box
      css={{
        border: `4px solid ${color}`,
        position: "relative",
        zIndex: "$100",
        "&::before": {
          content: '" "',
          width: "100%",
          height: "100%",
          border: `4px solid ${color}`,
          transform: "rotate(-5deg)",
          position: "absolute",
          zIndex: -1,
        },
        mb: "$8",
      }}
    >
      {children}
    </Box>
  </div>
);
