import React from "react";
import * as Stitches from "@stitches/react";
import { Pill } from "src/ccl/blocks/pill/Pill";
import { Input } from "src/ccl/data-entry";
import { AvailableIcon, Icon } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";
import { Button } from "src/ccl/navigation";

export const TextInputPill = ({
  icon,
  placeholder,
  onChange,
  name,
  active,
  value,
  css = {},
  withinModal = false,
  showClearButton = false,
}: {
  icon?: AvailableIcon;
  placeholder: string;
  onChange: (value: string) => void;
  name: string;
  active: boolean;
  value: string;
  withinModal?: boolean;
  showClearButton?: boolean;
  css?: Stitches.CSS;
}) => (
  <Pill
    variant="rebrand"
    css={mergeCss(
      {
        position: "relative",
        width: withinModal ? "100%" : "min-content",
        margin: withinModal ? "$0, $0, $0, $0" : undefined,
        height: withinModal ? "50px" : undefined,
        backgroundColor: active ? "$black" : "inherit",
        "&:focus-within": {
          borderColor: "$black",
        },
      },
      css,
    )}
  >
    <Flex css={{ alignItems: "center", width: "inherit" }}>
      {icon && (
        <Icon
          variant={icon}
          size={withinModal ? 20 : 24}
          css={{
            flexShrink: 0,
            display: "flex",
          }}
          color={"grey6"}
        />
      )}
      <Input
        name={name}
        type="text"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        css={{
          color: active ? "$white" : "$black",
          backgroundColor: active ? "$black" : "$white",
          height: "30px",
          border: active ? undefined : "none",
          "::placeholder": {
            color: "grey6",
          },
          "@bp4": { height: "$6" },
        }}
      />
    </Flex>
    {showClearButton && active && (
      <Button
        variant="unstyled"
        onClick={() => onChange("")}
        css={{
          position: "absolute",
          right: "10px",
        }}
      >
        <Icon variant="cross" size={16} color={"grey6"} />
      </Button>
    )}
  </Pill>
);
