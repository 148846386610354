import { CSS } from "@stitches/react";
import React from "react";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";
import { iconSizes, tokens } from "src/ccl/stitches/theme";

export interface FormTabProps {
  active: boolean;
  icon: AvailableIcon;
  iconSize?: keyof typeof iconSizes;
  title: string;
  titleCondensed?: string;
  boxCss?: CSS;
  rebrand?: boolean;
}

export const FormTab = ({
  active,
  icon,
  iconSize = 20,
  title,
  titleCondensed = title,
  boxCss = {},
  rebrand = false,
}: FormTabProps) => {
  let notActiveColor = "white";

  if (rebrand) {
    notActiveColor = "grey4";
  }
  return (
    <Flex
      css={mergeCss(
        {
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: active ? "$grey1" : "$black",
          padding: "$4",
        },
        boxCss,
      )}
    >
      <Icon
        variant={icon}
        size={iconSize}
        color={
          active ? "black" : (notActiveColor as keyof typeof tokens.colors)
        }
        css={{ mb: rebrand ? "0" : "$3" }}
      />
      <Text
        color={
          active ? "black" : (notActiveColor as keyof typeof tokens.colors)
        }
        variant={{ "@initial": "meta", "@bp2": "body" }}
        css={{ display: "none", "@bp2": { display: "block" } }}
      >
        {title}
      </Text>
      <Text
        color={
          active ? "black" : (notActiveColor as keyof typeof tokens.colors)
        }
        variant={{ "@initial": "meta", "@bp2": "body" }}
        css={{ display: "block", "@bp2": { display: "none" } }}
      >
        {titleCondensed}
      </Text>
    </Flex>
  );
};
