import type * as Stitches from "@stitches/react";
import { ResponsiveValue, Space } from "./types";

export const utils = {
  // Abbreviated margin properties
  m: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    margin: value,
  }),
  mt: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    marginTop: value,
  }),
  mr: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    marginRight: value,
  }),
  mb: (value: Stitches.ScaleValue<"space"> | number | string | boolean) => ({
    marginBottom: value,
  }),
  ml: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    marginLeft: value,
  }),
  mx: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    marginLeft: value,
    marginRight: value,
  }),
  my: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    marginTop: value,
    marginBottom: value,
  }),
  p: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    padding: value,
  }),
  pt: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    paddingTop: value,
  }),
  pr: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    paddingRight: value,
  }),
  pb: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    paddingBottom: value,
  }),
  pl: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    paddingLeft: value,
  }),
  px: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    paddingLeft: value,
    paddingRight: value,
  }),
  py: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    paddingTop: value,
    paddingBottom: value,
  }),

  // A property for applying width/height together
  size: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    width: value,
    height: value,
  }),

  w: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    width: value,
  }),
  h: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    height: value,
  }),
  bg: (value: Stitches.PropertyValue<"backgroundColor">) => ({
    backgroundColor: value,
  }),
  // bg: responsiveValue<t.Colors>("backgroundColor"),
  //

  gridColumns: (value: ResponsiveValue<string | number> | undefined) => ({
    gridTemplateColumns:
      typeof value === "number" ? `repeat(${value}, 1fr)` : value,
  }),
  gridGap: (value: ResponsiveValue<Space>) => ({
    gap: value,
  }),

  // A property to apply linear gradient
  linearGradient: (value: Stitches.PropertyValue<"backgroundImage">) => ({
    backgroundImage: `linear-gradient(${value})`,
  }),

  spaceX: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    "& > *": {
      marginLeft: value,
      "&:first-child": {
        marginLeft: 0,
      },
    },
  }),
  spaceY: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    "& > *": {
      marginTop: value,
      "&:first-child": {
        marginTop: 0,
      },
    },
  }),

  separator: (symbol: string) => ({
    "& > *": {
      "&::before": {
        content: `"${symbol}"`,
      },
      "&:first-child::before": {
        display: "none",
      },
    },
  }),

  clearfix: () => ({
    "::after": {
      content: "",
      display: "table",
      clear: "both",
    },
  }),

  // An abbreviated property for border-radius
  br: (value: Stitches.ScaleValue<"space"> | number | string) => ({
    borderRadius: value,
  }),
};
