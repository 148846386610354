import React from "react";
import { ExternalLink, Link } from "src/ccl/navigation";

const AnchorRegex = /(<a href=(?:'|")([^"']+)(?:'|")>([^<]+)<\/a>)/g;

// Takes a string with some <a> tags in it, and converts those tags to
// Link or ExternalLink components
// The `origin` param determines whether the link is internal or external:
// `window.location.origin` is a good value for this
export const parseLinks = (
  text: string,
  origin: string,
  externaLinkEventName: string,
) => {
  const tokens = text.split(AnchorRegex);
  const result = [];

  for (let i = 0; i < tokens.length; i++) {
    if (tokens[i].startsWith("<a")) {
      // tokens[i] is the whole anchor tag
      // tokens[i+1] is the url
      // tokens[i+2] is the text
      const url = tokens[i + 1];
      const text = tokens[i + 2];

      if (url.startsWith(origin)) {
        result.push(
          <Link key={i} to={url.replace(origin, "")}>
            {text}
          </Link>,
        );
      } else {
        result.push(
          <ExternalLink key={i} to={url} eventName={externaLinkEventName}>
            {text}
          </ExternalLink>,
        );
      }

      // Skip over the url and text to the next relevant element
      i += 2;
    } else {
      if (tokens[i].trim() !== "") {
        result.push(tokens[i]);
      }
    }
  }

  return result;
};
