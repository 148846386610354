import * as Yup from "yup";
import { PortfolioImageCategory } from "src/graphql/types";

export const PortfolioCategoryValidation = (
  existingCategories: PortfolioImageCategory[],
  initialName?: string,
) =>
  Yup.object().shape({
    name: Yup.string()
      .required("Required")
      .test("Name is invalid", "Category name must be unique", (newName) => {
        if (newName) {
          return !existingCategories
            .map(({ name }) => name)
            .filter((name) => (initialName ? name !== initialName : true))
            .includes(newName);
        }
        return true;
      }),
  });
