import React from "react";
import { Grid } from "src/ccl/layout";
import { tokens, iconSizes } from "src/ccl/stitches/theme";
import { Icon } from "src/ccl/document";

interface BrandBannerProps {
  backgroundColor?: `$${keyof typeof tokens.colors}`;
  iconColor?: keyof typeof tokens.colors;
  iconSize?: keyof typeof iconSizes;
  variant?: "homepage" | "default";
}

export const BrandBanner = ({
  backgroundColor = "$white",
  iconColor = "black",
  iconSize = 80,
  variant = "default",
}: BrandBannerProps) => (
  <Grid
    css={{
      gridColumns: "1fr 1fr",
      gridGap: "$0",
      "@bp2": { gridColumns: "1fr 1fr 1fr 1fr 1fr 1fr" },
      backgroundColor: backgroundColor,
      justifyItems: "center",
    }}
  >
    {variant === "homepage" ? (
      <>
        <Icon
          variant="balenciaga"
          size={120}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="farfetch"
          size={120}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="harrods"
          size={100}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="drMartens"
          size={iconSize}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="goodhood"
          size={120}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="dazed"
          size={100}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
      </>
    ) : (
      <>
        <Icon
          variant="nike"
          size={iconSize}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="burberry"
          size={100}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="saintLaurent"
          size={120}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="adidas"
          size={iconSize}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="depop"
          size={iconSize}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
        <Icon
          variant="vogue"
          size={iconSize}
          color={iconColor}
          css={{ alignSelf: "center" }}
        />
      </>
    )}
  </Grid>
);
