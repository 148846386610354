import React from "react";
import { CSS } from "@stitches/react";
import { LegacyRadioSelect } from "./LegacyRadioSelect";
import { ProfileRadioSelect } from "src/ccl/data-entry/input/ProfileRadioSelect";
import { Box, Grid } from "src/ccl/layout";
import { useFeatures } from "src/hooks";
import { FieldOption } from "src/graphql/types";
import { mergeCss } from "src/ccl";

export interface RadioSelectProps {
  name?: string;
  label?: string;
  optional?: boolean;
  options: FieldOption[];
  selectedOption: string;
  setSelectedOption: (val: string) => void;
  gridCss?: CSS;
}

export const RadioSelect = ({
  name,
  label,
  options,
  selectedOption,
  setSelectedOption,
  optional = false,
  gridCss = {},
}: RadioSelectProps) => {
  const { featureEnabled } = useFeatures();
  return (
    <Box>
      {featureEnabled("tpa_updated_creative_upload") ? (
        <Grid
          css={mergeCss(
            {
              gridGap: "$4",
            },
            gridCss,
          )}
        >
          {options.map((option) => (
            <ProfileRadioSelect
              key={option.value}
              option={option}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              name={name}
            />
          ))}
        </Grid>
      ) : (
        <LegacyRadioSelect
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          optional={optional}
          name={name}
          label={label}
        />
      )}
    </Box>
  );
};
