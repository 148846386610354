import React from "react";
import type * as Stitches from "@stitches/react";
import { mergeCss } from "src/ccl/stitches";
import { AvailableIcon, Icon, Text, TextVariants } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { iconSizes, tokens } from "src/ccl/stitches/theme";

export const ActionItem = ({
  icon,
  text,
  onClick,
  iconSize = 14,
  color = "black",
  textVariant = "meta",
  css = {},
  iconCss = {},
}: {
  icon: AvailableIcon;
  text: string;
  iconSize?: keyof typeof iconSizes;
  color?: keyof typeof tokens.colors;
  textVariant?: keyof typeof TextVariants;
  textColor?: keyof typeof tokens.colors;
  onClick?: () => void;
  css?: Stitches.CSS;
  iconCss?: Stitches.CSS;
}) => (
  <Flex css={{ alignItems: "center", cursor: "pointer" }} onClick={onClick}>
    <Icon
      variant={icon}
      size={iconSize}
      css={mergeCss({ mr: "$3" }, iconCss)}
      color={color}
    />
    <Text
      variant={textVariant}
      color={color}
      css={mergeCss(
        {
          textDecoration: "underline",
          mr: "$6",
        },
        css,
      )}
    >
      {text}
    </Text>
  </Flex>
);
