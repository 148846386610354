import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Toggle } from "src/ccl/data-entry";
import { Icon, Modal, ModalProps, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { UPDATE_AGENCY_TALENT_MUTATION } from "src/graphql/mutations";
import {
  Mutation,
  MutationUpdateAgencyTalentArgs,
  TalentProfile,
  User,
} from "src/graphql/types";
import { useStoreModel } from "src/hooks";

interface VisibilityPreferencesModalProps extends ModalProps {
  talent: User;
}

export const VisibilityPreferencesModal = ({
  isOpen,
  onClose,
  talent,
}: VisibilityPreferencesModalProps) => {
  const profile = talent.profile as TalentProfile;

  const { isFirstPartyAgent } = useStoreModel("currentUser");

  const [published, setPublished] = useState(profile.published || false);
  const [isContactSelect, setIsContactSelect] = useState(
    profile.isContactSelect || false,
  );
  const [isContactOpen, setIsContactOpen] = useState(
    profile.isContactOpen || false,
  );

  const [updateAgencyTalent, { loading }] = useMutation<
    Mutation,
    MutationUpdateAgencyTalentArgs
  >(UPDATE_AGENCY_TALENT_MUTATION);

  const handleSave = async () => {
    await updateAgencyTalent({
      variables: {
        talentId: talent.id,
        talentProfile: {
          published,
          isContactSelect,
          isContactOpen,
        },
      },
    });
    onClose && onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Set visibility preferences for ${talent.name}`}
      titleVariant="nh3"
      titleCss={{
        marginBottom: 0,
        fontSize: "$22 !important",
      }}
      showCloseButton
      withBodyPadding={false}
    >
      <Flex css={{ padding: "18px", gap: "$5", flexDirection: "column" }}>
        <Flex css={{ gap: "26px", alignItems: "start" }}>
          <Box css={{ pt: "$2" }}>
            <Toggle
              id="HideFromAgency"
              checked={!published}
              onCheck={() => setPublished(false)}
              onUncheck={() => setPublished(true)}
              iconChecked={<Icon variant="check" size={10} />}
              iconUnchecked={<Icon variant="cross" size={10} color="white" />}
              uncheckedGreyBackground
              height={21}
            />
          </Box>
          <Flex css={{ flexDirection: "column", gap: "$3" }}>
            <Text variant="b2Bold">Hide from Agency homepage</Text>
            <Text variant="b2">Talent will be hidden from agency homepage</Text>
          </Flex>
        </Flex>
        {isFirstPartyAgent && (
          <>
            <Flex css={{ gap: "26px", alignItems: "start" }}>
              <Box css={{ pt: "$2" }}>
                <Toggle
                  id="HideFromContactOpen"
                  checked={!isContactOpen}
                  onCheck={() => setIsContactOpen(false)}
                  onUncheck={() => setIsContactOpen(true)}
                  iconChecked={<Icon variant="check" size={10} />}
                  iconUnchecked={
                    <Icon variant="cross" size={10} color="white" />
                  }
                  uncheckedGreyBackground
                  height={21}
                />
              </Box>
              <Flex css={{ flexDirection: "column", gap: "$3" }}>
                <Text variant="b2Bold">Hide from Contact Open</Text>
                <Text variant="b2">
                  Talent will be hidden from clients on Contact Open
                </Text>
              </Flex>
            </Flex>
            <Flex css={{ gap: "26px", alignItems: "start" }}>
              <Box css={{ pt: "$2" }}>
                <Toggle
                  id="HideFromContactSelect"
                  checked={!isContactSelect}
                  onCheck={() => setIsContactSelect(false)}
                  onUncheck={() => setIsContactSelect(true)}
                  iconChecked={<Icon variant="check" size={10} />}
                  iconUnchecked={
                    <Icon variant="cross" size={10} color="white" />
                  }
                  uncheckedGreyBackground
                  height={21}
                />
              </Box>
              <Flex css={{ flexDirection: "column", gap: "$3" }}>
                <Text variant="b2Bold">Hide from Contact Select</Text>
                <Text variant="b2">
                  Talent is hidden from clients on Contact Select by default. To
                  unhide, a request must be sent to a Contact agent for
                  approval.
                </Text>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      <Flex
        css={{
          padding: "18px",
          justifyContent: "space-between",
          flexDirection: "column-reverse",
          gap: "18px",
          "@sm": {
            flexDirection: "row",
          },
        }}
      >
        <Button
          data-test-id="ClearAllButton"
          variant="secondaryCta"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-test-id="ShowJobButton"
          variant="primaryCta"
          onClick={handleSave}
          disabled={loading}
        >
          Save changes
        </Button>
      </Flex>
    </Modal>
  );
};
