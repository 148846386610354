import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";

export const MobileFilterButton = ({
  text,
  icon,
  borderRight,
  onClick,
  suffix,
}: {
  text: string;
  icon: AvailableIcon;
  borderRight?: boolean;
  onClick: () => void;
  suffix?: number;
}) => {
  return (
    <Flex
      data-test-id={`MobileFilterButton-${text}`}
      css={{
        py: "$2",
        justifyContent: "space-around",
        borderRight: borderRight ? "1px solid $grey2" : "unset",
        "&:hover": { cursor: "pointer" },
      }}
      onClick={onClick}
    >
      <Flex css={{ gap: "$4" }}>
        <Icon css={{ my: "auto" }} variant={icon} size={18} />
        <Text css={{ lineHeight: "30px", fontSize: "18px" }}>{text}</Text>
        {suffix && (
          <Box
            css={{
              backgroundColor: "$black",
              color: "$white",
              borderRadius: "$round",
              my: "auto",
              width: 22,
              height: 22,
            }}
          >
            {suffix}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
