import React from "react";
import { Link } from "src/ccl/navigation";
import { Grid } from "src/ccl/layout";
import { Text } from "src/ccl/document";

interface SubLevelProps {
  linkTo: string;
  title: string;
  isSelected: boolean;
  onClick?: () => void;
}

export const SubLevel = ({
  linkTo,
  title,
  isSelected,
  onClick,
}: SubLevelProps) => {
  return (
    <Grid
      css={{
        borderLeft: "1px solid $grey3",
        pt: "12px",
        pb: "12px",
        "&:first-child": {
          pt: "18px",
        },
        "&:last-child": {
          pb: "0px",
          borderLeft: "1px solid $grey3",
          borderImage:
            "linear-gradient(to bottom, $grey3 67%, transparent 40%) 1 100%",
        },
        gridColumns: "14px auto",
        gap: "$4",
        alignItems: "center",
      }}
    >
      <hr
        style={{
          borderColor: "#cfcfcf",
          borderStyle: "solid",
          borderWidth: "1px 0px 0px 0px",
        }}
      />
      <Link
        to={linkTo}
        css={{
          textDecoration: "none",
        }}
        onClick={onClick}
      >
        <Text
          variant={isSelected ? "b2Bold" : "b2"}
          css={{
            "&:hover": {
              fontWeight: "$semiBold",
            },
          }}
          color={isSelected ? "black" : "grey4"}
        >
          {title}
        </Text>
      </Link>
    </Grid>
  );
};
