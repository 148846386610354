import React, { Children, useState } from "react";
import { CSS, ComponentProps } from "@stitches/react";
import { Flex, Box } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { tokens } from "src/ccl/stitches/theme";
import { mergeCss } from "src/ccl/stitches";

interface TabProps {
  id: string;
  title: (active: boolean) => React.ReactNode;
  children: React.ReactNode;
}

export const Tab = ({ children }: TabProps) => <Box>{children}</Box>;

interface TabbedContainerProps extends ComponentProps<typeof Box> {
  initialActiveId: string;
  backgroundColor?: `$${keyof typeof tokens.colors}`;
  navCss?: CSS;
  onTabChange?: (newTabId: string) => void;
}

export const TabbedContainer = ({
  initialActiveId,
  css = {},
  navCss = {},
  backgroundColor = "$black",
  onTabChange,
  children,
}: TabbedContainerProps) => {
  const [visibleId, setVisibleId] = useState(initialActiveId);

  return (
    <Box css={css}>
      <Flex
        css={mergeCss(
          { backgroundColor: backgroundColor, pt: "$4", pl: "$4", pr: "$4" },
          navCss,
        )}
        as="nav"
      >
        {Children.map(children, (child) => {
          const tabCount = Children.count(children);

          if (!React.isValidElement<TabProps>(child)) {
            return child;
          }

          const tab: React.ReactElement<TabProps> = child;
          const { id, title } = tab.props;

          return (
            <Button
              variant="unstyled"
              type="button"
              onClick={() => {
                setVisibleId(id);
                if (onTabChange) {
                  onTabChange(id);
                }
              }}
              css={{ width: `${100 / tabCount}%`, textTransform: "none" }}
            >
              {title(id === visibleId)}
            </Button>
          );
        })}
      </Flex>

      <Box>
        {Children.map(children, (child) => {
          if (!React.isValidElement<TabProps>(child)) {
            return null;
          }

          const tab: React.ReactElement<TabProps> = child;

          if (visibleId === tab.props.id) {
            return <Box>{child}</Box>;
          } else {
            return null;
          }
        })}
      </Box>
    </Box>
  );
};
