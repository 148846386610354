import { gql } from "@apollo/client";
import { PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const UPDATE_PORTFOLIO_IMAGE_CATEGORY_MUTATION = gql`
  mutation UpdatePortfolioImageCategory(
    $name: String!
    $portfolioImageCategoryId: ID!
  ) {
    updatePortfolioImageCategory(
      name: $name
      portfolioImageCategoryId: $portfolioImageCategoryId
    ) {
      ...PortfolioImageCategoryDetails
    }
  }
  ${PORTFOLIO_IMAGE_CATEGORY_DETAILS_FRAGMENT}
`;
