import { gql } from "@apollo/client";

export const CREATE_AGENCY_TALENT_MUTATION = gql`
  mutation CreateAgencyTalent(
    $name: String!
    $firstName: String
    $lastName: String
    $email: String!
    $phoneNumber: String!
    $addressLine1: String!
    $addressLine2: String
    $city: String!
    $county: String
    $country: String!
    $postcode: String!
    $talentProfile: CreateTalentProfileInput!
    $talentInvitationToken: String
    $password: String
  ) {
    createAgencyTalent(
      name: $name
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      county: $county
      country: $country
      postcode: $postcode
      talentProfile: $talentProfile
      talentInvitationToken: $talentInvitationToken
      password: $password
    ) {
      slug
    }
  }
`;
