import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";
import { ADEditProfileNav } from "src/components/dashboards/agents";
import { Text } from "src/ccl/document";
import { Breadcrumbs, Button, Link } from "src/ccl/navigation";
import { Box, FormLayout, Flex } from "src/ccl/layout";
import { Agency, User } from "src/graphql/types";
import { TalentManagementActionBar } from "src/components/dashboards/agents/talentManagement/TalentManagementActionBar";
import { useStoreModel, useUserKindContext } from "src/hooks";
import { sendToAmplitude } from "src/utils/analytics";

interface TalentEditFormProps {
  agency?: Agency;
  user: User;
  disabled: boolean;
  loading: boolean;
  children?: React.ReactNode;
}

export const TalentEditForm = ({
  agency,
  user,
  disabled,
  loading,
  children,
}: TalentEditFormProps) => {
  const { dirty } = useFormikContext();
  const { pathname } = useLocation();
  const currentUser = useStoreModel("currentUser");
  const userKindContext = useUserKindContext(currentUser);
  const agencyName = currentUser.me?.agency?.name;
  const isSelfServe = currentUser.me?.id === user.id;

  useEffect(() => {
    const unloadHandler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };

    if (dirty) {
      window.addEventListener("beforeunload", unloadHandler);
    }

    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, [dirty]);

  return (
    <FormLayout
      header={
        <Flex
          css={{
            mb: "$5",
            justifyContent: "space-between",
            alignItems: "center",
            "@bp5": { mb: "$9" },
          }}
        >
          {agency ? (
            <Breadcrumbs>
              <Link to="/dashboard/agent/agencies">All agencies</Link>
              <Link to={`/dashboard/agent/agencies/${agency.id}/talent`}>
                {agency?.name}
              </Link>
              <Text>{user?.name}</Text>
            </Breadcrumbs>
          ) : (
            <Text variant="h1">{user.name}</Text>
          )}

          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
            css={{ flexShrink: 0 }}
            data-test-id="SaveProfileButton"
            onClick={() => {
              sendToAmplitude("creative profile - views talent overview", {
                url: pathname,
                userType: userKindContext,
                agency: agencyName,
              });
            }}
          >
            {loading ? "Saving..." : "Save Profile"}
          </Button>
        </Flex>
      }
      accessory={isSelfServe ? null : <ADEditProfileNav user={user} loaded />}
      actionBar={
        <TalentManagementActionBar
          name={user.name}
          btnText={loading ? "Saving..." : "Save profile"}
          disabled={disabled}
        />
      }
      flexCSS={{ "@bp2": { flexWrap: "nowrap" } }}
    >
      <Box css={{ position: "relative" }}>{children}</Box>
    </FormLayout>
  );
};
