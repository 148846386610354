import { useLocation } from "react-router-dom";
import React from "react";
import { Link } from "src/ccl/navigation";
import { useStoreModel } from "src/hooks";
import { TalentVertical } from "src/graphql/types";
import { verticalMap } from "src/utils/user";

export const AgentDashboardSubHeaderItem = ({
  url,
  match,
  activeVertical,
  children,
}: {
  url: string;
  match: RegExp;
  activeVertical?: TalentVertical;
  children?: React.ReactNode;
}) => {
  const href = useLocation().pathname + useLocation().search;
  const isActive = activeVertical && url.includes(verticalMap[activeVertical]);
  const active = match.test(href) || isActive;
  const filterModel = useStoreModel("agencyDashboardFilters");

  return (
    <Link
      to={url}
      onClick={() => filterModel.setFilters({})}
      css={{
        color: active ? "$white" : "$grey6",
        "&:hover": {
          color: "$white",
        },
        textDecoration: active ? "underline 2px solid" : "none",
        textUnderlineOffset: "8px",
        fontSize: "$14",
        lineHeight: "$26",
        fontWeight: "$bold",
        px: "$5",
        py: "$5",
        "@bp2": {
          fontSize: "$16",
        },
      }}
    >
      {children}
    </Link>
  );
};

export const renderTalentManagementSubHeaders = (
  verticalNames: string[],
  activeVertical?: TalentVertical,
) => {
  return verticalNames.map((vertical, i) => (
    <AgentDashboardSubHeaderItem
      key={i}
      url={`/dashboard/agent/talent?v=${vertical.toLocaleLowerCase()}s`}
      match={
        new RegExp(
          `/dashboard/agent/talent(.+)?v=${vertical.toLocaleLowerCase()}s$`,
          "i",
        )
      }
      activeVertical={activeVertical}
    >
      {vertical}s
    </AgentDashboardSubHeaderItem>
  ));
};
