export const filterMap = <T, U>(
  array: T[],
  mapCallback: (val: T, index: number) => U | null | undefined,
): U[] =>
  array.reduce<U[]>((result, val, i) => {
    const mapped = mapCallback(val, i);
    if (mapped) {
      result.push(mapped);
    }
    return result;
  }, []);

export const partitionArray = <T>(
  arr: T[] | null,
  fn: (obj: T) => boolean,
): [T[], T[]] => {
  if (!arr) {
    return [[], []];
  }

  const a: T[] = [];
  const b: T[] = [];

  arr.forEach((item) => {
    fn(item) ? a.push(item) : b.push(item);
  });

  return [a, b];
};

export const sortByCreatedAt = <T extends { createdAt: Date | string }>(
  arr: T[],
) =>
  arr
    .slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    ) as T[];
