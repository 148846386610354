import { gql } from "@apollo/client";
import { SAVED_TALENT_COLLECTION_DETAILS_FRAGMENT } from "./SavedTalentCollectionDetails";

export const BOOKER_DASHBOARD_SAVED_TALENT_COLLECTIONS_FRAGMENT = gql`
  fragment BookerDashboardSavedTalentCollections on User {
    profile {
      ... on BookerProfile {
        savedTalentCollections(
          sortOrder: { column: "created_at", direction: DESC }
          first: 10
          after: $savedTalentCollectionsEndCursor
        ) {
          totalCount
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              ...SavedTalentCollectionDetails
            }
          }
        }
      }
    }
  }
  ${SAVED_TALENT_COLLECTION_DETAILS_FRAGMENT}
`;
