import { tokens } from "src/ccl/stitches/theme";

type Style = Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

export const controlStyles = {
  backgroundColor: tokens.colors.white,
  border: `2px solid ${tokens.colors.black}`,
  borderRadius: 0,
  boxShadow: "none",
};

export const selectStyles = {
  container: (styles: Style) => ({
    ...styles,
  }),
  clearIndicator: (styles: Style) => ({
    ...styles,
    color: tokens.colors.black,
  }),
  dropdownIndicator: (styles: Style) => ({
    ...styles,
    color: tokens.colors.black,
  }),
  menu: (styles: Style) => ({
    ...styles,
    border: `2px solid ${tokens.colors.black}`,
    borderRadius: 0,
    zIndex: 20,
  }),

  placeholder: (styles: Style) => ({
    ...styles,
    color: tokens.colors.grey6,
  }),
};
