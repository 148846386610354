import React from "react";
import { Icon, Text } from "src/ccl/document";
import { Flex, Box } from "src/ccl/layout";

export const MakeChanges = ({ text }: { text: string }) => (
  <Flex
    css={{
      border: "4px $grey6 solid",
      boxShadow: "0px 4px 0px 0px #888888",
      padding: "$8",
      gap: "$6",
    }}
  >
    <Icon
      variant="pencil"
      size={30}
      color="grey6"
      css={{
        flexShrink: 0,
        mt: "$3",
        display: "none",
        "@bp2": { display: "block" },
      }}
    />

    <Box>
      <Text weight="bold" css={{ mb: "$4" }}>
        You can still make changes.
      </Text>

      <Text>{text}</Text>
    </Box>
  </Flex>
);
