import React from "react";
import { NoBookmarkedCreatives } from "./NoBookmarkedCreatives";
import { Box } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { TalentCardGrid } from "src/ccl/document";
import { useHandleBookmark } from "src/hooks";
import { TalentVertical, User } from "src/graphql/types";

export const BookmarkTalentGrid = ({
  loading,
  loadingMore,
  users,
  vertical,
  talentUsers,
  setTalentUsers,
  openTalentProfileModal,
  fetchMore,
  onAddToCollection,
  analyticsEventPage,
  bookmarkCta,
}: {
  loading: boolean;
  loadingMore: boolean;
  vertical?: TalentVertical;
  users: User[];
  talentUsers: User[];
  setTalentUsers: (users: User[]) => void;
  openTalentProfileModal: (profile: string) => void;
  fetchMore?: () => void;
  onAddToCollection?: (talent: User) => void;
  analyticsEventPage?: string;
  bookmarkCta?: string;
}) => {
  const { handleBookmark, loading: bookmarkLoading } = useHandleBookmark(true);
  return (
    <Box css={{ pb: "$17" }}>
      {!loading && users.length === 0 && (
        <NoBookmarkedCreatives vertical={vertical} />
      )}
      {(loading || users.length > 0) && (
        <>
          <TalentCardGrid
            onAddToCollection={onAddToCollection}
            context="bookmarks"
            loading={loading}
            talent={users}
            openTalentProfileModal={openTalentProfileModal}
            isCheckable={() => true}
            isChecked={(u) =>
              talentUsers.some((selectedUser) => selectedUser.id === u.id)
            }
            handleBookmark={handleBookmark}
            isBookmarkDisabled={bookmarkLoading}
            analyticsEventPage={analyticsEventPage}
            onCheck={(u, checked) => {
              if (checked) {
                setTalentUsers(talentUsers.concat(u));
              } else {
                setTalentUsers(
                  talentUsers.filter(
                    (selectedUser) => u.id !== selectedUser.id,
                  ),
                );
              }
            }}
            bookmarkCta={bookmarkCta}
          />
          {fetchMore && (
            <Box css={{ textAlign: "center", my: "$6" }}>
              <Button variant="primary" disabled={loading} onClick={fetchMore}>
                {loadingMore ? "Loading..." : "Load more"}
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
