import React from "react";
import { Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Dropdown } from "src/ccl/navigation";

export const MoreDropdown = ({ children }: { children?: React.ReactNode }) => (
  <Box
    css={{
      position: "relative",
      "@bp2": { display: "none" },
    }}
  >
    <Dropdown
      launcher={
        <Flex
          css={{
            alignItems: "center",
            backgroundColor: "$grey1",
            px: "$5",
            py: "$3",
            borderBottom: "3px $black solid",
          }}
        >
          <Text>More</Text>
          <Icon
            variant="moreDots"
            size={16}
            css={{ display: "block", ml: "$3" }}
          />
        </Flex>
      }
      position="right"
      bodyCss={{
        width: "80vw",
        padding: "$6",
      }}
    >
      {children}
    </Dropdown>
  </Box>
);
