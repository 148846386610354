import { useHistory } from "react-router-dom";

const SEPARATOR = "~";

export function getURLStringArrayValue(name: string): string[] {
  const params = new URLSearchParams(document.location.search);
  const param = params.get(name)?.split(SEPARATOR) || [];
  return param;
}
export function getURLNumberArrayValue(name: string): number[] {
  const params = new URLSearchParams(document.location.search);
  const param = params.get(name)?.split(SEPARATOR) || [];
  return param.map((v) => parseInt(v));
}
export function getURLStringValue(name: string): string | null {
  const params = new URLSearchParams(document.location.search);
  return params.get(name);
}
export function getURLNumberValue(name: string): number | undefined {
  const params = new URLSearchParams(document.location.search);
  const param = params.get(name);
  return param ? parseInt(param) : undefined;
}

export function useURLArraySearchParam<T>(
  name: string,
  stringToType: (val: string) => T,
  typeToString: (val: T) => string,
): [T[], (val: T[]) => void] {
  const history = useHistory();
  const pathname = document.location.pathname;
  const params = new URLSearchParams(document.location.search);
  const param = (params.get(name)?.split(SEPARATOR) || []).map(stringToType);

  const setParam = (param: T[]) => {
    const params = new URLSearchParams(document.location.search);
    const newVal = param.map(typeToString).join(SEPARATOR);

    if (newVal.length === 0) {
      params.delete(name);
    } else {
      params.set(name, newVal);
    }

    history.push({ pathname, search: params.toString() });
  };

  return [param, setParam];
}

export const useURLStringArraySearchParam = <T = string>(name: string) =>
  useURLArraySearchParam(
    name,
    (v) => v as T,
    (v) => v as string,
  );
export const useURLNumberArraySearchParam = (name: string) =>
  useURLArraySearchParam(
    name,
    (v) => parseInt(v),
    (v) => v.toString(),
  );

export function useURLScalarSearchParam<T>(
  name: string,
  stringToType: (val?: string) => T,
  typeToString: (val: T) => string | undefined,
): [T, (val: T) => void] {
  const history = useHistory();
  const pathname = document.location.pathname;
  const params = new URLSearchParams(document.location.search);
  const param = stringToType(params.get(name) || undefined);

  const setParam = (param: T) => {
    const params = new URLSearchParams(document.location.search);
    const newVal = typeToString(param);

    if (!newVal || newVal.length === 0) {
      params.delete(name);
    } else {
      params.set(name, newVal);
    }

    history.push({ pathname, search: params.toString() });
  };

  return [param, setParam];
}

export const useURLTypedSearchParam = <T = string>(name: string) =>
  useURLScalarSearchParam<T | undefined>(
    name,
    (v?: string) => (v ? (v as T) : undefined),
    (v?: T) => (v ? (v as string) : undefined),
  );

export const useURLStringSearchParam = <T = string>(name: string) =>
  useURLScalarSearchParam<T | undefined>(
    name,
    (v?: string) => (v ? (v as T) : undefined),
    (v?: T) => (v ? (v as string) : undefined),
  );
export const useURLNumberSearchParam = (name: string) =>
  useURLScalarSearchParam<number | undefined>(
    name,
    (v?: string) => (v ? parseInt(v) : undefined),
    (v: number | undefined) => v?.toString(),
  );
export const useURLBooleanSearchParam = (name: string) =>
  useURLScalarSearchParam<boolean>(
    name,
    (v?: string) => v === "true",
    (v?: boolean) => v?.toString() || "",
  );
