import { CSS } from "@stitches/react";
import { ReactNode } from "react";
import { Box, Flex } from "src/ccl/layout";
import { mergeCss, styled } from "src/ccl/stitches";

const Container = styled(Flex, {
  width: "100%",
  height: "100%",

  "@md": {
    gap: "$6",
  },
  "@lg": {
    gap: "$9",
  },
});

const PrimaryPane = styled(Box, {
  height: "100%",
  top: 0,
});

const Content = styled(Box, {
  flex: 1,
  overflow: "hidden",
});

export const TwoPane = ({
  children,
  primaryPane,
  primaryPaneCss = {},
  hideSidePane,
}: {
  children: ReactNode;
  primaryPane: ReactNode;
  primaryPaneCss?: CSS;
  hideSidePane?: boolean;
}) => (
  <Container>
    {!hideSidePane && (
      <PrimaryPane css={mergeCss({ width: "20%" }, primaryPaneCss)}>
        {primaryPane}
      </PrimaryPane>
    )}
    <Content>{children}</Content>
  </Container>
);
