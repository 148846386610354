import { gql } from "@apollo/client";

export const CREATE_AGENCY_MUTATION = gql`
  mutation CreateAgency(
    $name: String!
    $location: String!
    $logo: FileUploadInput
    $paymentsEmail: String!
  ) {
    createAgency(
      name: $name
      location: $location
      logo: $logo
      paymentsEmail: $paymentsEmail
    ) {
      id
      name
      location
    }
  }
`;
