type RobotsValue = "all" | "noindex" | "nofollow" | "none";

interface MetaTag {
  name?: string;
  property?: string;
  content: string;
}

interface OGProperty {
  property: string;
  content: string;
}

export interface TiaraConfig {
  title?: string;
  description?: string;
  imageUrl?: string;
  twitterImageUrl?: string;
  ogType?: string;
  ogProperties?: OGProperty[];
  robots?: RobotsValue;
}

const defaultTitle = "Contact";
const defaultDescription = "Book creative talent. Fast.";
const defaultImageUrl =
  "https://contact-media.imgix.net/gloss/default-og-image.png";

const updateTitle = (title?: string) => {
  if (!title || title === "") {
    document.title = `${defaultTitle} - where creative talent lives`;
    return;
  }

  document.title = `${defaultTitle} - ${title}`;
};

const insertMetaTag = (attributes: MetaTag) => {
  const element = document.createElement("meta");

  Object.entries(attributes).forEach(([key, value]) =>
    element.setAttribute(key, value),
  );

  element.dataset.tiara = "true";

  document.head.appendChild(element);
};

const removeExistingMetaTags = () => {
  const existingTags = document.head.querySelectorAll("meta");

  existingTags.forEach((tag) => {
    if (tag.dataset.tiara !== "true" || !tag.parentNode) {
      return;
    }

    tag.parentNode.removeChild(tag);
  });
};

const insertMetaTags = (tags?: MetaTag[]) => {
  if (!tags) {
    return;
  }

  tags.forEach((tag) => insertMetaTag(tag));
};

const assembleMetaTags = (config: TiaraConfig): MetaTag[] => {
  const tags = [
    {
      name: "description",
      content: config.description || defaultDescription,
    },
    {
      property: "og:site_name",
      content: "Contact",
    },
    {
      property: "og:title",
      content: config.title || defaultTitle,
    },
    {
      property: "og:description",
      content: config.description || defaultDescription,
    },
    {
      property: "og:image",
      content: config.imageUrl || defaultImageUrl,
    },
    {
      property: "og:url",
      content: window.location.href,
    },
    {
      property: "og:type",
      content: config.ogType || "website",
    },
    {
      name: "twitter:card",
      content: config.imageUrl ? "summary_large_image" : "summary",
    },
    {
      name: "twitter:title",
      content: config.title || defaultTitle,
    },
    {
      name: "twitter:description",
      content: config.description || defaultDescription,
    },
    {
      name: "twitter:image",
      content: config.twitterImageUrl || config.imageUrl || defaultImageUrl,
    },
    {
      name: "robots",
      content: config.robots || "all",
    },
  ];

  if (config.ogProperties?.length) {
    config.ogProperties.forEach((p) => tags.push(p));
  }

  return tags;
};

export const updateTiara = (config: TiaraConfig) => {
  updateTitle(config.title);
  removeExistingMetaTags();
  insertMetaTags(assembleMetaTags(config));
};
