import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import { camelCase } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { MissingInfoAlert } from "src/components/dashboards/agents";
import { formatDate } from "src/utils/dates";
import { sendToAmplitude, trackEvent } from "src/utils/analytics";
import {
  UpdateTalentProfileInput,
  Mutation,
  MutationUpdateAgencyTalentArgs,
  TalentProfile,
  User,
  VerticalConfiguration,
  MutationCreateAssetArgs,
  Asset,
  SocialMedia,
  Agency,
} from "src/graphql/types";
import {
  TalentFormValues,
  UserValidationSchema,
} from "src/components/dashboards/agents/talentManagement/forms/formConfigV2";
import { talentHelpers } from "src/utils/talent";
import {
  FieldInfoText,
  ProfileForm,
} from "src/components/dashboards/agents/talentManagement/forms";
import { UPDATE_AGENCY_TALENT_MUTATION } from "src/graphql/mutations";
import { snakeCaseKeys } from "src/utils/snakeCaseKeys";
import { ValidationBlock } from "src/ccl/feedback";
import { CREATE_ASSET_MUTATION } from "src/graphql/mutations/user/CreateAsset";
import { getValidationErrors } from "src/validations";
import { Box, Container, Flex, Grid, LegacyContainer } from "src/ccl/layout";
import { Button, Link } from "src/ccl/navigation";
import { Icon, Text } from "src/ccl/document";
import { SectionHeader } from "src/components/SectionHeader";
import { useFeatures, useStoreModel, useUserKindContext } from "src/hooks";
import { StepHeader } from "src/components/StepHeader";
import { FullHeaderPage } from "src/ccl/templates";

interface ProfileProps {
  agency?: Agency;
  user: User;
  verticalConfig: VerticalConfiguration;
}

export const LegacyProfile = ({
  agency,
  user,
  verticalConfig,
}: ProfileProps) => {
  const history = useHistory();

  const [mounted, setMounted] = useState(false);

  const { pathname } = useLocation();
  const currentUser = useStoreModel("currentUser");
  const userKindContext = useUserKindContext(currentUser);
  const agencyName = currentUser.me?.agency?.name;

  const profile = user.profile as TalentProfile;

  const [createAsset, { loading: createAssetLoading }] = useMutation<
    Mutation,
    MutationCreateAssetArgs
  >(CREATE_ASSET_MUTATION, {
    update(cache, { data }) {
      if (!data || !data.createAsset) {
        return;
      }
      cache.modify({
        id: `User:${user.id}`,
        fields: {
          featuredAsset() {
            if (!data || !data.createAsset) {
              return;
            }

            return data.createAsset as Asset;
          },
        },
      });
    },
  });

  const [
    updateAgencyTalent,
    { loading: updateAgencyTalentLoading, data: updatedAgencyTalent },
  ] = useMutation<Mutation, MutationUpdateAgencyTalentArgs>(
    UPDATE_AGENCY_TALENT_MUTATION,
  );

  const loading = updateAgencyTalentLoading || createAssetLoading;

  const vertical = profile.vertical;
  const verticalData = profile.verticalData || [];
  // Transform [{ key: 'shooting_formats', value: ['digital']}]
  // into {shootingFormats: ['digital']}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const verticalDataValues: Record<string, any> = {};
  verticalData.forEach((data) => {
    try {
      verticalDataValues[camelCase(data.key)] = JSON.parse(data.value);
    } catch {
      verticalDataValues[camelCase(data.key)] = data.value;
    }
  });

  const [initialValues, setInitialValues] = useState<TalentFormValues>({
    name: user.name || "",
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    phoneNumber: user.phoneNumber || "",
    addressLine1: user.addressLine1 || "",
    addressLine2: user.addressLine2 || "",
    city: user.city || "",
    postcode: user.postcode || "",
    country: user.country || "",
    talentProfile: {
      primaryLocation: profile.primaryLocation,
      additionalLocations: profile.additionalLocations,
      dateOfBirth: formatDate(profile.dateOfBirth, "dd/MM/yyyy"),
      biography: profile.biography || "",
      instagramUsername: profile.instagramUsername || "",
      hobbies: profile.hobbies || ([] as string[]),
      gender: profile.gender,
      published: !!profile.published,
      dietaryRequirements: profile.dietaryRequirements,
      pastClients: profile.pastClients,
      pronouns: profile.pronouns,
      isContactOpen: profile.isContactOpen,
      isContactSelect: profile.isContactSelect,
      minDayRate: profile.minDayRate,
      maxDayRate: profile.maxDayRate,
      website: profile.website || "",
      verticalData: verticalDataValues,
      vertical: profile.vertical,
      agencyId: profile.agency?.id,
      shrineAssets: undefined,
      portfolioImages: [],
      socialMediaFields:
        profile.socialMediaFields?.map((socialMediaInput: SocialMedia) => {
          return {
            platform: socialMediaInput.platform,
            handle: socialMediaInput.handle,
            followerCount: socialMediaInput.followerCount,
          };
        }) || [],
    },
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  const { featureEnabled } = useFeatures();
  const ContainerComponent = featureEnabled("unified_dashboards")
    ? Container
    : LegacyContainer;

  return (
    <FullHeaderPage>
      <Formik
        initialValues={initialValues}
        validate={async (values) => {
          const schema = UserValidationSchema(
            verticalConfig,
            values.talentProfile.gender,
            "update",
          );

          return getValidationErrors(values, schema);
        }}
        onSubmit={async (values, formik) => {
          const { talentProfile } = values;
          const {
            verticalData,
            primaryLocation,
            dateOfBirth,
            additionalLocations,
            pastClients,
            minDayRate,
            maxDayRate,
            website,
            instagramUsername,
          } = talentProfile as UpdateTalentProfileInput;
          delete talentProfile.portfolioImages;
          delete talentProfile.vertical;

          const [d, m, y] = dateOfBirth.split("/");
          const formattedDob = formatDate(new Date(y, m - 1, d), "yyyy-MM-dd");

          const parsedVerticalData = snakeCaseKeys(verticalData);

          await updateAgencyTalent({
            variables: {
              ...values,
              talentId: user.id,

              talentProfile: {
                ...talentProfile,
                primaryLocation: primaryLocation
                  ? { name: primaryLocation.name }
                  : undefined,
                additionalLocations: additionalLocations?.map((l) => ({
                  name: l.name,
                })),
                dateOfBirth: formattedDob,
                pastClients: pastClients,
                verticalData: parsedVerticalData,
                minDayRate: minDayRate ? minDayRate : undefined,
                maxDayRate: maxDayRate ? maxDayRate : undefined,
                website: website ? website : undefined,
                instagramUsername: instagramUsername
                  ? instagramUsername
                  : undefined,
              },
            },
          });

          if (
            values.talentProfile.agencyId !==
            initialValues.talentProfile.agencyId
          ) {
            history.push("/dashboard/agent/talent");
          }
          setInitialValues(values);
          formik.resetForm({ values });

          trackEvent("agencyDashboard:editTalent:editProfile");
        }}
      >
        {({ values, submitForm }) => {
          return (
            <>
              <ContainerComponent css={{ pt: "14px" }} variant="gutter">
                <Link
                  to={
                    agency
                      ? `/dashboard/agent/agencies/${agency.id}/talent/${user.slug}`
                      : `/dashboard/agent/talent/${user.slug}`
                  }
                  css={{ textDecoration: "none" }}
                >
                  <Flex
                    css={{
                      gap: "$4",
                      alignItems: "center",
                    }}
                  >
                    <Icon variant="chevronLeft" size={28} />
                    <Text variant="nh3">{values.name}</Text>
                  </Flex>
                </Link>
              </ContainerComponent>

              <StepHeader
                labels={["Talent details", "Social Media", "Payment details"]}
                buttons={[
                  {
                    label: "Save Changes",
                    onClick: () => {
                      sendToAmplitude(
                        "adds creative profile - clicks continue",
                        {
                          url: pathname,
                          userType: userKindContext,
                          agency: agencyName,
                        },
                      );
                      submitForm();
                    },
                    variant: "primaryCta",
                  },
                ]}
                parentMounted={mounted}
              />

              <ContainerComponent css={{ pt: "47px" }} variant="gutter">
                <Flex css={{ flexDirection: "column" }}>
                  {profile.missingInformation &&
                    profile.missingInformation.length > 0 && (
                      <MissingInfoAlert>
                        {user.name}{" "}
                        {talentHelpers.buildMissingInformationString(profile)}
                      </MissingInfoAlert>
                    )}
                  {!loading && updatedAgencyTalent && (
                    <ValidationBlock
                      variant="success"
                      title="Your changes have been saved"
                    />
                  )}
                  <Box data-step-header-section>
                    <SectionHeader step="1" label="Talent details" />

                    <Text variant="b2Bold">Set profile image</Text>

                    <Box css={{ width: "336px" }}>
                      <FieldInfoText text="This is the main image that will appear on our marketplace. Make sure it’s cropped to your liking." />
                    </Box>
                  </Box>
                  p
                  <Grid
                    css={{
                      gridGap: "$9",
                      gridColumns: 1,
                      alignItems: "flex-start",
                      "@bp2": {
                        gridColumns: "2fr 1fr",
                      },
                    }}
                  >
                    <ProfileForm
                      user={user}
                      values={values}
                      vertical={vertical}
                      verticalConfig={verticalConfig}
                      profilePicture={user.featuredAsset}
                      context="edit"
                      onProfilePictureUpload={async (asset) => {
                        if (asset.key) {
                          createAsset({
                            variables: {
                              kind: "featured",
                              file: {
                                key: asset.key,
                                filename: asset.filename,
                                contentType: asset.contentType,
                                contentLength: asset.contentLength,
                              },
                              assetableId: user.profile.id,
                              assetableType: "TalentProfile",
                            },
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Box
                    css={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      zIndex: "$400",
                      width: "100%",
                      height: "96px",
                      background: "$white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                      boxShadow: "0px 4px 20px 0px #00000014",
                      "@sm": {
                        display: "none",
                      },
                    }}
                  >
                    <Button
                      data-test-id="TalentManagementActionBarButton"
                      variant="primaryCta"
                      onClick={() => {
                        sendToAmplitude(
                          "adds creative profile - clicks continue",
                          {
                            url: pathname,
                            userType: userKindContext,
                            agency: agencyName,
                          },
                        );
                        submitForm();
                      }}
                      css={{ width: "100%" }}
                    >
                      Save Profile
                    </Button>
                  </Box>
                </Flex>
              </ContainerComponent>
            </>
          );
        }}
      </Formik>
    </FullHeaderPage>
  );
};
