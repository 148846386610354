import React from "react";
import type * as Stitches from "@stitches/react";
import { mergeCss } from "src/ccl/stitches/config";
import { sendToAmplitude } from "src/utils/analytics";
import { AvailableIcon, Icon, Text, TextVariants } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { iconSizes, tokens } from "src/ccl/stitches/theme";
import { ResponsiveText } from "src/components";

export interface IconTextProps {
  leftIcon?: AvailableIcon;
  rightIcon?: AvailableIcon;
  iconSize?: keyof typeof iconSizes;
  css?: Stitches.CSS;
  text: React.ReactNode;
  textVariant?: keyof typeof TextVariants;
  onClick?: () => void;
  amplitudeEvent?: string;
  dataTestId?: string;
  color?: keyof typeof tokens.colors;
  textCss?: Stitches.CSS;
  shortText?: string;
  iconCss?: Stitches.CSS;
}

export const IconText = ({
  leftIcon,
  rightIcon,
  iconSize = 16,
  css = {},
  text,
  textVariant,
  onClick,
  amplitudeEvent,
  dataTestId,
  color = "black",
  textCss = {},
  shortText,
  iconCss,
}: IconTextProps) => {
  return (
    <Flex
      data-test-id={dataTestId}
      css={mergeCss({ gap: "$3", alignItems: "center" }, css)}
      onClick={
        onClick &&
        (() => {
          onClick();
          amplitudeEvent && sendToAmplitude(amplitudeEvent);
        })
      }
    >
      {leftIcon && (
        <Icon variant={leftIcon} size={iconSize} color={color} css={iconCss} />
      )}

      <Text variant={textVariant} color={color} css={textCss}>
        <ResponsiveText initial={shortText ? shortText : text} bp2={text} />
      </Text>

      {rightIcon && (
        <Icon variant={rightIcon} size={iconSize} color={color} css={iconCss} />
      )}
    </Flex>
  );
};
