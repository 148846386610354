import {
  action,
  Action,
  ActionOn,
  actionOn,
  computed,
  Computed,
  persist,
  thunk,
  Thunk,
} from "easy-peasy";
import { StoreModel } from "..";
import { UploadedFile } from "src/entities";
import {
  CreateJobVerticalInput,
  MutationCreateJobArgs,
  Scalars,
  TalentVertical,
  User,
} from "src/graphql/types";
import { getTalentVerticals } from "src/utils/user";

type Status = "blank" | "talentSelected" | "jobDetailsFilled" | "readyToSubmit";

export interface DraftJobModelV2 {
  vertical: TalentVertical | null;
  details: MutationCreateJobArgs | null;
  startDate: Scalars["ISO8601Date"] | null;
  endDate: Scalars["ISO8601Date"] | null;
  deckFiles: UploadedFile[] | null;
  talentUsers: User[];
  status: Computed<DraftJobModelV2, Status>;
  setVertical: Thunk<DraftJobModelV2, TalentVertical, void, StoreModel>;
  setVerticalData: Action<DraftJobModelV2, TalentVertical>;
  setDetails: Action<DraftJobModelV2, MutationCreateJobArgs | null>;
  setStartDate: Action<DraftJobModelV2, Scalars["ISO8601Date"] | null>;
  setEndDate: Action<DraftJobModelV2, Scalars["ISO8601Date"] | null>;
  setDeckFiles: Action<DraftJobModelV2, UploadedFile[] | null>;
  setTalentUsers: Action<DraftJobModelV2, User[]>;
  addTalentUser: Action<DraftJobModelV2, User>;
  addTalentUsers: Action<DraftJobModelV2, User[]>;
  removeTalentUser: Action<DraftJobModelV2, string>;
  reset: Thunk<DraftJobModelV2, void, void, StoreModel>;
  resetCache: Thunk<DraftJobModelV2, void, void, StoreModel>;
  resetData: Action<DraftJobModelV2>;
  jobVerticals: CreateJobVerticalInput[];
  setJobVerticals: Action<DraftJobModelV2, CreateJobVerticalInput[]>;
  updateJobVerticals: ActionOn<DraftJobModelV2>;
  customisedTimeslots: number[];
  setCustomisedTimeslots: Action<DraftJobModelV2, number[]>;
  spansMultipleDays: boolean | null;
  setSpansMultipleDays: Action<DraftJobModelV2, boolean | null>;
  isLoggedOutState: boolean | null;
  setLoggedOutState: Action<DraftJobModelV2, boolean | null>;
}

const persistInLocalStorage = (model: DraftJobModelV2) =>
  persist(model, { storage: "localStorage" });

export const draftJobModelV2: DraftJobModelV2 = persistInLocalStorage({
  vertical: null,
  details: null,
  deckFiles: null,
  startDate: null,
  endDate: null,
  talentUsers: [],
  spansMultipleDays: null,
  status: computed((state) => {
    if (
      state.details &&
      state.talentUsers &&
      state.talentUsers.length > 0 &&
      state.jobVerticals.length > 0 &&
      state.jobVerticals.every(
        ({ talentRequired, budget }) => budget === 0 && talentRequired === 0,
      )
    ) {
      return "jobDetailsFilled";
    }

    if (
      state.details &&
      state.talentUsers &&
      state.talentUsers.length > 0 &&
      state.jobVerticals.length > 0 &&
      state.jobVerticals.every(({ talentRequired }) => talentRequired > 0)
    ) {
      return "readyToSubmit";
    }

    if (state.talentUsers && state.talentUsers.length > 0) {
      return "talentSelected";
    }

    return "blank";
  }),
  setVertical: thunk((actions, payload, { getState }) => {
    if (getState().vertical !== payload) {
      actions.resetCache();
    }
    actions.setVerticalData(payload);
  }),
  setVerticalData: action((state, payload) => {
    state.vertical = payload;
  }),
  setDetails: action((state, payload) => {
    state.details = payload;

    if (state.startDate && state.details) {
      state.details.startDate = state.startDate;
    }
    if (state.endDate && state.details) {
      state.details.endDate = state.endDate;
    }
  }),
  setStartDate: action((state, payload) => {
    state.startDate = payload;
  }),
  setEndDate: action((state, payload) => {
    state.endDate = payload;
  }),
  setDeckFiles: action((state, payload) => {
    state.deckFiles = payload;
  }),
  setTalentUsers: action((state, payload) => {
    state.talentUsers = payload;
  }),
  addTalentUser: action((state, payload) => {
    if (!state.talentUsers) {
      state.talentUsers = [payload];
    } else if (!state.talentUsers.some((u) => u.id === payload.id)) {
      state.talentUsers.push(payload);
    }
  }),
  addTalentUsers: action((state, payload) => {
    if (!state.talentUsers) {
      state.talentUsers = payload;
    } else {
      const existingUsers = state.talentUsers.map(({ id }) => id);
      payload.forEach((u) => {
        if (existingUsers.includes(u.id)) {
          return;
        }
        state.talentUsers.push(u);
      });
    }
  }),
  updateJobVerticals: actionOn(
    (actions) => [
      actions.removeTalentUser,
      actions.addTalentUser,
      actions.setTalentUsers,
    ],
    (state) => {
      const newVerticals = getTalentVerticals(state.talentUsers);

      state.jobVerticals = newVerticals.map((v) => {
        const existingJobVertical = state.jobVerticals.find(
          ({ vertical }) => vertical === v,
        );

        if (existingJobVertical) {
          return existingJobVertical;
        } else {
          return {
            vertical: v,
            budget: 0,
            deliverables: [],
            talentRequired: 0,
            jobLength: "",
            timeslots: [],
          };
        }
      });
    },
  ),
  removeTalentUser: action((state, payload) => {
    if (!state.talentUsers) return;

    state.talentUsers = state.talentUsers.filter((u) => u.id !== payload);
  }),
  resetData: action((state) => {
    state.details = null;
    state.talentUsers = [];
    state.deckFiles = null;
    state.jobVerticals = [];
    state.startDate = null;
    state.endDate = null;
    state.customisedTimeslots = [];
  }),
  resetCache: thunk((actions, payload, { getStoreActions }) => {
    getStoreActions().jobs.queryCache.evictCachedQuery("createJob");
  }),
  reset: thunk((actions, payload, { getStoreActions }) => {
    actions.resetData();
    getStoreActions().jobs.queryCache.evictCachedQuery("createJob");
  }),
  jobVerticals: [],
  setJobVerticals: action((state, payload) => {
    state.jobVerticals = payload;
  }),
  customisedTimeslots: [],
  setCustomisedTimeslots: action((state, payload) => {
    state.customisedTimeslots = payload;
  }),
  setSpansMultipleDays: action((state, payload) => {
    state.spansMultipleDays = payload;
  }),
  isLoggedOutState: null,
  setLoggedOutState: action((state, payload) => {
    state.isLoggedOutState = payload;
  }),
});
