import { Icon, Img, Modal, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { SignInForm } from "src/components/auth";

export const SignInModal = ({
  isOpen,
  onClose,
  onSignIn,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSignIn?: () => void;
}) => (
  <Modal
    width="wide"
    isOpen={isOpen}
    showCloseButton
    onClose={onClose}
    withBodyPadding={false}
    withTitlePadding={false}
    overlayCss={{ padding: "0px" }}
  >
    <Flex
      css={{
        justifyContent: "center",
        gap: "$13",
        padding: "$8",
        "@lg": { justifyContent: "initial", padding: "12px" },
      }}
    >
      <Box
        css={{
          position: "relative",
          display: "none",
          "@bp3": {
            display: "block",
          },
        }}
      >
        <Img
          src="https://contact-media.imgix.net/gloss/sign-in-modal-flairiest-nasiir.jpg"
          css={{
            display: "none",
            borderRadius: "20px",
            objectFit: "cover",
            "@bp3": { display: "block", width: "257px", height: "594px" },
          }}
        />
        <Box
          css={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            padding: "8px 19px",
          }}
        >
          <Text color="white" variant="nh2">
            P.A.M x Puma
          </Text>
          <Flex
            css={{
              justifyContent: "space-between",
            }}
          >
            <Text color="white" variant="b2">
              Flairiest Nasiir - Model
            </Text>
            <Icon variant="link" size={14} color="white" />
          </Flex>
        </Box>
      </Box>
      <SignInForm isModal onSignIn={onSignIn} />
    </Flex>
  </Modal>
);
