import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Icon, Modal, Text } from "src/ccl/document";
import { Field, FormikInput } from "src/ccl/data-entry";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { UPDATE_SAVED_TALENT_COLLECTION_MUTATION } from "src/graphql/mutations/booker/UpdateSavedTalentCollection";
import {
  Mutation,
  MutationUpdateSavedTalentCollectionArgs,
  SavedTalentCollection,
} from "src/graphql/types";

export const EditCollectionModal = ({
  collection,
  isOpen,
  onClose,
  onDelete,
}: {
  collection: SavedTalentCollection;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}) => {
  const [editCollection] = useMutation<
    Mutation,
    MutationUpdateSavedTalentCollectionArgs
  >(UPDATE_SAVED_TALENT_COLLECTION_MUTATION);

  const history = useHistory();
  return (
    <Modal isOpen={isOpen} showCloseButton={true} onClose={onClose}>
      <Box data-test-id={"edit-modal"} css={{ py: "$5" }}>
        <Formik
          initialValues={{ name: collection.name }}
          onSubmit={async (values) => {
            const response = await editCollection({
              variables: {
                id: collection.id,
                name: values.name,
              },
            });
            if (response.data?.updateSavedTalentCollection?.slug) {
              onClose();
              history.push(response.data?.updateSavedTalentCollection?.slug);
            }
          }}
        >
          {() => (
            <Form>
              <Flex
                css={{
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                <Text variant="nh3">Edit collection</Text>

                <Field
                  variant="b2Bold"
                  name="name"
                  label="Name your collection"
                  css={{ marginBottom: "0" }}
                >
                  <FormikInput variant="rebrand" name="name" type="string" />
                </Field>

                <Flex
                  css={{
                    justifyContent: "flex-start",
                    flexDirection: "column-reverse",
                    gap: "18px",
                    "@sm": {
                      justifyContent: "space-between",
                      flexDirection: "row",
                    },
                  }}
                >
                  <Button variant="secondaryCta" onClick={onDelete}>
                    <Flex
                      css={{
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        "@sm": {
                          justifyContent: "start",
                        },
                      }}
                    >
                      <Icon variant="trash" color="red" />
                      Delete collection
                    </Flex>
                  </Button>
                  <Button variant="primaryCta" type="submit">
                    Save changes
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
