import {
  AgentTalentCardFeature,
  AgentTalentCardMeta,
  AgentTalentCardVariant,
} from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { User } from "src/graphql/generated/types";

interface AgentTalentCardProps {
  variant?: AgentTalentCardVariant;
  talent: User;
}
export const AgentTalentCard = ({
  variant = "single",
  talent,
}: AgentTalentCardProps) => (
  <Box>
    <AgentTalentCardFeature variant={variant} talent={talent} />
    <Box css={{ mt: "$4" }}>
      <AgentTalentCardMeta talent={talent} />
    </Box>
  </Box>
);
