import React from "react";
import { Input, InputProps } from "src/ccl/data-entry";
import { Flex } from "src/ccl/layout";
import { styled } from "src/ccl/stitches";

const InputSuffix = styled("span", {
  position: "absolute",
  right: "1rem",
  color: "$grey6",
});

const InputPrefix = styled("span", {
  position: "absolute",
  left: "1rem",
  color: "$grey6",
  lineHeight: 1.7,
});

export const EnhancedInput = ({
  inputSuffix,
  inputPrefix,
  prefixCss,
  suffixCss,
  value,
  style,
  ...props
}: InputProps) => (
  <Flex
    css={{
      position: "relative",
      alignItems: "center",
    }}
  >
    {inputPrefix && <InputPrefix css={prefixCss}>{inputPrefix}</InputPrefix>}
    <Input
      id={props.id || `form-${props.name}`}
      style={{
        paddingLeft: inputPrefix ? "2rem" : undefined,
        paddingRight: inputSuffix ? "2.5rem" : undefined,
        ...style,
      }}
      value={value}
      {...props}
    />

    {inputSuffix && <InputSuffix css={suffixCss}>{inputSuffix}</InputSuffix>}
  </Flex>
);
