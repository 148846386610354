import React from "react";
import { Pill } from "src/ccl/blocks";
import { Text } from "src/ccl/document";

export const JobStatePill = ({
  title,
  backgroundColor,
  textColor,
}: {
  title: string;
  backgroundColor: string;
  textColor: string;
}) => {
  return (
    <Pill
      variant="rounded"
      css={{
        backgroundColor: backgroundColor,
        boxShadow: "none",
      }}
    >
      <Text css={{ color: textColor, whiteSpace: "nowrap" }}>{title}</Text>
    </Pill>
  );
};
