import React, { FC } from "react";
import { Box, Flex } from "src/ccl/layout";
import { Invoice, InvoiceState } from "src/graphql/types";
import { Icon, IconText, Text } from "src/ccl/document";
import { formatDate } from "src/utils/dates";
import { Pill } from "src/ccl/blocks";
import { Button, ExternalLink } from "src/ccl/navigation";
import { useStoreModel } from "src/hooks";
import { invoiceStateText } from "src/utils/lang";
import { tagColors } from "src/utils/invoice";

interface InvoiceItemContentProps {
  invoice: Invoice;
}

export const InvoiceItemContent: FC<InvoiceItemContentProps> = ({
  invoice,
}) => {
  const isPaid = invoice.state === InvoiceState.Paid;
  const isPending = invoice.state === InvoiceState.Pending;
  const { isBooker } = useStoreModel("currentUser");

  return (
    <Box
      css={{
        border: "1px solid $grey3",
        borderRadius: "$lg",
      }}
    >
      <Flex
        css={{
          gap: "$7",
          "@bp3": { gap: "$11" },
          "&:hover": {
            backgroundColor: isBooker || !isPending ? "$grey1" : "$white",
          },
        }}
      >
        <Flex
          css={{
            flex: 1,
            flexDirection: "column",
            "@bp2": {
              flexDirection: "row",
              justifyContent: "space-between",
            },
          }}
        >
          <Flex css={{ flexDirection: "column", p: "$6", gap: "$5" }}>
            <Text variant="h4">
              {invoice.paymentReference
                ? `Invoice ID: ${invoice.paymentReference}`
                : `Invoice`}
            </Text>

            <Flex
              css={{
                flexDirection: "column",
                gap: "$4",

                "@bp2": { flexDirection: "row", gap: "$7" },
              }}
            >
              <IconText
                text={formatDate(invoice.invoiceDate)}
                textVariant="meta"
                leftIcon="calendar"
              />
              <IconText
                text={
                  <Flex css={{ alignItems: "center", gap: "$3" }}>
                    {!isBooker && invoice.isLatePaymentInvoice ? (
                      <Text
                        variant="mini"
                        css={{
                          fontWeight: "$bold",
                          alignSelf: "center",
                          pl: "$3",
                        }}
                      >
                        Late payment invoice
                      </Text>
                    ) : (
                      <>
                        <Text variant="mini">Due date:</Text>
                        <Text variant="meta">
                          {formatDate(invoice.dueDate)}
                        </Text>
                      </>
                    )}
                  </Flex>
                }
                leftIcon="alarmNudge"
              />
            </Flex>
          </Flex>

          <Flex
            css={{
              pb: "$6",
              alignItems: "center",
              "@bp2": {
                pb: 0,
                pr: "$7",
              },
            }}
          >
            <Pill
              variant="rounded"
              css={{
                backgroundColor: `$${tagColors[invoice.state].bg}`,
                color: `$${tagColors[invoice.state].color}`,
                m: 0,
                py: "$3",
                px: "$5",
                display: "flex",
                alignItems: "center",
                boxShadow: "none",
                fontWeight: "$semiBold",
              }}
            >
              {invoiceStateText(invoice.state)}
              {isPaid && (
                <Icon
                  variant="tick"
                  size={12}
                  color={isPaid ? "white" : "black"}
                  css={{
                    ml: "$3",
                  }}
                />
              )}
            </Pill>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

interface InvoiceItemProps {
  invoice: Invoice;
  onClick?: () => void;
}

export const InvoiceItem = ({ invoice, onClick }: InvoiceItemProps) => {
  if (onClick) {
    return (
      <Button
        data-test-id="InvoiceItem"
        onClick={onClick}
        css={{
          textDecoration: "none",
          cursor: "pointer",
        }}
      >
        <InvoiceItemContent invoice={invoice} />
      </Button>
    );
  } else {
    return (
      <ExternalLink
        data-test-id="InvoiceItem"
        to={invoice.pdfUrl}
        target="_blank"
        rel="noreferrer"
        css={{ textDecoration: "none" }}
      >
        <InvoiceItemContent invoice={invoice} />
      </ExternalLink>
    );
  }
};
