import React from "react";
import { Flex, Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";

export const TalentManagementActionBar = ({
  name,
  btnText,
  disabled,
  onClick,
}: {
  name: string;
  btnText?: string;
  disabled?: boolean;
  onClick?: () => void;
}) => (
  <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
    <Box>
      <Text variant={{ "@initial": "body", "@bp2": "impact" }} weight="bold">
        {name}
      </Text>
    </Box>
    {btnText && (
      <Button
        variant="primary"
        type="submit"
        onClick={onClick}
        disabled={disabled}
        data-test-id="TalentManagementActionBarButton"
      >
        {btnText}
      </Button>
    )}
  </Flex>
);
