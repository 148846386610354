import React from "react";
import { ComponentProps, CSS } from "@stitches/react";
import { Text } from "src/ccl/document/text";
import { mergeCss, styled } from "src/ccl/stitches";
import { tokens, iconSizes } from "src/ccl/stitches/theme";
import { AvailableIcon, Icon } from "src/ccl/document/icon";

const StyledPanel = styled("div", {
  display: "flex",
  flexDirection: "row",
  boxShadow: "$focus",
  backgroundColor: "$white",
  border: "2px solid $black",
  variants: {
    variant: {
      secondary: {
        border: "1px solid $grey3",
        borderRadius: "10px",
      },
      profile: {
        border: "3px solid $black",
      },
      notice: {
        background: "$grey1",
        border: "none",
        boxShadow: "none",
        borderRadius: "$1",
      },
    },
  },
});

const IconContainer = styled("div", {
  pr: "$5",
});

const Heading = styled(Text, {
  pb: "$3",
  fontWeight: "$bold",
});

const Content = styled("div", {
  width: "100%",
});
interface PanelProps extends ComponentProps<typeof StyledPanel> {
  heading?: React.ReactNode;
  icon?: AvailableIcon;
  iconSize?: keyof typeof iconSizes;
  iconColor?: keyof typeof tokens.colors;
  css?: CSS;
  contentCss?: CSS;
}

export const Panel = ({
  variant,
  icon,
  iconSize = 30,
  iconColor = "black",
  heading = null,
  css = {},
  children,
  onClick,
  contentCss = {},
}: PanelProps) => (
  <StyledPanel
    variant={variant}
    css={mergeCss({ padding: "$4" }, css)}
    onClick={onClick}
  >
    {icon && (
      <IconContainer>
        <Icon size={iconSize} variant={icon} color={iconColor} />
      </IconContainer>
    )}

    <Content css={mergeCss({}, contentCss)}>
      {heading && <Heading>{heading}</Heading>}
      {children}
    </Content>
  </StyledPanel>
);
