import React, { useRef, useEffect } from "react";
import { Box } from "src/ccl/layout";

const addUpListener = (fn: EventListener, useCapture: boolean) =>
  document.addEventListener("mouseup", fn, { capture: useCapture });
const removeUpListener = (fn: EventListener, useCapture: boolean) =>
  document.removeEventListener("mouseup", fn, { capture: useCapture });

const addDownListener = (fn: EventListener, useCapture: boolean) =>
  document.addEventListener("mousedown", fn, { capture: useCapture });
const removeDownListener = (fn: EventListener, useCapture: boolean) =>
  document.removeEventListener("mousedown", fn, { capture: useCapture });

interface OutsideClickHandlerProps {
  onOutsideClick: () => void;
  children?: React.ReactNode;
}
export const OutsideClickHandler = ({
  children,
  onOutsideClick,
}: OutsideClickHandlerProps) => {
  const useCapture = true;
  const childNode = useRef<HTMLDivElement>(null);
  let removeMouseUp: null | (() => void) = null;

  const onMouseUp: EventListener = (e) => {
    if (e.target instanceof Element) {
      const isDescendantOfRoot =
        e.target && childNode.current?.contains(e.target);
      if (removeMouseUp) {
        removeMouseUp();
        removeMouseUp = null;
      }

      if (!isDescendantOfRoot) {
        onOutsideClick();
      }
    }
  };

  const onMouseDown: EventListener = (e) => {
    if (e.target instanceof Element) {
      const isDescendantOfRoot = childNode.current?.contains(e.target);
      if (!isDescendantOfRoot) {
        if (removeMouseUp) {
          removeMouseUp();
          removeMouseUp = null;
        }
        addUpListener(onMouseUp, useCapture);
        removeMouseUp = () => removeUpListener(onMouseUp, useCapture);
      }
    }
  };

  useEffect(() => {
    addDownListener(onMouseDown, useCapture);
    return () => {
      removeUpListener(onMouseUp, useCapture);
      removeDownListener(onMouseDown, useCapture);
    };
  }, [useCapture]);

  return <Box ref={childNode}>{children}</Box>;
};
