import React from "react";
import { styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";

const StyledLogoSvg = styled("svg", {
  display: "block",
  width: 81,
  height: 12,

  "@bp2": {
    width: 135,
    height: 20,
  },

  "@bp5": {
    width: 200,
    height: 30,
  },
});

const StyledMarkSvg = styled("svg", {
  display: "block",
  width: 13,
  height: 15,

  "@bp2": {
    width: 22,
    height: 25,
  },
});

const StyledBigMarkSvg = styled("svg", {
  display: "block",
  width: 20,
  height: 22,
});

const logoSvg = (fill: string) => (
  <StyledLogoSvg
    viewBox="0 0 135 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Back to homepage</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0327 6.03637C13.8874 4.61818 12.4897 3.16364 9.47616 3.16364C6.17221 3.16364 4.26609 4.78182 4.26609 7.81818C4.26609 10.8546 6.15406 12.4727 9.47616 12.4727C12.4897 12.4727 13.8874 11.0546 14.0146 9.29088H18.6618C18.5711 13.3091 15.249 15.6182 9.47616 15.6182C3.72148 15.6182 0 13.1273 0 7.80001C0 2.47273 3.72148 0 9.47616 0C15.2671 0 18.4803 2.3091 18.6255 6.03637H14.0327ZM59.235 0.436358V15.1636H54.8963L45.8922 5.12727H45.7833V15.1636H42.0799V0.436358H46.5094L55.4228 10.3636H55.5317V0.436358H59.235ZM72.1604 3.63636H78.732L78.7502 0.436358H61.7221V3.63636H68.2937V15.1818H72.1604V3.63636ZM90.7312 12.491H82.1992L80.856 15.1818H76.5717L84.16 0.454556H88.8256L96.3776 15.1818H92.0752L90.7312 12.491ZM83.6696 9.52728H89.2608C87.5184 6.00002 86.52 3.85456 86.52 3.85456H86.4112C86.4112 3.85456 85.3944 6.00002 83.6696 9.52728ZM106.398 3.16364C109.411 3.16364 110.81 4.61818 110.954 6.03637H115.547C115.402 2.30909 112.189 0 106.398 0C100.643 0 96.904 2.52727 96.904 7.83637C96.904 13.1454 100.625 15.6364 106.38 15.6364C112.171 15.6364 115.474 13.3273 115.584 9.29088H110.936C110.81 11.0546 109.411 12.4727 106.398 12.4727C103.076 12.4727 101.188 10.8546 101.188 7.81818C101.188 4.78182 103.094 3.16364 106.398 3.16364ZM134.881 3.63636H128.31V15.1818H124.442V3.63636H117.871V0.436358H134.881V3.63636ZM35.5266 11.7273V3.87272H25.2334V11.7273H35.5266ZM21.8025 0.436358H38.9576V20H21.8025V0.436358Z"
      fill={fill}
    />
  </StyledLogoSvg>
);

const markSvg = (fill: string) => (
  <StyledMarkSvg
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Back to homepage</title>
    <path
      d="M19.2 4.74698V15.5972H4.8V4.74698H19.2ZM24 0H0V27H24V0Z"
      fill={fill}
    />
  </StyledMarkSvg>
);
const bigMarkSvg = (fill: string) => (
  <StyledBigMarkSvg
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Back to homepage</title>
    <path
      d="M19.2 4.74698V15.5972H4.8V4.74698H19.2ZM24 0H0V27H24V0Z"
      fill={fill}
    />
  </StyledBigMarkSvg>
);

interface LogoProps {
  variant: "full" | "mark" | "bigMark";
  color?: keyof typeof tokens.colors;
}

export const LegacyLogo = ({ variant, color = "black" }: LogoProps) => {
  if (variant === "full") {
    return logoSvg(tokens.colors[color]);
  } else if (variant === "mark") {
    return markSvg(tokens.colors[color]);
  } else {
    return bigMarkSvg(tokens.colors[color]);
  }
};
