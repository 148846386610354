import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { JobsListItem } from ".";
import { Loading } from "src/components";
import { ApiError } from "src/components/errors";
import { Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { Box, Grid } from "src/ccl/layout";

import { extractNodes } from "src/utils/lang";
import { Query, Job, JobState, SortDirection } from "src/graphql/types";
import { styled } from "src/ccl";
import { BOOKER_DASHBOARD_LIST_JOBS_QUERY } from "src/graphql/queries";

const InternallyBorderedGrid = styled(Grid, {
  "& > *": {
    "@bp2": {
      borderTop: "1px $grey2 solid",
      pt: "$6",
      mb: "$6",
    },
  },
});

const JOBS_PER_PAGE = 10;

interface JobsListProps {
  states: JobState[];
  sortOrder?: "Asc" | "Desc";
}

export const JobsList = ({ states, sortOrder = "Asc" }: JobsListProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { loading, error, data, fetchMore } = useQuery<Query>(
    BOOKER_DASHBOARD_LIST_JOBS_QUERY,
    {
      variables: {
        first: JOBS_PER_PAGE,
        filters: {
          states: states,
        },
        sortOrder: {
          direction: SortDirection[sortOrder],
          column: "start_date",
        },
      },
    },
  );

  const jobResult = data?.me?.jobs;
  const jobs = extractNodes<Job>(jobResult);

  if (loading) {
    return <Loading css={{ minHeight: "60vh" }} />;
  }

  if (error) {
    return <ApiError />;
  }

  return (
    <Box>
      {jobs.length === 0 && <Text>No jobs at the moment.</Text>}

      {jobs.length > 0 && (
        <InternallyBorderedGrid
          css={{
            gridTemplateColumns: "1fr",
            "@bp2": { gridTemplateColumns: "1fr 1fr 1fr" },
            "@bp5": { gridTemplateColumns: "2fr 1fr 1fr" },
          }}
        >
          {jobs.map((job) => (
            <JobsListItem key={job.id} job={job} />
          ))}
        </InternallyBorderedGrid>
      )}

      {!loading && jobResult?.pageInfo.hasNextPage && (
        <Box css={{ textAlign: "center", my: "$6" }}>
          <Button
            variant="primary"
            disabled={isLoadingMore}
            onClick={async () => {
              setIsLoadingMore(true);
              await fetchMore({
                variables: {
                  first: JOBS_PER_PAGE,
                  after: jobResult.pageInfo.endCursor || "",
                },
              });
              setIsLoadingMore(false);
            }}
          >
            {isLoadingMore ? "Loading..." : "Load more"}
          </Button>
        </Box>
      )}
    </Box>
  );
};
