import { forwardRef } from "react";
import { ApolloError } from "@apollo/client";
import { DragOverlay, Over } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { Asset } from "./Asset";
import { Box, Flex, Grid } from "src/ccl/layout";
import { SortableItem } from "src/ccl/sorting";
import { Icon, Text } from "src/ccl/document";
import { Scalars } from "src/graphql/types";
import { ValidationBlock } from "src/ccl/feedback";
import { FileDragDropUpload } from "src/ccl/data-entry";
import { UploadedFile } from "src/entities";
import { DragHandle } from "src/components/DragHandle";
import { dropAnimationShrinkToTarget } from "src/utils/dragAndDrop";

const EmptyContent = () => (
  <Box css={{ py: "$9" }}>
    <Icon variant="gallery" size={60} color="grey3" />
    <Text
      css={{
        textDecoration: "underline",
        color: "$grey4",
        mb: "10px",
      }}
    >
      Upload photos
    </Text>
    <Text variant="meta" css={{ color: "$grey6" }}>
      or drag photos here
    </Text>
    <Text variant="meta" css={{ color: "$grey6" }}>
      (max size per photo 5MB)
    </Text>
  </Box>
);

export const AssetDragOverlay = forwardRef<
  HTMLDivElement,
  {
    item: SortableImage;
    index: number;
  }
>(({ item, index, ...props }, ref) => (
  <Flex css={{ height: "100%", width: "100%" }} ref={ref} {...props}>
    <DragHandle index={index} />
    <Asset item={item} imageAlt="" />
  </Flex>
));

interface SortableImage {
  id: string;
  mediaUrl: string;
  sortWeight: number;
}

export const SortableSelectableAssetGrid = ({
  existingAssets,
  onUpload,
  onRemove,
  context,
  loading = false,
  error,
  onItemClick,
  activeItemIndex,
  hideMobileUploader = false,
  isSelecting,
  selectedAssetIds,
  onSelect,
  dropTarget,
}: {
  onItemClick: (item: SortableImage) => void;
  existingAssets: SortableImage[];
  onRemove: (item: SortableImage) => void;
  onUpload: (newAssets: UploadedFile[]) => void;
  context?: "addTalent";
  loading?: boolean;
  error?: ApolloError;
  activeItemIndex?: number;
  hideMobileUploader?: boolean;
  isSelecting: boolean;
  selectedAssetIds: Scalars["ID"][];
  onSelect: (item: SortableImage) => void;
  dropTarget?: Over;
}) => {
  const activeItem =
    activeItemIndex !== undefined ? existingAssets[activeItemIndex] : undefined;

  return (
    <Box>
      <SortableContext items={existingAssets}>
        {error && (
          <ValidationBlock
            variant="error"
            title={error.name}
            body={error.message}
          />
        )}
        <Grid
          css={{
            "@sm": { gridColumns: context === "addTalent" ? 2 : 2 },
            "@lg": { gridColumns: context === "addTalent" ? 3 : 3 },
            "@xl": { gridColumns: context === "addTalent" ? 4 : 4 },
            gridColumns: existingAssets.length === 0 ? 1 : undefined,
            gridGap: "$3",
          }}
        >
          <FileDragDropUpload
            attachmentStoreName="uploads"
            maxFileCount={100}
            contentNoFiles={<EmptyContent />}
            contentWithFiles={<EmptyContent />}
            onUpload={onUpload}
            existingFileCount={existingAssets.length}
            type="image"
            multiple={true}
            showPreviewList={false}
            withBorder={true}
            loading={loading}
            containerCss={{
              display: hideMobileUploader ? "none" : "flex",
              border: "1px dashed $grey2",
              borderRadius: "$lg",
              height: existingAssets.length ? "100%" : "350px",
            }}
          />
          {existingAssets.map((item, index) => (
            <SortableItem
              hideDragHandle={isSelecting}
              key={index}
              index={index}
              item={item}
              component={(props) => (
                <Asset
                  onSelect={onSelect}
                  onClick={onItemClick}
                  imageAlt={`Image number ${index + 1}`}
                  onRemove={onRemove}
                  isSelecting={isSelecting}
                  selected={selectedAssetIds.includes(props.item.id)}
                  {...props}
                />
              )}
            />
          ))}
        </Grid>
      </SortableContext>
      <DragOverlay
        style={{ transformOrigin: "0 0 " }}
        dropAnimation={dropTarget && dropAnimationShrinkToTarget(dropTarget)}
      >
        {activeItem && (
          <AssetDragOverlay item={activeItem} index={activeItemIndex || 0} />
        )}
      </DragOverlay>
    </Box>
  );
};
