import React from "react";
import { JobDetailItem } from "./JobDetailItem";

export function OptionalDetailItem<T>({
  object,
  field,
  title,
  body,
}: {
  object: T;
  field: keyof T;
  title: React.ReactNode;
  body: React.ReactNode;
}) {
  return field && object[field] ? (
    <JobDetailItem title={title} body={body} />
  ) : (
    <></>
  );
}
