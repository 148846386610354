import React from "react";
import { formatDistanceToNow } from "date-fns";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Link } from "src/ccl/navigation";
import { Job, JobState, JobTalent, JobTalentState } from "src/graphql/types";
import { jobTalentStateTitle } from "src/utils/jobTalent";
import { jobTalentStates } from "src/ccl/stitches/theme";
import { RejectionReasonTooltip } from "src/utils/rejectionReasonTooltip";

const NotificationBlock = ({
  body,
  icon,
}: {
  body: string;
  icon: AvailableIcon;
}) => (
  <Flex css={{ alignItems: "center" }}>
    <Icon variant={icon} size={14} />
    <Text variant="meta" css={{ ml: "$2" }}>
      {body}
    </Text>
  </Flex>
);

const MetaBlock = ({
  jobState,
  jobTalent,
}: {
  jobTalent: JobTalent;
  jobState: JobState;
}) => {
  if (jobState === JobState.Approved) {
    if (jobTalent.nudgedAt) {
      return (
        <NotificationBlock
          icon="plane"
          body={`Last nudged ${formatDistanceToNow(
            new Date(jobTalent.nudgedAt),
          )} ago`}
        />
      );
    }

    if (jobTalent.notifiedAt) {
      return (
        <NotificationBlock
          icon="plane"
          body={`Last notified ${formatDistanceToNow(
            new Date(jobTalent.notifiedAt),
          )} ago`}
        />
      );
    }
  }

  return null;
};

interface TalentStateLabelProps {
  jobTalent: JobTalent;
  jobState: JobState;
  conflictingJob?: Job;
  canChangeResponseTalent: boolean;
  onChangeResponseTalent: () => void;
}

export const TalentStateLabel = ({
  jobTalent,
  jobState,
  conflictingJob,
  canChangeResponseTalent,
  onChangeResponseTalent,
}: TalentStateLabelProps) => {
  return (
    <>
      {conflictingJob && jobState !== JobState.Completed && (
        <Flex
          css={{
            alignItems: "baseline",
            mb: "$2",
          }}
        >
          <Icon
            variant="alert"
            color="red"
            size={14}
            css={{ width: 18, mt: "$2" }}
          />
          <Text variant="meta" css={{ ml: "$2" }}>
            Date clash, is booked for{" "}
            <Link to={`/dashboard/agent/jobs/${conflictingJob.slug}`}>
              {conflictingJob.name}
            </Link>
          </Text>
        </Flex>
      )}

      {jobState === JobState.PendingApproval ? (
        <Text variant="meta" color="grey6">
          Will be emailed after job approval
        </Text>
      ) : (
        <Box>
          <Flex css={{ alignItems: "center" }}>
            {jobTalent.state === JobTalentState.Rejected && (
              <RejectionReasonTooltip jobTalent={jobTalent} />
            )}
            <Text
              variant="meta"
              css={{
                mb: "$2",
                color: jobTalentStates[jobTalent.state],
                textDecoration: canChangeResponseTalent ? "underline" : "none",
                cursor: canChangeResponseTalent ? "pointer" : "",
              }}
              onClick={() => {
                if (canChangeResponseTalent) {
                  onChangeResponseTalent();
                }
              }}
            >
              {jobTalentStateTitle(jobTalent.state)}
            </Text>
          </Flex>

          <MetaBlock jobState={jobState} jobTalent={jobTalent} />
        </Box>
      )}
    </>
  );
};
