import { useState } from "react";
import { Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { ExternalLink } from "src/ccl/navigation";
import { SocialMediaPlatform, TalentProfile, User } from "src/graphql/types";
import { useStoreModel } from "src/hooks";
import { convertFollowerCount, getSocialMedia } from "src/utils/socialMedia";

interface LinksSectionProps {
  talent: User;
}

export const SocialMediaLinksSection = ({ talent }: LinksSectionProps) => {
  const { loggedIn } = useStoreModel("currentUser");
  const profile = talent.profile as TalentProfile;

  const instragram = getSocialMedia(profile, SocialMediaPlatform.Instagram);
  const tiktok = getSocialMedia(profile, SocialMediaPlatform.Tiktok);
  const youtube = getSocialMedia(profile, SocialMediaPlatform.Youtube);

  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };

  return (
    <Flex css={{ gap: "$5" }}>
      {instragram && (
        <ExternalLink
          to={loggedIn ? `https://www.instagram.com/${instragram.handle}` : ""}
          onClick={(e) => {
            if (!loggedIn) {
              e.preventDefault();
              toggleSignInModal();
            }
          }}
          target="_blank"
          eventName={"talentProfile:instagramLink"}
          css={{
            display: "flex",
            alignItems: "center",
            fontSize: "$14",
            lineHeight: "$22",
            textDecoration: "none",
          }}
        >
          <Flex css={{ alignItems: "center", gap: "$3" }}>
            <Icon variant="instagram" size={30} color="grey5" />
            <Flex css={{ flexDirection: "column" }}>
              <Text variant="mini">
                {convertFollowerCount(instragram.followerCount)}
              </Text>
            </Flex>
          </Flex>
        </ExternalLink>
      )}

      {tiktok && (
        <ExternalLink
          to={loggedIn ? `https://www.tiktok.com/@${tiktok.handle}` : ""}
          onClick={(e) => {
            if (!loggedIn) {
              e.preventDefault();
              toggleSignInModal();
            }
          }}
          target="_blank"
          eventName={"talentProfile:tiktokLink"}
          css={{
            display: "flex",
            alignItems: "center",
            fontSize: "$14",
            lineHeight: "$22",
            textDecoration: "none",
          }}
        >
          <Flex css={{ alignItems: "center", gap: "$3" }}>
            <Icon variant="tiktok" size={30} color="grey5" />
            <Flex css={{ flexDirection: "column" }}>
              <Text variant="mini">
                {convertFollowerCount(tiktok.followerCount)}
              </Text>
            </Flex>
          </Flex>
        </ExternalLink>
      )}

      {youtube && (
        <ExternalLink
          to={loggedIn ? `https://www.youtube.com/@${youtube.handle}` : ""}
          onClick={(e) => {
            if (!loggedIn) {
              e.preventDefault();
              toggleSignInModal();
            }
          }}
          target="_blank"
          eventName={"talentProfile:tiktokLink"}
          css={{
            display: "flex",
            alignItems: "center",
            fontSize: "$14",
            lineHeight: "$22",
            textDecoration: "none",
          }}
        >
          <Flex css={{ alignItems: "center", gap: "$3" }}>
            <Icon variant="youtube" size={30} color="grey5" />
            <Flex css={{ flexDirection: "column" }}>
              <Text variant="mini">
                {convertFollowerCount(youtube.followerCount)}
              </Text>
            </Flex>
          </Flex>
        </ExternalLink>
      )}
    </Flex>
  );
};
