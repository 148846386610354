import { Action, action } from "easy-peasy";
import { CurrentUserModel } from "./currentUser";

type PersonalInfo = {
  firstName: string;
  lastName: string;
  phone: string;
};

export interface DraftModel {
  email: string;
  personalInfo: PersonalInfo;
  setEmail: Action<CurrentUserModel, string>;
  setPersonalInfo: Action<CurrentUserModel, PersonalInfo>;
}

export const draftModel: DraftModel = {
  email: "",
  personalInfo: {
    firstName: "",
    lastName: "",
    phone: "",
  },
  setEmail: action((state, payload) => {
    state.email = payload;
  }),
  setPersonalInfo: action((state, payload) => {
    state.personalInfo = payload;
  }),
};
