import { debounce, isEqual } from "lodash";
import { useMemo, useState } from "react";

/**
 * Written for use in TextFilters, where resetting the core searchParams state would not remove
 * the displayed text value, instead keeping whatever was previously typed.
 *
 * The use of lodash's debounce function ensures the callback is only called after the
 * input stops changing (i.e. when the user stops typing). This works great.
 *
 * The problem comes when the initialValue state is cleared further up the component Tree.
 * The component re-renders with the new initialValue (i.e. "" in the case of a TextFilter
 * where the core state has been reset) but the stored internal value is still set to whatever
 * the user last typed. Storing the `changing` state lets us check whether to replace the
 * internal state with the provided initialState on re-render.
 */
export function useDebouncedInputValue<T>(
  initialValue: T,
  callback: (value: T) => void,
): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(initialValue);
  const [changing, setChanging] = useState(false);

  if (!changing && !isEqual(value, initialValue)) {
    setValue(initialValue);
  }

  const debouncedCallback = debounce((value: T) => {
    callback(value);
    setChanging(false);
  }, 300);
  const memodCallback = useMemo(() => debouncedCallback, []);

  const changeHandler = (value: T) => {
    if (!changing) {
      setChanging(true);
    }
    setValue(value);
    memodCallback(value);
  };

  return [value, changeHandler];
}
