import { authModel, AuthModel } from "./auth";
import { DraftModel, draftModel } from "./draft";
import { KindsModel, kindsModel } from "./kinds";
import { utilsModel, UtilsModel } from "./utils";
import {
  customerIOModel,
  CustomerIOModel,
} from "src/models/currentUser/customerio";

export type CurrentUserModel = AuthModel &
  KindsModel &
  UtilsModel &
  CustomerIOModel &
  DraftModel;

export const currentUserModel: CurrentUserModel = {
  ...authModel,
  ...kindsModel,
  ...utilsModel,
  ...customerIOModel,
  ...draftModel,
};
