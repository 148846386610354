import { gql } from "@apollo/client";
import { PACKAGE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const PACKAGE_QUERY = gql`
  query Package($id: ID!) {
    package(id: $id) {
      ...PackageDetails
    }
  }
  ${PACKAGE_DETAILS_FRAGMENT}
`;
