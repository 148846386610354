import { isBefore, isEqual, isAfter } from "date-fns";

export const numArrayOfLength = (n: number) =>
  Array.from({ length: n }, (_, i) => i + 1);

export const isEqualDate = (
  day: number,
  monthAndYear: Date,
  startDate: Date,
) => {
  monthAndYear.setDate(day);
  return isEqual(startDate, monthAndYear);
};

export const isInRange = (
  day: number,
  monthAndYear: Date,
  startDate: Date,
  endDate?: Date,
) => {
  monthAndYear.setDate(day);
  return endDate && startDate <= monthAndYear && monthAndYear <= endDate;
};

export const isBeforeDate = (
  day: number,
  monthAndYear: Date,
  startDate: Date,
) => {
  monthAndYear.setDate(day);
  return isBefore(monthAndYear, startDate);
};

export const isAfterDate = (day: number, monthAndYear: Date, endDate: Date) => {
  monthAndYear.setDate(day);
  return isAfter(monthAndYear, endDate);
};
