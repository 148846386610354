import React from "react";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches";
import { Link, Dropdown } from "src/ccl/navigation";
import { UserKindContext } from "src/hooks";
import { Box } from "src/ccl/layout";
import { sendToAmplitude } from "src/utils/analytics";

const DropdownLauncher = styled("span", {
  fontSize: "$12",
  marginRight: "$11",
  lineHeight: "$18",
  fontWeight: "$bold",
  textTransform: "uppercase",
  display: "none",
  alignItems: "center",

  "@bp2": {
    display: "flex",
  },

  "&:hover": {
    color: "$grey6",
  },
});

const DropdownIcon = styled(Icon, {
  marginLeft: "$3",
});

const DropdownVertical = styled(Link, {
  display: "flex",
  minWidth: "360px",
  textDecoration: "none",
  alignItems: "center",
  py: "$4",
  px: "$5",

  "&:hover": {
    backgroundColor: "$grey1",
  },
});

const DropdownVerticalDescription = styled("div", {
  marginLeft: "$5",
});

const DropDownItem = ({
  amplitudeEvent,
  copy,
  icon,
  linkTo,
  title,
}: {
  amplitudeEvent?: string;
  copy: string;
  icon: AvailableIcon;
  linkTo: string;
  title: string;
}) => (
  <DropdownVertical
    to={linkTo}
    onClick={() => amplitudeEvent && sendToAmplitude(amplitudeEvent)}
  >
    <Icon variant={icon} size={40} />
    <DropdownVerticalDescription>
      <Text css={{ fontWeight: "$bold" }}>{title}</Text>
      <Text variant="meta" color="grey6">
        {copy}
      </Text>
    </DropdownVerticalDescription>
  </DropdownVertical>
);

export const ForYouDropdown = ({ context }: { context: UserKindContext }) => (
  <Dropdown
    position="left"
    launcher={
      <DropdownLauncher data-test-id="HeaderDropdown">
        Contact for you
        <DropdownIcon variant="chevronDown" size={16} color="black" />
      </DropdownLauncher>
    }
  >
    {context === "signedOut" && (
      <>
        <DropDownItem
          amplitudeEvent="top nav - clicks contact for you for clients"
          copy="Book diverse creative talent"
          icon="bookersVertical"
          title="For clients"
          linkTo="/for/clients"
        />
        <DropDownItem
          copy="Be your own boss"
          icon="talentVertical"
          title="For creatives"
          linkTo="/for/creatives"
        />

        <Box
          css={{
            height: "1px",
            background: "$grey3",
            display: "block",
            my: "$4",
          }}
        />
      </>
    )}

    <DropDownItem
      amplitudeEvent="top nav - contact for you backstage"
      copy="Blogging from behind the scenes"
      icon="backstageVertical"
      title="Backstage"
      linkTo="/backstage"
    />

    {context !== "signedOut" && (
      <DropDownItem
        amplitudeEvent="top nav - contact for you creative paths"
        copy="Listen to Creative Paths"
        icon="creativePathsVertical"
        title="Podcast"
        linkTo="/creative-paths"
      />
    )}
  </Dropdown>
);
