import { gql } from "@apollo/client";

export const ONBOARD_BOOKER_MUTATION = gql`
  mutation OnboardBooker(
    $firstName: String
    $lastName: String
    $password: String
    $phoneNumber: String
    $token: String!
  ) {
    onboardBooker(
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      token: $token
    ) {
      id
    }
  }
`;
