import React from "react";
import { styled } from "src/ccl/stitches";
import { Flex } from "src/ccl/layout";
import { Text, Icon, IconProps } from "src/ccl/document";
import { Link } from "src/ccl/navigation";
import { sendToAmplitude } from "src/utils/analytics";
import { Loading } from "src/components";
import { useStoreModel } from "src/hooks";

export interface StatCardProps {
  context: "nextJob" | "upcomingJobs" | "completedJobs";
  icon: IconProps["variant"];
  text: string;
  stat: number | null;
  amplitudeTracking: string;
  loading: boolean;
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  minWidth: "206px",
  minHeight: "113px",
  borderRadius: "9px",
  background: "#141414",
  "@bp2": {
    width: "330px",
    height: "125px",
  },
  "@bp5": {
    maxWidth: "206px",
    height: "125px",
  },

  variants: {
    state: {
      nextJob: {
        background: "linear-gradient(110.44deg, #228390 0.88%, #60A6B0 100%)",
      },
      noNextJob: {
        background: "$black",
      },
      upcomingJobs: {
        background:
          "linear-gradient(137.59deg, rgba(251, 230, 255, 0.9) 16.63%, rgba(217, 250, 225, 0.9) 92.7%)",
      },
      completedJobs: {
        background:
          "linear-gradient(135.84deg, rgba(103, 103, 103, 0.2) 12.05%, rgba(184, 184, 184, 0) 110.49%)",
      },
    },
  },
});

const Box = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  borderRadius: "9px",
  justifyContent: "flex-end",
  padding: "$6",
  "@bp5": { paddingRight: "0px" },
});

export const StatCard = (props: StatCardProps) => {
  const {
    draftV2: { talentUsers },
  } = useStoreModel("jobs");
  const { context, stat, icon, text, amplitudeTracking, loading } = props;
  const color = context === "nextJob" ? "white" : "black";
  return (
    <Container
      state={context === "nextJob" && stat === null ? "noNextJob" : context}
    >
      <Link
        css={{
          textDecoration: "none",
          minHeight: "inherit",
          minWidth: "inherit",
        }}
        onClick={() => sendToAmplitude(amplitudeTracking)}
        to={
          context === "nextJob" && stat === null
            ? "/jobs/new/shortlist"
            : "/dashboard/client/jobs"
        }
      >
        {!loading && context === "nextJob" && stat === null ? (
          <Flex
            css={{
              flexDirection: "column",
              height: "100%",
              justifyContent: "flex-end",
              padding: "$4",
              gap: "20px",
            }}
          >
            <Icon variant="plusCircle" size={30} />
            <Flex
              css={{
                textDecoration: "none",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                variant="h2"
                weight="bold"
                color="white"
                css={{
                  display: "none",
                  "@bp2": { display: "revert" },
                  "@bp5": { display: "none" },
                }}
              >
                Create Job
              </Text>
              <Text
                variant="meta"
                weight="bold"
                color="white"
                css={{
                  textTransform: "uppercase",
                  whiteSpace: "pre-wrap",
                  "@bp2": { display: "none" },
                  "@bp5": { display: "revert" },
                }}
              >
                {talentUsers.length > 0
                  ? "Continue \ncreating job"
                  : "Create Job"}
              </Text>
              <Icon size={16} variant="chevronRight" color="white" />
            </Flex>
          </Flex>
        ) : (
          <Box data-test-id={`${context}StatCard`}>
            <Icon size={20} variant={icon} color={color} />
            {loading ? (
              <Loading />
            ) : (
              <>
                <Text variant="h2" color={color} css={{ marginTop: "$3" }}>
                  {stat} {context === "nextJob" && <>days </>}
                </Text>
                <Text variant="meta" weight="bold" color={color}>
                  {context !== "nextJob" && stat === 1
                    ? text.slice(0, -1)
                    : text}
                </Text>
              </>
            )}
          </Box>
        )}
      </Link>
    </Container>
  );
};
