import React from "react";
import { Field } from "formik";
import { Checkbox, CheckboxProps } from "..";

export const FormikCheckbox = (props: CheckboxProps) => (
  <Field
    as={Checkbox}
    type="checkbox"
    id={props.id || `form-${props.name}`}
    {...props}
  />
);
