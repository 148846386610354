import React, { useState } from "react";
import {
  InfoBanner,
  AgentTalentCardTooltip,
  Icon,
  Text,
} from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Link } from "src/ccl/navigation";
import { tokens } from "src/ccl/stitches/theme";
import { User, TalentProfile } from "src/graphql/types";
import { copyToClipboard } from "src/utils/clipboard";
import { truncate } from "src/utils/lang";

const OverlayNavItem = ({
  children,
  onClick,
  onMouseLeave,
}: {
  onClick: () => void;
  onMouseLeave: () => void;
  children: React.ReactNode;
}) => (
  <Box
    css={{ cursor: "pointer" }}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
  >
    {children}
  </Box>
);

export const AgentTalentCardOverlayNav = ({
  color = "white",
  talent: { phoneNumber, email, profile, slug },
}: {
  color?: keyof typeof tokens.colors;
  talent: User;
}) => {
  const talentProfile = profile as TalentProfile;
  const { instagramUsername } = talentProfile;
  const [copied, setCopied] = useState<boolean>(false);
  const copiedFeedback = (value: string) => (
    <Flex css={{ flexDirection: "column", alignItems: "center" }}>
      <InfoBanner icon="tick" color="white" variant="tightCentered">
        <Text variant="meta" color="white">
          Copied to clipboard
        </Text>
      </InfoBanner>
      <Text
        variant="meta"
        color="white"
        weight="bold"
        css={{ display: "block", "@bp2": { display: "none" } }}
      >
        {value}
      </Text>
    </Flex>
  );
  const handleOnClick = (value: string) => {
    copyToClipboard(value);
    setCopied(true);
  };
  return (
    <Flex
      css={{
        backgroundColor: "$black",
        position: "absolute",
        width: "100%",
        bottom: "0",
        justifyContent: "space-evenly",
        py: "$3",
        borderRadius: "0px 0px 14px 14px",
      }}
    >
      {phoneNumber && (
        <AgentTalentCardTooltip
          backgroundColor={copied ? "$green" : "$grey3"}
          content={
            copied ? (
              copiedFeedback(phoneNumber)
            ) : (
              <Text variant="meta">{phoneNumber}</Text>
            )
          }
        >
          <OverlayNavItem
            onMouseLeave={() => setCopied(false)}
            onClick={() => handleOnClick(phoneNumber || "")}
          >
            <Icon size={16} variant="phone" color={color} />
          </OverlayNavItem>
        </AgentTalentCardTooltip>
      )}

      {email && (
        <AgentTalentCardTooltip
          backgroundColor={copied ? "$green" : "$grey3"}
          content={
            copied ? (
              copiedFeedback(email as string)
            ) : (
              <Text variant="meta">{truncate(email, 22)}</Text>
            )
          }
        >
          <OverlayNavItem
            onMouseLeave={() => setCopied(false)}
            onClick={() => handleOnClick(email || "")}
          >
            <Icon size={16} variant="mail" color={color} />
          </OverlayNavItem>
        </AgentTalentCardTooltip>
      )}

      {instagramUsername && (
        <AgentTalentCardTooltip
          backgroundColor={copied ? "$green" : "$grey3"}
          content={
            copied ? (
              copiedFeedback(instagramUsername as string)
            ) : (
              <Text variant="meta">{instagramUsername}</Text>
            )
          }
        >
          <OverlayNavItem
            onMouseLeave={() => setCopied(false)}
            onClick={() => handleOnClick(instagramUsername || "")}
          >
            <Icon size={16} variant="instagram" color={color} />
          </OverlayNavItem>
        </AgentTalentCardTooltip>
      )}

      <Link to={`/dashboard/agent/talent/${slug}`}>
        <Icon size={16} variant="user" color={color} />
      </Link>
    </Flex>
  );
};
