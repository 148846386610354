import React from "react";
import { Modal } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { User } from "src/graphql/generated/types";
import { pluralize } from "src/utils/lang";
import { getTalentVerticals } from "src/utils/user";
import { ShortlistVerticals } from "src/components/ShortlistVerticals";

export interface FullSelectionModalProps {
  talent: User[];
  onClose: () => void;
  isOpen: boolean;
  isRemovable?: (talent: User) => boolean;
  onRemove?: (talent: User) => void;
  setShowShareShortlistModal?: (value: boolean) => void;
  flowContext?: "createJob" | "editJob";
  buttonVariant?: "primary" | "secondary";
  showShareShortlistButton?: boolean;
}

export const FullSelectionModal = ({
  talent,
  onClose,
  isOpen,
  isRemovable,
  onRemove,
  setShowShareShortlistModal,
  flowContext,
  buttonVariant = "primary",
  showShareShortlistButton = false,
}: FullSelectionModalProps) => {
  const talentVerticals = getTalentVerticals(talent);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton
      context={flowContext}
      title={`${pluralize(talent.length, "Creative")} shortlisted`}
      modalStyles={{
        maxHeight: "80%",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
      parentCss={{
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Flex css={{ flexDirection: "column", overflowY: "auto" }}>
        <Flex
          css={{
            overflowY: "scroll",
            flexDirection: "column",
            pr: "$4",
          }}
        >
          <ShortlistVerticals
            talent={talent}
            talentVerticals={talentVerticals}
            isRemovable={isRemovable}
            onRemove={onRemove}
          />
        </Flex>
        <Flex
          css={{
            justifyContent: "space-between",
            pt: "$6",
            flexDirection: "column-reverse",
            "@bp1": { flexDirection: "row" },
          }}
        >
          <Button
            data-test-id="FullSelectionModalClose"
            variant={buttonVariant}
            onClick={onClose}
          >
            Close
          </Button>
          {showShareShortlistButton && (
            <Button
              variant="primary"
              onClick={
                setShowShareShortlistModal &&
                (() => setShowShareShortlistModal(true))
              }
              css={{ mb: "$5", "@bp1": { width: "initial", mb: 0 } }}
            >
              Share shortlist
            </Button>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
