import { gql } from "@apollo/client";
import { TALENT_PROFILE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const AGENCY_TALENT_USER_QUERY = gql`
  query AgentTalentUser($id: ID!, $slug: ID) {
    agency(id: $id) {
      id
      name
      talent(id: $slug) {
        id
        name
        firstName
        lastName
        slug
        email
        phoneNumber
        addressLine1
        addressLine2
        city
        postcode
        country
        featuredAsset {
          id
          height
          width
          mediaUrl
        }
        notes {
          id
          body
          createdAt
          user {
            id
            name
            featuredAsset {
              id
              height
              width
              mediaUrl
            }
          }
        }
        profile {
          ... on TalentProfile {
            ...TalentProfileDetails
          }
        }
      }
    }
  }

  ${TALENT_PROFILE_DETAILS_FRAGMENT}
`;
