import React from "react";
import { googleMapsApiKey } from "src/config";

const params: { [key: string]: string } = {
  zoom: "13",
  size: "640x150",
  scale: "2",
  maptype: "roadmap",
  style: "saturation:-100",
  key: googleMapsApiKey,
};

const paramString = Object.keys(params)
  .map((k) => `${k}=${params[k]}`)
  .join("&");

export const MapImage = ({ location }: { location: string }) => (
  <a
    title={`Open maps at ${location}`}
    href={`https://www.google.com/maps/search/${location}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      alt={`Map of ${location}`}
      src={`https://maps.googleapis.com/maps/api/staticmap?center=${location}&${paramString}`}
    />
  </a>
);
