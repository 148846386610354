import React from "react";
import { ComponentProps } from "@stitches/react";
import { InputStyles } from ".";
import { styled } from "src/ccl";

const StyledTextarea = styled("textarea", {
  ...InputStyles,
  ...{
    height: "auto",
  },
});
export interface TextareaProps extends ComponentProps<typeof StyledTextarea> {
  variant?: "rebrand";
}

export const Textarea = (props: TextareaProps) => (
  <StyledTextarea id={props.id || `form-${props.name}`} {...props} />
);
