import { Link } from "..";
import { styled } from "src/ccl/stitches";

export const HeaderLink = styled(Link, {
  textDecoration: "none",
  fontSize: "$12",
  lineHeight: "$18",
  fontWeight: "$bold",
  textTransform: "uppercase",
  marginRight: "$11",
});
