import React, { useState, useEffect } from "react";
import { SocialMediaLinksSection } from "./SocialMediaLinksSection";
import { Box, ContentContainer, Flex, Grid } from "src/ccl/layout";
import {
  ProfileAttribute,
  TalentProfile,
  TalentVertical,
  User,
  VerticalConfiguration,
} from "src/graphql/types";
import { useHandleBookmark, useStoreModel } from "src/hooks";
import { Icon, SignInModal, Text } from "src/ccl/document";
import { VerticalTitle } from "src/components/talent/talentProfile/VerticalTitle";
import { verticalMap } from "src/utils/user";
import { Biography } from "src/components/talent/talentProfile/Biography";
import { MainDetails, TalentProfileMeasurements } from "src/components";
import { Button } from "src/ccl/navigation";

export interface VerticalTalentProfileHeaderProps {
  vertical: TalentVertical;
  talent: User;
  profileAttributes: ProfileAttribute[];
  isRemovable: boolean;
  isShortlisted: boolean;
  showShortlistButton: boolean;
  shortlistButtonClick: () => void;
  handleBookmark?: (isChecked: boolean, talentId: string) => void;
  isBookmarkDisabled?: boolean;
}

interface TalentProfileHeaderProps {
  verticalConfig: VerticalConfiguration;
  talent: User;
  isRemovable: boolean;
  isShortlisted: boolean;
  shortlistButtonClick: (shortlisted: boolean) => void;
  showShortlistButton: boolean;
  onAddToCollection?: (talent: User) => void;
}

export const TalentProfileHeader = ({
  verticalConfig,
  talent,
  isRemovable,
  isShortlisted,
  shortlistButtonClick,
  showShortlistButton,
  onAddToCollection,
}: TalentProfileHeaderProps) => {
  const { isBooker, loggedIn } = useStoreModel("currentUser");
  const { handleBookmark: toggleBookmark, loading: bookmarkLoading } =
    useHandleBookmark();
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };
  const handleBookmark = isBooker
    ? toggleBookmark
    : !loggedIn
    ? toggleSignInModal
    : undefined;

  useEffect(() => {
    if (loggedIn && signInModalOpen) {
      toggleSignInModal();
    }
  }, [loggedIn]);

  const vertical = verticalConfig.id;
  const profile = talent.profile as TalentProfile;
  const creativeType: string[] =
    JSON.parse(
      profile.verticalData?.find((d) => d.key === "creative_type")?.value ||
        "null",
    ) || [];
  const prettyCreativeType: string =
    creativeType.length === 1
      ? creativeType[0].replace(/_/g, " ")
      : verticalMap[vertical];
  const props = {
    vertical: vertical,
    talent: talent,
    profileAttributes: verticalConfig.profileAttributes,
    isRemovable: isRemovable,
    isShortlisted: isShortlisted,
    shortlistButtonClick: () => shortlistButtonClick(isShortlisted),
    showShortlistButton: showShortlistButton,
    handleBookmark: handleBookmark,
    isBookmarkDisabled: !isBooker || bookmarkLoading,
  };

  return (
    <ContentContainer variant="noGutter" css={{ py: "$5", height: "100%" }}>
      <SignInModal isOpen={signInModalOpen} onClose={toggleSignInModal} />

      <Box
        data-test-id="TalentProfileHeader"
        css={{
          px: "$8",
          height: "100%",
          "@bp1": {
            position: "relative",
          },
        }}
      >
        <VerticalTitle
          vertical={vertical}
          category={talent.groups?.[0]?.name}
          creativeType={prettyCreativeType}
        />

        <Box
          css={{
            order: 1,
            "@bp2": {
              order: "inherit",
            },
          }}
        >
          <Flex css={{ gap: "$5", flexDirection: "column" }}>
            <MainDetails
              isLegacy={false}
              talent={talent}
              isBookmarkDisabled={!isBooker || bookmarkLoading}
              handleBookmark={handleBookmark}
              onAddToCollection={
                onAddToCollection ? () => onAddToCollection(talent) : undefined
              }
            />
            <SocialMediaLinksSection talent={talent} />
          </Flex>
          <Box css={{ mt: "$9" }}>
            {profile.scrubbedBiography &&
              profile.scrubbedBiography.trim() !== "" && (
                <Biography body={profile.scrubbedBiography} lineCount={3} />
              )}
          </Box>
          {/* Interests and Past Clients */}
          <Grid
            css={{
              gridTemplateColumns: "1fr",
              gap: "$5",
              mt: "$9",
              "@bp1": { gridTemplateColumns: "1fr 1fr" },
            }}
          >
            {profile.hobbies && profile.hobbies.length > 0 && (
              <Box>
                <Flex css={{ alignItems: "center", gap: "$3" }}>
                  <Icon size={18} variant="interestsBulb" color="grey5" />
                  <Text
                    variant="title"
                    css={{ textTransform: "none", color: "$grey5" }}
                  >
                    Interests
                  </Text>
                </Flex>
                <Text css={{ mt: "$3" }}>{profile.hobbies.join(", ")}</Text>
              </Box>
            )}
            {profile.pastClients && profile.pastClients.length > 0 && (
              <Box>
                <Flex css={{ alignItems: "center", gap: "$3" }}>
                  <Icon size={18} variant="communityFilled" color="grey5" />
                  <Text
                    variant="title"
                    css={{ textTransform: "none", color: "$grey5" }}
                  >
                    Past Clients
                  </Text>
                </Flex>
                <Text css={{ mt: "$3" }}>{profile.pastClients.join(", ")}</Text>
              </Box>
            )}
          </Grid>
          {vertical === TalentVertical.FashionModel && (
            <TalentProfileMeasurements
              talent={talent}
              css={{ py: "$7", "@bp2": { pt: "$11", pb: "$0" } }}
            />
          )}
          <Box
            css={{
              position: "fixed",
              bottom: "$14",
              right: "$8",
              left: "$8",
              "@bp1": {
                position: "absolute",
                bottom: "$0",
              },
            }}
          >
            {showShortlistButton && (
              <Button
                variant={isShortlisted ? "green" : "primaryCta"}
                css={{ width: "100%", borderRadius: "$md" }}
                onClick={props.shortlistButtonClick}
                disabled={!isRemovable}
              >
                {isShortlisted ? "Shortlisted" : "Shortlist"}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </ContentContainer>
  );
};
