import React from "react";
import type * as Stitches from "@stitches/react";
import { mergeCss, styled } from "src/ccl/stitches";

const StyledGrid = styled("div", {
  display: "grid",
  variants: {
    variant: {
      offset: {
        mt: "60px",
        "& > :nth-child(odd)": { mt: "-60px" },
        "& > :nth-child(even)": { mt: "70px" },
      },
    },
  },
});

export type GridProps = Stitches.ComponentProps<typeof StyledGrid>;

export const Grid = ({ css = {}, children, ...props }: GridProps) => (
  <StyledGrid {...props} css={mergeCss({}, css)}>
    {children}
  </StyledGrid>
);
