import React from "react";
import type * as Stitches from "@stitches/react";
import { keyframes } from "@stitches/react";
import { Box } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";
import { mergeCss } from "src/ccl";

type Color = `$${keyof typeof tokens.colors}`;

type PulsatingBoxProps = Stitches.VariantProps<typeof Box> & {
  colorFrom?: Color;
  colorTo?: Color;
  css?: Stitches.CSS;
  children?: React.ReactNode;
};

const pulse = (colorFrom: Color, colorTo: Color) =>
  keyframes({
    "0%": { backgroundColor: colorFrom },
    "50%": { backgroundColor: colorTo },
    "100%": { backgroundColor: colorFrom },
  });

export const PulsatingBox = ({
  colorFrom = "$grey1",
  colorTo = "$grey2",
  css = {},
  children,
  ...props
}: PulsatingBoxProps) => (
  <Box
    css={mergeCss(
      {
        animation: `${pulse(colorFrom, colorTo)} 1.8s linear infinite`,
      },
      css,
    )}
    {...props}
  >
    {children}
  </Box>
);
