import React from "react";
import { Grid } from "src/ccl/layout/grid";
import { Text } from "src/ccl/document/text";
import { Icon } from "src/ccl/document/icon";
import { Link, ExternalLink, SupportLauncher } from "src/ccl/navigation";
import { styled } from "src/ccl/stitches";
import { Flex } from "src/ccl/layout";
import { zendeskOpen } from "src/utils/zendesk";
import { sendToAmplitude } from "src/utils/analytics";
import { useLocationAwareURL } from "src/hooks/useLocationAwareURL";
import { UserKindContext } from "src/hooks";

const StyledExternalLink = styled(ExternalLink, {
  textDecoration: "none",
  display: "flex",
});

const StyledList = styled("ul", {
  listStyleType: "none",
  margin: 0,
  padding: 0,
});

const StyledListItem = styled("li", {
  paddingTop: "$3",
  paddingBottom: "$3",
});

const linkStyles = {
  textDecoration: "none",
  color: "$grey6",
};

const ListTitle = styled(Text, {
  pb: "$6",
});

const FooterLink = styled(Link, linkStyles);
const ExternalFooterLink = styled(ExternalLink, linkStyles);

const Copyright = styled(Text, {
  color: "$grey6",
  fontSize: "$14",
});

const Meta = styled(Flex, {
  mt: "$10",
  flexDirection: "column-reverse",
  justifyContent: "space-between",
  "@bp3": {
    flexDirection: "row",
    mt: 80,
  },
});

const MetaLinks = styled(Flex, {
  flexDirection: "column",
  "@bp3": {
    flexDirection: "row",
  },
});

const MetaLink = styled("div", {
  mb: "$5",
  "@bp3": {
    ml: "$5",
    mb: 0,
  },
});

export interface FooterProps {
  context: UserKindContext;
  onSignOut?: () => void;
}

export const Footer = ({ context, onSignOut }: FooterProps) => {
  const helpEmail = useLocationAwareURL("mailto:help@{{HOST}}");
  const applyUrl = useLocationAwareURL("https://apply.{{HOST}}/");
  return (
    <footer>
      <Grid
        css={{
          alignItems: "baseline",
          gridColumns: 1,
          gridGap: "$10",
          "@bp2": { gridColumns: 2 },
          "@bp3": { gridColumns: 4 },
        }}
      >
        <div>
          <ListTitle variant="h3">Product</ListTitle>

          <StyledList>
            <StyledListItem>
              <FooterLink
                to="/for/clients"
                onClick={() => sendToAmplitude("footer - contact for clients")}
              >
                Contact for clients
              </FooterLink>
            </StyledListItem>
            <StyledListItem>
              <FooterLink to="/for/creatives">Contact for creatives</FooterLink>
            </StyledListItem>
            <StyledListItem>
              <FooterLink to="/for/agencies">
                Contact for modelling agencies
              </FooterLink>
            </StyledListItem>
            <StyledListItem>
              <FooterLink to="/l/booked-on-contact">
                Booked on Contact
              </FooterLink>
            </StyledListItem>
          </StyledList>
        </div>

        <div>
          <ListTitle variant="h3">Account</ListTitle>

          <StyledList>
            {context === "signedOut" && (
              <StyledListItem>
                <FooterLink to="/sign-in">Sign in</FooterLink>
              </StyledListItem>
            )}
            {context !== "signedOut" && (
              <StyledListItem>
                <FooterLink to="/dashboard">Dashboard</FooterLink>
              </StyledListItem>
            )}
            {(context === "signedOut" || context === "client") && (
              <StyledListItem>
                <FooterLink
                  to="/jobs/new"
                  onClick={() => sendToAmplitude("footer - book talent")}
                >
                  Book creatives
                </FooterLink>
              </StyledListItem>
            )}
            {context === "signedOut" && (
              <StyledListItem>
                <ExternalFooterLink
                  to={applyUrl}
                  eventName="footer:apply"
                  target="_blank"
                >
                  Become a model
                </ExternalFooterLink>
              </StyledListItem>
            )}
            {context !== "signedOut" && (
              <StyledListItem>
                <ExternalFooterLink
                  to="/"
                  eventName="footer:signOut"
                  onClick={(e) => {
                    e.preventDefault();
                    onSignOut && onSignOut();
                  }}
                >
                  Sign out
                </ExternalFooterLink>
              </StyledListItem>
            )}
            <StyledListItem>
              <ExternalFooterLink
                to="https://support.contact.xyz/hc/en-gb"
                target="_blank"
              >
                FAQs
              </ExternalFooterLink>
            </StyledListItem>
          </StyledList>
        </div>

        <div>
          <ListTitle variant="h3">Company</ListTitle>

          <StyledList>
            <StyledListItem>
              <FooterLink to="/about">About</FooterLink>
            </StyledListItem>
            <StyledListItem>
              <FooterLink to="/careers">Careers</FooterLink>
            </StyledListItem>
            <StyledListItem>
              <FooterLink
                to="/creative-paths"
                onClick={() => sendToAmplitude("footer - creative paths")}
              >
                Podcast
              </FooterLink>
            </StyledListItem>
            <StyledListItem>
              <FooterLink
                to="/backstage"
                onClick={() => sendToAmplitude("footer - backstage blog")}
              >
                Backstage blog
              </FooterLink>
            </StyledListItem>
            <StyledListItem>
              <ExternalFooterLink
                to={helpEmail}
                eventName="footer:contact"
                onClick={(event) => {
                  event.preventDefault();
                  zendeskOpen();
                }}
              >
                Contact us
              </ExternalFooterLink>
            </StyledListItem>
          </StyledList>
        </div>

        <div>
          <ListTitle variant="h3">Small print</ListTitle>

          <StyledList>
            <StyledListItem>
              <FooterLink to="/terms">Terms</FooterLink>
            </StyledListItem>
            <StyledListItem>
              <FooterLink to="/privacy">Privacy policy</FooterLink>
            </StyledListItem>
            <StyledListItem>
              <ExternalFooterLink
                to="#"
                eventName="footer:cookies"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.cookiehub) {
                    window.cookiehub.openSettings();
                  }
                }}
              >
                Manage cookies
              </ExternalFooterLink>
            </StyledListItem>
          </StyledList>
        </div>
      </Grid>

      <Meta>
        <Copyright>
          &copy; {new Date().getFullYear()} Contact Creatives Ltd
        </Copyright>

        <MetaLinks>
          <MetaLink>
            <SupportLauncher color="black" textVariant="meta" />
          </MetaLink>

          <MetaLink>
            <StyledExternalLink
              to="https://www.instagram.com/contact.xyz"
              target="_blank"
              eventName="footer:instagram"
            >
              <Icon size={20} variant="instagramColour" />
              <Text variant="meta" css={{ marginLeft: "$2" }}>
                Contact on Instagram
              </Text>
            </StyledExternalLink>
          </MetaLink>
        </MetaLinks>
      </Meta>
    </footer>
  );
};
