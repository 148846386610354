import { gql } from "@apollo/client";

export const CANCEL_JOB_MUTATION = gql`
  mutation Mutation(
    $jobId: ID!
    $cancellationReason: JobCancellationReason!
    $cancellationNote: String
  ) {
    cancelJob(
      jobId: $jobId
      cancellationReason: $cancellationReason
      cancellationNote: $cancellationNote
    ) {
      id
    }
  }
`;
