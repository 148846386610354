import { Action } from "easy-peasy";
import {
  MeasurementUnitPreferenceModel,
  unitPreferenceModel,
} from "./measurementUnit";
import {
  AgencyTableViewMode,
  AgencyTableModePreferenceModel,
  agencyTableModePreferenceModel,
} from "./agencyTableMode";

import {
  AgencyKanbanViewMode,
  AgencyKanbanModePreferenceModel,
  agencyKanbanModePreferenceModel,
} from "./agencyKanbanMode";

export type MeasurementUnit = "metric" | "imperial";

export interface PreferencesModel {
  measurementUnit: {
    preference: MeasurementUnit;
    setPreference: Action<MeasurementUnitPreferenceModel, MeasurementUnit>;
  };
  agencyTableMode: {
    preference: AgencyTableViewMode;
    setPreference: Action<AgencyTableModePreferenceModel, AgencyTableViewMode>;
  };
  agencyKanbanMode: {
    preference: AgencyKanbanViewMode;
    setPreference: Action<
      AgencyKanbanModePreferenceModel,
      AgencyKanbanViewMode
    >;
  };
}

export const preferencesModel: PreferencesModel = {
  measurementUnit: unitPreferenceModel,
  agencyTableMode: agencyTableModePreferenceModel,
  agencyKanbanMode: agencyKanbanModePreferenceModel,
};
