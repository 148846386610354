import React, { useEffect } from "react";
import { range } from "lodash";
import { getDaysInMonth, lastDayOfMonth, add } from "date-fns";
import { FormikErrors } from "formik";
import { Grid } from "src/ccl/layout";
import {
  Field,
  FormikCheckbox,
  FormikInput,
  SelectReact,
} from "src/ccl/data-entry";
import { ValidationBlock } from "src/ccl/feedback";
import { JobContext, JobHelpBox } from "src/utils/job";
import { useIsMobile } from "src/hooks/useIsMobile";

type FormField =
  | "startDay"
  | "startMonth"
  | "startYear"
  | "endDay"
  | "endMonth"
  | "endYear";

const monthOptions = [
  {
    label: "January",
    value: "1",
  },
  {
    label: "February",
    value: "2",
  },
  {
    label: "March",
    value: "3",
  },
  {
    label: "April",
    value: "4",
  },
  {
    label: "May",
    value: "5",
  },
  {
    label: "June",
    value: "6",
  },
  {
    label: "July",
    value: "7",
  },
  {
    label: "August",
    value: "8",
  },
  {
    label: "September",
    value: "9",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];

export interface DatePickerValues {
  startDate?: string;
  endDate?: string;
  startDay?: number;
  startMonth?: number;
  startYear?: number;
  endDay?: number;
  endMonth?: number;
  endYear?: number;
  spansMultipleDays: boolean;
}

export interface DatePickerProps {
  values: DatePickerValues;
  errors: FormikErrors<DatePickerValues>;
  setValues: (values: DatePickerValues) => void;
  setFocusedHelpBox: (name: JobHelpBox) => void;
  context: JobContext;
  helpBox?: JobHelpBox;
}
export const DatePicker = ({
  values,
  errors,
  setValues,
  setFocusedHelpBox,
  context,
  helpBox = "DatesHelperBox",
}: DatePickerProps) => {
  const onFocus = () => setFocusedHelpBox(helpBox);
  const onBlur = () => setFocusedHelpBox(null);
  const chosenStartDate = new Date(
    values?.startYear || 0,
    (values?.startMonth || 0) - 1,
  );
  const daysInChosenMonth = getDaysInMonth(chosenStartDate);

  const dayOptions = range(1, daysInChosenMonth + 1).map((i) => ({
    label: i.toString(),
    value: i.toString(),
  }));

  const updateDate = (field: FormField, value: number) => {
    if (
      values.startDay &&
      values.startMonth &&
      values.startYear &&
      field &&
      value
    ) {
      let { startDay, startMonth, startYear, endDay, endMonth, endYear } =
        values;

      switch (field) {
        case "startDay":
          startDay = value;

          break;
        case "startMonth": {
          startMonth = value;
          const startDate = new Date(startYear, startMonth - 1);
          if (startDay > lastDayOfMonth(startDate).getDate()) {
            startDay = lastDayOfMonth(startDate).getDate();
          }
          break;
        }
        case "startYear":
          startYear = value;

          break;

        case "endDay":
          endDay = value;

          break;
        case "endMonth": {
          endMonth = value;
          const endDate = new Date(endYear as number, endMonth - 1);
          if ((endDay as number) > lastDayOfMonth(endDate).getDate()) {
            endDay = lastDayOfMonth(endDate).getDate();
          }
          break;
        }
        case "endYear":
          endYear = value;

          break;
      }

      const startDate = new Date(startYear, startMonth - 1, startDay);
      const endDate = field.toString().includes("end")
        ? new Date(endYear as number, (endMonth as number) - 1, endDay)
        : add(startDate, { days: 1 });

      setValues({
        ...values,
        endDate: endDate.toString(),
        endDay: endDate.getDate(),
        endMonth: endDate.getMonth() + 1,
        endYear: endDate.getFullYear(),
        startDay: startDay,
        startMonth: startMonth,
        startYear: startYear,
        startDate: startDate.toString(),
      });
    }
  };
  const isMobile = useIsMobile();
  const startDateError = (errors as any).startDate; // eslint-disable-line @typescript-eslint/no-explicit-any
  const endDateError = (errors as any).endDate; // eslint-disable-line @typescript-eslint/no-explicit-any
  useEffect(() => {
    if (context === JobContext.Create) {
      if (!values.spansMultipleDays) {
        setValues({
          ...values,
          endDate: values.startDate,
          endDay: values.startDay,
          endMonth: values.startMonth,
          endYear: values.startYear,
        });
      } else {
        const endDate = add(Date.parse(values.startDate as string), {
          days: 1,
        });
        setValues({
          ...values,
          endDate: endDate.toString(),
          endDay: endDate.getDate(),
          endMonth: endDate.getMonth() + 1,
          endYear: endDate.getFullYear(),
        });
      }
    }
  }, [values.spansMultipleDays]);

  if (context === JobContext.BookerEdit) {
    return (
      <>
        <Field variant="b2Bold" label="When will the job start?">
          <FormikInput
            variant="rebrand"
            name="startDate"
            type="date"
            disabled
          />
        </Field>
        <Field variant="b2Bold" label="When will the job end?">
          <FormikInput variant="rebrand" name="endDate" type="date" disabled />
        </Field>
      </>
    );
  }

  if (context === JobContext.AgentEdit) {
    return (
      <>
        <Field
          variant="b2Bold"
          label="When will the job start?"
          name="startDate"
        >
          <FormikInput variant="rebrand" name="startDate" type="date" />
        </Field>
        <Field variant="b2Bold" label="When will the job end?" name="startDate">
          <FormikInput variant="rebrand" name="endDate" type="date" />
        </Field>
      </>
    );
  }
  return (
    <>
      <Grid
        css={{
          gridColumns: 3,
          gridGap: "$3",
          alignContent: "center",
        }}
      >
        <SelectReact
          variant="rebrand"
          label={
            isMobile
              ? values.spansMultipleDays
                ? "Start date"
                : "Date"
              : values.spansMultipleDays
              ? "Start day"
              : "Day"
          }
          aria-label="Start day"
          labelCss={{ mb: "2px" }}
          boxCss={{ minWidth: "88px" }}
          dropdownCss={{ px: "$3" }}
          id="startDay"
          onFocus={onFocus}
          onBlur={onBlur}
          value={dayOptions.find(
            (o) => o.value === values.startDay?.toString(),
          )}
          options={dayOptions}
          onChange={(option) => {
            updateDate("startDay", parseInt(option?.value || "0"));
          }}
        />
        <SelectReact
          variant="rebrand"
          label={values.spansMultipleDays ? "Start month" : "Month"}
          aria-label="Start month"
          labelCss={{
            mb: "2px",
            visibility: "hidden",
            "@bp2": { visibility: "visible" },
          }}
          boxCss={{ minWidth: "100px" }}
          dropdownCss={{ px: "$3" }}
          id="startMonth"
          onFocus={onFocus}
          onBlur={onBlur}
          value={monthOptions.find(
            (o) => o.value === values.startMonth?.toString(),
          )}
          options={monthOptions}
          onChange={(option) => {
            updateDate("startMonth", parseInt(option?.value || "0"));
          }}
        />

        <Field
          variant="b2Bold"
          label={values.spansMultipleDays ? "Start year" : "Year"}
          labelCss={{ visibility: "hidden", "@bp2": { visibility: "visible" } }}
          name="startYear"
          aria-label="Start year"
        >
          {/* This fixes a weird displacement between the title of the field and the title of SelectReact*/}
          <FormikInput
            variant="rebrand"
            type="number"
            name="startYear"
            onFocus={onFocus}
            onBlur={onBlur}
            style={{ minWidth: "unset" }}
            onChange={(event) => {
              updateDate("startYear", parseInt(event?.target?.value || "0"));
            }}
          />
        </Field>
      </Grid>
      {startDateError && (
        <ValidationBlock title={startDateError} variant="error" />
      )}
      <Field
        variant="b2Bold"
        label="Multi-day job"
        name="spansMultipleDays"
        layout="inline"
        labelWeight="regular"
      >
        <FormikCheckbox
          variant="rebrand"
          css={{ width: 24, height: 24 }}
          name="spansMultipleDays"
        />
      </Field>
      {values.spansMultipleDays && (
        <>
          <Grid
            css={{
              gridGap: "$3",
              gridColumns: 3,
              alignContent: "center",
            }}
          >
            <SelectReact
              variant="rebrand"
              label={isMobile ? "End date" : "End day"}
              aria-label="End day"
              labelCss={{ mb: "$3" }}
              boxCss={{ minWidth: "88px" }}
              dropdownCss={{ px: "$3" }}
              id="endDay"
              onFocus={onFocus}
              onBlur={onBlur}
              value={dayOptions.find(
                (o) => o.value === values.endDay?.toString(),
              )}
              options={dayOptions}
              onChange={(option) => {
                updateDate("endDay", parseInt(option?.value || "0"));
              }}
            />
            <SelectReact
              variant="rebrand"
              label={values.spansMultipleDays ? "End month" : "Month"}
              aria-label="End month"
              labelCss={{
                mb: "$3",
                visibility: "hidden",
                "@bp2": { visibility: "visible" },
              }}
              boxCss={{ minWidth: "100px" }}
              dropdownCss={{ px: "$3" }}
              id="endMonth"
              onFocus={onFocus}
              onBlur={onBlur}
              value={monthOptions.find(
                (o) => o.value === values.endMonth?.toString(),
              )}
              options={monthOptions}
              onChange={(option) => {
                updateDate("endMonth", parseInt(option?.value || "0"));
              }}
            />
            <Field
              variant="b2Bold"
              label={values.spansMultipleDays ? "End year" : "Year"}
              labelCss={{
                visibility: "hidden",
                "@bp2": { visibility: "visible" },
              }}
              name="endYear"
              aria-label="End year"
            >
              {/* This fixes a weird displacement between the title of the field and the title of SelectReact*/}

              <FormikInput
                variant="rebrand"
                type="number"
                name="endYear"
                onFocus={onFocus}
                onBlur={onBlur}
                style={{ marginTop: "3px", minWidth: "unset" }}
                onChange={(event) => {
                  updateDate("endYear", parseInt(event?.target?.value || "0"));
                }}
              />
            </Field>
          </Grid>
          <ValidationBlock title={endDateError} variant="error" />
        </>
      )}
    </>
  );
};
