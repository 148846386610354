import type * as Stitches from "@stitches/react";
import React from "react";
import { Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches";

interface FieldInfoTextProps {
  text: string | React.ReactNode;
  fieldVariant?: "sectionInfo" | "fieldInfo";
  dataTestId?: string;
  css?: Stitches.CSS;
}

const StyledFieldInfoText = styled(Text, {
  variants: {
    fieldVariant: {
      sectionInfo: {
        mt: "$10",
      },
      fieldInfo: {
        mt: "$5",
      },
    },
  },
});

export const FieldInfoText = ({
  text,
  fieldVariant = "fieldInfo",
  dataTestId,
  css,
}: FieldInfoTextProps) => (
  <StyledFieldInfoText
    css={css}
    data-test-id={dataTestId}
    variant="meta"
    color="grey6"
    fieldVariant={fieldVariant}
  >
    {text}
  </StyledFieldInfoText>
);
