import React, { ReactNode } from "react";
import { Range, getTrackBackground } from "react-range";
import { styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";
import { Icon } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { Tooltip } from "src/ccl/feedback";

const Track = styled("div", {
  backgroundColor: "muted",
  height: 5,
  marginX: 3,
  marginTop: 10,
  marginBottom: 22,
});

const Thumb = styled("div", {
  fontSize: "12px",
  width: 30,
  outline: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "1ch",
});

const Circle = styled("div", {
  width: 30,
  height: 30,
  borderRadius: "50%",
  backgroundColor: "$black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

interface RangeInputProps {
  minimum: number;
  maximum: number;
  onChange: (values: [number, number]) => void;
  sliderPrefix?: React.ReactNode;
  values: [number, number];
  formatValue?: (val: number) => ReactNode;
}

export const RangeInput = ({
  minimum: defaultMinimum,
  maximum: defaultMaximum,
  onChange,
  sliderPrefix = null,
  values,
  formatValue,
}: RangeInputProps) => {
  const [minimum, maximum] = values.sort((v1, v2) => (v1 > v2 ? 1 : -1));

  return (
    <Range
      step={1}
      min={defaultMinimum}
      max={defaultMaximum}
      values={[
        minimum < defaultMinimum ? defaultMinimum : minimum,
        maximum > defaultMaximum ? defaultMaximum : maximum,
      ]}
      onChange={([min, max]) => onChange([min, max])}
      allowOverlap={false}
      renderTrack={({ props, children }) => (
        <Track
          {...props}
          style={{
            ...props.style,
            background: getTrackBackground({
              min: defaultMinimum,
              max: defaultMaximum,
              values: [minimum, maximum],
              colors: [
                tokens.colors.grey1,
                tokens.colors.black,
                tokens.colors.grey1,
              ],
            }),
          }}
        >
          {children}
        </Track>
      )}
      renderThumb={({ props, value }) => (
        <Thumb {...props}>
          <Circle>
            <Icon variant="range" size={20} color="white" />
          </Circle>

          <Box
            css={{
              backgroundColor: "$white",
              minWidth: "50px",
              textAlign: "center",
            }}
          >
            {sliderPrefix}
            {formatValue ? formatValue(value) : value}
          </Box>
        </Thumb>
      )}
    />
  );
};

interface SingleRangeInputProps {
  minimum: number;
  maximum: number;
  onChange: (value: number) => void;
  sliderPrefix?: React.ReactNode;
  value: number;
  formatValue?: (val: number) => ReactNode;
}

export const SingleRangeInput = ({
  minimum: defaultMinimum,
  maximum: defaultMaximum,
  onChange,
  value,
  formatValue,
}: SingleRangeInputProps) => {
  return (
    <Range
      step={5}
      min={defaultMinimum}
      max={defaultMaximum}
      values={[value < defaultMinimum ? defaultMinimum : value]}
      onChange={([value]) => onChange(value)}
      allowOverlap={false}
      renderMark={({ props }) => (
        <div
          data-test-id="singleRangeInputMark"
          {...props}
          style={{
            ...props.style,
            height: "4px",
            width: "2px",
            backgroundColor: tokens.colors.grey4,
            borderRadius: "10px",
          }}
        />
      )}
      renderTrack={({ props, children }) => (
        <Track
          {...props}
          style={{
            ...props.style,
            height: "2px",
            background: getTrackBackground({
              min: defaultMinimum,
              max: defaultMaximum,
              values: [value],
              colors: [
                tokens.colors.grey3,
                tokens.colors.grey3,
                tokens.colors.grey3,
              ],
            }),
          }}
        >
          {children}
        </Track>
      )}
      renderThumb={({ props, value }) => (
        <Thumb
          {...props}
          style={{
            ...props.style,
            marginTop: 0,
          }}
        >
          <Tooltip
            content={`${formatValue ? formatValue(value) : value}%`}
            active
            alignment="center"
          >
            <Circle>
              <Icon variant="range" size={20} color="white" />
            </Circle>
          </Tooltip>
        </Thumb>
      )}
    />
  );
};
