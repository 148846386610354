import { icons } from "./icons";
import { logos } from "./logos";
import { buttons } from "./buttons";

export const availableIcons = {
  ...icons,
  ...logos,
  ...buttons,
};

export type AvailableIcon = keyof typeof availableIcons;
