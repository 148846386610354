import React from "react";
import type * as Stitches from "@stitches/react";
import { Icon, Img, Modal, ModalProps, Text } from "src/ccl/document";
import tpaBloomPayment from "src/assets/agentDashboard/tpaBloomPayment.png";
import bloomLogo from "src/assets/agentDashboard/bloom.svg";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Job } from "src/graphql/types";
import { formatCurrency } from "src/utils/currencyFormatter";
import { Button } from "src/ccl/navigation";
import { TPABloomFeePercentage } from "src/utils/job";

const GridItem = ({
  children,
  css,
}: {
  children: React.ReactNode;
  css?: Stitches.CSS;
}) => (
  <Text variant={{ "@initial": "meta", "@bp2": "body" }} css={css}>
    {children}
  </Text>
);

const DetailsGrid = ({ job }: { job: Job }) => {
  const { endDate, booker, commissionForUser, currency } = job;
  const tpaBloomFee = (commissionForUser || 0) * (TPABloomFeePercentage / 100);
  return (
    <Grid
      css={{
        gridColumns: 2,
        border: "1px solid $grey2",
        p: "$7",
        rowGap: "$5",
        my: "$6",

        ":nth-child(even)": {
          rowGap: "$6",
          justifySelf: "end",
        },

        "@bp2": {
          my: "$7",
        },
      }}
    >
      <GridItem>Job Completed</GridItem>
      <GridItem>{endDate}</GridItem>
      <GridItem>Client</GridItem>
      <GridItem>{booker.name}</GridItem>
      <GridItem>Your payout</GridItem>
      <GridItem>{formatCurrency(commissionForUser || 0, currency)}</GridItem>
      <GridItem>Contact bloom fee {`(${TPABloomFeePercentage}%)`}</GridItem>
      <GridItem>{formatCurrency(tpaBloomFee, currency)}</GridItem>
      <GridItem css={{ fontWeight: "$bold" }}>You will receive</GridItem>
      <GridItem css={{ fontWeight: "$bold" }}>
        {formatCurrency((commissionForUser || 0) - tpaBloomFee, currency)}
      </GridItem>
    </Grid>
  );
};

export interface TPABloomModalProps extends ModalProps {
  job: Job;
  activateBloom: () => void;
  showConfirmationModal: boolean;
}

export const TPABloomModal = ({
  job,
  activateBloom,
  showConfirmationModal = false,
  ...modalProps
}: TPABloomModalProps) => {
  if (showConfirmationModal) {
    return (
      <Modal width="wide" {...modalProps}>
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            css={{
              background:
                "linear-gradient(rgba(230, 138, 157, 0.51),rgba(165, 95, 255, 0.89) 51.56%, rgba(230, 138, 157, 0.7) 100%)",
              borderRadius: "$round",
              px: "10px",
              pt: "9px",
              pb: "$3",
            }}
          >
            <Icon variant="check" color="white" size={20} />
          </Box>
          <Text
            variant="h4"
            css={{
              mt: "$6",
              mb: "$3",
            }}
          >
            Payment&apos;s on the way
          </Text>
          <Text
            css={{
              textAlign: "center",
            }}
          >
            The payment should be in your account in 12-24 hours.
          </Text>
        </Flex>
        <DetailsGrid job={job} />
        <Button
          variant={{ "@initial": "white", "@bp2": "secondary" }}
          onClick={() => modalProps.onClose?.()}
          css={{ width: "100%", "@bp2": { width: "initial" } }}
        >
          Close
        </Button>
      </Modal>
    );
  }

  return (
    <Modal width="wide" {...modalProps}>
      <Flex>
        <Box
          css={{
            display: "none",
            background: `url(${tpaBloomPayment}) no-repeat center`,
            backgroundSize: "contain",
            height: "auto",
            width: "257px",
            flexShrink: 0,
            mr: "$9",

            "@bp2": {
              display: "initial",
            },
          }}
        >
          <Flex
            css={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              px: "$7",
            }}
          >
            <Img src={bloomLogo} alt="Bloom" />
            <Text
              variant="meta"
              color="white"
              css={{
                mt: "$6",
              }}
            >
              No more waiting for invoices.
            </Text>
            <Text
              variant="meta"
              color="white"
              css={{
                fontWeight: "$bold",
                textAlign: "center",
              }}
            >
              Get paid instantly with Contact Bloom.
            </Text>
          </Flex>
        </Box>

        <Box
          css={{
            position: "relative",
            "@bp2": {
              py: "$16",
            },
          }}
        >
          <Text variant={{ "@initial": "h3", "@bp2": "h2" }}>
            Get paid early
          </Text>
          <Text
            css={{
              my: "$7",
              "@bp2": {
                mt: "$6",
              },
            }}
          >
            You now have the opportunity to be paid your commission for this job
            instantly, instead of waiting 30+ days.
          </Text>
          <DetailsGrid job={job} />
          <Button
            variant="primary"
            css={{
              mt: "$6",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              borderRadius: "8px",
            }}
            onClick={activateBloom}
          >
            <Icon
              variant="bloom"
              color="white"
              size={24}
              css={{
                mr: "$4",
              }}
            />
            Get paid now
          </Button>
          <Text
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              my: "$3",
            }}
          >
            or
          </Text>
          <Button
            variant="white"
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              borderRadius: "8px",
            }}
            onClick={() => {
              modalProps.onClose?.();
            }}
          >
            Get paid in 30 days
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
};
