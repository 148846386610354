import { useMutation } from "@apollo/client";
import React from "react";
import { Avatar } from "src/ccl/blocks";
import { Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { styled } from "src/ccl/stitches";
import {
  ASSIGN_JOB_MUTATION,
  REMOVE_JOB_ASSIGNEE_MUTATION,
} from "src/graphql/mutations";
import {
  MutationAssignJobArgs,
  MutationRemoveJobAssigneeArgs,
  Assignee,
} from "src/graphql/types";
import { getInitials, pluralize } from "src/utils/lang";

const AvatarItemOverlay = styled("div", {
  display: "none",
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  color: "$white",
  alignItems: "center",
  justifyContent: "center",

  variants: {
    variant: {
      currentlyAssigned: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      },
    },
  },
});

const AvatarWrapper = styled(Box, {
  borderRadius: "$round",
  position: "relative",
  overflow: "hidden",
  width: 40,
  height: 40,
});

interface JobAssigneeItemProps {
  jobId: string;
  assignee: Assignee;
  currentlyAssigned?: boolean;
}

export const JobAssigneeItem = ({
  assignee,
  jobId,
  currentlyAssigned = false,
}: JobAssigneeItemProps) => {
  const [assignJob] = useMutation<MutationAssignJobArgs>(ASSIGN_JOB_MUTATION);
  const [removeJobAssignee] = useMutation<MutationRemoveJobAssigneeArgs>(
    REMOVE_JOB_ASSIGNEE_MUTATION,
  );

  return (
    <Box
      css={{
        cursor: "pointer",
        ":hover": {
          [`${AvatarWrapper}`]: {
            border: "4px solid $grey3",
          },

          [`${AvatarItemOverlay}`]: {
            display: "flex",
          },
        },
      }}
      onClick={async () => {
        if (currentlyAssigned) {
          await removeJobAssignee({
            variables: {
              jobId: jobId,
            },
          });
        } else {
          await assignJob({
            variables: {
              jobId: jobId,
              assigneeId: assignee.id,
            },
          });
        }
      }}
    >
      <Flex css={{ alignItems: "center" }}>
        <AvatarWrapper
          css={{
            border: currentlyAssigned ? "4px solid $green" : "4px solid $white",
          }}
        >
          <AvatarItemOverlay
            title={assignee.name}
            variant={currentlyAssigned ? "currentlyAssigned" : undefined}
          >
            {currentlyAssigned && (
              <Icon variant="cross" color="white" size={14} />
            )}
          </AvatarItemOverlay>
          <Avatar
            imageUrl={assignee.featuredAsset?.mediaUrl}
            initials={getInitials(assignee.name)}
          />
        </AvatarWrapper>

        <Box css={{ pl: "$2" }}>
          <Text
            variant="meta"
            css={{
              color: currentlyAssigned ? "$green" : "$black",
              fontWeight: "$bold",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "100%",
              lineHeight: "$18",
            }}
          >
            {assignee.name}
          </Text>

          {assignee.assignedLiveJobCount !== undefined && (
            <Text variant="meta" color="grey6" css={{ lineHeight: "$18" }}>
              {pluralize(assignee.assignedLiveJobCount, "active job")}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
