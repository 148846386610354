import React from "react";
import { BaseFilter, BaseFilterProps } from "src/ccl/filtering/baseFilter";
import { Box } from "src/ccl/layout";
import { MeasurementDisplay } from "src/graphql/generated/types";
import { formatMetric } from "src/utils/measurementConversions";
import { useStoreModel } from "src/hooks";
import { RangeInput } from "src/ccl/data-entry/input/RangeInput";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";

interface RangeFilterProps extends BaseFilterProps {
  minimum: number;
  maximum: number;
  onChange: (nextMinimum: number, nextMaximum: number) => void;
  metricMeasurementDisplay?: MeasurementDisplay;
  imperialMeasurementDisplay?: MeasurementDisplay;
  sliderPrefix?: React.ReactNode;
  values: [number, number];
}

export const RangeFilter = ({
  minimum: defaultMinimum,
  maximum: defaultMaximum,
  onChange,
  metricMeasurementDisplay,
  imperialMeasurementDisplay,
  onReset,
  sliderPrefix = null,
  values,
  ...filterProps
}: RangeFilterProps) => {
  const {
    measurementUnit: { preference: unitDisplay },
  } = useStoreModel("preferences");

  const [debouncedValues, debouncedOnChange] = useDebouncedInputValue<
    [number, number]
  >(values, ([min, max]) => onChange(min, max));
  return (
    <BaseFilter {...filterProps} onReset={onReset}>
      <Box css={{ px: "$4" }}>
        <RangeInput
          minimum={defaultMinimum}
          maximum={defaultMaximum}
          values={debouncedValues}
          onChange={debouncedOnChange}
          sliderPrefix={sliderPrefix}
          formatValue={(value) =>
            formatMetric(
              value,
              unitDisplay === "imperial"
                ? imperialMeasurementDisplay
                : metricMeasurementDisplay,
            )
          }
        />
      </Box>
    </BaseFilter>
  );
};
