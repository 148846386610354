import * as Yup from "yup";
import { FormikValues, validateYupSchema, yupToFormErrors } from "formik";

export const getValidationErrors = async (
  values: FormikValues,
  schema: Yup.ObjectSchema<FormikValues>,
) => {
  let errors = {};
  try {
    await validateYupSchema(values, schema);
  } catch (err) {
    // Capture Yup validation errors but let anything else blow up
    if (err instanceof Yup.ValidationError) {
      errors = yupToFormErrors(err);
    } else {
      throw err;
    }
  }

  return errors;
};
