import React, { ReactElement } from "react";
import { CSS, VariantProps } from "@stitches/react";
import { Icon } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { styled } from "src/ccl/stitches";

export interface BreadcrumbsProps extends VariantProps<typeof Flex> {
  children: ReactElement[] | ReactElement;
  css?: CSS;
}

const StyledBreadcrumbs = styled(Flex, {
  spaceX: "$4",
  alignItems: "center",
});

export const Breadcrumbs = ({ children, ...props }: BreadcrumbsProps) => {
  const newChildren: ReactElement[] = [];
  React.Children.forEach(children, (child, index) => {
    newChildren.push(React.cloneElement(child, { key: index }));
    // @TODO - Replace right arrow with icon once icon component exists
    newChildren.push(
      <Icon
        variant="breadcrumbArrow"
        key={`${index}.0`}
        size={8}
        color="grey6"
      />,
    );
  });
  return (
    <StyledBreadcrumbs data-test-id="Breadcrumbs" {...props}>
      {newChildren}
    </StyledBreadcrumbs>
  );
};
