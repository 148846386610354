import { gql } from "@apollo/client";

export const CATEGORISE_PORTFOLIO_IMAGES_MUTATION = gql`
  mutation CategorisePortfolioImages(
    $portfolioImageIds: [ID!]!
    $portfolioImageCategoryIds: [ID!]!
  ) {
    categorisePortfolioImages(
      portfolioImageIds: $portfolioImageIds
      portfolioImageCategoryIds: $portfolioImageCategoryIds
    ) {
      success
    }
  }
`;
