import React from "react";
import { Icon } from "src/ccl/document";

export const DropdownIndicator = () => (
  <Icon
    variant="chevronDown"
    size={14}
    color="grey6"
    css={{ px: "$4", cursor: "pointer", width: "auto" }}
  />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ClearIndicator = (props: any) => {
  const {
    children = (
      <Icon
        variant="cross"
        size={14}
        color="black"
        css={{ cursor: "pointer", width: "auto" }}
      />
    ),
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      {children}
    </div>
  );
};
