import { State } from "easy-peasy";
import { CurrentUserModel } from "src/models/currentUser";

export type UserKindContext =
  | "signedOut"
  | "client"
  | "agent"
  | "talent"
  | "TPA";

export const useUserKindContext = (
  currentUser: State<CurrentUserModel>,
): UserKindContext => {
  if (currentUser.isBooker) {
    return "client";
  }

  if (currentUser.isAgent && !currentUser.isFirstPartyAgent) {
    return "TPA";
  }

  if (currentUser.isAgent) {
    return "agent";
  }

  if (currentUser.isTalent) {
    return "talent";
  }

  return "signedOut";
};
