// GRAPHQL_TYPE_MIGRATION

import { AgencyJobState, JobState, JobTalentState } from "./types";
import { tokens } from "src/ccl/stitches/theme";

export const JobStateBackground: Record<JobState, keyof typeof tokens.colors> =
  {
    [JobState.PendingApproval]: "lightOrange",
    [JobState.Approved]: "lightPurple",
    [JobState.AwaitingPayment]: "turquoise",
    [JobState.Finalized]: "lightGreen",
    [JobState.Completed]: "grey1",
    [JobState.Expired]: "white",
    [JobState.Cancelled]: "cancelledLight",
    [JobState.Rejected]: "rejectedLight",
  };

export const JobStateColors: Record<JobState, keyof typeof tokens.colors> = {
  [JobState.PendingApproval]: "orange",
  [JobState.Approved]: "purple",
  [JobState.AwaitingPayment]: "turquoiseDark",
  [JobState.Finalized]: "green",
  [JobState.Completed]: "black",
  [JobState.Expired]: "grey6",
  [JobState.Cancelled]: "peach",
  [JobState.Rejected]: "red",
};

export const JobTalentStateColors: Record<
  JobTalentState,
  keyof typeof tokens.colors
> = {
  [JobTalentState.Accepted]: "green",
  [JobTalentState.AwaitingConfirmation]: "green",
  [JobTalentState.Rejected]: "red",
  [JobTalentState.Pending]: "grey6",
  [JobTalentState.RejectedByBooker]: "red",
  [JobTalentState.Confirmed]: "green",
  [JobTalentState.Paid]: "green",
  [JobTalentState.Disputed]: "red",
  [JobTalentState.PendingAgencyApproval]: "black",
};

type JobCardThemeName =
  | "purple"
  | "orange"
  | "black"
  | "peach"
  | "green"
  | "grey"
  | "red"
  | "turquoiseDark";
type JobCardTheme = [string, string, string];

const jobCardThemes: Record<JobCardThemeName, JobCardTheme> = {
  purple: ["$purple", "#FBE4FF", "#BC00DA"],
  orange: ["$orange", "#FEE8D9", "#BB4E00"],
  black: ["$black", "#e5e5e5", "$black"],
  peach: ["$peach", "#FCE8EB", "#D22A49"],
  green: ["$green", "#DCF6E2", "#03811F"],
  grey: ["$grey2", "$grey2", "$black"],
  red: ["$red", "#F8DEDC", "#D32014"],
  turquoiseDark: ["$turquoiseDark", "$turquoisePale", "$turquoiseDark"],
};

export const BookerJobStateTheme: Record<JobState, JobCardTheme> = {
  [JobState.PendingApproval]: jobCardThemes.orange,
  [JobState.Approved]: jobCardThemes.purple,
  [JobState.AwaitingPayment]: jobCardThemes.turquoiseDark,
  [JobState.Finalized]: jobCardThemes.green,
  [JobState.Completed]: jobCardThemes.grey,
  [JobState.Expired]: jobCardThemes.black,
  [JobState.Cancelled]: jobCardThemes.peach,
  [JobState.Rejected]: jobCardThemes.red,
};

export const AgentJobStateTheme: Record<
  AgencyJobState,
  [string, string, string]
> = {
  [AgencyJobState.PendingTalentResponse]: jobCardThemes.purple,
  [AgencyJobState.PendingBookerResponse]: jobCardThemes.orange,
  [AgencyJobState.RejectedByTalent]: jobCardThemes.black,
  [AgencyJobState.RejectedByBooker]: jobCardThemes.peach,
  [AgencyJobState.Confirmed]: jobCardThemes.green,
  [AgencyJobState.Completed]: jobCardThemes.grey,
  [AgencyJobState.Paid]: jobCardThemes.grey,
  [AgencyJobState.Cancelled]: jobCardThemes.red,
  [AgencyJobState.Expired]: jobCardThemes.grey,
};
