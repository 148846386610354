import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Job,
  JobTalent,
  MutationRemoveTalentArgs,
  TalentProfile,
} from "src/graphql/types";
import { Text, ConfirmModal } from "src/ccl/document";
import { REMOVE_JOB_TALENT_MUTATION } from "src/graphql/mutations/booker";
import { verticalMap } from "src/utils/user";

export interface RemoveJobTalentModalProps {
  job: Job;
  jobTalent?: JobTalent;
  onCancel: () => void;
  onRemove: () => void;
}

export const RemoveJobTalentModal = ({
  job,
  jobTalent,
  onRemove,
  onCancel,
}: RemoveJobTalentModalProps) => {
  const history = useHistory();
  const [removeJobTalent, { loading: removeJobTalentLoading }] =
    useMutation<MutationRemoveTalentArgs>(REMOVE_JOB_TALENT_MUTATION);

  const profile = jobTalent?.talent.profile as TalentProfile;
  const vertical = profile?.vertical;
  const prettyVertical = jobTalent ? verticalMap[vertical] : "";

  const jobSlug = job.slug;
  const onlyOneCreativeRemains = job?.talent?.length === 1;

  return (
    <ConfirmModal
      title={
        onlyOneCreativeRemains
          ? `Can't remove creative`
          : `Remove ${prettyVertical}`
      }
      isOpen={!!jobTalent}
      confirmButtonTitle={onlyOneCreativeRemains ? "Edit shortlist" : "Remove"}
      confirmButtonLoadingTitle="Removing..."
      cancelButtonTitle="Close"
      onCancel={onCancel}
      onConfirm={
        onlyOneCreativeRemains
          ? () => {
              history.push(
                `/dashboard/client/jobs/${jobSlug}/talent?v=${vertical.toLowerCase()}`,
              );
            }
          : async () => {
              await removeJobTalent({
                variables: { jobId: job.id, jobTalentId: jobTalent?.id },
              });
              onRemove();
            }
      }
      isDisabled={removeJobTalentLoading}
    >
      <Text>
        {onlyOneCreativeRemains
          ? `You need at least one creative on a job. Edit the shortlist if you’re
        looking to find different creatives, or cancel the job if it’s no longer
        going ahead`
          : `Are you sure you want to remove this ${prettyVertical} from the job?
        You’ll have to shortlist them again if you change your mind.`}
      </Text>
    </ConfirmModal>
  );
};
