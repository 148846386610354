import { gql } from "@apollo/client";

export const BOOKER_BOOKMARKED_TALENT = gql`
  query BookerBookmarkedTalent {
    me {
      id
      profile {
        ... on BookerProfile {
          savedTalent(last: 1) {
            totalCount
            edges {
              node {
                id
                talent {
                  featuredAsset {
                    mediaUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
