import { gql } from "@apollo/client";

export const SETTINGS_PAYMENTS_QUERY = gql`
  query SettingsPaymentsQuery {
    me {
      payments {
        id
        createdAt
        amount
        currency
        state
        remittanceLetterUrl
        sentAt

        lineItems {
          amount
          kind
        }

        jobTalent {
          job {
            name
            brand
            startDate
            endDate
          }
        }
      }
    }
  }
`;
