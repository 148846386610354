import mixpanel from "mixpanel-browser";
import { mixpanelApiKey, environmentName, debugAnalytics } from "src/config";
import { User } from "src/graphql/types";

const enableMixpanel =
  environmentName === "production" || environmentName === "staging";

if (enableMixpanel) {
  mixpanel.init(mixpanelApiKey);
}

const log = (message: string) => {
  if (debugAnalytics) {
    // eslint-disable-next-line no-console
    console.log(`[Mixpanel event]: ${message}`);
  }
};

const actions = {
  identify: (user: User) => {
    if (enableMixpanel) {
      mixpanel.identify(user.id);
      mixpanel.register({
        email: user.email,
        name: user.name,
      });
    } else {
      // eslint-disable-next-line no-console
      log(`identify user: ${user.id}`);
    }
  },
  aliasUser: (userId: string) => {
    if (enableMixpanel) {
      mixpanel.alias(userId);
    } else {
      // eslint-disable-next-line no-console
      log(`Alias user: ${userId}`);
    }
  },
  trackEvent: (event: string, properties?: unknown) => {
    if (enableMixpanel) {
      mixpanel.track(event, properties as Record<string, unknown>);
    } else {
      // eslint-disable-next-line no-console
      log(`${event} with properties ${JSON.stringify(properties)}`);
    }
  },
  resetUser: () => {
    if (enableMixpanel) {
      mixpanel.reset();
    } else {
      // eslint-disable-next-line no-console
      log(`reset user`);
    }
  },
};

export const Mixpanel = actions;
