import { flatten, uniq } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TabbedMenu, TabbedMenuItem, TabGroup } from "src/ccl/blocks";
import { PortfolioImage } from "src/ccl/document";
import { PortfolioImageModal } from "src/ccl/document/modal/PortfolioImageModal";
import { Box } from "src/ccl/layout";
import {
  PortfolioImage as PortfolioImageType,
  TalentProfile,
  User,
} from "src/graphql/types";
export const PortfolioColumnCount = 3;

const buildColumns = (items: PortfolioImageType[]) => {
  const columns = new Array(PortfolioColumnCount)
    .fill([])
    .map(() => new Array(0));

  items.forEach((item, i) => {
    columns[i % PortfolioColumnCount].push(item);
  });

  return columns;
};

export const PortfolioImageGallery = ({
  talent,
  onItemClick,
}: {
  talent: User;
  onItemClick?: (portfolioImage: PortfolioImageType) => void;
}) => {
  const params = new URLSearchParams(window?.location.search);
  const selectedCategory = params.get("t");
  const history = useHistory();

  const [selectedPortfolioImage, setSelectedPortfolioImage] =
    useState<PortfolioImageType | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  if (!selectedCategory) {
    params.append("t", "All");
    history.replace(`?${params.toString()}`);
  }

  const buildTags = (profile: TalentProfile) => {
    const categories = profile.portfolioImageCategories.map(({ name }) => name);
    return ["All", ...uniq(categories).sort()];
  };

  const getTabbedMenuUrl = (tag: string) => {
    if (params.get("profile")) {
      const url = location.search.split("?");
      const filterParams = url[1]
        .split("&")
        .filter((a: string) => !a.includes("t="));
      const link = "?" + filterParams.join("&") + `&t=${tag}`;
      return link;
    } else {
      return `?t=${tag}`;
    }
  };

  const profile = talent.profile as TalentProfile;
  const categories = profile.portfolioImageCategories;
  const portfolioImages = profile.portfolioImages || [];
  const tags = buildTags(profile);

  const visibleItems =
    selectedCategory === "All"
      ? portfolioImages
      : categories.find(({ name }) => name === selectedCategory)
          ?.portfolioImages || [];

  const columns = flatten(buildColumns(visibleItems));

  return (
    <Box
      data-test-id="PortfolioImageGallery"
      css={{ height: "100%", width: "100%" }}
    >
      <TabbedMenu
        css={{ backgroundColor: "transparent", px: 0, width: "100%" }}
      >
        <TabGroup
          css={{
            maxWidth: "100%",
            flexWrap: "wrap",
            paddingX: 0,
          }}
        >
          {tags.map((tag, index) => {
            return (
              <TabbedMenuItem
                isLegacy={false}
                key={`portfolio-tab-${index}`}
                variant="tab"
                text={tag}
                url={getTabbedMenuUrl(tag)}
                match={new RegExp(`.+?=${tag.split(" ").join(" ")}`)}
              />
            );
          })}
        </TabGroup>
      </TabbedMenu>
      <Box
        css={{
          columnCount: "1",
          columnGap: "1em",
          margin: "1.5em",
          padding: "0",
          fontSize: ".85em",
          "@bp1": {
            columnCount: "2",
          },
        }}
      >
        {columns.map((portfolioImage) => (
          <Box
            key={`portfolio-item-${portfolioImage.id}`}
            css={{ cursor: "pointer" }}
            onClick={() => {
              if (onItemClick) {
                onItemClick(portfolioImage);
              } else {
                setSelectedPortfolioImage(portfolioImage);
                setModalOpen(true);
              }
            }}
          >
            <PortfolioImage
              asset={portfolioImage}
              alt={`An image of ${talent.name}`}
            />
          </Box>
        ))}
      </Box>

      {selectedPortfolioImage !== null && (
        <PortfolioImageModal
          talent={talent}
          portfolioImage={selectedPortfolioImage}
          isOpen={modalOpen}
          onClose={toggleModal}
        />
      )}
    </Box>
  );
};
