import { isEqual } from "lodash";
import { QueryVerticalTalentSearchArgs } from "src/graphql/types";

export const determineNumberOfActiveFilters = (
  searchParams: QueryVerticalTalentSearchArgs,
  baseSearchParams: QueryVerticalTalentSearchArgs,
) => {
  // The fields parameter already exists in the base params, we need to use this
  // set the default active filters length, else it provides a minus number
  let numberOfActiveFilters = searchParams.fields
    ? searchParams.fields.length
    : 0;

  const seenRangeKeys: string[] = [];
  //Here we check the search params against the base params and match up any
  //keys that exist and update the total of the active filters based on this.
  Object.keys(searchParams).forEach((key) => {
    // We have to make sure any range filters are only counted once
    // either both min and max are active, or one of min or max are active
    if (key.startsWith("min") || key.startsWith("max")) {
      if (seenRangeKeys.includes(key.slice(3))) {
        return;
      }
      seenRangeKeys.push(key.slice(3));
    }
    if (
      // we ignore the fields and seed here as we've already counted the fields
      // and the seed randomly generates on every change to make the creatives
      // at random, this messes with the total of active filters if included.
      key !== "fields" &&
      key !== "seed" &&
      !isEqual(
        baseSearchParams[key as keyof QueryVerticalTalentSearchArgs],
        searchParams[key as keyof QueryVerticalTalentSearchArgs],
      ) &&
      searchParams[key as keyof QueryVerticalTalentSearchArgs] !== undefined
    ) {
      ++numberOfActiveFilters;
    }
  });

  // This returns the number of the active filters for us to display to the user
  return numberOfActiveFilters;
};
