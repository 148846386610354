import React from "react";
import { Filter } from "src/graphql/types";
import { TextFilter } from "src/ccl/filtering";
import { uppercaseFirstLetter } from "src/utils/lang";
import { useURLStringSearchParam } from "src/hooks";

export const UrlStateTextFilter = ({
  filter,
  expanded,
}: {
  filter: Filter;
  expanded: boolean;
}) => {
  const [value, setValue] = useURLStringSearchParam(filter.name);

  return (
    <TextFilter
      value={value || ""}
      name={filter.name}
      label={uppercaseFirstLetter(filter.name)}
      expanded={expanded || value !== ""}
      onChange={setValue}
      onReset={() => {
        setValue("");
      }}
      filterCss={{
        px: 0,
        pb: 0,
      }}
      labelCss={{
        px: 0,
      }}
      helperText={filter.helperText}
    />
  );
};
