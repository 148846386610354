import { gql } from "@apollo/client";

export const RESET_CATEGORY_MUTATION = gql`
  mutation ResetAgencyTalentCategory($talentId: ID!) {
    resetGroupToDefault(talentId: $talentId) {
      id
      groups {
        id
        name
        manuallySet
      }
    }
  }
`;
