import React from "react";
import { TableHeader, TableHeaderItem } from "src/ccl/document/table";
import { Text } from "src/ccl/document";
import { SortOrderInput, SortDirection } from "src/graphql/types";
import {
  currentFieldName,
  currentDirectionFromSortOrder,
} from "src/utils/sorting";

const TableHeaderItemLabel = ({ text }: { text: string }) => (
  <Text variant="mini" color="grey6">
    {text}
  </Text>
);

interface BookerTableHeaderProps {
  sortOrder: SortOrderInput;
  setSortOrder: (column: string, sortDirection: SortDirection) => void;
}

export const BookerTableHeader = ({
  sortOrder,
  setSortOrder,
}: BookerTableHeaderProps) => (
  <TableHeader css={{ "@md": { display: "flex" } }}>
    <TableHeaderItem
      widthPercentage={40}
      fieldName="name"
      active={currentFieldName("name", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder("name", sortOrder)}
      onSortChange={setSortOrder}
    >
      <TableHeaderItemLabel text="clients" />
    </TableHeaderItem>
    <TableHeaderItem
      widthPercentage={20}
      fieldName="kind"
      active={currentFieldName("kind", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder("kind", sortOrder)}
      onSortChange={setSortOrder}
    >
      <TableHeaderItemLabel text="Type" />
    </TableHeaderItem>
    <TableHeaderItem
      widthPercentage={20}
      fieldName="jobCount"
      active={currentFieldName("jobCount", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder(
        "jobCount",
        sortOrder,
      )}
      onSortChange={setSortOrder}
    >
      <TableHeaderItemLabel text="Jobs posted" />
    </TableHeaderItem>
    <TableHeaderItem
      widthPercentage={20}
      fieldName="createdAt"
      active={currentFieldName("createdAt", sortOrder)}
      currentSortDirection={currentDirectionFromSortOrder(
        "createdAt",
        sortOrder,
      )}
      onSortChange={setSortOrder}
    >
      <TableHeaderItemLabel text="Join date" />
    </TableHeaderItem>
  </TableHeader>
);
