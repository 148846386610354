import React from "react";
import { BaseFilter, BaseFilterProps } from "src/ccl/filtering/baseFilter";
import { Checkbox, Input } from "src/ccl/data-entry";
import { MultiSelectFilterOption, OptionWrapper } from "src/ccl/filtering";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Flex, Grid } from "src/ccl/layout";
import { styled } from "src/ccl";
import { TalentSearchLocationContext } from "src/graphql/types";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";

export const OptionIcon = styled(Flex, {
  width: "18px",
  height: "18px",
  flexShrink: 0,
  boxSizing: "content-box",
  justifyContent: "center",
  alignItems: "center",
  border: "2px solid $black",
  "input:checked ~ &": {
    backgroundColor: "$black",
  },
});

interface LocationFilterProps extends BaseFilterProps {
  location: string;
  locationContext?: TalentSearchLocationContext;
  onLocationChange: (value: string) => void;
  onContextChange: (context: TalentSearchLocationContext) => void;
  options: MultiSelectFilterOption[];
}

const primaryOnly = [TalentSearchLocationContext.PrimaryOnly];
const additionalOnly = [TalentSearchLocationContext.AdditionalOnly];
const primaryAndAdditional = [
  TalentSearchLocationContext.PrimaryOnly,
  TalentSearchLocationContext.AdditionalOnly,
];

export const LocationFilter = ({
  location,
  locationContext,
  options,
  onLocationChange,
  onContextChange,
  onReset,
  ...filterProps
}: LocationFilterProps) => {
  const [locationValue, setLocationValue] = useDebouncedInputValue(
    location,
    onLocationChange,
  );

  const selectedOptions =
    locationContext === TalentSearchLocationContext.PrimaryAndAdditional
      ? primaryAndAdditional
      : locationContext === TalentSearchLocationContext.PrimaryOnly
      ? primaryOnly
      : additionalOnly;

  const handleCheck = (option: string, checked: boolean) => {
    if (!checked) {
      if (selectedOptions === primaryAndAdditional) {
        if (option === TalentSearchLocationContext.PrimaryOnly) {
          onContextChange(TalentSearchLocationContext.AdditionalOnly);
        } else {
          onContextChange(TalentSearchLocationContext.PrimaryOnly);
        }
      }
    } else {
      if (
        selectedOptions === primaryOnly &&
        option === TalentSearchLocationContext.AdditionalOnly
      ) {
        onContextChange(TalentSearchLocationContext.PrimaryAndAdditional);
      } else if (
        selectedOptions === additionalOnly &&
        option === TalentSearchLocationContext.PrimaryOnly
      ) {
        onContextChange(TalentSearchLocationContext.PrimaryAndAdditional);
      }
    }
  };

  return (
    <BaseFilter
      {...filterProps}
      expanded={filterProps.expanded || locationValue?.length > 0}
      onReset={onReset}
    >
      <Input
        name={filterProps.name}
        data-test-id="FTGTextFilter-Location-Input"
        type="text"
        value={locationValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setLocationValue(event.target.value);
        }}
      />
      <Grid css={{ mt: "$3", gridColumns: 2 }}>
        {options.map((option) => (
          <OptionWrapper key={option.value}>
            <Checkbox
              css={{
                position: "absolute",
                top: 0,
                bottom: 0,
                opacity: 0,
                cursor: "pointer",
              }}
              value={option.value}
              checked={
                !!selectedOptions?.includes(
                  option.value as TalentSearchLocationContext,
                )
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const checked = event.target.checked;
                handleCheck(value, checked);
              }}
            />
            <OptionIcon
              css={{
                backgroundColor: "$white",
              }}
            >
              <Icon
                variant={(option.icon as AvailableIcon) || "check"}
                size={14}
                color={
                  selectedOptions?.includes(
                    option.value as TalentSearchLocationContext,
                  )
                    ? "white"
                    : "grey3"
                }
              />
            </OptionIcon>

            <Text
              variant="meta"
              css={{
                ml: "$2",
              }}
            >
              {option.description || option.label}
            </Text>
          </OptionWrapper>
        ))}
      </Grid>
    </BaseFilter>
  );
};
