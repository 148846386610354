import { useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { isNewDashboardVar } from "./../graphql/cache";
import { useFeatures } from "./store";

type useNewDashboardReturnType = {
  isNewDashboard: boolean;
  setToNewDashboard: (value: boolean, url: string) => void;
};

export const useNewDashboard = (): useNewDashboardReturnType => {
  const history = useHistory();
  const { featureEnabled } = useFeatures();

  const { data: { isNewDashboard = false } = {} } = useQuery(gql`
    query NewDashboard {
      isNewDashboard @client
    }
  `);

  const setToNewDashboard = (value: boolean, url: string) => {
    if (value) {
      localStorage.setItem("isNewDashboard", "true");

      if (url === "/dashboard/client") {
        history.push("/dashboard/client/jobs");
      }
    } else {
      localStorage.removeItem("isNewDashboard");
    }
    isNewDashboardVar(value);
  };

  return {
    isNewDashboard: isNewDashboard ?? featureEnabled("unified_dashboards"),
    setToNewDashboard,
  };
};
