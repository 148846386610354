import { gql } from "@apollo/client";
import {
  JOB_DETAILS_FRAGMENT,
  JOB_DETAILS_SPLIT_ADDRESS_FRAGMENT,
  JOB_VERTICAL_DETAILS_FRAGMENT,
} from "src/graphql/fragments";

export const AGENT_DASHBOARD_EDIT_JOB_QUERY = gql`
  query AgentDashboardEditJob($jobId: ID!) {
    job(id: $jobId) {
      state
      selfTapeRequired
      cateringProvided
      ...JobDetails
      ...JobDetailsSplitAddress
      ...JobVerticalDetails
      invoiceBillingName
      invoiceBillingAddress
      excludeVat
      spansMultipleDays
      talent {
        id
        talent {
          name
          id
          featuredAsset {
            mediaUrl
          }
          groups {
            id
            manuallySet
            name
          }
          averageResponseTimeMinutes
          bookmarked
          country
          slug
          profile {
            ... on TalentProfile {
              vertical
              primaryLocation {
                name
              }
            }
          }
        }
      }
    }
  }
  ${JOB_DETAILS_FRAGMENT}
  ${JOB_DETAILS_SPLIT_ADDRESS_FRAGMENT}
  ${JOB_VERTICAL_DETAILS_FRAGMENT}
`;
