import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Job,
  JobTalent,
  MutationAwaitJobPaymentArgs,
  Mutation,
} from "src/graphql/types";
import { Checkbox } from "src/ccl/data-entry";
import { Text, Icon } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button, Link } from "src/ccl/navigation";
import { AWAIT_JOB_PAYMENT_MUTATION } from "src/graphql/mutations";

interface TermsAndConditionsProps {
  job: Job;
  onCancel: () => void;
  onBack: () => void;
  selectedJobTalent: JobTalent[];
  refetchJob: () => void;
}

export const TermsAndConditions = ({
  job,
  onCancel,
  onBack,
  selectedJobTalent,
  refetchJob,
}: TermsAndConditionsProps) => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [awaitJobPayment, { loading: awaitJobPaymentLoading }] = useMutation<
    Mutation,
    MutationAwaitJobPaymentArgs
  >(AWAIT_JOB_PAYMENT_MUTATION, {
    variables: {
      jobId: job.id,
      talentIdsToConfirm: selectedJobTalent.map((jt) => jt.id),
    },
  });

  const valid = selectedJobTalent.length > 0;

  const handleConfirm = async () => {
    await awaitJobPayment();
    refetchJob();
  };

  const buttonDisabled = !valid || awaitJobPaymentLoading || !termsAccepted;

  return (
    <Flex css={{ flexDirection: "column" }} data-test-id="TermsAndConditions">
      <Text variant="h2" css={{ pb: "$7" }}>
        One last thing
      </Text>
      <Text css={{ pb: "$8" }}>
        In order to submit the job, you must accept our terms and conditions.
        These include:
      </Text>
      <Text css={{ pb: "$7", pl: "$6", maxWidth: "652px" }}>
        <ul style={{ listStyle: "disc" }}>
          <li>
            <Text>
              You cannot take this booking or any future bookings off the
              Contact platform
            </Text>
          </li>
          <li>
            <Text>
              You agree to pay upfront, or pay within 30 days of invoicing
            </Text>
          </li>
          <li>
            <Text>
              Any reasonable adjustments or requirements, including those
              required due to religion, disability, or dietary requirements
            </Text>
          </li>
        </ul>
      </Text>
      <Flex css={{ gap: "$4", alignItems: "center", mb: "$12" }}>
        <Checkbox
          css={{
            height: "18px",
            width: "18px",
            backgroundColor: "$grey1",
            flexShrink: 0,
          }}
          id="acceptTerms"
          onChange={(e) => setTermsAccepted(e.target.checked)}
        />
        <label htmlFor="acceptTerms">
          <Text weight="bold" css={{ cursor: "pointer" }}>
            I accept Contact&apos;s{" "}
            <Link to="/terms" target="_blank">
              terms and conditions
            </Link>{" "}
            and agree to comply with the above
          </Text>
        </label>
      </Flex>
      <Flex css={{ justifyContent: "space-between", justifySelf: "flex-end" }}>
        <Button variant="secondary" onClick={onBack} type="reset">
          Back
        </Button>

        <Button
          data-test-id="ConfirmJobButton"
          variant="primary"
          onClick={async () => {
            await handleConfirm();
            onCancel();
          }}
          disabled={buttonDisabled}
          css={{
            height: "48px",
            "@bp1": {
              minWidth: "170px",
            },
          }}
        >
          {awaitJobPaymentLoading ? (
            "Confirming..."
          ) : (
            <Flex
              css={{
                justifyContent: "center",
                alignItems: "center",
                gap: "$3",
              }}
            >
              <Icon
                variant="check"
                size={16}
                color="white"
                css={{ flexShrink: 0 }}
              />
              <Text as="span" color="white" variant="buttonText">
                Confirm Job
              </Text>
            </Flex>
          )}
        </Button>
      </Flex>
    </Flex>
  );
};
