import React, { PropsWithChildren, useState } from "react";
import type * as Stitches from "@stitches/react";
import { useHistory } from "react-router-dom";
import { mergeCss, styled } from "src/ccl/stitches";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { Panel, Text } from "src/ccl/document";
import {
  ActionItem,
  GoBack,
  ResetModal,
  StepSummary,
} from "src/components/formLayout";

interface FormLayoutHeaderProps {
  onClick?: () => void;
  ctaText?: string;
  goBackTo?: string | (() => void | Promise<void>);
  resetUrl?: string;
  resetCopy?: string;
  onReset?: () => void;
  onBack?: () => void;
  title: string;
  disabled?: boolean;
  steps?: string[];
  currentStepIndex?: number;
  buttonType?: "button" | "submit";
  buttonVariant?: "primary" | "default";
  variant?: "caj" | "shortlist";
  helpComponent?: React.ReactNode;
  headerCss?: Stitches.CSS;
  stepIndexCss?: Stitches.CSS;
  titleVariant?: string;
  wrapperComponent?: React.FC<PropsWithChildren>;
  infoBox?: string;
}

const Wrapper = styled(Box, {
  mb: "$5",
  margin: "0 auto",

  variants: {
    variant: {
      caj: {
        px: "$8",
        "@bp4": {
          px: "$0",
        },
      },
      shortlist: {
        px: "$8",
        "@bp4": {
          px: "$10",
        },
      },
      shortlistProfile: {
        backgroundColor: "$grey1",
      },
    },
  },
});

export const FormLayoutHeader = ({
  ctaText,
  goBackTo,
  resetUrl,
  resetCopy,
  onReset,
  onBack,
  title,
  steps,
  currentStepIndex,
  disabled = false,
  onClick = () => {},
  buttonType = "submit",
  buttonVariant = "primary",
  variant,
  helpComponent = null,
  headerCss = {},
  stepIndexCss = {},
  titleVariant = "h1",
  wrapperComponent,
  infoBox,
}: FormLayoutHeaderProps) => {
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const history = useHistory();

  const Wrap = wrapperComponent || Wrapper;

  return (
    <Wrap variant={variant} data-test-id="FormLayoutHeader">
      {resetUrl && resetCopy && (
        <ResetModal
          isOpen={showResetModal}
          copy={resetCopy}
          onCancel={() => setShowResetModal(!setShowResetModal)}
          onConfirm={() => {
            onReset && onReset();
            history.push(resetUrl);
          }}
        />
      )}
      <Flex
        css={mergeCss(
          { justifyContent: "space-between", alignItems: "center", mb: "$10" },
          headerCss,
        )}
      >
        <Text variant={titleVariant}>{title}</Text>
        <Flex>
          {infoBox && (
            <Panel
              css={{ mr: "$4", alignItems: "end" }}
              iconSize={16}
              icon="information"
            >
              <Text variant="meta">{infoBox}</Text>
            </Panel>
          )}
          {ctaText && (
            <Button
              type={buttonType}
              variant={buttonVariant}
              disabled={disabled}
              onClick={onClick}
              data-test-id="FormHeaderCTA"
            >
              {ctaText}
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex css={mergeCss({ justifyContent: "space-between" }, stepIndexCss)}>
        <Flex css={{ mr: "$3", alignItems: "start", "@bp3": { mr: "$8" } }}>
          {currentStepIndex === 0 && (
            <>
              {goBackTo && (
                <GoBack goBackTo={goBackTo}>
                  <ActionItem icon="chevronLeft" text="Back" onClick={onBack} />
                </GoBack>
              )}

              {helpComponent}
            </>
          )}
          {currentStepIndex !== 0 && goBackTo && (
            <Flex>
              <GoBack goBackTo={goBackTo}>
                <ActionItem icon="chevronLeft" text="Back" onClick={onBack} />
              </GoBack>
              {resetUrl && resetCopy && (
                <ActionItem
                  icon="reset"
                  text="Reset"
                  onClick={() => setShowResetModal(!showResetModal)}
                />
              )}
              {helpComponent}
            </Flex>
          )}
        </Flex>
        {steps && currentStepIndex !== undefined && (
          <Box css={{ flex: 1 }}>
            <StepSummary steps={steps} currentStep={currentStepIndex} />
          </Box>
        )}
      </Flex>
    </Wrap>
  );
};
