import { DropdownPill } from "src/ccl/blocks";
import { FilterOption } from "src/ccl/filtering";
import { FilterOption as GroupFilterOption } from "src/graphql/types";

interface GenderFilterProps {
  variant?: "dropdown" | "pill";
  group?: string;
  setGroup?: React.Dispatch<React.SetStateAction<string>>;
  groups: GroupFilterOption[];
  selectedFilter?: string | null;
  setSelectedFilter?: (filter: string | null) => void;
}

export const GroupFilter = ({
  group,
  setGroup,
  groups,
  selectedFilter,
  setSelectedFilter,
}: GenderFilterProps) => {
  return (
    <DropdownPill
      active={group !== "all"}
      text="Group"
      icon="filter"
      withHoverEffect
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
    >
      {groups.map((g, i) => (
        <FilterOption
          key={i}
          variant="dropdown"
          label={g.label}
          checked={group === g.value}
          onClick={() => {
            setGroup && setGroup(g.value);
          }}
        />
      ))}
    </DropdownPill>
  );
};
