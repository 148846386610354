import React from "react";
import { Modal, Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button, ExternalLinkButton } from "src/ccl/navigation";
import { useFeatures } from "src/hooks";

const readMoreUrl =
  "https://support.contact.xyz/hc/en-gb/articles/13911957760657--Paying-for-your-job";

export const BloomFeaureModal = ({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { featureEnabled } = useFeatures();
  return (
    <Modal
      width="normal"
      onClose={onClose}
      isOpen={isOpen}
      showCloseButton={true}
      modalStyles={{ transition: "0.3s ease-in" }}
      overlayCss={{ transition: "0.3s ease-in" }}
    >
      <Flex
        css={{
          flexDirection: "column",
          alignItems: "center",
          gap: "$7",
          mb: "$7",
        }}
      >
        <Icon
          variant="moneyBudgetBag"
          size={30}
          css={{
            "@bp2": {
              display: "none",
            },
          }}
        />
        <Icon
          variant="moneyBudgetBag"
          size={60}
          css={{
            display: "none",
            "@bp2": {
              display: "initial",
            },
          }}
        />
        <Text
          variant={{ "@initial": "h3", "@bp2": "h2" }}
          css={{
            textAlign: "center",
          }}
        >
          Flexible, automated payments are here
        </Text>
        <Text
          css={{
            textAlign: "center",
          }}
        >
          We&apos;re excited to announce{" "}
          <strong>{featureEnabled("trade_account") ? "four" : "three"}</strong>{" "}
          new payment options. Now, you&apos;ll be able to pay for your job by{" "}
          <strong>card</strong>, <strong>bank transfer</strong>, and{" "}
          <strong>invoice</strong>.{" "}
          {featureEnabled("trade_account") && (
            <>
              Plus, the introduction of <strong>Buy Now Pay Later</strong>,
              which lets you open a trade account and pay back within 30, 60, or
              90 day terms.
            </>
          )}
        </Text>
      </Flex>
      <Flex
        css={{
          flexDirection: "column-reverse",
          gap: "$4",
          "@bp2": {
            justifyContent: "space-between",
            flexDirection: "row",
          },
        }}
      >
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <ExternalLinkButton
          variant="primary"
          to={readMoreUrl}
          onClick={() => {
            onClose();
          }}
          css={{
            textAlign: "center",
          }}
        >
          Read More
        </ExternalLinkButton>
      </Flex>
    </Modal>
  );
};
