import React from "react";
import { Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";

export const ApiError = () => (
  <Box>
    <Text variant="h4">Oops, something went wrong</Text>
    <Text>Looks like some wires got crossed. Please try again!</Text>
  </Box>
);
