import { Form, Formik } from "formik";
import { Field, FormikTextarea } from "src/ccl/data-entry";
import { Modal, ModalProps } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";

export const DeclineRequestModal = ({
  isOpen,
  onClose,
  onDecline,
}: ModalProps & { onDecline: (declineReason: string) => void }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Decline request"
      titleVariant="nh3"
      titleCss={{
        marginBottom: 0,
        fontSize: "$22 !important",
      }}
      showCloseButton
      withBodyPadding={false}
    >
      <Flex css={{ flexDirection: "column", padding: "18px" }}>
        <Formik
          initialValues={{ reason: "" }}
          onSubmit={({ reason }) => onDecline(reason)}
        >
          <Form>
            <Field
              name="reason"
              label="Tell the agency why"
              css={{ mb: "$5" }}
              labelCss={{ mb: "$5" }}
            >
              <FormikTextarea
                rows={8}
                name="reason"
                placeholder="Provide as many details as possible"
                variant="rebrand"
              />
            </Field>
            <Flex
              css={{
                justifyContent: "space-between",
                flexDirection: "column-reverse",
                gap: "18px",
                "@sm": {
                  flexDirection: "row",
                },
              }}
            >
              <Button variant="secondaryCta" type="button" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primaryCta" type="submit">
                Decline request
              </Button>
            </Flex>
          </Form>
        </Formik>
      </Flex>
    </Modal>
  );
};
