import React, { useState } from "react";
import { JobState, SortDirection, SortOrderInput } from "src/graphql/types";
import { JobStateColors } from "src/graphql/colors";
import { Flex } from "src/ccl/layout";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { FloatingPopover } from "src/ccl/feedback";

export enum KanbanSelectorAttribute {
  CreatedAt = "created_at",
  StartDate = "start_date",
}

interface CircleProps {
  jobState: JobState;
  sortOrder: SortOrderInput;
}

export const Circle = ({ jobState, sortOrder }: CircleProps) => {
  let iconVariant: AvailableIcon = "sortDefault";

  if (sortOrder.direction === SortDirection.Asc) {
    iconVariant = "sortAsc";
  } else if (sortOrder.direction === SortDirection.Desc) {
    iconVariant = "sortDesc";
  }

  return (
    <Flex
      css={{
        height: "20px",
        width: "20px",
        borderRadius: "99999999px",
        background: `$${JobStateColors[jobState]}`,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon variant={iconVariant} color="white" size={12} />
    </Flex>
  );
};

interface SelectorOptionProps {
  text: string;
  selected: string;
  onClick: () => void;
}

const SelectorOption = ({ text, selected, onClick }: SelectorOptionProps) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  return (
    <Flex
      css={{
        width: "max-content",
        gap: "$4",
        alignItems: "center",
        cursor: "pointer",
      }}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onClick={onClick}
    >
      <Icon
        variant="check"
        color={isShown || selected === text ? "black" : "white"}
        size={14}
      />
      <Text
        css={{
          display: "block",
          textDecoration: isShown ? "underline" : "none",
        }}
      >
        {text}
      </Text>
    </Flex>
  );
};

interface KanbanSortSelectorProps {
  jobState: JobState;
  sortOrder: SortOrderInput;
  onSortChange: (
    sortByAttribute: KanbanSelectorAttribute,
    sortDirection: SortDirection,
  ) => void;
}

export const KanbanSortSelector = ({
  jobState,
  sortOrder,
  onSortChange,
}: KanbanSortSelectorProps) => {
  const [isSelected, setIsSelected] = useState("Job Date: New to old");
  return (
    <Flex>
      <FloatingPopover
        direction="down"
        alignment="right"
        wrapperCss={{ transform: "translate(0, calc(100% + 6px))" }}
        launcher={<Circle jobState={jobState} sortOrder={sortOrder} />}
      >
        <Flex
          css={{
            p: "$8",
            flexDirection: "column",
          }}
        >
          <SelectorOption
            selected={isSelected}
            text="Job Date: New to old"
            onClick={() => {
              setIsSelected("Job Date: New to old");
              onSortChange(
                KanbanSelectorAttribute.StartDate,
                SortDirection.Desc,
              );
            }}
          />
          <SelectorOption
            selected={isSelected}
            text="Job Date: Old to new"
            onClick={() => {
              setIsSelected("Job Date: Old to new");
              onSortChange(
                KanbanSelectorAttribute.StartDate,
                SortDirection.Asc,
              );
            }}
          />
          <SelectorOption
            selected={isSelected}
            text="Post Date: New to old"
            onClick={() => {
              setIsSelected("Post Date: New to old");
              onSortChange(
                KanbanSelectorAttribute.CreatedAt,
                SortDirection.Desc,
              );
            }}
          />
          <SelectorOption
            selected={isSelected}
            text="Post Date: Old to new"
            onClick={() => {
              setIsSelected("Post Date: Old to new");
              onSortChange(
                KanbanSelectorAttribute.CreatedAt,
                SortDirection.Asc,
              );
            }}
          />
        </Flex>
      </FloatingPopover>
    </Flex>
  );
};
