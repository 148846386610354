import { gql } from "@apollo/client";
import { ASSIGNEE_PARTS_FRAGMENT } from "src/graphql/fragments/AssigneeParts";

export const JOB_PARTS_FRAGMENT = gql`
  fragment JobParts on Job {
    id
    name
    slug
    brand
    state
    virtual
    vertical
    startDate
    endDate
    createdAt
    booker {
      id
      name
    }
    jobVerticals {
      vertical
    }
    assignedAgent {
      ...AssigneeParts
    }
    notes {
      ...NoteDetails
    }
    talent {
      id
      state
      email
      phoneNumber
      talent {
        id
        name
        slug
        featuredAsset {
          mediaUrl
        }
        profile {
          ... on TalentProfile {
            id
            agency {
              name
            }
            vertical
            location
          }
        }
      }
    }
  }
  ${ASSIGNEE_PARTS_FRAGMENT}
`;
