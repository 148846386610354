import { gql } from "@apollo/client";
import { INVOICE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const BOOKER_DASHBOARD_JOB_INVOICES_QUERY = gql`
  query BookerDashboardJobInvoices($jobId: ID!) {
    job(id: $jobId) {
      id
      state
      name
      slug
      invoices {
        ...InvoiceDetails
      }
    }
  }
  ${INVOICE_DETAILS_FRAGMENT}
`;
