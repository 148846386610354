import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Flex } from "src/ccl/layout";
import { AvailableIcon, Icon, IconText } from "src/ccl/document";
import { useToggle } from "src/hooks";

interface TopLevelProps {
  name: string;
  icon: AvailableIcon;
  children: React.ReactNode;
  match: RegExp;
}

export const TopLevel = ({ name, icon, children, match }: TopLevelProps) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isSelected = match.test(currentPath + location.search);

  const [subLevelShow, toggleSubLevelShow] = useToggle();

  return (
    <>
      <Box onClick={() => toggleSubLevelShow()}>
        <Flex
          css={{
            alignItems: "center",
            justifyContent: "space-between",
            height: "60px",
            backgroundColor: isSelected ? "$grey5" : "white",
            borderRadius: "$2",
            px: "$6",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: isSelected ? "$grey5" : "$grey1",
            },
          }}
        >
          <Flex css={{ gap: "$5", alignItems: "center" }}>
            <IconText
              leftIcon={icon}
              text={name}
              iconSize={24}
              iconCss={{
                "@sm": { display: "none" },
                "@lg": { display: "block" },
              }}
              color={isSelected ? "white" : "black"}
              textVariant={isSelected ? "nh4" : "b2Bold"}
            />
          </Flex>
          <Icon
            size={16}
            variant="chevronUp"
            color={isSelected ? "white" : "black"}
            css={{
              transform:
                isSelected || subLevelShow ? "rotate(180deg)" : "rotate(90deg)",
            }}
          />
        </Flex>
        <Box css={{ ml: "$8", mt: "$2", ...(subLevelShow && { pb: "$4" }) }}>
          {isSelected ? children : subLevelShow ? children : undefined}
        </Box>
      </Box>
    </>
  );
};
