import { gql } from "@apollo/client";

export const ASSIGNEE_PARTS_FRAGMENT = gql`
  fragment AssigneeParts on JobAssignee {
    id
    name
    assignedLiveJobCount
    featuredAsset {
      mediaUrl
    }
  }
`;
