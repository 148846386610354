import React from "react";
import { useLocation } from "react-router-dom";
import { StyledNavLink } from "src/ccl/navigation";
import { Box } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";

interface SideNavigationItemProps {
  to: string;
  match?: RegExp;
  longContent?: (color: string) => React.ReactNode;
  shortContent?: (color: string) => React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: (color: string) => React.ReactNode;
  isBooker?: boolean;
}

export const SideNavigationItem = ({
  to,
  match,
  longContent,
  shortContent,
  onClick,
  children = () => null,
  isBooker,
}: SideNavigationItemProps) => {
  const path = useLocation().pathname;
  const active = to === path || match?.test(path) || false;
  const color: keyof typeof tokens.colors = isBooker
    ? "black"
    : active
    ? "white"
    : "black";

  const backgroundColor =
    isBooker && active
      ? tokens.colors.grey2
      : isBooker && !active
      ? tokens.colors.white
      : !isBooker && active
      ? tokens.colors.black
      : tokens.colors.grey1;

  const activeColor = isBooker
    ? tokens.colors.black
    : !isBooker && active
    ? tokens.colors.black
    : tokens.colors.grey1;

  return (
    <StyledNavLink
      to={to}
      onClick={onClick}
      css={{
        backgroundColor: backgroundColor,
        color: activeColor,
        boxShadow: "$innerShadow",
      }}
      activeStyle={{
        backgroundColor: backgroundColor,
        color: activeColor,
      }}
    >
      {longContent && shortContent ? (
        <>
          <Box css={{ display: "none", "@bp2": { display: "block" } }}>
            {longContent(color)}
          </Box>
          <Box css={{ display: "block", "@bp2": { display: "none" } }}>
            {shortContent(color)}
          </Box>
        </>
      ) : (
        <Box>{longContent ? longContent(color) : children(color)}</Box>
      )}
    </StyledNavLink>
  );
};
