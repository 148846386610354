import React from "react";
import * as Stitches from "@stitches/react";
import { Text, TextVariants, Icon } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";
import { iconSizes, tokens } from "src/ccl/stitches/theme";

interface ReviewProps {
  average: number;
  total: number;
  color?: keyof typeof tokens.colors;
  css?: Stitches.CSS;
  textVariant?: keyof typeof TextVariants;
  iconSize?: keyof typeof iconSizes;
}

export const Review = ({
  average,
  total,
  textVariant = "meta",
  color = "black",
  iconSize = 16,
  css = {},
}: ReviewProps) => (
  <Flex
    css={mergeCss(
      {
        alignItems: "center",
      },
      css,
    )}
  >
    <Icon variant="star" size={iconSize} color={color} />
    <Text
      color={color}
      variant={
        textVariant === "meta" || textVariant === "mini"
          ? textVariant
          : { "@initial": "body", "@bp2": textVariant }
      }
      css={{
        mx: "$2",
      }}
    >
      {average}
    </Text>
    <Text
      color={color}
      variant={
        textVariant === "meta" || textVariant === "mini"
          ? textVariant
          : { "@initial": "body", "@bp2": textVariant }
      }
    >
      ({total})
    </Text>
  </Flex>
);
