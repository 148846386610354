import React from "react";
import { TogglePill } from "src/ccl/blocks";
import { FilterOption } from "src/ccl/filtering";

interface BookmarkedFilterProps {
  bookmarked: boolean;
  setBookmarked: (v: boolean) => void;
}

export const BookmarkedFilter = ({
  bookmarked,
  setBookmarked,
}: BookmarkedFilterProps) => (
  <TogglePill
    icon="bookmarkOutline"
    iconSize={24}
    checked={bookmarked}
    setChecked={setBookmarked}
    content="Bookmarked"
    withHoverEffect
  />
);

export const BookmarkedFilterOptions = ({
  bookmarked,
  setBookmarked,
}: BookmarkedFilterProps) => (
  <>
    <FilterOption
      label="Show bookmarked creatives"
      checked={bookmarked}
      onClick={() => setBookmarked(!bookmarked)}
    />
  </>
);
