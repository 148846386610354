import { JobKind } from "src/graphql/types";

// for reference, check "£0 submission on jobs" Notion page
export const NoBudgetValidationJobKinds: JobKind[] = [
  JobKind.Editorial,
  JobKind.Casting,
  JobKind.Fitting,
  JobKind.Gifting,
  JobKind.Other,
  JobKind.TestShoot,
];
