import { capitalize } from "lodash";
import React from "react";
import { NoBookmarkedCreativesCard } from "./NoBookmarkedCreativesCard";
import { Carousel } from "src/ccl/blocks";
import { Icon, TalentCard, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { SavedTalent, TalentProfile, User } from "src/graphql/types";
import { verticalMap } from "src/utils/user";
import bookmarksGold from "src/assets/icons/bookmarksGold.svg";
import { Link } from "src/ccl/navigation";
import { useHandleBookmark } from "src/hooks";
import { sendToAmplitude } from "src/utils/analytics";
import { baseUrl } from "src/config";

const GoldBookmarkIcon = ({
  totalSavedTalent,
}: {
  totalSavedTalent?: number;
}) => (
  <Box
    css={{
      background: `url(${bookmarksGold}) no-repeat center`,
      width: 42,
      height: 59,
      position: "relative",
      top: 0,
      textAlign: "center",
      justifyContent: "space-around",
    }}
  >
    <Text css={{ pt: "$4", fontWeight: "$bold" }}>{totalSavedTalent}</Text>
  </Box>
);

export interface BookmarkedCreativeProps {
  loading: boolean;
  savedTalent: SavedTalent[];
  totalSavedTalent?: number;
  fetchMoreSavedTalent: () => void;
}

export const BookmarkedCreatives = ({
  loading,
  savedTalent,
  totalSavedTalent,
  fetchMoreSavedTalent,
}: BookmarkedCreativeProps) => {
  const { handleBookmark, loading: bookmarkLoading } = useHandleBookmark(true);
  const noSavedTalent = !loading && savedTalent.length === 0;

  return (
    <>
      {noSavedTalent ? (
        <NoBookmarkedCreativesCard />
      ) : (
        <Box
          css={{
            overflow: "auto", // required to constrain the carousel @initial for some reason
            borderTop: "1px solid $grey3",
            borderBottom: "1px solid $grey3",
            "@bp2": {
              borderRadius: 8,
              border: "1px solid $grey3",
            },
            padding: "$7",
            minHeight: 433,
          }}
          data-test-id="BookmarkedCreatives"
        >
          <Flex css={{ gap: "$3", pb: "$6", "@bp2": { gap: "$7" } }}>
            <Box>
              <GoldBookmarkIcon totalSavedTalent={totalSavedTalent} />
            </Box>
            <Box css={{ width: "100%" }}>
              <Text variant="mini" css={{ fontWeight: "$medium" }}>
                Recently Added
              </Text>
              <Link
                to="/dashboard/client/bookmarks"
                css={{ textDecoration: "none" }}
                onClick={() => {
                  sendToAmplitude(
                    "booker dashboard - clicks view all bookmarks",
                  );
                }}
              >
                <Flex css={{ justifyContent: "space-between" }}>
                  <Text variant={{ "@initial": "h3", "@bp2": "h2" }}>
                    All Bookmarks
                  </Text>
                  <Icon variant="chevronRight" size={20} css={{ my: "auto" }} />
                </Flex>
              </Link>
            </Box>
          </Flex>
          <Carousel gap={28} fetchMore={fetchMoreSavedTalent}>
            {savedTalent.map((st, i) => (
              <Box css={{ width: 189 }} key={i}>
                <TalentCard
                  onClick={() =>
                    sendToAmplitude(
                      "booker dashboard - clicks on bookmarked creative",
                      {
                        talentId: st.talent.id,
                        creativeVertical: (st.talent.profile as TalentProfile)
                          .vertical,
                      },
                    )
                  }
                  talent={st.talent}
                  externalLink
                  url={(slug) => `${baseUrl}/creatives/${slug}`}
                  handleBookmark={handleBookmark}
                  isBookmarkDisabled={bookmarkLoading}
                  getSubHeading={(talent: User) => {
                    const profile = talent.profile as TalentProfile;
                    return (
                      <Text variant="meta" css={{ color: "$grey6" }}>
                        {st.timeSinceBookmarked}
                        {
                          <>
                            {" "}
                            &bull; {capitalize(verticalMap[profile.vertical])}
                          </>
                        }
                      </Text>
                    );
                  }}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
      )}
    </>
  );
};
