import React from "react";
import { JobState } from "src/graphql/types";
import { Flex, Box } from "src/ccl/layout";
import { Icon, PulsatingIcon, Text } from "src/ccl/document";
import { JobStateColors } from "src/graphql/colors";
import { jobStates, tokens } from "src/ccl/stitches/theme";
import { ExternalLink } from "src/ccl/navigation";

interface StepProps {
  currentStep: number;
  state: JobState;
  step: number;
  title: string;
  heading: string;
  subtextContent?: React.ReactNode;
}

const Step: React.FC<StepProps> = ({
  currentStep,
  state,
  step,
  title,
  heading,
  subtextContent,
}) => {
  const isCurrentStep = step === currentStep;
  const isCompletedStep = step < currentStep;
  const stepColor = jobStates[state] as keyof typeof tokens.colors;

  return (
    <Flex css={{ ml: -12 }}>
      <Box>
        {isCurrentStep && (
          <PulsatingIcon color={stepColor} backgroundColor={stepColor}>
            <Icon
              variant="check"
              size={12}
              color={stepColor}
              css={{ transform: "translate(3px, 0px)" }}
            />
          </PulsatingIcon>
        )}
        {isCompletedStep && (
          <Box
            css={{
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              backgroundColor: "$darkGreen",
            }}
          >
            <Icon
              variant="check"
              size={12}
              color="white"
              css={{ transform: "translate(3px, 0px)" }}
            />
          </Box>
        )}
      </Box>
      <Box
        css={{ width: "100%", pl: step > currentStep ? "$9" : "$4", pb: "$5" }}
      >
        <Text
          variant="mini"
          color={
            isCurrentStep
              ? JobStateColors[state]
              : isCompletedStep
              ? "black"
              : "grey6"
          }
        >
          {title}
        </Text>
        <Text
          variant={{ "@initial": "nh4", "@md": "nh3" }}
          color={isCurrentStep || isCompletedStep ? "black" : "grey6"}
          css={{ my: "$3" }}
        >
          {step}. {heading}
        </Text>
        {subtextContent && currentStep <= step && subtextContent}
      </Box>
    </Flex>
  );
};

interface JobsGuideModalProgressBarProps {
  state: JobState;
  stage: number;
}

interface StepData {
  step: number;
  title: string;
  heading: string;
  subtextContent?: React.ReactNode;
}

export const JobsGuideModalProgressBar: React.FC<
  JobsGuideModalProgressBarProps
> = ({ state, stage }) => {
  const steps: StepData[] = [
    { step: 1, title: "Job creation", heading: "You've created a job" },
    {
      step: 2,
      title: "Pending approval",
      heading: stage > 2 ? "We've approved your job" : "Approve job",
      subtextContent: stage <= 2 && (
        <Text color={stage !== 2 ? "grey6" : "black"}>
          We&apos;ll check that the job and fee are suitable. This normally
          takes a few hours but can be a lot quicker. Not heard from us? check
          your spam folder or email{" "}
          <ExternalLink
            to="mailto:bookings@contact.xyz"
            eventName={"client:jobs:view:emailLink"}
          >
            <strong style={{ textDecoration: "underline" }}>
              bookings@contact.xyz
            </strong>
          </ExternalLink>
        </Text>
      ),
    },
    {
      step: 3,
      title: "waiting for responses",
      heading: stage > 3 ? "Your job's confirmed" : "Confirm talents",
      subtextContent: stage <= 3 && (
        <Text color={stage !== 3 ? "grey6" : "black"}>
          We&apos;ll notify the talents on your shortlist and they&apos;ll
          accept or reject the job. You can email talent directly by finding
          their details under your live booking.
        </Text>
      ),
    },
    {
      step: 4,
      title: "PAYMENT DETAILS",
      heading: "Payment via Invoice",
      subtextContent: stage <= 4 && (
        <Text color={stage !== 4 ? "grey6" : "black"} css={{ mt: "$5" }}>
          Once you finalise your shortlist the job is confirmed. An invoice will
          be generated when the job has been completed and will be available for
          you to download via your dashboard. Please email{" "}
          <ExternalLink
            to="mailto:bookings@contact.xyz"
            eventName={"client:jobs:view:emailLink"}
          >
            <strong style={{ textDecoration: "underline" }}>
              bookings@contact.xyz
            </strong>
          </ExternalLink>{" "}
          with any supplier forms or PO Numbers if required.
        </Text>
      ),
    },
    {
      step: 5,
      title: "JOB CONFIRMED",
      heading: "Get Ready",
      subtextContent: stage <= 4 && (
        <Text color={stage !== 4 ? "grey6" : "black"} css={{ mt: "$5" }}>
          Your job is now locked in and you can locate your talents email under
          your confirmed job. Please feel free to reach out to them with your
          Contact job manager on CC with a call sheet and any specifics ahead of
          your job.
        </Text>
      ),
    },
  ];

  return (
    <Flex
      css={{
        ml: 9,
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        borderLeft: "3px solid $grey2",
        gap: "$7",
      }}
    >
      {steps.map(({ step, title, heading, subtextContent }) => (
        <Step
          key={step}
          state={state}
          currentStep={stage}
          step={step}
          title={title}
          heading={heading}
          subtextContent={subtextContent}
        />
      ))}
    </Flex>
  );
};
