import { lazy, ComponentType } from "react";

const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const protectedLazy = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
) => {
  const attempt = async (firstAttempt = true): Promise<{ default: T }> => {
    try {
      return await factory();
    } catch (e) {
      if (e instanceof Error && e.name !== "ChunkLoadError") {
        throw e;
      }

      await sleep(1000);

      if (!firstAttempt) {
        window.location.reload();
      }

      return attempt(false);
    }
  };

  return lazy(attempt);
};
