export const howItWorksList = [
  {
    icon: "moneyBudget",
    linkTo: "/l/pricing",
    title: "Pricing",
    subtitle: "Know exactly what you're paying for",
    eventName: "how it works - pricing",
  },
  {
    icon: "jobs",
    linkTo: "/l/booked-on-contact",
    title: "Booked on Contact",
    subtitle: "Get inspired by other clients",
    eventName: "how it works - booked on contact",
  },
  {
    icon: "quoteRounded",
    linkTo: "/l/case-studies",
    title: "Case studies",
    subtitle: "Meet the other brands using Contact",
    eventName: "how it works - case studies",
  },
  {
    icon: "bookWithStar",
    linkTo: "/about",
    title: "Our story",
    subtitle: `We're on a mission to empower creatives`,
    eventName: "how it works - about",
  },
  {
    icon: "playCircle",
    linkTo: "/l/getting-started",
    title: "Watch a demo",
    subtitle: "Learn how to book a job in 3 minutes",
    eventName: "how it works - demo",
  },
];

export const resourcesList = [
  {
    icon: "question",
    linkTo: "https://support.contact.xyz/hc/en-gb",
    bookerLinkTo:
      "https://support.contact.xyz/hc/en-gb/categories/7109010192785-Bookers",
    title: "FAQs",
    subtitle: "Your questions answered",
    openInNewWindow: true,
    eventName: "resources - faqs",
  },
  {
    icon: "clapperboard",
    linkTo: "/backstage",
    bookerLinkTo: "/backstage",
    title: "Backstage",
    subtitle: "Blogging from behind the scenes",
    eventName: "resources - backstage",
  },
  {
    icon: "speaker",
    linkTo: "/creative-paths",
    bookerLinkTo: "/creative-paths",
    title: "Podcast",
    subtitle: "Listen to creatives tell their story",
    eventName: "resources - podcast",
  },
];

export const contactForList = [
  {
    icon: "bookersVertical",
    linkTo: "/for/clients",
    title: "Clients",
    subtitle: "For clients",
    eventName: "Contact for - clients",
  },
  {
    icon: "agenciesVertical",
    linkTo: "/for/agencies",
    title: "Agencies",
    subtitle: "For agencies",
    eventName: "Contact for - agencies",
  },
  {
    icon: "creativePathsVertical",
    linkTo: "/for/creatives",
    title: "Creatives",
    subtitle: "For creatives",
    eventName: "Contact for - creatives",
  },
];
