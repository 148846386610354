import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { Modal, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { Field, FormikInput } from "src/ccl/data-entry";
import { INVITE_AGENT_MUTATION } from "src/graphql/mutations";
import { Button } from "src/ccl/navigation";
import { ValidationBlock } from "src/ccl/feedback";
import { sendToAmplitude } from "src/utils/analytics";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email("Must be a valid email address"),
});

interface ADAddAgentModalProps {
  isOpen: boolean;
  onClose: () => void;
  agencyId: string | undefined;
  isFpa?: boolean;
}

export const ADAddAgentModal = ({
  isOpen,
  onClose,
  agencyId,
  isFpa,
}: ADAddAgentModalProps) => {
  const [createAgentInvitation, { loading, error }] = useMutation(
    INVITE_AGENT_MUTATION,
    {
      update(cache, { data: { inviteUserToAgency } }) {
        cache.modify({
          id: `Agency:${agencyId}`,
          fields: {
            agentInvitations(existingInvitations = []) {
              const newInvitationRef = cache.writeFragment({
                data: inviteUserToAgency,
                fragment: gql`
                  fragment AgentInvitationDetails on AgentInvitation {
                    id
                    email
                    createdAt
                  }
                `,
              });
              return [...existingInvitations, newInvitationRef];
            },
          },
        });
      },
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={true}>
      <Box>
        <Text variant="nh3" css={{ mb: "$7" }}>
          Add new agent
        </Text>
        <Text css={{ mb: "$9" }}>
          Submit the email address you wish to send an invite to.
        </Text>

        <Formik
          validationSchema={validationSchema}
          initialValues={{ email: "" }}
          onSubmit={async (values) => {
            await createAgentInvitation({
              variables: {
                email: values.email,
                ...(isFpa && { agencyId }),
              },
            });
            onClose();
            sendToAmplitude("tpa settings manage agents - adds agent", {
              agency_id: agencyId,
            });
          }}
        >
          <Form>
            <Field variant="b2Bold" name="email" label="Email address">
              <FormikInput
                id="form-email"
                name="email"
                type="email"
                variant="rebrand"
              />
            </Field>

            <Flex
              css={{
                mt: "$9",
                justifyContent: "space-between",
                flexDirection: "column-reverse",
                gap: "$4",
                "@sm": {
                  flexDirection: "row",
                },
              }}
            >
              <Button variant="secondaryCta" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primaryCta" type="submit" disabled={loading}>
                Send invite
              </Button>
            </Flex>

            {error && <ValidationBlock title={error.message} variant="error" />}
          </Form>
        </Formik>
      </Box>
    </Modal>
  );
};
