import React from "react";
import { Field } from "formik";
import { Toggle, ToggleProps } from "./Toggle";

export const FormikToggle = ({ id, name, ...props }: Partial<ToggleProps>) => (
  <Field
    as={Toggle}
    type="checkbox"
    id={id || `form-${name}`}
    name={name}
    {...props}
  />
);
