import React from "react";
import type * as Stitches from "@stitches/react";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";
import { Pill } from "src/ccl/blocks";
import { iconSizes } from "src/ccl/stitches/theme";

export const MegaNavExplainer = ({
  icon,
  iconSize,
  title,
  copy,
  pill = false,
  css = {},
  iconCss = {},
}: {
  icon: AvailableIcon;
  title: string;
  copy: string;
  pill?: boolean;
  css?: Stitches.CSS;
  iconSize: keyof typeof iconSizes;
  iconCss?: Stitches.CSS;
}) => (
  <Flex
    css={mergeCss(
      {
        flexDirection: "row",
        "@bp2": { flexDirection: "column" },
      },
      css,
    )}
  >
    <Box>
      <Icon
        variant={icon}
        size={iconSize}
        css={mergeCss(
          { pr: "$5", height: "auto", "@bp2": { pr: "$0", mb: "$3" } },
          iconCss,
        )}
      />
    </Box>
    <Box>
      {pill ? (
        <Flex
          css={{
            alignItems: "flex-start",
            flexDirection: "column",
            "@bp2": { mb: "$3", flexDirection: "row", pt: "$3" },
          }}
        >
          <Pill
            variant="announcement"
            css={{ mt: "$0", ml: "$0", mb: "$3", "@bp2": { mb: "$0" } }}
          >
            <Text variant="meta" color="white">
              New
            </Text>
          </Pill>
          <Text
            weight="bold"
            css={{ pl: "$0", "@bp2": { pl: "$3", pt: "$1" } }}
          >
            {title}
          </Text>
        </Flex>
      ) : (
        <Text weight="bold" css={{ mb: "$3" }}>
          {title}
        </Text>
      )}
      <Text>{copy}</Text>
    </Box>
  </Flex>
);
