import React, { ReactNode } from "react";
import {
  documentToReactComponents,
  RenderNode,
  CommonNode,
} from "@contentful/rich-text-react-renderer";
import {
  Document,
  BLOCKS,
  INLINES,
  Text as ContentfulTextBlock,
  Inline as ContentfulInlineBlock,
} from "@contentful/rich-text-types";
import { BlogAsset } from "src/components/blog/BlogAsset";
import { ContentfulAsset } from "src/entities";
import { styled } from "src/ccl/stitches";
import { Box } from "src/ccl/layout";
import { ExternalLink } from "src/ccl/navigation";
import { Text } from "src/ccl/document";

const ListUl = styled("ul", {
  ml: "$9",
  listStyle: "initial",
});

const ListOl = styled("ol", {
  ml: "$9",
});

const isInlineBlock = (node: CommonNode): node is ContentfulInlineBlock =>
  Object.values(INLINES as any).includes(node.nodeType); // eslint-disable-line @typescript-eslint/no-explicit-any

const isTextBlock = (node: CommonNode): node is ContentfulTextBlock =>
  node.nodeType === "text";

const isEmbed = (node: CommonNode) => {
  if (!isInlineBlock(node) || !isTextBlock(node.content[0])) {
    return false;
  }

  if (
    !node.data.uri.startsWith("https://www.youtube.com/watch?v=") &&
    !node.data.uri.startsWith("https://open.spotify.com/episode/")
  ) {
    return false;
  }

  return node.data.uri === node.content[0].value;
};

const getEmbedLink = (node: CommonNode) => {
  const { uri } = node.data;
  if (uri.includes("youtube")) {
    const youTubeId = uri.split("v=")[1].split("&")[0];
    return `https://www.youtube.com/embed/${youTubeId}`;
  } else if (uri.includes("spotify")) {
    const splitLink = uri.split("/");
    const spotifyId = splitLink[splitLink.length - 1];
    return `https://open.spotify.com/embed/episode/${spotifyId}`;
  }
};

/* eslint-disable react/display-name */
const StaticRenderers = {
  [BLOCKS.PARAGRAPH]: (node: CommonNode, children: ReactNode) => (
    <Text css={{ mb: "$5", lineHeight: "$26" }}>{children}</Text>
  ),
  [BLOCKS.HEADING_1]: (node: CommonNode, children: ReactNode) => (
    <Text css={{ mb: "$3" }} variant="h1">
      {children}
    </Text>
  ),
  [BLOCKS.HEADING_2]: (node: CommonNode, children: ReactNode) => (
    <Text css={{ mb: "$3" }} variant="h2">
      {children}
    </Text>
  ),
  [BLOCKS.HEADING_3]: (node: CommonNode, children: ReactNode) => (
    <Text css={{ mb: "$3" }} variant="h3">
      {children}
    </Text>
  ),
  [BLOCKS.HEADING_4]: (node: CommonNode, children: ReactNode) => (
    <Text css={{ mb: "$3" }} variant="h4">
      {children}
    </Text>
  ),
  [BLOCKS.HEADING_5]: (node: CommonNode, children: ReactNode) => (
    <Text css={{ mb: "$3" }} variant="h4">
      {children}
    </Text>
  ),
  [BLOCKS.HEADING_6]: (node: CommonNode, children: ReactNode) => (
    <Text css={{ mb: "$3" }} variant="h4">
      {children}
    </Text>
  ),
  [BLOCKS.OL_LIST]: (node: CommonNode, children: ReactNode) => (
    <ListOl>{children}</ListOl>
  ),
  [BLOCKS.UL_LIST]: (node: CommonNode, children: ReactNode) => (
    <ListUl>{children}</ListUl>
  ),
  [INLINES.HYPERLINK]: (node: CommonNode, children: ReactNode) => {
    if (isEmbed(node)) {
      return (
        <Box
          css={{
            width: "100%",
            paddingTop: "56.25%",
            position: "relative",
            height: 0,
          }}
        >
          <iframe
            title="Embedded content"
            src={getEmbedLink(node)}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </Box>
      );
    }

    return (
      <ExternalLink to={node.data.uri} eventName="blog:post:link">
        {children}
      </ExternalLink>
    );
  },
};

const buildNodeRenderers = (assets: ContentfulAsset[]): RenderNode => {
  return {
    ...StaticRenderers,
    [BLOCKS.EMBEDDED_ASSET]: (node: CommonNode) => {
      const assetId = node.data.target.sys.id;
      const asset = assets.find((a) => a.sys.id === assetId);
      if (!asset) {
        return null;
      }

      return (
        <Box css={{ mb: "$5" }}>
          <BlogAsset asset={asset} />
          {asset.fields.description && (
            <Text
              variant="meta"
              color="grey6"
              css={{
                textAlign: "center",
                mt: "$3",
              }}
            >
              {asset.fields.description}
            </Text>
          )}
        </Box>
      );
    },
  };
};
/* eslint-enable react/display-name */

interface ContentfulRichTextProps {
  document: Document;
  assets?: ContentfulAsset[];
}

export const ContentfulRichText = ({
  document,
  assets = [],
}: ContentfulRichTextProps) => {
  const renderers = buildNodeRenderers(assets);

  return <>{documentToReactComponents(document, { renderNode: renderers })}</>;
};
