import { gql } from "@apollo/client";

export const BOOKER_INVITATION_QUERY = gql`
  query BookerInvitation($token: String!) {
    bookerInvitation(token: $token) {
      email
      token
      bookingCompanyName
      isExistingAccount
    }
  }
`;
