import React, { useState } from "react";
import { startOfDay } from "date-fns";
import { useRangePickerState } from "./useRangePickerState";
import { isAfterDate, isBeforeDate, isEqualDate, isInRange } from "./util";
import { styled } from "src/ccl";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Flex, Box, Grid } from "src/ccl/layout";
import { formatDate, formatDisplayDate } from "src/utils/dates";
import { DateRangeInput } from "src/graphql/types";
import { EnhancedInput } from "src/ccl/data-entry/input";
import { useDatePickerState } from "src/ccl/data-entry/datePicker/useDatePickerState";
import { OutsideClickHandler } from "src/ccl/util/OutsideClickHandler";

const dayTitles = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

const CalendarHeader = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate?: string;
}) => (
  <>
    <Flex css={{ justifyContent: "space-between" }}>
      <Text color="grey6" variant="meta">
        Start
      </Text>
      <Text color="grey6" variant="meta">
        End
      </Text>
    </Flex>
    <Grid
      css={{
        justifyContent: "space-between",
        pt: "$3",
        gridColumns: "1fr 1fr 1fr",
        pb: "$10",
      }}
    >
      <Text variant="meta" css={{ fontWeight: "$medium" }}>
        {startDate}
      </Text>
      <Text variant="meta" css={{ textAlign: "center" }}>
        To
      </Text>
      <Text
        color={endDate ? "black" : "grey6"}
        variant="meta"
        css={{ fontWeight: "$medium", textAlign: "right" }}
      >
        {endDate || "Select Date"}
      </Text>
    </Grid>
  </>
);

const CalendarControl = ({
  variant,
  onClick,
}: {
  variant: AvailableIcon;
  onClick: () => void;
}) => (
  <Box
    onClick={onClick}
    css={{
      my: "auto",
      "&:hover": { cursor: "pointer" },
      justifySelf: variant === "chevronRight" ? "end" : "start",
    }}
  >
    <Icon variant={variant} size={18} />
  </Box>
);

const Day = styled("div", {
  textAlign: "center",
  py: "$2",
  fontSize: "$14",
  lineHeight: "$22",
  fontWeight: "$bold",
  borderRadius: "$round",
  width: "$9",
  height: "$9",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$grey3",
  },
  variants: {
    variant: {
      selected: {
        backgroundColor: "$black",
        color: "$white",
        "&:hover": {
          color: "$black",
        },
      },
      inRange: {
        backgroundColor: "$grey2",
      },
      disabled: {
        color: "$grey3",
        "&:hover": {
          backgroundColor: "initial",
          cursor: "not-allowed",
        },
      },
    },
  },
});

const Cell = ({
  isStart,
  isEnd,
  inRange,
  index,
  day,
  onClick,
  disabled,
}: {
  isStart: boolean;
  isEnd: boolean;
  inRange: boolean;
  index: number;
  day?: number;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const selected = isStart || isEnd;

  const isStartOfRow = index % 7 === 0 || day === 1 || isStart;
  const isEndOfRow = index % 7 === 6 || isEnd;

  // being the start of the range overrides being the end of a row
  const overrideFillLeft = isEndOfRow && isStart;
  const fillLeft = !overrideFillLeft && inRange && isEndOfRow;
  const fillRight = inRange && isStartOfRow;
  const linearGradient = fillLeft
    ? `to left, $white 50%, $grey2 50%`
    : fillRight
    ? `to right, $white 50%, $grey2 50%`
    : undefined;

  // we round the edges when we're at the start of the month end of the
  //month, start of a row or end of a row,
  const roundEdges = fillLeft || fillRight;
  return (
    <Flex
      css={{
        justifyContent: "space-around",
        backgroundColor: !selected && inRange ? "$grey2" : undefined,
        linearGradient,
      }}
    >
      {day && (
        <Day
          onClick={disabled ? undefined : onClick}
          variant={
            selected
              ? "selected"
              : roundEdges
              ? "inRange"
              : disabled
              ? "disabled"
              : undefined
          }
        >
          {day}
        </Day>
      )}
    </Flex>
  );
};

export const RangePicker = ({
  onChange,
}: {
  onChange: (dateRange: DateRangeInput) => void;
}) => {
  const { startDate, endDate, ...rangePickerProps } =
    useRangePickerState(onChange);

  return (
    <Box css={{ pt: "$6", mt: "$7", borderTop: "1px solid $grey1" }}>
      <CalendarHeader
        startDate={formatDate(startDate, "dd MMM yyyy")}
        endDate={endDate ? formatDate(endDate, "dd MMM yyyy") : undefined}
      />
      <CalendarBody
        startDate={startDate}
        endDate={endDate}
        {...rangePickerProps}
      />
    </Box>
  );
};

export const DatePicker = ({
  onChange,
  rangeDate = false,
  disablePastDates,
  initialDate,
  endDate,
  disabled,
  startDate,
  calendarPosition = "top",
}: {
  onChange: (date: Date) => void;
  disablePastDates?: boolean;
  initialDate?: Date;
  disabled?: boolean;
  calendarPosition?: "top" | "bottom";
  startDate?: Date;
  endDate?: Date;
  rangeDate?: boolean;
}) => {
  const { date, ...datePickerProps } = useDatePickerState((d) => {
    setShowCalendar(false);
    onChange(d);
  }, initialDate);
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setShowCalendar(false)}>
      <Box onFocus={() => setShowCalendar(true)}>
        <Box>
          <EnhancedInput
            type="text"
            disabled={disabled}
            value={formatDisplayDate(date, "dd MMM yyyy")}
            inputSuffix={<Icon variant="calendar" size={14} color="grey6" />}
            onChange={() => {}}
            variant="rebrand"
            css={{
              cursor: "pointer",
              alignItems: "center",
            }}
          />
        </Box>

        <Box css={{ position: "relative" }}>
          <Box
            css={{
              zIndex: "$500",
              position: "absolute",
              [calendarPosition === "top" ? "bottom" : "top"]: "$14",
              left: "$0",
              px: "$11",
              py: "$9",
              backgroundColor: "$white",
              display: showCalendar ? "initial" : "none",
              boxShadow: "$subtle",
            }}
          >
            <CalendarBody
              startDate={startDate || date}
              endDate={endDate}
              disablePastDates={disablePastDates}
              rangeDate={rangeDate}
              {...datePickerProps}
            />
          </Box>
        </Box>
      </Box>
    </OutsideClickHandler>
  );
};
export const CalendarBody = ({
  previousMonth,
  nextMonth,
  setDate,
  daysOfMonth,
  displayedMonth,
  startDate,
  endDate,
  disablePastDates,
  rangeDate = false,
}: {
  previousMonth: () => void;
  nextMonth: () => void;
  setDate: (d: number) => void;
  daysOfMonth: (number | undefined)[];
  displayedMonth: Date;
  startDate: Date;
  endDate?: Date;
  disablePastDates?: boolean;
  rangeDate?: boolean;
}) => {
  return (
    <>
      <Grid
        css={{
          justifyContent: "space-between",
          gridColumns: "1fr 1fr 1fr",
          pb: "$7",
        }}
      >
        <CalendarControl variant="chevronLeft" onClick={previousMonth} />
        <Text css={{ fontWeight: "$bold", textAlign: "center" }}>
          {formatDate(displayedMonth, "MMMM yyyy")}
        </Text>
        <CalendarControl variant="chevronRight" onClick={nextMonth} />
      </Grid>
      <Grid
        css={{ gridColumns: "repeat(7, 1fr)", textAlign: "center", rowGap: 9 }}
      >
        {dayTitles.map((d, i) => (
          <Text
            key={i}
            variant="meta"
            color="grey6"
            css={{ fontWeight: "$bold" }}
          >
            {d}
          </Text>
        ))}

        {daysOfMonth.map((d, i) => {
          const inRange = d && isInRange(d, displayedMonth, startDate, endDate);
          return (
            <Cell
              key={i}
              index={i}
              day={d}
              onClick={d ? () => setDate(d) : undefined}
              isStart={!!(d && isEqualDate(d, displayedMonth, startDate))}
              isEnd={
                !!(d && endDate && isEqualDate(d, displayedMonth, endDate))
              }
              inRange={!!inRange}
              disabled={
                !!(
                  (disablePastDates &&
                    d &&
                    isBeforeDate(d, displayedMonth, startOfDay(new Date()))) ||
                  (rangeDate &&
                    endDate &&
                    d &&
                    isAfterDate(d, displayedMonth, endDate)) ||
                  (rangeDate &&
                    startDate &&
                    d &&
                    isBeforeDate(d, displayedMonth, startDate))
                )
              }
            />
          );
        })}
      </Grid>
    </>
  );
};
