import { gql } from "@apollo/client";

export const TALENT_INVITATION_QUERY = gql`
  query TalentInvitation($token: String!) {
    talentInvitation(token: $token) {
      email
      vertical
    }
  }
`;
