import { gql } from "@apollo/client";

export const MESSAGING_LIST_JOBS_QUERY = gql`
  query MessagingListJobs($filters: JobFilterInput) {
    me {
      id
      jobs(filters: $filters) {
        totalCount
        edges {
          node {
            id
            name
            startDate
            brand
            state
          }
        }
      }
    }
  }
`;
