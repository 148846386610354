import { bookerKindOptions } from "./bookerKinds";
import { DropdownPill } from "src/ccl/blocks";
import { FilterOption } from "src/ccl/filtering";

interface BookerTypeFilterProps {
  variant?: "dropdown" | "pill";
  bookerKind: string;
  setBookerKind: (kind: string) => void;
}

export const BookerTypeFilterOptions = ({
  variant,
  bookerKind,
  setBookerKind,
}: BookerTypeFilterProps) => {
  const clientTypes = [
    {
      label: "All clients",
      value: "",
    },
    ...bookerKindOptions,
  ];
  return (
    <>
      {clientTypes.map((clientType, i) => (
        <FilterOption
          key={i}
          variant={variant}
          label={clientType.label}
          checked={bookerKind === clientType.value}
          onClick={() => setBookerKind(clientType.value)}
        />
      ))}
    </>
  );
};

export const BookerTypeFilter = ({
  bookerKind,
  setBookerKind,
}: BookerTypeFilterProps) => {
  return (
    <DropdownPill
      active={bookerKind !== ""}
      text="Booker type"
      icon="filter"
      withHoverEffect
    >
      <BookerTypeFilterOptions
        bookerKind={bookerKind}
        setBookerKind={setBookerKind}
      />
    </DropdownPill>
  );
};
