import React from "react";
import { FormikErrors, useFormikContext } from "formik";
import { TalentFormValues } from "./formConfigV2";
import { Box, Grid } from "src/ccl/layout";
import {
  Checkbox,
  Field,
  FormikInput,
  MultiSelect,
  SelectReact,
} from "src/ccl/data-entry";
import { Text } from "src/ccl/document";
import {
  Field as VerticalField,
  InputType,
  TalentVertical,
  UpdateTalentProfileInput,
  VerticalConfiguration,
} from "src/graphql/types";
import {
  ColouredRadioSelect,
  FieldInfoText,
  RadioSelect,
} from "src/components/dashboards/agents/talentManagement";
import { OptionWrapper } from "src/ccl/filtering";
import { ValidationBlock } from "src/ccl/feedback";
import { uppercaseFirstLetter } from "src/utils/lang";
import { verticalMap } from "src/utils/user";
import { useFeatures } from "src/hooks";

const renderTextField = (field: VerticalField, hidden: boolean) => {
  return hidden ? undefined : (
    <Field
      variant="b2Bold"
      name={`talentProfile.verticalData.${field.name}`}
      label={field.label}
      labelCss={{ mb: "$5" }}
      secondaryLabel={!field.required && "(optional)"}
    >
      <FormikInput
        name={`talentProfile.verticalData.${field.name}`}
        inputSuffix={field.unit}
        type="text"
        variant="rebrand"
      />
      {field.unit && field.minValue && field.maxValue && (
        <FieldInfoText
          dataTestId={`FormHint-talentProfile.verticalData.${field.name}`}
          text={`This should be between ${field.minValue}${field.unit} and ${field.maxValue}${field.unit}`}
        />
      )}
    </Field>
  );
  return <Text>Test</Text>;
};

const renderRadioField = (
  field: VerticalField,
  selectedOption: string,
  onChange: (value: string) => void,
  hidden: boolean,
  error?: string,
) => {
  const { featureEnabled } = useFeatures();
  return hidden ? (
    <></>
  ) : (
    <Box
      css={{
        width: "100%",
        gridColumn: featureEnabled("tpa_updated_creative_upload")
          ? "span 2"
          : undefined,
      }}
    >
      <Text variant="b2Bold" css={{ mb: "$5", mt: "$9" }} weight="bold">
        {field.label}
        {!field.required && (
          <Text variant="meta" color="grey6" as="span">
            (optional)
          </Text>
        )}
      </Text>
      {(field.options || [])[0].colour !== null ? (
        <ColouredRadioSelect
          name={field.name}
          options={field.options || []}
          selectedOption={selectedOption}
          setSelectedOption={(value) => onChange(value)}
          gridCss={{
            width: "100%",
            gridColums: 1,
            "@sm": { gridColumns: 2 },
            "@lg": { gridColumns: 3 },
          }}
        />
      ) : (
        <RadioSelect
          name={field.name}
          options={field.options || []}
          selectedOption={selectedOption}
          setSelectedOption={(value) => onChange(value)}
          gridCss={{
            width: "100%",
            gridColums: 1,
            "@sm": { gridColumns: 2 },
            "@lg": { gridColumns: 3 },
          }}
        />
      )}
      {error && (
        <ValidationBlock
          title={error}
          variant="error"
          data-test-id={`FormFieldError-${field.name}`}
          css={{ mt: "$3" }}
        />
      )}
    </Box>
  );
};

const renderSelectField = (
  field: VerticalField,
  selectedOption: { label: string; value: string } | undefined,
  onChange: (value: string) => void,
  hidden: boolean,
  error?: string,
) => {
  const { featureEnabled } = useFeatures();
  const rebrandFlag = featureEnabled("tpa_updated_creative_upload");
  const dropdownVariant = rebrandFlag ? "rebrand" : undefined;
  return hidden ? (
    <></>
  ) : (
    <Box css={{ mb: "$5" }}>
      <SelectReact
        id={field.name}
        label={field.label}
        initialValues={selectedOption}
        onChange={(value) => {
          if (value?.value) {
            onChange(value.value);
          }
        }}
        options={field.options}
        optional={!field.required}
        variant={dropdownVariant}
      />
      {error && (
        <ValidationBlock
          title={error}
          variant="error"
          data-test-id={`FormFieldError-${field.name}`}
          css={{ mt: "$3" }}
        />
      )}
    </Box>
  );
};

const renderMultiSelectField = (
  field: VerticalField,
  onChange: (values: string[]) => void,
  hidden: boolean,
  error?: string,
) =>
  hidden ? (
    <></>
  ) : (
    <Box>
      <MultiSelect
        name={field.name}
        label={field.label}
        onChange={(values) => {
          onChange(values);
        }}
        options={field.options}
        optional={!field.required}
      />
      {error && (
        <ValidationBlock
          title={error}
          variant="error"
          data-test-id={`FormFieldError-${field.name}`}
        />
      )}
    </Box>
  );

const renderRadioSelectField = (
  field: VerticalField,
  value: string,
  onChange: (value: string) => void,
  hidden: boolean,
  error?: string,
) =>
  hidden ? (
    <></>
  ) : (
    <>
      <RadioSelect
        name={field.name}
        label={field.label}
        selectedOption={value}
        setSelectedOption={(value) => {
          onChange(value);
        }}
        options={field.options || []}
        optional={!field.required}
      />
      {error && (
        <ValidationBlock
          title={error}
          variant="error"
          data-test-id={`FormFieldError-${field.name}`}
        />
      )}
    </>
  );

const renderMultiCheckboxField = (
  field: VerticalField,
  initialChecked: string[],
  onChange: (value: string, checked: boolean) => void,
  hidden: boolean,
  error?: string,
) =>
  hidden ? (
    <></>
  ) : (
    <Box>
      <Text css={{ mb: "$2" }} weight="bold">
        {field.label}
        {!field.required && (
          <Text variant="meta" color="grey6" as="span" css={{ ml: "$2" }}>
            (optional)
          </Text>
        )}
      </Text>
      <Grid css={{ gridColumns: 1, "@bp2": { gridColumns: 2 } }}>
        {(field.options || []).map((f) => (
          <OptionWrapper
            key={f.value}
            htmlFor={`${field.name}-${f.value}`}
            css={{ alignItems: "center" }}
          >
            <Checkbox
              id={`${field.name}-${f.value}`}
              css={{ width: 42, height: 42 }}
              value={f.value}
              defaultChecked={initialChecked.includes(f.value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { checked } = e.target;
                onChange(f.value, checked);
              }}
            />
            <Box css={{ cursor: "pointer" }}>
              <Text css={{ ml: "$4" }}>{f.label}</Text>
            </Box>
          </OptionWrapper>
        ))}
      </Grid>
      {error && (
        <ValidationBlock
          title={error}
          variant="error"
          data-test-id={`FormFieldError-${field.name}`}
        />
      )}
    </Box>
  );

const renderField = (
  talentProfile: UpdateTalentProfileInput,
  field: VerticalField,
  errors: FormikErrors<TalentFormValues>,
  onChange: (value: string | string[]) => void,
) => {
  const value = talentProfile.verticalData[field.name] || undefined;

  const selectSelectedOption = (field.options || []).find(
    (option) => option.value === (value || "").toString(),
  );

  const verticalDataErrors = parseVerticalDataErros(errors);
  const hidden = !!(
    talentProfile.gender &&
    field.excludedGenders?.includes(talentProfile.gender)
  );

  switch (field.inputType) {
    case InputType.MultiSelect:
      return renderMultiSelectField(
        field,
        (value) => {
          onChange(value);
        },
        hidden,
        verticalDataErrors.verticalData[field.name],
      );
    case InputType.Radio:
      return renderRadioField(
        field,
        value,
        (value) => {
          onChange(value);
        },
        hidden,
        verticalDataErrors.verticalData[field.name],
      );
    case InputType.RadioSelect:
      return renderRadioSelectField(
        field,
        value,
        (value) => {
          onChange(value);
        },
        hidden,
        verticalDataErrors.verticalData[field.name],
      );
    case InputType.Select:
      return renderSelectField(
        field,
        selectSelectedOption,
        (value) => {
          onChange(value);
        },
        hidden,
        verticalDataErrors.verticalData[field.name],
      );
    case InputType.Text:
      return renderTextField(field, hidden);
  }
};

// Formik is a real pain here. The errors object is built dynamically
// depending on the vertical, but it tries to guess the type from the static
// types. We don't know what the errors object will contain here as it's
// dependent on the vertical. So we'll just cast it to a map and try pluck
// the values out that way.
const parseVerticalDataErros = (errors: FormikErrors<TalentFormValues>) => {
  const verticalDataErrors = (errors.talentProfile || { verticalData: {} }) as {
    verticalData: Record<string, string>;
  };
  if (!verticalDataErrors.verticalData) {
    verticalDataErrors.verticalData = {};
  }

  return verticalDataErrors;
};

interface ProfileVerticalFieldsFormProps {
  vertical: TalentVertical;
  verticalConfig: VerticalConfiguration;
  talentProfile: UpdateTalentProfileInput;
  values: TalentFormValues;
  errors?: FormikErrors<TalentFormValues>;
}

export const ProfileVerticalFieldsForm = ({
  vertical,
  verticalConfig,
  talentProfile,
  values,
  errors = {},
}: ProfileVerticalFieldsFormProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <Text variant="h4" css={{ mt: "$9" }}>
        {vertical === TalentVertical.FashionModel
          ? "Measurements & appearance"
          : `${uppercaseFirstLetter(verticalMap[vertical])} specific details`}
      </Text>
      {vertical === TalentVertical.FashionModel && (
        <FieldInfoText
          text="These are shown on the model's profile and used to search for creatives. It’s important to keep these details updated."
          fieldVariant="sectionInfo"
        />
      )}
      <Grid
        css={{ columnGap: "$9", gridColumns: 1, "@bp2": { gridColumns: 2 } }}
      >
        {verticalConfig.fields.map((field) => {
          const hidden = !!(
            talentProfile.gender &&
            field.excludedGenders?.includes(talentProfile.gender)
          );
          return (
            <React.Fragment key={field.name}>
              {field.name === "height" && (
                <Text
                  variant="h4"
                  css={{
                    mb: "$9",
                    mt: "$4",
                    gridColumn: "initial",
                    "@bp2": { gridColumn: "1 / span 2" },
                  }}
                >
                  Measurements
                </Text>
              )}
              {field.name === "complexion" && (
                <Text
                  variant="h4"
                  css={{
                    mt: "$9",
                    gridColumn: "initial",
                    "@bp2": { gridColumn: "1 / span 2" },
                  }}
                >
                  Appearance
                </Text>
              )}
              {field.inputType === InputType.MultiCheckbox
                ? renderMultiCheckboxField(
                    field,
                    values.talentProfile?.verticalData[field.name] || [],
                    (value, checked) => {
                      let currentValues: string[] = [];
                      if (values.talentProfile?.verticalData?.[field.name]) {
                        currentValues =
                          values.talentProfile.verticalData[field.name];
                      }

                      if (checked && !currentValues.includes(value)) {
                        currentValues = [...currentValues, value];
                      } else {
                        const index = currentValues.indexOf(value, 0);
                        if (index > -1) {
                          currentValues.splice(index, 1);
                        }
                      }
                      setFieldValue(
                        `talentProfile.verticalData.${field.name}`,
                        currentValues,
                      );
                    },
                    hidden,
                    parseVerticalDataErros(errors).verticalData[field.name],
                  )
                : renderField(talentProfile, field, errors, (value) => {
                    setFieldValue(
                      `talentProfile.verticalData.${field.name}`,
                      value,
                    );
                  })}
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
};
