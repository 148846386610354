import React from "react";
import { useMutation } from "@apollo/client";
import { AdditionalPaymentItem } from "./AdditionalPaymentItem";
import { AdditionalPaymentFormV2 } from "src/components/payments";
import { Avatar } from "src/ccl/blocks";
import { AssetImageType, Modal, ModalProps, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import {
  JobTalent,
  LineItem,
  MutationAddLineItemArgs,
  MutationDeleteLineItemArgs,
  OutboundPayment,
} from "src/graphql/types";
import { getInitials } from "src/utils/lang";
import {
  ADD_LINE_ITEM_MUTATION,
  DELETE_LINE_ITEM_MUTATION,
} from "src/graphql/mutations";
import { LINE_ITEM_FRAGMENT } from "src/graphql/fragments";
import { ValidationBlock } from "src/ccl/feedback";

interface AdditionalPaymentModalV2Props
  extends Pick<ModalProps, "onClose" | "isOpen"> {
  talent: JobTalent;
  featuredImage?: AssetImageType;
}
export const AdditionalPaymentModalV2: React.FC<
  AdditionalPaymentModalV2Props
> = ({ talent, featuredImage, onClose, ...modalProps }) => {
  const [addLineItem, { loading, error: createPaymentError }] = useMutation<
    { addLineItem: LineItem },
    MutationAddLineItemArgs
  >(ADD_LINE_ITEM_MUTATION, {
    update(cache: any, data: any) {
      if (!data.data) {
        return;
      }

      const payment = data.data.addLineItem;
      const newPaymentRef = cache.writeFragment({
        data: payment,
        fragment: LINE_ITEM_FRAGMENT,
      });
      cache.modify({
        id: `JobTalent:${talent.id}`,
        fields: {
          lineItems(existingPaymentRefs = []) {
            return [...existingPaymentRefs, newPaymentRef];
          },
        },
      });
      if (payment.outboundPayment?.id) {
        cache.modify({
          id: `OutboundPayment:${payment.outboundPayment?.id}`,
          fields: {
            lineItems(existingPaymentRefs = []) {
              return [...existingPaymentRefs, newPaymentRef];
            },
          },
        });
      }
    },
  });

  const [deleteLineItem, { error: deletePaymentError }] = useMutation<
    { deleteLineItem: OutboundPayment },
    MutationDeleteLineItemArgs
  >(DELETE_LINE_ITEM_MUTATION, {
    update(cache, result) {
      if (result.data) {
        cache.evict({
          id: `LineItem:${result.data.deleteLineItem.id}`,
        });
      }
    },
  });

  const lineItemsWithoutPayment =
    talent?.lineItems?.filter((item) => !item.outboundPayment) ?? [];

  const lineItems =
    talent?.outboundPayments?.reduce(
      (arr, p) => [...(p.lineItems ?? []), ...arr],
      lineItemsWithoutPayment,
    ) || [];

  return (
    <Modal onClose={onClose} width="super-wide" {...modalProps}>
      <Flex css={{ gap: "32px" }}>
        {/* Additional payment section */}
        <Flex
          css={{
            flexDirection: "column",
            flex: "2",
          }}
        >
          <Flex
            css={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              mb: "25px",
            }}
          >
            <Box>
              <Text variant="h3" css={{ mb: "6px" }}>
                {lineItems.length === 0
                  ? "Additional payment"
                  : "Additional payments"}
              </Text>
              <Text variant="modalB1">to {talent.name}</Text>
            </Box>
            {featuredImage && (
              <Avatar
                imageUrl={featuredImage?.mediaUrl}
                initials={getInitials(talent.name)}
                size="$megaAvatar"
              />
            )}
          </Flex>

          <AdditionalPaymentFormV2
            onSubmit={(values) => {
              addLineItem({
                variables: {
                  ...values,
                  amount: values.amount
                    ? parseFloat(values.amount.toString())
                    : 0.0,
                  jobTalentId: talent.id,
                },
              });
            }}
            createPaymentError={createPaymentError}
            onClose={onClose}
            loading={loading}
          />
        </Flex>
        <Flex css={{ flexDirection: "column", flex: "1" }}>
          {deletePaymentError && (
            <ValidationBlock
              variant="error"
              size="sm"
              css={{ my: "$4" }}
              title="Couldn't delete additional payment"
              body={deletePaymentError.message}
            />
          )}

          {lineItems.length > 0 && (
            <Box css={{ height: "70vh", overflowY: "scroll" }}>
              {lineItems.map((lineItem) => (
                <AdditionalPaymentItem
                  lineItem={lineItem}
                  key={lineItem.id}
                  onDelete={(paymentId) => {
                    deleteLineItem({
                      variables: {
                        jobTalentId: talent.id,
                        lineItemId: paymentId,
                      },
                    }).catch(() => {});
                  }}
                />
              ))}
            </Box>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
