import React, { useState } from "react";
import { capitalize } from "lodash";
import { Radio, RadioProps } from "./Radio";
import { Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";

interface RadioOption {
  labelText: string;
  id: string;
}

interface RadioGroupProps {
  options: RadioOption[];
  defaultChecked?: string;
  name: string;
  variant?: RadioProps["variant"];
  size?: RadioProps["size"];
  color?: keyof typeof tokens.colors;
  opacity?: string;
  context?: "mobile";
  selectedOption: string;
  setSelectedOption: (val: string) => void;
  setHoveredOption?: (val: string) => void;
}

export const RadioGroup = ({
  options,
  name,
  variant,
  size,
  color,
  context,
  selectedOption,
  setSelectedOption,
  setHoveredOption,
}: RadioGroupProps) => {
  const [hovered, setHovered] = useState("");
  return (
    <Box
      css={{
        "@bp4": {
          ">*:not(:last-child)": {
            pb: "$3",
          },
        },
      }}
    >
      {options.map((option) => (
        <Box
          key={option.id}
          onMouseOver={() => {
            if (setHoveredOption) {
              setHoveredOption(option.id);
            }
            setHovered(option.id);
          }}
          onMouseLeave={() => {
            setHovered("");
          }}
          onClick={() => {
            setHovered("");
          }}
          css={
            context === "mobile"
              ? {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "63px",
                  paddingLeft: "$7",
                  paddingRight: "$5",
                  borderRadius: "5px",
                  backgroundColor:
                    selectedOption === option.id ? "black" : "$grey1",
                }
              : {
                  opacity:
                    hovered === "" ? "1" : hovered === option.id ? "1" : "0.6",
                }
          }
        >
          <Text
            as="label"
            data-test-id={
              context === "mobile"
                ? `${option.id}-label-mobile`
                : `${option.id}-label`
            }
            htmlFor={context === "mobile" ? `${option.id}-mobile` : option.id}
            css={
              context === "mobile"
                ? { display: "flex", justifyContent: "space-between" }
                : { verticalAlign: "text-bottom", cursor: "pointer" }
            }
          >
            {context === "mobile" && (
              <Text
                variant="meta"
                css={{
                  fontWeight: "500",
                  color: selectedOption === option.id ? "white" : undefined,
                }}
              >
                {capitalize(option.labelText)}
              </Text>
            )}
            <Radio
              variant={variant}
              size={size}
              id={context === "mobile" ? `${option.id}-mobile` : option.id}
              value={option.id}
              name={name}
              checked={selectedOption === option.id}
              onChange={(e) => {
                setSelectedOption(e.target.value);
              }}
            />
            {context !== "mobile" && (
              <Text
                variant="buttonText"
                css={{
                  display: "inline",
                  verticalAlign: "top",
                  ml: "$4",
                  color: color,
                }}
              >
                {option.labelText}
              </Text>
            )}
          </Text>
        </Box>
      ))}
    </Box>
  );
};
