import { gql } from "@apollo/client";

export const FIRST_PARTY_AGENT_INVOICES_INDEX_QUERY = gql`
  query FirstPartyAgentInvoicesIndexQuery(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filters: InvoiceFilterInput
    $sortOrder: SortOrderInput
  ) {
    invoices(
      after: $after
      before: $before
      first: $first
      last: $last
      filters: $filters
      sortOrder: $sortOrder
    ) {
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          invoiceNumber
          paymentReference
          paymentMethod
          pdfUrl
          state
          invoiceDate
          dueDate
          paidAt
          trustshareProjectId
          xeroReference
          total
          currency
          poNumber
          overdue
          hasSentLatePaymentInvoice
          isLatePaymentInvoice
          job {
            id
            slug
            name
          }
        }
      }
    }
  }
`;
