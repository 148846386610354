import React, { useState } from "react";
import type * as Stitches from "@stitches/react";
import { Page, PageProps } from "./Page";
import { MegaNav } from "src/ccl/navigation";
import { Header } from "src/ccl/blocks";
import { useUserKindContext, useStoreModel } from "src/hooks";

interface FullHeaderPageProps extends PageProps {
  css?: Stitches.CSS;
  fullPageHeaderChildren?: React.ReactNode;
  children?: React.ReactNode;
}

export const FullHeaderPage = ({
  children,
  css,
  fullPageHeaderChildren,
  ...boxProps
}: FullHeaderPageProps) => {
  const [megaNavVisible, setMegaNavVisible] = useState(false);

  const currentUser = useStoreModel("currentUser");
  const userKindContext = useUserKindContext(currentUser);

  const onSignOut = () => {
    currentUser.logOut();
  };

  return (
    <Page
      {...boxProps}
      header={
        <Header
          css={css}
          fullPageHeaderChildren={fullPageHeaderChildren}
          context={userKindContext}
          avatarInitials={currentUser?.avatarInitials}
          avatarImageUrl={currentUser?.me?.featuredAsset?.mediaUrl}
          userName={currentUser?.me?.name}
          userEmail={currentUser?.me?.email}
          onSignOut={onSignOut}
          onMouseEnter={() => setMegaNavVisible(true)}
        />
      }
    >
      <MegaNav
        isVisible={megaNavVisible}
        onMouseLeave={() => setMegaNavVisible(false)}
      />
      {children}
    </Page>
  );
};
