import { gql } from "@apollo/client";

export const REMOVE_TALENT_MUTATION = gql`
  mutation RemoveTalent($jobId: ID!, $jobTalentId: ID!) {
    removeTalent(jobId: $jobId, jobTalentId: $jobTalentId) {
      id
      talent {
        id
      }
    }
  }
`;
