import React from "react";
import { AvailableIcon, Text, Icon } from "src/ccl/document";
import { Flex } from "src/ccl/layout";

export const SortOrderOption = ({
  selected,
  onClick,
  label,
  icon,
}: {
  selected: boolean;
  onClick: () => void;
  label: string;
  icon: AvailableIcon;
}) => (
  <Flex
    data-test-id="SortOrderOption"
    css={{
      py: "$3",
      px: "$4",
      mx: "$3",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: !selected ? "$grey1" : "inhert",
      },
      borderRadius: "8px",
      gap: "$4",
      backgroundColor: selected ? "black" : "unset",
      "@bp4": { width: "250px", mx: "$0" },
    }}
    onClick={onClick}
  >
    <Icon
      variant={icon}
      size={18}
      css={{ my: "auto" }}
      color={selected ? "white" : "black"}
      solid={false}
    />
    <Text css={{ color: selected ? "$white" : "$black" }}>{label}</Text>
  </Flex>
);
