import React, { ReactNode } from "react";
import { CSS } from "@stitches/react";
import { Flex } from "src/ccl/layout";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";

interface SubButtonProps {
  buttonCss?: CSS;
  secondaryCtaText?: string;
  secondaryOnClick?: () => void;
  mainCtaText?: string;
  mainOnClick?: () => void;
  ctaRounded?: boolean;
  mainCtaIcon?: AvailableIcon;
}

const SubHeaderButtons = ({
  mainCtaText,
  secondaryCtaText,
  mainCtaIcon,
  mainOnClick,
  ctaRounded,
  secondaryOnClick,
  buttonCss = {},
}: SubButtonProps) => (
  <>
    {secondaryCtaText && (
      <Button variant="secondaryCta" onClick={secondaryOnClick} css={buttonCss}>
        {secondaryCtaText}
      </Button>
    )}
    {mainCtaText && (
      <Button
        variant={ctaRounded ? "tertiaryDark" : "primaryCta"}
        onClick={mainOnClick}
        css={buttonCss}
      >
        <Flex css={{ gap: "$4", justifyContent: "center" }}>
          {mainCtaIcon && <Icon variant={mainCtaIcon} color="white" />}
          {mainCtaText}
        </Flex>
      </Button>
    )}
  </>
);

export interface SubLevelHeaderProps extends SubButtonProps {
  title: string;
  infoText?: string;
  children: ReactNode;
}

export const SubLevelHeader = ({
  title,
  infoText,
  secondaryCtaText,
  secondaryOnClick,
  mainCtaText,
  mainOnClick,
  ctaRounded = false,
  mainCtaIcon,
  children,
}: SubLevelHeaderProps) => {
  return (
    <>
      <Flex
        css={{
          alignItems: "center",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          background: "$white",
        }}
      >
        <Flex css={{ alignItems: "baseline" }}>
          <Text variant={{ "@initial": "nh4", "@md": "nh3" }}>{title}</Text>
          {infoText && (
            <Text
              variant={{ "@initial": "b3", "@md": "b2" }}
              css={{ ml: "$4" }}
            >
              {infoText}
            </Text>
          )}
        </Flex>
        <Flex
          css={{
            display: "none",
            "@sm": { display: "flex", gap: "$8" },
          }}
        >
          <SubHeaderButtons
            secondaryCtaText={secondaryCtaText}
            secondaryOnClick={secondaryOnClick}
            mainCtaIcon={mainCtaIcon}
            mainCtaText={mainCtaText}
            mainOnClick={mainOnClick}
            ctaRounded={ctaRounded}
          />
        </Flex>
      </Flex>
      {children}
      <Flex
        css={{
          flexDirection: "column-reverse",
          background: "$white",
          gap: "$8",
          position: "fixed",
          bottom: 0,
          py: "20px",
          mx: "-20px",
          width: "100%",
          zIndex: "$200",
          boxShadow: "$subtle",
          borderRadius: "$2 $2 0px 0px",
          "@sm": { display: "none" },
        }}
      >
        <SubHeaderButtons
          secondaryCtaText={secondaryCtaText}
          secondaryOnClick={secondaryOnClick}
          mainCtaIcon={mainCtaIcon}
          mainCtaText={mainCtaText}
          mainOnClick={mainOnClick}
          ctaRounded={ctaRounded}
          buttonCss={{ mx: "20px" }}
        />
      </Flex>
    </>
  );
};
