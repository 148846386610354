import React from "react";
import { TalentProfile, User } from "src/graphql/types";
import { ExternalLink, Link } from "src/ccl/navigation";
import { adminDashboardUrl, baseUrl } from "src/config";

interface TalentLinkProps {
  talent: User;
  analyticsEventName: string;
  context?: "agent" | "client";
  children?: React.ReactNode;
}

export const TalentLink = ({
  talent,
  analyticsEventName,
  children,
  context = "agent",
}: TalentLinkProps) => {
  if (context === "client") {
    return <Link to={`${baseUrl}/creatives/${talent.slug}`}>{children}</Link>;
  }

  if ((talent.profile as TalentProfile).agency.firstParty) {
    return (
      <Link
        to={`/dashboard/agent/talent/${talent.slug}`}
        css={{
          textDecoration: "none",
        }}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <ExternalLink
        to={`${adminDashboardUrl}/users/${talent.slug}`}
        eventName={analyticsEventName}
        target="_blank"
        css={{
          textDecoration: "none",
        }}
      >
        {children}
      </ExternalLink>
    );
  }
};
