import { gql } from "@apollo/client";

export const CREATE_MARKETING_ATTRIBUTION_MUTATION = gql`
  mutation CreateMarketingAttribution(
    $eventName: String!
    $utmData: UtmDataInput!
    $jobId: Int
  ) {
    createMarketingAttribution(
      eventName: $eventName
      utmData: $utmData
      jobId: $jobId
    )
  }
`;
