import React from "react";
import { CSS } from "@stitches/react";
import { LegacyProfileRadioSelect } from "./LegacyProfileRadioSelect";
import { ProfileRadioSelect } from "src/ccl/data-entry/input/ProfileRadioSelect";
import { Grid } from "src/ccl/layout";
import { useFeatures } from "src/hooks";
import { FieldOption } from "src/graphql/types";
import { mergeCss } from "src/ccl";

interface ColouredRadioSelectProps {
  options: FieldOption[];
  selectedOption: string;
  setSelectedOption: (val: string) => void;
  name: string;
  gridCss?: CSS;
}

export const ColouredRadioSelect = ({
  name,
  options,
  selectedOption,
  setSelectedOption,
  gridCss = {},
}: ColouredRadioSelectProps) => {
  const { featureEnabled } = useFeatures();
  return (
    <Grid
      css={mergeCss(
        {
          gridGap: "$4",
        },
        gridCss,
      )}
    >
      {options.map((option) => (
        <React.Fragment key={option.value}>
          {featureEnabled("tpa_updated_creative_upload") ? (
            <ProfileRadioSelect
              option={option}
              name={name}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          ) : (
            <LegacyProfileRadioSelect
              option={option}
              name={name}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};
