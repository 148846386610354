import { gql } from "@apollo/client";

export const UPDATE_SAVED_TALENT_COLLECTION_MUTATION = gql`
  mutation UpdateSavedTalentCollection($id: ID!, $name: String!) {
    updateSavedTalentCollection(id: $id, name: $name) {
      id
      slug
    }
  }
`;
