export const isExternalLink = (url: string) => {
  try {
    const link = new URL(url);
    return link.hostname !== window.location.hostname;
  } catch {
    return false;
  }
};

/**
 * Add a querystring to this existing URL.
 *
 * This is an anti-pattern! Please use URL and URLSearchParams objects directly,
 * and only serialize to string at the very end, where you actually need
 * it. This was added to fix egregious use of string concatenation for setting
 * query strings, but *it should not be used for new code.* If you don’t like
 * the URLSearchParams API (which I can understand), write a wrapper for that,
 * instead. Don’t encode, decode, reencode.
 *
 * ‘query’ can be the raw query string or it can start with a leading &, but not
 * with a question mark.
 *
 * The type is a hack to support an even bigger anti-pattern: passing
 * potentially undefined strings. Doing this is almost certainly a bug, as the
 * result will appear somewhere in e.g. a CSS url(...) clause, which should just
 * be removed entirely. If you check the result for undefined, you might as well
 * check the input for undefined.
 */
export function urlAddQuery<T extends string | (string | undefined)>(
  url: T,
  query: string,
): T {
  if (query.startsWith("?")) {
    throw new Error(
      "urlAddQuery: query string should not start with a question mark",
    );
  }
  // TODO: This should never happen. Throw an error here, and remove all call
  // sites that pass undefined to this function. Added for backwards bug
  // compatibility only.
  if (url === undefined) {
    // The typesystem is wrong
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return undefined as any;
  }
  // TODO: This should absolutely never happen. Throw an error here and remove
  // all call sites where this happens. Added for backwards bug compatibility
  // only.
  if (url === "") {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return "" as any;
  }
  const u = new URL(url);
  // Leading ampersands are ignored by the URLSearchParams constructor, as well
  // as double ampersands.
  u.search = new URLSearchParams(u.search + "&" + query).toString();
  // The typesystem is wrong
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return u.toString() as any;
}
