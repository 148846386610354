import React from "react";
import { JobAssigneeItem } from "src/components/jobAssignees";
import { Box, Grid } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { Assignee, Job } from "src/graphql/types";
import { sortedJobAssignees } from "src/utils/job";

interface JobAssigneeGridProps {
  job: Job;
  assignees: Assignee[];
}

export const JobAssigneeGrid = ({ job, assignees }: JobAssigneeGridProps) => (
  <Box
    css={{
      padding: "$4",
      overflowY: "scroll",
      maxHeight: 220,
    }}
  >
    <Text variant="mini" color="grey6" css={{ mb: "$4" }}>
      Assign owner
    </Text>

    <Grid
      css={{
        gridColumns: 1,
        gridGap: "$3",
        "@bp2": { gridColumns: 2 },
      }}
    >
      {sortedJobAssignees(assignees, job.assignedAgent?.id).map((assignee) => (
        <JobAssigneeItem
          key={assignee.id}
          assignee={assignee}
          jobId={job.id}
          currentlyAssigned={assignee.id === job.assignedAgent?.id}
        />
      ))}
    </Grid>
  </Box>
);
