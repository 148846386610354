import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import Bugsnag from "@bugsnag/js";
import * as ActionCable from "@rails/actioncable";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { typeDefs } from "./typeDefs";
import { isNewDashboardVar } from "./cache";
import { graphQLBaseUrl, webSocketBaseUrl } from "src/config";
import { store } from "src/models";

const ACCESS_TOKEN_KEY = "Contact-Access-Token";
const LAST_FRONTEND_DEPLOY_TIMESTAMP_KEY = "Contact-Last-Frontend-Deploy";

const createCable = () => {
  const cable = ActionCable.createConsumer(webSocketBaseUrl);
  cable.subscriptions.create("GraphqlChannel", {
    connected() {
      // eslint-disable-next-line no-console
      console.log("WebSocket connected");
    },
    disconnected() {
      // eslint-disable-next-line no-console
      console.log("WebSocket disconnected");
    },
    rejected() {
      console.error("Connection rejected: Unauthorized");
    },
  });

  return cable;
};

const createCableLink = () => {
  const cable = createCable();
  const link = new ActionCableLink({
    cable,
    connectionParams: {
      access_token: store.getState().currentUser.accessToken,
    },
  });

  return link;
};

const httpLink = new HttpLink({
  uri: graphQLBaseUrl,
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const accessToken = headers.get(ACCESS_TOKEN_KEY);
      if (accessToken) {
        store.getActions().currentUser.setAccessToken(accessToken);
      }

      const lastFrontendDeployTimestamp = headers.get(
        LAST_FRONTEND_DEPLOY_TIMESTAMP_KEY,
      );
      if (lastFrontendDeployTimestamp) {
        store
          .getActions()
          .contactConfig.setLastFrontendDeployTimestamp(
            lastFrontendDeployTimestamp,
          );
      }
    }

    return response;
  });
});

const authLink = new ApolloLink((operation, forward) => {
  const token = store.getState().currentUser.accessToken;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  operation.setContext(({ headers }: Record<string, any>) => ({
    headers: {
      ...headers,
      [ACCESS_TOKEN_KEY]: token,
    },
  }));
  return forward(operation);
});

const errorReportingLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.errors) {
      const error = response.errors[0];
      Bugsnag.notify(new Error(error.message), (event) => {
        event.severity = "error";
        event.unhandled = true;
        event.groupingHash = `GraphQL Error Response: ${error.message}`;
        if (error.path) {
          event.addMetadata("gql", { path: error.path[0] });
        }
      });
    }

    return response;
  });
});

const hasSubscriptionOperation = ({ query: { definitions } }: any) => {
  return definitions.some(
    ({ kind, operation }: any) =>
      kind === "OperationDefinition" && operation === "subscription",
  );
};

export const graphQLClient = new ApolloClient({
  link: from([authLink, errorReportingLink, afterwareLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          jobs: relayStylePagination(["filters", "sortOrder"]),
          activityStream: relayStylePagination(),
        },
      },
      BookerProfile: {
        fields: {
          savedTalent: relayStylePagination(["sortOrder"]),
          savedTalentCollections: relayStylePagination(["sortOrder"]),
          activityStream: relayStylePagination(),
        },
      },
      SavedTalentCollection: {
        fields: {
          members: relayStylePagination(["sortOrder"]),
        },
      },
      Query: {
        queryType: true,
        fields: {
          talentSearch: relayStylePagination(),
          verticalTalentSearch: relayStylePagination([
            "group",
            "isContactOpen",
            "isContactSelect",
          ]),
          bookers: relayStylePagination([
            "sortField, sortDirection",
            "companyId",
          ]),
          invoices: relayStylePagination(),
          thirdPartyAgencies: relayStylePagination(),
          bookingCompanies: relayStylePagination(),
          isNewDashboard: {
            read() {
              return isNewDashboardVar();
            },
          },
        },
      },
      JobTalent: {
        fields: {
          outboundPayments: {
            merge: false,
          },
        },
      },
      Agency: {
        fields: {
          agents: relayStylePagination(["sortOrder"]),
          agencyReviews: relayStylePagination(["sortOrder"]),
          packages: relayStylePagination(["sortOrder"]),
          commissionRateHistory: relayStylePagination(["sortOrder"]),
          jobs: relayStylePagination(),
        },
      },
    },
  }),
  typeDefs,
});

export const graphQLClientWithCableLink = () => {
  const splitLink = ApolloLink.split(
    hasSubscriptionOperation,
    createCableLink(),
    from([authLink, errorReportingLink, afterwareLink, httpLink]),
  );

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          fields: {
            jobs: relayStylePagination(["filters", "sortOrder"]),
            activityStream: relayStylePagination(),
          },
        },
        BookerProfile: {
          fields: {
            savedTalent: relayStylePagination(["sortOrder"]),
            savedTalentCollections: relayStylePagination(["sortOrder"]),
            activityStream: relayStylePagination(),
          },
        },
        SavedTalentCollection: {
          fields: {
            members: relayStylePagination(["sortOrder"]),
          },
        },
        Query: {
          queryType: true,
          fields: {
            talentSearch: relayStylePagination(),
            verticalTalentSearch: relayStylePagination([
              "group",
              "isContactOpen",
              "isContactSelect",
            ]),
            bookers: relayStylePagination([
              "sortField, sortDirection",
              "companyId",
            ]),
            invoices: relayStylePagination(),
            thirdPartyAgencies: relayStylePagination(),
            bookingCompanies: relayStylePagination(),
            isNewDashboard: {
              read() {
                return isNewDashboardVar();
              },
            },
          },
        },
        JobTalent: {
          fields: {
            outboundPayments: {
              merge: false,
            },
          },
        },
        Agency: {
          fields: {
            agents: relayStylePagination(["sortOrder"]),
            agencyReviews: relayStylePagination(["sortOrder"]),
            packages: relayStylePagination(["sortOrder"]),
            commissionRateHistory: relayStylePagination(["sortOrder"]),
            jobs: relayStylePagination(),
          },
        },
      },
    }),
    typeDefs,
  });
};
