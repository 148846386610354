import { BookerDashboardJobsTalent } from "./BookerDashboardJobsTalent";
import { JobDetails } from "./JobDetails";
import { JobTitle } from "./JobTitle";
import { ViewInvoiceButton } from "./ViewInvoiceButton";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { Job, JobState, JobTalent } from "src/graphql/types";
import { BookerJobStateTheme } from "src/graphql/colors";
import { sendToAmplitude } from "src/utils/analytics";
import { jobCardTitleForBooker, jobCardTitleForAgent } from "src/utils/job";
import { Link } from "src/ccl/navigation";
import { Pill } from "src/ccl/blocks";
import { verticalIconBackgroundMap, verticalIconMap } from "src/utils/user";

export const JobsIndexJobCard = ({
  job,
  dashboard,
  isFpa,
}: {
  job: Job;
  dashboard: "agent" | "client";
  isFpa?: boolean;
}) => {
  const [, jobStateColorSecondary, jobStateColorTertiary] =
    BookerJobStateTheme[job.state];

  const jobTalent = job?.talent as JobTalent[];

  return (
    <Link
      to={`/dashboard/${isFpa ? "agent" : dashboard}/jobs/${job.slug}`}
      data-test-id="JobCard"
      onClick={() =>
        sendToAmplitude(
          `${
            isFpa ? "agent" : dashboard
          } jobs page - clicks latest jobs job card`,
        )
      }
      css={{
        textDecoration: "none",
      }}
    >
      <Flex
        css={{
          border: "1.5px solid $grey2",
          borderRadius: "12px",
          padding: "26px",
          flexDirection: "column",
          "&:hover": {
            border: "1px solid $grey6",
            boxShadow: "$subtle",
          },
        }}
      >
        <Flex
          css={{
            justifyContent: "space-between",
          }}
        >
          <JobTitle job={job} />
          <Box
            css={{
              display: "none",
              "@sm": {
                display: "block",
              },
            }}
          >
            <Flex css={{ gap: "6px", flexWrap: "wrap" }}>
              {job.jobVerticals.map((jobVerticals, index) => (
                <Flex
                  key={index}
                  css={{
                    width: "30px",
                    height: "30px",
                    background: `$${
                      verticalIconBackgroundMap[jobVerticals.vertical]
                    }`,
                    borderRadius: "$round",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    variant={verticalIconMap[jobVerticals.vertical]}
                    size={18}
                  />
                </Flex>
              ))}
            </Flex>
          </Box>
        </Flex>
        <Flex css={{ gap: "$5" }}>
          <JobDetails job={job} color="grey4" />
        </Flex>
        <Flex
          css={{
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Flex css={{ justifyContent: "space-between" }}>
            {job.talent && job.talent.length > 0 && (
              <Flex
                css={{ alignItems: "center", gap: "8px", flexWrap: "wrap" }}
              >
                <BookerDashboardJobsTalent
                  talent={jobTalent}
                  job={job}
                  size={38}
                  overlap={12}
                  showLeftover={true}
                  reverseOverlap={true}
                />
                <Text variant="b3" color="grey5">
                  {job.talent.length} Shortlisted
                </Text>
              </Flex>
            )}
            <Pill
              variant="roundedTertiary"
              css={{
                display: "none",
                width: "max-content",
                backgroundColor: jobStateColorSecondary,
                margin: 0,
                "@sm": {
                  display: "block",
                },
              }}
            >
              <Text
                variant="b3Bold"
                css={{ color: jobStateColorTertiary, padding: "8px 12px" }}
              >
                {dashboard === "client"
                  ? jobCardTitleForBooker(job)
                  : jobCardTitleForAgent(job)}
              </Text>
            </Pill>
          </Flex>
          <Flex
            css={{
              justifyContent: "space-between",
              alignItems: "center",
              gap: "8px",
              flexWrap: "wrap",
              "@sm": {
                display: "none",
              },
            }}
          >
            <Pill
              variant="roundedTertiary"
              css={{
                width: "max-content",
                backgroundColor: jobStateColorSecondary,
                margin: 0,
              }}
            >
              <Text
                variant="b3Bold"
                css={{ color: jobStateColorTertiary, padding: "8px 12px" }}
              >
                {dashboard === "client"
                  ? jobCardTitleForBooker(job)
                  : jobCardTitleForAgent(job)}
              </Text>
            </Pill>
            <Flex css={{ gap: "6px", flexWrap: "wrap" }}>
              {job.jobVerticals.map((jobVerticals, index) => (
                <Flex
                  key={index}
                  css={{
                    width: "30px",
                    height: "30px",
                    background: `$${
                      verticalIconBackgroundMap[jobVerticals.vertical]
                    }`,
                    borderRadius: "$round",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    variant={verticalIconMap[jobVerticals.vertical]}
                    size={18}
                  />
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
        {dashboard === "client" &&
          job.state === JobState.Completed &&
          job.totalInvoiceCount > 0 &&
          job.paymentViaExternalPlatform && (
            <Box
              css={{
                paddingTop: "20px",
                ml: 0,
                "@md": {
                  ml: "auto",
                },
              }}
            >
              <ViewInvoiceButton
                unpaidInvoiceCount={job.unpaidInvoiceCount}
                url={`/dashboard/client/jobs/${job.slug}/invoices`}
                buttonCss={{
                  width: "100%",
                  textAlign: "center",
                }}
              />
            </Box>
          )}
      </Flex>
    </Link>
  );
};
