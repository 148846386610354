import { gql } from "@apollo/client";

export const SAVE_TALENT_MUTATION = gql`
  mutation SaveTalent($talentId: ID!) {
    saveTalent(talentId: $talentId) {
      id
      talent {
        talentProfile {
          vertical
        }
      }
    }
  }
`;
