import React, { useState } from "react";
import { styled } from "src/ccl";
import { EnhancedInput, InputProps } from "src/ccl/data-entry";
import { Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { OutsideClickHandler } from "src/ccl/util/OutsideClickHandler";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";
import { formatDate } from "src/utils/dates";

const timeFormat = "HH:mmaaa";
const timePattern = "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$";
const defaultStartTime = "09:00am";
const defaultEndTime = "17:00pm";

const Caret = styled(Box, {
  width: "0",
  height: "0",
  position: "absolute",
  right: "20%",
  top: -6,
  color: "$white",
  borderLeftWidth: "6px",
  borderLeftColor: "transparent",
  borderLeftStyle: "solid",
  borderRightWidth: "6px",
  borderRightColor: "transparent",
  borderRightStyle: "solid",
  borderBottomStyle: "solid",
  borderBottomWidth: "6px",
});

export interface DateTimeInputProps extends Omit<InputProps, "type"> {
  date?: Date;
}

const TimeInput = ({
  defaultValue,
  onChange,
}: Pick<InputProps, "defaultValue" | "onChange">) => (
  <EnhancedInput
    variant="rebrand"
    type="text"
    defaultValue={defaultValue}
    onChange={onChange}
    maxLength={5}
    pattern={timePattern}
    placeholder="hh:mm"
    css={{
      minWidth: "137px",
      textAlign: "center",
      "&:invalid": {
        borderColor: "$red",
      },
    }}
  />
);

export const DateTimeInput = ({ date, ...props }: DateTimeInputProps) => {
  const [startTime, setStartTime] = useState(
    date ? formatDate(date, timeFormat) : defaultStartTime,
  );
  const [startTimeValue, setStartTimeValue] = useDebouncedInputValue(
    startTime,
    setStartTime,
  );
  const [endTime, setEndTime] = useState(
    date ? formatDate(date, timeFormat) : defaultEndTime,
  );
  const [endTimeValue, setEndTimeValue] = useDebouncedInputValue(
    endTime,
    setEndTime,
  );
  const [isActive, setIsActive] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleChange = (value: string, callback: (s: string) => void) => {
    if (!value.match(new RegExp(timePattern))) return;

    const [hours, minutes] = value.split(":");
    const newTime = new Date(date || Date.now());
    newTime.setHours(parseInt(hours) | 0);
    newTime.setMinutes(parseInt(minutes) | 0);

    setTouched(true);
    callback(formatDate(newTime, timeFormat));
  };
  return (
    <OutsideClickHandler onOutsideClick={() => setIsActive(false)}>
      <Box
        css={{
          position: "relative",
          width: "325px",
          cursor: date ? "pointer" : "default",
        }}
        onMouseEnter={() => setIsActive(!!date)}
        onMouseLeave={() => setIsActive(false)}
      >
        <EnhancedInput
          type="text"
          variant="rebrand"
          value={date ? formatDate(date, "dd/LL/yyyy ") : "dd/mm/yyyy"}
          css={{
            fontSize: "$12",
            fontWeight: "$bold",
            cursor: date ? "pointer" : "default",
            caretColor: "transparent",
            border: !touched ? "1px solid $grey3" : undefined,
          }}
          inputSuffix={
            <Flex
              css={{
                alignItems: "center",
              }}
            >
              <Text color="grey6">{`${startTime} - ${endTime}`}</Text>

              <Icon variant="time" size={14} color="grey6" css={{ ml: "$3" }} />
            </Flex>
          }
          {...props}
        />

        {isActive && (
          <Flex
            css={{
              alignItems: "center",
              p: "$5",
              boxShadow: "$dropBlack",
              mt: "7px",
              position: "relative",
              borderRadius: "8px",
              width: "fit-content",
            }}
          >
            <Caret />
            <TimeInput
              defaultValue={startTimeValue.substring(
                0,
                startTimeValue.length - 2,
              )}
              onChange={(e) => {
                const value = e.target.value;
                if (value <= endTimeValue) {
                  handleChange(value, setStartTimeValue);
                }
              }}
            />
            <Text css={{ mx: "$5" }}>to</Text>
            <TimeInput
              defaultValue={endTimeValue.substring(
                0,
                startTimeValue.length - 2,
              )}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= startTimeValue) {
                  handleChange(value, setEndTimeValue);
                }
              }}
            />
          </Flex>
        )}
      </Box>
    </OutsideClickHandler>
  );
};
