import { gql } from "@apollo/client";
import {
  JOB_DETAILS_FRAGMENT,
  TALENT_DETAILS_FRAGMENT,
} from "src/graphql/fragments";

export const TPA_DASHBOARD_SHOW_JOB_QUERY = gql`
  query TPADashboardShowJob($jobId: ID!) {
    job(id: $jobId) {
      ...JobDetails
      jobVerticals {
        budget
        vertical
        jobLength
        talentRequired
        timeslots {
          to
          from
        }
      }
      package {
        name
      }
      state
      commissionForUser
      additionalFeesAmount
      fullAddress
      tpaBloomEligibility
      bloomActivatedAtForCurrentUser
      tpaBookingFee
      tpaAdditionalFeesCommission
      tpaTalentCommission
      talent {
        ...TalentDetails
        talent {
          id
          name
          slug
          email
          featuredAsset {
            mediaUrl
          }
          profile {
            ... on TalentProfile {
              primaryLocation {
                name
              }
              vertical
            }
          }
        }
      }
      notes {
        body
        createdAt
        id
        user {
          email
          firstName
          id
          lastName
          name
        }
      }
    }
  }
  ${JOB_DETAILS_FRAGMENT}
  ${TALENT_DETAILS_FRAGMENT}
`;
