import React from "react";
import { EmptyPaymentState } from "./EmptyPaymentState";
import { JobDetails } from "./JobDetails";
import { PaymentBreakdown } from "./PaymentBreakdown";
import { RemittanceDownload } from "./RemittanceDownload";
import { Tooltip } from "src/ccl/feedback";
import { Box, Flex } from "src/ccl/layout";
import { OutboundPayment, OutboundPaymentState } from "src/graphql/types";

interface PaymentsListProps {
  payments: OutboundPayment[];
  emptyStateTitle?: React.ReactNode;
  fullStateTitle?: React.ReactNode;
  showPaymentFailedContactLink?: boolean;
}

export const PaymentsList = ({
  payments,
  emptyStateTitle = null,
  fullStateTitle = null,
  showPaymentFailedContactLink = true,
}: PaymentsListProps) => {
  if (payments.length === 0) {
    return <EmptyPaymentState title={emptyStateTitle} />;
  }

  return (
    <>
      <Box css={{ mb: "$10" }}>{fullStateTitle}</Box>

      <Box data-test-id="PaymentList">
        {payments.map((payment) => {
          const job = payment.jobTalent?.job;
          const isPending = payment.state === OutboundPaymentState.Pending;

          const mainColor = isPending ? "grey6" : "black";

          return (
            <Flex
              key={payment.id}
              css={{ borderTop: "1px solid $grey2", py: "$5" }}
              data-test-id={`PaymentList-${payment.id}`}
            >
              <Box
                css={{
                  display: "none",
                  width: "6%",
                  textAlign: "center",
                  "@bp2": { display: "block" },
                }}
              >
                {payment.remittanceLetterUrl && (
                  <Tooltip content="Download remittance letter">
                    <RemittanceDownload url={payment.remittanceLetterUrl} />
                  </Tooltip>
                )}
              </Box>

              {job && <JobDetails job={job} color={mainColor} />}

              <Box css={{ textAlign: "right" }}>
                <PaymentBreakdown
                  payment={payment}
                  color={mainColor}
                  showPaymentFailedContactLink={showPaymentFailedContactLink}
                />
              </Box>
            </Flex>
          );
        })}
      </Box>
    </>
  );
};
