import { gql } from "@apollo/client";
import { PACKAGE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const UPDATE_PACKAGE_MUTATION = gql`
  mutation UpdatePackage(
    $packageId: ID!
    $name: String
    $bookerName: String
    $bookerEmail: String
    $bookerCompany: String
    $kind: JobKind
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $location: String
    $brand: String
    $description: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $country: String
    $postcode: String
    $virtual: Boolean
    $usage: String
    $packageTalent: [PackageTalentInput!]
    $deckFiles: [FileUploadInput!]
    $packageVerticals: [CreatePackageVerticalInput!]
    $isTemplate: Boolean
  ) {
    updatePackage(
      packageId: $packageId
      name: $name
      kind: $kind
      bookerName: $bookerName
      bookerEmail: $bookerEmail
      bookerCompany: $bookerCompany
      startDate: $startDate
      endDate: $endDate
      location: $location
      brand: $brand
      description: $description
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      country: $country
      postcode: $postcode
      virtual: $virtual
      usage: $usage
      packageTalent: $packageTalent
      deckFiles: $deckFiles
      packageVerticals: $packageVerticals
      isTemplate: $isTemplate
    ) {
      ...PackageDetails
    }
  }
  ${PACKAGE_DETAILS_FRAGMENT}
`;
