import React, { useState, Dispatch } from "react";
import type * as Stitches from "@stitches/react";
import { MobileTalentSelectionSummary } from "./MobileTalentSelectionSummary";
import { SelectionSummaryCreativeList } from "./SelectionSummaryCreativeList";
import { FullSelectionModal } from ".";
import {
  Filter,
  FilterContext,
  QueryVerticalTalentSearchArgs,
} from "src/graphql/types";
import {
  SearchParamsAction,
  SearchParamsActionKind,
} from "src/hooks/useVerticalTalentSearchParams";
import { FilterModal } from "src/components/filtering";
import { pluralize } from "src/utils/lang";
import { TalentVertical, User } from "src/graphql/generated/types";
import { mergeCss, styled } from "src/ccl/stitches";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { Icon, InfoBanner, Text } from "src/ccl/document";
import { ShareShortlistModal } from "src/components";
import { getTalentVerticals } from "src/utils/user";

const Wrapper = styled(Box, {
  padding: "$4",
  mb: "$5",
  boxShadow: "$focus",
  borderWidth: 2,
  borderColor: "$black",
  borderStyle: "solid",
  backgroundColor: "$white",
  flexGrow: 1,

  variants: {
    variant: {
      highlighted: {
        borderColor: "$red",
      },
      additionalInfo: {
        backgroundColor: "$grey1",
        borderLeft: "none",
        display: "none",
        "@bp2": { display: "block" },
      },
    },
  },
});

const NoTalentShortlisted = ({
  highlighted,
  maxShortlistCount,
  flowContext,
}: {
  highlighted: boolean;
  maxShortlistCount?: number;
  flowContext?: "createJob" | "editJob";
}) => (
  <Flex css={{ alignItems: "center" }}>
    <Icon
      variant="checklist"
      size={40}
      css={{
        display: flowContext === "createJob" ? "none" : "block",
        mr: "$2",
        "@bp4": { display: "block" },
      }}
    />

    <Flex
      css={{
        ml: "$3",
        flexDirection: "column",
        "@bp4": { flexDirection: "row" },
      }}
    >
      <Text color={highlighted ? "red" : "black"} css={{ fontWeight: "$bold" }}>
        {maxShortlistCount
          ? `Shortlist up to ${maxShortlistCount} creatives`
          : "You must shortlist at least 1 creative"}
      </Text>
      <Text
        variant="meta"
        css={{
          color: flowContext === "createJob" ? "$grey6" : "$red",
          "@bp4": { pl: "$6", alignSelf: "center", color: "$red" },
        }}
      >
        {maxShortlistCount
          ? "Then pick 1 to finalise the job with later"
          : "Please don't shortlist disproportionately. If you require 2 creatives, we advise shortlisting 3 or 4."}
      </Text>
    </Flex>
  </Flex>
);

export type HelpContext = "aboutShortlisting" | "remainingTalentCount";

export interface TalentSelectionSummaryProps {
  talent: User[];
  isRemovable: (user: User) => boolean;
  onRemove: (user: User) => void;
  highlighted?: boolean;
  showShare?: boolean;
  vertical?: TalentVertical;
  remainingShortlistableTalentCount?: number;
  maxShortlistCount?: number;
  selectionCss?: Stitches.CSS;
  context?: "booker" | "agent";
  flowContext?: "createJob" | "editJob";
  goBackTo?: string | (() => void | Promise<void>);
  onBack?: () => void;
  filters?: Filter[];
  setSearchParams?: Dispatch<SearchParamsAction>;
  searchParams?: QueryVerticalTalentSearchArgs;
  filterContext?: FilterContext;
  expanded?: boolean;
  talentLoading?: boolean;
  totalCount?: number;
  numberOfActiveFilters?: number;
  onProfile?: boolean;
  onContinue?: () => void;
}

export const TalentSelectionSummary = ({
  talent,
  vertical,
  highlighted = false,
  remainingShortlistableTalentCount = 0,
  isRemovable,
  maxShortlistCount,
  onRemove,
  onBack,
  goBackTo,
  selectionCss = {},
  context = "agent",
  flowContext,
  filters,
  setSearchParams,
  searchParams,
  filterContext,
  expanded,
  talentLoading,
  totalCount,
  numberOfActiveFilters = 0,
  onProfile = false,
  onContinue,
}: TalentSelectionSummaryProps) => {
  const [fullSelectionModalIsOpen, setFullSelectionModalIsOpen] =
    useState(false);
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
  const [showShareShortlistModal, setShowShareShortlistModal] = useState(false);

  const talentUsersToShow = window.matchMedia("(min-width: 640px)").matches
    ? 3
    : 1;
  const slicedTalentUsers = talent.slice(-talentUsersToShow);

  const switchModals = () => {
    setFullSelectionModalIsOpen(false);
    setShowShareShortlistModal(true);
  };

  const cancelFilters = () => {
    if (setSearchParams) {
      setSearchParams({
        kind: SearchParamsActionKind.ResetAll,
        payload: null,
      });
      setFilterModalIsOpen(false);
    }
  };

  return (
    <>
      <MobileTalentSelectionSummary
        css={{
          marginTop: "$7",
          mx: "$5",
          position: onProfile ? "unset" : "sticky",
          bottom: 22,
          top: 75,
          zIndex: "$400",
          "@bp2": {
            top: flowContext === "editJob" ? 0 : 85,
          },
          "@bp4": {
            top: flowContext === "editJob" ? 0 : 155,
          },
        }}
        onBack={onBack}
        goBackTo={goBackTo}
        talent={talent}
        displayFilters
        onProfile={onProfile}
        openShortlistModal={() => {
          setFullSelectionModalIsOpen(true);
        }}
        openFilterModal={() => {
          setFilterModalIsOpen(true);
        }}
        numberOfActiveFilters={numberOfActiveFilters}
        onContinue={onContinue}
      />
      <Box
        data-test-id="TalentSelectionSummary"
        css={mergeCss(
          {
            zIndex: "$400",
            px: "$5",
            alignItems: "center",
            "@bp4": {
              position: onProfile ? "unset" : "sticky",
              top: flowContext === "editJob" ? 0 : undefined,
              bottom: 20,
            },
          },
          selectionCss,
        )}
      >
        <ShareShortlistModal
          talent={talent}
          isOpen={showShareShortlistModal}
          onClose={() => setShowShareShortlistModal(false)}
          talentVerticals={getTalentVerticals(talent)}
        />
        {filters &&
          setSearchParams &&
          searchParams &&
          filterContext &&
          vertical && (
            <FilterModal
              filters={filters}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              expanded={expanded}
              vertical={vertical}
              talentLoading={talentLoading}
              totalCount={totalCount}
              isOpen={filterModalIsOpen}
              onClose={() => setFilterModalIsOpen(false)}
              onCancel={cancelFilters}
            />
          )}
        <Flex
          css={{ flexDirection: "column", "@bp4": { flexDirection: "row" } }}
        >
          <Wrapper
            variant={highlighted ? "highlighted" : undefined}
            css={{
              width: "100%",
              backgroundColor:
                flowContext === "createJob" ? "$grey1" : "$white",
              border: flowContext === "createJob" ? "none" : "2px $black solid",
              display: talent.length > 0 ? "none" : "block",
              "@bp4": {
                display: "block",
                backgroundColor: "$white",
                border: "1px $grey3 solid",
                width: "100%",
                mt: "$5",
              },
            }}
          >
            <FullSelectionModal
              isOpen={fullSelectionModalIsOpen}
              onClose={() => setFullSelectionModalIsOpen(false)}
              talent={talent}
              isRemovable={isRemovable}
              onRemove={onRemove}
              showShareShortlistButton={context === "booker"}
              buttonVariant={context === "booker" ? "secondary" : "primary"}
              flowContext={flowContext}
              setShowShareShortlistModal={switchModals}
            />
            {talent.length > 0 ? (
              <Flex
                css={{
                  ml: "$2",
                  justifyContent: "space-between",
                }}
              >
                <Flex>
                  <Flex
                    css={{
                      borderRight: "2px solid $grey3",
                      pr: "$0",
                    }}
                  >
                    <Text
                      variant="meta"
                      weight="bold"
                      css={{ "@bp2": { display: "none" } }}
                    >
                      Shortlist ({talent.length})
                    </Text>

                    <Text
                      variant="meta"
                      weight="bold"
                      css={{
                        display: "none",
                        alignSelf: "center",
                        "@bp2": { display: "initial" },
                      }}
                    >
                      You&apos;ve shortlisted{" "}
                      {pluralize(talent.length, "creative")}
                    </Text>

                    {maxShortlistCount &&
                      remainingShortlistableTalentCount <= 0 && (
                        <Flex css={{ ml: "auto", alignItems: "center" }}>
                          <InfoBanner
                            color="grey6"
                            variant="tightCentered"
                            withIcon={false}
                          >
                            <Text
                              css={{
                                color: "red",
                                fontSize: "$14",
                                fontWeight: "$bold",
                                display: "none",
                                "@bp3": {
                                  display: "block",
                                },
                              }}
                            >
                              {`Maximum added: ${maxShortlistCount}`}
                            </Text>
                          </InfoBanner>
                        </Flex>
                      )}
                    <Button
                      variant="link"
                      css={{ ml: "$4", mr: "$4", color: "$grey6" }}
                      onClick={() => {
                        setFullSelectionModalIsOpen(true);
                      }}
                    >
                      View
                    </Button>
                  </Flex>

                  <SelectionSummaryCreativeList
                    users={slicedTalentUsers}
                    onRemove={onRemove}
                    isRemovable={isRemovable}
                  />
                </Flex>
                {onContinue && (
                  <Flex>
                    <Button variant="primary" onClick={onContinue}>
                      Continue
                    </Button>
                  </Flex>
                )}
              </Flex>
            ) : (
              <NoTalentShortlisted
                highlighted={highlighted}
                maxShortlistCount={maxShortlistCount}
                flowContext={flowContext}
              />
            )}
          </Wrapper>
        </Flex>
      </Box>
    </>
  );
};
