import { gql } from "@apollo/client";

export const JOB_TALENT_REJECTION_REASONS_QUERY = gql`
  query JobTalentRejectionReasons {
    jobTalentRejectionReasons {
      key
      value
    }
  }
`;
