import React from "react";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches";

interface StepSummaryProps {
  steps: string[];
  currentStep: number;
  slimline?: boolean;
}

const Step = styled("div", {});

export const StepSummary = ({
  steps,
  currentStep,
  slimline = false,
}: StepSummaryProps) => (
  <Box>
    {!slimline && (
      <Flex css={{ mb: "$4", alignItems: "center" }}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Text
              variant="meta"
              color={index === currentStep ? "black" : "grey6"}
            >
              {index + 1}. {step}
            </Text>
            {index + 1 < steps.length && (
              <Icon
                variant="arrowRight"
                size={8}
                color={index + 1 === currentStep ? "black" : "grey6"}
                css={{ mx: "$5" }}
              />
            )}
          </React.Fragment>
        ))}
      </Flex>
    )}
    <Flex css={{ alignItems: "center" }}>
      <Grid
        css={{
          gridColumns: steps.length,
          border: "1px solid $black",
          width: slimline ? "14px" : "100%",
          height: slimline ? "14px" : "4px",
          mr: slimline ? "$3" : "initial",
          display: slimline ? "none" : "grid",
          "@bp2": {
            display: "grid",
          },
        }}
      >
        {steps.map((_step, index) => (
          <Step
            key={`step-${index}`}
            css={{
              height: "100%",
              backgroundColor: index <= currentStep ? "$white" : "$black",
            }}
          />
        ))}
      </Grid>
      <Text
        variant="meta"
        css={{
          color: "$grey6",
          mr: "$4",
          whiteSpace: "nowrap",
          "@bp2": { display: slimline ? "block" : "none", color: "initial" },
        }}
      >
        Step {currentStep + 1} of {steps.length}
      </Text>
    </Flex>
  </Box>
);
