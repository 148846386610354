import { gql } from "@apollo/client";
import { SHORTLIST_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const BOOKER_DASHBOARD_JOB_SHORTLIST_QUERY = gql`
  query BookerDashboardJobShortlist($jobId: ID!) {
    job(id: $jobId) {
      ...ShortlistDetails
    }
  }
  ${SHORTLIST_DETAILS_FRAGMENT}
`;
