import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import { StoreModel } from "src/models";

export interface ContactConfigModel {
  lastRefreshTimestamp: number | null;
  setLastRefreshTimestamp: Action<ContactConfigModel, number>;
  lastFrontendDeployTimestamp: number | null;
  setLastFrontendDeployTimestamp: Action<ContactConfigModel, string>;
  onAppMount: Thunk<ContactConfigModel, void, void, StoreModel>;
  shouldPromptToRefresh: Computed<ContactConfigModel, boolean>;
}

export const contactConfigModel: ContactConfigModel = {
  lastRefreshTimestamp: null,
  setLastRefreshTimestamp: action((state, payload) => {
    state.lastRefreshTimestamp = payload;
  }),
  lastFrontendDeployTimestamp: null,
  setLastFrontendDeployTimestamp: action((state, payload) => {
    state.lastFrontendDeployTimestamp = parseInt(payload);
  }),
  onAppMount: thunk(async (actions) => {
    actions.setLastRefreshTimestamp(Math.round(Date.now() / 1000));
  }),
  shouldPromptToRefresh: computed((state) => {
    if (state.lastFrontendDeployTimestamp && state.lastRefreshTimestamp) {
      return state.lastFrontendDeployTimestamp > state.lastRefreshTimestamp;
    }
    return false;
  }),
};
