import React from "react";
import { Icon, LegacyLogo, Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { Link, ExternalLink, LinkButton } from "src/ccl/navigation";
import { styled } from "src/ccl/stitches";
import { UserKindContext, useStoreModel } from "src/hooks";
import { useLocationAwareURL } from "src/hooks/useLocationAwareURL";
import { sendToAmplitude } from "src/utils/analytics";
import { zendeskAvailable, zendeskOpen } from "src/utils/zendesk";

const Backdrop = styled("div", {
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: "$501",
  backdropFilter: "blur(5px)",
  transition: "400ms ease all",
  opacity: 0,
  visibility: "hidden",

  variants: {
    state: {
      open: {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
});

const Wrapper = styled("div", {
  position: "fixed",
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "$black",
  zIndex: "$502",
  boxShadow: "0 0 40px rgba(0, 0, 0, 0.5)",
  minWidth: "275px",
  width: "80vw",
  boxSizing: "border-box",
  overflowY: "auto",
  transition: "400ms ease all",
  transform: "translate(calc(100% + 40px), 0)",
  visibility: "hidden",

  variants: {
    state: {
      open: {
        transform: "translate(0, 0)",
        visibility: "visible",
      },
    },
  },
});

const Fade = styled("div", {
  opacity: 0,
  transition: "400ms ease all",

  variants: {
    state: {
      open: {
        opacity: 1,
      },
    },
  },
});

const BigLinkContainer = styled("nav", { marginTop: "$10" });

const primaryLinkStyles = {
  variant: "$body2",
  textDecoration: "none",
  color: "$white",
  display: "block",
  marginBottom: "$6",
  "&:hover": {
    color: "$grey3",
  },

  "&:last-child": {
    mb: 0,
  },
};

const PrimaryLink = styled(Link, primaryLinkStyles);
const PrimaryExternalLink = styled(ExternalLink, primaryLinkStyles);

const secondaryLinkStyles = {
  fontWeight: "$bold",
};

const SecondaryLink = styled(PrimaryLink, secondaryLinkStyles);

const CloseLink = styled("button", {
  fontSize: "$12",
  fontFamily: "Shapiro",
  lineHeight: "$18",
  fontWeight: "$bold",
  textTransform: "uppercase",
  color: "$white",
  backgroundColor: "$black",
  border: "none",
  textDecoration: "$none",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "$0",
  right: "$0",
  padding: "$4",
  cursor: "pointer",
});

const CloseLinkText = styled("span", { marginLeft: "$3" });

const Divider = styled("hr", {
  height: "1px",
  border: "none",
  mx: "$9",
  backgroundColor: "$grey6",
});

const UserDetail = styled("div", {
  fontSize: "$14",
  color: "$grey6",
  mb: "$3",
});

const StyledExternalLink = styled(ExternalLink, {
  textDecoration: "none",
  display: "flex",
});
const supportUrl = useLocationAwareURL("https://support.{{HOST}}/hc/en-gb");

export interface LegacyNavigationPaneProps {
  userName?: string;
  userCompany?: string;
  userEmail?: string;
  open?: boolean;
  context: UserKindContext;
  onClose?: () => void;
  onSignOut?: () => void;
}

export const LegacyNavigationPane = ({
  open = false,
  onClose = () => {},
  onSignOut,
  userName,
  userCompany,
  userEmail,
  context,
}: LegacyNavigationPaneProps) => {
  const { featureEnabled } = useStoreModel("features");
  return (
    <Box>
      <Backdrop state={open ? "open" : undefined} onClick={onClose} />
      <Wrapper
        state={open ? "open" : undefined}
        data-test-id="LegacyMobileNavPane"
      >
        <Fade state={open ? "open" : undefined}>
          <Box css={{ px: "$9", py: "$5" }}>
            <CloseLink onClick={onClose}>
              <Icon variant="closeCross" color="white" size={16} />
              <CloseLinkText>Close</CloseLinkText>
            </CloseLink>
            <LegacyLogo variant="bigMark" color="white" />
            <BigLinkContainer>
              {(context === "signedOut" || context === "client") && (
                <LinkButton
                  variant="header"
                  to="/jobs/new"
                  css={{
                    justifyContent: "space-between",
                    maxWidth: "160px",
                    mb: "$8",
                  }}
                >
                  Book Creatives
                  <Icon variant="chevronRight" size={12} />
                </LinkButton>
              )}
              <PrimaryLink to="/for/creatives">
                Contact for creatives
              </PrimaryLink>
              <PrimaryLink to="/for/clients">Contact for clients</PrimaryLink>
              <PrimaryLink to="/for/agencies">Contact for agencies</PrimaryLink>
              <PrimaryLink to="/backstage">Backstage blog</PrimaryLink>
              <PrimaryLink to="/creative-paths">Podcast</PrimaryLink>
              <PrimaryLink to="/about">About Contact</PrimaryLink>
              <PrimaryLink to="/careers">Careers</PrimaryLink>
            </BigLinkContainer>
          </Box>

          <Divider />

          <Box css={{ px: "$9", py: "$5" }}>
            {context !== "signedOut" ? (
              <>
                <Box css={{ mb: "$7" }}>
                  {userName && <UserDetail>{userName}</UserDetail>}
                  {userEmail && <UserDetail>{userEmail}</UserDetail>}
                  {userCompany && <UserDetail>{userCompany}</UserDetail>}
                </Box>
                <PrimaryLink to="/account/settings">Settings</PrimaryLink>
                <StyledExternalLink
                  to={supportUrl}
                  target="_blank"
                  onClick={(event) => {
                    if (
                      !featureEnabled("zendesk_enabled") ||
                      !zendeskAvailable()
                    ) {
                      return;
                    }
                    event.preventDefault();
                    zendeskOpen();
                    sendToAmplitude("top nav - clicks help");
                  }}
                  eventName="header:help"
                >
                  <Text variant={"$body2"} color={"white"} css={{ mb: "$6" }}>
                    Need help?
                  </Text>
                </StyledExternalLink>
                <PrimaryExternalLink
                  to="/"
                  eventName="navigationPane:signOut"
                  onClick={(e) => {
                    e.preventDefault();

                    if (onSignOut) {
                      onSignOut();
                    }
                  }}
                >
                  Sign out
                </PrimaryExternalLink>
              </>
            ) : (
              <>
                <StyledExternalLink
                  to={supportUrl}
                  target="_blank"
                  onClick={(event) => {
                    if (
                      !featureEnabled("zendesk_enabled") ||
                      !zendeskAvailable()
                    ) {
                      return;
                    }
                    event.preventDefault();
                    zendeskOpen();
                    sendToAmplitude("top nav - clicks help");
                  }}
                  eventName="header:help"
                >
                  <Text variant={"$body2"} color={"white"} css={{ mb: "$6" }}>
                    Need help?
                  </Text>
                </StyledExternalLink>
                <SecondaryLink to="/sign-in">Sign in</SecondaryLink>
              </>
            )}
          </Box>
        </Fade>
      </Wrapper>
    </Box>
  );
};
