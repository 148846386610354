import React, { useState } from "react";
import type * as Stitches from "@stitches/react";
import { mergeCss, styled } from "src/ccl/stitches/config";
import { AvailableIcon, Icon } from "src/ccl/document/icon";
import { Text } from "src/ccl/document/text";
import { iconSizes } from "src/ccl/stitches/theme";

const Container = styled("div", {
  borderTop: "4px solid $grey6",
  borderLeft: "4px solid $grey6",
  borderRight: "4px solid $grey6",
  borderBottom: "8px solid $grey6",
  padding: "$4",
  position: "relative",
  display: "flex",
  transitionProperty: "opacity, position, top",
  transitionDuration: "0.2s",
  transitionTimingFunction: "ease",
  transform: "translateZ(0)",

  "@bp2": {
    padding: "$8",
  },

  "svg.toggle": {
    transition: "0.2s ease all",
  },

  variants: {
    variant: {
      active: {
        backgroundColor: "$grey2",
      },
      inactive: {
        backgroundColor: "$white",
      },
    },
    height: {
      expanded: {
        height: "auto",
        overflow: "initial",
        svg: {
          transform: "rotateX(180deg)",
        },
      },
      collapsed: {
        height: "$17",
        overflow: "hidden",
      },
    },
  },
});

const GradientOverlay = styled("div", {
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  linearGradient: "180deg, rgba(255, 255, 255, 0) 0%, #fff 100%",
});

const BlockTitle = styled(Text, {
  mb: "$5",
});

const InlineTitle = styled(Text, {
  display: "inline-block",
  fontWeight: "$bold",
  pr: "$2",
});

const IconContainer = styled("div", {
  pr: "$4",
});

const ExpandBox = styled("div", {
  position: "absolute",
  bottom: "-4px",
  right: "-2px",
  backgroundColor: "$grey6",
  padding: "$2 $5",
  cursor: "pointer",
});

interface HelperBoxProps
  extends Omit<Stitches.VariantProps<typeof Container>, "title"> {
  active: boolean;
  title: React.ReactNode;
  collapsible?: boolean;
  collapsed?: boolean;
  icon?: AvailableIcon;
  iconSize?: keyof typeof iconSizes;
  css?: Stitches.CSS;
  children: React.ReactNode;
}

export const HelperBox = ({
  active,
  title,
  children,
  css = {},
  collapsible = false,
  collapsed = collapsible,
  icon,
  iconSize = 14,
}: HelperBoxProps) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  return (
    <Container
      css={mergeCss({}, css)}
      variant={active ? "active" : "inactive"}
      height={isCollapsed ? "collapsed" : "expanded"}
    >
      {isCollapsed && <GradientOverlay />}

      {collapsible && icon && (
        <IconContainer>
          <Icon variant={icon} size={iconSize} />
        </IconContainer>
      )}

      <div>
        {collapsible ? (
          <InlineTitle>{title}:</InlineTitle>
        ) : (
          <BlockTitle variant="h3">{title}</BlockTitle>
        )}

        {children}

        {collapsible && (
          <ExpandBox
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
          >
            <Icon
              variant="chevronDown"
              color="white"
              size={14}
              className="toggle"
            />
          </ExpandBox>
        )}
      </div>
    </Container>
  );
};
