import { gql } from "@apollo/client";

export const INVITE_BOOKER_MUTATION = gql`
  mutation InviteUserToBookingCompany($email: String!) {
    inviteUserToBookingCompany(email: $email) {
      id
      email
    }
  }
`;
