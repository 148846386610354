import { Computed, computed } from "easy-peasy";
import { CurrentUserModel } from "./currentUser";
import { AgentProfile, User } from "src/graphql/types";

type UserKind = "talent" | "booker" | "agent";

export interface KindsModel {
  isBooker: Computed<CurrentUserModel, boolean>;
  isTalent: Computed<CurrentUserModel, boolean>;
  isAgent: Computed<CurrentUserModel, boolean>;
  isAgencyOwner: Computed<CurrentUserModel, boolean>;
  isFirstPartyAgent: Computed<CurrentUserModel, boolean>;
  kind: Computed<CurrentUserModel, string>;
}

const isOfKind = (kind: UserKind, user: User | null) => {
  if (!user) {
    return false;
  }

  if (user.kinds?.includes(kind)) {
    return true;
  }

  return false;
};

export const kindsModel: KindsModel = {
  kind: computed((state) => state.me?.kinds?.[0] || ""),
  isBooker: computed((state) => isOfKind("booker", state.me)),
  isTalent: computed((state) => isOfKind("talent", state.me)),
  isAgent: computed((state) => isOfKind("agent", state.me)),
  isAgencyOwner: computed((state) => {
    if (!state.isAgent) {
      return false;
    }

    if (!state.me) {
      return false;
    }

    const profile = state.me.profile as AgentProfile;

    return profile.role === "owner";
  }),
  isFirstPartyAgent: computed((state) => !!state.me?.agency?.firstParty),
};
