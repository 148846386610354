import React from "react";
import tapeImage1x from "./tape@1x.png";
import tapeImage2x from "./tape@2x.png";
import { styled } from "src/ccl/stitches";
import { Box } from "src/ccl/layout";

const StyledTape = styled("img", {
  position: "absolute",
  top: "-20px",
  left: "-16px",
  zIndex: "$100",
});

export const StickyTapeContainer = ({
  children,
  ...props
}: {
  children?: React.ReactNode;
}) => (
  <Box {...props} css={{ position: "relative" }}>
    <StyledTape
      src={tapeImage1x}
      srcSet={`${tapeImage1x}, ${tapeImage2x} 2x`}
      aria-hidden="true"
      alt=""
    />
    {children}
  </Box>
);
