import { action, Action } from "easy-peasy";
import { Package } from "src/graphql/types";

export interface PackageDraftModel {
  packageDraft: Package | null;
  setPackageDraft: Action<PackageDraftModel, Package | null>;
}

export const packageDraftModel: PackageDraftModel = {
  packageDraft: null,
  setPackageDraft: action((state, payload) => {
    state.packageDraft = payload;
  }),
};
