import React from "react";
import { CSS } from "@stitches/react";
import { breakpoints, styled, mergeCss } from "src/ccl/stitches";

const StyledSpan = styled("span");

interface ResponsiveTextProps
  extends Partial<Record<keyof typeof breakpoints, React.ReactNode>> {
  initial: React.ReactNode;
  children?: React.ReactNode;
  css?: CSS;
}

export const ResponsiveText = ({
  initial,
  css = {},
  ...breakpoints
}: ResponsiveTextProps) => (
  <>
    <StyledSpan
      css={mergeCss(
        {
          display: "initial",
          "@bp1": {
            display: breakpoints.bp1 ? "none" : "initial",
          },
          "@bp2": {
            display: breakpoints.bp1 || breakpoints.bp2 ? "none" : "initial",
          },
          "@bp3": {
            display:
              breakpoints.bp1 || breakpoints.bp2 || breakpoints.bp3
                ? "none"
                : "initial",
          },
          "@bp4": {
            display:
              breakpoints.bp1 ||
              breakpoints.bp2 ||
              breakpoints.bp3 ||
              breakpoints.bp4
                ? "none"
                : "initial",
          },
          "@bp5": {
            display:
              breakpoints.bp1 ||
              breakpoints.bp2 ||
              breakpoints.bp3 ||
              breakpoints.bp4 ||
              breakpoints.bp5
                ? "none"
                : "initial",
          },
        },
        css,
      )}
    >
      {initial}
    </StyledSpan>

    {breakpoints.bp1 && (
      <StyledSpan
        css={{
          display: "none",
          "@bp1": {
            display: "initial",
          },
          "@bp2": {
            display: breakpoints.bp2 ? "none" : "initial",
          },
          "@bp3": {
            display: breakpoints.bp2 || breakpoints.bp3 ? "none" : "initial",
          },
          "@bp4": {
            display:
              breakpoints.bp2 || breakpoints.bp3 || breakpoints.bp4
                ? "none"
                : "initial",
          },
          "@bp5": {
            display:
              breakpoints.bp2 ||
              breakpoints.bp3 ||
              breakpoints.bp4 ||
              breakpoints.bp5
                ? "none"
                : "initial",
          },
        }}
      >
        {breakpoints.bp1}
      </StyledSpan>
    )}

    {breakpoints.bp2 && (
      <StyledSpan
        css={{
          display: "none",
          "@bp2": {
            display: "initial",
          },
          "@bp3": {
            display: breakpoints.bp3 ? "none" : "initial",
          },
          "@bp4": {
            display: breakpoints.bp3 || breakpoints.bp4 ? "none" : "initial",
          },
          "@bp5": {
            display:
              breakpoints.bp3 || breakpoints.bp4 || breakpoints.bp5
                ? "none"
                : "initial",
          },
        }}
      >
        {breakpoints.bp2}
      </StyledSpan>
    )}

    {breakpoints.bp3 && (
      <StyledSpan
        css={{
          display: "none",
          "@bp3": {
            display: "initial",
          },
          "@bp4": {
            display: breakpoints.bp4 ? "none" : "initial",
          },
          "@bp5": {
            display: breakpoints.bp4 || breakpoints.bp5 ? "none" : "initial",
          },
        }}
      >
        {breakpoints.bp3}
      </StyledSpan>
    )}

    {breakpoints.bp4 && (
      <StyledSpan
        css={{
          display: "none",
          "@bp4": { display: "initial" },
          "@bp5": {
            display: breakpoints.bp5 ? "none" : "initial",
          },
        }}
      >
        {breakpoints.bp4}
      </StyledSpan>
    )}

    {breakpoints.bp5 && (
      <StyledSpan
        css={{
          display: "none",
          "@bp5": { display: "initial" },
        }}
      >
        {breakpoints.bp5}
      </StyledSpan>
    )}
  </>
);
