import { gql } from "@apollo/client";
import { NOTE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const CREATE_AGENCY_BOOKER_NOTE_MUTATION = gql`
  mutation CreateAgencyBookerNote($bookerId: ID!, $body: String!) {
    createAgencyBookerNote(bookerId: $bookerId, body: $body) {
      ...NoteDetails
    }
  }
  ${NOTE_DETAILS_FRAGMENT}
`;
