import React from "react";
import { Text } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";
import { Job } from "src/graphql/types";
import { jobFormattedDate } from "src/utils/job";

interface JobDetailsProps {
  job: Job;
  color: keyof typeof tokens.colors;
}

export const JobDetails = ({ job, color }: JobDetailsProps) => (
  <Box css={{ flex: 1 }}>
    <Text
      css={{
        textDecoration: "none",
        color: color,
      }}
    >
      {job.name}
    </Text>
    <Text variant="meta" color="grey6" css={{ mt: "$4" }}>
      {job.brand}
    </Text>
    <Text variant="meta" color="grey6" css={{ mt: "$3" }}>
      {jobFormattedDate(job)}
    </Text>
  </Box>
);
