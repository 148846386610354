import React from "react";
import { Box, Flex } from "src/ccl/layout";
import { AssetImage, Text } from "src/ccl/document";
import { Agency, TalentVertical } from "src/graphql/types";
import { verticalMap } from "src/utils/user";
import { Link } from "src/ccl/navigation";

interface LegacyVerticalTitleProps {
  vertical: TalentVertical;
  category: string | undefined;
  agency?: Agency;
  creativeType?: string;
}

export const LegacyVerticalTitle = ({
  vertical,
  category,
  agency,
  creativeType,
}: LegacyVerticalTitleProps) => {
  return (
    <Flex
      css={{
        borderBottom: "1px solid $grey2",
        pb: "$4",
        mb: "$5",
        "@bp3": {
          pb: "$7",
          mb: "$10",
        },
      }}
    >
      {agency?.logo && (
        <Box css={{ width: 44, height: 50, mr: "$4", "@bp3": { mr: "$5" } }}>
          <AssetImage
            asset={agency?.logo}
            size={{
              "@initial": {
                width: 44,
                height: 50,
                fit: "clamp",
              },
            }}
            alt={`Logo of ${agency.name}`}
          />
        </Box>
      )}
      <Flex
        css={{
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Text
          variant={{ "@initial": "buttonText", "@bp3": "h3" }}
          color="grey6"
          css={{
            mb: "$2",
          }}
        >
          {creativeType || verticalMap[vertical]}
        </Text>
        {vertical === TalentVertical.FashionModel && (
          <Flex>
            <Text
              variant="meta"
              color="grey6"
              css={{
                mr: "$5",
              }}
            >
              Category: {category}
            </Text>
            {agency?.name != "Contact" ? (
              <Link
                to={`/agency/${agency?.slug}`}
                css={{ textDecoration: "none" }}
              >
                <Text
                  variant="meta"
                  color="grey6"
                  css={{
                    fontWeight: "bold",
                  }}
                >
                  {agency?.name}
                </Text>
              </Link>
            ) : (
              <Text
                variant="meta"
                color="grey6"
                css={{
                  fontWeight: "bold",
                }}
              >
                {agency?.name}
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
