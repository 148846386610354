import { gql } from "@apollo/client";

export const UPDATE_SAVED_TALENT_COLLECTIONS = gql`
  mutation UpdateSavedTalentCollections(
    $talentId: ID!
    $collectionIds: [ID!]!
  ) {
    updateSavedTalentCollections(
      talentId: $talentId
      collectionIds: $collectionIds
    ) {
      id
    }
  }
`;
