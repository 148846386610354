import { gql } from "@apollo/client";

export const USER_PARTS_FRAGMENT = gql`
  fragment UserParts on User {
    id
    name
    featuredAsset {
      mediaUrl
    }
    assignedLiveJobCount
  }
`;
