import { gql } from "@apollo/client";
import {
  OUTBOUND_PAYMENTS_DETAILS_FRAGMENT,
  PAYMENT_BATCH_DETAILS_FRAGMENT,
  LINE_ITEM_FRAGMENT,
} from "src/graphql/fragments";

export const FIRST_PARTY_AGENT_PAYMENT_BATCH_INDEX_QUERY = gql`
  query FirstPartyAgentPaymentBatchIndexQuery(
    $sortColumn: String
    $sortDirection: SortDirection
  ) {
    paymentBatches(sortColumn: $sortColumn, sortDirection: $sortDirection) {
      ...PaymentBatchDetailsFragment
      outboundPayments {
        ...OutboundPaymentsDetailsFragment
        lineItems {
          ...LineItemDetails
        }
      }
    }
  }
  ${PAYMENT_BATCH_DETAILS_FRAGMENT}
  ${OUTBOUND_PAYMENTS_DETAILS_FRAGMENT}
  ${LINE_ITEM_FRAGMENT}
`;
