import React, { useState } from "react";
import { addDays, startOfToday } from "date-fns";
import { Button, LinkButton } from "src/ccl/navigation";
import {
  Job,
  JobDispute,
  JobRejectionReason,
  JobState,
} from "src/graphql/types";
import { ExplanationModal, Icon, Text } from "src/ccl/document";
import {
  jobFinalized,
  jobApproved,
  jobAllTalentResponded,
  jobCompleted,
  jobFormattedDate,
  jobHasRespondedTalent,
  jobHasRespondedTalentCount,
  jobPendingApproval,
  jobCancelled,
  jobExpired,
  jobRejected,
  jobIsResubmittable,
  jobCancelledReasonText,
  jobRejectionReasonText,
  jobAwaitingPayment,
  jobHappeningToday,
  jobPaidUpfront,
} from "src/utils/job";
import { Box, Flex } from "src/ccl/layout";
import { formatDate, isBetween } from "src/utils/dates";
import { useFeatures } from "src/hooks";

const DisputeContent = ({
  lastDispute,
  onReportIssue,
  showCta,
  hasUnresolvedDisputes,
  allDisputesResolved,
}: {
  showCta: boolean;
  lastDispute?: JobDispute;
  hasUnresolvedDisputes: boolean;
  allDisputesResolved: boolean;
  onReportIssue: () => void;
}) => (
  <>
    <Box
      css={{
        width: "80%",
        height: "1px",
        backgroundColor: "$grey3",
        borderRadius: "$0",
      }}
    />
    {showCta ? (
      <>
        <Text>
          If you had any issues with the booking, you need to report an issue
          within 24hrs of the job date, so we can pause any payments and
          investigate.
        </Text>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onReportIssue();
          }}
        >
          Report an issue
        </Button>
      </>
    ) : (
      <Box>
        {lastDispute && (
          <Text
            as="span"
            css={{
              fontWeight: "$bold",
            }}
          >
            You reported an issue on {formatDate(lastDispute.createdAt)}.
          </Text>
        )}
        <Text as="span">
          {" "}
          {hasUnresolvedDisputes &&
            "We aim to get in touch with you within 3 working days."}
          {allDisputesResolved && "All issues have been marked as resolved."}
        </Text>
      </Box>
    )}
  </>
);

const MoreInformationCta = ({ onClick }: { onClick: () => void }) => (
  <>
    <Text
      css={{
        textDecoration: "underline",
        display: "inline-block",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      More information is available here
    </Text>
    .
  </>
);

const RejectionModal = ({
  isOpen,
  onClose,
  rejectionNote,
  rejectionReason,
  jobSlug,
  isResubmittable,
}: {
  isOpen: boolean;
  onClose: () => void;
  primaryCta?: React.FC;
  rejectionNote: string;
  rejectionReason: JobRejectionReason;
  jobSlug: string;
  isResubmittable: boolean;
}) => {
  let buttonText: string;
  if (rejectionReason === JobRejectionReason.InappropriateBudget) {
    buttonText = "Adjust the Rate";
  } else if (JobRejectionReason.InsufficientInformation) {
    buttonText = "Update Description";
  }

  return (
    <ExplanationModal
      title="Job rejected"
      isOpen={isOpen}
      showCloseButton
      onCancel={onClose}
      primaryCta={
        isResubmittable
          ? () => (
              <LinkButton variant="primary" to={`${jobSlug}/resubmit`}>
                {buttonText}
              </LinkButton>
            )
          : undefined
      }
    >
      <Text css={{ fontWeight: "bold" }}>A note from our operations team:</Text>
      <Text
        css={{
          py: "$9",
          mb: "$9",
          borderBottom: "1px solid $grey2",
        }}
      >
        &quot;{rejectionNote}&quot;
      </Text>
    </ExplanationModal>
  );
};

const ResubumitJobButton = ({ slug }: { slug: string }) => (
  <LinkButton
    data-test-id={"ResubmitButton"}
    to={`${slug}/resubmit/shortlist/talent`}
    css={{
      display: "flex",
      justifyContent: "center",
      width: "fit-content",
    }}
  >
    <Icon variant="reset" css={{ mr: "$4" }} size={16} />
    <Text variant="buttonText">Resubmit job</Text>
  </LinkButton>
);

interface SubTextBasedOnJobStateProps {
  job: Job;
  onChoosePaymentMethod: () => void;
  onReportIssue: () => void;
  onPayInvoice: () => void;
}

export const SubTextBasedOnJobState = ({
  job,
  onChoosePaymentMethod,
  onReportIssue,
  onPayInvoice,
}: SubTextBasedOnJobStateProps) => {
  const { rejectionNote, slug } = job;
  const [showRejectionNoteModal, setShowRejectionNoteModal] =
    useState<boolean>(false);
  const { featureEnabled } = useFeatures();

  const isHappeningToday = jobHappeningToday(job);
  const today = startOfToday();
  const isInBloomGracePeriod =
    job.state === JobState.Completed &&
    isBetween(new Date(job.endDate), today, addDays(new Date(job.endDate), 1));

  const isAwaitingPayment = jobAwaitingPayment(job.state);
  const showDisputeCTA =
    !isAwaitingPayment &&
    !job.hasUnresolvedDisputes &&
    !job.jobDisputes?.length &&
    (isHappeningToday || isInBloomGracePeriod) &&
    jobPaidUpfront(job);

  const allDisputesResolved =
    !job.hasUnresolvedDisputes && !!job.jobDisputes?.length;
  const lastDispute = job.jobDisputes?.slice(-1)[0];

  const responsiveTalent = jobHasRespondedTalentCount(job);

  const showDisputeContent =
    showDisputeCTA || job.hasUnresolvedDisputes || allDisputesResolved;

  if (jobAwaitingPayment(job.state)) {
    if (job.paymentProcessing) {
      return (
        <Text>
          Your shortlist is confirmed, but your job isn&apos;t confirmed until
          you pay via your selected payment method. If you have already paid, it
          could take a few hours to be processed.
        </Text>
      );
    }
    return (
      <>
        <Text>
          It’s time to choose your payment method before you can confirm this
          job. We&apos;ll hold funds securely. You&apos;ll have 24 hours after
          the job date to let us know if there are any issues, and we can
          investigate before paying out.
        </Text>
        <Button variant="primary" onClick={onChoosePaymentMethod}>
          Choose payment method
        </Button>
      </>
    );
  }

  if (jobFinalized(job.state)) {
    return (
      <>
        <Text>
          Your job&apos;s now confirmed! It&apos;s time to get ready.
          <br />
          Check your inbox for a confirmation email with your{" "}
          {responsiveTalent === 1 ? "creative's" : "creatives'"} details.
        </Text>
        {showDisputeContent && (
          <DisputeContent
            lastDispute={lastDispute}
            showCta={showDisputeCTA}
            onReportIssue={onReportIssue}
            hasUnresolvedDisputes={!!job.hasUnresolvedDisputes}
            allDisputesResolved={allDisputesResolved}
          />
        )}
      </>
    );
  }

  if (jobCompleted(job.state)) {
    return (
      <>
        {featureEnabled("post_completion_payments") &&
        job.payableInvoices?.length ? (
          <>
            <Text>
              You can opt to pay your unpaid invoice now via Credit Card or Open
              Banking, or you can pay directly into the bank account details on
              the invoice
            </Text>
            <Button variant="primary" onClick={onPayInvoice}>
              Pay invoice
            </Button>
          </>
        ) : (
          <Text>This job was completed on {jobFormattedDate(job)}.</Text>
        )}
        {showDisputeContent && (
          <DisputeContent
            lastDispute={lastDispute}
            showCta={showDisputeCTA}
            onReportIssue={onReportIssue}
            hasUnresolvedDisputes={!!job.hasUnresolvedDisputes}
            allDisputesResolved={allDisputesResolved}
          />
        )}
      </>
    );
  }

  if (jobApproved(job.state)) {
    if (jobAllTalentResponded(job)) {
      return (
        <Text>
          Choose the creatives you&apos;d like to work with. Or you can
          shortlist more creatives.
        </Text>
      );
    }

    if (jobHasRespondedTalent(job)) {
      return (
        <Flex>
          <Text>
            {responsiveTalent} of the {job?.talent?.length || 0} creatives on
            your shortlist {responsiveTalent === 1 ? "has" : "have"} responded.
            You can still add to your shortlist.
          </Text>
        </Flex>
      );
    }
    return (
      <Text>
        Your shortlisted creatives are still responding. Email them below to
        start a conversation.
      </Text>
    );
  }

  if (jobPendingApproval(job.state)) {
    return (
      <Text>
        We&apos;re still approving your job. This normally takes a few hours but
        can be a lot quicker.
        <br />
        <br />
        Not heard from us? <strong>Check your spam folder.</strong>
      </Text>
    );
  }

  if (jobCancelled(job.state)) {
    return (
      <>
        <Text>
          {jobCancelledReasonText(job.cancelledAt, job?.cancellationReason)}
        </Text>
        <ResubumitJobButton slug={slug} />
      </>
    );
  }

  if (jobExpired(job.state)) {
    return (
      <Text>
        The job wasn&apos;t confirmed before the job date and has now expired.
      </Text>
    );
  }

  if (jobRejected(job.state)) {
    return (
      <>
        <Text>
          {jobRejectionReasonText(job?.rejectionReason)}{" "}
          {(job.rejectionReason == "INAPPROPRIATE_BUDGET" ||
            job.rejectionReason == "INSUFFICIENT_INFORMATION") &&
            rejectionNote && (
              <>
                <RejectionModal
                  rejectionNote={rejectionNote}
                  onClose={() => setShowRejectionNoteModal(false)}
                  isOpen={showRejectionNoteModal}
                  rejectionReason={job.rejectionReason}
                  jobSlug={job.slug}
                  isResubmittable={jobIsResubmittable(job)}
                />
                <MoreInformationCta
                  onClick={() => setShowRejectionNoteModal(true)}
                />
              </>
            )}
        </Text>
        {(job.rejectionReason == "INAPPROPRIATE_BUDGET" ||
          job.rejectionReason == "INSUFFICIENT_INFORMATION") && (
          <ResubumitJobButton slug={slug} />
        )}
      </>
    );
  }

  return null;
};
