import { CSS } from "@stitches/react";
import React from "react";
import ReactModal from "react-modal";
import { mergeCss, styled } from "src/ccl";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";

export type ModalWidth = "normal" | "wide" | "super-wide" | "responsive";

const classNames: Record<ModalWidth, string> = {
  responsive: "ReactModal__Content--responsive",
  normal: "ReactModal__Content",
  wide: "ReactModal__Content--wide",
  "super-wide": "ReactModal__Content--super-wide",
};

export const modalTitleStyles = (
  showCloseButton: boolean,
  titleCss: React.CSSProperties,
  isNewDashboard: boolean,
) =>
  mergeCss(
    {
      fontSize: "$24",
      mb: "$5",
      mt: 0,
      "@bp1": {
        fontSize: "$30",
      },
      ...(isNewDashboard && { fontFamily: "$sansNew" }),
    },
    titleCss,
  );
export interface ModalProps {
  isOpen: boolean;
  title?: string;
  onClose?: () => void;
  width?: ModalWidth;
  modalStyles?: React.CSSProperties;
  showCloseButton?: boolean;
  variant?: "dark";
  context?: "createJob" | "editJob" | "talentProfile";
  subtitle?: React.ReactNode;
  subtitleBold?: boolean;
  titleCss?: React.CSSProperties;
  withBodyPadding?: boolean;
  withTitlePadding?: boolean;
  overlayCss?: React.CSSProperties;
  parentCss?: CSS;
  children?: React.ReactNode;
  titleVariant?: string;
  icon?: AvailableIcon;
  isNewDashboard?: boolean;
}

export const CloseIcon = styled(Icon, {
  objectFit: "contain",
  objectPosition: "center center",
  height: 12,
  width: 12,
});

const CloseLink = styled("a", {
  position: "absolute",
  top: "-17px",
  right: "-17px",
  padding: "$2",
  backgroundColor: "$white",
  cursor: "pointer",

  variants: {
    variant: {
      dark: {
        backgroundColor: "$black",
      },
    },
  },
});

const baseModalStyles: React.CSSProperties = {
  position: "initial",
  top: "initial",
  left: "initial",
  right: "initial",
  bottom: "initial",
  backgroundColor: tokens.colors.white,
  border: "none",
  borderRadius: "10px",
  margin: "auto",
  padding: tokens.space[9],
};

const overlayStyles: React.CSSProperties = {
  height: "100%",
  width: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  overflowY: "auto",
  display: "flex",
  padding: tokens.space[5],
};

export const Modal = ({
  isOpen,
  title,
  onClose,
  modalStyles,
  width = "normal",
  showCloseButton = false,
  children,
  variant,
  context,
  subtitle,
  subtitleBold = true,
  titleCss = {},
  withBodyPadding = true,
  withTitlePadding = true,
  overlayCss,
  parentCss = {},
  titleVariant = "h2",
  icon,
  isNewDashboard = false,
}: ModalProps) => (
  <ReactModal
    testId="ReactModal"
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel={title}
    closeTimeoutMS={100}
    onAfterOpen={() => {
      document.body.style.overflow = "hidden";
    }}
    onAfterClose={() => {
      document.body.style.overflow = "scroll";
    }}
    className={classNames[width]}
    style={{
      content: {
        ...baseModalStyles,
        backgroundColor:
          context === "talentProfile"
            ? undefined
            : variant === "dark"
            ? tokens.colors.black
            : tokens.colors.white,
        padding: context === "talentProfile" ? undefined : "8px",
        ...modalStyles,
      },
      overlay: overlayCss
        ? mergeCss(overlayStyles, overlayCss)
        : { ...overlayStyles },
    }}
  >
    {showCloseButton && (
      <Box css={{ position: "relative", right: "$6", top: "$6" }}>
        <CloseLink
          variant={variant}
          onClick={() => {
            onClose && onClose();
          }}
          data-test-id="CloseModalButton"
        >
          <Flex css={{ alignItems: "center", flexDirection: "row" }}>
            <CloseIcon
              variant="cross"
              size={12}
              color={variant === "dark" ? "white" : "black"}
            />
          </Flex>
        </CloseLink>
      </Box>
    )}
    <Flex
      css={{
        borderBottom:
          context && ["createJob", "editJob"].includes(context)
            ? "2px $grey3 solid"
            : "none",
        mb:
          context && ["createJob", "editJob"].includes(context)
            ? "$3"
            : undefined,
        "@bp2": {
          borderBottom: "none",
        },
        justifyContent: "space-between",
        alignItems: "center",
        py: withTitlePadding ? "$2" : "$0",
        px: withTitlePadding ? "$6" : "$0",
      }}
    >
      {title && (
        <Flex
          css={{
            mt: showCloseButton ? "$8" : 0,
            gap: "$3",
            flexDirection: "column",
            "@bp2": {
              flexDirection: "row",
              alignItems: "center",
              gap: "$8",
            },
          }}
        >
          {icon && (
            <Box>
              <Icon variant={icon} size={60} />
            </Box>
          )}
          <Text
            variant={titleVariant}
            css={modalTitleStyles(showCloseButton, titleCss, isNewDashboard)}
          >
            {title}
          </Text>
        </Flex>
      )}
      {subtitle && (
        <Text
          variant="meta"
          css={{ fontWeight: subtitleBold ? "bold" : "regular" }}
        >
          {subtitle}
        </Text>
      )}
    </Flex>
    <Box
      css={mergeCss(
        {
          px: withBodyPadding ? "$6" : "$0",
          py: withBodyPadding ? "$4" : "$0",
        },
        parentCss,
      )}
    >
      {children}
    </Box>
  </ReactModal>
);
