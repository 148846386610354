import { useQuery } from "@apollo/client";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BOOKER_SAVED_TALENT_COLLECTIONS } from "src/graphql/queries";
import {
  BookerProfile,
  Maybe,
  Query,
  SavedTalentCollection,
} from "src/graphql/types";
import { extractNodes } from "src/utils/lang";

type Result = [
  SavedTalentCollection[],
  string,
  Dispatch<SetStateAction<string>>,
  boolean,
  Maybe<BookerProfile>,
];

export const useBookmarkCollectionsQuery = (deps: unknown[]): Result => {
  const [name, setName] = useState("");
  const { refetch, data, loading } = useQuery<Query>(
    BOOKER_SAVED_TALENT_COLLECTIONS,
    {
      variables: {
        first: 8,
        name,
      },
    },
  );
  const bookerProfile = data?.me?.profile as BookerProfile;
  const collections = extractNodes<SavedTalentCollection>(
    bookerProfile?.savedTalentCollections,
  );

  useEffect(() => {
    refetch();
  }, [name, ...deps]);

  return [collections, name, setName, loading, bookerProfile];
};
