import { useState } from "react";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addMonths,
  addDays,
} from "date-fns";
import { formatDate } from "src/utils/dates";
import { DateRangeInput, JobDateRangeOption } from "src/graphql/types";

const sevenDaysAgo = (date: Date) => addDays(date, -7);
const thirtyDaysAgo = (date: Date) => addDays(date, -30);
const oneMonthAgo = (date: Date) => addMonths(date, -1);

const buildRange = (option: JobDateRangeOption) => {
  const today = new Date();
  switch (option) {
    case JobDateRangeOption.ThisWeek:
      return {
        startDate: startOfWeek(today),
        endDate: endOfWeek(today),
      };
    case JobDateRangeOption.LastSevenDays:
      return {
        startDate: sevenDaysAgo(today),
        endDate: today,
      };
    case JobDateRangeOption.ThisMonth:
      return {
        startDate: startOfMonth(today),
        endDate: endOfMonth(today),
      };
    case JobDateRangeOption.LastThirtyDays:
      return {
        startDate: thirtyDaysAgo(today),
        endDate: today,
      };
    case JobDateRangeOption.LastMonth: {
      const lastMonth = oneMonthAgo(today);
      return {
        startDate: startOfMonth(lastMonth),
        endDate: endOfMonth(lastMonth),
      };
    }
    default:
      return { startDate: null, endDate: null };
  }
};

export const formatRange = ({ startDate, endDate }: DateRangeInput) => ({
  startDate: formatDate(startDate, "yyyy-MM-dd"),
  endDate: formatDate(endDate, "yyyy-MM-dd"),
});

const buildFormattedRange = (option: JobDateRangeOption) =>
  formatRange(buildRange(option));

export const useDateRangeFilterState = (
  onChange: (dateRange?: DateRangeInput) => void,
): [
  JobDateRangeOption,
  (option: JobDateRangeOption) => { selected: boolean; onClick: () => void },
] => {
  const [selected, setSelected] = useState<JobDateRangeOption>(
    JobDateRangeOption.NONE,
  );

  const buildRangeOptionProps = (option: JobDateRangeOption) => ({
    selected: selected === option,
    onClick: () => {
      if (selected === option) {
        onChange(undefined);
        setSelected(JobDateRangeOption.NONE);
        return;
      }
      if (option !== JobDateRangeOption.Custom) {
        onChange(buildFormattedRange(option));
        setSelected(option);
      } else {
        setSelected(option);
      }
    },
  });

  return [selected, buildRangeOptionProps];
};
