import { useEffect, useState } from "react";
import { useIntersect } from "./useIntersect";

export const useStatefulIntersect = function (): [
  boolean,
  (node: Element | null) => void,
] {
  const [sentinelRef, entry] = useIntersect();
  const [isShowing, setIsShowing] = useState(true);
  useEffect(() => {
    if (!entry) {
      return;
    }

    setIsShowing(entry.isIntersecting);
  }, [entry]);

  return [isShowing, sentinelRef];
};
