import { gql } from "@apollo/client";

export const REGENERATE_INVOICE_MUTATION = gql`
  mutation RebuildInvoice($invoiceId: ID!) {
    rebuildInvoice(invoiceId: $invoiceId) {
      id
      state
    }
  }
`;
