import { gql } from "@apollo/client";

export const TALENT_MANAGEMENT_PAYMENTS_QUERY = gql`
  query TalentManagementPaymentsQuery($slug: ID) {
    me {
      agency {
        talent(id: $slug) {
          payments {
            id
            createdAt
            amount
            currency
            state
            remittanceLetterUrl
            sentAt

            lineItems {
              amount
              kind
            }

            jobTalent {
              job {
                id
                slug
                name
                state
                startDate
                endDate
              }
            }
          }
        }
      }
    }
  }
`;
