import React from "react";
import { TalentSelectionHelpLink } from "./FormLayoutHeaderHelpLinks";
import { GoBack } from ".";
import { Flex, Grid } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { styled } from "src/ccl/stitches";
import { Link, SupportLauncher } from "src/ccl/navigation";
import { sendToAmplitude } from "src/utils/analytics";
import { CJStep } from "src/pages/createJob/CJSteps";

interface StepSummaryV2Props {
  steps: CJStep[];
  currentStep: number;
  goBackTo?: string | (() => void | Promise<void>);
  showProgressBar: boolean;
  expanded: boolean;
}

const StepBar = styled("div", {});

type StepSummaryProgressBarProps = {
  steps: CJStep[];
  expanded: boolean;
  currentStep: number;
};

const StepSummaryProgressBar = ({
  steps,
  expanded,
  currentStep,
}: StepSummaryProgressBarProps) => (
  <>
    {steps.map((step, index) => (
      <Flex
        key={step.id}
        css={{
          borderTop: "2px solid $black",
          borderBottom: "2px solid $black",
          width: "100%",
          height: "$2",
          boxSizing: "content-box",
          display: "inline-block",
          borderLeft: index === 0 ? "2px solid $black" : "0",
          borderRight: index === steps.length - 1 ? "2px solid $black" : "0",
          transition: expanded ? "opacity 1s ease" : "opacity 0.2s ease",
          opacity: expanded ? 1 : 0,
        }}
      >
        <StepBar
          key={`step-${index}`}
          css={{
            height: "100%",
            backgroundColor: index <= currentStep ? "$black" : "$white",
          }}
        />
      </Flex>
    ))}
  </>
);

const DisplayHelperButtons = () => (
  <Flex
    css={{
      ml: "$8",
      alignItems: "center",
    }}
  >
    <TalentSelectionHelpLink
      text="Shortlisting guide"
      iconSize={14}
      color="black"
      textVariant="meta"
    />
    <SupportLauncher
      textVariant="meta"
      color="black"
      shortContent="Get help"
      css={{ textDecoration: "underline" }}
    />
  </Flex>
);

type LinkStepProps = {
  steps: CJStep[];
  step: CJStep;
  currentStep: number;
  index: number;
  expanded: boolean;
};

const LinkStep = ({
  index,
  currentStep,
  steps,
  step,
  expanded,
}: LinkStepProps) => (
  <Link
    data-test-id="StepSummaryStep"
    css={{
      textDecoration: "none",
      transition: "200ms ease all",
      px: !expanded ? "$4" : "0",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      backgroundColor: index === currentStep ? "$black" : "$grey1",
      border: index === currentStep ? "1px solid $black" : "1px solid $grey3",
      borderRadius:
        index === 0
          ? "$pill 0 0 $pill"
          : index === steps.length - 1
          ? "0 $pill $pill 0"
          : "0",
      py: "$3",
    }}
    to={step.url}
    key={step.id}
    onClick={
      currentStep === 3
        ? () => sendToAmplitude("jobs form creative details - clicks back")
        : () => null
    }
  >
    <Text
      variant="meta"
      weight="bold"
      css={{
        color:
          index === currentStep
            ? "$white"
            : index < currentStep
            ? "$black"
            : "$grey6",
        display: "inline-block",
      }}
    >
      {index + 1}
      {expanded && (
        <Text
          variant="meta"
          weight="bold"
          css={{
            pl: "$2",
            color:
              index === currentStep
                ? "$white"
                : index < currentStep
                ? "$black"
                : "$grey6",
            display: "inline-block",
          }}
        >
          {`. ${step.title}`}
        </Text>
      )}
    </Text>
  </Link>
);

export const StepSummaryV2 = ({
  steps,
  currentStep,
  goBackTo,
  showProgressBar,
  expanded,
}: StepSummaryV2Props) => {
  return (
    <Flex
      data-test-id="StepSummary"
      css={{
        mt: showProgressBar ? "$4" : "$0",
        transition: "margin 0.4s ease",
        "@bp2": {
          pb: expanded ? 0 : "$4",
        },
      }}
    >
      <Grid
        css={{
          position: "relative",
          width: expanded ? "100%" : undefined,
          gridColumns: expanded
            ? steps.length
            : `minmax(78px, max-content) repeat(${
                steps.length - 1
              }, minmax(46px, max-content))`,
          gridRowGap: showProgressBar ? "16px" : "0px",
          whiteSpace: "nowrap",
          transition: "row-gap 0.5s ease 0s",
        }}
      >
        {steps.map((step, index) => {
          const firstStep = index === 0;
          const pastStep = index < currentStep;

          const activeStep = index === currentStep;

          return pastStep && !firstStep ? (
            <LinkStep
              expanded={expanded}
              index={index}
              currentStep={currentStep}
              steps={steps}
              step={step}
              key={`link-step-${index}`}
            />
          ) : (
            <Flex
              data-test-id="StepSummaryStep"
              css={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: activeStep ? "$black" : "$grey1",
                border: activeStep ? "1px solid $black" : "1px solid $grey3",
                borderRadius: firstStep
                  ? "$pill 0 0 $pill"
                  : index === steps.length - 1
                  ? "0 $pill $pill 0"
                  : "0",
                py: "$2",
              }}
              key={`text-step-${index}`}
              onClick={
                typeof goBackTo !== "string" && firstStep
                  ? goBackTo
                  : () => null
              }
            >
              {firstStep && goBackTo && (
                <GoBack
                  data-test-id="BackIcon"
                  goBackTo={goBackTo}
                  css={{
                    position: "absolute",
                    left: 0,
                    border: "1px solid $black",
                    borderRadius: "$round",
                    backgroundColor: "$white",
                    width: "$8",
                    height: "$8",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-around",
                    ml: "$2",
                  }}
                >
                  <Icon variant="chevronLeft" size={14} css={{ my: "auto" }} />
                </GoBack>
              )}

              <Text
                variant="meta"
                weight="bold"
                css={{
                  color: activeStep ? "$white" : pastStep ? "$black" : "$grey6",
                  display: "inline-block",
                  lineHeight: "$loose",
                  px: "$3",
                  ml: expanded ? 25 : 0,
                }}
              >
                {index + 1}
                {expanded && (
                  <Text
                    variant="meta"
                    weight="bold"
                    css={{
                      pl: "$2",
                      color: activeStep
                        ? "$white"
                        : pastStep
                        ? "$black"
                        : "$grey6",
                      display: "inline-block",
                    }}
                  >
                    {`. ${step.title}`}
                  </Text>
                )}
                {!expanded && activeStep && (
                  <Text
                    variant="meta"
                    weight="bold"
                    css={{
                      pl: "$2",
                      color: activeStep
                        ? "$white"
                        : pastStep
                        ? "$black"
                        : "$grey6",
                      display: "inline-block",
                    }}
                  >
                    {`. ${step.title}`}
                  </Text>
                )}
              </Text>
            </Flex>
          );
        })}

        {showProgressBar && (
          <StepSummaryProgressBar
            steps={steps}
            expanded={expanded}
            currentStep={currentStep}
          />
        )}
      </Grid>
      {!expanded && <DisplayHelperButtons />}
    </Flex>
  );
};
