import React from "react";
import { Footer, FooterProps } from "src/ccl/blocks";
import { LegacyContainer } from "src/ccl/layout";
import { PatternBackgroundContainer } from "src/ccl/ornamental";
import { styled } from "src/ccl/stitches";

const FooterBgContainer = styled(PatternBackgroundContainer, {
  borderTopColor: "$grey1",
  borderTopWidth: 4,
  borderTopStyle: "solid",
  py: "$10",
  flex: "1 1 0%",

  "@bp3": {
    py: "72px",
  },

  "@bp4": {
    py: "106px",
  },
});

export const PatternedFooter = ({ ...props }: FooterProps) => (
  <FooterBgContainer css={{ mt: "$20" }}>
    <LegacyContainer variant="gutter">
      <Footer {...props} />
    </LegacyContainer>
  </FooterBgContainer>
);
