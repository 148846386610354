import { useMemo, useState } from "react";
import { useLocation } from "react-router";
import { includes, map, size } from "lodash";
import { AgentTalentCardImageGrid } from "./AgentTalentCardImageGrid";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Asset, TalentProfile, TalentVertical, User } from "src/graphql/types";
import { styled } from "src/ccl/stitches/config";
import { Icon, Text } from "src/ccl/document";
import { AgentTalentCardOverlayNav } from "src/ccl/document/agentTalentCard/AgentTalentCardOverlayNav";
import { Link } from "src/ccl/navigation";
import { useNewDashboard } from "src/hooks/useNewDashboard";

export type AgentTalentCardVariant = "single" | "multi";

interface AgentTalentCardFeatureProps {
  variant?: AgentTalentCardVariant;
  talent: User;
}

const Overlay = styled(Box, {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
});

const FeatureWrapper = styled(Box, {
  position: "relative",
});

const FeatureOverlay = styled(Overlay, {
  display: "none",
  zIndex: "$100",
  linearGradient:
    "180deg, rgba(0, 0, 0, 0.85) 0%, rgba(136, 136, 136, 0.85) 100%",

  [`${FeatureWrapper}:hover &`]: {
    "@bp2": {
      display: "block",
    },
  },
});

const FeatureLayout = styled(Grid, {
  gridTemplateColumns: "repeat(3, minmax(min(50px,100%), 1fr));",
  gridTemplateRows: "repeat(2, minmax(min(50px,100%), 1fr));",
  gap: "1px",
});

const NoImages = ({ isSingle }: { isSingle: boolean }) => (
  <Flex
    css={{
      backgroundColor: "$grey2",
      alignItems: "center",
      justifyContent: "center",
      height: isSingle ? 200 : 153,

      "@bp2": {
        height: isSingle ? 248 : 165,
      },
      "@bp5": {
        height: isSingle ? 238 : 306,
      },
    }}
  >
    <Icon variant="portfolio" size={40} color="grey6" />
  </Flex>
);

export const AgentTalentCardFeature = ({
  talent,
  variant = "single",
}: AgentTalentCardFeatureProps) => {
  const { isNewDashboard } = useNewDashboard();
  const { pathname } = useLocation();
  const isAgenciesPathname = includes(pathname, "agencies");
  const isTpaManageTalent = includes(pathname, "/dashboard/agent/talent");
  const isContactOpenRoute = includes(
    pathname,
    "/dashboard/agent/talent/select",
  );
  const isContactSelectRoute = includes(
    pathname,
    "/dashboard/agent/talent/open",
  );
  const { name, profile, completedJobCount, groups, featuredAsset, agency } =
    talent;
  const {
    minDayRate,
    maxDayRate,
    portfolioImages,
    portfolioImageCategories,
    vertical,
    published,
  } = profile as TalentProfile;

  const polaroidPortfolioImages =
    portfolioImageCategories.find((pic) => pic.name === "Polaroids")
      ?.portfolioImages || [];
  const portfolioCategoryCount = size(portfolioImageCategories || []);
  const polaroidImageCount = size(polaroidPortfolioImages);

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const isSingle = variant === "single";

  const isPublished = published === false;

  const showMultipleImages = useMemo(() => {
    return (
      vertical === TalentVertical.Photographer &&
      variant === "multi" &&
      size(portfolioImages) > 2
    );
  }, [vertical, variant, portfolioImages]);

  const talentImages = useMemo((): Asset[] => {
    if (featuredAsset || size(portfolioImages) > 0) {
      const isSingleImage = variant === "single" && featuredAsset;
      if (isSingleImage) {
        return [featuredAsset];
      }
      return map(portfolioImages, (image) => {
        return image as Asset;
      });
    }

    return [];
  }, [featuredAsset, variant, portfolioImages]);

  return (
    <>
      <FeatureWrapper>
        <FeatureOverlay
          css={{
            display: showDetails ? "block" : "none",
            borderRadius: "14px",
          }}
        >
          <Box
            css={{
              zIndex: "$300",
              position: "absolute",
              right: 10,
              top: 10,
              cursor: "pointer",
              "@bp2": { display: showDetails ? "block" : "none" },
            }}
            onClick={() => setShowDetails(false)}
          >
            <Icon variant="cross" size={12} color="white" />
          </Box>
          <Link
            to={
              (isNewDashboard && isContactOpenRoute) || isContactSelectRoute
                ? `${pathname}/${agency?.id}/${talent?.slug}`
                : isNewDashboard && !isAgenciesPathname && !isTpaManageTalent
                ? `${pathname}/${agency?.id}/${talent?.slug}`
                : `${pathname}/${talent?.slug}`
            }
            css={{
              display: "block",
              height: "100%",
              "&:hover": { textDecoration: "none" },
            }}
          >
            <Box
              css={{
                p: "$6",
                overflowY: "scroll",
                height: "80%",
                width: "100%",
                scrollbarWidth: "none",
                "-ms-overflow-style": "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {(minDayRate || maxDayRate) && (
                <Flex css={{ justifyContent: "space-between", mb: "$3" }}>
                  <Box>
                    <Text color="white" variant="mini">
                      Daily rate
                    </Text>
                    <Text color="white" variant="meta">
                      {minDayRate && `£${minDayRate}`}{" "}
                      {minDayRate && maxDayRate && "-"}{" "}
                      {maxDayRate && `£${maxDayRate}`}
                    </Text>
                  </Box>
                </Flex>
              )}
              {completedJobCount !== undefined && (
                <Box css={{ mb: "$3" }}>
                  <Text color="white" variant="mini">
                    Jobs
                  </Text>
                  <Text color="white" variant="meta">
                    {completedJobCount}
                  </Text>
                </Box>
              )}

              {groups?.length && vertical === TalentVertical.FashionModel && (
                <>
                  <Text color="white" variant="mini">
                    Category
                  </Text>
                  <Text
                    color={groups[0].manuallySet ? "turquoise" : "white"}
                    variant="meta"
                  >
                    {groups[0].name}
                  </Text>
                </>
              )}
            </Box>
          </Link>
          <AgentTalentCardOverlayNav talent={talent} />
        </FeatureOverlay>

        {isPublished && (
          <Flex
            css={{
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "$white",
              opacity: 0.7,
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "$100",
              pointerEvents: "none",
              flexDirection: "column",
              maxWidth: "480px",
            }}
          >
            <Icon variant="hiddenProfile" size={40} />
            <Text
              css={{
                [`${FeatureWrapper}:hover &`]: {
                  "@bp2": {
                    display: "none",
                  },
                },
                "@bp2": {
                  position: "absolute",
                  bottom: 5,
                },
                "@bp1": {
                  display: showDetails ? "none" : undefined,
                },
              }}
              align="center"
              variant="meta"
            >
              This creative isn&apos;t shown on Contact&apos;s booking flow. If
              you think that&apos;s incorrect get in touch!
            </Text>
          </Flex>
        )}

        {size(talentImages) ? (
          <FeatureLayout>
            <AgentTalentCardImageGrid
              images={talentImages}
              name={name}
              isSingle={isSingle}
              showMultipleImages={showMultipleImages}
              vertical={vertical}
              showingDetails={showDetails}
              onShowDetailsClick={() => {
                setShowDetails(true);
              }}
              portfolioCategoryCount={portfolioCategoryCount}
              polaroidImageCount={polaroidImageCount}
            />
          </FeatureLayout>
        ) : (
          <NoImages isSingle={isSingle} />
        )}
      </FeatureWrapper>
    </>
  );
};
