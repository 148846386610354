import React from "react";
import type * as Stitches from "@stitches/react";
import { ActionItem } from ".";
import { ExternalLink } from "src/ccl/navigation";
import { useLocationAwareURL } from "src/hooks/useLocationAwareURL";
import { iconSizes, tokens } from "src/ccl/stitches/theme";
import { TextVariants } from "src/ccl/document";

export const TalentSelectionHelpLink = ({
  onClick,
  text = "Help",
  iconSize,
  color,
  textVariant,
  css = {},
  linkCss = {},
  iconCss = {},
}: {
  onClick?: () => void;
  text?: string;
  iconSize?: keyof typeof iconSizes;
  color?: keyof typeof tokens.colors;
  textVariant?: keyof typeof TextVariants;
  css?: Stitches.CSS;
  linkCss?: Stitches.CSS;
  iconCss?: Stitches.CSS;
}) => {
  const supportUrl = useLocationAwareURL(
    "https://support.{{HOST}}/hc/en-gb/articles/360018875278",
  );
  return (
    <ExternalLink
      to={supportUrl}
      eventName="talentSelection:explanation:help"
      target="_blank"
      onClick={onClick}
      css={linkCss}
    >
      <ActionItem
        icon="information"
        text={text}
        iconSize={iconSize}
        color={color}
        textVariant={textVariant}
        css={css}
        iconCss={iconCss}
      />
    </ExternalLink>
  );
};
