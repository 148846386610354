import { filterMap } from "./array";
import { formatDate } from "./dates";
import { snakeCaseKeys } from "./snakeCaseKeys";
import {
  CreateTalentProfileInput,
  DraftPortfolioImage,
  PortfolioImage,
  PortfolioImageCategory,
  PortfolioImageInput,
  TalentVertical,
} from "src/graphql/types";
import { TalentProfileFormValues } from "src/components/dashboards/agents/talentManagement/forms/formConfigV2";

export class InvalidTalentProfileValuesError extends Error {}

export const buildVerticalData = (verticalData: Record<string, unknown>) => {
  const parsedVerticalData = snakeCaseKeys(verticalData);

  for (const d in verticalData) {
    if (!verticalData[d]) {
      delete parsedVerticalData[d];
    }
  }

  return parsedVerticalData;
};

export const parseDateOfBirth = (dateString?: string) => {
  if (!dateString) {
    return;
  }
  const [d, m, y] = dateString.split("/").map((n) => parseInt(n));
  return formatDate(new Date(y, m - 1, d), "yyyy-MM-dd");
};

const buildPortfolioImageCategorisations = (
  portfolioImage: PortfolioImage,
  portfolioCategories: PortfolioImageCategory[],
) =>
  filterMap(portfolioCategories, (category, sortWeight) => {
    if (category.portfolioImages.find(({ id }) => id === portfolioImage.id)) {
      return { name: category.name, sortWeight };
    }
  });

export const buildPortfolioImages = (
  portfolioImages: DraftPortfolioImage[],
  portfolioCategories: PortfolioImageCategory[],
): PortfolioImageInput[] =>
  filterMap(portfolioImages, (image, sortWeight) => {
    if (image.fileData) {
      return {
        fileData: image.fileData,
        sortWeight,
        categories: buildPortfolioImageCategorisations(
          image,
          portfolioCategories,
        ),
      };
    }
  });

export const buildTalentProfile = (
  talentProfileValues: TalentProfileFormValues,
  portfolioImages: DraftPortfolioImage[],
  portfolioCategories: PortfolioImageCategory[],
  vertical: TalentVertical,
): CreateTalentProfileInput => {
  delete talentProfileValues.portfolioImages;
  delete talentProfileValues.vertical;
  const {
    verticalData,
    primaryLocation,
    dateOfBirth,
    additionalLocations,
    pastClients,
    minDayRate,
    maxDayRate,
    website,
    instagramUsername,
    gender,
  } = talentProfileValues;
  const formattedDob = parseDateOfBirth(dateOfBirth);

  const parsedVerticalData = buildVerticalData(verticalData);
  const parsedPortfolioImages = buildPortfolioImages(
    portfolioImages,
    portfolioCategories,
  );
  if (!gender) {
    // this is pretty much impossible, all the forms are validated before this function is called
    // so this is just to placate typescript
    throw new InvalidTalentProfileValuesError();
  }

  return {
    ...talentProfileValues,
    agencyId: undefined,
    gender: gender,
    vertical: vertical,
    portfolioImages: parsedPortfolioImages,
    primaryLocation: primaryLocation,
    additionalLocations: additionalLocations,
    dateOfBirth: formattedDob,
    pastClients: pastClients,
    verticalData: parsedVerticalData,
    minDayRate: minDayRate || undefined,
    maxDayRate: maxDayRate || undefined,
    website: website || undefined,
    instagramUsername: instagramUsername || undefined,
  };
};
