import { gql } from "@apollo/client";

export const TALENT_DASHBOARD_JOBS_QUERY = gql`
  query TalentDashboardJobs {
    me {
      profile {
        ... on TalentProfile {
          vertical
        }
      }
      jobs {
        edges {
          node {
            id
            name
            state
            virtual
            vertical
            usage
            deliverables
            location
            city
            country
            fullAddress
            startDate
            endDate
            budget
            description
            stateForCurrentUser
            shortlistCount
            talentRequired
            jobLength
            jobVerticals {
              vertical
              talentRequired
              shortlistCount
              budget
              deliverables
              jobLength
            }
            booker {
              id
              name
              phoneNumber
            }
            deckFiles {
              url
              filename
            }
          }
        }
      }
    }
  }
`;
