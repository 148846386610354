import { gql } from "@apollo/client";

export const AGENT_DASHBOARD_BOOKER_SEARCH_QUERY = gql`
  query BookerSearchQuery(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $nameOrEmail: String
    $excludedIds: [ID!]
    $kind: String
    $sortField: String
    $sortDirection: SortDirection
    $companyId: ID
  ) {
    bookers(
      after: $after
      before: $before
      first: $first
      last: $last
      nameOrEmail: $nameOrEmail
      excludedIds: $excludedIds
      kind: $kind
      sortField: $sortField
      sortDirection: $sortDirection
      companyId: $companyId
    ) @connection(key: "bookers", filter: ["sortField", "sortDirection"]) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          slug
          createdAt
          jobs {
            totalCount
          }
          profile {
            ... on BookerProfile {
              id
              kind
            }
          }
        }
      }
    }
  }
`;
