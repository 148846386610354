import { gql } from "@apollo/client";

export const RESUBMIT_JOB_MUTATION = gql`
  mutation ResubmitJob(
    $jobId: ID!
    $jobDetails: UpdateJobDetailsInput
    $jobVerticals: [UpdateJobVerticalInput!]
    $talentIds: [ID!]
  ) {
    resubmitJob(
      jobId: $jobId
      jobDetails: $jobDetails
      jobVerticals: $jobVerticals
      talentIds: $talentIds
    ) {
      id
      name
      startDate
      endDate
      location
      jobVerticals {
        budget
        jobLength
        talentRequired
        deliverables
        vertical
      }
    }
  }
`;
