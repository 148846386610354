import { gql } from "@apollo/client";

export const CREATE_AGENT_PROFILE_MUTATION = gql`
  mutation CreateAgentProfile(
    $role: AgentProfileRole!
    $agency: CreateAgencyInput!
  ) {
    createAgentProfile(role: $role, agency: $agency) {
      id
      kinds
      name
      profile {
        ... on AgentProfile {
          id
          role
        }
      }
    }
  }
`;
