import React from "react";
import { useLocation } from "react-router-dom";
import {
  TabbedMenu,
  TabbedMenuExternalItem,
  TabbedMenuItem,
  TabGroup,
} from "src/ccl/blocks";
import { Box, LegacyContainer } from "src/ccl/layout";
import { FullHeaderPage } from "src/ccl/templates";
import { useStoreModel, useVerticalConfigurations } from "src/hooks";
import { Dropdown, ExternalLink, SubNav } from "src/ccl/navigation";
import { Icon } from "src/ccl/document";
import {
  renderTalentManagementSubHeaders,
  TPAHeader,
} from "src/components/dashboards/agents";
import { TalentVertical } from "src/graphql/types";
import { adminDashboardUrl } from "src/config";

export const MoreDropdown = () => (
  <Dropdown
    launcher={
      <Icon
        variant="moreDots"
        size={16}
        color="white"
        css={{ display: "block", margin: "$4" }}
      />
    }
    position="right"
    bodyCss={{
      width: "100vw",
      padding: "$6",
    }}
  >
    <ExternalLink
      to={`${adminDashboardUrl}/email_logs`}
      eventName="agencyDashboard:admin:emailLogs"
      css={{
        textDecoration: "none",
        color: "$grey6",
        display: "block",
        mb: "$3",
        fontSize: "$14",
      }}
    >
      Email logs
    </ExternalLink>
    <ExternalLink
      to={`${adminDashboardUrl}/password-resets`}
      eventName="agencyDashboard:admin:passwordReset"
      css={{
        textDecoration: "none",
        color: "$grey6",
        display: "block",
        mb: "$3",
        fontSize: "$14",
      }}
    >
      Password reset
    </ExternalLink>
    <ExternalLink
      to={`${adminDashboardUrl}/audit_logs`}
      eventName="agencyDashboard:admin:changelog"
      css={{
        textDecoration: "none",
        color: "$grey6",
        display: "block",
        fontSize: "$14",
      }}
    >
      Changelog
    </ExternalLink>
  </Dropdown>
);

export const AgentDashboardLayout = ({
  vertical,
  fullPageHeaderChildren,
  context,
  children,
}: {
  vertical?: TalentVertical;
  fullPageHeaderChildren?: React.ReactNode;
  context?: "agentEditShortlist";
  children?: React.ReactNode;
}) => {
  const { isFirstPartyAgent } = useStoreModel("currentUser");

  const location = useLocation();
  const talentManagementSubHeader =
    isFirstPartyAgent && location.pathname.includes("/dashboard/agent/talent");

  const { configurations: verticalConfigs } = useVerticalConfigurations();

  const isEditShortlist = context === "agentEditShortlist" && isFirstPartyAgent;

  return (
    <FullHeaderPage
      fullPageHeaderChildren={fullPageHeaderChildren}
      css={
        !isEditShortlist
          ? undefined
          : {
              position: "sticky",
              top: "-1px",
              zIndex: "$500",
              backgroundColor: "$white",
              "@bp4": { position: "relative" },
            }
      }
    >
      <TabbedMenu>
        <LegacyContainer
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <TabGroup>
            {isFirstPartyAgent && (
              <>
                <TabbedMenuItem
                  url="/dashboard/agent/jobs"
                  match={/\/dashboard\/agent\/jobs(?!\/new)/}
                  text="Jobs"
                />
                <TabbedMenuItem
                  url="/dashboard/agent/talent?v=models"
                  match={/\/dashboard\/agent\/talent(?!\/new)/}
                  text="Talent"
                />
                <TabbedMenuItem
                  url="/dashboard/agent/agencies"
                  match={/\/dashboard\/agent\/agencies(?!\/new)/}
                  text="Agencies"
                />

                <TabbedMenuItem
                  url="/dashboard/agent/payments"
                  match={/\/dashboard\/agent\/payments/}
                  text="Payments"
                  condensedText="Pay"
                />

                <TabGroup
                  css={{ display: "none", "@bp2": { display: "flex" } }}
                >
                  <TabbedMenuItem
                    url="/dashboard/agent/packages"
                    match={/\/dashboard\/agent\/packages/}
                    text="Packages"
                  />

                  <TabbedMenuItem
                    url="/dashboard/agent/clients"
                    match={/\/dashboard\/agent\/clients(?!\/new)/}
                    text="Clients"
                    icon="user"
                  />
                </TabGroup>
              </>
            )}
          </TabGroup>

          {isFirstPartyAgent && (
            <>
              <TabGroup css={{ display: "flex", "@bp2": { display: "none" } }}>
                <TabbedMenuItem
                  url="/dashboard/agent/reviews"
                  match={/\/dashboard\/agent\/reviews/}
                  text="Reviews"
                />
                <TabbedMenuItem
                  url="/dashboard/agent/packages"
                  match={/\/dashboard\/agent\/packages/}
                  text="Packages"
                  icon="packageCompleted"
                />

                <TabbedMenuItem
                  url="/dashboard/agent/clients"
                  match={/\/dashboard\/agent\/clients(?!\/new)/}
                  text="Clients"
                  icon="user"
                />
                <MoreDropdown />
              </TabGroup>

              <TabGroup css={{ display: "none", "@bp2": { display: "flex" } }}>
                <TabbedMenuItem
                  url="/dashboard/agent/reviews"
                  match={/\/dashboard\/agent\/reviews/}
                  text="Reviews"
                />

                <TabbedMenuExternalItem
                  to={`${adminDashboardUrl}`}
                  text="Admin panel"
                  variant="link"
                />
                <TabbedMenuExternalItem
                  to={`${adminDashboardUrl}/password_resets`}
                  text="Password reset"
                  variant="link"
                  eventName="agencyDashboard:admin:passwordReset"
                />
                <TabbedMenuExternalItem
                  to={`${adminDashboardUrl}/audit_logs`}
                  text="Changelog"
                  variant="link"
                  eventName="agencyDashboard:admin:changelog"
                />
              </TabGroup>
            </>
          )}
        </LegacyContainer>
      </TabbedMenu>

      <Box css={{ minHeight: "50vh" }}>
        {talentManagementSubHeader &&
          verticalConfigs &&
          verticalConfigs.length >= 2 && (
            <SubNav>
              {renderTalentManagementSubHeaders(
                verticalConfigs.map((config) => config.label),
                vertical,
              )}
            </SubNav>
          )}
        {!isFirstPartyAgent && (
          <SubNav
            flexCss={{
              backgroundColor: "$white",
              height: "39px",
              "@bp2": {
                backgroundColor: "$grey1",
                height: "60px",
              },
            }}
            css={{
              backgroundColor: "$white",
              border: "1px solid $grey2",
              "@bp2": {
                backgroundColor: "$grey1",
                border: "none",
              },
            }}
          >
            <TPAHeader />
          </SubNav>
        )}
        {children}
      </Box>
    </FullHeaderPage>
  );
};
