import React from "react";
import { head } from "lodash";
import { Box, Flex } from "src/ccl/layout";
import { Asset, TalentVertical } from "src/graphql/types";
import { styled } from "src/ccl/stitches/config";
import { AssetImage, Text, InfoBanner } from "src/ccl/document";
import { Pill } from "src/ccl/blocks";

const Overlay = styled(Box, {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
});

const ImageWrapper = styled(Box, {
  position: "relative",
  gridColumn: "1/4",
  gridRow: "1/4",
});

const ImageOverlay = styled(Overlay, {
  padding: "$3",
});

interface AgentTalentCardImageGridProps {
  name: string;
  showMultipleImages: boolean;
  isSingle: boolean;
  vertical: TalentVertical;
  images: Asset[];
  portfolioCategoryCount: number;
  polaroidImageCount: number;
  showingDetails: boolean;
  onShowDetailsClick: () => void;
}

export const AgentTalentCardImageGrid = ({
  name,
  showMultipleImages,
  isSingle,
  images,
  vertical,
  portfolioCategoryCount,
  polaroidImageCount,
  showingDetails,
  onShowDetailsClick,
}: AgentTalentCardImageGridProps) => {
  const featuredImage = head(images) as Asset;

  return (
    <>
      <ImageWrapper
        css={{
          gridColumn: showMultipleImages ? "1/3" : undefined,
          gridRow: showMultipleImages ? "1/3" : undefined,
        }}
      >
        <AssetImage
          asset={featuredImage}
          size={{
            "@initial": {
              aspectRatio: isSingle || showMultipleImages ? 1.12 : 1,
            },
            "@bp2": {
              aspectRatio: isSingle || showMultipleImages ? 1.12 : 0.75,
            },
            "@bp5": {
              aspectRatio: isSingle || showMultipleImages ? 1.28 : 0.85,
            },
          }}
          alt={`A picture of ${name}`}
          containerCss={{
            borderRadius: showMultipleImages ? "14px 0px 0px 14px" : "14px",
          }}
        />
        <ImageOverlay>
          <Flex
            css={{
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              {vertical === TalentVertical.FashionModel ? (
                <>
                  <Pill variant="rounded">
                    <InfoBanner
                      icon="cameraPhoto"
                      iconSize={14}
                      variant="tightCentered"
                    >
                      <Text variant="meta">{portfolioCategoryCount}</Text>
                    </InfoBanner>
                  </Pill>
                  {polaroidImageCount > 0 && (
                    <Pill variant="rounded">
                      <InfoBanner
                        icon="polaroids"
                        iconSize={14}
                        variant="tightCentered"
                      >
                        <Text variant="meta">{polaroidImageCount}</Text>
                      </InfoBanner>
                    </Pill>
                  )}
                </>
              ) : (
                <Pill variant="rounded">
                  <InfoBanner
                    icon="portfolio"
                    iconSize={14}
                    variant="tightCentered"
                  >
                    <Text variant="meta">{images.length}</Text>
                  </InfoBanner>
                </Pill>
              )}
            </Box>

            {!showingDetails && (
              <Box
                css={{
                  "@bp2": {
                    display: "none",
                  },
                }}
              >
                <Pill
                  variant="rounded"
                  onClick={onShowDetailsClick}
                  css={{ cursor: "pointer" }}
                >
                  <InfoBanner
                    icon="moreDots"
                    iconSize={14}
                    variant="tightCentered"
                  >
                    <Text variant="meta">More details</Text>
                  </InfoBanner>
                </Pill>
              </Box>
            )}
          </Flex>
        </ImageOverlay>
      </ImageWrapper>

      {showMultipleImages && (
        <>
          {images.slice(1, 3).map((image, index) => (
            <AssetImage
              key={index}
              alt={`A picture of ${name}`}
              asset={image}
              size={{
                "@initial": {
                  width: 500,
                  aspectRatio: 1.12,
                },
                "@bp5": {
                  aspectRatio: 1.28,
                },
              }}
              containerCss={{
                borderRadius:
                  index === 0 ? "0px 14px 0px 0px" : "0px  0px 14px 0px",
              }}
            />
          ))}
        </>
      )}
    </>
  );
};
