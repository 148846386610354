import { useEffect, useState } from "react";
import { getLocalStorageItem, updateLocalStorageItem } from "src/utils/storage";

export type AvailableFeature = "agency_commission_setting" | "bloom_payments";

const localStorageKey = "seenFeatures";

const hasSeenFeature = (feature: AvailableFeature) => {
  const seenFeatures = getLocalStorageItem(localStorageKey);
  return !!seenFeatures?.includes(feature);
};

const addSeenFeature = (feature: AvailableFeature) => {
  const seenFeatures = getLocalStorageItem(localStorageKey);
  const updatedFeatures = seenFeatures
    ? [seenFeatures, feature].join(":")
    : feature;
  updateLocalStorageItem(localStorageKey, updatedFeatures);
};

export const useSeenFeatureState = (
  feature: AvailableFeature,
): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!hasSeenFeature(feature)) {
      setTimeout(() => setIsOpen(true), 3000);
    }
  }, []);

  return [
    isOpen,
    () => {
      addSeenFeature(feature);
      setIsOpen(false);
    },
  ];
};
