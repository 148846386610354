import React from "react";
import type * as Stitches from "@stitches/react";
import { Link, ExternalLink, ExternalLinkProps } from "src/ccl/navigation";
import { AvailableIcon, Icon, Text } from "src/ccl/document";
import { mergeCss } from "src/ccl/stitches";

interface SideNavigationMiniItemProps extends Partial<ExternalLinkProps> {
  to: string;
  text: string;
  icon?: AvailableIcon;
  linkType?: "internal" | "external";
  css?: Stitches.CSS;
}

export const SideNavigationMiniItem = ({
  to,
  text,
  icon,
  linkType = "internal",
  css = {},
  ...props
}: SideNavigationMiniItemProps) => {
  const ParentComponent = (
    linkType === "external" ? ExternalLink : Link
  ) as React.ElementType;

  return (
    <ParentComponent
      to={to}
      css={mergeCss(
        {
          alignItems: "center",
          display: "flex",
          my: "$3",
        },
        css,
      )}
      {...props}
    >
      {icon && <Icon variant={icon} size={14} css={{ mr: "$3" }} />}
      <Text as="span" variant="meta">
        {text}
      </Text>
    </ParentComponent>
  );
};
