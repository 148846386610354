import { gql } from "@apollo/client";
import { PACKAGE_DETAILS_FRAGMENT } from "src/graphql/fragments";

export const CONVERT_PACKAGE_TO_JOB_MUTATION = gql`
  mutation ConvertPackageToJob($packageId: ID!) {
    convertPackageToJob(packageId: $packageId) {
      ...PackageDetails
    }
  }
  ${PACKAGE_DETAILS_FRAGMENT}
`;
