import React from "react";
import { Icon, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";

interface EmptyPaymentStateProps {
  title: React.ReactNode;
}

export const EmptyPaymentState = ({ title }: EmptyPaymentStateProps) => (
  <>
    {title}

    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        mt: "$16",
        spaceY: "$5",
      }}
    >
      <Icon variant="moneyBudget" size={60} color="grey6" />
      <Text color="grey6">No payments just yet</Text>
    </Flex>
  </>
);
