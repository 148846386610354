import { gql } from "@apollo/client";

export const ADD_SAVED_TALENT_TO_COLLECTIONS = gql`
  mutation AddSavedTalentToCollections($talentId: ID!, $collectionIds: [ID!]!) {
    addSavedTalentToCollections(
      talentId: $talentId
      collectionIds: $collectionIds
    ) {
      id
      talent {
        featuredAsset {
          mediaUrl
        }
      }
    }
  }
`;
