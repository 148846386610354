import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Field, FormikInput, Select } from "src/ccl/data-entry";
import { Text } from "src/ccl/document";
import { Button } from "src/ccl/navigation";
import { Box, Flex, Grid } from "src/ccl/layout";
import { ValidationBlock } from "src/ccl/feedback";

const roles = ["Booker", "Creative"];

const initialValues = { name: "", email: "" };

export const SubscriptionForm = () => {
  const [role, setRole] = useState(roles[0].toLowerCase());
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: Record<string, string> = {};

        if (values.name.trim() === "") {
          errors.name = "Required";
        }
        if (values.email.trim() === "") {
          errors.email = "Required";
        }

        return errors;
      }}
      onSubmit={(values) => {
        if (!window._cio || submittedSuccessfully) {
          return;
        }
        window._cio.identify({
          id: values.email,
          role: role,
          ...values,
        });
        setSubmittedSuccessfully(true);
      }}
    >
      {({ errors }) => (
        <Form>
          <Text
            variant={{ "@initial": "h2", "@bp2": "h1" }}
            color="white"
            css={{ mb: "$8", "@bp2": { mb: "$10" } }}
          >
            Stay in the loop
          </Text>

          <Grid
            css={{
              gridColumns: "1fr",
              gridGap: "$8",
              "@bp2": { gridColumns: "1fr 1fr" },
              "@bp3": { gridColumns: "1fr 1fr 1fr 1fr" },
            }}
          >
            <Field
              label="Name"
              color="white"
              css={{ mb: "$0", alignSelf: "flex-end" }}
              wrapperComponent={React.Fragment}
            >
              <FormikInput
                type="text"
                name="name"
                placeholder="e.g. Sarah Edwards"
              />
            </Field>

            <Field
              label="Email"
              color="white"
              css={{ mb: "$0", alignSelf: "flex-end" }}
              wrapperComponent={React.Fragment}
            >
              <FormikInput
                type="text"
                name="email"
                placeholder="e.g. sarah@gmail.com"
              />
            </Field>

            <Field
              label="Role"
              color="white"
              css={{ mb: "$0", alignSelf: "flex-end" }}
              wrapperComponent={React.Fragment}
            >
              <Select
                name="role"
                value={role}
                onChange={(e: React.SyntheticEvent<HTMLSelectElement>) => {
                  setRole(e.currentTarget.value);
                }}
              >
                {roles.map((role, i) => (
                  <option value={role.toLowerCase()} key={i}>
                    {role}
                  </option>
                ))}
              </Select>
            </Field>

            <Flex css={{ alignSelf: "end" }}>
              <Button
                type="submit"
                variant="primary"
                css={{ border: "2px $white solid", width: "100%" }}
              >
                Subscribe
              </Button>
            </Flex>
          </Grid>

          <Box css={{ mt: "$5" }}>
            {Object.keys(errors).length > 0 && (
              <ValidationBlock
                variant="error"
                title="Oops, something went wrong. Have you filled all the fields?"
              />
            )}

            {submittedSuccessfully && (
              <ValidationBlock
                variant="success"
                title="Thanks, you’ve been added to our mailing list."
              />
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
