import React from "react";
import { CSS } from "@stitches/react";
import { Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";

export const TableHeader = ({
  children,
  css = {},
}: {
  children: React.ReactNode;
  css?: CSS;
}) => (
  <Flex
    css={mergeCss(
      {
        background: "#FAFAFA",
        padding: "8px 10px",
        borderRadius: "$lg",
        display: "none",
        "@lg": {
          display: "flex",
        },
      },
      css,
    )}
  >
    {children}
  </Flex>
);
