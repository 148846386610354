import { useCallback, useState } from "react";
import { styled } from "src/ccl";
import { RangePicker } from "src/ccl/data-entry";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import {
  BrandFilterOptions,
  DateRangeOption,
  JobStateOptions,
  SearchFilter,
  VerticalFilterOptions,
} from "src/components/filtering";
import { OwnerOptions } from "src/components/filtering/jobFilters/OwnerFilter";
import {
  formatRange,
  useDateRangeFilterState,
} from "src/components/filtering/jobFilters/useDateRangeFilterState";
import {
  DateRangeInput,
  JobDateRangeOption,
  JobState,
  TalentVertical,
} from "src/graphql/types";

interface Owner {
  label: string;
  value: string;
}

interface JobFilterModalProps extends ModalProps {
  view: string;
  jobCount?: number;
  onReset: () => void;
  nameOrBrand: string;
  setNameOrBrand: (value: string) => void;
  verticals: TalentVertical[];
  setVerticals: (verticals: TalentVertical[]) => void;
  availableJobStates: JobState[];
  states: JobState[];
  setStates: (states: JobState[]) => void;
  owner: Owner | undefined;
  setOwner: (value: string) => void;
  ownerOptions: Owner[];
  onDateRangeChange: (dateRange?: DateRangeInput) => void;
  brands?: string[];
  setBrands?: (brands: string[]) => void;
  brandOptions?: Array<string>;
  isBooker?: boolean;
}

const Divider = styled("hr", {
  width: "100%",
  height: "1px",
  border: "none",
  backgroundColor: "$grey1",
});

export const FilterContainer = ({
  label,
  children,
}: {
  label: string;
  children: JSX.Element;
}) => {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "18px",
      }}
    >
      <Text variant="nh4">{label}</Text>
      {children}
    </Flex>
  );
};

export const JobFilterModal = ({
  isOpen,
  onClose,
  view,
  jobCount,
  onReset,
  nameOrBrand,
  setNameOrBrand,
  verticals,
  setVerticals,
  availableJobStates,
  states,
  setStates,
  owner,
  setOwner,
  ownerOptions,
  onDateRangeChange,
  brands = [],
  setBrands = () => {},
  brandOptions = [],
  isBooker = false,
}: JobFilterModalProps) => {
  const [showAllBrands, setShowAllBrands] = useState(false);
  const [selected, buildRangeOptionProps] =
    useDateRangeFilterState(onDateRangeChange);
  const setCustomRange = useCallback(
    (range: DateRangeInput) => onDateRangeChange(formatRange(range)),
    [],
  );

  return (
    <Modal
      title="Filters"
      titleVariant="nh3"
      titleCss={{
        marginBottom: 0,
        fontSize: "$22 !important",
      }}
      showCloseButton
      withBodyPadding={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex
        css={{
          pt: "$5",
          px: "$5",
        }}
      >
        <Divider />
      </Flex>
      <Flex
        data-test-id="JobTypeFilterModal"
        css={{
          flexDirection: "column",
          gap: "32px",
          padding: "$9 $5",
          maxHeight: "595px",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            backgroundColor: "$white",
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "$grey3",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "$grey2",
            borderRadius: "5px",
            height: "81px",
          },
        }}
      >
        <FilterContainer label="Name">
          <SearchFilter
            placeholder="Search by name"
            search={nameOrBrand}
            onChange={setNameOrBrand}
            withinModal={true}
            showClearButton
          />
        </FilterContainer>
        <FilterContainer label="Creative type">
          <Flex
            css={{
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <VerticalFilterOptions
              variant="pill"
              verticals={verticals}
              onChange={setVerticals}
            />
          </Flex>
        </FilterContainer>
        {view === "simple" && (
          <FilterContainer label="Status">
            <Flex
              css={{
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <JobStateOptions
                variant="pill"
                availableStates={availableJobStates}
                states={states}
                onChange={setStates}
              />
            </Flex>
          </FilterContainer>
        )}
        {!isBooker && (
          <FilterContainer label="Owner">
            <Flex
              css={{
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <OwnerOptions
                variant="pill"
                owner={owner}
                setOwner={setOwner}
                ownerOptions={ownerOptions}
              />
            </Flex>
          </FilterContainer>
        )}

        {isBooker && (
          <FilterContainer label="Brand">
            <>
              <Flex
                css={{
                  gap: "8px",
                  flexWrap: "wrap",
                }}
              >
                <BrandFilterOptions
                  variant="pill"
                  brands={brands}
                  brandOptions={
                    showAllBrands ? brandOptions : brandOptions.slice(0, 10)
                  }
                  onChange={setBrands}
                />
                {brandOptions.length > 10 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowAllBrands((prev) => !prev);
                    }}
                  >
                    {showAllBrands ? "Hide" : "Show all"}
                  </Button>
                )}
              </Flex>
            </>
          </FilterContainer>
        )}
        <FilterContainer label="Date range">
          <>
            <Flex
              css={{
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <DateRangeOption
                label="This Week"
                {...buildRangeOptionProps(JobDateRangeOption.ThisWeek)}
              />
              <DateRangeOption
                label="Last 7 Days"
                {...buildRangeOptionProps(JobDateRangeOption.LastSevenDays)}
              />
              <DateRangeOption
                label="This Month"
                {...buildRangeOptionProps(JobDateRangeOption.ThisMonth)}
              />
              <DateRangeOption
                label="Last 30 Days"
                {...buildRangeOptionProps(JobDateRangeOption.LastThirtyDays)}
              />
              <DateRangeOption
                label="Last Month"
                {...buildRangeOptionProps(JobDateRangeOption.LastMonth)}
              />
              <DateRangeOption
                label="Custom"
                {...buildRangeOptionProps(JobDateRangeOption.Custom)}
              />
            </Flex>
            {selected === JobDateRangeOption.Custom && (
              <RangePicker onChange={setCustomRange} />
            )}
          </>
        </FilterContainer>
      </Flex>
      <Flex
        css={{
          flexDirection: "column",
          gap: "25px",
          padding: "$0 $5 $5 $5",
        }}
      >
        <Flex>
          <Divider />
        </Flex>
        <Flex
          css={{
            justifyContent: "space-between",
            flexDirection: "column",
            gap: "18px",
            "@sm": {
              flexDirection: "row",
            },
          }}
        >
          <Button
            data-test-id="ClearAllButton"
            variant="secondaryCta"
            onClick={onReset}
          >
            Clear All
          </Button>
          <Button
            data-test-id="ShowJobButton"
            variant="primaryCta"
            onClick={onClose}
          >
            Show {jobCount} jobs
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
