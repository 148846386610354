import { gql } from "@apollo/client";

export const PAYMENT_DETAILS_FRAGMENT = gql`
  fragment PaymentDetails on OutboundPayment {
    id
    state
    jobTalent {
      id
      talent {
        email
      }
    }
  }
`;
