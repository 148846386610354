import React from "react";
import { Checkbox } from "src/ccl/data-entry";
import { Grid, Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";

interface LegacyProfileRadioSelectProps {
  option: {
    value: string;
    description?: string;
    colour?: string;
    label?: string;
  };
  selectedOption: string;
  setSelectedOption: (val: string) => void;
  name: string;
}

export const LegacyProfileRadioSelect = ({
  option,
  selectedOption,
  setSelectedOption,
  name,
}: LegacyProfileRadioSelectProps) => (
  <Grid
    css={{
      gridColumns: "47px auto",
      columnGap: "$2",
      mb: "$4",
      alignItems: "center",
    }}
  >
    <Checkbox
      css={{
        border: "none",
        backgroundColor: `#${option.colour}`,
        "&:focus": {
          border: "2px solid $grey6",
        },
        "&:checked": {
          backgroundColor: `#${option.colour}`,
          border: "2px solid $grey6",
        },
      }}
      checked={selectedOption === option.value}
      onChange={() => setSelectedOption(option.value)}
      id={`${name}-${option.value}`}
    />

    <Box as="label" htmlFor={option.value} css={{ cursor: "pointer" }}>
      <Text css={{ ml: "$4" }}>{option.description || option.label}</Text>
    </Box>
  </Grid>
);
