import React from "react";
import { ApolloError } from "@apollo/client";
import { Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";

export const ReviewAdvice = ({ error }: { error?: ApolloError }) => (
  <Box
    css={{
      backgroundColor: error
        ? "rgba(221, 32, 20, 0.1)"
        : "rgba(23, 197, 61, 0.1)",
      borderRadius: "4px",
      px: "$5",
      py: "$4",
    }}
  >
    <Box>
      <Text variant="meta" css={{ color: error ? "red" : "$green" }}>
        {error
          ? error.message
          : `Feedback saved. Thank you for reviewing our creatives!`}
      </Text>
    </Box>
  </Box>
);
