import { ActionOn, actionOn } from "easy-peasy";
import { CurrentUserModel } from "./currentUser";

export interface CustomerIOModel {
  identifyCustomerIOUser: ActionOn<CurrentUserModel>;
}

export const customerIOModel: CustomerIOModel = {
  identifyCustomerIOUser: actionOn(
    (actions) => [actions.logIn, actions.setInitialised],
    (state) => {
      if (!window._cio || !state.me) {
        return;
      }

      const { email, id } = state.me;

      window._cio.identify({ email, id });
    },
  ),
};
