import { includes, isNil } from "lodash";

export const basePathname = (pathname: string, agencyId?: string) => {
  if (includes(pathname, "/open")) {
    return `/dashboard/agent/talent/open/${agencyId}`;
  } else if (includes(pathname, "/select")) {
    return `/dashboard/agent/talent/select/${agencyId}`;
  }

  if (!isNil(agencyId)) {
    return `/dashboard/agent/agencies/${agencyId}/talent`;
  }

  return `/dashboard/agent/talent`;
};
