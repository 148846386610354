import React from "react";
import type * as Stitches from "@stitches/react";
import { Link } from "src/ccl/navigation";
import { Box } from "src/ccl/layout";

interface GoBackProps {
  goBackTo: string | (() => void | Promise<void>);
  css?: Stitches.CSS;
  children?: React.ReactNode;
}

export const GoBack = ({ goBackTo, children, css = {} }: GoBackProps) =>
  typeof goBackTo === "string" ? (
    <Link to={goBackTo} css={css} data-test-id="BackIcon">
      {children}
    </Link>
  ) : (
    <Box onClick={goBackTo} css={css} data-test-id="BackIcon">
      {children}
    </Box>
  );
