import React from "react";
import type * as Stitches from "@stitches/react";
import splatter1x from "./splatter@1x.png";
import splatter2x from "./splatter@2x.png";
import { mergeCss, styled } from "src/ccl/stitches";
import { Box } from "src/ccl/layout";

const StyledImg = styled("img", {
  position: "absolute",
  top: 0,
  pointerEvents: "none",
});

const TopRightImg = styled(StyledImg, {
  display: "none",
  pointerEvents: "none",
  right: 0,
  transform: "scaleX(-1)",
  "@bp2": {
    display: "block",
  },
});

export interface SplatteredCornerContainerProps {
  css?: Stitches.CSS;
  children?: React.ReactNode;
}

export const SplatteredCornerContainer = ({
  children,
  css = {},
}: SplatteredCornerContainerProps) => (
  <Box css={mergeCss({ position: "relative" }, css)}>
    <StyledImg
      src={splatter1x}
      srcSet={`${splatter1x}, ${splatter2x} 2x`}
      aria-hidden="true"
      alt=""
      css={{
        left: 0,
      }}
    />

    <TopRightImg
      src={splatter1x}
      srcSet={`${splatter1x}, ${splatter2x} 2x`}
      aria-hidden="true"
      alt=""
      css={{
        position: "absolute",
        top: 0,
        right: 0,
        transform: "scaleX(-1)",
      }}
    />

    {children}
  </Box>
);
