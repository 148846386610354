import React, { useState } from "react";
import { ForYouDropdown } from "./ForYouDropdown";
import { ProfileDropdown } from "./ProfileDropdown";
import { LegacyNavigationPane } from "src/ccl/blocks/navigationPane";
import { Icon, Logo } from "src/ccl/document";
import {
  HeaderLink,
  SupportLauncher,
  Link,
  LinkButton,
} from "src/ccl/navigation";
import { sendToAmplitude } from "src/utils/analytics";
import { UserKindContext } from "src/hooks";
import { styled } from "src/ccl/stitches";
import { Box } from "src/ccl/layout";

const Wrapper = styled("header", {
  mx: "auto",
  py: "$4",
  position: "relative",
  zIndex: "$500",

  "@bp2": { p: "$7" },
  "@bp5": { px: "$8", py: "$12" },
});

const HeaderContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  overflow: "hidden",
  px: "$6",

  "@bp2": {
    px: 0,
    marginBottom: "$0",
    overflow: "visible",
  },
});

const TopLinkContainer = styled("div", {
  display: "flex",
  alignItems: "center",

  "@bp2": {
    minHeight: "$avatar",
  },
});

const DesktopHeaderLink = styled(HeaderLink, {
  display: "none",

  "@bp2": {
    display: "block",
  },
});

const MobileNavigationPaneLauncher = styled("a", {
  display: "flex",
  marginLeft: "$5",
  cursor: "pointer",

  "@bp2": {
    display: "none",
  },
});

interface LegacyHeaderProps {
  context: UserKindContext;
  avatarImageUrl?: string;
  avatarInitials?: string;
  userName?: string;
  userEmail?: string;
  onSignOut?: () => void;
  onMouseEnter?: () => void;
  fullPageHeaderChildren?: React.ReactNode;
}

export const LegacyHeader = ({
  context,
  avatarImageUrl,
  avatarInitials,
  userName,
  userEmail,
  onSignOut,
  onMouseEnter,
  fullPageHeaderChildren,
}: LegacyHeaderProps) => {
  const [navigationPaneOpen, setNavigationPaneOpen] = useState(false);

  return (
    <>
      <LegacyNavigationPane
        open={navigationPaneOpen}
        onClose={() => setNavigationPaneOpen(false)}
        onSignOut={() => {
          onSignOut && onSignOut();
          setNavigationPaneOpen(false);
        }}
        userName={userName}
        userEmail={userEmail}
        context={context}
      />
      <Wrapper>
        <HeaderContainer>
          <Link
            data-test-id="HeaderLogo"
            css={{ display: "none", "@bp2": { display: "block" } }}
            to={context === "signedOut" ? "/" : "/dashboard"}
            onClick={() =>
              sendToAmplitude("top nav - clicks contact home button")
            }
          >
            <Logo variant="full" />
          </Link>

          <Link
            data-test-id="HeaderMark"
            css={{ display: "block", "@bp2": { display: "none" } }}
            to={context === "signedOut" ? "/" : `/dashboard`}
            onClick={() =>
              sendToAmplitude("top nav - clicks contact home button")
            }
          >
            <Logo variant="mark" />
          </Link>

          <TopLinkContainer>
            <ForYouDropdown context={context} />

            {context === "signedOut" && (
              <DesktopHeaderLink
                data-test-id="HeaderPodcast"
                css={{
                  display: "none",
                  "@bp2": { display: "flex", alignItems: "center" },
                }}
                to="/creative-paths"
              >
                <Icon
                  variant="creativePathsVertical"
                  size={16}
                  css={{
                    mr: "$2",
                    circle: {
                      fill: "$black",
                    },
                  }}
                />
                Podcast
              </DesktopHeaderLink>
            )}
            <SupportLauncher
              data-test-id="HeaderNeedHelp"
              longContent="Need help?"
              color="black"
              iconSize={16}
              css={{
                display: "none",
                marginRight: "$11",
                "@bp2": { display: "block" },
                "@bp3": { display: "none" },
              }}
              longContentCSS={{
                marginRight: "$11",
              }}
              iconCss={{
                "@bp2": { display: "block" },
              }}
            />

            {context === "signedOut" && (
              <DesktopHeaderLink
                data-test-id="HeaderSignIn"
                to="/sign-in"
                css={{
                  display: "flex",
                  alignItems: "center",
                  mr: "$5",
                  "@bp2": { mr: "$9" },
                }}
              >
                <Icon
                  variant="user"
                  size={16}
                  css={{
                    display: "none",
                    "@bp2": { display: "block", mr: "$2" },
                  }}
                />
                Sign in
              </DesktopHeaderLink>
            )}
            {fullPageHeaderChildren ? (
              <> {fullPageHeaderChildren} </>
            ) : (
              <>
                <LinkButton
                  data-test-id="HeaderDashboardCTA"
                  variant="header"
                  to={
                    context === "client" || context === "signedOut"
                      ? "/jobs/new"
                      : "/dashboard"
                  }
                  onClick={() =>
                    (context === "client" || context === "signedOut") &&
                    sendToAmplitude("top nav - clicks book talent")
                  }
                  onMouseEnter={() =>
                    (context === "client" || context === "signedOut") &&
                    onMouseEnter &&
                    onMouseEnter()
                  }
                >
                  {context === "client" || context === "signedOut"
                    ? "Book Creatives"
                    : "Dashboard"}
                  <Icon variant="chevronRight" size={12} css={{ ml: "$4" }} />
                </LinkButton>

                <MobileNavigationPaneLauncher
                  data-test-id="LegacyHeaderMobileBurger"
                  onClick={(e) => {
                    e.preventDefault();

                    setNavigationPaneOpen(true);
                  }}
                >
                  <Icon
                    size={30}
                    variant="burgerSquare"
                    color="grey2"
                    css={{
                      path: {
                        fill: "$black",
                      },
                    }}
                  />
                </MobileNavigationPaneLauncher>

                {context !== "signedOut" && (
                  <Box css={{ marginLeft: "$11" }}>
                    <ProfileDropdown
                      avatarImageUrl={avatarImageUrl}
                      avatarInitials={avatarInitials}
                      onSignOut={onSignOut}
                      userName={userName}
                      userEmail={userEmail}
                      context={context}
                    />
                  </Box>
                )}
              </>
            )}
          </TopLinkContainer>
        </HeaderContainer>
      </Wrapper>
    </>
  );
};
