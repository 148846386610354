import { useCallback, useState } from "react";
import { isEqual } from "lodash";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import {
  DateRangeInput,
  JobState,
  TalentVertical,
  JobDateRangeOption,
  SortOrderInput,
} from "src/graphql/types";
import { FilterOption } from "src/ccl/filtering";
import {
  BrandFilterOptions,
  DateRangeOption,
  EARLIEST_JOBS,
  JobStateOptions,
  LATEST_JOBS,
  SearchFilter,
  VerticalFilterOptions,
} from "src/components/filtering";
import {
  formatRange,
  useDateRangeFilterState,
} from "src/components/filtering/jobFilters/useDateRangeFilterState";
import { styled } from "src/ccl";
import { Button } from "src/ccl/navigation";
import { RangePicker } from "src/ccl/data-entry";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";
import { pluralize } from "src/utils/lang";

const Divider = styled("hr", {
  width: "100%",
  height: "1px",
  border: "none",
  backgroundColor: "$grey1",
});

export const FilterContainer = ({
  label,
  children,
}: {
  label: string;
  children: JSX.Element;
}) => {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "18px",
      }}
    >
      <Text variant="nh4">{label}</Text>
      {children}
    </Flex>
  );
};

interface JobFilterModalProps extends ModalProps {
  name?: string;
  setName?: (value: string) => void;
  brand?: string;
  setBrand?: (value: string) => void;
  states: JobState[];
  setStates: (states: JobState[]) => void;
  brands: string[];
  brandOptions: string[];
  setBrands: (brands: string[]) => void;
  verticals: TalentVertical[];
  setVerticals: (verticals: TalentVertical[]) => void;
  sortOrder: SortOrderInput;
  onSortOrderChange: (sortOrder: SortOrderInput) => void;
  jobCount?: number;
  onReset: () => void;
  availableJobStates: JobState[];
  onDateRangeChange: (dateRange?: DateRangeInput) => void;
}
export const JobFilterModal = ({
  name,
  setName,
  brand,
  setBrand,
  states,
  setStates,
  brands,
  brandOptions,
  setBrands,
  verticals,
  setVerticals,
  sortOrder,
  onSortOrderChange,
  jobCount,
  onReset,
  availableJobStates,
  onDateRangeChange,
  ...modalProps
}: JobFilterModalProps) => {
  const [selected, buildRangeOptionProps] =
    useDateRangeFilterState(onDateRangeChange);
  const setCustomRange = useCallback(
    (range: DateRangeInput) => onDateRangeChange(formatRange(range)),
    [],
  );

  const [sortByActive, setSortByActive] = useState(false);
  const [sortByPast, setSortByPast] = useState(false);
  const sortByStateFlags = sortByPast || sortByActive;

  const resetStateFlags = () => {
    setSortByActive(false);
    setSortByPast(false);
  };

  const [showAllBrands, setShowAllBrands] = useState(false);

  const [nameValue, setNameValue] = useDebouncedInputValue(
    name || "",
    setName || function () {},
  );

  const [brandValue, setBrandValue] = useDebouncedInputValue(
    brand || "",
    setBrand || function () {},
  );

  return (
    <Modal
      title="Filters"
      titleVariant="nh3"
      titleCss={{
        marginBottom: 0,
        fontSize: "$22 !important",
      }}
      showCloseButton
      withBodyPadding={false}
      {...modalProps}
    >
      <Flex
        css={{
          pt: "$5",
          px: "$5",
        }}
      >
        <Divider />
      </Flex>
      <Flex
        data-test-id="JobFilterModal"
        css={{
          flexDirection: "column",
          gap: "32px",
          padding: "$5",
          maxHeight: "595px",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            backgroundColor: "$white",
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "$grey3",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "$grey2",
            borderRadius: "5px",
            height: "81px",
          },
        }}
      >
        <FilterContainer label="Job">
          <SearchFilter
            withinModal={true}
            placeholder="Search by job name"
            search={nameValue}
            onChange={setNameValue}
            showClearButton={true}
          />
        </FilterContainer>
        <FilterContainer label="Status">
          <Flex
            css={{
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <JobStateOptions
              variant="pill"
              availableStates={availableJobStates}
              states={states}
              onChange={setStates}
            />
          </Flex>
        </FilterContainer>
        <FilterContainer label="Brand">
          <>
            <SearchFilter
              withinModal={true}
              placeholder="Search for brand"
              search={brandValue}
              onChange={setBrandValue}
              showClearButton={true}
            />
            <Flex
              css={{
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <BrandFilterOptions
                variant="pill"
                brands={brands}
                brandOptions={
                  showAllBrands ? brandOptions : brandOptions.slice(0, 10)
                }
                onChange={setBrands}
              />
              {brandOptions.length > 10 && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowAllBrands((prev) => !prev);
                  }}
                >
                  {showAllBrands ? "Hide" : "Show all"}
                </Button>
              )}
            </Flex>
          </>
        </FilterContainer>
        <FilterContainer label="Creative type">
          <Flex
            css={{
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <VerticalFilterOptions
              variant="pill"
              verticals={verticals}
              onChange={setVerticals}
            />
          </Flex>
        </FilterContainer>
        <FilterContainer label="Date range">
          <>
            <Flex
              css={{
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <DateRangeOption
                label="This Week"
                {...buildRangeOptionProps(JobDateRangeOption.ThisWeek)}
              />
              <DateRangeOption
                label="Last 7 Days"
                {...buildRangeOptionProps(JobDateRangeOption.LastSevenDays)}
              />
              <DateRangeOption
                label="This Month"
                {...buildRangeOptionProps(JobDateRangeOption.ThisMonth)}
              />
              <DateRangeOption
                label="Last 30 Days"
                {...buildRangeOptionProps(JobDateRangeOption.LastThirtyDays)}
              />
              <DateRangeOption
                label="Last Month"
                {...buildRangeOptionProps(JobDateRangeOption.LastMonth)}
              />
              <DateRangeOption
                label="Custom"
                {...buildRangeOptionProps(JobDateRangeOption.Custom)}
              />
            </Flex>
            {selected === JobDateRangeOption.Custom && (
              <RangePicker onChange={setCustomRange} />
            )}
          </>
        </FilterContainer>
        <FilterContainer label="Sort by">
          <Flex
            css={{
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <FilterOption
              variant="pill"
              icon="chevronDown"
              label="Latest Job Date"
              checked={!sortByStateFlags && isEqual(LATEST_JOBS, sortOrder)}
              onClick={() => {
                onSortOrderChange(LATEST_JOBS);
                resetStateFlags();
              }}
            />
            <FilterOption
              variant="pill"
              icon="chevronUp"
              label="Earliest Job Date"
              checked={isEqual(EARLIEST_JOBS, sortOrder)}
              onClick={() => {
                onSortOrderChange(EARLIEST_JOBS);
                resetStateFlags();
              }}
            />
          </Flex>
        </FilterContainer>
      </Flex>
      <Flex
        css={{ flexDirection: "column", gap: "25px", padding: "$0 $5 $5 $5" }}
      >
        <Flex>
          <Divider />
        </Flex>
        <Flex
          css={{
            justifyContent: "space-between",
            flexDirection: "column-reverse",
            gap: "18px",
            "@sm": {
              flexDirection: "row",
            },
          }}
        >
          <Button
            data-test-id="ClearAllButton"
            variant="secondaryCta"
            onClick={onReset}
          >
            Clear All
          </Button>
          <Button
            data-test-id="ShowJobButton"
            variant="primaryCta"
            onClick={modalProps.onClose}
          >
            Show {pluralize(jobCount || 0, "Job")}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
