import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Box, Flex, LegacyContainer } from "src/ccl/layout";
import { Button, ButtonProps } from "src/ccl/navigation";
import { Text } from "src/ccl/document";

const Step = ({
  number,
  label,
  active,
  completed,
  completedColor,
}: {
  number: number;
  label: string;
  active: boolean;
  completed: boolean;
  completedColor?: string;
}) => (
  <Flex
    css={{
      alignItems: "center",
    }}
  >
    <Flex
      css={{
        backgroundColor: active
          ? "$black"
          : completed && completedColor
          ? completedColor
          : "$grey4",
        width: "30px",
        height: "30px",
        borderRadius: "$round",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text color="white" weight="bold">
        {number}
      </Text>
    </Flex>
    <Text
      variant={{ "@initial": "meta", "@bp2": "h4" }}
      css={{
        ml: "$5",
        fontWeight: "bold",
        display: !active ? "none" : undefined,
      }}
    >
      {label}
    </Text>
  </Flex>
);

export const StepHeader = ({
  step = 0,

  labels,
  buttons,
  parentMounted,
  backCta,
  forceStep = false,
  completedStepColor,
  variant,
}: {
  step?: number;

  labels: string[];
  buttons: {
    onClick: () => void;
    label?: string;
    disabled?: boolean;
    variant?: ButtonProps["variant"];
  }[];

  parentMounted: boolean;

  backCta?: ReactNode;
  forceStep?: boolean;
  completedStepColor?: string;
  variant?: "modal";
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = useState(step);

  useEffect(() => {
    const sections = document.querySelectorAll("[data-step-header-section]");

    sections.forEach((section, i) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setCurrentStep(i);
          }
        },
        {
          threshold: 0.3,
        },
      );
      observer.observe(section);
    });
  }, [parentMounted]);

  useEffect(() => {
    if (!forceStep) return;
    setCurrentStep(step);
  }, [step]);

  return (
    <Box
      ref={ref}
      css={{
        zIndex: "$400",
        backgroundColor: "$white",
        position: "sticky",
        top: 0,
      }}
    >
      <LegacyContainer
        variant="gutter"
        css={{
          transition: "0.5s ease padding-top",
          py: "$7",
          px: "$5",
          "@bp1": {
            px: "$7",
          },
          "@bp3": {
            pt:
              variant === "modal"
                ? "$7"
                : (ref.current?.offsetTop || 0) > 100
                ? "$13"
                : undefined,
            pb: variant === "modal" ? "$7" : "$13",
          },
        }}
      >
        {backCta}

        <Flex
          css={{
            justifyContent: "space-between",
          }}
        >
          <Flex
            css={{
              gap: "$7",
            }}
          >
            {labels.map((label, i) => {
              return (
                <Step
                  key={`step-${i}`}
                  active={currentStep === i}
                  number={i + 1}
                  label={label}
                  completed={currentStep > i}
                  completedColor={completedStepColor}
                />
              );
            })}
          </Flex>
          <Flex
            css={{ display: "none", "@bp2": { gap: "28px", display: "flex" } }}
          >
            {buttons.map(({ onClick, label, disabled, variant }, i) => {
              if (label) {
                return (
                  <Button
                    data-test-id={`StepHeaderButton-${i}`}
                    key={i}
                    onClick={onClick}
                    disabled={disabled}
                    variant={variant}
                  >
                    {label}
                  </Button>
                );
              }

              return null;
            })}
          </Flex>
        </Flex>
      </LegacyContainer>
      <Box
        css={{
          height: "$2",
          backgroundColor: "$grey1",
        }}
      >
        <Box
          css={{
            height: "100%",
            backgroundColor: "$black",
            width: "100%",
            transition: "0.5s ease width",
          }}
        />
      </Box>
    </Box>
  );
};
