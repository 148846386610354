import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Text } from "src/ccl/document";
import { Box, Grid } from "src/ccl/layout";
import { formatCurrency } from "src/utils/currencyFormatter";
import { pluralize } from "src/utils/lang";
import { styled } from "src/ccl/stitches";
import {
  jobBookingFee,
  jobPriceForModels,
  jobTotalValue,
  jobVatValue,
} from "src/utils/job";
import { Field, Input, FormikInput } from "src/ccl/data-entry";
import { InfoBanner } from "src/ccl/document/infoBanner";
import { TalentVertical } from "src/graphql/types";
import { verticalMap } from "src/utils/user";

interface CostCalculatorProps {
  budget: number;
  talentRequired: number;
  variant?: "default" | "inverse";
  title?: string;
  explainer?: string;
  showModelInput?: boolean;
  showBudgetInput?: boolean;
  showExplainer?: boolean;
  onInputChange?: (value: number) => void;
  vertical: TalentVertical;
  feeHint?: React.ReactNode;
}

const Container = styled(Grid, {
  gridTemplateColumns: "1fr",
  variants: {
    variant: {
      default: {
        color: "$black",
        backgroundColor: "$white",
      },
      inverse: {
        padding: "$6",
        backgroundColor: "$black",
        [`& ${Text}`]: {
          color: "$white",
        },
        "@bp1": {
          gridTemplateColumns: "1fr 1fr",
        },
      },
    },
  },
});

const Calculations = ({
  budget,
  talentRequired = 0,
  vertical,
}: CostCalculatorProps) => (
  <Grid css={{ gridTemplateColumns: "3fr 2fr" }}>
    <Text>
      {formatCurrency(budget)} x{" "}
      {pluralize(talentRequired, verticalMap[vertical as TalentVertical])}
    </Text>
    <Text> = {formatCurrency(jobPriceForModels(budget, talentRequired))}</Text>

    <Text>Booking fee</Text>
    <Text> = {formatCurrency(jobBookingFee(budget, talentRequired))}</Text>

    <Text>VAT</Text>
    <Text> = {formatCurrency(jobVatValue(budget, talentRequired))}</Text>

    <Text weight="bold">Total cost</Text>
    <Text weight="bold">
      {" "}
      = {formatCurrency(jobTotalValue(budget, talentRequired))}
    </Text>
  </Grid>
);

export const CostCalculator = ({
  budget,
  talentRequired,
  variant = "default",
  title = "Cost calculator",
  showModelInput = false,
  showBudgetInput = false,
  showExplainer = false,
  onInputChange,
  vertical,
  feeHint = null,
}: CostCalculatorProps) => {
  const [modelsCount, setModelsCount] = useState<number>(talentRequired);
  const [newBudget, setNewBudget] = useState<number>(budget);
  const showCalculations =
    (showModelInput || showBudgetInput) && !!modelsCount && budget > 0;

  useEffect(() => {
    setModelsCount(talentRequired);
  }, [talentRequired]);

  const calculationsProps = {
    vertical: vertical,
    budget: budget,
    talentRequired: modelsCount,
  };
  return (
    <Container variant={variant} data-test-id="CostCalculator">
      <Box>
        <Text weight="bold" color="grey6" css={{ mb: "$3" }}>
          {title}
        </Text>
        {showExplainer && (
          <InfoBanner variant="centered" css={{ mb: "$3" }} color="grey3">
            <Text variant="meta" color="grey3">
              Only due after the job
            </Text>
          </InfoBanner>
        )}

        {showCalculations && (
          <Box css={{ display: "none", "@bp1": { display: "block" } }}>
            <Calculations {...calculationsProps} />
          </Box>
        )}
      </Box>
      {showModelInput ? (
        <Box>
          <Field
            labelWeight="regular"
            variant="meta"
            label={`How many ${verticalMap[vertical]}s required?`}
          >
            <Input
              name="talentRequired"
              placeholder="(optional)"
              type="number"
              value={modelsCount}
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const count = parseInt(e.target.value);
                setModelsCount(count);
                onInputChange && onInputChange(count);
              }}
            />
          </Field>
          {showCalculations && (
            <Box css={{ display: "block", "@bp1": { display: "none" } }}>
              <Calculations {...calculationsProps} />
            </Box>
          )}
        </Box>
      ) : showBudgetInput ? (
        <Box>
          <Formik initialValues={{ budget: budget }} onSubmit={() => {}}>
            <Field
              css={{
                pb: "$2",
                pt: "$3",
                "@bp2": { pt: "$0" },
              }}
              label="Total fee"
              labelWeight="regular"
              color="white"
            >
              <FormikInput
                name="budget"
                type="number"
                inputPrefix="£"
                value={newBudget}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const budget = parseInt(e.target.value);
                  setNewBudget(budget);
                  onInputChange && onInputChange(budget);
                }}
              />
            </Field>
          </Formik>

          {feeHint && (
            <InfoBanner color="white" css={{ pb: "$6", "@bp2": { mb: "$0" } }}>
              {feeHint}
            </InfoBanner>
          )}

          {showCalculations && (
            <Box css={{ display: "block", "@bp1": { display: "none" } }}>
              <Calculations {...calculationsProps} />
            </Box>
          )}
        </Box>
      ) : (
        <Calculations {...calculationsProps} />
      )}
    </Container>
  );
};
