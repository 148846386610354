import React from "react";
import { Text } from "src/ccl/document/text";
import { Flex } from "src/ccl/layout";
import { tokens } from "src/ccl/stitches/theme";
import { JobTalentState } from "src/graphql/generated/types";

interface TalentDashboardStateLabelProps {
  state: JobTalentState;
  isPill?: boolean;
}

const stateLabels: Record<JobTalentState, string> = {
  [JobTalentState.Accepted]: "Accepted",
  [JobTalentState.AwaitingConfirmation]: "Accepted",
  [JobTalentState.Rejected]: "Unavailable",
  [JobTalentState.Pending]: "Response pending",
  [JobTalentState.RejectedByBooker]: "Released",
  [JobTalentState.Confirmed]: "Confirmed",
  [JobTalentState.Paid]: "Paid",
  [JobTalentState.Disputed]: "In dispute",
  [JobTalentState.PendingAgencyApproval]: "Pending agency approval",
};

const colors: Record<JobTalentState, keyof typeof tokens.colors> = {
  [JobTalentState.Accepted]: "orange",
  [JobTalentState.AwaitingConfirmation]: "orange",
  [JobTalentState.Rejected]: "red",
  [JobTalentState.Pending]: "orange",
  [JobTalentState.RejectedByBooker]: "red",
  [JobTalentState.Confirmed]: "green",
  [JobTalentState.Paid]: "green",
  [JobTalentState.Disputed]: "red",
  [JobTalentState.PendingAgencyApproval]: "black",
};

const pillColors: Record<JobTalentState, keyof typeof tokens.colors> = {
  [JobTalentState.Accepted]: "pendingLight",
  [JobTalentState.AwaitingConfirmation]: "pendingLight",
  [JobTalentState.Rejected]: "rejectedLight",
  [JobTalentState.Pending]: "pendingLight",
  [JobTalentState.RejectedByBooker]: "rejectedLight",
  [JobTalentState.Confirmed]: "positiveLight",
  [JobTalentState.Paid]: "positiveLight",
  [JobTalentState.Disputed]: "rejectedLight",
  [JobTalentState.PendingAgencyApproval]: "grey1",
};

export const TalentDashboardStateLabel = ({
  state,
  isPill,
}: TalentDashboardStateLabelProps) => (
  <Flex
    css={{
      alignItems: "center",
      ...(isPill && {
        bg: `$${pillColors[state]}`,
        px: "$5",
        py: "$3",
        borderRadius: "$pill",
      }),
    }}
  >
    <Text
      color={colors[state]}
      css={{
        ...(!isPill && { pl: "$3", borderLeft: `3px solid $${colors[state]}` }),
      }}
      variant="mini"
      weight="bold"
    >
      {stateLabels[state]}
    </Text>
  </Flex>
);
