import React from "react";
import { Box, Flex } from "src/ccl/layout";
import { styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";

type ToolTipAlignment = "left" | "center";

export interface AgentTalentCardTooltipProps {
  content: React.ReactNode;
  alignment?: ToolTipAlignment;
  backgroundColor?: `$${keyof typeof tokens.colors}`;
  children?: React.ReactNode;
}

const Wrapper = styled(Flex, {
  boxSizing: "border-box",
  width: "100%",
  pointerEvents: "none",
  position: "absolute",
  top: 0,
  padding: "$4",
  backgroundColor: "$grey3",
  color: "$white",
  opacity: 0,
  whiteSpace: "nowrap",
  transition: "100ms ease opacity",
  fontSize: "$14",
  transform: "translate(-50%,-100%)",
  left: "50%",
  justifyContent: "center",

  [`.icon:hover + &`]: {
    opacity: 1,
  },
});

const Caret = styled("div", {
  width: "0",
  height: "0",
  position: "absolute",
  top: "-8px",
  borderLeftWidth: "6px",
  borderLeftColor: "transparent",
  borderLeftStyle: "solid",
  borderRightWidth: "6px",
  borderRightColor: "transparent",
  borderRightStyle: "solid",
  borderTopColor: "$grey3",
  borderTopStyle: "solid",
  borderTopWidth: "6px",
  left: "50%",
  transform: "translate(calc(50% - 12px))",
  opacity: 0,

  [`:hover + &`]: {
    opacity: 1,
  },
});

export const AgentTalentCardTooltip = ({
  content,
  children,
  backgroundColor,
}: AgentTalentCardTooltipProps) => (
  <>
    <Box className="icon" css={{ position: "relative" }}>
      {children}
      <Caret css={{ borderTopColor: backgroundColor as string }} />
    </Box>
    <Wrapper css={{ bg: backgroundColor }}>{content}</Wrapper>
  </>
);
