import type * as Stitches from "@stitches/react";
import { styled } from "src/ccl/stitches/config";

const roleButtonStyles = {
  backgroundColor: "$white",
  color: "$black",
  height: "86px",
  width: "100%",
  borderRadius: "8px",
  border: "2px solid $grey3",
  textTransform: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "$3",
  "&:focus": {
    border: "2px solid $black",
  },
};
export const ButtonVariants: {
  [v: string]: {};
} = {
  default: {
    backgroundColor: "$grey2",
    color: "$black",
    "&:hover": {
      backgroundColor: "$grey2",
      color: "$grey6",
    },
    "&:active": {
      color: "$grey6",
      boxShadow: "$innerDrop",
    },
    "&:disabled": {
      color: "$grey6",
      cursor: "not-allowed",
      "&:hover": {
        backgroundColor: "$black",
      },
      "&:active": {
        backgroundColor: "$black",
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  grey: {
    backgroundColor: "$grey2",
    color: "$black",
    borderRadius: "$2",
    "&:hover": {
      backgroundColor: "$grey2",
      color: "$grey6",
    },
    "&:active": {
      color: "$grey6",
      boxShadow: "$innerDrop",
    },
    "&:disabled": {
      color: "$grey6",
      cursor: "not-allowed",
      "&:hover": {
        backgroundColor: "$black",
      },
      "&:active": {
        backgroundColor: "$black",
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  primary: {
    backgroundColor: "$black",
    color: "$white",
    "&:hover": { backgroundColor: "$grey6", color: "$white" },
    "&:active": {
      backgroundColor: "$grey6",
      color: "$white",
      boxShadow: "$innerDrop",
    },
    "&:disabled": {
      backgroundColor: "$grey6",
      color: "$grey3",
      pointerEvents: "none",
    },
  },
  primaryV2: {
    backgroundColor: "$black",
    color: "$white",
    fontWeight: "$bold",
    fontSize: "$5",
    borderRadius: "$2",
    border: "1.5px solid $grey5",
    fontFamily: "$sansNew",
  },
  primaryPill: {
    backgroundColor: "$black",
    color: "$white",
    borderRadius: "50px",
    "&:hover": { backgroundColor: "$grey6", color: "$white" },
    "&:disabled": {
      backgroundColor: "$grey6",
      color: "$grey3",
      pointerEvents: "none",
    },
  },
  secondary: {
    color: "$black",
    backgroundColor: "$grey1",
  },
  secondaryV2: {
    color: "$black",
    fontWeight: "$bold",
    fontSize: "$5",
    backgroundColor: "$white",
    borderRadius: "$2",
    border: "1.5px solid $grey5",
    fontFamily: "$sansNew",
  },
  header: {
    color: "$black",
    backgroundColor: "$grey2",
    fontFamily: "Shapiro",
    display: "flex",
    alignItems: "center",
    px: "$4",
    py: "$3",
  },
  green: {
    color: "$white",
    backgroundColor: "$green",
  },
  red: {
    color: "$black",
    backgroundColor: "$red",
  },
  unstyled: {
    backgroundColor: "unset",
    border: "none",
    padding: 0,
    "&:hover": { backgroundColor: "unset" },
    "&:active": {
      backgroundColor: "unset",
      boxShadow: "none",
    },
  },
  link: {
    textTransform: "unset",
    fontFamily: "unset",
    fontWeight: "unset",
    textDecoration: "underline",
    backgroundColor: "unset",
    border: "none",
    padding: 0,
    "&:hover": { backgroundColor: "unset" },
    "&:active": {
      backgroundColor: "unset",
      boxShadow: "none",
    },
  },
  white: {
    backgroundColor: "$white",
    color: "$black",
    border: "2px solid black",
  },
  inverted: {
    backgroundColor: "$white",
    color: "$black",
    "&:hover": { backgroundColor: "$grey6", color: "$black" },
    "&:active": { backgroundColor: "$grey6", color: "$black" },
  },
  disabled: {
    backgroundColor: "$grey2",
    color: "$grey6",
  },
  simplified: {
    fontFamily: "Shapiro",
    height: "34px",
    border: "1px solid $grey3",
    borderRadius: "50px",
    backgroundColor: "$grey1",
    textTransform: "unset",
    textDecoration: "underline",
    fontWeight: "400",
    fontSize: "$14",
    lineHeight: "$22",
    gap: "7px",
    px: "12px",
    py: "5px",
    display: "flex",
    alignItems: "center",
  },
  circle: {
    p: 0,
    pt: 2,
    alignItems: "center",
    justifyContent: "center",
    width: 34,
    height: 34,
    borderRadius: "$round",
  },
  tertiaryDark: {
    backgroundColor: "$black",
    color: "$white",
    borderRadius: "$pill",
    px: "$5",
    py: "$4",

    "&:hover": {
      backgroundColor: "$grey5",
      color: "$grey1",
    },

    "&:disabled": {
      color: "$grey4",
      backgroundColor: "$grey1",
    },
  },
  tertiaryLight: {
    backgroundColor: "$white",
    color: "$black",
    borderRadius: "$pill",
    px: "$5",
    py: "$4",

    "&:hover": {
      backgroundColor: "$grey2",
      color: "$grey5",
    },

    "&:disabled": {
      color: "$grey1",
      backgroundColor: "$grey5",
    },
  },
  primaryCta: {
    backgroundColor: "$black",
    color: "$white",
    borderRadius: "$2",
    px: "$6",
    py: "$6",
    minWidth: "142px",

    "&:hover": {
      backgroundColor: "$grey5",
      color: "$white",
    },

    "&:disabled": {
      backgroundColor: "$grey5",
      color: "$grey3",
    },
  },
  secondaryCta: {
    backgroundColor: "$white",
    color: "black",
    borderRadius: "$2",
    px: "$6",
    py: "$6",
    minWidth: "142px",
    border: "1.5px solid $grey5",

    "&:hover": {
      backgroundColor: "$grey1",
      color: "$grey5",
      border: "1.5px solid $grey4",
    },

    "&:disabled": {
      backgroundColor: "$grey1",
      color: "$grey4",
      border: "1.5px solid $grey3",
    },
  },
  role: roleButtonStyles,
  userRoleSelection: {
    ...roleButtonStyles,
    "&:focus": {
      border: "2px solid $black",
      backgroundColor: "$grey2",
      color: "$grey6",
    },
    "&:disabled": {
      backgroundColor: "$grey4",
      cursor: "not-allowed",
      "&:hover": {
        backgroundColor: "$grey4",
        color: "$grey6",
      },
    },
  },
  primaryPillV2: {
    padding: "12px 16px",
    borderRadius: 20,
    backgroundColor: "$black",
    color: "$white",
    marginRight: "$4",
    "&:hover": {
      backgroundColor: "$black",
      color: "$white",
    },
    "&:disabled": {
      backgroundColor: "$grey2",
      color: "$grey6",
      "&:hover": {
        backgroundColor: "$grey2",
        color: "$grey6",
      },
    },
  },
  primaryPillV2Transparent: {
    padding: "12px 16px",
    borderRadius: 20,
    color: "$black",
    backgroundColor: "transparent",
    marginRight: "$4",
    "&:hover": {
      border: "1px solid $black",
      backgroundColor: "transparent",
      color: "$black",
    },
  },
  primaryPillBordered: {
    padding: "12px 16px",
    borderRadius: 10,
    color: "$black",
    border: "1px solid $black",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "$black",
      color: "$white",
    },
  },
  secondaryPill: {
    padding: "12px 16px",
    borderRadius: 20,
    backgroundColor: "$grey1",
    color: "$grey5",
    marginRight: "$4",
    "&:hover": {
      backgroundColor: "$grey2",
    },
    "&:disabled": {
      backgroundColor: "$grey2",
      color: "$grey6",
      "&:hover": {
        backgroundColor: "$grey2",
        color: "$grey6",
      },
    },
  },
  secondaryPillV2: {
    padding: "12px 16px",
    borderRadius: 20,
    backgroundColor: "$white",
    color: "$black",
    marginRight: "$4",
    fontFamily: "$sansNew",
    fontWeight: "$bold",
    "&:hover": {
      backgroundColor: "$black",
      color: "$white",
    },
    "&:disabled": {
      backgroundColor: "$grey2",
      color: "$grey6",
      "&:hover": {
        backgroundColor: "$grey2",
        color: "$grey6",
      },
    },
  },
};

export const buttonStyles = {
  border: "none",
  cursor: "pointer",
  fontFamily: "$sansWide",
  fontSize: "$12",
  lineHeight: "$16",
  fontWeight: 500,
  px: "$6",
  py: "$5",
  textTransform: "uppercase",
  ...ButtonVariants.default,

  variants: {
    variant: { ...ButtonVariants },
  },
} as const;

export const Button = styled("button", buttonStyles);

export type ButtonProps = Stitches.VariantProps<typeof Button>;
