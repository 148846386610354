import { gql } from "@apollo/client";

export const INVOICE_DETAILS_FRAGMENT = gql`
  fragment InvoiceDetails on Invoice {
    id
    paymentReference
    paymentMethod
    state
    invoiceNumber
    isJobPaymentInvoice
    isLatePaymentInvoice
    invoiceDate
    dueDate
    pdfUrl
    total
    feeItems {
      kind
      amount
      description
    }
  }
`;
