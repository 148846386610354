import { useState } from "react";
import { CloseIcon, Modal } from "./Modal";
import { Avatar } from "src/ccl/blocks";
import { PortfolioImage } from "src/ccl/document/assetImage";
import { Icon } from "src/ccl/document/icon";
import { Box, Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document/text";
import {
  PortfolioImage as PortfolioImageType,
  TalentProfile,
  User,
} from "src/graphql/types";
import { getInitials } from "src/utils/lang";
import { urlAddQuery } from "src/utils/url";
import { formatDate } from "src/utils/dates";

interface PortfolioImageModalProps {
  talent: User;
  portfolioImage: PortfolioImageType;
  isOpen: boolean;
  onClose: () => void;
}

const PortfolioImageModal: React.FC<PortfolioImageModalProps> = ({
  talent,
  portfolioImage,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} showCloseButton onClose={onClose} width="super-wide">
      <PortfolioImageModalContent
        onClose={onClose}
        talent={talent}
        portfolioImage={portfolioImage}
      />
    </Modal>
  );
};

interface PortfolioImageModalContentProps {
  talent: User;
  portfolioImage: PortfolioImageType;
  onBack?: () => void;
  onClose?: () => void;
}

const PortfolioImageModalContent: React.FC<PortfolioImageModalContentProps> = ({
  talent,
  portfolioImage,
  onBack,
  onClose,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(() => {
    const index = (talent.profile as TalentProfile).portfolioImages.findIndex(
      (image) => image.id === portfolioImage.id,
    );
    return index !== -1 ? index : 0;
  });

  const talentProfile = talent.profile as TalentProfile;
  const portfolioImages = talentProfile.portfolioImages;
  const currentImage = portfolioImages[currentImageIndex];

  const category = talentProfile.portfolioImageCategories.find((category) =>
    category.portfolioImages.some((image) => image.id === currentImage?.id),
  );

  const handleScrollThroughImages = (direction: "left" | "right") => {
    const totalImages = portfolioImages.length;
    setCurrentImageIndex((prevIndex) =>
      direction === "left"
        ? prevIndex > 0
          ? prevIndex - 1
          : totalImages - 1
        : prevIndex < totalImages - 1
        ? prevIndex + 1
        : 0,
    );
  };

  return (
    <>
      <Flex
        css={{
          flexDirection: "column",
          justifyContent: "initial",
          mt: "$3",
          pb: "$6",
          gap: "$5",
          "@bp2": {
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "$2",
            mt: "$8",
          },
        }}
      >
        <Flex css={{ justifyContent: "space-between", width: "100%" }}>
          <Flex css={{ alignItems: "center" }}>
            <Flex css={{ mr: "$7", alignItems: "center" }} onClick={onBack}>
              <Icon
                variant="chevronLeft"
                size={18}
                color="black"
                css={{ cursor: "pointer" }}
              />
            </Flex>
            <Avatar
              size="$largeAvatar"
              imageUrl={urlAddQuery(
                talent.featuredAsset?.mediaUrl,
                "w=70&crop=faces",
              )}
              css={{ mr: "$6", minWidth: "$largeAvatar" }}
              initials={getInitials(talent.name)}
            />
            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <Flex css={{ gap: "$4", alignItems: "center" }}>
                <Text variant="b1Bold" color="black">
                  {talent.name}
                </Text>
                {category && (
                  <>
                    <Icon variant="chevronRight" size={18} color="black" />
                    <Text variant="b1Bold" color="black">
                      {category.name}
                    </Text>
                  </>
                )}
              </Flex>
              <Flex css={{ alignItems: "center", gap: "$3" }}>
                <Text
                  variant="rebrand"
                  css={{ fontSize: "$14", fontWeight: "$bold" }}
                >
                  Date Taken:
                </Text>
                <Icon variant="calendar" size={16} color="black" />
                <Text variant="rebrand" css={{ fontSize: "$14" }}>
                  {formatDate(currentImage.createdAt, "MM/dd/yy")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <a
            onClick={() => {
              onClose && onClose();
            }}
            data-test-id="CloseModalButton"
          >
            <Box css={{ cursor: "pointer" }}>
              <CloseIcon variant="cross" size={12} color="black" />
            </Box>
          </a>
        </Flex>
      </Flex>

      {currentImage && (
        <Box css={{ mt: "$11", mb: "$5", position: "relative" }}>
          <PortfolioImage
            maxHeight={window.innerHeight - 350}
            asset={currentImage}
            alt={`A portfolio image from ${talent.name}`}
          />
          {portfolioImages.length > 1 && (
            <>
              <NavigationButton
                direction="left"
                onClick={() => handleScrollThroughImages("left")}
              />
              <NavigationButton
                direction="right"
                onClick={() => handleScrollThroughImages("right")}
              />
            </>
          )}
        </Box>
      )}
      <Flex
        css={{ width: "100%", justifyContent: "center", marginBottom: "$7" }}
      >
        <Text>{`(${currentImageIndex + 1}/${portfolioImages.length})`}</Text>
      </Flex>
    </>
  );
};

interface NavigationButtonProps {
  direction: "left" | "right";
  onClick: () => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  direction,
  onClick,
}) => (
  <Flex
    css={{
      justifyContent: "center",
      alignItems: "center",
      padding: "$3",
      borderRadius: "$round",
      backgroundColor: "$grey2",
      position: "absolute",
      [direction]: 10,
      top: "50%",
      cursor: "pointer",
      transform: "translateY(-50%)",
    }}
    onClick={onClick}
  >
    <Icon
      variant={direction === "left" ? "chevronLeft" : "chevronRight"}
      size={20}
      color="black"
    />
  </Flex>
);

export { PortfolioImageModal, PortfolioImageModalContent };
