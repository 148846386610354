import { useState } from "react";
import { capitalize } from "lodash";
import { AdvancedFilters } from "./AdvancedFilters";
import { Icon, Modal, ModalProps, SignInModal, Text } from "src/ccl/document";
import { FilterOption } from "src/ccl/filtering";
import { Box, Flex } from "src/ccl/layout";
import { BudgetFilterOptions } from "src/components/createJob/creativeFilters/BudgetFilter";
import { GenderFilterOptions } from "src/components/createJob/creativeFilters/GenderFilter";
import { LocationPillFilterOptions } from "src/components/createJob/creativeFilters/LocationFilter";
import {
  CreativeSearchRefineOption,
  FilterContext,
  Gender,
  TalentVertical,
} from "src/graphql/types";
import { Button } from "src/ccl/navigation";
import { useStoreModel } from "src/hooks";
import { SearchFilter, VerticalFilterOptions } from "src/components/filtering";
import { styled } from "src/ccl";
import { Toggle } from "src/ccl/data-entry";
import { verticalMap } from "src/utils/user";

const Divider = styled("hr", {
  width: "100%",
  height: "1px",
  border: "none",
  backgroundColor: "$grey1",
});

export const FilterContainer = ({
  label,
  children,
}: {
  label: string;
  children: JSX.Element;
}) => {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "18px",
      }}
    >
      <Text variant="nh4">{label}</Text>
      {children}
    </Flex>
  );
};

interface CreativeFiltersModalProps extends ModalProps {
  verticals: TalentVertical[];
  setVerticals: (verticals: TalentVertical[]) => void;
  nameOrBrand?: string;
  setNameOrBrand: (value: string) => void;
  locations: string[];
  setLocations: (locations: string[]) => void;
  genders: Gender[];
  setGenders: (genders: Gender[]) => void;
  minDayRate: number;
  maxDayRate: number;
  setDayRates: (values: [number, number]) => void;
  dayRateDistribution: number[];
  defaultMaximum: number;
  creativeCount?: number;
  refineValue: CreativeSearchRefineOption;
  setRefineValue: (value: CreativeSearchRefineOption) => void;
  onReset: () => void;
  filterContext: FilterContext;
  allowAdvanceFilter?: boolean;
}

export const CreativeFiltersModal = ({
  filterContext,
  verticals,
  setVerticals,
  nameOrBrand,
  setNameOrBrand,
  locations,
  setLocations,
  genders,
  setGenders,
  minDayRate,
  maxDayRate,
  setDayRates,
  dayRateDistribution,
  defaultMaximum,
  creativeCount,
  refineValue,
  setRefineValue,
  onReset,
  onClose,
  allowAdvanceFilter = true,
  ...modalProps
}: CreativeFiltersModalProps) => {
  const currentUser = useStoreModel("currentUser");
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const toggleSignInModal = () => {
    setSignInModalOpen((prev) => !prev);
  };

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  return (
    <Modal
      title="Filters"
      titleVariant="nh3"
      titleCss={{
        marginBottom: 0,
        fontSize: "$22 !important",
      }}
      showCloseButton
      withBodyPadding={false}
      onClose={onClose}
      {...modalProps}
    >
      <Flex
        css={{
          pt: "$5",
          px: "$5",
        }}
      >
        <Divider />
      </Flex>
      <Flex
        data-test-id="JobFilterModal"
        css={{
          flexDirection: "column",
          gap: "32px",
          padding: "$9 $5",
          maxHeight: "595px",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            backgroundColor: "$white",
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "$grey3",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "$grey2",
            borderRadius: "5px",
            height: "81px",
          },
        }}
      >
        <FilterContainer label="Search by name">
          <SearchFilter
            placeholder="Search by name"
            search={nameOrBrand}
            onChange={setNameOrBrand}
            withinModal={true}
            showClearButton
          />
        </FilterContainer>
        <FilterContainer label="Talent type">
          <Flex
            css={{
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <VerticalFilterOptions
              variant="roundedButton"
              verticals={verticals}
              onChange={setVerticals}
            />
          </Flex>
        </FilterContainer>
        <FilterContainer label="Location">
          <LocationPillFilterOptions
            onChange={setLocations}
            locations={locations}
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "18px",
            }}
          />
        </FilterContainer>
        <FilterContainer label="Gender">
          <Flex
            css={{
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <GenderFilterOptions
              variant="roundedButton"
              genders={genders}
              onChange={setGenders}
            />
          </Flex>
        </FilterContainer>
        <FilterContainer label="Budget">
          <BudgetFilterOptions
            minDayRate={minDayRate}
            maxDayRate={maxDayRate}
            setDayRates={setDayRates}
            dayRateDistribution={dayRateDistribution}
            defaultMaximum={defaultMaximum}
          />
        </FilterContainer>
        <FilterContainer label="Sort by">
          <Flex
            css={{
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <FilterOption
              variant="pill"
              icon="user"
              label="All"
              checked={refineValue === undefined}
              onClick={() => setRefineValue(CreativeSearchRefineOption.All)}
            />
            <FilterOption
              variant="pill"
              icon="timer"
              label="Recently booked"
              checked={
                refineValue === CreativeSearchRefineOption.RecentlyBooked
              }
              onClick={() => {
                currentUser.loggedIn
                  ? setRefineValue(CreativeSearchRefineOption.RecentlyBooked)
                  : toggleSignInModal();
              }}
            />
            <FilterOption
              variant="pill"
              icon="bookmark"
              label="Bookmarked"
              checked={refineValue === CreativeSearchRefineOption.Bookmarked}
              onClick={() => {
                currentUser.loggedIn
                  ? setRefineValue(CreativeSearchRefineOption.Bookmarked)
                  : toggleSignInModal();
              }}
            />
          </Flex>
        </FilterContainer>
        {allowAdvanceFilter && (
          <Flex
            css={{
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Flex css={{ justifyContent: "space-between" }}>
              <Text variant="nh3">Advanced Filters</Text>
              <Toggle
                id="isFeaturedToggle"
                checked={showAdvancedFilters}
                onCheck={() => setShowAdvancedFilters(true)}
                onUncheck={() => setShowAdvancedFilters(false)}
                iconChecked={<Icon variant="check" size={10} />}
                iconUnchecked={<Icon variant="cross" size={10} color="white" />}
                uncheckedGreyBackground
                height={21}
              />
            </Flex>
            {showAdvancedFilters && (
              <>
                {verticals.length === 0 ? (
                  <Box css={{ px: "$4", pb: "$5" }}>
                    {Object.entries(verticalMap).map(([vertical, name], i) => (
                      <Flex
                        key={i}
                        css={{ py: "$2", "&:hover": { cursor: "pointer" } }}
                        onClick={() => {
                          setVerticals([vertical as TalentVertical]);
                        }}
                      >
                        <Text
                          css={{
                            fontSize: "$16",
                            lineHeight: "26px",
                          }}
                        >
                          {capitalize(name) + "s"}
                        </Text>
                        <Icon
                          variant="chevronRight"
                          size={18}
                          css={{ my: "auto", ml: "auto" }}
                        />
                      </Flex>
                    ))}
                  </Box>
                ) : (
                  <AdvancedFilters
                    vertical={verticals[0]}
                    context={filterContext}
                  />
                )}
              </>
            )}
          </Flex>
        )}
      </Flex>
      <Flex
        css={{
          flexDirection: "column",
          gap: "25px",
          padding: "$0 $5 $5 $5",
        }}
      >
        <Flex>
          <Divider />
        </Flex>
        <Flex
          css={{
            justifyContent: "space-between",
            flexDirection: "column-reverse",
            gap: "18px",
            "@sm": {
              flexDirection: "row",
            },
          }}
        >
          <Button
            data-test-id="ClearAllButton"
            variant="secondaryCta"
            onClick={onReset}
          >
            Clear All
          </Button>
          <Button
            data-test-id="ShowJobButton"
            variant="primaryCta"
            onClick={onClose}
          >
            Show {creativeCount} talents
          </Button>
        </Flex>
      </Flex>
      <SignInModal isOpen={signInModalOpen} onClose={toggleSignInModal} />
    </Modal>
  );
};
