import React from "react";
import { ComponentProps, CSS } from "@stitches/react";
import { BlogVideo } from "./BlogVideo";
import { BlogImage } from "./BlogImage";
import { ContentfulAsset } from "src/entities";
import { Box } from "src/ccl/layout";
import { mergeCss } from "src/ccl/stitches";

interface BlogAssetProps extends ComponentProps<typeof Box> {
  asset: ContentfulAsset;
  alt?: string;
  assetCSS?: CSS;
}

export const BlogAsset = ({ asset, alt, assetCSS = {} }: BlogAssetProps) => {
  if (!asset.fields.file) {
    return null;
  }

  if (
    asset.fields.file.contentType &&
    asset.fields.file.contentType.startsWith("video/")
  ) {
    return <BlogVideo src={asset.fields.file.url} css={assetCSS} />;
  }

  if (
    asset.fields.file.details.image &&
    asset.fields.file.details.image.width <
      asset.fields.file.details.image.height
  ) {
    assetCSS = mergeCss(
      {
        maxWidth: 400,
        width: "100%",
        transition: "0.2s ease-in opacity",
      },
      assetCSS,
    );
  }

  return (
    <BlogImage
      src={asset.fields.file.url}
      alt={alt || asset.fields.title || asset.fields.description || ""}
      css={{ transition: "0.2s ease-in opacity", ...assetCSS }}
    />
  );
};
