import React from "react";
import { Formik, Form } from "formik";
import { ModalProps, Modal, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { Field, FormikInput } from "src/ccl/data-entry";
import { PortfolioCategoryValidation } from "src/validations";
import { PortfolioImageCategory } from "src/graphql/types";

interface EditPortfolioCategoryModalProps extends ModalProps {
  category: PortfolioImageCategory;
  onEdit: (name: string) => void;
  onDelete: () => void;
  existingCategories: PortfolioImageCategory[];
}

export const EditPortfolioCategoryModal = ({
  category,
  onClose,
  onDelete,
  isOpen,
  onEdit,
  existingCategories,
}: EditPortfolioCategoryModalProps) => (
  <Modal showCloseButton={true} onClose={onClose} isOpen={isOpen}>
    <Formik
      initialValues={{
        name: category.name,
      }}
      validationSchema={PortfolioCategoryValidation(
        existingCategories,
        category.name,
      )}
      onSubmit={({ name }) => {
        onEdit(name);
      }}
    >
      {() => (
        <Form data-test-id="EditCategoryModal">
          <Flex
            css={{
              flexDirection: "column",
              gap: "$9",
            }}
          >
            <Text variant="nh3">Edit category</Text>
            <Field variant="b2Bold" name="name" label="Category name">
              <FormikInput
                variant="rebrand"
                autoFocus
                placeholder="E.g. Lucy & Yak E-commerce campaign"
                name="name"
                type="string"
              />
            </Field>
            <Flex
              css={{
                flexDirection: "column-reverse",
                gap: "18px",
                "@sm": {
                  flexDirection: "row",
                  justifyContent: "space-between",
                },
              }}
            >
              <Button
                variant="secondaryCta"
                type="button"
                onClick={onDelete}
                css={{
                  color: "$red",
                }}
              >
                Delete Category
              </Button>
              <Button
                variant="primaryCta"
                type="submit"
                data-test-id="edit-button"
              >
                Save Category
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  </Modal>
);
