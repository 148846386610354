import { gql } from "@apollo/client";

export const AWAIT_JOB_PAYMENT_MUTATION = gql`
  mutation AwaitJobPaymentMutation($jobId: ID!, $talentIdsToConfirm: [ID!]!) {
    awaitJobPayment(jobId: $jobId, talentIdsToConfirm: $talentIdsToConfirm) {
      id
      state
      commission
      talent {
        id
        name
        state
      }
      invoices {
        id
        isJobPaymentInvoice
      }
    }
  }
`;
