import { gql } from "@apollo/client";

export const CHANGE_JOB_TALENT_RESPONSE_STATE_MUTATION = gql`
  mutation TalentJobResponseMutation(
    $jobId: ID!
    $jobTalentId: ID!
    $response: JobTalentStateEvent!
    $rejectionReason: JobTalentRejectionReason
    $rejectionNote: String
  ) {
    talentJobResponse(
      jobId: $jobId
      jobTalentId: $jobTalentId
      response: $response
      rejectionReason: $rejectionReason
      rejectionNote: $rejectionNote
    ) {
      id
      talent {
        id
        state
        transitionableEvents
      }
    }
  }
`;
