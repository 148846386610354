import { ApolloQueryResult } from "@apollo/client";
import React from "react";
import { BookerTableHeader } from "./BookerTableHeader";
import { Box, Flex } from "src/ccl/layout";
import { BookerListItem } from "src/components/dashboards/agents/bookers/BookerListItem";
import { Query, SortOrderInput, User } from "src/graphql/types";
import { Button } from "src/ccl/navigation";
import { Loading } from "src/components";
import { ApiError } from "src/components/errors";
import { EmptyState } from "src/components/dashboards/agents";
import { Icon, Text } from "src/ccl/document";
import { BookerFiltersModal } from "src/components/dashboards/agents/bookers/BookerFiltersModal";
import { useToggle } from "src/hooks";
import { BookerSortModal } from "src/components/dashboards/agents/bookers/BookerSortModal";

interface BookerListProps {
  bookers: User[];
  loading: boolean;
  error: boolean;
  canFetchMore?: boolean;
  loadMore: () => Promise<ApolloQueryResult<Query>>;
  sortOrder: SortOrderInput;
  onSortOrderChange: (sortOrder: SortOrderInput) => void;
  nameOrEmail: string;
  setNameOrEmail: (search: string) => void;
  bookerKind: string;
  setBookerKind: (kind: string) => void;
}

export const BookerList = ({
  bookers,
  canFetchMore,
  loadMore,
  loading,
  error,
  sortOrder,
  onSortOrderChange,
  nameOrEmail,
  setNameOrEmail,
  bookerKind,
  setBookerKind,
}: BookerListProps) => {
  if (error) {
    return <ApiError />;
  }

  const [filterModalOpen, toggleFilterModal] = useToggle();
  const [sortModalOpen, toggleSortModal] = useToggle();

  return (
    <>
      <BookerFiltersModal
        isOpen={filterModalOpen}
        onClose={toggleFilterModal}
        nameOrEmail={nameOrEmail}
        setNameOrEmail={setNameOrEmail}
        bookerKind={bookerKind}
        setBookerKind={setBookerKind}
      />
      <BookerSortModal
        isOpen={sortModalOpen}
        onClose={toggleSortModal}
        sortOrder={sortOrder}
        setSortOrder={(column, sortDirection) =>
          onSortOrderChange({ column, direction: sortDirection })
        }
      />
      <Box
        css={{
          flex: 1,
        }}
      >
        <Flex
          css={{
            background: "#FAFAFA",
            borderRadius: "$lg",
            padding: "13px 10px",
            justifyContent: "space-between",
            alignItems: "center",
            "@md": {
              display: "none",
            },
          }}
        >
          <Flex
            css={{
              alignItems: "center",
              gap: "9px",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={toggleFilterModal}
          >
            <Icon variant="arrowDown" size={16} color="grey5" />
            <Text variant="button" color="grey6">
              Filter
            </Text>
          </Flex>
          <Flex
            css={{
              alignItems: "center",
              gap: "9px",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={toggleSortModal}
          >
            <Icon variant="sortDefault" size={16} />
            <Text variant="button" color="grey6">
              Order
            </Text>
          </Flex>
        </Flex>
        <BookerTableHeader
          sortOrder={sortOrder}
          setSortOrder={(column, sortDirection) =>
            onSortOrderChange({ column, direction: sortDirection })
          }
        />
        {!loading && bookers.length === 0 && <EmptyState subject="clients" />}
        {bookers.length !== 0 && (
          <Box css={{ my: "$6" }}>
            {bookers.map((booker, i) => (
              <BookerListItem booker={booker} key={i} />
            ))}
          </Box>
        )}
        {loading && <Loading />}
        {!loading && canFetchMore && (
          <Button
            disabled={loading}
            onClick={loadMore}
            variant="primaryCta"
            css={{ display: "block", mx: "auto", my: "$12" }}
          >
            Load more
          </Button>
        )}
      </Box>
    </>
  );
};
