import { gql } from "@apollo/client";

export const CHATROOM_MESSAGES_QUERY = gql`
  query ChatroomMessages($id: ID!) {
    chatroom(id: $id) {
      id
      kind
      name
      lastSentAt
      messages {
        totalCount
        nodes {
          id
          createdAt
          sender {
            id
            name
            featuredAsset {
              mediaUrl
            }
          }
          content
        }
      }
    }
  }
`;
