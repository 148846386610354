import React from "react";
import { SortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Flex, Box } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { Scalars } from "src/graphql/types";
import { DragHandle, DragHandleContainer } from "src/components/DragHandle";

export const SortableItem = <T extends { id: Scalars["ID"] }>({
  item,
  index,
  component: Component,
  sortingStrategy,
  hideDragHandle = false,
}: {
  item: T;
  index: number;
  component: React.FC<{
    item: T;
    isDragging?: boolean;
  }>;
  sortingStrategy?: SortingStrategy;
  hideDragHandle?: boolean;
}) => {
  const {
    setNodeRef: sortableRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
    isSorting,
    isOver,
  } = useSortable({
    id: item.id,
    animateLayoutChanges: () => true,
    strategy: sortingStrategy,
  });

  return (
    <Flex
      css={{
        transformOrigin: "50% 50%",
        display: "inline-flex",
        position: "relative",
        width: "100%",
        "@bp2": {
          "&:hover": {
            [`& ${DragHandleContainer}`]: {
              [`& ${Flex}`]: {
                display: "block",
              },
              [`& ${Text}`]: {
                display: "none",
              },
            },
          },
        },
      }}
      ref={sortableRef}
      style={{
        transition,
        transform: isSorting ? undefined : CSS.Translate.toString(transform),
      }}
    >
      {isOver && !hideDragHandle && (
        <Box
          css={{
            position: "absolute",
            backgroundColor: "lightblue",
            top: -5,
            width: "100%",
            height: "3px",
            "@bp2": {
              top: 0,
              left: -10,
              height: "100%",
              width: "3px",
            },
          }}
        />
      )}
      {!hideDragHandle && (
        <DragHandle
          index={index}
          attributes={attributes}
          listeners={listeners}
        />
      )}
      <Component item={item} isDragging={isDragging} />
    </Flex>
  );
};
