import React, { useEffect, useState } from "react";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { Box, Flex } from "src/ccl/layout";
import { ConfirmModal, Icon, IconText, Modal, Text } from "src/ccl/document";
import { TalentVertical } from "src/graphql/types";
import { verticalIconMap, verticalMap } from "src/utils/user";
import { Button, Dropdown } from "src/ccl/navigation";
import { Input } from "src/ccl/data-entry";
import { Tooltip } from "src/ccl/feedback";
import { useDebouncedInputValue } from "src/hooks/useDebouncedInputValue";
import { ResponsiveText } from "src/components/ResponsiveText";
import { useToggle } from "src/hooks";
import { styled } from "src/ccl";
import { sendToAmplitude } from "src/utils/analytics";

const TooManyUsers =
  "Looks like you've got too many creatives here to shortlist in one go. Shortlist them directly in the list below.";

const ShortlistAllButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    data-test-id="ShortlistAllButton"
    variant="simplified"
    css={{
      textDecoration: "none",
      border: "unset",

      "&:disabled": {
        "&:hover": {
          backgroundColor: "$grey2",
        },
      },
    }}
    onClick={onClick}
  >
    <Icon variant="checklist" />
    <Text variant="mini">
      <ResponsiveText initial="Shortlist all" bp2="Shortlist all creatives" />
    </Text>
  </Button>
);

const VerticalFilterButton = styled(Flex, {
  borderRadius: "$md",
  width: "fit-content",
  py: "$2",
  px: "$4",
  gap: "$5",
  alignItems: "center",
});

const VerticalDropdownOption = ({
  vertical,
  setVertical,
  selected,
}: {
  vertical?: TalentVertical;
  setVertical: (v?: TalentVertical) => void;
  selected: boolean;
}) => {
  const prettyVertical = vertical
    ? capitalize(verticalMap[vertical])
    : "All creative";
  return (
    <Flex
      data-test-id="VerticalFilterOption"
      onClick={() => setVertical(vertical)}
      css={{
        backgroundColor: selected ? "$black" : undefined,
        py: "$3",
        px: "$4",
        gap: "$3",
        borderRadius: "$md",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: selected ? undefined : "$grey1",
        },
        "@bp2": {
          width: 247,
        },
      }}
    >
      <Icon
        color={selected ? "white" : "black"}
        variant={vertical ? verticalIconMap[vertical] : "user"}
        css={{ my: "auto" }}
      />
      <Text color={selected ? "white" : undefined}>{prettyVertical}s</Text>
    </Flex>
  );
};

const VerticalFilterOptions = ({
  vertical,
  setVertical,
}: {
  vertical?: TalentVertical;
  setVertical: (v?: TalentVertical) => void;
}) => (
  <Box>
    <VerticalDropdownOption
      setVertical={setVertical}
      selected={vertical === undefined}
    />
    {Object.values(TalentVertical).map((v, i) => (
      <VerticalDropdownOption
        key={`talent-vertical-option-${i}`}
        vertical={v}
        setVertical={setVertical}
        selected={v === vertical}
      />
    ))}
  </Box>
);

export const LegacyCollectionShowHeader = ({
  collectionName,
  totalUsers,
  loading,
  name,
  setName,
  vertical,
  setVertical,
  onShortlistAll,
  onEdit,
  analyticsEventPage,
}: {
  collectionName: string;
  totalUsers?: number;
  loading: boolean;
  name: string;
  setName: (name: string) => void;
  vertical?: TalentVertical;
  setVertical: (v?: TalentVertical) => void;
  onShortlistAll?: () => void;
  onEdit?: () => void;
  analyticsEventPage?: string;
}) => {
  const [verticalSelectModalOpen, toggleVerticalSelectModal] = useToggle();
  const [shortlistAllModalOpen, toggleShortlistAllModal] = useToggle();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    if (tooltipOpen) {
      return;
    }

    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 5000);
  };
  const [nameValue, setNameValue] = useDebouncedInputValue(name, (value) =>
    setName(value),
  );

  useEffect(() => {
    if (loading) {
      return;
    }
    sendToAmplitude(`${analyticsEventPage} - lands on page`, {
      collectionName,
    });
  }, [collectionName, loading, analyticsEventPage]);

  return (
    <>
      <Modal
        isOpen={verticalSelectModalOpen}
        onClose={toggleVerticalSelectModal}
        title="Refine"
      >
        <VerticalFilterOptions vertical={vertical} setVertical={setVertical} />
        <Box
          css={{
            borderTop: "1px solid $grey3",
            textAlign: "center",
            mt: "$20",
          }}
        >
          <Text color="grey4" css={{ pt: "$4", pb: "$5" }}>
            {loading
              ? "loading..."
              : `${
                  totalUsers === undefined ? "No" : totalUsers
                } creatives found`}
          </Text>
          <Flex css={{ justifyContent: "right" }}>
            <Button onClick={toggleVerticalSelectModal} variant="primary">
              Close
            </Button>
          </Flex>
        </Box>
      </Modal>
      <ConfirmModal
        showCloseButton={true}
        onClose={toggleShortlistAllModal}
        isOpen={shortlistAllModalOpen}
        title={`Are you sure you want to add ${totalUsers} creatives to your shortlist?`}
        confirmButtonTitle="Add to shortlist"
        subtitle="You can view and edit your shortlist using the basket in the top right corner."
        onConfirm={() => {
          onShortlistAll && onShortlistAll();
          toggleShortlistAllModal();
          toggleTooltip();
          sendToAmplitude(`${analyticsEventPage} - shortlists all`);
        }}
        onCancel={toggleShortlistAllModal}
        icon="checklist"
        titleVariant="sch2"
      />

      <Link to="/dashboard/client/collections">
        <IconText
          text="All bookmarks"
          leftIcon="chevronLeft"
          iconSize={16}
          css={{
            textDecoration: "underline",
            pb: "$6",
            "@bp3": { display: "none" },
          }}
        />
      </Link>
      <Text variant="h2">{collectionName}</Text>
      <Text css={{ color: "$grey6", py: "$5" }}>
        {loading
          ? "loading..."
          : `${
              totalUsers === undefined ? "No" : totalUsers
            } bookmarked creatives`}
      </Text>
      <Flex
        css={{
          gap: "$6",
          pb: "$9",
          flexDirection: "column-reverse",
          "@bp2": {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
      >
        <Flex
          css={{
            flexGrow: 1,
          }}
        >
          <Input
            name="name"
            css={{
              "@bp2": { width: "70%" },
              pl: "$5",
              border: "1px solid $grey3",
              borderRadius: "40px",
            }}
            placeholder="Search by name"
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
          />
          <Icon
            variant="searchOutline"
            size={24}
            css={{ ml: -37, mt: "13px" }}
            color="grey4"
          />
        </Flex>
        {onEdit && (
          <Button
            variant="simplified"
            onClick={onEdit}
            css={{ width: "fit-content" }}
            data-test-id="edit-collection-button"
          >
            <Icon variant="pencil" />
            <Text>Edit collection</Text>
          </Button>
        )}
      </Flex>
      <Flex
        css={{
          justifyContent:
            totalUsers !== undefined && totalUsers !== 0
              ? "space-between"
              : "flex-end",
          pt: "$6",
          borderTop: "2px solid $grey1",
          "@bp2": {
            pt: "$10",
            pb: "$12",
          },
        }}
      >
        {tooltipOpen ? (
          <Tooltip
            wrapperCss={{
              whiteSpace: "inherit",
              width: "300%",
              active: true,
              opacity: !onShortlistAll ? 0 : undefined,
            }}
            alignment={"left"}
            content={
              <Text color="white" css={{ fontSize: 14, lineHeight: 1 }}>
                {TooManyUsers}
              </Text>
            }
          >
            {!loading && totalUsers && totalUsers > 0 && (
              <ShortlistAllButton
                onClick={
                  onShortlistAll ? toggleShortlistAllModal : toggleTooltip
                }
              />
            )}
          </Tooltip>
        ) : (
          <>
            {!loading && totalUsers && totalUsers > 0 && (
              <ShortlistAllButton
                onClick={
                  onShortlistAll ? toggleShortlistAllModal : toggleTooltip
                }
              />
            )}
          </>
        )}
        <VerticalFilterButton
          data-test-id="VerticalFilterButton"
          css={{
            display: "flex",
            backgroundColor: vertical ? "$black" : "$grey1",
            "&:hover": {
              cursor: "pointer",
            },
            "@bp2": {
              display: "none",
            },
          }}
          onClick={toggleVerticalSelectModal}
        >
          <Text variant="body" color={vertical ? "white" : "black"}>
            {vertical
              ? `${capitalize(verticalMap[vertical])}s`
              : "All creatives"}
          </Text>
          <Icon variant="arrowDown" color={vertical ? "white" : "black"} />
        </VerticalFilterButton>
        <Box css={{ display: "none", "@bp2": { display: "block" } }}>
          <Dropdown
            position="center"
            bodyCss={{
              border: "none",
              width: "max-content",
              borderRadius: "8px",
            }}
            launcher={
              <VerticalFilterButton
                data-test-id="VerticalFilterButton"
                css={{
                  backgroundColor: vertical ? "$black" : "$grey1",
                }}
              >
                <Text variant="body" color={vertical ? "white" : "black"}>
                  {vertical
                    ? `${capitalize(verticalMap[vertical])}s`
                    : "All creatives"}
                </Text>
                <Icon
                  variant="arrowDown"
                  color={vertical ? "white" : "black"}
                />
              </VerticalFilterButton>
            }
          >
            <VerticalFilterOptions
              vertical={vertical}
              setVertical={setVertical}
            />
          </Dropdown>
        </Box>
      </Flex>
    </>
  );
};
