import React, { useCallback, useState } from "react";
import { CreativeSearchRefineOption } from "src/graphql/types";
import { Text, Icon, SignInModal } from "src/ccl/document";
import { Flex, Box } from "src/ccl/layout";
import { Dropdown } from "src/ccl/navigation";
import { styled } from "src/ccl";
import { useStoreModel } from "src/hooks";
import { SortOrderOption } from "src/components/filtering";

const Caret = styled(Box, {
  width: "0",
  height: "0",
  position: "absolute",
  top: -6,
  right: "50%",
  borderLeftWidth: "6px",
  borderLeftColor: "transparent",
  borderLeftStyle: "solid",
  borderRightWidth: "6px",
  borderRightColor: "transparent",
  borderRightStyle: "solid",
  borderBottomColor: "$white",
  borderBottomStyle: "solid",
  borderBottomWidth: "6px",
});

export const SortCreativesFilter = ({
  refineValue,
  setRefineValue,
}: {
  refineValue: CreativeSearchRefineOption;
  setRefineValue: (val: CreativeSearchRefineOption) => void;
}) => {
  const currentUser = useStoreModel("currentUser");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const onClose = useCallback(() => setDropdownOpen(false), []);
  const onOpen = useCallback(() => setDropdownOpen(true), []);
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };
  let dropdownText = "All";
  if (refineValue === CreativeSearchRefineOption.RecentlyBooked) {
    dropdownText = "Recently booked";
  }
  if (refineValue === CreativeSearchRefineOption.Bookmarked) {
    dropdownText = "Bookmarked";
  }

  return (
    <Box data-test-id="SortCreativesFilter" css={{ width: "fit-content" }}>
      <Dropdown
        position="center"
        bodyCss={{
          border: "none",
          filter: "drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.2))",
          width: "max-content",
          borderRadius: "8px",
        }}
        onClose={onClose}
        onOpen={onOpen}
        launcher={
          <Box
            css={{
              borderRadius: "2px",
              backgroundColor:
                refineValue !== CreativeSearchRefineOption.All
                  ? "$black"
                  : dropdownOpen
                  ? "$grey6"
                  : "$grey1",
              width: "fit-content",
              py: "$2",
              px: "$4",
            }}
          >
            <Flex css={{ alignItems: "center", cursor: "pointer", gap: "$3" }}>
              <Icon
                variant="sortRows"
                size={18}
                css={{ my: "auto" }}
                color={
                  dropdownOpen || refineValue !== CreativeSearchRefineOption.All
                    ? "white"
                    : "grey4"
                }
              />
              <Text
                variant="body"
                color={
                  dropdownOpen || refineValue !== CreativeSearchRefineOption.All
                    ? "white"
                    : "grey4"
                }
              >
                {dropdownText}
              </Text>
            </Flex>
          </Box>
        }
      >
        <Caret data-test-id="caret" />
        <SortOrderOption
          label="All"
          icon="user"
          selected={refineValue === CreativeSearchRefineOption.All}
          onClick={() => {
            setRefineValue(CreativeSearchRefineOption.All);
          }}
        />
        <SortOrderOption
          label="Recently booked"
          icon="timer"
          selected={refineValue === CreativeSearchRefineOption.RecentlyBooked}
          onClick={() => {
            currentUser.loggedIn
              ? setRefineValue(CreativeSearchRefineOption.RecentlyBooked)
              : toggleSignInModal();
          }}
        />
      </Dropdown>
      <SignInModal isOpen={signInModalOpen} onClose={toggleSignInModal} />
    </Box>
  );
};
