import React from "react";
import { CSS, VariantProps } from "@stitches/react";
import { BrandBanner } from "./marketing";
import { mergeCss, styled } from "src/ccl/stitches/config";
import { Text } from "src/ccl/document";
import { ContentContainer } from "src/ccl/layout";

const Wrapper = styled("div", {
  variants: {
    variant: {
      normal: {
        backgroundColor: "$white",
        color: "$black",
      },
      inverted: { backgroundColor: "$black", color: "$white" },
    },
  },
});

const Heading = styled(Text, {
  textAlign: "center",
  fontSize: "$18",
  lineHeight: "$24",
  "@bp2": {
    fontSize: "$24",
    lineHeight: "$32",
  },
});

interface SocialProofProps extends VariantProps<typeof Wrapper> {
  variant?: "normal" | "inverted";
  css?: CSS;
  children?: React.ReactNode;
}

export const SocialProof = ({
  variant = "normal",
  css = {},
  children,
}: SocialProofProps) => {
  const color = variant === "normal" ? "black" : "white";

  return (
    <Wrapper variant={variant} css={mergeCss({}, css)}>
      <ContentContainer>
        <Heading variant="h2" color={color}>
          Trusted by the best
        </Heading>
      </ContentContainer>

      <ContentContainer>
        <BrandBanner
          backgroundColor={variant === "normal" ? "$white" : "$black"}
          iconColor={variant === "normal" ? "black" : "white"}
        />
      </ContentContainer>

      {children}
    </Wrapper>
  );
};
