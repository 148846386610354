import React from "react";
import { styled } from "@stitches/react";
import { MegaNavExplainer } from "./MegaNavExplainer";
import { Box, LegacyContainer, Grid } from "src/ccl/layout";
import { VerticalSelector } from "src/components";

const Divider = styled("hr", {
  height: "1px",
  border: "none",
  backgroundColor: "$grey3",
});

export const MegaNav = ({
  isVisible = false,
  onMouseLeave,
}: {
  isVisible: boolean;
  onMouseLeave: () => void;
}) => (
  <Box
    data-test-id="MegaNav"
    css={{
      display: "none",
      "@bp2": { display: isVisible ? "block" : "none" },
      width: "100%",
    }}
    onMouseLeave={() => onMouseLeave()}
  >
    <Box
      css={{
        boxShadow: "$focus",
        backgroundColor: "$white",
        width: "100%",
        px: "$11",
        pb: "$11",
        position: "absolute",
        zIndex: "$400",
      }}
    >
      <VerticalSelector
        bgCss={{
          backgroundColor: "$white",
          position: "relative",
          top: "$0",
          pt: "$11",
          pb: "$6",
          "@bp2": {
            pb: "$11",
          },
        }}
        containerCss={{
          mt: "$0",
        }}
        wrap={true}
        amplitudeEvent="top nav - clicks vertical card"
      />

      <LegacyContainer>
        <Divider />
        <Grid
          css={{
            gridColumns: 1,
            gridGap: "$9",
            mt: "$11",
            "@bp2": { mt: "$13", gridColumns: 3, gridGap: "$8" },
          }}
        >
          <MegaNavExplainer
            pill={true}
            title={"Book your creative team"}
            copy={
              "Shortlist all the creatives you need before making your final selection."
            }
            icon={"cardShortlist"}
            iconSize={60}
            iconCss={{ "@bp2": { height: "50px" } }}
          />

          <MegaNavExplainer
            title={"Chat to creatives"}
            copy={
              "You can email your shortlisted creatives before finalising the job and budget."
            }
            icon={"community"}
            iconSize={60}
          />

          <MegaNavExplainer
            title={"Assigned support"}
            copy={
              "Need a helping hand? Each booking is assigned a clear contact for you to chat to if you ever need support."
            }
            icon={"supportIllo"}
            iconSize={60}
          />
        </Grid>
      </LegacyContainer>
    </Box>
  </Box>
);
