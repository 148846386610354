import { gql } from "@apollo/client";

export const AGENT_ALL_TALENT_QUERY = gql`
  query AgentAllTalent {
    me {
      agency {
        allTalent {
          name
        }
      }
    }
  }
`;
