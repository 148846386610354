import { useState } from "react";
import { FileDragDropUpload } from "src/ccl/data-entry";
import { Icon, Text } from "src/ccl/document";
import { ValidationBlock } from "src/ccl/feedback";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { UploadedFile } from "src/entities";
import { FeaturedAsset } from "src/models/agencies/addTalentDraft";
import { CropModal } from "src/components/dashboards/agents/talentManagement/forms/CropModal";

const EmptyContent = () => (
  <Box>
    <Icon variant="gallery" size={60} color="grey3" />
    <Text
      css={{
        textDecoration: "underline",
        color: "$grey4",
        mb: "10px",
      }}
    >
      Upload photos
    </Text>
    <Text variant="meta" css={{ color: "$grey6" }}>
      or drag photos here
    </Text>
    <Text variant="meta" css={{ color: "$grey6" }}>
      (max. size per photo 5MB)
    </Text>
  </Box>
);

export const FeaturedAssetForm = ({
  featuredAsset,
  error,
  onDelete,
  onUpload,
}: {
  featuredAsset?: FeaturedAsset;
  error?: string;
  onDelete?: () => void;
  onUpload: (files: UploadedFile[]) => void;
}) => {
  const [cropModalOpen, setCropModalOpen] = useState(false);

  return (
    <Box
      data-test-id="ProfileImage"
      css={{
        pt: "20px",
        mb: "$9",
        "@bp2": {
          maxWidth: "350px",
          pt: "22px",
        },
      }}
    >
      <FileDragDropUpload
        showMaxFileCountReached={false}
        type="image"
        multiple={false}
        existingImage={
          (featuredAsset && {
            id: "1",
            sortWeight: 0,
            ...featuredAsset,
          }) ||
          undefined
        }
        contentNoFiles={<EmptyContent />}
        contentHovering={<Text>Drop files here...</Text>}
        contentWithFiles={
          <Flex css={{ width: "100%" }}>
            <Button
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                setCropModalOpen(true);
              }}
              css={{
                width: "100%",
                borderRadius: "0 0 0 8px",
                textTransform: "initial",
              }}
            >
              <Flex
                css={{
                  gap: "$4",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon variant="crop" />
                <Text variant="b2">Crop</Text>
              </Flex>
            </Button>
            {onDelete ? (
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
                css={{
                  width: "100%",
                  borderRadius: "0 0 8px 0",
                  textTransform: "initial",
                }}
              >
                <Flex
                  css={{
                    gap: "$4",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon variant="trash" color="red" />
                  <Text variant="b2" color="red">
                    Delete
                  </Text>
                </Flex>
              </Button>
            ) : (
              <Button
                variant="secondary"
                onClick={() => {}}
                css={{
                  width: "100%",
                  borderRadius: "0 0 8px 0",
                  textTransform: "initial",
                }}
              >
                <Flex
                  css={{
                    gap: "$4",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon variant="pencil" />
                  <Text variant="b2">Edit</Text>
                </Flex>
              </Button>
            )}
          </Flex>
        }
        onUpload={onUpload}
        attachmentStoreName="uploads"
        maxFileCount={1}
        altText="Profile image"
        showPreviewList={false}
        showPlaceholder={false}
        showPreviewImage={true}
        isReplaceable
        withBorder
        containerCss={{
          flexDirection: "column",
          border: featuredAsset ? "none" : "1.5px dashed $grey3",
          borderRadius: "8px",
          width: 343,
          height: "350px",
        }}
        imageCss={{
          height: "300px",
          width: "100%",
          borderRadius: "8px 8px 0 0",
          objectFit: "initial",
        }}
        assetImageSize={{
          "@initial": {
            width: 343,
            height: 300,
            aspectRatio: 1,
          },
        }}
        assetImageContainer={{
          width: "100%",
          height: "300px",
        }}
        assetImageCss={{
          background: "none",
          borderRadius: "8px 8px 0 0",
          paddingTop: 0,
          height: "300px",
        }}
      />

      {error && (
        <ValidationBlock variant="error" title={error} css={{ mt: "$3" }} />
      )}

      <CropModal
        isOpen={cropModalOpen}
        onClose={() => setCropModalOpen(false)}
        featuredAsset={featuredAsset}
        onUpload={onUpload}
      />
    </Box>
  );
};
