import React from "react";
import { capitalize } from "lodash";
import { Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { TalentVertical } from "src/graphql/types";
import { verticalIconMapAlt, verticalMap } from "src/utils/user";
import { Tooltip } from "src/ccl/feedback";

interface VerticalTitleProps {
  vertical: TalentVertical;
  category: string | undefined;
  creativeType?: string;
}

export const VerticalTitle = ({
  vertical,
  category,
  creativeType,
}: VerticalTitleProps) => {
  return (
    <Flex
      css={{
        borderBottom: "1px solid $grey2",
        pb: "$4",
        mb: "$5",
        "@bp3": {
          pb: "$7",
          mb: "$10",
        },
        width: "100%",
      }}
    >
      <Flex
        css={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Flex css={{ alignItems: "center" }}>
          <Tooltip
            content={capitalize(verticalMap[vertical])}
            css={{ zIndex: "250" }}
            hover="noMobileHover"
          >
            <Icon
              size={30}
              variant={verticalIconMapAlt[vertical]}
              css={{ justifySelf: "flex-start", mr: "$3" }}
            />
          </Tooltip>
          <Text
            variant={{ "@initial": "buttonText", "@bp3": "nh4" }}
            css={{
              mb: "$2",
              textTransform: "capitalize",
              fontFamily: "$sansNew",
            }}
          >
            {(creativeType || verticalMap[vertical]).toUpperCase()}
          </Text>
        </Flex>
        {vertical === TalentVertical.FashionModel && (
          <Flex>
            <Text
              variant="meta"
              css={{
                mr: "$5",
                color: "$grey4",
                fontFamily: "$sansNew",
              }}
            >
              Category: <strong>{category}</strong>
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
