import React from "react";
import { useMutation } from "@apollo/client";
import { AdditionalPaymentsList } from ".";
import { Text, ModalProps, Modal } from "src/ccl/document";
import {
  Asset,
  JobTalent,
  LineItem,
  MutationAddLineItemArgs,
  MutationDeleteLineItemArgs,
  OutboundPayment,
} from "src/graphql/types";
import { Box, Flex } from "src/ccl/layout";
import { Avatar } from "src/ccl/blocks";
import { getInitials } from "src/utils/lang";
import { AdditionalPaymentForm } from "src/components/payments";
import { ValidationBlock } from "src/ccl/feedback";
import {
  ADD_LINE_ITEM_MUTATION,
  DELETE_LINE_ITEM_MUTATION,
} from "src/graphql/mutations";
import { LINE_ITEM_FRAGMENT } from "src/graphql/fragments";

interface AdditionalPaymentModalProps
  extends Pick<ModalProps, "onClose" | "isOpen"> {
  jobTalent: JobTalent;
  avatarAsset?: Asset;
}

export const AdditionalPaymentModal = ({
  jobTalent,
  avatarAsset,
  onClose,
  ...modalProps
}: AdditionalPaymentModalProps) => {
  const [addLineItem, { loading, error: createPaymentError }] = useMutation<
    { addLineItem: LineItem },
    MutationAddLineItemArgs
  >(ADD_LINE_ITEM_MUTATION, {
    update(cache, data) {
      if (!data.data) {
        return;
      }

      const payment = data.data.addLineItem;
      const newPaymentRef = cache.writeFragment({
        data: payment,
        fragment: LINE_ITEM_FRAGMENT,
      });
      cache.modify({
        id: `JobTalent:${jobTalent.id}`,
        fields: {
          lineItems(existingPaymentRefs = []) {
            return [...existingPaymentRefs, newPaymentRef];
          },
        },
      });
      if (payment.outboundPayment?.id) {
        cache.modify({
          id: `OutboundPayment:${payment.outboundPayment?.id}`,
          fields: {
            lineItems(existingPaymentRefs = []) {
              return [...existingPaymentRefs, newPaymentRef];
            },
          },
        });
      }
    },
  });

  const [deleteLineItem, { error: deletePaymentError }] = useMutation<
    { deleteLineItem: OutboundPayment },
    MutationDeleteLineItemArgs
  >(DELETE_LINE_ITEM_MUTATION, {
    update(cache, result) {
      if (result.data) {
        cache.evict({
          id: `LineItem:${result.data.deleteLineItem.id}`,
        });
      }
    },
  });

  const lineItemsWithoutPayment =
    jobTalent?.lineItems?.filter((li) => !li.outboundPayment) || [];
  const lineItems =
    jobTalent?.outboundPayments?.reduce(
      (arr, p) => [...(p.lineItems || []), ...arr],
      lineItemsWithoutPayment,
    ) || [];

  return (
    <Modal onClose={onClose} {...modalProps}>
      <Flex
        css={{
          justifyContent: "space-between",
          mb: lineItems.length === 0 ? "$10" : "$7",
        }}
      >
        <Box>
          <Text variant={{ "@initial": "h3", "@bp2": "h2" }} css={{ pb: "$2" }}>
            {lineItems.length === 0
              ? "Additional payment"
              : "Additional payments"}
          </Text>
          <Text>to {jobTalent.talent.name}</Text>
        </Box>

        <Box css={{ display: "none", "@bp2": { display: "block" } }}>
          <Avatar
            imageUrl={avatarAsset?.mediaUrl}
            initials={getInitials(jobTalent.talent.name)}
            size="$megaAvatar"
          />
        </Box>
      </Flex>

      {deletePaymentError && (
        <ValidationBlock
          variant="error"
          size="sm"
          css={{ my: "$4" }}
          title="Couldn't delete additional payment"
          body={deletePaymentError.message}
        />
      )}

      {lineItems.length > 0 && (
        <Box css={{ mb: "$8" }}>
          <AdditionalPaymentsList
            lineItems={lineItems}
            onDelete={(paymentId) => {
              deleteLineItem({
                variables: {
                  jobTalentId: jobTalent.id,
                  lineItemId: paymentId,
                },
              }).catch(() => {});
            }}
          />
        </Box>
      )}

      <AdditionalPaymentForm
        title={
          lineItems.length === 0 ? "Payment type" : "Add additional payment"
        }
        onSubmit={(values) => {
          addLineItem({
            variables: {
              ...values,
              amount: values.amount
                ? parseFloat(values.amount.toString())
                : 0.0,
              jobTalentId: jobTalent.id,
            },
          });
        }}
        createPaymentError={createPaymentError}
        onClose={onClose}
        loading={loading}
      ></AdditionalPaymentForm>
    </Modal>
  );
};
