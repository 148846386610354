import React, { useState } from "react";
import { isEqual } from "lodash";
import {
  ACTIVE_STATES,
  EARLIEST_JOBS,
  LATEST_JOBS,
  PAST_STATES,
} from "src/components/filtering";
import { FilterOption } from "src/ccl/filtering";
import { Modal, ModalProps, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { JobState, SortOrderInput } from "src/graphql/types";
import { Button } from "src/ccl/navigation";

interface SortOrderModalProps extends ModalProps {
  sortOrder: SortOrderInput;
  onSortOrderChange: (sortOrder: SortOrderInput) => void;
  onStatesChange: (sortOrder: JobState[]) => void;
  jobCount?: number;
}
export const SortOrderModal = ({
  sortOrder,
  onSortOrderChange,
  onStatesChange,
  jobCount,
  ...modalProps
}: SortOrderModalProps) => {
  const [sortByActive, setSortByActive] = useState(false);
  const [sortByPast, setSortByPast] = useState(false);
  const sortByStateFlags = sortByPast || sortByActive;
  const resetStateFlags = () => {
    setSortByActive(false);
    setSortByPast(false);
  };
  return (
    <Modal
      withBodyPadding={false}
      titleCss={{ paddingTop: "$4" }}
      title="Sort by"
      {...modalProps}
    >
      <Box css={{ px: "$3" }}>
        <Box
          css={{
            height: 300,
          }}
        >
          <FilterOption
            label="Latest Job Date"
            checked={!sortByStateFlags && isEqual(LATEST_JOBS, sortOrder)}
            onClick={() => {
              onSortOrderChange(LATEST_JOBS);
              resetStateFlags();
            }}
          />
          <FilterOption
            label="Earliest Job Date"
            checked={isEqual(EARLIEST_JOBS, sortOrder)}
            onClick={() => {
              onSortOrderChange(EARLIEST_JOBS);
              resetStateFlags();
            }}
          />
          <FilterOption
            label="Active Jobs"
            checked={isEqual(LATEST_JOBS, sortOrder) && sortByActive}
            onClick={() => {
              onSortOrderChange(LATEST_JOBS);
              onStatesChange(ACTIVE_STATES);
              setSortByPast(false);
              setSortByActive(true);
            }}
          />
          <FilterOption
            label="Past Jobs"
            checked={isEqual(LATEST_JOBS, sortOrder) && sortByPast}
            onClick={() => {
              onSortOrderChange(LATEST_JOBS);
              onStatesChange(PAST_STATES);
              setSortByActive(false);
              setSortByPast(true);
            }}
          />
        </Box>
        <Box css={{ textAlign: "center" }}>
          <Text color="grey6">{`${jobCount || "No"} jobs found`}</Text>
        </Box>
        <Flex css={{ height: "48px", mt: "$7", mb: "$5", mx: "$5" }}>
          <Button
            data-test-id="ConfirmButtonSecondary"
            variant="secondary"
            onClick={modalProps.onClose}
            css={{ mr: "auto" }}
          >
            Close
          </Button>

          <Button
            data-test-id="ConfirmButtonPrimary"
            variant="primary"
            onClick={modalProps.onClose}
            css={{}}
          >
            Search
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};
