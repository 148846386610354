import { gql } from "@apollo/client";
import {
  USER_ASSET_FRAGMENT,
  USER_BASE_FRAGMENT,
  USER_PROFILE_FRAGMENT,
} from "src/graphql/fragments";

export const BOOKER_DASHBOARD_SAVED_TALENT_FRAGMENT = gql`
  fragment BookerDashboardSavedTalent on User {
    profile {
      ... on BookerProfile {
        savedTalent(
          first: 10
          sortColumn: "created_at"
          sortDirection: DESC
          after: $savedTalentEndCursor
        ) {
          totalCount
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              timeSinceBookmarked
              talent {
                bookmarked
                ...UserBase
                ...UserAsset
                profile {
                  ... on TalentProfile {
                    ...UserProfile
                    lastPortfolioUpload {
                      id
                      mediaUrl
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${USER_BASE_FRAGMENT}
  ${USER_PROFILE_FRAGMENT}
  ${USER_ASSET_FRAGMENT}
`;
