import React from "react";
import { Field } from "formik";
import { Textarea, TextareaProps } from "./Textarea";

export const FormikTextarea = (props: TextareaProps) => (
  <Field
    as={Textarea}
    id={props.id || `form-${props.name}`}
    {...props}
    css={
      props.variant === "rebrand"
        ? {
            minHeight: "128px",
            borderRadius: "8px",
            border: props.value ? "2px solid $black" : "1.5px solid $grey2",
            "&:hover": props.value
              ? undefined
              : {
                  border: "1.5px solid $grey4",
                },
          }
        : undefined
    }
  />
);
