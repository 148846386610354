import React from "react";
import { Form, Formik } from "formik";
import { Field, FormikInput } from "src/ccl/data-entry";
import { ModalProps, Modal, Text } from "src/ccl/document";
import { Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { PortfolioCategoryValidation } from "src/validations";
import { PortfolioImageCategory } from "src/graphql/types";

interface CreatePortfolioCategoryModalProps extends ModalProps {
  onCreate: (name: string) => void;
  existingCategories: PortfolioImageCategory[];
}

export const CreatePortfolioCategoryModal = ({
  onClose,
  isOpen,
  onCreate,
  existingCategories,
}: CreatePortfolioCategoryModalProps) => {
  return (
    <Modal
      showCloseButton={true}
      onClose={() => {
        onClose?.();
      }}
      isOpen={isOpen}
    >
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={PortfolioCategoryValidation(existingCategories)}
        validateOnMount={false}
        onSubmit={({ name }) => {
          onCreate(name);
        }}
      >
        {() => (
          <Form>
            <Flex
              css={{
                flexDirection: "column",
                gap: "$9",
              }}
            >
              <Text variant="nh3">Add new category</Text>
              <Field variant="b2Bold" name="name" label="Category name">
                <FormikInput
                  variant="rebrand"
                  autoFocus
                  placeholder="E.g. Lucy & Yak E-commerce campaign"
                  name="name"
                  type="string"
                />
              </Field>
              <Flex
                css={{
                  flexDirection: "column-reverse",
                  gap: "18px",
                  "@sm": {
                    flexDirection: "row",
                    justifyContent: "space-between",
                  },
                }}
              >
                <Button variant="secondaryCta" type="reset" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primaryCta"
                  type="submit"
                  data-test-id="create-button"
                >
                  Create category
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
